import * as types from './actionTypes';
import { getAppointmentTypes } from '../../api/appointmentTypesApi';
import { beginApiCall, apiCallError } from './apiStatusActions';

const beginLoadAppointmentTypes = () => {
  return { type: types.BEGIN_FETCH_APPOINTMENT_TYPES };
};

export function loadAppointmentTypesAction(appointmentTypes) {
  return { type: types.FETCH_APPOINTMENT_TYPES_SUCCESS, appointmentTypes };
}

export function loadAppointmentTypes() {
  // eslint-disable-next-line func-names
  return async function (dispatch) {
    dispatch(beginApiCall());
    dispatch(beginLoadAppointmentTypes());
    try {
      const appointmentTypes = await getAppointmentTypes();
      dispatch(loadAppointmentTypesAction(appointmentTypes));
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}
