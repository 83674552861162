import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function searchFiltersReducer(
  state = initialState.searchFilters,
  action,
) {
  switch (action.type) {
    case types.UPDATE_SEARCH_FILTERS:
      return action.filters;
    case types.BACK_TO_LANDING_PAGE:
      return initialState.searchFilters;
    default:
      return state;
  }
}
