import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { BrandingContext } from 'components/contexts/BrandingContext';
import './Paginator.scss';

const Paginator = ({ handleChange, activePage, totalPages }) => {
  const { branding } = useContext(BrandingContext);
  const buttonBaseClass = 'pagination-link pagination-page';
  const lastPageIsActive = activePage === totalPages;
  const buttons = [];

  const activeStyle = {
    backgroundColor: branding.buttonColor,
    color: branding.buttonTextColor,
    borderColor: branding.buttonColor,
  };

  buttons.push(
    <button
      type="button"
      aria-label="page-left 1"
      style={
        // eslint-disable-next-line no-nested-ternary
        (activePage === 1 || activePage === undefined) && totalPages === 1
          ? activeStyle
          : activePage === 1
          ? activeStyle
          : {}
      }
      className={`${buttonBaseClass}${
        // eslint-disable-next-line no-nested-ternary
        (activePage === 1 || activePage === undefined) && totalPages === 1
          ? ' active'
          : activePage === 1
          ? ' active'
          : 'btn-default btn-default-outline'
      }`}
      disabled={
        (activePage === 1 || activePage === undefined) && totalPages === 1
      }
      onClick={() => handleChange(1)}
      key={1}
    >
      1
    </button>,
  );

  if (activePage > 3) {
    buttons.push(
      <button
        type="button"
        aria-label="page-left ..."
        className={`${buttonBaseClass} empty`}
        key="..."
      >
        ...
      </button>,
    );
  }

  for (
    let i = Math.max(activePage - 2, 2);
    i < Math.min(activePage + 3, totalPages);
    i += 1
  ) {
    buttons.push(
      <button
        type="button"
        aria-label={`page-right ${i}`}
        className={`${buttonBaseClass}${
          activePage === i ? ' active' : 'btn-default btn-default-outline'
        }`}
        style={activePage === i ? activeStyle : {}}
        value={i}
        key={i}
        onClick={e => {
          handleChange(parseInt(e.target.value, 10));
        }}
      >
        {i}
      </button>,
    );
  }

  if (activePage < totalPages - 3) {
    buttons.push(
      <div className={`${buttonBaseClass} empty`} key="...2">
        ...
      </div>,
    );
  }

  if (totalPages > 1) {
    buttons.push(
      <button
        type="button"
        aria-label={`page-right ${totalPages}`}
        className={`${buttonBaseClass}${
          lastPageIsActive ? ' active' : 'btn-default btn-default-outline'
        }`}
        style={lastPageIsActive ? activeStyle : {}}
        onClick={() => handleChange(totalPages)}
        key={totalPages + 10000} // avoid duplicated key issue by adding a big number
      >
        {totalPages}
      </button>,
    );
  }

  return (
    <div className="results-pagination">
      <button
        type="button"
        aria-label="page-left icon"
        className={`chevron pagination-link ${
          activePage === 1 || activePage === undefined ? ' disabled' : ''
        }`}
        onClick={() => handleChange(Math.max(activePage - 1, 1))}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>

      {buttons}

      <button
        type="button"
        aria-label="page-right icon"
        className={`chevron pagination-link ${
          activePage === undefined || activePage === totalPages
            ? ' disabled'
            : ''
        }`}
        onClick={() => handleChange(Math.min(activePage + 1, totalPages))}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

Paginator.propTypes = {
  handleChange: PropTypes.func.isRequired,
  activePage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default Paginator;
