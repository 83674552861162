import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DialogMessage from 'components/form/registrationForm/DialogMessage';
import { validateCouponCode } from 'api/couponCodeApi';

const CouponCodeDialog = ({
  appointmentTypeId,
  hasCouponCode,
  healthSystemId,
  setShowCouponCodeDialog,
  couponCode,
}) => {
  const [t] = useTranslation();
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    (async () => {
      const verifyCouponCode = async () => {
        if (couponCode) {
          const { response } = await validateCouponCode({
            code: couponCode,
            health_system_id: healthSystemId,
            appointment_type_id: appointmentTypeId,
          });
          if (response.is_valid === true) return false;
        }
        return true;
      };

      if (hasCouponCode) {
        const isValid = await verifyCouponCode();
        setShowDialog(isValid);
        setShowCouponCodeDialog(isValid);
      }
    })();
  }, [hasCouponCode]);

  return (
    <>
      {showDialog && (
        <DialogMessage
          show={showDialog}
          title={t('BookingError.Title')}
          message={t('BookingError.Message')}
          primaryBtnText={t('BookingError.OK')}
          primaryBtnClick={() => {
            if (history.length > 2) {
              history.goBack();
            } else {
              history.push('/');
            }
          }}
        />
      )}
    </>
  );
};

CouponCodeDialog.propTypes = {
  appointmentTypeId: PropTypes.number.isRequired,
  hasCouponCode: PropTypes.bool.isRequired,
  healthSystemId: PropTypes.number.isRequired,
  setShowCouponCodeDialog: PropTypes.func.isRequired,
  couponCode: PropTypes.string.isRequired,
};

export default CouponCodeDialog;
