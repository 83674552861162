import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import PersonIcon from '@material-ui/icons/Person';
import { BrandingContext } from 'components/contexts/BrandingContext';
import './EducationalImage.scss';

const EducationalImage = ({ image, venueType }) => {
  const { branding } = useContext(BrandingContext);
  return (
    <div className="EducationalImage">
      {image && (
        <div
          className="educational-image-element"
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition:
              venueType === 'emergency_room' ? 'right' : 'center',
          }}
        />
      )}

      {!image && (
        <div
          className="educational-image-element"
          style={{ backgroundColor: branding.primaryColor }}
        >
          <PersonIcon fontSize="inherit" />
        </div>
      )}
    </div>
  );
};

EducationalImage.defaultProps = {
  image: null,
  venueType: '',
};

EducationalImage.propTypes = {
  image: PropTypes.string,
  venueType: PropTypes.string,
};

export default EducationalImage;
