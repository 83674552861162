import { Box, Grid, Typography, Button, TextField, MenuItem, FormHelperText } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import { ReactComponent as Preloader } from 'assets/images/icons/preloader.svg';
import BrandingButton from "components/common/BrandingButton";
import { PatientIntakeContext } from "../contexts/PatientIntakeContext";
import "./PatientIntakePage.scss";
import InsuranceUpload from "./InsuranceUpload";
import { getDocuments, fetchDocument, getInsurancePlans, uploadDocuments, FetchFormRecognizerAPI, calcCopay, getInsuranceInfo, updateInsuranceInfo } from "api/patientIntakeApi";
import InputMask from 'react-input-mask';
import AppointmentInfoModal from "./AppointmentInfoModal";
import IntakeHeader from "./IntakeHeader";
import { isMobile } from 'react-device-detect';

const InsurancePage = () => {

    const [t] = useTranslation();
    const intake = useContext(PatientIntakeContext);
    const insuranceData = intake?.insuranceData
    const insurance = intake?.intakeInfo?.insurance;
    const btnDisbaled = insurance?.FrontImage && insurance?.BackImage
    const [isUploading, setIsUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [insurancePlans, setInsurancePlans] = useState([]);
    const [isPhoneNumValid, setIsPhoneNumValid] = useState(true);
    const disableInsuranceBtn = insuranceData?.planName && insuranceData?.subscriberId && insuranceData?.groupNumber && insuranceData?.memberId && insuranceData?.subscriberName
    const [showApptInfoModal, setShowApptInfoModal] = useState(false);

    const handleChangeInsuranceData = (name, value) => {
        intake.setInsuranceData(prevIntakeInfo => ({
            ...prevIntakeInfo,
            [name]: value,
            dataChanged: true
        }));
    };

    const handleChangeInsurancePlan = (value) => {
        intake.setInsuranceData(prevIntakeInfo => ({
            ...prevIntakeInfo,
            planName: value,
            otherName: "",
            dataChanged: true
        }));
    };

    const useStyles = makeStyles((theme) => ({
        apptInfoStyle: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        },
        infoText: {
            color: "#3175F6",
            fontSize: "14px !important",
            fontWeight: "600 !important"
        },
        uploadButton: {
            paddingInline: "18px",
            float: "right",
            borderRadius: "8px",
            marginBlock: "15px",
            opacity: !btnDisbaled || errorMessage || loading ? 0.6 : 1,
            pointerEvents: !btnDisbaled || errorMessage || loading ? "none" : ""
        },
        arrowBtn: {
            paddingLeft: "5px"
        },
        boxStyle: {
            bgcolor: '#F9F9F9',
            width: '100%',
            aspectRatio: '20/5',
            borderRadius: 3,
            display: 'inline-flex',
            alignItems: "center",
            opacity: intake?.copayInfo?.isCopayCollected && insurance?.FrontImage && insurance?.BackImage ? 0.6 : 1,
            pointerEvents: intake?.copayInfo?.isCopayCollected && insurance?.FrontImage && insurance?.BackImage ? "none" : ""
        },
        grid: {
            display: "flex",
            justifyContent: "space-between",
            alignItems:"center",
            marginInline: isMobile ? "20px !important" : "35px !important"
        },
        nextButton: {
            paddingInline: "18px",
            borderRadius: "8px",
            textTransform: "none !important",
            opacity: !disableInsuranceBtn ? 0.6 : 1,
            pointerEvents: !disableInsuranceBtn ? "none" : ""
        },
        backButton: {
            paddingInline: "18px",
            borderRadius: "8px",
            textTransform: "none !important",
            marginBlock: "15px",
        },
        backArrowBtn: {
            paddingRight: "5px"
        },
        uploadSections: {
            position: "relative"
        },
        uploadingStyle: {
            borderRadius: 1,
            position: "absolute !important",
            left: "0px", right: 1,
            top: "0px", bottom: '0px',
            backgroundColor: "#969696",
            zIndex: '999',
            bgcolor: '#969696',
            opacity: 0.8,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 1
        },
        errorContainer: {
            borderRadius: "8px !important",
            backgroundColor: "#EB5757 !important",
            paddingInline: "10px"
        },
        errorStyle: {
            color: "#FFFFFF",
            fontSize: "12px !important"
        },
        flex: {
            display: "flex",
            justifyContent: "space-between",
        }
    }));
    const classes = useStyles();

    const fetchDocuments = async () => {
        const data = await getDocuments(intake?.secureId, intake?.secureToken);
        if (data === "PatientIntake.TokenExpired") {
            intake.handleTokenExpiry()
            return;
        }
        if (data?.patientIntakeDocuments?.length > 0) {
            intake.setDocs(data?.patientIntakeDocuments);
        }
    };

    const getDocument = async (doc, side) => {
        await fetchDocument(intake?.secureId, intake?.secureToken, doc?.documentFetchId).then((res) => {
            if (res === "PatientIntake.TokenExpired") {
                intake.handleTokenExpiry()
                return;
            }
            if (res?.success) {
                let fileType = res?.data?.filename?.split(".")?.pop();
                intake.updateInsuranceInfo({ [`${side}Image`]: res?.data?.fileData, [`${side}ImageName`]: res?.data?.filename, [`${side}ImageType`]: fileType }, "insurance");
            }
        }).finally(() => setLoading(false));
    };


    useEffect(() => {
        const getImageData = async () => {
            const frontImageDoc = intake?.docs?.find(doc => doc?.documentOrientation == 'front' && doc?.documentTypeCode == "insurance")
            if (frontImageDoc) {
                setLoading(true)
                await getDocument(frontImageDoc, "Front")

            }

            const backImageDoc = intake?.docs?.find(doc => doc?.documentOrientation == "back" && doc?.documentTypeCode == "insurance")
            if (backImageDoc) {
                setLoading(true)
                await getDocument(backImageDoc, "Back")
            }
        }

        if (intake?.secureToken && !insurance?.FrontImageName && !insurance?.BackImageName) getImageData();

    }, [intake?.docs]);

    useEffect(() => {
        if (intake?.secureToken) fetchDocuments();
        getPlans();
    }, []);

    const getPlans = async () => {
        try {
            const data = await getInsurancePlans(intake?.scheduleId);
            const plans = data?.data?.map(element => {
                return element?.attributes
            });
            setInsurancePlans(plans)
        } catch (e) {
            console.log(e);
        }
    }
    const getImageInfo = async () => {
        if (insurance?.ImagesChanged || !insuranceData?.planName) {
            const frontImagePromise = FetchFormRecognizerAPI(insurance?.FrontImageName, insurance?.FrontImage);
            const backImagePromise = FetchFormRecognizerAPI(insurance?.BackImageName, insurance?.BackImage);
            try {
                const imgageResponses = await Promise.all([frontImagePromise, backImagePromise]);
                var parsedData = null;
                imgageResponses.forEach(response => {
                    parsedData = JSON.parse(response);
                    if (parsedData.plan?.name != null && parsedData?.plan?.name?.confidence >= 0.99) {
                        const item = insurancePlans?.find(item => item?.name == parsedData.plan?.name?.value)
                        if (item) {
                            handleChangeInsuranceData('planName', item?.permalink)
                        } else {
                            handleChangeInsuranceData('otherName', parsedData?.plan?.name?.value)
                            handleChangeInsuranceData('planName', "other")
                        }
                    }
                    if (parsedData?.payer?.address != null)
                        handleChangeInsuranceData('address', parsedData?.payer?.address)

                    if (parsedData?.payer?.phoneNumber != null)
                        handleChangeInsuranceData('phoneNumber', parsedData?.payer?.phoneNumber)

                    if (parsedData?.idNumber?.number != null && parsedData?.idNumber?.number?.confidence >= 0.99) {
                        handleChangeInsuranceData('subscriberId', parsedData?.idNumber?.number?.value)
                        handleChangeInsuranceData('memberId', parsedData?.idNumber?.number?.value)
                    }
                    if (parsedData?.groupNumber?.confidence != null && parsedData?.groupNumber?.confidence >= 0.99)
                        handleChangeInsuranceData('groupNumber', parsedData?.groupNumber?.value)
                    if (parsedData?.member?.name != null && parsedData?.member?.name?.confidence >= 0.99)
                        handleChangeInsuranceData('subscriberName', parsedData?.member?.name?.value)
                })
            } catch (error) {
                console.error(error)
            }
        }
        setIsUploading(false);
        setLoading(false)
        intake.setShowInsuranceForm(true);

    }


    const handleClick = async () => {
        if (!intake?.validImageTypes?.includes(insurance?.[`FrontImageType`]) || !intake?.validImageTypes?.includes(insurance?.[`BackImageType`])) {
            setErrorMessage(t(`PatientIntake.InvalidFileTypeError`));
            return;
        }
        if (!insurance?.ValidFrontImageSize || !insurance?.ValidBackImageSize) {
            setErrorMessage(t(`PatientIntake.BigInsuranceDocSize`));
            return;
        }
        setIsUploading(true);
        setLoading(true);
        const formData = [
            {
                "filename": insurance?.FrontImageName,
                "document_type": "insurance",
                "document_content": insurance?.FrontImage,
                "page": 1
            },
            {
                "filename": insurance?.BackImageName,
                "document_type": "insurance",
                "document_content": insurance?.BackImage,
                "page": 2
            }
        ]

        try {
            if (insurance?.ImagesChanged) {
                const data = await uploadDocuments(intake?.secureId, intake?.secureToken, formData)
                if (data === "PatientIntake.TokenExpired") {
                    intake.handleTokenExpiry()
                    setIsUploading(false);
                    setLoading(false)
                    return;
                } else if (!data?.hasErrors && data?.results?.length > 0 && intake?.copayInfo?.isCopayCollected) {
                    intake.updateInsuranceInfo({ ImagesChanged: false }, "insurance");
                    getInsuranceInformation();
                    return;
                }
                else if (!data?.hasErrors && data?.results?.length > 0) {
                    intake.updateInsuranceInfo({ ImagesChanged: false }, "insurance");
                    getImageInfo();
                } else {
                    setIsUploading(false);
                    setLoading(false)
                    setErrorMessage(t('PatientIntake.ScanError'))
                }
            } else {
                getInsuranceInformation();
                return;
            }
        } catch (e) {
            console.log(e);
            setIsUploading(false);
            setLoading(false)
        }
    }

    useEffect(() => {
        setErrorMessage(errorMessage)
    }, [errorMessage])

    useEffect(() => {        
       if(intake?.showInsuranceForm && !insuranceData?.planName) getInsuranceInformation();
    }, [intake?.showInsuranceForm])

    const handlePhoneMask = (e) => {

        if (e.target?.value !== null && e.target?.value?.includes("_")) {
            setIsPhoneNumValid(false);
        } else {
            setIsPhoneNumValid(true);
        }
    }

    const handleSaveInsuranceData = async () => {
        const insurancePlan = insurancePlans?.find(plan => plan?.permalink === insuranceData?.planName);
        const formData = {
            "insurance_plan_name": insurancePlan?.name || "other",
            "insurance_plan_permalink": insuranceData?.planName,
            "other_insurance": insuranceData?.otherName,
            "insurance_full_address": insuranceData?.address,
            "insurance_phone_number": insuranceData?.phoneNumber,
            "insurance_subscriber_id": insuranceData?.subscriberId,
            "insurance_group_number": insuranceData?.groupNumber,
            "insurance_subscriber_name": insuranceData?.subscriberName,
            "insurance_member_id": insuranceData?.memberId
        }

        try {
            setIsUploading(true);
            setLoading(true);
            if (intake?.copayInfo?.paymentDeclined) intake.setPayMethod("payLater");
            const data = await updateInsuranceInfo(intake?.secureId, intake?.secureToken, formData);
            if (data === "PatientIntake.TokenExpired") {
                intake.handleTokenExpiry();
                setIsUploading(false);
                setLoading(false);
                return;
            } else if (data && data?.success && !intake?.copayInfo?.isCopayCollected) {
                const data = await calcCopay(intake?.secureId, intake?.secureToken, formData);
                if (data === "PatientIntake.TokenExpired") {
                    intake.handleTokenExpiry()
                    setIsUploading(false);
                    setLoading(false)
                    return;
                } else if (data?.isValidCopay) {
                    intake.setInsuranceData(prevIntakeInfo => ({
                        ...prevIntakeInfo,
                        dataChanged: false
                    }));
                    if (data?.copay > 0) {
                        const paymentObj = {
                            "documentTypeId": 999,
                            "documentTypeCode": "payment",
                            "documentTypeName": "Payment"
                        }
                        let steps = intake?.requiredSteps;
                        if (!intake?.requiredSteps.find(item => item.documentTypeCode === "payment")) {
                            steps.splice(intake?.page + 1, 0, paymentObj);
                            intake.setRequiredSteps(steps);
                        }
                        intake.setCopayInfo(prevCopayInfo => ({
                            ...prevCopayInfo,
                            copayAmount: data?.copay
                        }));
                        intake.markStepComplete("insurance");
                    } else {
                        intake.markStepComplete("insurance");
                    }
                } else if (!data?.isValidCopay) {
                    intake.setInsuranceData(prevIntakeInfo => ({
                        ...prevIntakeInfo,
                        dataChanged: false
                    }));
                    setIsUploading(false);
                    setLoading(false)
                    intake.markStepComplete("insurance");
                } else {
                    setIsUploading(false);
                    setLoading(false)
                    setErrorMessage(t('PatientIntake.InternalServerError'))
                }
            } else if (data && data?.success && intake?.copayInfo?.isCopayCollected) {
                const paymentObj = {
                    "documentTypeId": 999,
                    "documentTypeCode": "payment",
                    "documentTypeName": "Payment"
                }
                let steps = intake?.requiredSteps;
                if (!intake?.requiredSteps?.find(item => item.documentTypeCode === "payment")) {
                    steps.splice(intake?.page + 1, 0, paymentObj);
                    intake.setRequiredSteps(steps);
                }
                intake.markStepComplete("insurance");
            } else {
                setErrorMessage(t(data?.errorMessage));
            }
        } catch (e) {
            setErrorMessage(t('PatientIntake.InternalServerError'))
        } finally {
            setIsUploading(false);
            setLoading(false)
            intake.setShowInsuranceForm(false);
        }

    }

    const getInsuranceInformation = async () => {
        const data = await getInsuranceInfo(intake?.secureId, intake?.secureToken);

        if (data?.isSuccess) {
            intake.setInsuranceData(prevIntakeInfo => ({
                ...prevIntakeInfo,
                'planName': data?.data?.insurancePlanPermalink,
                'otherName': data?.data?.otherInsurance,
                'permalink': data?.data?.insurancePlanPermalink,
                'address': data?.data?.insuranceFullAddress,
                'phoneNumber': data?.data?.insurancePhoneNumber,
                'subscriberId': data?.data?.insuranceSubscriberId,
                'memberId': data?.data?.insuranceMemberId,
                'groupNumber': data?.data?.insuranceGroupNumber,
                'subscriberName': data?.data?.insuranceSubscriberName,
                dataChanged: true
            }));

        }

        setIsUploading(false);
        setLoading(false)
        intake.setShowInsuranceForm(true);
    }

    const handleClickBack = () => {
        intake.prevPage();
    }

    return (
        <Grid container alignItems="center" justifyContent="center">

            <Box
                component="div"
                sx={{ flexGrow: 1, marginTop: 3 }}
            >
                <Grid container justifyContent={"center"} alignItems="center">
                    <Grid item xs={12} >
                        <IntakeHeader pageName={t('PatientIntake.Insurance')} setShowApptInfoModal={setShowApptInfoModal} />
                    </Grid>
                    {!intake?.showInsuranceForm ?
                            <Grid item xs={12} >
                                <div className={classes.boxStyle} >
                                    <Grid container rowSpacing={2} justifyContent={"center"} alignItems="center" >
                                        <Grid item xs={10} md={6} className={classes.uploadSections}>
                                            {loading &&
                                                <Box component={"div"} className={classes.uploadingStyle} >
                                                    <Preloader className="rotateLoader" />
                                                    {isUploading && <Typography sx={{ color: '#fff', paddingInline: 3 }}>{t('PatientIntake.Uploading')}....</Typography>}
                                                </Box>
                                            }
                                            <Grid container spacing={2} justifyContent={"center"} alignItems="center" >
                                                <InsuranceUpload side="Front" setError={setErrorMessage} page={"insurance"} />
                                                <InsuranceUpload side="Back" setError={setErrorMessage} page={"insurance"} />
                                                {errorMessage &&
                                                    <Grid item xs={12} >
                                                        <Box component={"div"} className={classes.errorContainer}>
                                                            <Typography className={classes.errorStyle}>{errorMessage}</Typography>
                                                        </Box>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </div>
                                <Grid item xs={12} className={classes.grid}>
                                {intake?.page !== 1 ? 
                                        <Button
                                            type="button"
                                            className={classes.backButton}
                                            variant="outlined"
                                            onClick={handleClickBack}
                                        >
                                            <ArrowBack fontSize="small" className={classes.backArrowBtn} /> {t('PatientIntake.Back')}
                                        </Button> : <div /> }
                                        <BrandingButton
                                            type="button"
                                            className={classes.uploadButton}
                                            onClick={handleClick}
                                        >
                                            {t('PatientIntake.Next')}<ArrowForward fontSize="small" className={classes.arrowBtn} />
                                        </BrandingButton>
                                    </Grid>
                            </Grid>
                         :
                        <Grid item xs={11} >
                        <Grid container justifyContent={"center"} alignItems="center">
                            {errorMessage &&
                                <Grid item xs={12} >
                                    <Box component={"div"} className={classes.errorContainer}>
                                        <Typography className={classes.errorStyle}>{errorMessage}</Typography>
                                    </Box>
                                </Grid>
                            }
                            <Box
                                component="form"
                                noValidate
                                autoComplete="off"
                                sx={{ flexGrow: 1, marginTop: 3 }}
                            >

                                <Grid container spacing={1} justifyContent={"center"}>
                                    <Grid item xs={12} marginY={1}>
                                        <Typography variant="h5" component="h5" className="pageNameStyle">
                                            {t('PatientIntake.CheckDataFromCard')}
                                        </Typography>

                                    </Grid>

                                    <Grid item sm={6} xs={12} marginY={1}>
                                        <TextField
                                            id="outlined-select-currency"
                                            select
                                            className="form-item"
                                            label={t('PatientIntake.Insurance')}
                                            size="small"
                                            fullWidth
                                            required
                                            value={insuranceData?.planName}
                                            name="planName"
                                            onChange={(e) => handleChangeInsurancePlan(e.target.value)}
                                            disabled={intake?.copayInfo?.isCopayCollected && !intake?.isCoPayCollectedInRegForm}
                                        >
                                            <MenuItem value="">{t('choose')}</MenuItem>
                                            <MenuItem value={"other"}>{t('PatientIntake.Other')}</MenuItem>
                                            {insurancePlans?.map(plan =>
                                                <MenuItem key={plan?.permalink} value={plan?.permalink}>{plan?.name}</MenuItem>
                                            )}
                                        </TextField>
                                    </Grid>

                                    <Grid item sm={6} xs={12} marginY={1} >
                                        <TextField
                                            className="form-item"
                                            label={t('PatientIntake.OtherInsurance')}
                                            size="small"
                                            fullWidth
                                            value={insuranceData?.otherName}
                                            name="otherName"
                                            onChange={(e) => handleChangeInsuranceData("otherName", e.target.value)}
                                            error={!!insuranceData?.otherName && !intake?.copayInfo?.isCopayCollected}
                                            disabled={intake?.copayInfo?.isCopayCollected && !intake?.isCoPayCollectedInRegForm}
                                        />
                                        {insuranceData?.otherName && !intake?.copayInfo?.isCopayCollected && (
                                            <FormHelperText error={!!insuranceData?.otherName}>{t('PatientIntake.OtherInsuranceErrorMsg')}</FormHelperText>
                                        )}
                                    </Grid>

                                    <Grid item sm={6} xs={12} marginY={1} >
                                        <InputMask
                                            mask="(999) 999-9999"
                                            value={insuranceData?.phoneNumber}
                                            onChange={(e) => { handleChangeInsuranceData("phoneNumber", e.target.value); setIsPhoneNumValid(true) }}
                                            onBlur={handlePhoneMask}
                                            disabled={intake?.copayInfo?.isCopayCollected && !intake?.isCoPayCollectedInRegForm}
                                        >
                                            {() => <TextField
                                                label={t('PatientIntake.InsurancePhoneNumber')}
                                                name="phoneNumber"
                                                className="form-item"
                                                type="text"
                                                size="small"
                                                fullWidth
                                                disabled={intake?.copayInfo?.isCopayCollected && !intake?.isCoPayCollectedInRegForm}
                                            />
                                            }

                                        </InputMask>
                                        {!isPhoneNumValid && (
                                            <FormHelperText error={!isPhoneNumValid}>{t('PatientIntake.InvalidPhoneNumber')}</FormHelperText>
                                        )}
                                    </Grid>

                                    <Grid item sm={6} xs={12} marginY={1} >
                                        <TextField
                                            label={t('PatientIntake.InsuranceFullAddress')}
                                            className="form-item"
                                            size="small"
                                            fullWidth
                                            value={insuranceData?.address}
                                            name="address"
                                            onChange={(e) => handleChangeInsuranceData("address", e.target.value)}
                                            disabled={intake?.copayInfo?.isCopayCollected && !intake?.isCoPayCollectedInRegForm}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12} marginY={1}>
                                        <TextField
                                            label={t('PatientIntake.InsuranceSubscriberId')}
                                            className="form-item"
                                            size="small"
                                            fullWidth
                                            required
                                            value={insuranceData?.subscriberId}
                                            name="subscriberId"
                                            onChange={(e) => handleChangeInsuranceData("subscriberId", e.target.value)}
                                            disabled={intake?.copayInfo?.isCopayCollected && !intake?.isCoPayCollectedInRegForm}
                                        />
                                    </Grid>

                                    <Grid item sm={6} xs={12} marginY={1}>
                                        <TextField
                                            label={t('PatientIntake.InsuranceGroupNumber')}
                                            className="form-item"
                                            size="small"
                                            fullWidth
                                            required
                                            value={insuranceData?.groupNumber}
                                            name="groupNumber"
                                            onChange={(e) => handleChangeInsuranceData("groupNumber", e.target.value)}
                                            disabled={intake?.copayInfo?.isCopayCollected && !intake?.isCoPayCollectedInRegForm}
                                        />
                                    </Grid>

                                    <Grid item sm={6} xs={12} marginY={1}>
                                        <TextField
                                            label={t('PatientIntake.InsuranceSubscriberName')}
                                            className="form-item"
                                            size="small"
                                            fullWidth
                                            required
                                            value={insuranceData?.subscriberName}
                                            name="subscriberName"
                                            onChange={(e) => handleChangeInsuranceData("subscriberName", e.target.value)}
                                            disabled={intake?.copayInfo?.isCopayCollected && !intake?.isCoPayCollectedInRegForm}
                                        />
                                    </Grid>

                                    <Grid item sm={6} xs={12} marginY={1}>
                                        <TextField
                                            label={t('PatientIntake.InsuranceMemberId')}
                                            className="form-item"
                                            size="small"
                                            fullWidth
                                            required
                                            value={insuranceData?.memberId}
                                            name="memberId"
                                            onChange={(e) => handleChangeInsuranceData("memberId", e.target.value)}
                                            disabled={intake?.copayInfo?.isCopayCollected && !intake?.isCoPayCollectedInRegForm}
                                        />
                                    </Grid>
                                    <Grid item xs={12} marginBottom={4} className={classes.flex}>
                                        <Button
                                            type="button"
                                            className={classes.backButton}
                                            variant="outlined"
                                            onClick={() => intake?.setShowInsuranceForm(false)}
                                        >
                                            <ArrowBack fontSize="small" className={classes.backArrowBtn} /> {t('PatientIntake.Back')}
                                        </Button>
                                        <BrandingButton
                                            type="button"
                                            className={classes.nextButton}
                                            onClick={handleSaveInsuranceData}
                                        >
                                            {t('PatientIntake.Next')}<ArrowForward fontSize="small" className={classes.arrowBtn} />
                                        </BrandingButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>}
                </Grid>
            </Box>
            <AppointmentInfoModal
                show={showApptInfoModal}
                onClose={() => setShowApptInfoModal(false)}
            />
        </Grid>
    )
}

export default InsurancePage;