import {
  handleResponse,
  handleError,
  getEndpoint,
  getHeaderInfo,
  getSubDomain,
} from './apiUtils';

const createPayUrl = params => {
  return getEndpoint('payment/pay', params);
};

const handleCreateResponse = async response => {
  const { status } = response;
  const jsonData = response.json();
  if (response.ok) return jsonData;
  if (status === 400 || status === 422) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message,
    // so parse as text instead of json.

    return jsonData;
  }
  throw new Error('Network response was not ok.');
};

export const createPay = async formData => {
  const { method, headers } = getHeaderInfo('POST');
  const fixedHeaders = {
    ...headers,
    'Content-Type': 'application/vnd.api+json',
  };
  const params = {
    method,
    headers: fixedHeaders,
  };
  return fetch(createPayUrl(formData), params)
    .then(handleCreateResponse)
    .catch(e => {
      console.error(`API call failed. ${e}`);
    });
};

export const getPaymentSettings = facilityPage => {
  const parmalink =
    localStorage.getItem('isFacilityPage') === 'true'
      ? facilityPage
      : getSubDomain();
  return fetch(
    getEndpoint(`payment/settings/`, {
      url_permalink: parmalink,
    }),
    getHeaderInfo(),
  )
    .then(handleResponse)
    .catch(handleError);
};
