import { handleResponse, handleError } from './apiUtils';
const geocodingApiUrl = (zipcode, countryCode) =>
  `https://maps.googleapis.com/maps/api/geocode/json?components=country:${countryCode}|locality:${zipcode}&key=${process.env.REACT_APP_GOOGLE_LOCATION_SERVICE_API_KEY}`;
const reverseGeocodingApiUrl = (lat, lng) =>
  `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_LOCATION_SERVICE_API_KEY}&location_type=ROOFTOP`;

export function getReverseGeocoding(lat, lng) {
  return fetch(reverseGeocodingApiUrl(lat, lng))
    .then(handleResponse)
    .catch(handleError);
}

export async function getGeocoding(zipcode) {
  const countryCode =
    localStorage.getItem('countryCode') === undefined ||
    localStorage.getItem('countryCode') === '' ||
    localStorage.getItem('countryCode') === null
      ? 'US'
      : localStorage.getItem('countryCode');
  let zipCodes = '';
  if (zipcode !== undefined && zipcode.indexOf(',') > -1) {
    zipCodes = `${zipcode.split(',')[0].trim()},${zipcode
      .split(',')[1]
      .trim()}`;
  } else zipCodes = zipcode;
  if (zipCodes !== undefined)
    return fetch(geocodingApiUrl(zipCodes, countryCode))
      .then(handleResponse)
      .catch(handleError);
  return {
    results: [],
  };
}

export async function getPlaces(searchTerm, stateFunc) {
  if (window.google && searchTerm !== '') {
    const service = new window.google.maps.places.AutocompleteService();
    const countryCode =
      localStorage.getItem('countryCode') === undefined ||
      localStorage.getItem('countryCode') === '' ||
      localStorage.getItem('countryCode') === null
        ? 'US'
        : localStorage.getItem('countryCode');

    service.getPlacePredictions(
      {
        input: searchTerm,
        types: ['(regions)'],
        componentRestrictions: { country: [countryCode] },
      },
      (results, status) => {
        if (status === 'OK') {
          const formattedOptions = results.map(p => {
            return {
              text: p.description,
              id: p.id,
              zipcode: p.description,
              terms: p?.terms,
            };
          });
          stateFunc(formattedOptions);
        }
      },
    );
  }
}
