/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core';
import { BlackTheme } from 'components/form/FormElements';
import { Form } from 'react-bootstrap';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import { getAppointmentTypePayments } from 'api/appointmentTypesApi';
import { useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import { BrandingContext } from '../contexts/BrandingContext';
import { RegistrationFormContext } from './RegistrationFormProvider';
import AddressFields from './registrationForm/AddressFields';
import BirthDateFields from './registrationForm/BirthDateFields';
import BrandingButton from '../common/BrandingButton';
import CommentFields from './registrationForm/CommentFields';
import ContactNameFields from './registrationForm/ContactNameFields';
import EmailFields from './registrationForm/EmailFields';
import EmployerPatientFields from './registrationForm/EmployerPatientFields';
import GenderFields from './registrationForm/GenderFields';
import HasPhysicianFields from './registrationForm/HasPhysicianFields';
import InsurancePlanFields from './registrationForm/InsurancePlanFields';
import InsuranceGroupMemberFields from './registrationForm/InsuranceGroupMemberFields';
import LastTestFields from './registrationForm/LastTestFields';
import NameFields from './registrationForm/NameFields';
import NewPatientFields from './registrationForm/NewPatientFields';
import PatientComplaintFields from './registrationForm/PatientComplaintFields';
import PatientDescriptionFields from './registrationForm/PatientDescriptionFields';
import PhoneNumberFields from './registrationForm/PhoneNumberFields';
import PhysicianNameFields from './registrationForm/PhysicianNameFields';
import PregnantFields from './registrationForm/PregnantFields';
import ReferringFacilityFields from './registrationForm/ReferringFacilityFields';
import ReferringPhysicianFields from './registrationForm/ReferringPhysicianFields';
import SSNFields from './registrationForm/SSNFields';
import StandingAssistanceFields from './registrationForm/StandingAssistanceFields';
import TermsAndConditions from './registrationForm/TermsAndConditions';
import TranslatorFields from './registrationForm/TranslatorFields';
import { useTranslation } from 'react-i18next';
import StripeForm from './registrationForm/StripeForm';
import DialogMessage from './registrationForm/DialogMessage';
import { getCurrency } from 'utils/helpers';
import { createPay, getPaymentSettings } from 'api/paymentApi';
import { getSubDomain } from 'api/apiUtils';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const RegistrationForm = ({
  appointmentType,
  handleErrors,
  handleScreeningQuestions,
  scheduleId,
  scheduleLocation,
  screeningQuestionsAnswers,
  zipcode,
  paymentMessage,
  isScreeningQuestionsShowing,
}) => {
  const { branding } = useContext(BrandingContext);
  const formContext = useContext(RegistrationFormContext);
  const [t] = useTranslation();
  const location = useLocation();
  const params = QueryString.parse(location.search);
  const [viewAlert, setViewAlert] = useState(false);
  const [paymentRequired, setpaymentRequired] = useState(false);
  const [paymentTransactionId, setPaymentTransactionId] = useState();
  const [hasCouponCode, setHasCouponCode] = useState(false);
  const [couponCode, setCouponCode] = useState();
  const [paymentChoice, setPaymentChoice] = useState();
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [appointmentCost, setAppointmentCost] = useState(0);
  const [appointmentCurrency, setAppointmentCurrency] = useState('USD');
  const [paymentCreated, setpaymentCreated] = useState(false);
  const [paymentAuthorized, setPaymentAuthorized] = useState(false);
  const [hsPaymentMode, setHsPaymentMode] = useState('');
  const [viewCloseAlert, setViewCloseAlert] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [payCalled, setPayCalled] = useState(false);
  const callPay = async () => {
    setPayCalled(true);
    const paymentParams = {
      schedule_id: scheduleId,
    };
    if (appointmentType.id) {
      paymentParams.appointment_type_id = appointmentType.id;
    }
    const { response } = await createPay(paymentParams);
    if (response?.session) {
      formContext.handleSetFormState({
        paymentTransactionId: response.session.transactionId,
        providerSessionSecret: response.session.providerSessionSecret,
        publishableKey: response.session.publishableKey,
        providerUserId: response.session.providerUserId,
      });
      setpaymentCreated(true);
      setPaymentTransactionId(response.session.transactionId);
      setPaymentOpen(true);
    }
  };

  useEffect(() => {
    handleErrors(formContext.formErrors);
    if (
      paymentRequired &&
      formContext.formErrors.length === 1 &&
      formContext.formErrors[0].title === 'payment-transaction-id-missing' &&
      paymentCreated === false &&
      canceled === false
    ) {
      if (!payCalled) callPay();
    }
    if (
      paymentRequired &&
      paymentCreated &&
      formContext.formErrors.length === 1 &&
      formContext.formErrors[0].message.includes('requires_payment_method') &&
      canceled === false
    ) {
      setPaymentOpen(true);
    }
    if (
      paymentRequired &&
      paymentAuthorized &&
      formContext.formErrors.length === 1 &&
      // formContext.formErrors[0].title.includes('is taken') ||
      formContext.formErrors[0].title.includes('appointment-at') &&
      canceled === false
    ) {
      setPaymentOpen(false);
    }
  }, [
    formContext.formErrors,
    paymentRequired,
    paymentCreated,
    paymentAuthorized,
  ]);

  useEffect(() => {
    (async () => {
      if (appointmentType && scheduleId) {
        await formContext.getVisitSettings(appointmentType, scheduleId);
        if (appointmentType?.has_coupon_code) {
          if (localStorage.getItem('code')) {
            setCouponCode(localStorage.getItem('code'));
          }
          setHasCouponCode(true);
        }
      }
    })();

    return () => {
      formContext.resetFields();
    };
  }, [appointmentType, scheduleId]);

  useEffect(() => {
    formContext.handleSetFormState({
      couponCode: { value: couponCode },
      hasCouponCode: { value: hasCouponCode },
    });
  }, [couponCode]);

  useEffect(() => {
    formContext.handleSetFormState({ zip: { value: zipcode } });
  }, [zipcode]);

  useEffect(() => {
    if (formContext.screeningQuestions) {
      handleScreeningQuestions(formContext.screeningQuestions);
    }
  }, [formContext.screeningQuestions]);

  const fetchApptPayment = async () => {
    const apptId = params.appointmentTypes;
    const scheduleId = location.pathname.split('/')[2];
    if (scheduleId) {
      try {
        const res = await getAppointmentTypePayments(apptId, scheduleId);
        if (
          res &&
          res.response &&
          res.response.items &&
          res.response.items.length > 0
        ) {
          const data = res.response.items[0];
          setAppointmentCurrency(getCurrency(data.currency));
          setAppointmentCost(data.appointmentFeeAmount);
        }
        const { response } = await getPaymentSettings(
          localStorage.getItem('facilityPage'),
        );
        setViewAlert(false);
        setHsPaymentMode('disabled');
        if (
          response['visit.payment'] &&
          response['visit.payment'] === 'enabled'
        ) {
          setHsPaymentMode('enabled');
          setViewAlert(true);
        }

        if (
          response['visit.payment'] &&
          response['visit.payment'] === 'required'
        ) {
          setpaymentRequired(true);
          setHsPaymentMode('required');
          setViewAlert(true);
          setPaymentChoice('PAYNOW');
        }
      } catch (err) {
        console.error('Get payment Error => ', err);
      }
    }
  };
  useEffect(() => {
    fetchApptPayment();
  }, []);
  let locationName = '';
  let timezoneName = '';

  if (!isEmpty(scheduleLocation)) {
    locationName = scheduleLocation.attributes.name;
    timezoneName = scheduleLocation.attributes.timeZone;
  }

  const toggleViewAlert = from => {
    setViewAlert(!viewAlert);
    setpaymentRequired(from);
  };
  const toggleCloseViewAlert = from => {
    setViewCloseAlert(!viewCloseAlert);
    setpaymentRequired(from);
  };
  const renderStripe = e => {
    const stripePromise = loadStripe(formContext.form.publishableKey, {
      stripeAccount: formContext.form.providerUserId,
    });
    return (
      <Elements stripe={stripePromise}>
        <StripeForm
          show={paymentOpen && paymentRequired}
          toggleModal={e => {
            setPaymentOpen(false);
            setViewCloseAlert(true);
          }}
          formContext={formContext}
          appointmentCurrency={appointmentCurrency}
          appointmentCost={appointmentCost}
          handleClose={e => {
            setPaymentAuthorized(false);
            setViewAlert(true);
          }}
          callVisit={async e => {
            // e.preventDefault();
            const urlPermalink =
              localStorage.getItem('isFacilityPage') === 'true'
                ? localStorage.getItem('facilityPage')
                : getSubDomain();
            await formContext.submitRegistrationForm(
              appointmentType.id,
              scheduleId,
              scheduleLocation,
              screeningQuestionsAnswers,
              paymentChoice,
              paymentTransactionId,
              urlPermalink,
              hasCouponCode,
              couponCode,
            );
            setPaymentAuthorized(true);
          }}
        />
      </Elements>
    );
  };
  return (
    <MuiThemeProvider theme={BlackTheme}>
      <Form>
        <div
          className="form-section"
          style={{ borderColor: branding.primaryColor }}
        >
          {paymentRequired && (
            <div className="form-section_row">
              <div className="label">{t('RegistrationForm.appointmentCost')}</div>
              <div className="text">
                {`Appointment fee in the amount of ${appointmentCurrency}${appointmentCost} will be charged to your credit card.`}
              </div>
            </div>
          )}
          {!isEmpty(appointmentType) && (
            <div className="form-section_row">
              <div className="label">
                {t('RegistrationForm.appointmentType')}
              </div>
              <div className="text">{appointmentType.name}</div>
            </div>
          )}

          {formContext.dateTime && (
            <div className="form-section_row">
              <div className="label">
                {t('RegistrationForm.appointmentTime')}
              </div>
              <div className="text">
                {moment(formContext.dateTime)
                  .tz(timezoneName)
                  .format('dddd, MMMM DD - h:mm A')}
              </div>
            </div>
          )}

          <PatientComplaintFields />
          <PhysicianNameFields />
          <NewPatientFields locationName={locationName} />
          <ReferringFacilityFields />
          <HasPhysicianFields />
          <ReferringPhysicianFields />
          <LastTestFields />
          <GenderFields />
          <PatientDescriptionFields />
          <PregnantFields />
          <InsurancePlanFields scheduleId={scheduleId} />
          <InsuranceGroupMemberFields />
          <CommentFields />
        </div>

        <div
          className="form-section"
          style={{ borderColor: branding.primaryColor }}
        >
          <NameFields />
          <EmailFields />
          <PhoneNumberFields />
          <BirthDateFields />
          <SSNFields />
        </div>

        <div
          className="form-section"
          style={{ borderColor: branding.primaryColor }}
        >
          <AddressFields />
          <ContactNameFields />
          <TranslatorFields />
          <EmployerPatientFields />
          <StandingAssistanceFields />
          <TermsAndConditions />
        </div>
        {formContext.form.publishableKey && renderStripe()}

        {viewAlert && !isScreeningQuestionsShowing && (
          <DialogMessage
            show={viewAlert}
            title={t('paymentModal.titlePaymentInformation')}
            message={
              paymentRequired
                ? paymentMessage === 'visit'
                  ? t('paymentModal.visitInitialCostMessage').replace('{appointmentCurrency}', `${appointmentCurrency}`).replace('{appointmentCost}', `${appointmentCost}`)
                  : t('paymentModal.visitProviderRequiresPaymentMessage').replace('{paymentMessage}', `${paymentMessage}`).replace('{paymentMessage}', `${paymentMessage}`).replace('{appointmentCurrency}', `${appointmentCurrency}`).replace('{appointmentCost}', `${appointmentCost}`)
                : paymentMessage === 'visit'
                  ? t('paymentModal.visitInitialCostMessage').replace('{appointmentCurrency}', `${appointmentCurrency}`).replace('{appointmentCost}', `${appointmentCost}`)
                  : t('paymentModal.visitPayNowORLaterMessage').replace('{appointmentCurrency}', `${appointmentCurrency}`).replace('{appointmentCost}', `${appointmentCost}`).replace('{paymentMessage}', `${paymentMessage}`)
            }
            primaryBtnText={!paymentRequired ? t('paymentModal.btnPayNow') : t('paymentModal.btnOK')}
            primaryBtnClick={e => {
              toggleViewAlert(true);
              setPaymentChoice('PAYNOW');
            }}
            secondryBtnText={!paymentRequired ? t('paymentModal.btnPayLater') : null}
            secondryBtnClick={e => {
              toggleViewAlert(false);
              setPaymentChoice('PAYLATER');
            }}
          />
        )}

        {viewCloseAlert && (
          <DialogMessage
            show={viewCloseAlert}
            title={t('paymentModal.titlePaymentInformation')}
            message={
              hsPaymentMode === 'required'
                ? t('paymentModal.visitProviderRequiresPayMessage').replace('{paymentMessage}', `${paymentMessage}`).replace('{paymentMessage}', `${paymentMessage}`)
                : t('paymentModal.visitContinueBookingMessage').replace('{paymentMessage}', `${paymentMessage}`)
            }
            primaryBtnText={hsPaymentMode === 'enabled' ? t('paymentModal.btnPayNow') : t('paymentModal.btnOK')}
            primaryBtnClick={e => {
              toggleCloseViewAlert(true);
              setPaymentOpen(false);
              setPaymentChoice('PAYNOW');
            }}
            secondryBtnText={hsPaymentMode === 'enabled' ? t('paymentModal.btnPayLater') : null}
            secondryBtnClick={e => {
              toggleCloseViewAlert(false);
              setPaymentChoice('PAYLATER');
              setPaymentTransactionId(null);
            }}
          />
        )}

        <BrandingButton
          className="submit-btn"
          type="submit"
          onClick={e => {
            e.preventDefault();
            setCanceled(false);
            const urlPermalink =
              localStorage.getItem('isFacilityPage') === 'true'
                ? localStorage.getItem('facilityPage')
                : getSubDomain();
            formContext.submitRegistrationForm(
              appointmentType.id,
              scheduleId,
              scheduleLocation,
              screeningQuestionsAnswers,
              paymentChoice,
              paymentTransactionId,
              urlPermalink,
              hasCouponCode,
              couponCode,
            );
          }}
        >
          {t('RegistrationForm.bookIt')}
        </BrandingButton>
      </Form>
    </MuiThemeProvider>
  );
};

RegistrationForm.propTypes = {
  appointmentCostLabel: PropTypes.string,
  appointmentTimeLabel: PropTypes.string,
  appointmentType: PropTypes.instanceOf(Object).isRequired,
  appointmentTypeLabel: PropTypes.string,
  handleErrors: PropTypes.func.isRequired,
  handleScreeningQuestions: PropTypes.func.isRequired,
  scheduleId: PropTypes.string.isRequired,
  scheduleLocation: PropTypes.instanceOf(Object).isRequired,
  screeningQuestionsAnswers: PropTypes.instanceOf(Array).isRequired,
  zipcode: PropTypes.string.isRequired,
  submitButtonLabel: PropTypes.string.isRequired,
  paymentMessage: PropTypes.string.isRequired,
  isScreeningQuestionsShowing: PropTypes.bool.isRequired,
};

export default RegistrationForm;
