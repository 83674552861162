import { useEffect, useState } from 'react';

/**
 * This hook contains the logic to display the Filter Section (FS)
 * On Desktop, this is a Sidebar on the InventoryPage.
 * On Mobile, this is a sticky footer on the bottom of the Inventory Page.
 * @param {Boolean} searched Flag set by the page when a search is performed.
 * @param {Array} results Array of results. If no results, expect empty array.
 * @param {Array} filterOperations Array of filter operations.
 * @return {Boolean}
 */
function useFilterSectionDisplayStatus(
  searched,
  results = [],
  filterOperations = [],
) {
  const [isFSVisible, setIsFSVisible] = useState(false);

  useEffect(() => {
    // No search performed, FS not visible.
    if (!searched) {
      setIsFSVisible(false);
    }
    // Search performed and there are results: FS visible.
    if (searched && results.length > 0) {
      setIsFSVisible(true);
    }
    // Search performed, but no results, FS not visible.
    if (searched && results.length === 0) {
      setIsFSVisible(false);
    }

    // Search performed w filter applied & no results: FS visible.
    // Search performed w filter applied & results: FS visible.
    if (searched && filterOperations.length > 0) {
      setIsFSVisible(true);
    }

    return () => {
      setIsFSVisible(false);
    };
  }, [searched, results, filterOperations]);

  return isFSVisible;
}

export default useFilterSectionDisplayStatus;
