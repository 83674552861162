import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import PageNotFound from 'components/PageNotFound';
import BreadCrumbs from 'components/common/BreadCrumbs';
import './ConfirmationPage.scss';
import { connect, useSelector } from 'react-redux';
import { fetchVisit } from 'redux/actions/visitsActions';
import ConfirmationSection from './ConfirmationSection';
import ConfirmationFormio from '../customTemplates/ConfirmationFormio';
import PageMetaInfo from '../common/PageMetaInfo';

const ConfirmationPage = ({ match, fetchVisit }) => {
  const historyLocation = useLocation();
  const [location, setLocation] = useState({});
  const [provider, setProvider] = useState({});
  const [number, setNumber] = useState();
  const [showCustomTemplates, setShowCustomTemplates] = useState(false);
  const [timezoneName, setTimezoneName] = useState();
  const healthSystem = useSelector(state => state.healthSystem);
  const visit = useSelector(state => state.visitReducer);

  useEffect(() => {
    (async () => {
      if (
        visit?.data === null ||
        match.params.confirmationId !== visit?.data?.attributes['secure-id']
      ) {
        await fetchVisit(match.params.confirmationId);
      }
    })();
  }, [match.params.confirmationId]);

  useEffect(() => {
    if (visit && visit.data !== null && visit.data !== 'loaded') {
      // Visits have one location and one provider, so find them
      // by type instead of by id.
      const loc = visit?.included.find(l => l.type === 'locations');
      setLocation(loc.attributes);

      // setting timezone
      const zoneName = loc.attributes['time-zone'];
      setTimezoneName(zoneName);

      const providerData = visit.included.find(p => p.type === 'providers');
      if (!isEmpty(providerData)) {
        setProvider(providerData);
      }
      setNumber(visit.data.attributes['confirmation-number']);
    }
  }, [visit]);

  useEffect(() => {
    if (healthSystem && Object.keys(healthSystem).length) {
      if (healthSystem['custom-template']) {
        setShowCustomTemplates(healthSystem['custom-template'] === 'on');
      }
    }
  }, [healthSystem]);

  useEffect(() => {
    if (historyLocation.state && historyLocation.state.hasCouponCode) {
      localStorage.removeItem('code');
    }
  }, [historyLocation]);

  let pageTitle = '';

  if (healthSystem.region !== null && healthSystem.region !== undefined) {
    pageTitle = `Visit Confirmation - ${healthSystem.region.name}`;
  } else {
    pageTitle = `Visit Confirmation - ${healthSystem.name}`;
  }

  let pageDesc = '';
  if (healthSystem.region !== null && healthSystem.region !== undefined) {
    pageDesc = `Book an appointment online for ${healthSystem.region.name} emergency rooms, urgent care centers, primary care physicians, and more`;
  } else {
    pageDesc = `Book an appointment online for ${healthSystem.name} emergency rooms, urgent care centers, primary care physicians, and more`;
  }

  if (visit.data === 'loaded') return <PageNotFound />;
  if (visit.data === null || !timezoneName) return <></>;

  return (
    <main className="ConfirmationPage container">
      <PageMetaInfo title={pageTitle} description={pageDesc} />
      <BreadCrumbs onlyHome />
      {!showCustomTemplates && (
        <ConfirmationSection
          number={number}
          location={location}
          provider={provider}
          timezoneName={timezoneName}
          visit={visit}
        />
      )}

      {showCustomTemplates && (
        <ConfirmationFormio
          visitId={visit.data.id}
          location={location}
          provider={provider}
          timezoneName={timezoneName}
          visit={visit}
        />
      )}
    </main>
  );
};

ConfirmationPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      confirmationId: PropTypes.string,
      scheduleId: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  fetchVisit: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    healthSystem: state.healthSystem,
  };
}

const mapDispatchToProps = {
  fetchVisit,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationPage);
