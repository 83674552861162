import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomTextField from '../CustomTextField';

const PhysicianNameFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const [t] = useTranslation();
  return (
    <>
      {formContext.isFieldEnabled('referring-provider-name') && (
        <div className="form-section_row">
          <CustomTextField
            fieldKey="referringPhysician"
            fieldValue={formContext.form.referringPhysician}
            validateFormHandler={formContext.handleSetFormState}
            label={t('PhysicianNameFields.referringPhysicianName')}
            required={formContext.isFieldRequired('referring-provider-name')}
          />
        </div>
      )}
    </>
  );
};

export default PhysicianNameFields;
