import { isEmpty } from 'lodash';
import {
  ER_SERVICE_NAMES,
  UC_SERVICE_NAMES,
  PAGE_TITLE_SERVICE_EXCLUSIONS,
} from 'config/searchConstants';

/**
 * Generate html meta page title
 * @param {Object} healthSystem
 * @param {Object} provider
 * @param {String} serviceName
 * @returns String
 */
export function getPageTitle(healthSystem, provider, serviceName) {
  let pageTitle = '';
  // If any of these conditions are true, we need to return the 'generic' title.
  const forceGeneralTitle =
    ER_SERVICE_NAMES.includes(serviceName) ||
    UC_SERVICE_NAMES.includes(serviceName) ||
    PAGE_TITLE_SERVICE_EXCLUSIONS.includes(serviceName) ||
    isEmpty(provider);

  if (forceGeneralTitle) {
    if (healthSystem.region !== undefined && healthSystem.region !== null) {
      return `Online Healthcare Scheduling at ${healthSystem.region.name}`;
    }
    return `Online Healthcare Scheduling at ${healthSystem.name}`;
  }

  pageTitle = provider.name;
  if (!isEmpty(provider.suffix)) {
    pageTitle += `, ${provider.suffix}`;
  }
  return pageTitle;
}

/**
 * Generate html meta page description
 * @param {Object} healthSystem
 * @param {Object} provider
 * @param {String} serviceName
 * @returns String
 */
export function getPageDescription(healthSystem, provider, serviceName) {
  // If any of these conditions are true, we need to return the 'generic' title.
  const forceGeneralDescription =
    ER_SERVICE_NAMES.includes(serviceName) || isEmpty(provider);

  if (forceGeneralDescription) {
    if (ER_SERVICE_NAMES.includes(serviceName)) {
      return `Book a visit online for ${healthSystem.name}`;
    }
    return `Book an appointment online for ${healthSystem.name}`;
  }

  let providerText = provider.name;
  if (!isEmpty(provider.suffix)) {
    providerText += `, ${provider.suffix}`;
  }
  return `Book an appointment online with ${providerText} today. Enjoy the control and convenience of online scheduling today.`;
}
