import normalize from 'json-api-normalizer';
import { getVenuesOfCare } from 'api/venuesOfCareApi';
import * as types from './actionTypes';
import { beginApiCall, apiCallError } from './apiStatusActions';

const beginLoadVenuesOfCare = () => {
  return { type: types.BEGIN_FETCH_VENUES_OF_CARE };
};

export function loadVenuesOfCareSuccess(venuesOfCare, included) {
  return { type: types.FETCH_VENUES_OF_CARE_SUCCESS, venuesOfCare, included };
}

export function loadVenuesOfCare() {
  // eslint-disable-next-line func-names
  return async function(dispatch) {
    dispatch(beginApiCall());
    dispatch(beginLoadVenuesOfCare());
    try {
      const data = await getVenuesOfCare();
      const { venueOfCares } = normalize(data);
      const { included } = data;
      dispatch(loadVenuesOfCareSuccess(venueOfCares, included));
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}
