// Search inputs
export const ZIP_CODE_INPUT = 'ZIP_CODE_INPUT';
export const DOCTOR_NAME_SPECIALITY_INPUT = 'DOCTOR_NAME_SPECIALITY_INPUT';
export const APP_TYPE_INPUT = 'APP_TYPE_INPUT';
export const INSURANCE_INPUT = 'INSURANCE_INPUT';
export const DATE_TIME_INPUT = 'DATE_TIME_INPUT';

// Filter items
export const SERVICES = 'SERVICES';
export const GENDERS = 'GENDERS';
export const LANGUAGES = 'LANGUAGES';
export const HOSPITAL_AFFILIATIONS = 'HOSPITAL_AFFILIATIONS';
export const TAKING_NEW_PATIENTS = 'TAKING_NEW_PATIENTS';

export const ER_SERVICE_NAMES = [
  'Emergency Room',
  'EMERGENCY-ROOM',
  'EMERGENCY-DEPARTMENT',
  'EMERGENCY DEPARTMENT',
  'Emergency Department',
  'Emergency-Department',
  'Emergency Medicine',
  'Pediatric Emergency Room',
  'Pediatric Emergency',
  'Pediatric Emergency Medicine',
  'ER-UC',
  'ER/Urgent Care',
];

export const UC_SERVICE_NAMES = [
  'Urgent Care',
  'IMMEDIATE CARE',
  'Immediate Care',
  'Walk-in',
  'WALK-IN',
];

export const PAGE_TITLE_SERVICE_EXCLUSIONS = [
  'TELEHEALTH',
  'Telehealth',
  'IMMEDIATE CARE',
  'Immediate Care',
  'Walk-in',
  'WALK-IN',
];

export const serviceLineGroupsToMapVOC = [
  { GROUP_A: 'physician-services' },
  { GROUP_D: 'physician-services' },
  { GROUP_E: 'physician-services' },
  { GROUP_F: 'physician-services' },
  { GROUP_G: 'physician-services' },
  { GROUP_B: 'emergency-room' },
  { GROUP_C: 'urgent-care' },
];

export const timeZones = [
  { key: 'Central Time (US & Canada)', value: 'America/Chicago' },
  { key: 'Eastern Time (US & Canada)', value: 'America/New_York' },
  { key: 'Mountain Time (US & Canada)', value: 'America/Denver' },
  { key: 'Pacific Time (US & Canada)', value: 'America/Los_Angeles' },
  { key: 'Hawaii', value: 'Pacific/Honolulu' },
  { key: 'Alaska', value: 'America/Juneau' },
];
