import { useLocation, useHistory, useParams } from 'react-router-dom';
import QueryString from 'query-string';

export const useQueryString = () => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const urlParams = useParams();
  const params = QueryString.parse(search);

  const isDischargePage =
    pathname.split('/').includes('discharge') && !!urlParams.dischargeFacility;

  const setQueryParams = obj => {
    const search = QueryString.stringify({ ...obj });
    history.replace({ pathname, search });
  };

  const setQueryParamsFromObject = paramsObj => {
    setQueryParams({
      ...params,
      ...paramsObj,
    });
  };

  return {
    history,
    isDischargePage,
    params,
    pathname,
    search,
    setQueryParamsFromObject,
    urlParams,
  };
};
