import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import './BaseModal.scss';

const headerStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
};

const BaseModal = ({ show, onClose, preventEarlyExit, children }) => {
  return (
    <Modal
      overlayClassName="BaseModalOverlay"
      className="BaseModal"
      isOpen={show}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={!preventEarlyExit}
      shouldCloseOnEsc={!preventEarlyExit}
      ariaHideApp={false}
    >
      <div style={headerStyles}>
        {!preventEarlyExit && (
          <button className="btn-plain" type="button" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} style={{ color: 'black' }} />
          </button>
        )}
      </div>
      {children}
    </Modal>
  );
};

BaseModal.propTypes = {
  show: PropTypes.bool.isRequired,
  preventEarlyExit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

BaseModal.defaultProps = {
  preventEarlyExit: false,
};

export default BaseModal;
