import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function uiReducer(state = initialState.ui, action) {
  switch (action.type) {
    case types.CLEAR_NOTIFICATION:
      return { ...state, notification: initialState.ui.notification };
    case types.DISPLAY_NOTIFICATION:
      return { ...state, notification: { ...action.notification, show: true } };
    default:
      return state;
  }
}
