import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  makeStyles,
  MenuItem,
  Select,
  useTheme,
} from '@material-ui/core';
import Container from 'react-bootstrap/Container';
import { isEmpty } from 'lodash';
import BreadCrumbs from 'components/common/BreadCrumbs';
import { useTranslation, Trans } from 'react-i18next';
import { BrandingContext } from 'components/contexts/BrandingContext';
import EducationalInventory from 'components/educational/EducationalInventory';
import EducationalNowResult from 'components/educational/EducationalNowResult';
import EducationalResultSkeleton from 'components/educational/EducationalResultSkeleton';
import Search from 'components/inventory/Search';
import { loadVenuesOfCare } from 'redux/actions/venuesOfCareActions';
import { fetchHealthSystem } from 'redux/actions/healthSystemActions';
import {
  applySearchFilters,
  resetSearchFilters,
  loadScheduleTimes,
} from 'redux/actions/searchActions';
import { sortVenues } from 'utils/sortVenues';
import './EducationalPage.scss';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { useQueryString } from 'hooks/useQueryString';

const EducationalNowPage = ({
  searched,
  selectedParams,
  venuesOfCare,
  loadVenuesOfCare,
  fetchHealthSystem,
}) => {
  const { search, pathname } = useLocation();
  const queryParams = queryString.parse(search);
  const [vocData, setVocData] = useState([]);
  const { params, setQueryParamsFromObject } = useQueryString();
  const [sortBy, setSortBy] = useState(params.sort || 'nearest');
  const { data: venuesData } = venuesOfCare;
  const { branding } = useContext(BrandingContext);
  const [t] = useTranslation();
  const theme = useTheme();
  const useStyles = makeStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    inputStyle: {
      background: '#fff',
      color: '#000',
      paddingLeft: '5px',
      paddingRight: '5px',
    },
  });
  const classes = useStyles();

  const healthSystem = useSelector(state => state.healthSystem);
  let MediaContentBlockBottom = '';
  let MediaContentBlockTop = '';
  let MediaTitle_Top = '';
  if (healthSystem.region !== null) {
    if (
      t('Education_RegionPage_MediaContentBlock_Top') !== '' &&
      t('Education_RegionPage_MediaContentBlock_Top') !==
      'Education_RegionPage_MediaContentBlock_Top'
    ) {
      MediaContentBlockTop = t('Education_RegionPage_MediaContentBlock_Top', { hs_name: healthSystem?.name, region_name: healthSystem?.region?.name });
    }
    if (
      t('Education_RegionPage_MediaContentBlock_Bottom') !== '' &&
      t('Education_RegionPage_MediaContentBlock_Bottom') !==
      'Education_RegionPage_MediaContentBlock_Bottom'
    ) {
      MediaContentBlockBottom = t(
        'Education_RegionPage_MediaContentBlock_Bottom', { hs_name: healthSystem?.name, region_name: healthSystem?.region?.name }
      );
    }
    if (
      t('Education_RegionPage_MediaTitle_Top') !== '' &&
      t('Education_RegionPage_MediaTitle_Top') !==
      'Education_RegionPage_MediaTitle_Top'
    ) {
      MediaTitle_Top = t(
        'Education_RegionPage_MediaTitle_Top', { hs_name: healthSystem?.name, region_name: healthSystem?.region?.name }
      );
    }
  } else {
    if (
      t('Education_HealthSystemPage_MediaContentBlock_Top') !== '' &&
      t('Education_HealthSystemPage_MediaContentBlock_Top') !=
      'Education_HealthSystemPage_MediaContentBlock_Top'
    ) {
      MediaContentBlockTop = t(
        'Education_HealthSystemPage_MediaContentBlock_Top', { hs_name: healthSystem?.name, region_name: healthSystem?.region?.name }
      );
    }
    if (
      t('Education_HealthSystemPage_MediaContentBlock_Bottom') !== '' &&
      t('Education_HealthSystemPage_MediaContentBlock_Bottom') !==
      'Education_HealthSystemPage_MediaContentBlock_Bottom'
    ) {
      MediaContentBlockBottom = t(
        'Education_HealthSystemPage_MediaContentBlock_Bottom', { hs_name: healthSystem?.name, region_name: healthSystem?.region?.name }
      );
    }
    if (
      t('Education_HealthSystemPage_MediaTitle_Top') !== '' &&
      t('Education_HealthSystemPage_MediaTitle_Top') !==
      'Education_HealthSystemPage_MediaTitle_Top'
    ) {
      MediaTitle_Top = t(
        'Education_HealthSystemPage_MediaTitle_Top', { hs_name: healthSystem?.name, region_name: healthSystem?.region?.name }
      );
    }
  }

  let { insurancePlan, appointmentTypeId } = selectedParams;

  const { appointmentTypes, insurancePlans, urgency } = queryParams;
  let searchType = queryParams.searchType || 'now';

  if (!appointmentTypeId && appointmentTypes) {
    appointmentTypeId = appointmentTypes;
  }
  if (!insurancePlan && insurancePlans) {
    insurancePlan = insurancePlans;
  }

  if (!searchType && urgency) {
    searchType = urgency;
  }

  useEffect(() => {
    if (!Object.keys(healthSystem).length > 0) {
      fetchHealthSystem();
    }
    localStorage.setItem('zipCode', '');
  }, []);

  // Load VOC on initial load
  useEffect(() => {
    (async () => {
      if (isEmpty(venuesData)) {
        loadVenuesOfCare();
      }
    })();
  }, [venuesData, loadVenuesOfCare]);

  useEffect(() => {
    if (!isEmpty(venuesData)) {
      setVocData(sortVenues(Object.values(venuesData)));
    }
  }, [venuesData]);

  const handleSortBy = event => {
    setSortBy(event.target.value);
    setQueryParamsFromObject({ sort: event.target.value });
  };

  const renderContent = () => {
    if (searched && searchType === 'now') {
      return (
        <>
          {vocData.length === 0 && (
            <>
              <LinearProgress />
              <EducationalResultSkeleton />
            </>
          )}
          <EducationalNowResult
            venue={
              vocData.find(
                v => v.attributes.venueType === 'physician_services',
              ) || {}
            }
            appointmentTypeId={appointmentTypeId}
            insuranceId={insurancePlan}
            coord={`${selectedParams.latitude},${selectedParams.longitude}`}
          />
          <EducationalNowResult
            venue={
              vocData.find(v => v.attributes.venueType === 'urgent_care') || {}
            }
            appointmentTypeId={appointmentTypeId}
            coord={`${selectedParams.latitude},${selectedParams.longitude}`}
          />
          <EducationalNowResult
            venue={
              vocData.find(v => v.attributes.venueType === 'emergency_room') ||
              {}
            }
            appointmentTypeId={appointmentTypeId}
            coord={`${selectedParams.latitude},${selectedParams.longitude}`}
          />
        </>
      );
    }
    if (searched && searchType === 'inventory') {
      return <EducationalInventory />;
    }
    return <></>;
  };

  return (
    <main className="EducationalPage">
      {MediaTitle_Top && (
        <div style={{ color: branding.primaryColor }} className="container">
          <Trans>
            <h1 className='locizeHeaderStyle'>{MediaTitle_Top}</h1>
          </Trans>
        </div>
      )}
      {MediaContentBlockTop && (
        <div style={{ color: branding.primaryColor }} className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="desctop" content={MediaContentBlockTop} />
          </Helmet>
          <Trans>
            <p className="locizePTagStyle">{MediaContentBlockTop}</p>
          </Trans>
        </div>
      )}
      <Container className="search-container">
        <Search uxMode="educational" />
        {searched && searchType === 'inventory' && <BreadCrumbs />}
        {isMobile && searched && searchType === 'inventory' && (
          <Grid item>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                id="sort-by-label-education-now"
                htmlFor="sort-by-select-education-now"
                className={classes.inputStyle}
              >
                {t('InventoryControl.sort')}
              </InputLabel>
              <Select
                id="sort-by-select-education-now"
                labelId="sort-by-label-education-now"
                value={sortBy}
                onChange={handleSortBy}
              >
                <MenuItem value="soonest">
                  {t('InventoryControls.soonest')}
                </MenuItem>
                <MenuItem value="nearest">
                  {t('InventoryControls.closest')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
      </Container>
      <Container>{renderContent()}</Container>
      {MediaContentBlockBottom && (
        <div style={{ color: branding.primaryColor }} className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="desctop" content={MediaContentBlockBottom} />
          </Helmet>
          <Trans>
            <p className="locizePTagStyle">{MediaContentBlockBottom}</p>
          </Trans>
        </div>
      )}
    </main>
  );
};

EducationalNowPage.defaultProps = {
  searched: false,
  selectedParams: {},
  venuesOfCare: {},
};

EducationalNowPage.propTypes = {
  searched: PropTypes.bool,
  selectedParams: PropTypes.instanceOf(Object),
  loadVenuesOfCare: PropTypes.func.isRequired,
  venuesOfCare: PropTypes.instanceOf(Object),
  fetchHealthSystem: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    searched: state.providers.searched,
    selectedParams: state.searchParams.selectedParams,
    searchFilters: state.searchFilters,
    availableTimes: state.searchResult.availableTimes,
    venuesOfCare: state.venuesOfCare,
    loading: state.apiCallsInProgress > 0 || state.venuesOfCare.meta.loading,
  };
}

const mapDispatchToProps = {
  loadVenuesOfCare,
  applySearchFilters,
  resetSearchFilters,
  loadScheduleTimes,
  fetchHealthSystem,
};

export default connect(mapStateToProps, mapDispatchToProps)(EducationalNowPage);
