import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { flatten, uniq, isEmpty } from 'lodash';
import {
  GoogleMap,
  MarkerClusterer,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import Spinner from 'components/common/Spinner';
import './MapView.scss';

const MapView = ({ providers, locations, schedules }) => {
  const [mapInstance, setMapInstance] = useState({});
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [clustererInstance, setClustererInstance] = useState({});

  const updateMarkers = useCallback(() => {
    if (isEmpty(clustererInstance)) return;
    const allMarkers = clustererInstance.getMarkers();
    allMarkers.forEach(m => m.setVisible(false));

    const positions = uniq(locations).map(l => ({
      lat: l.attributes.latitude,
      lng: l.attributes.longitude,
    }));

    const matchedPositions = [];

    const markersMatchingLocations = allMarkers.filter(m => {
      const pos = m.getPosition();
      const markerPosition = {
        lat: pos.lat(),
        lng: pos.lng(),
      };

      const hasMatch =
        uniq(positions)
          .filter(p => p.lat === markerPosition.lat)
          .filter(p => p.lng === markerPosition.lng).length > 0;

      if (hasMatch) matchedPositions.push(markerPosition);

      const hasOnlyMatchedOnce =
        matchedPositions
          .filter(p => p.lat === markerPosition.lat)
          .filter(p => p.lng === markerPosition.lng).length === 1;

      return hasMatch && hasOnlyMatchedOnce;
    });

    markersMatchingLocations.forEach(m => m.setVisible(true));
  }, [clustererInstance, locations]);

  const centerMap = useCallback(
    map => {
      const bounds = new window.google.maps.LatLngBounds();
      locations.forEach(l =>
        bounds.extend({
          lat: l.attributes.latitude,
          lng: l.attributes.longitude,
        }),
      );
      map.fitBounds(bounds);
      map.panToBounds(bounds);
      updateMarkers();
    },
    [locations, updateMarkers],
  );

  useEffect(() => {
    if (!isEmpty(mapInstance)) {
      centerMap(mapInstance);
    }
  }, [centerMap, mapInstance]);

  useEffect(() => {
    if (!isEmpty(clustererInstance)) {
      updateMarkers();
    }
  }, [updateMarkers, clustererInstance]);

  const uniqSchedulesForLocation = location =>
    uniq(
      schedules.filter(s => s.relationships.location.data.id === location.id),
    );
  const providerSchedules = uniq(
    flatten(providers.map(p => p.relationships.schedules.data)),
  );

  const providerlessSchedules = uniqSchedulesForLocation(
    selectedLocation,
  ).filter(s => !providerSchedules.map(ps => ps.id).includes(s.id));

  const skedsForLocation = schedules.filter(
    s => s.relationships.location.data.id === selectedLocation.id,
  );

  const providersForLocation = providers.filter(p => {
    const providerIds = skedsForLocation
      .filter(s => !isEmpty(s.relationships.provider.data))
      .map(s => s.relationships.provider.data.id);
    return providerIds.includes(p.id);
  });

  const schedulesForLocation = !isEmpty(selectedLocation)
    ? providerlessSchedules
    : [];

  const shouldShowInfoWindow = () =>
    showInfoWindow &&
    !isEmpty(selectedLocation) &&
    (!isEmpty(providersForLocation) || !isEmpty(schedulesForLocation));

  const handleMarkerClick = location => {
    // InfoWindow will not reappear unless toggled off and on.
    if (setShowInfoWindow) {
      setShowInfoWindow(false);
    }
    setShowInfoWindow(true);
    setSelectedLocation(location);
  };

  const locationIdsForSchedules = schedules.map(
    s => s.relationships.location.data.id,
  );
  const uniqueLocations = uniq(
    locations.filter(l => locationIdsForSchedules.includes(l.id)),
  );

  const renderMap = () => (
    <div className="MapView">
      <GoogleMap
        mapContainerStyle={{
          height: '500px',
          width: '100%',
        }}
        onLoad={map => {
          setMapInstance(map);
          centerMap(map);
        }}
      >
        <MarkerClusterer
          ignoreHidden
          onLoad={clusterer => {
            setClustererInstance(clusterer);
            updateMarkers();
          }}
        >
          {clusterer =>
            uniqueLocations.map(l => (
              <Marker
                key={l.id}
                clusterer={clusterer}
                visible={false}
                onClick={() => handleMarkerClick(l)}
                position={{
                  lat: l.attributes.latitude,
                  lng: l.attributes.longitude,
                }}
                title="map-marker"
              />
            ))
          }
        </MarkerClusterer>

        {shouldShowInfoWindow() && (
          <InfoWindow
            position={{
              lat: selectedLocation.attributes.latitude,
              lng: selectedLocation.attributes.longitude,
            }}
            onClickClose={() => setShowInfoWindow(false)}
          >
            <div className="infoWindowWrapper">
              {schedulesForLocation.map(s => (
                <div key={s.id} className="providerInformation">
                  <Link to={`/schedule/calendar/${s.id}`}>
                    {selectedLocation.attributes.name} -{' '}
                    {s.attributes.service.name}
                  </Link>
                </div>
              ))}
              {providersForLocation.map(p => (
                <div key={p.id} className="providerInformation">
                  <Link to={`/providers/${p.id}`}>{p.attributes.name}</Link>
                  {p.attributes.subspecialties && (
                    <span>
                      {p.attributes.subspecialties.map(s => s.name).join(', ')}
                    </span>
                  )}
                </div>
              ))}
              <div className="locationInformation">
                <span className="locationName">
                  {selectedLocation.attributes.name}
                </span>
                <span>{selectedLocation.attributes.address}</span>
                <span>{`${selectedLocation.attributes.city}, ${selectedLocation.attributes.state}, ${selectedLocation.attributes.zip}`}</span>
              </div>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </div>
  );

  return !isEmpty(locations) ? renderMap() : <Spinner />;
};

MapView.propTypes = {
  locations: PropTypes.instanceOf(Object),
  schedules: PropTypes.instanceOf(Object),
  providers: PropTypes.instanceOf(Array),
};

MapView.defaultProps = {
  schedules: [],
  locations: [],
  providers: [],
};

export default MapView;
