import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import './Provider.scss';

const ProviderSkeleton = () => {
  return (
    <Grid container spacing={2} className="provider">
      <Grid
        item
        container
        direction="column"
        xs={3}
        alignContent="center"
        alignItems="center"
      >
        <Skeleton height={120} width={120} variant="circle" />
      </Grid>

      <Grid item container direction="column" xs={5} alignContent="center">
        <Skeleton width={340} height={40} />
        <Skeleton width={300} />
        <Skeleton width={150} />
        <Skeleton width={150} />
        <Skeleton width={150} />
      </Grid>
    </Grid>
  );
};

export default ProviderSkeleton;
