import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import BrandingButton from './BrandingButton';
import './ScheduleItButton.scss';

const ScheduleItButton = ({
  schedule,
  appointmentType,
  time,
  timezoneName,
  venueType,
}) => {
  const history = useHistory();
  const [t] = useTranslation();
  const getApptTypeId = () => {
    if (typeof appointmentType === 'object') {
      return appointmentType.id;
    }
    return appointmentType;
  };

  const getScheduleId = () => {
    if (typeof schedule === 'object') {
      return schedule.id;
    }
    return schedule;
  };

  const getRegLink = () => {
    return `/schedule/${getScheduleId()}?appointmentTypes=${getApptTypeId()}&date=${moment(
      time,
    )
      .tz(timezoneName)
      .format()}`;
  };


  if (!getScheduleId()) return <></>;
  if (!time) return <></>;
  return (
    <BrandingButton
      type="button"
      className="ScheduleItButton"
      onClick={() => {
        localStorage.setItem('voc', venueType.split('_').join('-'))
        history.push(getRegLink())
      }}
    >
      {t('FullSchedule.scheduleIt')}
    </BrandingButton>
  );
};

ScheduleItButton.defaultProps = {
  schedule: null,
  appointmentType: null,
};

ScheduleItButton.propTypes = {
  schedule: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]),
  appointmentType: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.string,
  ]),
  time: PropTypes.string.isRequired,
  timezoneName: PropTypes.string.isRequired,
};

export default ScheduleItButton;
