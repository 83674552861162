import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isMobile } from 'react-device-detect';
import { Grid, useTheme } from '@material-ui/core';
import Tabs from '@mui/material/Tabs';
import QueryString from 'query-string';
import { isUndefined } from 'lodash';
import { useFacilityServices } from 'hooks/useFacilityServices';
import { useQueryString } from 'hooks/useQueryString';
import { usePageTitle } from 'hooks/usePageTitle';
import ResultsTab from './ResultsTab';
import ResultsTabItem from './ResultsTabItem';
import serviceLineGroupMapping from '../../config/serviceLineGroupMappings';
import { serviceLineGroupsToMapVOC } from '../../config/searchConstants';

const styles = {
  center: {
    textAlign: 'center',
    margin: '0 auto',
  },
  icon: {
    cursor: 'pointer',
  },
  iconDisabled: {
    cursor: 'text',
    opacity: '0',
  },
};

const ServiceTabs = ({
  activeTab,
  handleTabClick,
  handleTabIndex,
  results,
  serviceResults,
  tabIndex,
  serviceLineResults,
  serviceTabs,
  countForSelectedServices,
}) => {
  const history = useHistory();
  const { facilityServices } = useFacilityServices();
  const { setQueryParamsFromObject, urlParams } = useQueryString();
  const { search } = useLocation();
  const params = QueryString.parse(search);
  const theme = useTheme();
  const [serviceName, setServiceName] = useState('');
  const [onLoad, setOnLoad] = useState(true);
  const tabsShown = 3;
  const [value, setValue] = useState(0);
  const handleTabEvent = serviceId => {
    const groupName =
      serviceLineGroupMapping[
        facilityServices[serviceId]?.attributes?.permalink
      ] || 'GROUP_D';
    const VOC = serviceLineGroupsToMapVOC.filter(t => t[groupName])[0][
      groupName
    ];
    setServiceName(facilityServices[serviceId].attributes.name);
    handleTabClick(serviceId);
  };

  useEffect(() => {
    const serviceId = params.service;
    // eslint-disable-next-line no-restricted-syntax
    // for (const parentServiceId in facilityServices) {
    // if (
    //   !isUndefined(urlParams.serviceLine) &&
    //   facilityServices[parentServiceId].attributes.permalink ===
    //     urlParams.serviceLine.replace('primary-care', 'provider-services')
    // ) {
    //   serviceId = facilityServices[parentServiceId].id;
    // }
    // }
    const idList =
      serviceLineResults && serviceLineResults.length > 0
        ? [...Object.keys(results)].filter(id =>
            serviceLineResults.some(ls => ls.id === id),
          )
        : [...Object.keys(results)];
    if (onLoad && params.service && Object.keys(facilityServices).length) {
      setOnLoad(false);
      if (serviceId === '') {
        handleTabEvent(params.service);

        handleTabIndex(
          Math.floor(Number(idList.indexOf(params.service)) / tabsShown),
        );
      } else {
        handleTabEvent(serviceId);
        handleTabIndex(
          Math.floor(Number(idList.indexOf(serviceId)) / tabsShown),
        );
        handleTabEvent(serviceId);
        setQueryParamsFromObject({ service: serviceId });
      }
    } else if (idList.length && Object.keys(facilityServices).length) {
      setServiceName(facilityServices[idList[0]].attributes.name);
    }
    // setIds(idList);
  }, [tabIndex, params.service, results, facilityServices]);

  useEffect(() => {
    if (isMobile) {
      setServiceName('Facility');
    }
  }, [isMobile]);
  const setHandleTabClick = serviceId => {
    let uri = '';
    if (!isUndefined(urlParams.serviceLine)) {
      if (
        isUndefined(urlParams.dischargeFacility) &&
        isUndefined(urlParams.locationPermalink)
      )
        uri = `/facility/${urlParams.facilityName}/service/${facilityServices[serviceId].attributes.permalink}?service=${serviceId}`;
      else if (
        !isUndefined(urlParams.dischargeFacility) &&
        isUndefined(urlParams.locationPermalink)
      ) {
        uri = `/${urlParams.dischargeFacility}/discharge/service/${facilityServices[serviceId].attributes.permalink}?service=${serviceId}`;
      } else if (
        !isUndefined(urlParams.dischargeFacility) &&
        !isUndefined(urlParams.locationPermalink)
      ) {
        uri = `/${urlParams.dischargeFacility}/discharge/${urlParams.locationPermalink}/service/${facilityServices[serviceId].attributes.permalink}?service=${serviceId}`;
      } else if (
        isUndefined(urlParams.dischargeFacility) &&
        !isUndefined(urlParams.locationPermalink)
      ) {
        uri = `/facility/${urlParams.facilityName}/${urlParams.locationPermalink}/service/${facilityServices[serviceId].attributes.permalink}?service=${serviceId}`;
      }
      history.replace(uri);
    } else setQueryParamsFromObject({ service: serviceId });
  };

  usePageTitle(serviceName);

  const handleChange = newValue => {
    setValue(newValue);
  };

  return (
    <>
      {serviceTabs?.length > 0 && (
        <ResultsTab>
          <Grid container alignItems="center">
            <Grid item xs={10} style={styles.center}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: 'transparent',
                    color: 'transparent',
                  },
                  tabIndex: 0,
                }}
                TabScrollButtonProps={{ tabIndex: 0 }}
                selectionFollowsFocus
              >
                {serviceTabs.map((serviceId, index) => {
                  if (facilityServices[serviceId]) {
                    return (
                      <ResultsTabItem
                        key={serviceId}
                        handleTabClick={() => {
                          handleTabEvent(serviceId);
                          setHandleTabClick(serviceId);
                          handleChange(index);
                        }}
                        serviceId={serviceId}
                        isActiveTab={
                          serviceId.toString() === activeTab?.toString()
                        }
                        name={facilityServices[serviceId].attributes.name}
                        resultsCount={countForSelectedServices[serviceId]}
                        serviceResults={serviceResults}
                      />
                    );
                  }

                  return null;
                })}
              </Tabs>
            </Grid>
          </Grid>
        </ResultsTab>
      )}
    </>
  );
};

ServiceTabs.defaultProps = {
  activeTab: null,
};

ServiceTabs.propTypes = {
  activeTab: PropTypes.string,
  handleTabClick: PropTypes.func.isRequired,
  handleTabIndex: PropTypes.func.isRequired,
  results: PropTypes.instanceOf(Object).isRequired,
  serviceResults: PropTypes.instanceOf(Object).isRequired,
  tabIndex: PropTypes.number.isRequired,
  serviceLineResults: PropTypes.instanceOf(Object),
  serviceTabs: PropTypes.array.isRequired,
  countForSelectedServices: PropTypes.instanceOf(Object).isRequired,
};

export default ServiceTabs;
