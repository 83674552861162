import React from 'react';
import { useHistory } from 'react-router-dom';
import BrandingButton from 'components/common/BrandingButton';
import './ProviderNoAvailability.scss';

/**
 * This component renders when provider has no availability.
 *
 * This is used in the Provider Detail Page.
 */
const ProviderNoAvailability = () => {
  const history = useHistory();

  return (
    <>
      <div className="ProviderNoAvailability">
        <p>
          <strong>
            The provider you have selected has no online availability at this
            time.
          </strong>
        </p>
        <div>
          <BrandingButton type="button" onClick={() => history.push('/')}>
            Search Providers with Availability.
          </BrandingButton>
        </div>
      </div>
    </>
  );
};

export default ProviderNoAvailability;
