import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearSearch } from 'redux/actions/searchActions';
import 'bootstrap/dist/css/bootstrap.min.css';
import i18next from 'i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import './Header.scss';
import { BrandingContext } from '../contexts/BrandingContext';
import Logo from './Logo';

// eslint-disable-next-line react/prop-types
const Header = ({ supportedLocales, clearSearch }) => {
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem('i18nextLng') === 'en'
      ? 'en-US'
      : localStorage.getItem('i18nextLng'),
  );
  const changeLanguage = (e, lang) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentLang(lang);
    i18next.changeLanguage(lang);
    window.location.reload();
  };
  // eslint-disable-next-line react/prop-types
  useEffect(() => {
    if (!!supportedLocales && supportedLocales.length === 1) {
      i18next.changeLanguage(supportedLocales[0].code);
    }
  }, []);
  const { branding } = useContext(BrandingContext);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleKeyPress = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleClick(); // Handle navigation on Enter or Space key
    }
  };

  return (
    <header className="Header" role="banner" style={{ minHeight: '125px' }}>
      {branding.logoImage && (
        <div
          className="bottom-navbar"
          style={{ backgroundColor: branding.mastheadColor, padding: '10px 0' }}
        >
          <div className="container" style={{ position: 'relative' }}>
            <Logo clearSearch={clearSearch} />
            {supportedLocales.length > 1 && (
              <>
                <div
                  alt="test"
                  role="menu"
                  aria-controls="lang-menu"
                  aria-haspopup="true"
                  className="header"
                  onClick={handleClick}
                  onKeyPress={handleKeyPress}
                  tabIndex={-1}
                >
                  <svg
                    id="Layer_1_8"
                    style={{
                      enableBackground: 'new 0 0 512 512',
                      width: '20px',
                    }}
                    version="1.1"
                    viewBox="0 0 512 512"
                  >
                    {' '}
                    <path d="M256,6C117.9,6,6,117.9,6,256s111.9,250,250,250s250-111.9,250-250S394.1,6,256,6z M223.7,34.5 c-24.3,30.2-43.2,63.9-56.2,99.7c-26-7-51.4-17.2-75.5-30.6C126,67.1,172,41.9,223.7,34.5z M80.8,116.7 c25.9,14.7,53.2,25.9,81.3,33.5c-9.7,31.2-15.1,63.9-15.9,97.3H32.3C34.1,198.2,52,152.9,80.8,116.7z M32.3,264.4h113.9 c0.8,33.5,6.2,66.1,15.9,97.3c-28.1,7.7-55.4,18.8-81.3,33.5C52,359.1,34.1,313.8,32.3,264.4z M92,408.3 c24.1-13.3,49.4-23.5,75.5-30.6c13.1,35.9,32,69.5,56.2,99.7C172,470,126,444.9,92,408.3z M247.6,479.6 c-27.8-31.5-49.2-67.3-63.6-105.9c20.9-4.5,42.2-7.1,63.6-7.6V479.6z M247.6,349.3c-23.2,0.5-46.3,3.4-69.1,8.4 c-9.4-29.9-14.6-61.2-15.4-93.3h84.5V349.3z M247.6,247.6h-84.5c0.8-32.1,6-63.4,15.4-93.3c22.7,5.1,45.9,7.9,69.1,8.4V247.6z M247.6,145.8c-21.3-0.5-42.6-3.1-63.6-7.6c14.3-38.6,35.7-74.4,63.6-105.9V145.8z M479.7,247.6h-114c-0.8-32.9-6.1-65.6-15.9-97.3 c28.2-7.7,55.6-18.9,81.5-33.6C460,152.9,477.9,198.2,479.7,247.6z M420,103.7c-24.1,13.4-49.6,23.6-75.7,30.6 c-12.9-35.4-31.6-69.2-56.1-99.8C340,41.9,386,67.1,420,103.7z M264.5,32.4c28.2,31.9,49.3,67.9,63.4,105.9 c-20.9,4.5-42.1,7-63.4,7.6V32.4z M264.5,162.7c23.1-0.5,46.2-3.3,68.9-8.4c9.5,30.3,14.7,61.7,15.4,93.2h-84.3V162.7z M264.5,264.4 h84.3c-0.8,31.5-5.9,62.9-15.4,93.2c-22.7-5-45.7-7.8-68.9-8.4V264.4z M264.5,366.2c21.3,0.5,42.5,3,63.4,7.6 c-14.1,38-35.2,74-63.4,105.9V366.2z M288.3,477.5c24.5-30.6,43.2-64.4,56.1-99.8c26.1,7,51.5,17.2,75.7,30.6 C386,444.9,340,470.1,288.3,477.5z M431.2,395.3c-25.9-14.7-53.3-25.9-81.5-33.6c9.8-31.6,15.1-64.4,15.9-97.3h114 C477.9,313.8,460,359.1,431.2,395.3z" />
                  </svg>
                  <svg
                    id="Layer_1_9"
                    style={{ enableBackground: 'new 0 0 64 64', width: '20px' }}
                    version="1.1"
                    viewBox="0 0 64 64"
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                  >
                    <g>
                      <g
                        id="Icon-Chevron-Left"
                        transform="translate(237.000000, 335.000000)"
                      >
                        <polyline
                          className="st0"
                          id="Fill-35"
                          points="-218.7,-308.6 -216.7,-310.6 -205,-298.8 -193.3,-310.6 -191.3,-308.6 -205,-294.9 -218.7,-308.6 "
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <Menu
                  id="lang-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {supportedLocales.map(
                    (lng, i) =>
                      lng.isDeleted === false &&
                      (currentLang === lng.code ? (
                        <MenuItem
                          key={i}
                          className="Mui-selected"
                          onClick={e => changeLanguage(e, lng.code)}
                        >
                          {lng.name}
                        </MenuItem>
                      ) : (
                        <MenuItem
                          key={i}
                          onClick={e => changeLanguage(e, lng.code)}
                        >
                          {lng.name}
                        </MenuItem>
                      )),
                  )}
                </Menu>
                <div className="clearfix" />
              </>
            )}
          </div>
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  /* Dispatch function found in searchActions. */
  clearSearch: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  clearSearch,
};

const mapStateToProps = state => ({
  healthSystem: state.healthSystem,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
