import * as types from './actionTypes';
import * as api from '../../api/geocodingApi';
import { apiCallError } from './apiStatusActions';

export function clearGeocoding(zipCode) {
  // eslint-disable-next-line func-names
  return async function(dispatch) {
    dispatch({ type: types.CLEAR_GEOCODING, zipCode });
  };
}

export function loadGeocoding(zipCode) {
  if (zipCode === '' && localStorage.getItem('zipCode') !== null)
    zipCode = localStorage.getItem('zipCode');
  localStorage.setItem('zipCode', zipCode);
  // eslint-disable-next-line func-names
  
  return async function(dispatch) {
    try {
      const geocodingApiData = await api.getGeocoding(zipCode);
      console.log('geocoding:', geocodingApiData);
      dispatch({
        type: types.FETCH_GEOCODING_SUCCESS,
        geocodingApiData,
      });
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function loadReverseGeocoding(lat, lng) {
  // eslint-disable-next-line func-names
  return async function (dispatch) {
    try {
      const reverseGeocodingApiData = await api.getReverseGeocoding(lat, lng);
      dispatch({
        type: types.FETCH_REVERSE_GEOCODING_SUCCESS,
        reverseGeocodingApiData,
      });
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}
