import React, { useContext } from 'react';
import { isEmpty } from 'lodash';
import './ServiceBanner.scss';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { BrandingContext } from 'components/contexts/BrandingContext';
import emergencyRoomImage from 'assets/images/vocImages/default_emergency_room_md.jpeg';
import urgentCareImage from 'assets/images/vocImages/default_urgent_care_md.jpeg';
import physServicesImage from 'assets/images/vocImages/default_phys_services_md.jpeg';

const ServiceBanner = ({ venueType, services, title }) => {
  const { branding } = useContext(BrandingContext);
  const listCount = Math.ceil(services.length / 2);
  const servicesLeft = services.slice(0, listCount);
  const servicesRight = services.slice(listCount, services.length);

  if (!listCount) return <></>;

  const image = () => {
    switch (venueType) {
      case 'urgent_care':
        return urgentCareImage;
      case 'emergency_room':
        return emergencyRoomImage;
      case 'physician_services':
        return physServicesImage;
      default:
        return null;
    }
  };

  return (
    <div
      className="PhysicianServices hide-mobile"
      style={{
        border: `1px solid ${branding.primaryColor}`,
        borderTopWidth: '3px',
      }}
    >
      <Grid
        container
        style={{ justifyContent: 'center' }}
        classes={{ root: 'banner-root' }}
      >
        <Grid item sm={3}>
          <div className="service-image-element-wrapper">
            <div
              className="service-image-element"
              style={{
                backgroundImage: `url(${image()})`,
                backgroundSize: 'cover',
                backgroundPosition:
                  venueType === 'emergency_room' ? 'right' : 'center',
              }}
            />
          </div>
        </Grid>

        <Grid item sm={3} container alignItems="center">
          <>
            <h2
              className="banner-title"
              style={{ color: branding.primaryColor }}
            >
              {title}
            </h2>
          </>
        </Grid>

        <Grid
          item
          sm={!isEmpty(servicesRight) ? 3 : 6}
          container
          alignItems="center"
        >
          <ul>
            {servicesLeft.map(service => (
              <li key={service}>{service}</li>
            ))}
          </ul>
        </Grid>

        {!isEmpty(servicesRight) && (
          <Grid item sm={3} container alignItems="center">
            <ul>
              {servicesRight.map(service => (
                <li key={service}>{service}</li>
              ))}
            </ul>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

ServiceBanner.propTypes = {
  venueType: PropTypes.string.isRequired,
  services: PropTypes.instanceOf(Array).isRequired,
  title: PropTypes.string.isRequired,
};

export default ServiceBanner;
