import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { BrandingContext } from '../contexts/BrandingContext';
import { useTranslation } from 'react-i18next';

/**
 * This component renders all Provider Background items
 * under an Education heading, except for languages and
 * hospital affiliations.
 *
 * This is used in the Provider Detail Page.
 */
const ProviderEducation = ({ metadata }) => {
  const { branding } = useContext(BrandingContext);
  const grad = metadata.find(md => md.name === 'Medical/Graduate School');
  const fellowship = metadata.find(md => md.name === 'Fellowship');
  const residency = metadata.find(md => md.name === 'Residency');
  const undergrad = metadata.find(md => md.name === 'Undergraduate School');
  const [t] = useTranslation();
  const filteredMetadata = [];

  // You made me do this, Peter. - March 30, 2020
  // You made me do this AGAIN, Peter. - April 1, 2020
  if (fellowship) filteredMetadata.push(fellowship);
  if (residency) filteredMetadata.push(residency);
  if (grad) filteredMetadata.push(grad);
  if (undergrad) filteredMetadata.push(undergrad);

  return (
    <>
      <h2
        className="section-title"
        style={{
          color: branding.primaryColor,
          borderBottomColor: branding.primaryColor,
        }}
      >
        {t('ProviderEducation.educationAndTraining')}
      </h2>

      {filteredMetadata.map(data => (
        <div key={data.name}>
          <p className="education-title">{data.name}</p>
          <p className="section-description">{data.description}</p>
        </div>
      ))}
    </>
  );
};

ProviderEducation.defaultProps = {
  metadata: [],
};

ProviderEducation.propTypes = {
  metadata: PropTypes.instanceOf(Array),
};

export default ProviderEducation;
