const initialState = {
  results: {
    data: [],
    included: [],
    links: {},
    meta: {},
  },
  matchedParameters: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case 'SET_RESULTS':
      return { results: action.payload.results };
    default:
      return state;
  }
}
