import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getServiceLines, getActiveServices } from 'api/serviceLinesApi';
import { BrandingContext } from '../contexts/BrandingContext';
import BreadCrumbs from 'components/common/BreadCrumbs';
import './Sitemap.scss';

const SitemapServices = () => {
  const { branding } = useContext(BrandingContext);

  const [services, setServices] = useState(null);

  function buildServiceUrlLink(element, servicesData) {
    let serviceName = element.attributes.name;
    const id = servicesData.find(element => element.attributes.name === serviceName)?.id;
    let subServiceIdParameter = `&subservice_ids=${element.relationships.services.data[0].id}`;
    let permalink = '';

    if (element.attributes.name === 'Primary Care') {
      permalink = 'find-a-doctor';
      serviceName = 'Find A Doctor';
    } else {
      permalink = element.attributes.permalink;
    }

    for (let i = 1; i < element.relationships.services.data.length; i++) {
      const subServiceID = element.relationships.services.data[i].id;
      subServiceIdParameter += `&subservice_ids=${subServiceID}`;
    }

    return `/search?service=${permalink}&service_id=${id}&serviceName=${serviceName}${subServiceIdParameter}&ux_mode=default`;
  }

  useEffect(() => {
    getActiveServices().then(setServices);
  }, []);
  const serviceMap = [];
  if (services !== null) {
    services.included.forEach(element => {
      serviceMap.push({
        name: element.attributes.name,
        link: buildServiceUrlLink(element, services.data),
      });

    });
  }

  serviceMap.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return (
    <div className="Sitemap">
      <div className="container">
        <BreadCrumbs sitemap />
        {serviceMap.map(item => (
          <ul>
            <li>
              <Link to={item.link}>{item.name}</Link>
            </li>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default SitemapServices;
