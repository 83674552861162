import * as types from './actionTypes';
import * as healthSystemApi from '../../api/healthSystemApi';
import { beginApiCall, apiCallError } from './apiStatusActions';

export function fetchHealthSystemSuccess(healthSystem) {
  return {
    type: types.FETCH_HEALTH_SYSTEM_SUCCESS,
    health_system: {
      id: healthSystem.data[0].id,
      ...healthSystem.data[0].attributes,
    },
  };
}

export function updateSearchFilters(healthSystem) {
  let { languages, affiliations } = healthSystem.data[0].attributes;

  const { attributes } = healthSystem.data[0];
  languages = Array.from(new Set(languages)).sort();
  affiliations = Array.from(new Set(affiliations)).sort();

  const filters = {
    languages,
    hospitalAffiliations: affiliations,
    timeZone: attributes['time-zone'],
  };
  return { type: types.UPDATE_SEARCH_FILTERS, filters };
}

export function fetchHealthSystem() {
  // eslint-disable-next-line func-names
  return async function (dispatch) {
    dispatch(beginApiCall());
    try {
      const healthSystem = await healthSystemApi.getHealthSystem();
      if (healthSystem.data[0].attributes['country-code'] !== null || healthSystem.data[0].attributes['country-code'] === []) {
        localStorage.setItem('countryCode', healthSystem.data[0].attributes['country-code']);
      }
      else {
        localStorage.setItem('countryCode', 'US');
      }
      dispatch(fetchHealthSystemSuccess(healthSystem));
      dispatch(updateSearchFilters(healthSystem));
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}
