/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getFacilityForSchedule } from 'api/scheduleApi';
import { isEmpty, isNull, isUndefined } from 'lodash';

const TrackingCodes = () => {
  const location = useLocation();
  const healthSystem = useSelector(state => state.healthSystem);
  const [facility, setFacility] = useState({
    data: null,
  });

  const facResults = useSelector(
    state => state.facilitiesReducer.availableFacilities,
  );
  const visit = useSelector(state => state.visitReducer);
  const serviceLines = useSelector(state => state.serviceLines.serviceLines);

  const isDischargePage = useRouteMatch({
    path: [
      '/:dischargeFacility/discharge',
      '/:dischargeFacility/discharge/:locationPermalink',
      '/:dischargeFacility/discharge/service/:serviceLine',
      '/:dischargeFacility/discharge/:locationPermalink/service/:serviceLine',
    ],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const isFacilityPage = useRouteMatch({
    path: [
      '/facility/:facilityName/:locationPermalink',
      '/facility/:facilityName/service/:serviceLine',
      '/facility/:facilityName/:locationPermalink/service/:serviceLine',
      '/facility/:facilityName',
    ],
    strict: true,
    sensitive: true,
    exact: true,
  });

  // eslint-disable-next-line no-unused-vars
  const isRegistrationPage = useRouteMatch({
    path: [
      '/schedule/:scheduleId',
      '/:dischargeFacility/discharge/schedule/:scheduleId',
    ],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const isConfirmationPage = useRouteMatch({
    path: [
      '/schedule/:scheduleId/confirmation/:confirmationId',
      '/:dischargeFacility/discharge/schedule/:scheduleId/confirmation/:confirmationId',
    ],
    strict: true,
    sensitive: true,
    exact: true,
  });
  const isScheduleDetailPage = useRouteMatch({
    path: [
      '/schedule/calendar/:id',
      '/:dischargeFacility/discharge/schedule/calendar/:id',
    ],
    strict: true,
    sensitive: true,
    exact: true,
  });
  const clearTrackingCodes = classToRemove => {
    window.document.body
      .querySelectorAll(classToRemove)
      .forEach(node => node.parentElement.removeChild(node));
  };

  const nullCheck = trackingCode =>
    isNull(trackingCode) ||
    isUndefined(trackingCode) ||
    isEmpty(trackingCode) ||
    trackingCode === 'null' ||
    trackingCode === 'undefined';

  const documentWriteCheck = trackingCode =>
    trackingCode.includes('document.write');

  const appendPageViewTrackingCodes = (
    trackingCode,
    classToAdd,
    classToRemove,
  ) => {
    try {
      if (nullCheck(trackingCode)) return;

      const HTML = `${trackingCode}`;
      const range = document.createRange();
      range.selectNode(document.getElementsByTagName('div').item(0));
      const documentFragment = range.createContextualFragment(HTML);
      // eslint-disable-next-line no-restricted-syntax
      let index = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const child of documentFragment.children) {
        if (documentWriteCheck(child.innerHTML))
          documentFragment.children.item(index).remove();
        // eslint-disable-next-line no-plusplus
        index++;
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const child of documentFragment.children) {
        child.classList.add(classToAdd);
      }
      clearTrackingCodes(classToRemove);
      const nodes = window.document.body.querySelectorAll(`.${classToAdd}`);
      if (!nodes.length > 0) {
        document.body.appendChild(documentFragment);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error => ', err);
    }
  };

  const appendCarenetTrackingCodes = () => {
    try {
      const gtmId = healthSystem['carenet-gtm-id'] || 'GTM-W4RNCT6';
      const trackingCode = `
        <!-- Google Tag Manager -->
        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmId}');</script>
        <!-- End Google Tag Manager -->
      `;
      const classname = 'carenet-gtm-code';
      const HTML = `${trackingCode}`;
      const range = document.createRange();
      range.selectNode(document.getElementsByTagName('div').item(0));
      const documentFragment = range.createContextualFragment(HTML);
      // eslint-disable-next-line no-restricted-syntax
      for (const child of documentFragment.children) {
        child.classList.add(classname);
      }
      const trackingCodeBody = `
      <!-- Google Tag Manager (noscript) -->
      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0"
          style="display:none;visibility:hidden"></iframe></noscript>
      <!-- End Google Tag Manager (noscript) -->
      `;
      const HTMLBody = `${trackingCodeBody}`;
      const rangeBody = document.createRange();
      rangeBody.selectNode(document.getElementsByTagName('div').item(0));
      const documentFragmentBody = rangeBody.createContextualFragment(HTMLBody);
      const nodes = window.document.head.querySelectorAll(`.${classname}`);
      if (!nodes.length > 0) {
        document.head.appendChild(documentFragment);
        document.body.appendChild(documentFragmentBody);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error => ', err);
    }
  };

  const appendConversionTrackingCodes = async () => {
    try {
      clearTrackingCodes('.hs-managed');
      clearTrackingCodes('.fac-managed');
      let HTML = `${healthSystem['conversion-tracking-code']}`;
      if (facResults?.data?.length > 0) {
        HTML = `${facResults?.data[0]?.attributes['conversion-tracking-code']}`;
      } else if (!(facResults?.data?.length > 0)) {
        if (facility?.data === null) {
          const {
            params: { scheduleId },
          } = isConfirmationPage;
          const results = await getFacilityForSchedule(scheduleId);
          if (!results.errors) {
            setFacility(results);
            HTML = `${results?.data?.attributes['conversion-tracking-code']}`;
          }
        } else {
          HTML = `${facility?.data?.attributes['conversion-tracking-code']}`;
        }
      }
      const range = document.createRange();
      range.selectNode(document.getElementsByTagName('div').item(0));

      if (nullCheck(HTML)) return;
      if (!isEmpty(HTML)) {
        const documentFragment = range.createContextualFragment(HTML);
        // eslint-disable-next-line no-restricted-syntax
        let index = 0;
        // eslint-disable-next-line no-restricted-syntax
        for (const child of documentFragment.children) {
          if (documentWriteCheck(child.innerHTML))
            documentFragment.children.item(index).remove();
          // eslint-disable-next-line no-plusplus
          index++;
        }
        // eslint-disable-next-line no-restricted-syntax
        for (let child of documentFragment.children) {
          child.classList.add('conversionmanaged');
          if (documentWriteCheck(child.innerHTML)) child = null;
        }
        const nodes = window.document.body.querySelectorAll(
          `.conversionmanaged`,
        );
        if (!nodes.length > 0) {
          document.body.appendChild(documentFragment);
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error => ', err);
    }
  };

  const getServiceLineNameByServiceId = async () => {
    let serviceLineName = '';
    const serviceId = visit?.included?.filter(t => t.type === 'schedules')[0]
      ?.attributes?.service?.id;
    Object.values(serviceLines).map(a =>
      // eslint-disable-next-line array-callback-return
      a?.relationships?.services?.data.filter(i => {
        if (i?.id?.toString() === serviceId.toString())
          serviceLineName = a?.attributes?.name;
      }),
    );
    return serviceLineName;
  };

  const setIQConversionDetails = useCallback(async () => {
    if (facility.data === null) {
      const {
        params: { scheduleId },
      } = isConfirmationPage;
      const results = await getFacilityForSchedule(scheduleId);
      setFacility(results);
    }
    if (
      visit.data !== null &&
      Object.keys(serviceLines).length > 0 &&
      Object.keys(healthSystem).length > 0 &&
      facility.data !== null
    ) {
      const serviceLineName = await getServiceLineNameByServiceId();
      window.IQConversionDetails = {
        secureId: visit?.data?.attributes['secure-id'],
        service:
          visit?.included?.filter(t => t.type === 'schedules').length > 0
            ? visit?.included?.filter(t => t.type === 'schedules')[0]
              ?.attributes?.service?.name
            : null,
        serviceLine: serviceLineName,
        appointmentAtTime: visit?.data?.attributes['appointment-at'],
        confirmationCode: visit?.data?.attributes['confirmation-number'],
        facilityPermalink: facility?.data?.attributes?.permalink,
        healthSystemPermalink: healthSystem?.permalink || null,
        locationPermalink:
          visit?.included?.filter(t => t.type === 'locations').length > 0
            ? visit?.included?.filter(t => t.type === 'locations')[0]
              ?.attributes?.permalink
            : null,
        providerPermalink:
          visit?.included?.filter(t => t.type === 'providers').length > 0
            ? visit?.included?.filter(t => t.type === 'providers')[0]
              ?.attributes?.permalink
            : null,
        regionPermalink: healthSystem?.region?.permalink || null,
      };
    }
  }, [visit, healthSystem, serviceLines, facility]);

  useEffect(() => {
    if (healthSystem['use-carenet-gtm'] === true) appendCarenetTrackingCodes();
  }, [healthSystem]);

  useEffect(() => {
    (async () => {
      // Full Schedule Page
      if (isScheduleDetailPage || isRegistrationPage) {
        if (facResults?.data?.length > 0) {
          const HTML = `${facResults?.data[0]?.attributes['pageview-tracking-code']}`;
          appendPageViewTrackingCodes(HTML);
        } else if (!(facResults?.data?.length > 0)) {
          if (facility?.data === null) {
            let sid = '';
            if (isRegistrationPage) {
              const {
                params: { scheduleId },
              } = isRegistrationPage;
              sid = scheduleId;
            }
            if (isScheduleDetailPage) {
              const {
                params: { id },
              } = isScheduleDetailPage;
              sid = id;
            }

            const results = await getFacilityForSchedule(sid);
            if (!results.errors) {
              setFacility(results);
              const HTML = `${results?.data?.attributes['pageview-tracking-code']}`;
              appendPageViewTrackingCodes(HTML, 'fac-managed', '.hs-managed');
            }
          } else {
            const HTML = `${facility?.data?.attributes['pageview-tracking-code']}`;
            appendPageViewTrackingCodes(HTML, 'fac-managed', '.hs-managed');
          }
        }
      } else if (isConfirmationPage) {
        await setIQConversionDetails();
        if (window?.IQConversionDetails) appendConversionTrackingCodes();
      } else if (isDischargePage || isFacilityPage) {
        if (facResults?.data?.length > 0) {
          const HTML = `${facResults?.data[0]?.attributes['pageview-tracking-code']}`;
          appendPageViewTrackingCodes(HTML, 'fac-managed', '.hs-managed');
        }
      } else {
        clearTrackingCodes('.conversionmanaged');
        const nodes = window.document.body.querySelectorAll('.hs-managed');
        if (!nodes.length > 0) {
          const HTML = `${healthSystem['pageview-tracking-code']}`;
          appendPageViewTrackingCodes(HTML, 'hs-managed', '.fac-managed');
        }
      }
    })();
  }, [location.pathname, facResults, facility, visit, serviceLines]);

  return <></>;
};

export default TrackingCodes;
