import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppBar, Typography } from '@material-ui/core';
import { BrandingContext } from '../contexts/BrandingContext';
import BreadCrumbs from 'components/common/BreadCrumbs';
import './Sitemap.scss';

const Sitemap = () => {
  const { branding } = useContext(BrandingContext);
  const [t] = useTranslation();
  const history = useHistory();

  const reloadCount = Number(sessionStorage.getItem('reloadCount')) || 0;
  useEffect(() => {
    if (reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload(true);
    } else {
      sessionStorage.removeItem('reloadCount');
    }
  }, []);

  const sitemapLabel = (
    <Typography variant="body1" component="h1" align="center">
      Sitemap
    </Typography>
  );

  const displayDesktop = () => {
    return (
      <div style={{ color: branding.primaryColor }} align="center">
        Sitemap
      </div>
    );
  };

  return (
    <div className="Sitemap">
      {/* <PageMetaInfo title={pageTitle} description={pageDescription} canonicalLink={canonical}/> */}
      <h3 position="relative">
        {displayDesktop()}
      </h3>
      <div className="container">
        <BreadCrumbs onlyHome sitemap />
        <h4 style={{ color: branding.primaryColor, marginTop: '10px' }}>
          Information
        </h4>

        <ul>
          <li>
            <Link to="/sitemap/cities">{t('Sitemap.Cities')}</Link>
          </li>
          <li>
            <Link to="/sitemap/services">{t('Sitemap.Services')}</Link>
          </li>
          <li>
            <Link to="/sitemap/facilities">{t('Sitemap.Facilities')}</Link>
          </li>
          <li>
            <Link to="/sitemap/providers">{t('Sitemap.Providers')}</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sitemap;
