import {
  handleResponse,
  handleError,
  getHeaderInfo,
  getEndpoint,
} from './apiUtils';

export function getInsurancePlans() {
  const searchParams = new URLSearchParams();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const ids = urlParams.get('service_id');
  if (ids != null) {
    searchParams.append('service_ids', urlParams.get('service_id'));
  }
  return fetch(getEndpoint('insurance-plans', searchParams), getHeaderInfo())
    .then(handleResponse)
    .catch(handleError);
}
