import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomTextField from '../CustomTextField';

const NameFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const showFirstName = formContext.isFieldEnabled('first-name');
  const showMiddleInitial = formContext.isFieldEnabled('middle-initial');
  const showLastName = formContext.isFieldEnabled('last-name');
  const [t] = useTranslation();
  return (
    <>
      {(showFirstName || showMiddleInitial || showLastName) && (
        <div className="form-section_row">
          <Grid container justify="space-between">
            {showFirstName && (
              <Grid item sm={4} xs={12} className="form-item">
                <CustomTextField
                  fieldKey="firstName"
                  fieldValue={formContext.form.firstName}
                  validateFormHandler={formContext.handleSetFormState}
                  shrink
                  required={formContext.isFieldRequired('first-name')}
                  label={t('NameFields.patientFirstName')}
                  hasError={formContext.hasError('first-name')}
                  errorMessage={formContext.getError('first-name')}
                />
              </Grid>
            )}

            {showMiddleInitial && (
              <Grid item sm={3} xs={8} className="form-item">
                <CustomTextField
                  fieldKey="middleInitial"
                  fieldValue={formContext.form.middleInitial}
                  validateFormHandler={formContext.handleSetFormState}
                  label={t('NameFields.patientMiddleInitial')}
                  required={formContext.isFieldRequired('middle-initial')}
                  shrink
                />
              </Grid>
            )}

            {showLastName && (
              <Grid item sm={4} xs={12}>
                <CustomTextField
                  fieldKey="lastName"
                  fieldValue={formContext.form.lastName}
                  validateFormHandler={formContext.handleSetFormState}
                  required={formContext.isFieldRequired('last-name')}
                  shrink
                  label={t('NameFields.patientLastName')}
                  hasError={formContext.hasError('last-name')}
                  errorMessage={formContext.getError('last-name')}
                />
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </>
  );
};

export default NameFields;
