import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import { useTranslation } from 'react-i18next';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './EducationalAlert.scss';

const EducationalAlert = ({ venue }) => {
  const [t] = useTranslation();
  return (
    <div className="EducationalAlert">
      <>
        {venue.attributes.venueType === 'emergency_room' && (
          <Alert variant="danger" className="custom-alert">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            {t('EducationalAlert.alertMessage')}
          </Alert>
        )}
      </>
    </div>
  );
};

EducationalAlert.propTypes = {
  venue: PropTypes.instanceOf(Object).isRequired,
};

export default EducationalAlert;
