import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import './DateHeading.scss';

/**
 * Formats/Displays a date.
 * Typically found in carousel components.
 */
const DateHeading = ({ date, layout, timezoneName, hideDayName }) => {
  if (!date || date === '') return <></>;
  if (!moment(date).isValid()) return <></>;
  const displayDate = moment.utc(date); // moment(date).tz(timezoneName);
  const displayClass = `DateHeading ${layout}`;
  let format1 = '';
  let format2 = '';

  if (
    localStorage.getItem('countryCode') === 'GB' ||
    localStorage.getItem('countryCode') === 'UK'
  ) {
    format1 = 'DD';
    format2 = 'MM';
  } else {
    format1 = 'MM';
    format2 = 'DD';
  }
  return (
    <div className={displayClass}>
      {!hideDayName && <div className="day">{displayDate.format('ddd')}</div>}
      <div className="month-day">{`${displayDate.format(
        format1,
      )}/${displayDate.format(format2)}`}</div>
    </div>
  );
};

DateHeading.defaultProps = {
  layout: 'vertical',
  hideDayName: false,
};

DateHeading.propTypes = {
  /** Date string. */
  date: PropTypes.string.isRequired,
  /** eg. 'America/Toronto' */
  timezoneName: PropTypes.string.isRequired,
  /** Optional. 'vertical' or 'horizontal' */
  layout: PropTypes.string,
  /** Optional. */
  hideDayName: PropTypes.bool,
};

export default DateHeading;
