import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import {
  Grid,
  MuiThemeProvider,
  Typography,
  useTheme,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { isMobile } from 'react-device-detect';
import { BrandingContext } from 'components/contexts/BrandingContext';
import { BlackTheme } from 'components/form/FormElements';
import ProviderForm from 'components/form/ProviderForm';
import PhysicianForm from 'components/form/PhysicianForm';
import './Search.scss';
import { useTranslation } from 'react-i18next';
import { useQueryString } from 'hooks/useQueryString';

const Search = ({
  resetPageCount,
  uxMode,
  setApptTypeRequired,
  setStartDate,
}) => {
  if (uxMode === 'educational') {
    localStorage.setItem('voc', '');
  }
  const { search, pathname } = useLocation();
  const { params } = useQueryString();
  const { branding } = useContext(BrandingContext);
  const theme = useTheme();
  const providerFormActive = () =>
    pathname === '/' ||
    (uxMode === 'educational' &&
      pathname.startsWith('/next-24') &&
      params.searchType === 'now');
  const [t] = useTranslation();
  const styles = {
    tabItem: {
      color: branding.primaryColor,
      outlineColor: branding.primaryColor,
    },
    tabPanel: {
      background:
        isMobile && branding.secondaryColor !== ''
          ? branding.secondaryColor
          : 'transparent',
      borderBottom: `1px solid ${branding.primaryColor}`,
      borderLeft: isMobile ? `1px solid ${branding.primaryColor}` : '',
      borderRight: isMobile ? `1px solid ${branding.primaryColor}` : '',
      borderTop: isMobile ? `1px solid ${branding.primaryColor}` : '',
    },
    tabPanelItem: {
      color: '#900',
      borderRight: isMobile ? `1px solid ${branding.primaryColor}` : '',
    },
    tabPanelItemActive: {
      color: '#900',
      borderBottom: !isMobile ? `2px solid ${branding.primaryColor}` : '',
    },
  };

  return (
    <div className="EducationalSearch">
      <div className="tab-container">
        {uxMode === 'educational' && (
          <Grid container className="tab-panel" style={styles.tabPanel}>
            <Grid
              item
              className={`tab-panel-item ${
                providerFormActive() ? 'active' : ''
              }`}
              style={
                !providerFormActive()
                  ? styles.tabPanelItem
                  : {
                      ...styles.tabPanelItem,
                      ...styles.tabPanelItemActive,
                    }
              }
            >
              <Typography variant="h6" component="h1">
                <Link
                  to={`/${search}`}
                  className="tab-item"
                  style={styles.tabItem}
                >
                  <span className="hide-mobile">
                    {t('SearchPage.nowLabel')}
                  </span>
                  <span className="hide-desktop">{t('SearchPage.now')}</span>
                </Link>
              </Typography>
            </Grid>
            <Grid
              item
              className={`tab-panel-item ${
                !providerFormActive() ? 'active' : ''
              }`}
              style={
                providerFormActive()
                  ? styles.tabPanelItem
                  : {
                      ...styles.tabPanelItem,
                      ...styles.tabPanelItemActive,
                    }
              }
            >
              <Typography variant="h6" component="h1">
                <Link
                  to={`/search${search}`}
                  className="tab-item"
                  style={styles.tabItem}
                >
                  <span className="hide-mobile">
                    {t('SearchPage.futureLabel')}
                  </span>
                  <span className="hide-desktop">{t('SearchPage.later')}</span>
                </Link>
              </Typography>
            </Grid>
          </Grid>
        )}

        <MuiThemeProvider theme={BlackTheme}>
          <div className="tab-content">
            {providerFormActive() && <ProviderForm />}
            {!providerFormActive() && (
              <PhysicianForm
                uxMode={uxMode}
                resetPageCount={resetPageCount}
                setApptTypeRequired={setApptTypeRequired}
                setStartDate={date => setStartDate(date)}
              />
            )}

            <p className="disclaimer">
              <strong>{t('Search.important')}</strong> {t('Search.important1')}
            </p>
          </div>
        </MuiThemeProvider>
      </div>
    </div>
  );
};

Search.propTypes = {
  resetPageCount: PropTypes.func,
  uxMode: PropTypes.string,
  setApptTypeRequired: PropTypes.func,
  setStartDate: PropTypes.func,
};

Search.defaultProps = {
  resetPageCount: () => {},
  setStartDate: () => {},
  uxMode: 'default',
  setApptTypeRequired: () => {},
};

export default Search;
