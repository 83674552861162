import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Button from 'react-bootstrap/Button';
import BaseModal from './BaseModal';
import './ScreeningQuestionModal.scss';

const formatInnerContent = html => ({ __html: html });

const ActiveScreeningQuestion = ({
  activeQuestion,
  onHandleOutcome,
  onClose,
}) => {
  const { text, outcomes, proceed, acknowledge } = activeQuestion;
  const singleChoice = activeQuestion['single-choice'];
  const chooseOutcome = value => {
    // If the client wants more information from the patient, but the answer
    // does not affect the outcome, simply remove the outcome array.
    const selectedOutcome = outcomes
      ? outcomes.find(outcome => outcome.value === value)
      : undefined;
    onHandleOutcome(selectedOutcome, value);
  };
  return (
    <>
      <div
        className="ScreeningQuestionModal__question-content"
        dangerouslySetInnerHTML={formatInnerContent(text)}
      />
      <div>
        {!isEmpty(acknowledge) && (
          <Button
            onClick={() => chooseOutcome(undefined)}
            className="btn-default"
          >
            {acknowledge[0].text}
          </Button>
        )}
        {!isEmpty(proceed) && (
          <Button onClick={onClose} className="btn-default">
            {proceed.text}
          </Button>
        )}
        {!isEmpty(singleChoice) && (
          <>
            {singleChoice.map((choice, i) => (
              <Button
                key={choice.value}
                onClick={() => chooseOutcome(choice.value)}
                variant={i === 0 ? 'primary' : 'outline-primary'}
                className="ScreeningQuestionModal__button"
              >
                {choice.text}
              </Button>
            ))}
          </>
        )}
      </div>
    </>
  );
};

const ActiveScreeningMessage = ({ content }) => (
  <div dangerouslySetInnerHTML={formatInnerContent(content)} />
);

const ScreeningQuestionModal = ({
  show,
  onClose,
  onHandleOutcome,
  heading,
  activeObject,
  presentationType,
}) => {
  return (
    <BaseModal show={show} onClose={onClose} preventEarlyExit>
      <div className="ScreeningQuestionModal">
        <h4>{heading}</h4>
        {presentationType === 'question' && (
          <ActiveScreeningQuestion
            activeQuestion={activeObject}
            onHandleOutcome={onHandleOutcome}
            onClose={onClose}
          />
        )}
        {presentationType === 'message' && (
          <ActiveScreeningMessage content={activeObject.content} />
        )}
      </div>
    </BaseModal>
  );
};

ScreeningQuestionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  activeObject: PropTypes.instanceOf(Object).isRequired,
  presentationType: PropTypes.string.isRequired,
  onHandleOutcome: PropTypes.func.isRequired,
};

export default ScreeningQuestionModal;
