import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import QueryString from 'query-string';
import { isEmpty, groupBy, isUndefined } from 'lodash';
import moment from 'moment-timezone';
import Appointment from 'components/appointmentPicker/Appointment';
import './DateCarouselContainer.scss';
import { isServiceGroupB_C } from 'config/serviceLineGroupMappings';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useSettings from 'hooks/useSettings';
import { useQueryString } from '../../hooks/useQueryString';

const DateCarouselContainer = ({
  dates,
  times,
  link,
  maxTimesPerDay,
  loading,
  timezoneName,
  locationId,
  showMore,
  showNextAvailable,
  scrollLeft,
  onScroll,
  whoIsScrolling,
  setWhoIsScrolling,
  scheduleId,
  inactive,
  nextAvailableTime,
  appointmentTypeFlag,
  servicePermalink,
  scheduleStatus
}) => {
  const [t] = useTranslation();
  const [timesToShow, setTimesToShow] = useState([4]);
  const [buttonText, setButtonText] = useState([t('more')]);
  const componentClass = `DateCarouselContainer`;
  const buttonClass = `btn-default-outline`;
  const { pathname, urlParams } = useQueryString();
  const healthSystem = useSelector(state => state.healthSystem);
  const [noAvailableAppointments, setNoAvailableAppointments] = useState();
  const availableScheduleDays = useSettings({
    scheduleId,
  });

  useEffect(() => {
    if (t('noAvailableAppointments') !== 'noAvailableAppointments') {
      const key = t('noAvailableAppointments');
      setNoAvailableAppointments(key.replace('90', availableScheduleDays));
    }
  }, [availableScheduleDays, t('noAvailableAppointments')]);

  useEffect(() => {
    if (isServiceGroupB_C(servicePermalink))
      setNoAvailableAppointments(t('noAvailableAppointmentsGroupBC'));
  }, [servicePermalink]);

  const isSearchPage = () =>
    pathname === '/search' ||
    pathname.indexOf('/search') > -1 ||
    pathname === '/';
  const [apptTypeSelect, setApptTypeSelect] = useState(true);
  // Returns an integer width for use by Material's Grid component
  const getContainerWidth = () => {
    if (isSearchPage()) {
      return 11;
    }
    if (!isUndefined(urlParams.serviceLine)) {
      return 12;
    }
    return 10;
  };

  const groupedTimes = groupBy(times, d => {
    return moment(d)
      .tz(timezoneName)
      .format('YYYY-MM-DD');
  });
  const dateSlideRef = useRef(null);
  const scrollRef = useRef(null);

  const handleScroll = () => {
    onScroll(scrollRef, scheduleId);
  };
  const onTouchStart = () => {
    setWhoIsScrolling(scheduleId);
  };
  useEffect(() => {
    if (!appointmentTypeFlag) {
      setApptTypeSelect(false);
    } else setApptTypeSelect(true);
  });
  useEffect(() => {
    scrollRef.current = dateSlideRef.current;
    scrollRef.current.id = scheduleId;
    scrollRef.current.addEventListener('touchstart', onTouchStart, {
      passive: true,
    });
    return () => {
      scrollRef.current.removeEventListener('touchstart', onTouchStart);
    };
  }, []);

  useEffect(() => {
    scrollRef.current.addEventListener('scroll', handleScroll, {
      passive: true,
    });
    return () => {
      scrollRef.current.removeEventListener('scroll', handleScroll);
    };
  }, [whoIsScrolling]);

  useEffect(() => {
    if (scrollLeft.fromComponent !== scheduleId)
      scrollRef.current.scrollLeft = scrollLeft.left;
  }, [scrollLeft]);
  const handleMore = () => {
    let gt = '';
    dates.forEach(day => {
      gt = groupedTimes[day.tz(timezoneName).format('YYYY-MM-DD')] || [];
    });
    if (timesToShow === gt.length) {
      setTimesToShow(4);
      setButtonText(t('more'));
    } else {
      setTimesToShow(gt.length);
      setButtonText(t('less'));
    }
  };

  /**
   * Determines if the next available time exists and should be shown
   * @returns {String | false} Either an appointment link or false
   */
  const checkNextAvailable = () => {
    if (!showNextAvailable) {
      return false;
    }
    // This component expects an array of visible dates.
    // We will use the last date in the array to determine
    // how we display the next available time.
    const lastDateFromList = moment(dates[dates.length - 1])
      .tz(timezoneName)
      .format();

    if (times.length > 0 || nextAvailableTime) {
      const time = times[0] ? times[0] : nextAvailableTime;
      const firstDateFromTime = moment(time)
        .tz(timezoneName)
        .format();
      const dateToCompare = moment(lastDateFromList)
        .tz(timezoneName)
        .format();
      const dateComparison = moment(firstDateFromTime)
        .tz(timezoneName)
        .isAfter(dateToCompare, 'day');
      // If the next available appointment is after the last visible date,
      // return a url to the next available time
      if (dateComparison) {
        const params = QueryString.parseUrl(link);
        // This allows us to 'carry forward' existing params, if supplied.
        const apptParams = {
          ...params.query,
          ...{
            date: moment(time)
              .tz(timezoneName)
              .format(),
          },
        };
        const apptQueryString = QueryString.stringify({
          ...apptParams,
          date: moment(time)
            .tz(timezoneName)
            .format(),
        });
        const apptLink = `${params.url}?${apptQueryString}`;
        return apptLink;
      }
      return false;
    }
    return false;
  };
  if (!apptTypeSelect) return null;
  if (loading) {
    return (
      <Grid container className={componentClass}>
        <Grid item sm={1} className="control" />
        <Grid
          item
          container
          sm={getContainerWidth()}
          className="dates"
          ref={dateSlideRef}
        >
          {dates.map(day => {
            const skeletonArray = [1, 2, 3, 4, 5, 6];
            return (
              <Grid item key={day.format()} className="date-column">
                {skeletonArray
                  .slice(
                    0,
                    showMore
                      ? timesToShow
                      : maxTimesPerDay || skeletonArray.length,
                  )
                  .map(value => (
                    <Skeleton
                      key={`${day.format()}-${value}`}
                      width={80}
                      height={30}
                      animation="wave"
                      variant="rect"
                      className="Appointment"
                    />
                  ))}
              </Grid>
            );
          })}
        </Grid>
        <Grid item sm={1} className="control" />
      </Grid>
    );
  }

  if (!loading) {
    const apptLink = checkNextAvailable();
    if (apptLink) {
      return (
        <>
          <Link
            to={apptLink}
            className="Appointment Link"
            style={{ textDecoration: 'none' }}
          >
            <button
              type="button"
              className={`${buttonClass} appointment-button`}
            >
              {t('nextavailabiltyis')}{' '}
              {moment(times[0] ? times[0] : nextAvailableTime)
                .tz(timezoneName)
                .format('ddd, MMM DD [at] hh:mm A')}
            </button>
          </Link>
        </>
      );
    }
    if (!apptLink && !nextAvailableTime && !times?.[0]?.length) {
      return (
        <>
          <div className="noAvailableAppointments">
            {noAvailableAppointments}
          </div>
        </>
      );
    }

    if (
      scheduleStatus?.forceDeactivation === true ||
      scheduleStatus?.active === false
    ) {
      return (
        <>
          <div className="noAvailableAppointments">
            {noAvailableAppointments}
          </div>
        </>
      );
    }
  }

  return (
    <Grid container className={componentClass}>
      <Grid item sm={1} className="control">
        {/** Empty grid item for alignment with DateCarousel */}
      </Grid>

      <Grid item container sm={getContainerWidth()} className="dates">
        {/** This is where the date columns go. */}
        {dates.map((day, index) => {
          const creationDate = moment(day)
            ?.creationData()
            ?.input?.slice(0, 10);
          // const gt = groupedTimes[day.tz(timezoneName).format('YYYY-MM-DD')] || [];
          // const gt = groupedTimes[day.utc().format('YYYY-MM-DD')] || [];
          const gt =
            groupedTimes[
            moment
              .utc(creationDate)
              .add(index, 'days')
              .format('YYYY-MM-DD')
            ] || [];

          if (isEmpty(gt)) {
            return (
              <Grid
                item
                key={day.tz(timezoneName).format()}
                className="date-column empty"
              >
                <span className="no-times-available">
                  {t('DateCarouselContainer.noTimesAvailable')}
                </span>
              </Grid>
            );
          }
          return (
            <Grid
              item
              key={day.tz(timezoneName).format()}
              className="date-column"
            >
              {gt
                .slice(
                  0,
                  showMore
                    ? timesToShow || gt.length
                    : maxTimesPerDay || gt.length,
                )
                .map(time => (
                  <Appointment
                    outline
                    key={time}
                    time={time}
                    link={link}
                    timezoneName={timezoneName}
                    locationId={locationId}
                    inactive={inactive}
                  />
                ))}
              {showMore && gt.length > 4 && (
                <button
                  type="button"
                  onClick={handleMore}
                  className="more btn-default-outline"
                >
                  {buttonText}
                </button>
              )}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

DateCarouselContainer.defaultProps = {
  dates: [],
  inactive: false,
  times: [],
  maxTimesPerDay: null,
  loading: false,
  locationId: '',
  showMore: false,
  showNextAvailable: true,
  scrollLeft: {},
  onScroll: () => { },
  setWhoIsScrolling: () => { },
  whoIsScrolling: '',
  scheduleId: '',
  nextAvailableTime: '',
  appointmentTypeFlag: true,
};

DateCarouselContainer.propTypes = {
  /** Array of Moment objects, representing a range of dates */
  dates: PropTypes.instanceOf(Array),
  /** Array of appointment times */
  times: PropTypes.instanceOf(Array),
  /** Pre-assembled registration page link */
  link: PropTypes.string.isRequired,
  /** Upper bound for number of appointments to display in each column. */
  maxTimesPerDay: PropTypes.number,
  /** Should this component show a loading animation? */
  loading: PropTypes.bool,
  locationId: PropTypes.string,
  timezoneName: PropTypes.string.isRequired,
  showMore: PropTypes.bool,
  showNextAvailable: PropTypes.bool,
  scrollLeft: PropTypes.instanceOf(Object),
  onScroll: PropTypes.func,
  setWhoIsScrolling: PropTypes.func,
  whoIsScrolling: PropTypes.string,
  scheduleId: PropTypes.string,
  inactive: PropTypes.bool,
  nextAvailableTime: PropTypes.string,
  appointmentTypeFlag: PropTypes.bool,
  servicePermalink: PropTypes.string.isRequired,
  scheduleStatus: PropTypes.instanceOf(Object).isRequired
};

export default DateCarouselContainer;
