import React from 'react';
import clsx from 'clsx';
import { makeStyles, Radio } from '@material-ui/core';

const radioStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.15)',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    border: '2px solid #000',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#fff',
    position: 'relative',
    '&:before': {
      backgroundColor: '#000',
      borderRadius: '50%',
      content: '""',
      display: 'block',
      height: 12,
      left: 2,
      position: 'absolute',
      top: 2,
      width: 12,
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

const CustomRadio = props => {
  const classes = radioStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

export default CustomRadio;
