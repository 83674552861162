import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useQueryString } from '../../hooks/useQueryString';

const MetaInfo = ({ title, canonicalLink, description }) => {
  const { isDischargePage } = useQueryString();
  return (
    <Helmet>
      <meta charSet="utf-8" />
      {description !== '' && <meta name="description" content={description} />}
      <title>{title}</title>
      <link rel="canonical" href={canonicalLink} />
      {isDischargePage && <meta name="robots" content="noindex,follow" />}
    </Helmet>
  );
};

MetaInfo.defaultProps = {
  title: 'InQuicker',
  canonicalLink: '',
  description: '',
};

MetaInfo.propTypes = {
  title: PropTypes.string,
  canonicalLink: PropTypes.string,
  description: PropTypes.string,
};

export default MetaInfo;
