import { getEndpoint, getHeaderInfo } from 'api/apiUtils';

export function getAvailableFacilities(filter) {
  return async function(dispatch) {
    try {
      const facilityResponse = await fetch(
        getEndpoint(`facilities`, filter),
        getHeaderInfo(),
      );
      const res = await facilityResponse.json();
      dispatch({ type: 'SET_FACILITIES_RESULTS', payload: res });
    } catch (err) {
      // console.error('In error', err);
    }
  };
}

export function getAllFacilities() {
  const paginateParameter = 'page[size]=999';
  const facilityResponse = fetch(
    getEndpoint(`facilities`, paginateParameter),
    getHeaderInfo(),
  )
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      const items = data;
      return items.data;
    });
  return facilityResponse;
}
