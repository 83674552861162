import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import ListIcon from '@material-ui/icons/List';
import 'components/inventory/InventoryControls.scss';
import { useTranslation } from 'react-i18next';

const InventoryControls = ({
  showMapView,
  setShowMapView,
  sortBy,
  handleChange,
}) => {
  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    inputStyle: {
      background: '#fff',
      color: '#000',
      paddingLeft: '5px',
      paddingRight: '5px',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    mapButton: {
      background: 'transparent',
      border: 'none',
      appearance: 'none',
    },
  }));
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <Grid
      container
      alignItems="center"
      spacing={2}
      className="InventoryControls hide-mobile"
    >
      <Grid item>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            id="sort-by-label"
            htmlFor="sort-by-select"
            className={classes.inputStyle}
          >
            {t('InventoryControl.sort')}
          </InputLabel>
          <Select
            id="sort-by-select"
            labelId="sort-by-label"
            value={sortBy}
            onChange={handleChange}
          >
            <MenuItem value="soonest">
              {t('InventoryControls.soonest')}
            </MenuItem>
            <MenuItem value="nearest">
              {t('InventoryControls.closest')}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {showMapView && (
        <Grid item className="map">
          <button
            aria-label="Calender Icon"
            className={`view-button ${classes.mapButton}`}
            type="button"
            onClick={() => setShowMapView(false)}
          >
            <ListIcon fontSize="large" style={{ marginRight: '5px' }} />
            {/* Key Not Found */}
            {t('text.listView')}
          </button>
        </Grid>
      )}
      {!showMapView && (
        <Grid item className="map">
          <button
            aria-label="Calender Icon"
            className={`view-button ${classes.mapButton}`}
            type="button"
            onClick={() => setShowMapView(true)}
          >
            <MapIcon fontSize="large" style={{ marginRight: '5px' }} />
            {t('InventoryControls.map')}
          </button>
        </Grid>
      )}
    </Grid>
  );
};

InventoryControls.defaultProps = {
  showMapView: false,
  setShowMapView: () => {},
  handleChange: () => {},
};

InventoryControls.propTypes = {
  showMapView: PropTypes.bool,
  setShowMapView: PropTypes.func,
  sortBy: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
};

export default InventoryControls;
