import { findIndex } from 'lodash';
import * as types from '../actions/actionTypes';
import initialState from './initialState';

const locationsWithAddedTitles = (locations, key, title) => {
  return locations.map(l => {
    return { ...l, title: l.key === key ? title : l.title };
  });
};

const updateHistoryLocations = (locations, newLocation) => {
  const pathnames = locations.map(l => l.pathname);
  if (pathnames.includes(newLocation.pathname)) {
    const index = findIndex(pathnames, p => p === newLocation.pathname);
    return [...locations.slice(0, index), newLocation];
  }

  return [...locations, newLocation];
};

export default function breadcrumbReducer(
  state = initialState.breadcrumb,
  action,
) {
  switch (action.type) {
    case types.ADD_HISTORY_LOCATION:
      return {
        ...state,
        history: updateHistoryLocations(state.history, action.location),
      };
    case types.UPDATE_LOCATION_TITLE:
      return {
        ...state,
        history: locationsWithAddedTitles(
          state.history,
          action.key,
          action.title,
        ),
      };
    case types.CLEAR_HISTORY:
      return { ...state, history: [] };
    default:
      return state;
  }
}
