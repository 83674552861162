import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TextField, FormHelperText } from '@material-ui/core';
import { spaceToKaleb } from '../../utils/helpers';

const textStyles = makeStyles({
  input: {
    '&&:focus': {
      outline: '1px solid transparent',
    },
  },
});

const CustomTextArea = ({
  label,
  required,
  fieldKey,
  fieldValue,
  validateFormHandler,
  hasError,
  errorMessage,
}) => {
  const classes = textStyles();
  const handleChange = e => {
    const { value } = e.target;

    if (required) {
      // console.log('run validation');
    }

    validateFormHandler({
      [fieldKey]: {
        error: false,
        value,
      },
    });
  };

  return (
    <>
      <TextField
        id={spaceToKaleb(label)}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          className: classes.input,
        }}
        value={fieldValue.value}
        label={
          <>
            {required && <span className="required">*</span>}
            {label}
          </>
        }
        margin="normal"
        multiline
        rows="4"
        variant="outlined"
        onChange={handleChange}
        error={hasError}
      />
      {errorMessage && (
        <FormHelperText error={hasError}>{errorMessage}</FormHelperText>
      )}
    </>
  );
};

CustomTextArea.defaultProps = {
  required: false,
};

CustomTextArea.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  fieldValue: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  validateFormHandler: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
};

export default CustomTextArea;
