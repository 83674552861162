import React, { useContext } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  RadioGroup,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CustomRadio from '../CustomRadio';
import CustomTextField from '../CustomTextField';
import { RegistrationFormContext } from '../RegistrationFormProvider';

const HasPhysicianFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const hasError = formContext.hasError('has-physician');
  const [t] = useTranslation();
  return (
    <>
      {formContext.isFieldEnabled('has-physician') && (
        <div className="form-section_row">
          <div className="label-question">
            {formContext.isFieldRequired('has-physician') && (
              <span className="required">* </span>
            )}
            {t('HasPhysicianFields.PatienthaveDoctor')}?
          </div>

          <FormControl variant="outlined" fullWidth error={hasError}>
            <RadioGroup
              row
              onChange={e =>
                formContext.handleSetFormState({
                  hasPhysician: { value: e.target.value },
                })
              }
            >
              <FormControlLabel
                value="yes"
                control={<CustomRadio />}
                label={t('VisitFeedbackPage.yes')}
              />
              <FormControlLabel
                value="no"
                control={<CustomRadio />}
                label={t('VisitFeedbackPage.no')}
              />
            </RadioGroup>

            {hasError && (
              <FormHelperText error={hasError}>
                {formContext.getError('has-physician')}
              </FormHelperText>
            )}
          </FormControl>

          {formContext.isFieldEnabled('primary-care-physician') &&
            formContext.form.hasPhysician.value === 'yes' && (
              <div className="form-section_row">
                <Grid container>
                  <Grid item sm={4} xs={12}>
                    <CustomTextField
                      fieldKey="primaryCarePhysician"
                      fieldValue={formContext.form.primaryCarePhysician}
                      validateFormHandler={formContext.handleSetFormState}
                      label="Primary care physician's name"
                      required
                      shrink
                      hasError={formContext.hasError('primaryCarePhysician')}
                      errorMessage={formContext.getError(
                        'primaryCarePhysician',
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
        </div>
      )}
    </>
  );
};

export default HasPhysicianFields;
