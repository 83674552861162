import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateLocationTitle } from 'redux/actions/breadcrumbActions';

export const usePageTitle = pageTitle => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      dispatch(updateLocationTitle(location.key, pageTitle));
    }, 50);
    // document.title = pageTitle;
  }, [pageTitle, location.key]);
  return pageTitle;
};
