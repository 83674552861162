import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function visitReducer(state = initialState.visit, action) {
  switch (action.type) {
    case types.CLEAR_VISIT:
      return initialState.visit;
    case types.FETCH_VISIT_SUCCESS:
      return action.visit;
    default:
      return state;
  }
}
