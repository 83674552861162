import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ER_SERVICE_NAMES } from '../../config/searchConstants';
import {
  Grid,
  MuiThemeProvider,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import QueryString from 'query-string';
import CustomSelect from 'components/form/CustomSelect';
import { BlackTheme } from 'components/form/FormElements';
import { BrandingContext } from 'components/contexts/BrandingContext';
import './BookApptForm.scss';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

const BookApptForm = ({
  appointmentType,
  appointmentTypes,
  handleChange,
  headingLabel,
  service,
}) => {
  const location = useLocation();
  const { branding } = useContext(BrandingContext);
  const [t] = useTranslation();
  const params = QueryString.parse(location.search);
  const useStyles = makeStyles({
    primaryHeading: {
      color: branding.secondaryTextColor,
      backgroundColor: branding.primaryColor,
    },
  });
  const classes = useStyles();

  const appointmentTypeOptions = appointmentTypes.map(at => ({
    value: at.id,
    text: at.name,
  }));

  return (
    <MuiThemeProvider theme={BlackTheme}>
      <div className="BookApptForm">
        <header
          className="sidebar-title"
          style={{ backgroundColor: branding.primaryColor }}
        >
          <Typography
            variant="h5"
            component="h2"
            className={`appointment-heading ${classes.primaryHeading}`}
          >
            {ER_SERVICE_NAMES.includes(service)
              ? t('ScheduleAppointment.bookAVisit')
              : t('BookApptForm.bookAnAppointment')}
          </Typography>
        </header>

        {!isEmpty(appointmentTypes) && (
          <div className="sidebar-content">
            <Grid
              container
              spacing={1}
              className="pt-2"
              style={{ marginBottom: '10px' }}
            >
              <Grid item sm={12} className="control">
                <CustomSelect
                  fieldKey="appointmentType"
                  fieldValue={appointmentType}
                  label={
                    ER_SERVICE_NAMES.includes(service)
                      ? t('ScheduleAppointment.visitType')
                      : t('RegistrationForm.appointmentType')
                  }
                  items={appointmentTypeOptions}
                  validateFormHandler={e => handleChange(e, 'appointmentType')}
                />
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    </MuiThemeProvider>
  );
};

BookApptForm.defaultProps = {
  appointmentType: {},
  appointmentTypes: [],
};

BookApptForm.propTypes = {
  /** Active/selected appointment type object */
  appointmentType: PropTypes.instanceOf(Object),
  /** Array of appt time options */
  appointmentTypes: PropTypes.instanceOf(Array),
  /** Data lifting function for parent components */
  handleChange: PropTypes.func.isRequired,
  headingLabel: PropTypes.string.isRequired,
  service: PropTypes.string,
};

export default BookApptForm;
