import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import {
  handleResponse,
  handleError,
  getHeaderInfo,
  getEndpoint,
  getBaseUrl,
} from './apiUtils';

export async function getTimes(
  scheduleId,
  appointmentTypeId,
  date,
  maxDays = 4,
  context = 'patient_now',
  toDate = null,
) {
  const dateUtc = moment(date)
    .startOf('day')
    .utc();
  const nowUtc = moment()
    .startOf('day')
    .utc();
  const duration = moment.duration(dateUtc.diff(nowUtc));
  const days = duration.asDays();
  let appt = {};
  if (
    appointmentTypeId !== '' &&
    appointmentTypeId !== undefined &&
    appointmentTypeId !== null
  ) {
    appt = { appointment_type_id: appointmentTypeId };
  }
  const toD =
    toDate ||
    moment(date)
      .add('1', 'months')
      .zone(date.toString())
      .format();
  return fetch(
    getEndpoint('available-appointment-times', {
      'schedule_ids[]': scheduleId,
      max_days: maxDays,
      from:
        days > 0
          ? moment(date)
              .zone(date.toString())
              .hour(0)
              .minute(0)
              .second(0)
              .format()
          : date.toString(),
      to: toD,
      context,
      ...appt,
    }),
    getHeaderInfo(),
  )
    .then(handleResponse)
    .catch(handleError);
}

export async function getNextTime(
  scheduleId,
  appointmentTypeId,
  date,
  context = 'patient_now',
) {
  return fetch(
    getEndpoint('available-appointment-times', {
      'schedule_ids[]': scheduleId,
      appointment_type_id: appointmentTypeId,
      max_days: 1,
      from: date.toString(),
      to: moment(date)
        .add('1', 'day')
        .toString(),
      context,
    }),
    getHeaderInfo(),
  )
    .then(handleResponse)
    .catch(handleError);
}

export async function getMultipleTimes(
  scheduleIds,
  date,
  appointmentTypeId,
  context = 'patient_now',
) {
  const urlPath = `${getBaseUrl()}/available-appointment-times`;
  const baseParams = {
    context,
    max_days: 1,
    from: date.toString(),
  };

  if (appointmentTypeId) {
    baseParams.appointment_type_id = appointmentTypeId;
  }

  const urlParams = new URLSearchParams(baseParams);
  scheduleIds.forEach(sid => {
    urlParams.append('schedule_ids[]', sid);
  });

  const fetchUrl = `${urlPath}?${urlParams}`;

  return fetch(fetchUrl, getHeaderInfo())
    .then(handleResponse)
    .catch(handleError);
}
