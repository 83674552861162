import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Alert from 'react-bootstrap/Alert';
import { FormControlLabel, makeStyles, RadioGroup } from '@material-ui/core';
import DialogMobile from './DialogMobile';
import CustomRadio from '../form/CustomRadio';

const useStyles = makeStyles(() => ({
  root: {
    display: 'block',
    width: '100%',
  },
}));

const DialogRadio = ({
  showDialog,
  handleShowDialog,
  icon,
  selections,
  title,
  selectedValue,
  forceSelection,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const selection = selections.find(s => s.value === selectedValue);

  const [radioValue, setRadioValue] = useState({
    error: false,
    value: '',
    text: selection ? selection.text : '',
  });

  const handleChange = e => {
    setRadioValue({
      ...radioValue,
      value: e.target.value,
      text: e.target.getAttribute('text'),
    });
  };

  const closeDialog = value => {
    if (value) {
      return handleShowDialog(radioValue);
    }

    return handleShowDialog();
  };

  const getContent = selections => {
    if (!selections.length) {
      return <Alert variant="secondary">There are no options available.</Alert>;
    }

    return (
      <RadioGroup
        aria-label={title}
        row
        onChange={e => handleChange(e)}
        defaultValue={selectedValue}
      >
        {!forceSelection && (
          <FormControlLabel
            className={classes.root}
            value=""
            control={<CustomRadio inputProps={{ text: t('choose') }} />}
            label={t('DialogRadio.choose')}
          />
        )}
        {selections.map(selection => (
          <FormControlLabel
            className={classes.root}
            key={selection.value}
            value={selection.value}
            control={<CustomRadio inputProps={{ text: selection.text }} />}
            label={selection.text}
          />
        ))}
      </RadioGroup>
    );
  };

  return (
    <>
      <DialogMobile
        content={getContent(selections)}
        icon={icon}
        title={title}
        showDialog={showDialog}
        handleShowDialog={value => closeDialog(value)}
      />
    </>
  );
};

DialogRadio.defaultProps = {
  icon: null,
  forceSelection: false,
};

DialogRadio.propTypes = {
  handleShowDialog: PropTypes.func.isRequired,
  icon: PropTypes.element,
  showDialog: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  selections: PropTypes.instanceOf(Array).isRequired,
  selectedValue: PropTypes.string.isRequired,
  forceSelection: PropTypes.bool,
};

export default DialogRadio;
