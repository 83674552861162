import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getProviders } from 'api/providerApi';
import { BrandingContext } from '../contexts/BrandingContext';
import BreadCrumbs from 'components/common/BreadCrumbs';
import './Sitemap.scss';

const SitemapProviders = () => {
  const { branding } = useContext(BrandingContext);
  const [providers, setProviders] = useState(null);

  useEffect(() => {
    getProviders(1, 999).then(setProviders);
  }, []);
  const providerMap = [];
  if (providers !== null) {
    providers.data.forEach(element => {
      providerMap.push({
        name: element.attributes.name,
        link: `/provider/${element.attributes.permalink}`,
      });
    });
  }

  providerMap.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return (
    <div className="Sitemap">
      <div className="container">
        <BreadCrumbs sitemap></BreadCrumbs>
        <ul>
          {providerMap.map(item => (
            <li key={item.name}>
              <Link to={item.link}>{item.name}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SitemapProviders;
