import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MapIcon from '@material-ui/icons/Map';
import ListIcon from '@material-ui/icons/List';
import { WatchLater } from '@material-ui/icons';
import DateRangeIcon from '@material-ui/icons/DateRange';
import moment from 'moment-timezone';
import { BrandingContext } from 'components/contexts/BrandingContext';
import InventoryDateCarousel from 'components/inventory/InventoryDateCarousel';
import 'components/inventory/InventoryControls.scss';
import DateCarousel from 'components/appointmentPicker/DateCarousel';
import DialogMobile from 'components/dialogs/DialogMobile';
import PickerDate from 'components/dialogs/DatePicker';

const InventoryControlsMobile = ({
  showDateCarousel,
  showMapView,
  setShowMapView,
  setTimeWindow,
  timezoneName,
  isFacility,
  dates,
  onDateChange,
  handleSetDates,
}) => {
  const { branding } = useContext(BrandingContext);
  const useStyles = makeStyles(() => ({
    mapButton: {
      background: 'transparent',
      border: 'none',
      appearance: 'none',
    },
  }));
  const classes = useStyles();

  const [showTimePickerDialog, setShowTimePickerDialog] = useState(false);
  const [date, setDate] = useState({});

  return (
    <div className="InventoryControls">
      <Grid className="date-row" container justify="center">
        {showDateCarousel ? <></> : <Grid item xs={1} />}
        {showMapView && (
          <Grid item xs={showDateCarousel ? 2 : 1}>
            <button
              aria-label="Calender Icon"
              className={`view-button ${classes.mapButton}`}
              type="button"
              onClick={() => setShowMapView(false)}
            >
              <ListIcon style={{ marginRight: '5px' }} />
            </button>
          </Grid>
        )}
        {!showMapView && (
          <Grid item xs={showDateCarousel ? 2 : 1}>
            <button
              aria-label="Calender Icon"
              className={`view-button ${classes.mapButton}`}
              type="button"
              onClick={() => setShowMapView(true)}
            >
              <MapIcon style={{ marginRight: '5px' }} />
            </button>
          </Grid>
        )}
        <Grid item xs={8} style={{ color: branding.primaryColor }}>
          {!showDateCarousel && `Today, ${moment().format('MMM D')}`}
          {timezoneName && showDateCarousel && isFacility ? (
            <DateCarousel
              dates={dates}
              onDateChange={onDateChange}
              timezoneName={timezoneName}
              dateHeadingLayout="horizontal"
            />
          ) : (
            timezoneName &&
            showDateCarousel && (
              <InventoryDateCarousel
                timezoneName={timezoneName}
                setTimeWindow={setTimeWindow}
              />
            )
          )}
        </Grid>
        {!showMapView && isFacility && (
          <Grid item xs={2}>
            <button
              aria-label="Calender Icon"
              className={`view-button ${classes.mapButton}`}
              type="button"
              onClick={() => setShowTimePickerDialog(true)}
            >
              <DateRangeIcon style={{ marginRight: '5px' }} />
            </button>
          </Grid>
        )}
        {showTimePickerDialog && (
          <DialogMobile
            content={
              <PickerDate
                handleChange={e => {
                  const startDate = moment(e?.start);
                  if (startDate.isValid()) {
                    setDate(e);
                  }
                }}
                timezone={timezoneName}
                timestamp={moment(dates[0])
                  .tz(timezoneName)
                  .format()}
              />
            }
            handleShowDialog={e => {
              setShowTimePickerDialog(false);
              if (e) {
                onDateChange(moment(date?.start).tz(timezoneName));
                handleSetDates(date?.start);
              }
            }}
            icon={<WatchLater />}
            showDialog={showTimePickerDialog}
            title="Date Picker"
          />
        )}

        {showDateCarousel ? <></> : <Grid item xs={1} />}
      </Grid>
    </div>
  );
};

InventoryControlsMobile.defaultProps = {
  showDateCarousel: false,
  showMapView: false,
  setShowMapView: () => { },
  isFacility: false,
  dates: [],
  onDateChange: () => { },
  handleSetDates: () => { },
};

InventoryControlsMobile.propTypes = {
  showMapView: PropTypes.bool,
  setShowMapView: PropTypes.func,
  showDateCarousel: PropTypes.bool,
  timezoneName: PropTypes.string.isRequired,
  setTimeWindow: PropTypes.func.isRequired,
  isFacility: PropTypes.bool,
  dates: PropTypes.arrayOf(Object),
  onDateChange: PropTypes.func,
  handleSetDates: PropTypes.func,
};

export default InventoryControlsMobile;
