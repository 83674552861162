import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';
import camel from 'camelcase-keys';
import PageMetaInfo from 'components/common/PageMetaInfo';
import ScheduleResult from 'components/result/ScheduleResult';
import { BrandingContext } from 'components/contexts/BrandingContext';
import { useFacilityServices } from 'hooks/useFacilityServices';
import { useQueryString } from 'hooks/useQueryString';
import { isMobile } from 'react-device-detect';

const ScheduleResultList = ({
  results: actualResults,
  locations,
  providers,
  loading,
  venue,
  selectedParams,
  patientNowTimes,
  uxMode,
  showResults,
  datesLoading,
  dates,
  dateCurrent,
  noOfDays,
  startTime,
  endTime,
  scrollLeft,
  onScroll,
  setWhoIsScrolling,
  whoIsScrolling,
  facilityPageTitle,
  facilityPageDesc1,
  facilityPageDesc2,
  canonicalLink,
  type,
  facilities,
}) => {
  const [t] = useTranslation();
  const { params, isDischargePage } = useQueryString();
  const { appointmentTypeId } = selectedParams;
  const { branding } = useContext(BrandingContext);
  const { facilityServices } = useFacilityServices();
  const styles = {
    keywordsTitle: {
      backgroundColor: branding.secondaryColor,
      color: branding.primaryColor,
      padding: '20px',
    },
  };
  const checkIfFacilityLocationOrDischarge = () =>
    type === 'facility' || type === 'location' || isDischargePage;
  const appointmentTypeCheck = () =>
    checkIfFacilityLocationOrDischarge() ? true : appointmentTypeId;
  const [propsResults, setPropsResults] = useState(actualResults);

  useEffect(() => {
    if (Object.prototype.toString.call(actualResults) !== '[object Array]') {
      const finalResults = {};
      if (!isDischargePage) {
        Object.keys(actualResults).forEach(key => {
          const keyFilters = actualResults?.[key]?.filter(
            ar => !ar?.attributes?.hidden,
          );
          if (keyFilters) {
            finalResults[key] = keyFilters;
          }
          setPropsResults(finalResults);
        });
      } else {
        setPropsResults(actualResults);
      }
    } else if (!isDischargePage) {
      setPropsResults(actualResults.filter(ar => !ar?.attributes?.hidden));
    } else {
      setPropsResults(actualResults);
    }
  }, [actualResults]);

  const getScheduleLocation = schedule => {
    const loc = find(locations, {
      id: schedule.relationships.location.data.id,
    });
    if (loc) return loc.attributes;
    return null;
  };

  const getScheduleProvider = schedule => {
    if (!schedule.relationships.provider.data) return null;
    const provider = find(providers, {
      id: schedule.relationships.provider.data.id,
    });
    if (provider) return provider;
    return null;
  };

  const getScheduleFacility = schedule => {
    if (!schedule.relationships.facility.data) return null;
    const facility = find(facilities, {
      id: schedule?.relationships?.facility?.data?.id,
    });
    if (facility) return facility?.attributes;
    return null;
  };

  const renderScheduleResult = schedule => {
    const inactive =
      schedule.attributes.active !== null
        ? schedule.attributes.active !== true
        : false;
    return (
      <ScheduleResult
        patientNowTimes={patientNowTimes}
        schedule={{
          ...camel(schedule.attributes),
          id: schedule.id,
          facility: schedule?.relationships?.facility?.data,
        }}
        appointmentTypeId={appointmentTypeId}
        location={getScheduleLocation(schedule)}
        provider={getScheduleProvider(schedule)}
        facility={getScheduleFacility(schedule)}
        startTime={startTime}
        endTime={endTime}
        selectedParams={selectedParams}
        venueType={
          venue && venue.attributes
            ? venue.attributes.venueType
            : 'physician_services'
        }
        key={schedule.id}
        uxMode={uxMode}
        datesShow={dates}
        datesLoading={datesLoading}
        dateCurrent={dateCurrent}
        noOfDays={noOfDays}
        scrollLeft={scrollLeft}
        onScroll={onScroll}
        setWhoIsScrolling={setWhoIsScrolling}
        whoIsScrolling={whoIsScrolling}
        inactive={
          schedule.attributes.active !== null
            ? schedule.attributes.active === true
              ? false
              : true
            : false
        }
        type={type}
      />
    );
  };

  const CreateFacilityPageDescription = () => {
    let facilityPageDescription = '';
    const serviceMap = [];
    if (propsResults !== undefined && propsResults.length) {
      propsResults.forEach(element => {
        serviceMap.push({
          name: element.attributes.service.name,
          id: element.attributes.service.id,
        });
      });
    }
    const uniqueSrvices = [];
    serviceMap.forEach(s => {
      if (uniqueSrvices.indexOf(s.name) === -1) {
        uniqueSrvices.push(s.name);
      }
    });
    let commaSeparated1 = uniqueSrvices.filter(item => item).join(', ');
    if (uniqueSrvices.length <= 2) {
      commaSeparated1 = uniqueSrvices.join(' and ');
    } else {
      commaSeparated1 = `${uniqueSrvices.slice(0, -1).join(', ')} and ${uniqueSrvices[uniqueSrvices.length - 1]
        }`;
    }
    if (commaSeparated1 !== '') {
      facilityPageDescription =
        facilityPageDesc1 + commaSeparated1 + facilityPageDesc2;
    }
    return facilityPageDescription;
  };
  const facilityPageDescription = CreateFacilityPageDescription();

  return (
    <div className="ResultList">
      {/* <PageMetaInfo title={facilityPageTitle} canonicalLink={canonicalLink} /> */}
      {loading && appointmentTypeCheck() && (
        <Alert
          style={{
            background: branding.secondaryColor,
            color: branding.primaryColor,
          }}
        >
          <Alert.Heading>{t('ScheduleResultList.hangTight')}!</Alert.Heading>
          {t('ScheduleResultList.fetchingresults')}...
        </Alert>
      )}
      {!loading && propsResults.length === 0 && showResults && (
        <Alert
          style={{
            background: branding.secondaryColor,
            color: branding.primaryColor,
          }}
        >
          {propsResults.length === 0 && (
            <>
              <Alert.Heading>{t('ResultList.weTried')}</Alert.Heading>
              {t('ResultList.noResult')}
            </>
          )}
          {venue != null &&
            venue.attributes != null &&
            venue.attributes.venueType === 'physician_services' &&
            propsResults.length === 0 &&
            !appointmentTypeId && (
              <>
                <Alert.Heading>
                  {t('ResultList.selectAppointmentType')}
                </Alert.Heading>
              </>
            )}
        </Alert>
      )}

      {!loading &&
        locations &&
        Array.isArray(propsResults) &&
        propsResults.map(schedule => renderScheduleResult(schedule))}

      {!loading &&
        locations &&
        !Array.isArray(propsResults) &&
        typeof propsResults === 'object' &&
        Object.keys(facilityServices).length > 0 &&
        Object.keys(propsResults).map(key => (
          <>
            {propsResults[key].length > 0 && (
              <Typography
                variant="h6"
                component="h2"
                className="keywords-heading"
                style={styles.keywordsTitle}
              >
                {facilityServices[key].attributes.name}
              </Typography>
            )}
            {propsResults[key].map(result => renderScheduleResult(result))}
          </>
        ))}
    </div>
  );
};

ScheduleResultList.defaultProps = {
  results: [],
  locations: [],
  providers: [],
  loading: false,
  venue: null,
  selectedParams: {},
  patientNowTimes: false,
  showResults: true,
  datesLoading: true,
  dates: [],
  dateCurrent: '',
  startTime: '',
  endTime: '',
  noOfDays: 1,
  scrollLeft: {},
  onScroll: () => { },
  setWhoIsScrolling: () => { },
  whoIsScrolling: '',
};

ScheduleResultList.propTypes = {
  results: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
  ]),
  locations: PropTypes.instanceOf(Array),
  providers: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  patientNowTimes: PropTypes.bool,
  venue: PropTypes.instanceOf(Object),
  selectedParams: PropTypes.instanceOf(Object),
  uxMode: PropTypes.string.isRequired,
  showResults: PropTypes.bool,
  dates: PropTypes.instanceOf(Array),
  datesLoading: PropTypes.bool,
  dateCurrent: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  noOfDays: PropTypes.number,
  scrollLeft: PropTypes.instanceOf(Object),
  onScroll: PropTypes.func,
  setWhoIsScrolling: PropTypes.func,
  whoIsScrolling: PropTypes.string,
  facilityPageTitle: PropTypes.string,
  facilityPageDesc1: PropTypes.string,
  facilityPageDesc2: PropTypes.string,
  canonicalLink: PropTypes.string,
  type: PropTypes.string,
  facilities: PropTypes.instanceOf(Array),
};

export default ScheduleResultList;
