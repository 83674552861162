import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Location from 'components/common/Location';
import Phone from 'components/common/Phone';
import moment from 'moment-timezone';
import './ResultListLocation.scss';

const ResultListSchedule = ({
  provider,
  scheduleId,
  appointmentType,
  times,
  servicePermalink,
}) => {
  if (times.length === 0) return <></>;
  return (
    <Grid item xs={12} sm={5}>
      <div className="available-times">
        <p className="hide-mobile">Available Time Today</p>
        <div className="button-container">
          {times.slice(0, 6).map(time => (
            <Link
              key={time}
              to={`/providers/${
                provider.id
              }/schedule/${scheduleId}/appointment-type/${appointmentType}?date=${moment(
                time,
              ).format('YYYYMMDDHHmm')}`}
            >
              <Button className="btn-default-outline" type="button" size="sm">
                {moment(time).format('h:mm a')}
              </Button>
            </Link>
          ))}
          <Link to="/" className="link-default">
            {/** TODO: Link to Add to Waitlist */}
          </Link>
        </div>
      </div>
    </Grid>
  );
};

ResultListSchedule.propTypes = {
  provider: PropTypes.instanceOf(Object).isRequired,
  scheduleId: PropTypes.string.isRequired,
  appointmentType: PropTypes.string.isRequired,
  times: PropTypes.instanceOf(Array).isRequired,
};

const ResultListLocation = ({
  phone,
  location,
  availableTimes,
  hideSchedule,
  provider,
}) => {
  const displaySchedules = () => {
    if (
      availableTimes &&
      availableTimes.times &&
      availableTimes.times.length > 0
    ) {
      return (
        <ResultListSchedule
          provider={provider}
          scheduleId={availableTimes.scheduleId}
          appointmentType={availableTimes.appointmentType}
          times={availableTimes.times}
        />
      );
    }
    return <></>;
  };

  const providerPhone = !phone.trim() ? location.phone : phone;
  if (
    !(
      location.facilityName &&
      location.address &&
      location.city &&
      location.state &&
      location.zip &&
      providerPhone
    )
  )
    return null;
  return (
    <div className="ResultListLocation">
      <Grid container className="provider-location" spacing={2}>
        <Grid item xs={12} sm={hideSchedule ? 12 : 7}>
          <Location location={location} />
          <Phone phoneNumber={providerPhone} />
        </Grid>

        {!hideSchedule && displaySchedules()}
      </Grid>
    </div>
  );
};

ResultListLocation.defaultProps = {
  hideSchedule: false,
  availableTimes: {
    scheduleId: null,
    appointmentType: null,
    times: [],
  },
  phone: '',
  provider: null,
};

ResultListLocation.propTypes = {
  phone: PropTypes.string,
  location: PropTypes.instanceOf(Object).isRequired,
  availableTimes: PropTypes.shape({
    scheduleId: PropTypes.string,
    appointmentType: PropTypes.string,
    times: PropTypes.instanceOf(Array),
  }),
  hideSchedule: PropTypes.bool,
  provider: PropTypes.instanceOf(Object),
  servicePermalink: PropTypes.string,
};

export default ResultListLocation;
