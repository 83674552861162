import React from 'react';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'components/common/BreadCrumbs';
import PageError from './PageError';

export default function PageNotFound({ errorCode }) {
  const [t] = useTranslation();

  return (
    <div>
      <PageError
        title={t('PageNotFound.notFound.title')}
        subtext={t('PageNotFound.notFound.subtext')}
        content={t('PageNotFound.notFound.content')}
        code={errorCode || '404'}
      />
    </div>
  );
}
