import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getPlaces } from 'api/geocodingApi';
import './ZipcodeAutocompleteOptions.scss';
import { useParams } from 'react-router-dom';

const useAlertOutside = (ref, setShouldBeDisplayed) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref?.current && !ref?.current?.contains(event.target)) {
        setShouldBeDisplayed(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleClickOutside);

    return () => {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleClickOutside);
    };
  }, [ref]);
};

const ZipcodeAutocompleteOptions = ({
  onSelect,
  onBlur,
  searchTerm,
  hidden,
}) => {
  const [options, setOptions] = useState([]);
  const urlParams = useParams();
  const [shouldBeDisplayed, setShouldBeDisplayed] = useState(false);
  const autoCompleteRef = useRef(null);
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    const getOptions = async searchTerm => {
      await getPlaces(searchTerm, setOptions);
    };

    if (searchTerm?.length > 2 || urlParams?.city) {
      getOptions(searchTerm || urlParams.city);
    }
  }, [searchTerm, setOptions]);

  useEffect(() => {
    if (
      options?.length > 0 &&
      urlParams?.city &&
      urlParams?.state &&
      !selected
    ) {
      const matched = options.find(
        o =>
          o?.terms?.[0]?.value?.toLowerCase() ===
            urlParams?.city?.toLowerCase() &&
          o?.terms?.[1]?.value?.toLowerCase() ===
            urlParams?.state?.toLowerCase(),
      );
      if (Object.keys(matched).length > 0) {
        onSelect(matched.zipcode);
        setSelected(true);
      }
    }
  }, [options.length]);

  const selectZipcode = e => {
    e.preventDefault();
    onSelect(e.target.dataset.zipcode);
  };

  useEffect(() => {
    const showAutoComplete =
      !hidden &&
      options.length > 0 &&
      searchTerm?.length > 2 &&
      !options.map(o => o.zipcode).includes(searchTerm);
    setShouldBeDisplayed(showAutoComplete);
  }, [searchTerm]);

  // const shouldBeDisplayed =
  // !hidden &&
  // options.length > 0 &&
  // searchTerm.length > 2 &&
  // !options.map(o => o.zipcode).includes(searchTerm);

  useAlertOutside(autoCompleteRef, setShouldBeDisplayed);

  return (
    shouldBeDisplayed && (
      <div
        onBlur={onBlur}
        id="links"
        ref={autoCompleteRef}
        className="ZipcodeAutocompleteOptions"
      >
        {options.length &&
          options.map(o => (
            <div
              key={o.id}
              data-zipcode={o.zipcode}
              role="button"
              tabIndex="-1"
              onClick={selectZipcode}
              onKeyDown={selectZipcode}
            >
              {o.text}
            </div>
          ))}
      </div>
    )
  );
};

ZipcodeAutocompleteOptions.defaultProps = {
  onBlur: () => {},
};

ZipcodeAutocompleteOptions.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired,
  onBlur: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
};

export default ZipcodeAutocompleteOptions;
