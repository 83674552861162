import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { getLocationById } from 'redux/reducers/providersReducer';
import LocationDetail from 'components/common/LocationDetail';

/**
 * This component wraps and connects LocationDetail
 * to Redux, allowing for loading via Location ID.
 *
 * This is used in the Provider Detail Page.
 */
const ProviderLocation = ({ location }) => {
  if (isEmpty(location)) return <></>;
  const { attributes } = location;
  return <LocationDetail location={attributes} />;
};

ProviderLocation.defaultProps = {
  location: {},
};

ProviderLocation.propTypes = {
  location: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state, props) => ({
  location: getLocationById(state, props.locationId),
});

export default connect(mapStateToProps)(ProviderLocation);
