import * as searchConstants from './searchConstants';

const ALL_SEARCH_FILTERS = [
  searchConstants.TAKING_NEW_PATIENTS,
  searchConstants.GENDERS,
  searchConstants.LANGUAGES,
  searchConstants.HOSPITAL_AFFILIATIONS,
];

const serviceLineGroups = {
  GROUP_A: {
    search: [
      searchConstants.ZIP_CODE_INPUT,
      searchConstants.DOCTOR_NAME_SPECIALITY_INPUT,
      searchConstants.DATE_TIME_INPUT,
      searchConstants.INSURANCE_INPUT,
      searchConstants.APP_TYPE_INPUT,
    ],
    filter: ALL_SEARCH_FILTERS,
  },
  GROUP_B: {
    search: [searchConstants.ZIP_CODE_INPUT],
    filter: [],
  },
  GROUP_C: {
    search: [searchConstants.ZIP_CODE_INPUT, searchConstants.APP_TYPE_INPUT],
    filter: [],
  },
  GROUP_D: {
    search: [
      searchConstants.ZIP_CODE_INPUT,
      searchConstants.DATE_TIME_INPUT,
      searchConstants.INSURANCE_INPUT,
      searchConstants.APP_TYPE_INPUT,
    ],
    filter: [...ALL_SEARCH_FILTERS, searchConstants.SERVICES],
  },
  GROUP_E: {
    search: [
      searchConstants.ZIP_CODE_INPUT,
      searchConstants.DATE_TIME_INPUT,
      searchConstants.INSURANCE_INPUT,
      searchConstants.APP_TYPE_INPUT,
    ],
    filter: [searchConstants.SERVICES],
  },
  GROUP_F: {
    search: [
      searchConstants.ZIP_CODE_INPUT,
      searchConstants.DOCTOR_NAME_SPECIALITY_INPUT,
      searchConstants.DATE_TIME_INPUT,
      searchConstants.INSURANCE_INPUT,
      searchConstants.APP_TYPE_INPUT,
    ],
    filter: [...ALL_SEARCH_FILTERS, searchConstants.SERVICES],
  },
  GROUP_G: {
    search: [
      searchConstants.ZIP_CODE_INPUT,
      searchConstants.DATE_TIME_INPUT,
      searchConstants.APP_TYPE_INPUT,
    ],
    filter: [...ALL_SEARCH_FILTERS, searchConstants.SERVICES],
  },
};

export default serviceLineGroups;
