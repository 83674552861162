import React, { useState } from 'react';
import { Avatar, makeStyles, useTheme } from '@material-ui/core';
import { capitalize } from 'lodash';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';

const textStyles = makeStyles({
  formContainer: {
    position: 'relative',
  },
  iconContainer: {
    left: '10px',
    position: 'absolute',
    top: '13px',
    zIndex: 2,
  },
  input: {
    padding: '9.5px 14px',
    '&&:focus': {
      outline: '1px solid transparent',
    },
  },
  iconPad: {
    maxWidth: '100%',
    overflow: 'hidden',
    paddingLeft: '25px !important',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  inputStyleFilled: {
    background: '#f8f9fa',
  },
  clipText: {
    maxWidth: '75%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

const Omnibox = ({
  icon,
  filled,
  options,
  handleChange,
  handleOnBlur,
  defaultValue,
}) => {
  const classes = textStyles();
  const [inputValue, setInputValue] = useState('');
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'));
  const [t] = useTranslation();
  const currentLocale = localStorage.getItem('i18nextLng');
  // only show Providers if user has filled in the search box.
  const renderOptions =
    inputValue.length > 0
      ? options
      : [...options.filter(o => o.type !== 'Provider')];

  const getClassName = () => {
    if (icon) {
      return classes.iconPad;
    }

    if (isTablet) {
      return classes.clipText;
    }

    return '';
  };
  return (
    <Autocomplete
      aria-label={t('Omnibox.placeholder.doctorName')}
      id="Omnibox"
      onChange={(_, value) => {
        handleChange(value);
      }}
      options={renderOptions.sort((a, b) => -b.type.localeCompare(a.type))}
      getOptionLabel={option => option.name}
      groupBy={option => capitalize(option.type)}
      freeSolo
      defaultValue={defaultValue}
      renderInput={params => {
        return (
          <div className={classes.formContainer}>
            <div className={classes.iconContainer}>{icon}</div>
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              className={`${filled ? classes.inputStyleFilled : ''} ${currentLocale?.includes('es') || currentLocale?.includes('fr')
                  ? 'inputElipsis'
                  : ''
              }`}
              label={
                <div className={`${getClassName()}`}>
                  {t('Omnibox.placeholder.doctorName')}
                </div>
              }
              inputProps={{
                ...params.inputProps,
                className: classes.input,
              }}
              variant="outlined"
              fullWidth
              onChange={e => {
                const { value } = e.target;
                setInputValue(value);
                const obj = { name: value };
                handleChange(obj);
              }}
              onBlur={() => handleOnBlur()}
            />
          </div>
        );
      }}
      renderOption={option => (
        <>
          {option.type === 'Provider' && (
            <Avatar
              style={{ marginRight: '10px' }}
              alt={option.name}
              src={option.image}
            />
          )}
          {option.name}
        </>
      )}
    />
  );
};

Omnibox.defaultProps = {
  options: [],
  handleChange: () => {},
  handleOnBlur: () => {},
  icon: null,
  filled: false,
  defaultValue: { name: '', type: '', id: '' },
};

Omnibox.propTypes = {
  /** Array of selectable Option objects.
   * Option attributes: id, name (required), type (required), image. */
  options: PropTypes.instanceOf(Array),
  /** Function to lift up state to parent component */
  handleChange: PropTypes.func,
  handleOnBlur: PropTypes.func,
  icon: PropTypes.element,
  filled: PropTypes.bool,
  defaultValue: PropTypes.instanceOf(Object),
};

export default Omnibox;
