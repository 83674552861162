import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { BrandingContext } from 'components/contexts/BrandingContext';
import ProviderSkeleton from './ProviderSkeleton';
import ResultListLocation from '../inventory/ResultListLocation';
import { useQueryString } from '../../hooks/useQueryString';
import './Provider.scss';
import QueryString from 'query-string';
import { setProviderAvatar } from '../../helpers/selectProviderAvatar';
import { useSelector } from 'react-redux';
const Provider = ({
  provider,
  availableTimes,
  loading,
  location,
  locations,
  hideSchedule,
  insurance,
  noLink,
  servicePermalink,
  schedules,
  facility
}) => {
  const { branding } = useContext(BrandingContext);
  const { isDischargePage, urlParams } = useQueryString();
  const { search } = useLocation();
  const params = QueryString.parse(search);
  const healthSystem = useSelector(state => state.healthSystem);
  const useStyles = makeStyles({
    primaryHeading: {
      color: branding.primaryColor,
    },
  });
  const classes = useStyles();
  const dischargeLink = () => {
    return isDischargePage ? `/${urlParams.dischargeFacility}/discharge` : '';
  };
  const getLocationData = id => {
    const defaultLocation = {
      address: '',
      city: '',
      'facility-name': '',
      latitude: 0,
      longitude: 0,
      name: '',
      phone: '',
      state: '',
      zip: '',
    };
    const location = locations.find(obj => obj.id === id) || {};

    return {
      id,
      ...{
        ...defaultLocation,
        ...location.attributes,
      },
    };
  };
  if (!provider || provider.attributes === undefined || loading)
    return <ProviderSkeleton />;

  let service = schedules?.find(schedule => schedule?.attributes?.service?.permalink === params?.serviceName);

  return (
    <Grid className="provider" container>
      <Grid
        item
        sm={3}
        xs={3}
        style={{ textAlign: 'center' }}
        className="hide-mobile"
      >
        <div className="hide-mobile">
          {provider.attributes &&
            setProviderAvatar(
              provider,
              locations?.length > 0 && facility ? locations[0]?.attributes : {},
              service && service?.attributes ? service?.attributes?.service?.permalink : schedules?.length > 0 ? schedules[0]?.attributes?.service?.permalink : '',
              healthSystem,
              service ? service?.attributes?.service?.name : schedules?.length > 0 ? schedules[0]?.attributes?.service?.name : '',
              facility
            )}
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={9}
        container
        direction="column"
        className="provider-container"
      >
        <Grid container>
          <Grid item xs={4} className="avatar-container hide-desktop">
            {provider.attributes &&
              setProviderAvatar(
                provider,
                locations?.length > 0 && facility ? locations[0]?.attributes : {},
                service && service?.attributes ? service?.attributes?.service?.permalink : schedules?.length > 0 ? schedules[0]?.attributes?.service?.permalink : '',
                healthSystem,
                service ? service?.attributes?.service?.name : schedules?.length > 0 ? schedules[0]?.attributes?.service?.name : '',
                facility
              )}
          </Grid>

          <Grid item xs={8} sm={12}>
            {noLink && (
              <Typography
                component="h1"
                className={`provider-name ${classes.primaryHeading}`}
              >
                {provider.attributes.name}
                {provider.attributes.suffix &&
                  `, ${provider.attributes.suffix}`}
              </Typography>
            )}
            {!noLink &&
              provider?.attributes?.providerType === 'HealthResource' && (
                <Typography
                  component="h1"
                  className={`provider-name ${classes.primaryHeading}`}
                >
                  {provider.attributes.name}
                  {provider.attributes.suffix &&
                    `, ${provider.attributes.suffix}`}
                </Typography>
              )}
            {!noLink &&
              provider?.attributes?.providerType !== 'HealthResource' && (
                <Link
                  className="provider-name-link"
                  to={`${dischargeLink()}/providers/${provider.id
                    }?${QueryString.stringify(params)}`}
                >
                  {provider.attributes.name}
                  {provider.attributes.suffix &&
                    `, ${provider.attributes.suffix}`}
                </Link>
              )}
            {insurance && <p>{insurance}</p>}
            {provider.attributes.subspecialties &&
              provider.attributes.subspecialties.length > 0 && (
                <p className="provider-title">
                  {provider.attributes.subspecialties
                    .map(s => s.name)
                    .join(', ')}
                </p>
              )}

            {!location &&
              provider.relationships.locations.data &&
              provider.relationships.locations.data.map(location => (
                <ResultListLocation
                  key={location.id}
                  location={getLocationData(location.id)}
                  availableTimes={availableTimes}
                  phone={location.phone}
                  hideSchedule={hideSchedule}
                  provider={provider}
                  servicePermalink={
                    !isNaN(params.service) === true
                      ? servicePermalink === undefined
                        ? params?.serviceName
                        : servicePermalink
                      : params.service
                  }
                />
              ))}
            {location && (
              <ResultListLocation
                key={location.latitude}
                location={location}
                availableTimes={availableTimes}
                phone={location.phone}
                hideSchedule={hideSchedule}
                provider={provider}
                servicePermalink={
                  !isNaN(params.service) === true
                    ? servicePermalink === undefined
                      ? params?.serviceName
                      : servicePermalink
                    : params.service
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Provider.defaultProps = {
  provider: null,
  loading: false,
  location: null,
  locations: [],
  hideSchedule: false,
  availableTimes: {
    scheduleId: null,
    appointmentType: null,
    times: [],
  },
  insurance: '',
  noLink: false,
};

Provider.propTypes = {
  hideSchedule: PropTypes.bool,
  loading: PropTypes.bool,
  location: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    id: PropTypes.number,
    attributes: PropTypes.instanceOf(Object),
  }),
  locations: PropTypes.instanceOf(Array),
  provider: PropTypes.instanceOf(Object),
  availableTimes: PropTypes.shape({
    scheduleId: PropTypes.string,
    appointmentType: PropTypes.string,
    times: PropTypes.instanceOf(Array),
  }),
  insurance: PropTypes.string,
  noLink: PropTypes.bool,
  servicePermalink: PropTypes.string,
  schedules: PropTypes.instanceOf(Array),
  facility: PropTypes.instanceOf(Object),
};

export default Provider;
