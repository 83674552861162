import { find } from 'lodash';
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function appointmentTypesReducer(
  state = initialState.appointmentTypes,
  action,
) {
  switch (action.type) {
    case types.BEGIN_FETCH_APPOINTMENT_TYPES:
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: true,
        },
      };
    case types.FETCH_APPOINTMENT_TYPES_SUCCESS:
      return action.appointmentTypes;
    default:
      return state;
  }
}

// Selectors
export const getAppointmentTypeById = (state, id) => {
  return find(state.appointmentTypes.data, p => p.id === id);
};
