import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function searchResultReducer(
  state = initialState.searchResult,
  action,
) {
  switch (action.type) {
    case types.BEGIN_SEARCH:
      return {
        ...state,
        searching: true,
      };
    case types.END_SEARCH:
      return {
        ...state,
        searching: false,
      };
    case types.RESET_SEARCH_RESULT:
      return initialState.searchResult;
    case types.UPDATE_SEARCH_RESULT:
      return {
        ...state,
        providers: action.providers,
        schedules: action.schedules,
      };
    case types.FILTERED_SEARCH_RESULT:
      return { ...state, ...action.paramsData };
    case types.FETCH_AVAILABLE_TIMES_SUCCESS:
      return { ...state, availableTimes: action.availableTimes };
    default:
      return state;
  }
}
