import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import BrandingButton from 'components/common/BrandingButton';
import './PageError.scss';

const PageError = ({ title, subtext, content, code }) => {
  const history = useHistory();

  return (
    <main className="PageError container">
      <h1>{title}</h1>
      <h3>{subtext}</h3>
      <p>{content}</p>
      <p>Error Code: {code}</p>
      <hr />
      <BrandingButton type="button" onClick={() => history.push('/')}>
        Return to Home Page
      </BrandingButton>
    </main>
  );
};

PageError.propTypes = {
  title: PropTypes.string.isRequired,
  subtext: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

export default PageError;
