import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import QueryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from '@material-ui/core';
import { getProviderSchedulesById } from 'redux/reducers/providersReducer';
import BookApptForm from 'components/appointmentPicker/BookApptForm';
import { createEvent } from 'api/analyticsApi';
import ProviderLocationPicker from './ProviderLocationPicker';
import './ProviderDetailSidebar.scss';
import { BlackTheme } from '../form/FormElements';

const ProviderDetailSidebar = ({
  provider,
  insurancePlans,
  schedules,
  updateQueryString,
  appointmentTypeParam,
  nowContext,
}) => {
  const { search } = useLocation();
  const [t] = useTranslation();
  const params = QueryString.parse(search);
  const [form, setForm] = useState({
    insurancePlans: {
      value: '',
    },
    appointmentType: {
      value: appointmentTypeParam,
    },
    date: {
      value: moment(),
    },
  });
  const [appointmentTypeOptions, setAppointmentTypeOptions] = useState([]);

  useEffect(() => {
    if (Number(appointmentTypeParam)) {
      setForm({
        ...form,
        appointmentType: {
          value: appointmentTypeParam,
        },
      });
    }
  }, [appointmentTypeParam]);

  useEffect(() => {
    (async () => {
      setAppointmentTypeOptions(provider.attributes.appointmentTypes);
    })();
  }, [provider, schedules]);

  const postEventData = async appointmentTypeId => {
    const params = {
      zip: localStorage.getItem('zipCode'),
      appointmentTypes: appointmentTypeId,
      insurancePlans: null,
      accepting_new_patients: provider.attributes.acceptingNewPatients,
      gender: provider.attributes.gender === 'm' ? 'male' : 'female',
      searchType: provider.type,
    };
    const voc_value = localStorage.getItem('voc');
    const result_count = 1;
    const response = await createEvent(params, voc_value, result_count);
    const { errors, data } = response;
  };

  const handleChange = (e, key) => {
    postEventData(e.appointmentType.value.toString());
    setForm({
      ...form,
      [key]: e[key],
    });
    updateQueryString({
      appointmentTypes: e.appointmentType.value.toString(),
    });
  };

  const handleDateChange = value => {
    setForm({
      ...form,
      date: { value },
    });
  };

  return (
    <MuiThemeProvider theme={BlackTheme}>
      <div className="ProviderDetailSidebar">
        <BookApptForm
          appointmentType={form.appointmentType}
          appointmentTypes={appointmentTypeOptions}
          form={form}
          handleChange={handleChange}
          insurancePlans={insurancePlans}
          headingLabel={t('ScheduleAppointment.bookAnAppointment')}
          servicePermalink={!isNaN(params.service) === true ? params.serviceName : params.service}
        />

        {!isEmpty(schedules) &&
          schedules.map(s => {
            return (
              <ProviderLocationPicker
                key={s.id}
                providerId={parseInt(provider.id, 10)}
                appointmentTypeId={parseInt(form.appointmentType.value, 10)}
                scheduleId={parseInt(s.id, 10)}
                date={form.date.value}
                changeDate={handleDateChange}
                servicePermalink={!isNaN(params.service) === true ? params.serviceName : params.service}
                nowContext={nowContext}
              />
            );
          })}
      </div>
    </MuiThemeProvider>
  );
};

ProviderDetailSidebar.defaultProps = {
  nowContext: false,
};

ProviderDetailSidebar.propTypes = {
  provider: PropTypes.instanceOf(Object).isRequired,
  insurancePlans: PropTypes.instanceOf(Array).isRequired,
  schedules: PropTypes.instanceOf(Array).isRequired,
  updateQueryString: PropTypes.func.isRequired,
  appointmentTypeParam: PropTypes.string.isRequired,
  nowContext: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
  schedules: getProviderSchedulesById(state, props.provider.id),
});

export default connect(mapStateToProps)(ProviderDetailSidebar);
