import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import MapLink from 'components/map/MapLink';
import './Location.scss';
import { useQueryString } from 'hooks/useQueryString';
import { isUndefined } from 'lodash';


/**
 * Renders location details (address, zip, etc.)
 * and a link to Google Maps search.
 * Directions link appears on the right.
 *
 * @param {*} location Object with keys:
 * - facilityName
 * - address
 * - city
 * - state
 * - zip
 */
const Location = ({ location }) => {
  const {
    urlParams,
  } = useQueryString();
  const { isDischargePage } = useQueryString();
  // Make sure object exists before rendering.
  if (!location) return <></>;
  // Make sure all required fields exist before rendering.
  if (
    !(
      location.facilityName &&
      location.address &&
      location.city &&
      location.state &&
      location.zip
    )
  )

    return <></>;
  return (
    <Grid
      container
      className="Location"
      alignItems="center"
      justify="space-between"
    >
      <Grid item xs={12} className={isUndefined(urlParams.facilityName) ? isDischargePage === true ? 'facilityhead' : 'inventoryhead' : isDischargePage === true ? 'facilityhead' : 'inventoryhead'} >
        <Typography className="heading" variant="h5" component="h3">
          <div>{location.facilityName}</div>
        </Typography>
        {!location.hideAddress && (
          <>
            <p className="location-name">{location.name}</p>
            <p className="location-address">{location.address}</p>
            <p className="location-address">{`${location.city}, ${location.state} ${location.zip}`}</p>
          </>
        )}
      </Grid>
      {!location.hideAddress && (
        <Grid item xs={12}>
          <MapLink location={location} />
        </Grid>
      )}
    </Grid>
  );
};

Location.propTypes = {
  /* Test note */
  location: PropTypes.shape({
    facilityName: PropTypes.string,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    hideAddress: PropTypes.bool,
  }).isRequired,
};

export default Location;
