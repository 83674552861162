import React, { useContext, useEffect } from 'react';
import { findIndex, uniq, isEmpty, isUndefined } from 'lodash';
import PropTypes, { element } from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import QueryString from 'query-string';
import { Breadcrumbs, makeStyles, Typography } from '@material-ui/core';
import { BrandingContext } from 'components/contexts/BrandingContext';
import { clearSearch } from 'redux/actions/searchActions';
import { fetchHealthSystem } from 'redux/actions/healthSystemActions';
import {
  loadActiveServices,
  loadFilteredActiveServices,
} from 'redux/actions/serviceLineActions';
import { cleanServiceLineUrls } from 'helpers/searchOptions';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { useQueryString } from '../../hooks/useQueryString';

const BreadCrumbs = ({
  clearSearch,
  services,
  locationHistory,
  searched,
  onlyHome,
  loadActiveServices,
  loadFilteredActiveServices,
  sitemap,
  fetchHealthSystem,
  healthSystem,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [t] = useTranslation();
  const { branding } = useContext(BrandingContext);
  const { urlParams, params } = useQueryString();
  const servicesReducer = useSelector(state => {
    return state?.serviceLines;
  });

  const isDischargePage = useRouteMatch({
    path: [
      '/:dischargeFacility/discharge',
      '/:dischargeFacility/discharge/providers/:id',
      '/:dischargeFacility/discharge/provider/:permalink',
      '/:dischargeFacility/discharge/:locationPermalink',
      '/:dischargeFacility/discharge/service/:serviceLine',
      '/:dischargeFacility/discharge/:locationPermalink/service/:serviceLine',
    ],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const useStyles = makeStyles({
    breadcrumbs: {
      paddingBottom: '20px',
      paddingTop: '20px',
    },
    a: {
      color: branding.primaryColor,
      '&:hover': branding.primaryColor,
    },
  });
  const healthSystemState = useSelector(state => state.healthSystem);

  useEffect(() => {
    if (healthSystemState === null) fetchHealthSystem();
  }, []);
  if (
    !isUndefined(urlParams.serviceLine) &&
    urlParams.serviceLine !== params.service &&
    !isUndefined(params.service)
  ) {
    // clearSearch();
    const serviceLineParam = cleanServiceLineUrls(urlParams.serviceLine);
    if (params.service !== serviceLineParam) history.push('/');
  }

  const serviceLinePermalink = !isUndefined(urlParams.serviceLine)
    ? urlParams.serviceLine
    : params.service;

  const classes = useStyles();
  useEffect(() => {
    if (
      urlParams.serviceLine === serviceLinePermalink &&
      serviceLinePermalink !== undefined
    ) {
      if (!Object.keys(servicesReducer?.serviceLines)?.length > 0) {
        const serviceLineParam = cleanServiceLineUrls(urlParams.serviceLine);
        loadFilteredActiveServices(serviceLineParam);
      }
    } else if (!Object.keys(servicesReducer?.serviceLines)?.length > 0) {
      loadActiveServices(isDischargePage !== null);
    }
  }, [serviceLinePermalink]);

  const handleGotoHome = () => {
    clearSearch();
    history.push('/');
  };
  let latestSearchPage = [...locationHistory]
    .reverse()
    .find(l => l.pathname === '/search');
  latestSearchPage = isEmpty(latestSearchPage)
    ? [...locationHistory]
      .reverse()
      .find(l => l.pathname.indexOf('/search') > -1)
    : latestSearchPage;

  if (urlParams.serviceLine !== undefined) {
    latestSearchPage = isEmpty(latestSearchPage)
      ? [...locationHistory].reverse()
      : latestSearchPage;
  }

  const homepage = [...locationHistory]
    .reverse()
    .find(l => l.pathname === '/' || l.pathname.indexOf(urlParams.serviceLine));
  const latestSearchLocation = () => {
    if (!isEmpty(latestSearchPage)) {
      return latestSearchPage;
    }

    if (searched && homepage) {
      return homepage;
    }
  };
  let searchDisplayName = !isEmpty(latestSearchPage)
    ? QueryString.parse(latestSearchPage.search).serviceName ||
    QueryString.parse(latestSearchPage.search).service ||
    (urlParams.serviceLine && !isUndefined(services)) ||
    t('BreadCrumbs.searchResults')
    : t('BreadCrumbs.searchResults');
  if (
    searchDisplayName === 'Find A Doctor' ||
    searchDisplayName === 'find-a-doctor'
  ) {
    searchDisplayName = t('LandingPage.findDoctor');
  } else if (
    !isEmpty(latestSearchPage) &&
    QueryString.parse(latestSearchPage.search).service_id
  ) {
    if (services[QueryString.parse(latestSearchPage.search).service_id]) {
      searchDisplayName =
        services[QueryString.parse(latestSearchPage.search).service_id]
          .attributes.name;
      searchDisplayName =
        searchDisplayName === 'Primary Care'
          ? t('LandingPage.findDoctor')
          : searchDisplayName;
    }
  } else if (isEmpty(latestSearchPage) && urlParams.serviceLine) {
    const service = Object.values(services).find(s => s.type === 'services');
    if (service) {
      searchDisplayName =
        service.attributes.name === 'Primary Care'
          ? t('LandingPage.findDoctor')
          : service.attributes.name;
    }
    //} else if (searchDisplayName === 'Search Results') {
    //   const service = Object.values(services).find(s => s.type === 'services');
    //   if (service) {
    //     searchDisplayName =
    //       service.attributes.name === 'Primary Care'
    //         ? t('LandingPage.findDoctor')
    //         : service.attributes.name;
  } else if (!isEmpty(latestSearchPage) && urlParams.serviceLine) {
    const service = Object.values(services).find(s => s.type === 'services');
    if (service) {
      searchDisplayName =
        service.attributes.name === 'Primary Care'
          ? t('LandingPage.findDoctor')
          : service.attributes.name;
    }
  }
  const pathFromMostRecentSearch = () => {
    const reverseIndex = findIndex(
      [...locationHistory].reverse(),
      latestSearchLocation(),
    );
    const currentPageIndex = findIndex(locationHistory, location);
    return uniq(
      locationHistory.slice(
        locationHistory.length - reverseIndex,
        currentPageIndex + 1,
      ),
    );
  };

  let onSearchPage =
    location.pathname === '/search' ||
    QueryString.parse(location.search).searchType === 'inventory' ||
    urlParams.serviceLine !== undefined;
  const hasBeenOnSearchPage =
    !isEmpty(latestSearchPage) && latestSearchPage !== undefined;
  let showSearch = searched || onSearchPage || hasBeenOnSearchPage;

  if (
    latestSearchLocation() !== undefined &&
    latestSearchLocation().pathname !== undefined &&
    latestSearchLocation()?.pathname.indexOf('/schedule') > -1 &&
    latestSearchLocation()?.pathname.indexOf('/providers') > -1
  ) {
    onSearchPage = true;
    showSearch = false;
  }

  if (
    location !== undefined &&
    location.pathname !== undefined &&
    (location.pathname.indexOf('/providers') > -1 ||
      location.pathname.indexOf('/schedule') > -1) &&
    healthSystem['ux-selection'] === 'Educational'
  ) {
    onSearchPage = true;
    showSearch = false;
  }

  function isSitemapPath(element, index, array) {
    if (element.pathname.includes('sitemap')) {
      return true;
    }
  }

  function findElementOfFirstSitemapLink(locationHistory) {
    let idx = 0;
    for (const element of locationHistory) {
      if (element.pathname === '/sitemap/') {
        return idx;
      } else if (
        element.pathname.includes('sitemap') &&
        locationHistory.length === 1
      ) {
        return idx;
      } else if (element.pathname.includes('sitemap')) {
        return idx;
      }
      idx++;
    }
  }

  function sanitizeForSitemapHistory(locationHistory) {
    let filteredHistory = [];
    if (locationHistory.some(isSitemapPath)) {
      let start = findElementOfFirstSitemapLink(locationHistory);
      let index = 0;
      for (const element of locationHistory) {
        if (start === 0) {
          return locationHistory;
        } else if (index >= start) {
          filteredHistory.push(element);
        }
        index++;
      }
      return filteredHistory;
    } else {
      return locationHistory;
    }
  }

  // eslint-disable-next-line no-nested-ternary
  const locations =
    !sitemap && showSearch && latestSearchPage !== undefined
      ? pathFromMostRecentSearch()
      : sanitizeForSitemapHistory(locationHistory);

  function buildSitemapLink(pathname) {
    if (pathname === '/sitemap/') {
      return 'Sitemap';
    }
    if (pathname === '/sitemap/providers') {
      return 'Providers';
    }
    if (pathname === '/sitemap/cities') {
      return 'Cities';
    }
    if (pathname === '/sitemap/services') {
      return 'Services';
    }
    if (pathname === '/sitemap/facilities') {
      return 'Facilities';
    }
  }

  function buildBreadCrumbTitle(location) {
    if (location.title !== undefined) {
      if (location.pathname.substring(0, 10) === '/facility/') {
        return 'Provider Services';
      }
      return location.title?.replace('Facility', 'Provider Services');
    } else {
      if (location.pathname !== '/') {
        if (location.pathname.includes('sitemap')) {
          return buildSitemapLink(location.pathname);
        } else if (QueryString.parse(location.search).serviceName) {
          return QueryString.parse(location.search).serviceName;
        } else {
          return 'Provider Services';
        }
      }
    }
  }

  return (
    <div elevation={0} className={`${classes.breadcrumbs}`}>
      <Breadcrumbs
        separator="›"
        aria-label="breadcrumb"
        aria-level="3"
        aria-selected="false"
        role="heading"
      >
        <Link className={classes.a} to="/" onClick={handleGotoHome}>
          {t('Breadcrumb.home')}
        </Link>

        {!onlyHome && showSearch && !onSearchPage && !sitemap && (
          <Link
            className={classes.a}
            to={{
              pathname: latestSearchLocation()?.pathname,
              search: latestSearchLocation()?.search,
              state: { initialSearch: true },
            }}
            key={latestSearchLocation().key}
          >
            {searchDisplayName}
          </Link>
        )}

        {!onlyHome && showSearch && onSearchPage && (
          <Typography color="textPrimary" key={location.key}>
            {searchDisplayName}
          </Typography>
        )}

        {!onlyHome &&
          locations.map((location, i) => {
            if (location.pathname !== '/') {
              if (i === locations.length - 1) {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Typography color="textPrimary" key={`${location.key}-${i}`}>
                    {buildBreadCrumbTitle(location)}
                  </Typography>
                );
              }
              return (
                <Link
                  to={`${location.pathname}${location.search}`}
                  key={location.title}
                >
                  {buildBreadCrumbTitle(location)}
                </Link>
              );
            }
          })}
      </Breadcrumbs>
    </div>
  );
};
const mapStateToProps = state => ({
  locationHistory: state.breadcrumb.history,
  searched: state.providers.searched,
  services: state.serviceLines.services,
  healthSystem: state.healthSystem,
});

const mapDispatchToProps = {
  clearSearch,
  loadActiveServices,
  loadFilteredActiveServices,
  fetchHealthSystem,
};

BreadCrumbs.defaultProps = {
  onlyHome: false,
};

BreadCrumbs.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  locationHistory: PropTypes.instanceOf(Array).isRequired,
  searched: PropTypes.bool.isRequired,
  onlyHome: PropTypes.bool,
  fetchHealthSystem: PropTypes.func.isRequired,
  healthSystem: PropTypes.instanceOf(Object).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(BreadCrumbs);
