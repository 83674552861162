import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DialogMessage from 'components/form/registrationForm/DialogMessage';
import './UnsupportedBrowser.scss';

const UnsupportedBrowser = () => {
  const [t] = useTranslation();
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <DialogMessage
        show={show}
        title={t('UnsupportedBrowser.modalTitle')}
        message={
          t('UnsupportedBrowser.modalBodyTop') +
          '\n\r• Chrome (latest stable version) \n\r • Firefox (latest stable version) \n\r• Edge (latest stable version) \n\r• Safari (latest stable version)\n\r' +
          t('UnsupportedBrowser.modalBodyBottom')
        }
        primaryBtnText={t('UnsupportedBrowser.modalClose')}
        primaryBtnClick={e => {
          handleClose();
        }}
        cancelBtnClick={e => {
          handleClose();
        }}
      />
    </>
  );
};

export default UnsupportedBrowser;
