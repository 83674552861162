/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  Form,
  Modal,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import 'components/form/registrationForm/StripeForm.scss';
import { FormHelperText } from '@material-ui/core';
import { BrandingContext } from 'components/contexts/BrandingContext';
import BrandingButton from 'components/common/BrandingButton';
import Loader from '../../../src/assets/images/ajaxloader.gif'
import { useTranslation } from 'react-i18next';
import { confirmTransaction } from "api/patientIntakeApi";

const IntakeStripeForm = props => {
  const {
    toggleModal,
    show = true,
    sessionKeys,
    handleClose,
    appointmentCurrency,
    appointmentCost,
    emailAddress,
    isRegForm
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [stripeErr, setStripeErr] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const { branding } = useContext(BrandingContext);
  const [t] = useTranslation();

  const makePayment = async () => {
    if (showError && isRegForm){
      await props.callVisit();
      return false;
    }
    if (!stripe || !elements) {
      return false;
    }
    const card = elements.getElement(CardNumberElement);
    const cardHolder = document.getElementById('cardHolderName');
    let cardHolderName = '';
    if (cardHolder) {
      cardHolderName = cardHolder.value;
    }
    if (cardHolderName) {
      setShowLoader(true);
      stripe
        .confirmCardPayment(sessionKeys.providerSessionSecret, {
          payment_method: {
            card,
            billing_details: {
              name: cardHolderName,
              email: emailAddress,
            },
          },
        })
        .then(async res => {
          if (res.error) {
            setShowLoader(false);
            setStripeErr([res.error]);
            if(res?.error?.code === "payment_intent_invalid_parameter") setShowError(true)
          }
          if (
            res.paymentIntent &&
            res.paymentIntent.status === 'requires_capture'
          ) {
            
            setShowError(false);
            const formData = {
              "last_4_card": "1235",
              "card_type": "visa"
            }

            const confirmData = await confirmTransaction(props?.secureId, props?.secureToken, formData);
            if(confirmData?.isSuccess){
              await props.callVisit();
            }else{
              setShowError(true)
            }
          }
        })
        .catch(err => {
          console.error('Payment Failed', err);
        }).finally(()=>setShowLoader(false))

    } else if (!stripeErr.find(err => err.code === 'cardName')) {
      setShowLoader(false);
      setStripeErr(
        stripeErr.concat({
          code: 'cardName',
          message: t('paymentModal.cardHolderNameRequired'),
        }),
      );
    }
  };

  return (
    <Modal
      show={show}
      onHide={ e=>{
        toggleModal();
        setShowError(false);
      }}
      onClose={handleClose}
      backdrop="static"
      keyboard={false}
      className="custom-modal"
    >
      {showLoader && (
        <div className="loader-container-box">
          <div className="loader-inner-box">
            <img src={Loader} alt="" className="loader-image" />
            <div className="loader-text">Processing Payment...</div>
          </div>
        </div>
      )}
      <ModalHeader closeButton>
        <ModalTitle>{t('paymentModal.Title')}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Form>
          <div className="logo-section">
            <img
              alt="Health System Logo"
              className="logo"
              src={branding.logoImage}
            />
          </div>
          <div className="form-control-inline">
            <label htmlFor="cardHolderName" className="label nopad">
              {t('paymentModal.labelAmount')}
            </label>
            <div className="input-element">
              {appointmentCurrency}
              {appointmentCost}
            </div>
          </div>
          <div className="form-control-inline">
            <label htmlFor="cardHolderName" className="label">
              {t('paymentModal.labelNameOnCard')}
            </label>
            <div className="input-element">
              <input
                placeholder={t('paymentModal.labelNameOnCard')}
                id="cardHolderName"
                className="stripeElement"
                style={{
                  fontSize: '16px',
                  width: '100%',
                  margin: '0',
                }}
              
              />
              <FormHelperText className="errors">
                {stripeErr.find(err => err.code === 'cardName') ? (
                  <>
                    {' '}
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="button-icon"
                    />{' '}
                    {stripeErr.find(err => err.code === 'cardName').message}
                  </>
                ) : (
                  ''
                )}
              </FormHelperText>
            </div>
          </div>
          <div className="form-control-inline">
            <label htmlFor="email" className="label">
              {t('paymentModal.labelEmailAddress')}
            </label>
            <div className="input-element">
              <input
                placeholder="Email"
                value={emailAddress}
                id="email"
                disabled
                className="stripeElement"
                style={{
                  fontSize: '16px',
                  width: '100%',
                  margin: '0',
                }}
              />
            </div>
          </div>
          <div className="form-control-vertical">
            <label htmlFor="email" className="label">
              {t('paymentModal.labelCardNumber')}
            </label>
            <div className="input-element">
              <div className="stripeElement">
                <CardNumberElement
                  placeholder={t('paymentModal.labelCardNumber')}
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                      },
                    },
                    showIcon: true,
                  }}
                  id="cardNumber"
                />
                <FormHelperText className="err errors">
                  {stripeErr.find(err => err.code.includes('number')) ? (
                    <>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="button-icon"
                      />
                      {t('paymentModal.cardNumberIsInvalid')}
                    </>
                  ) : (
                    ''
                  )}
                </FormHelperText>
              </div>
            </div>
          </div>

          <div className="contain-flex">
            <div className="inline">
              <div className="form-control-vertical">
                <label htmlFor="email" className="label">
                  {t('paymentModal.labelExpiration')}
                </label>
                <div className="input-element">
                  <div className="stripeElement">
                    <CardExpiryElement
                      placeholder="MM/YY"
                    />
                    <FormHelperText className="err errors">
                      {stripeErr.find(err => err.code.includes('expiry')) ? (
                        <>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="button-icon"
                          />
                          {t('CreditCard.ExpiryDate')}
                        </>
                      ) : (
                        ''
                      )}
                    </FormHelperText>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline">
              <div className="form-control-vertical">
                <label htmlFor="email" className="label">
                  {t('paymentModal.labelSecurityCode')}
                </label>
                <div className="input-element">
                  <div className="stripeElement cvv-image">
                    <CardCvcElement />
                    <FormHelperText className="err errors">
                      {stripeErr.find(err => err.code.includes('cvc')) ? (
                        <>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="button-icon"
                          />{' '}
                          {t('CreditCard.CVC')}
                        </>
                      ) : (
                        ''
                      )}
                    </FormHelperText>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </ModalBody>
      {showError && <p style={{padding:"10px"}} className="err errors">{isRegForm ? t('PatientIntake.CoPayError') : t('PatientIntake.PaymentStripeError')}</p>}
      <ModalFooter>
        <BrandingButton
          className="submit-btn"
          type="button"
          onClick={e => {
            e.preventDefault();
            makePayment();
          }}
        >
          {showError && isRegForm ?  t('PatientIntake.Close') :  t('paymentModal.btnPay')}
        </BrandingButton>
      </ModalFooter>
    </Modal>
  );
};
IntakeStripeForm.defaultProps = {
  emailAddress: '',
};

IntakeStripeForm.propTypes = {
  show: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  appointmentCurrency: PropTypes.string.isRequired,
  appointmentCost: PropTypes.string.isRequired,
  callVisit: PropTypes.func.isRequired,
  emailAddress: PropTypes.string, 
  secureId: PropTypes.string,
  secureToken: PropTypes.string,
  sessionKeys: PropTypes.object,
  isRegForm: PropTypes.bool.isRequired,
};
export default IntakeStripeForm;