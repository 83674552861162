/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect } from 'react';
import PropTypes, { element } from 'prop-types';
import { BrandingContext } from 'components/contexts/BrandingContext';
import { Container, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { getSubDomain } from 'api/apiUtils';

const AppointmentErrors = ({ errors }) => {
  const { branding } = useContext(BrandingContext);
  const [t] = useTranslation();
  const hsPermalink = getSubDomain();
  const translationKeys =
    errors[0]?.meta !== undefined ? errors[0]?.meta['translation-keys'] : [];

  if (branding.noAvailabilityCustomMessage) {
    // eslint-disable-next-line no-return-assign
    errors.map(e =>
      e.title === 'appointment-at' || e.title === 'is taken'
        ? (e.message =
          t(`CustomDefaultMessage:${hsPermalink}`) ||
          t('default:Branding.NoAvailabilityCustomMessage'))
        : e.message,
    );
  } else {
    // eslint-disable-next-line no-return-assign
    errors.map(e =>
      e.title === 'appointment-at'
        ? (e.message = t('default:Branding.NoAvailabilityCustomMessage'))
        : e.message,
    );
  }
  errors.map(e =>
    e.message ===
    'You have already registered to be seen at this facility today. Please check your email for a confirmation and further details.'
      ? (e.message = t('visit.allow_duplicate_visit_errormessage'))
      : e.message,
  );
  return (
    <div className="errors">
      <div className="errors-wrapper">
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <ul>
                {translationKeys.length > 0
                  ? translationKeys.map(element => (
                      <li key={element}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className="button-icon"
                        />
                        <div className="error-message">{t(element)}</div>
                      </li>
                    ))
                  : errors
                      .filter(e => {
                        if (e.title === 'payment-transaction-id-missing') {
                          return false;
                        }
                        if (e.message.includes('requires_payment_method')) {
                          return false;
                        }
                        return true;
                      })
                      .map((e, i) => (
                        <li key={i}>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="button-icon"
                          />
                          <div className="error-message">
                            {// eslint-disable-next-line no-nested-ternary
                            e.title === 'appointment-at - is taken'
                              ? e.message
                              : e.message.startsWith('translation_key') === true
                              ? t(
                                  e.message
                                    .substr(
                                      e.message.indexOf(':') + 1,
                                      e.message.length - 1,
                                    )
                                    .trim(),
                                )
                              : t(e.message.replace('birthdate', 'Birthdate'))}
                          </div>
                        </li>
                      ))}
              </ul>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};
AppointmentErrors.defaultProps = {
  errors: [],
};

AppointmentErrors.propTypes = {
  errors: PropTypes.instanceOf(Array),
};

export default AppointmentErrors;
