import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomTextField from '../CustomTextField';
import DateFields from '../DateFields';

const LastTestFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const [t] = useTranslation();

  return (
    <>
      {formContext.isFieldEnabled('last-test-date') && (
        <div className="form-section_row">
          <DateFields
            errorMessage={formContext.getError('last-test-date')}
            handleDateChange={e => {
              formContext.handleSetFormState({
                lastTestDate: {
                  value: `${e.day.value}/${e.month.value}/${e.year.value}`,
                },
              });
            }}
            hasError={formContext.hasError('last-test-date')}
            label={t('LastTestFields.lastTestDate')}
            required={formContext.isFieldRequired('last-test-date')}
          />
        </div>
      )}

      {formContext.isFieldEnabled('last-test-location') && (
        <div className="form-section_row">
          <Grid container>
            <Grid item sm={4} xs={12}>
              <CustomTextField
                fieldKey="lastTestLocation"
                fieldValue={formContext.form.lastTestLocation}
                validateFormHandler={formContext.handleSetFormState}
                label={t('LastTestFields.lastTestLocation')}
                shrink
                hasError={formContext.hasError('last-test-location')}
                errorMessage={formContext.getError('last-test-location')}
                required={formContext.isFieldRequired('last-test-location')}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default LastTestFields;
