import React, { useEffect, useState, useContext, useRef } from "react";
import { Grid, Button, LinearProgress } from "@mui/material";
import { Form } from 'react-formio';
import BrandingButton from "components/common/BrandingButton";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import { PatientIntakeContext } from "../contexts/PatientIntakeContext";
import IntakeHeader from "./IntakeHeader";
import AppointmentInfoModal from "./AppointmentInfoModal";
import { fetchForm, saveForm, savePDF } from 'api/patientIntakeApi';
import "./PatientIntakePage.scss";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Alert } from 'react-bootstrap';

const CustomTemplatePage  = ({name}) => {
    
    const [loading, setLoading] = useState(false);
    const [formDefinition, setFormDefinition] = useState(null);
    const [showApptInfoModal, setShowApptInfoModal] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [t] = useTranslation();
    const intake = useContext(PatientIntakeContext);
    const formRef = useRef(null);
    const [errorMessage, setErrorMessage] = useState("");

    const getFormJson = async () => {
        try {
            setLoading(true);
            const data =  await fetchForm(intake?.secureId, intake?.secureToken, name);
            if (data?.formDefinition && data?.formDefinition?.formioJson) {
                const json = JSON.parse(data?.formDefinition?.formioJson);
                setFormDefinition(json?.data);
                if(intake?.intakeInfo?.[name]) updateFormDefaults(json?.data, intake?.intakeInfo[name]); 
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const updateFormDefaults = (formDefinition, data) => {
        
        // Helper function to update defaultValue of a single component or its nested ones
        const updateComponentDefaults = (component, data) => {
            // Check if the component key exists in data
            if (component?.key && data?.hasOwnProperty(component?.key)) {
                // Update defaultValue based on component type
                if (component?.type === 'datagrid') {
                    component.defaultValue = data[component.key] || [];
                } else if (component?.key === 'html') {
                    component.content = data[component.key];
                } else {
                    component.defaultValue = data[component.key];
                }
            }

            // Handle nested components within a DataGrid
            if (component.type === 'datagrid' && Array.isArray(component.defaultValue)) {
                component.defaultValue.forEach((row, rowIndex) => {
                    if (component.components) {
                        component.components.forEach(subComponent => {
                            if (subComponent.key && row.hasOwnProperty(subComponent.key)) {
                                // Update the defaultValue for subcomponents inside each row
                                subComponent.defaultValue = row[subComponent.key];
                            }
                        });
                    }
                });
            }

            // Handle `columns` type components
            if (component.type === 'columns' && Array.isArray(component.columns)) {
                component.columns.forEach((column) => {
                    if (column.components) {
                        // Iterate through components inside each column
                        column.components.forEach(subComponent => updateComponentDefaults(subComponent, data));
                    }
                });
            }

            // Recursively handle components with nested components
            if (component.components) {
                component.components.forEach(subComponent => updateComponentDefaults(subComponent, data));
            }
        };

        // Iterate over all top-level components
        formDefinition.components.forEach(component => updateComponentDefaults(component, data));
    }

    useEffect(() => {
        getFormJson();
    }, []);

    const useStyles = makeStyles((theme) => ({
       
        arrowBtn: {
            paddingLeft: "5px"
        },
        grid: {
            display: "flex"
        },
        nextButton: {
            paddingInline: "18px",
            borderRadius: "8px",
            textTransform: "none !important",
            opacity: !formValid ? 0.6 : 1,
            pointerEvents: !formValid ? "none" : ""
        },
        backButton: {
            paddingInline: "18px",
            borderRadius: "8px",
            textTransform: "none !important"
        },
        backArrowBtn: {
            paddingRight: "5px"
        },
    }));
    const classes = useStyles();

    const handleClickBack = () => {
        setErrorMessage(""); 
        intake.prevPage();
    }

    const removeTdElementsWithButton = (element) => {
        const removeButtons = element?.querySelectorAll("button.formio-button-remove-row");
        removeButtons?.length > 0 && removeButtons.forEach((button) => {
          const td = button.closest("td");
          if (td) {
            td.remove(); // Remove the entire <td> that contains the button
          }
        });
    };
    
    const removeLastTfoot = (element) => {
        const footers = element?.querySelectorAll("tfoot");
        if (footers?.length > 0) {
          footers[footers.length - 1].remove();
        }
    };
    
    const removeDataGridButtonsForPdf = (element) => {
        removeTdElementsWithButton(element);
        removeLastTfoot(element);
    };

    const generateCanvasFromElement = async (element) => {
        return html2canvas(element, {
          scale: 1.2 // Adjust scale for higher quality
        });
    };


    const handleClickNext = async () => {
    
        const element = formRef?.current?.formio?.element;

        // Step 1: Remove unwanted elements
        removeDataGridButtonsForPdf(element);

        // Step 2: Capture the HTML content as a canvas
        const canvas = await generateCanvasFromElement(element);

        // Step 3: Add content to the PDF
        const imgData = canvas?.toDataURL("image/png");

        intake.updateInsuranceInfo(formRef?.current?.formio?._data, name); 

        if(name !== "consent"){

            const formData = {   
                "form_data":JSON.stringify(formRef?.current?.formio?._data)
            }

            const pdfData = {   
                "pdf_file": imgData,
            }
            setLoading(true);
            const result = await saveForm(intake?.secureId, intake?.secureToken, name, formData);
            if(result?.success){
                setErrorMessage("");
                intake.markStepComplete(name);
            }else{
                setErrorMessage(result?.message);
            }
            await savePDF(intake?.secureId, intake?.secureToken, name, pdfData);
        }else{
            intake.markStepComplete(name);
        }
        setLoading(false);
    }

    if (loading) return <LinearProgress />;

    return (
            <Grid container spacing={1} justifyContent={"center"} className="templatesPage">
                <Grid item xs={12} marginY={1}>
                    <IntakeHeader pageName={intake?.requiredSteps?.find(item => item?.documentTypeCode === name )?.documentTypeName} setShowApptInfoModal={setShowApptInfoModal} />
                </Grid>
                <Grid item xs={11} marginY={1}>
                    {errorMessage && (
                        <Alert severity="error">
                            <b>{t(errorMessage)}</b>
                        </Alert>
                    )}
                    {formDefinition && (
                        <Form
                            id="formio"
                            form={formDefinition}
                            ref={formRef}
                            onChange={(e)=> setFormValid(e?.isValid)}
                        />
                    )}
                    <Grid item xs={12} marginY={2} justifyContent={"space-between"} className={classes.grid}>
                        {intake?.page !== 1 ? 
                        <Button
                            type="button"
                            className={classes.backButton}
                            variant="outlined"
                            onClick={handleClickBack}
                        >
                            <ArrowBack fontSize="small" className={classes.backArrowBtn} /> {t('PatientIntake.Back')}
                        </Button> : <div /> }
                        <BrandingButton
                            id="submitBtn"
                            type="button"
                            className={classes.nextButton}
                            onClick={handleClickNext}
                        >
                            {t('PatientIntake.Next')}<ArrowForward fontSize="small" className={classes.arrowBtn} />
                        </BrandingButton>
                    </Grid>
                </Grid>
                <AppointmentInfoModal
                    show={showApptInfoModal}
                    onClose={() => setShowApptInfoModal(false)}
                />
            </Grid>
    )
}

export default CustomTemplatePage;