import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useHistory } from 'react-router-dom';
import QueryString from 'query-string';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { chunk, isEmpty, intersection } from 'lodash';
import moment from 'moment-timezone';
import Sticky from 'react-sticky-el';
import { connect } from 'react-redux';
import { loadVenuesOfCare } from 'redux/actions/venuesOfCareActions';
import {
  applySearchFilters,
  resetSearchFilters,
  loadScheduleTimes,
} from 'redux/actions/searchActions';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { isMobile } from 'react-device-detect';
import Paginator from 'components/common/Paginator';
import { getActiveVenueTab } from 'utils/getActiveVenueTab';
import InventoryFilter from 'components/inventory/InventoryFilter';
import MapView from 'components/inventory/MapView';
import 'components/inventory/InventoryPage.scss';
import InventoryTabs from 'components/inventory/InventoryTabs';
import InventoryControls from 'components/inventory/InventoryControls';
import InventoryControlsMobile from 'components/inventory/InventoryControlsMobile';
import ScheduleResultList from 'components/result/ScheduleResultList';
import { BrandingContext } from 'components/contexts/BrandingContext';
import {
  getEndpoint,
  getEndpointFromURLSearchParams,
  getHeaderInfo,
} from 'api/apiUtils';
import { groupedDays } from 'utils/dateTimeUtils';
import { fetchHealthSystem } from 'redux/actions/healthSystemActions';
import DateCarousel from 'components/appointmentPicker/DateCarousel';
import ServiceBanner from 'components/provider/ServiceBanner';
import { useQueryString } from 'hooks/useQueryString';
import useSettings from 'hooks/useSettings';

const providerData = (page, providers, pageSize) => {
  return chunk(providers, pageSize)[page - 1];
};

export function EducationalInventory({
  loading,
  pageSize,
  providers,
  searched,
  searchFilters,
  selectedParams,
  applySearchFilters,
  resetSearchFilters,
  loadScheduleTimes,
  loadVenuesOfCare,
  venuesOfCare,
  fetchHealthSystem,
  healthSystem,
}) {
  const history = useHistory();
  const { pathname } = useLocation();
  const { params, isDischargePage } = useQueryString();

  const [title] = useState(document.title);
  const [countPS, setCountPS] = useState(0);
  const [countUC, setCountUC] = useState(0);
  const [countER, setCountER] = useState(0);
  const [sortBy, setSortBy] = useState(params.sort);
  const [showMapView, setShowMapView] = useState(params.viewName === 'map');
  const [activeTab, setActiveTab] = useState(params.tab || '');
  const [activeFilters, setActiveFilters] = useState({});
  const [filterStack, setFilterStack] = useState([]);
  const [providersLoaded, setProvidersLoaded] = useState(false);
  const [reset, setReset] = useState(false);
  const [pageER, setPageER] = useState(1);
  const [pageUC, setPageUC] = useState(1);
  const [pagePS, setPagePS] = useState(1);
  const [resultsER, setResultsER] = useState([]);
  const [resultsUC, setResultsUC] = useState([]);
  const [resultsPS, setResultsPS] = useState([]);
  const [listLoadingER, setListLoadingER] = useState(true);
  const [listLoadingPS, setListLoadingPS] = useState(true);
  const [listLoadingUC, setListLoadingUC] = useState(true);
  const [hideFilter, setHideFilter] = useState(false);
  const [date, setDate] = useState();
  const [dates, setDates] = useState([]);
  const [datesLoading, setLoading] = useState(true);
  const [numberOfDays, setNumberOfDays] = useState(1);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.only('md'));
  const isLgDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [venue, setVenue] = useState();
  const [whoIsScrolling, setWhoIsScrolling] = useState();
  const [scrollLeft, setScrollLeft] = useState({
    left: '0',
    fromComponent: 'first',
  });
  const availableScheduleDays = useSettings({
    healthSystemId: healthSystem.id,
  });

  useEffect(() => {
    setSortBy(params.sort);
  }, [params.sort]);

  const { setQueryParamsFromObject } = useQueryString();

  const onScroll = (dateSliderRef, fromComponent) => {
    if (dateSliderRef.current.id === whoIsScrolling) {
      setScrollLeft({
        left: dateSliderRef.current.scrollLeft,
        fromComponent: whoIsScrolling,
      });
    }
  };

  const loadPS = () => {
    setListLoadingPS(true);
    if (!isEmpty(venuesOfCare)) {
      const venue = Object.values(venuesOfCare).find(
        v => v.attributes.venueType === 'physician_services',
      );
      if (venue) {
        (async () => {
          const searchOptions = {
            'filter[context]': 'patient_now',
            'filter[radius]': venue.attributes.searchDistanceRadius,
            'filter[venue_of_care]': venue.id,
            include: 'location,provider,service,insurance-plans',
          };

          if (selectedParams.appointmentTypeId) {
            searchOptions['filter[appointment_type]'] =
              selectedParams.appointmentTypeId;
          }

          if (selectedParams.longitude && selectedParams.latitude)
            searchOptions[
              'filter[near]'
            ] = `${selectedParams.latitude},${selectedParams.longitude}`;
          if (selectedParams.insurancePlan)
            searchOptions['filter[insurance]'] = selectedParams.insurancePlan;

          // set default sortBy option for initial loading
          if (!sortBy) {
            if (
              venue.attributes.educationalNowDisplayOrder === 'next_available'
            ) {
              setSortBy('soonest');
              searchOptions.sort = 'soonest';
              setQueryParamsFromObject({ sort: 'soonest' });
            } else {
              setSortBy('nearest');
              searchOptions.sort = 'nearest';
              setQueryParamsFromObject({ sort: 'nearest' });
            }
          }

          if (sortBy) {
            searchOptions.sort = sortBy;
          }

          // If we don't manually set these here, they won't get included
          // during the transition from 'now' to 'later', resulting in
          // unexpected result. This at least maintains consistency with
          // the 'manual' user-initiated search.
          searchOptions.from = selectedParams.date
            ? selectedParams.date.start
            : moment()
              .tz(searchFilters.timeZone)
              .format();
          searchOptions.check_availability = sortBy === 'soonest';
          searchOptions.to = selectedParams.date
            ? selectedParams.date.end
            : moment()
              .tz(searchFilters.timeZone)
              .add(availableScheduleDays, 'days')
              .format();

          let searchParams = null;
          if (reset) {
            searchParams = new URLSearchParams(searchOptions);
            setReset(false);
          } else {
            const {
              acceptNewPatient,
              languages,
              genders,
              hospitalAffiliationFilters,
            } = activeFilters;

            if (acceptNewPatient)
              searchOptions['filter[accepting_new_patients]'] = 'true';

            // When both genders are selected, ignore filter
            if (genders && genders.length === 1) {
              const [g] = genders;
              const gender = g === 'f' ? 'female' : 'male';
              searchOptions['filter[gender]'] = gender;
            }

            if (sortBy === 'soonest') {
              searchOptions.page_number = pagePS;
              searchOptions.page_size = pageSize;
              searchOptions.check_availability = true;
            } else {
              if (pagePS) {
                searchOptions['page[number]'] = pagePS;
                searchOptions['page[size]'] = pageSize;
              }

              if (showMapView) {
                searchOptions['page[number]'] = 1;
                searchOptions['page[size]'] = 1999;
              }
            }
            // For Array type of params
            searchParams = new URLSearchParams(searchOptions);

            if (
              hospitalAffiliationFilters &&
              hospitalAffiliationFilters.length > 0
            ) {
              hospitalAffiliationFilters.forEach(ha => {
                searchParams.append('filter[hospital_affiliation][]', ha);
              });
            }

            // If all languages are selected, ignore
            if (
              languages &&
              languages.length > 0 &&
              languages.length < searchFilters.languages.length
            ) {
              languages.forEach(language => {
                searchParams.append('filter[language][]', language);
              });
            }
          }

          if (selectedParams.appointmentTypeId) {
            const response = await fetch(
              getEndpointFromURLSearchParams(`schedules`, searchParams),
              getHeaderInfo(),
            );
            const results = await response.json();
            if (!isDischargePage) {
              const actualLength = results.data.length;
              const filteredData = results?.data.filter(
                d => d.attributes?.hidden !== true,
              );
              results.data = filteredData;
              const RECORD_COUNT = 'record-count';
              results.meta[RECORD_COUNT] =
                results.meta['record-count'] -
                (actualLength - filteredData.length);
            }
            if (!results?.errors) {
              setResultsPS(results);
              setCountPS(results?.meta['record-count']);
            }
          } else {
            setResultsPS([]);
            setCountPS(0);
          }

          setListLoadingPS(false);
        })();
      }
    }
  };

  const loadUC = () => {
    setListLoadingUC(true);
    if (!isEmpty(venuesOfCare)) {
      const venue = Object.values(venuesOfCare).find(
        v => v.attributes.venueType === 'urgent_care',
      );
      if (venue) {
        (async () => {
          const searchOptions = {
            // No appointment types for Urgent Care!
            // 'filter[appointment_type]': selectedParams.appointmentTypeId,
            'filter[context]': 'patient_now',
            'filter[radius]': venue.attributes.searchDistanceRadius,
            'filter[venue_of_care]': venue.id,
            include: 'location,provider,service,insurance-plans',
          };

          if (selectedParams.longitude && selectedParams.latitude)
            searchOptions[
              'filter[near]'
            ] = `${selectedParams.latitude},${selectedParams.longitude}`;
          if (venue.attributes.educationalNowDisplayOrder === 'next_available')
            searchOptions.sort = 'soonest';

          if (sortBy === 'soonest') {
            searchOptions.page_number = pageUC;
            searchOptions.page_size = pageSize;
            searchOptions.check_availability = true;
            searchOptions.sort = 'soonest';
          } else {
            if (pageUC) {
              searchOptions['page[number]'] = pageUC;
              searchOptions['page[size]'] = pageSize;
            }

            if (showMapView) {
              searchOptions['page[number]'] = 1;
              searchOptions['page[size]'] = 1999;
            }
          }

          // If we don't manually set these here, they won't get included
          // during the transition from 'now' to 'later', resulting in
          // unexpected result. This at least maintains consistency with
          // the 'manual' user-initiated search.
          searchOptions.from = selectedParams.date
            ? selectedParams.date.start
            : moment()
              .tz(searchFilters.timeZone)
              .format();
          searchOptions.check_availability = sortBy === 'soonest';
          searchOptions.to = selectedParams.date
            ? selectedParams.date.end
            : moment()
              .tz(searchFilters.timeZone)
              .add(availableScheduleDays, 'days')
              .format();

          const response = await fetch(
            getEndpoint(`schedules`, searchOptions),
            getHeaderInfo(),
          );
          const results = await response.json();
          if (!isDischargePage) {
            const actualLength = results.data.length;
            const filteredData = results?.data.filter(
              d => d.attributes?.hidden !== true,
            );
            results.data = filteredData;
            const RECORD_COUNT = 'record-count';
            results.meta[RECORD_COUNT] =
              results.meta['record-count'] -
              (actualLength - filteredData.length);
          }
          setResultsUC(results);
          setCountUC(results.meta['record-count']);
          setListLoadingUC(false);
        })();
      }
    }
  };

  const loadER = () => {
    setListLoadingER(true);
    if (!isEmpty(venuesOfCare)) {
      const venue = Object.values(venuesOfCare).find(
        v => v.attributes.venueType === 'emergency_room',
      );
      if (venue) {
        (async () => {
          const searchOptions = {
            // No appointment types for ERs!
            // 'filter[appointment_type]': selectedParams.appointmentTypeId,
            'filter[context]': 'patient_now',
            'filter[radius]': venue.attributes.searchDistanceRadius,
            'filter[venue_of_care]': venue.id,
            include: 'location,provider,service,insurance-plans',
          };

          if (selectedParams.longitude && selectedParams.latitude)
            searchOptions[
              'filter[near]'
            ] = `${selectedParams.latitude},${selectedParams.longitude}`;
          if (venue.attributes.educationalNowDisplayOrder === 'next_available')
            searchOptions.sort = 'soonest';

          if (sortBy === 'soonest') {
            searchOptions.page_number = pageER;
            searchOptions.page_size = pageSize;
            searchOptions.check_availability = true;
            searchOptions.sort = 'soonest';
          } else {
            if (pageER) {
              searchOptions['page[number]'] = pageER;
              searchOptions['page[size]'] = pageSize;
            }

            if (showMapView) {
              searchOptions['page[number]'] = 1;
              searchOptions['page[size]'] = 1999;
            }
          }

          // If we don't manually set these here, they won't get included
          // during the transition from 'now' to 'later', resulting in
          // unexpected result. This at least maintains consistency with
          // the 'manual' user-initiated search.
          searchOptions.from = selectedParams.date
            ? selectedParams.date.start
            : moment()
              .tz(searchFilters.timeZone)
              .format();
          searchOptions.check_availability = sortBy === 'soonest';
          searchOptions.to = selectedParams.date
            ? selectedParams.date.end
            : moment()
              .tz(searchFilters.timeZone)
              .add(availableScheduleDays, 'days')
              .format();

          const response = await fetch(
            getEndpoint(`schedules`, searchOptions),
            getHeaderInfo(),
          );
          const results = await response.json();
          if (!isDischargePage) {
            const actualLength = results.data.length;
            const filteredData = results?.data.filter(
              d => d.attributes?.hidden !== true,
            );
            results.data = filteredData;
            const RECORD_COUNT = 'record-count';
            results.meta[RECORD_COUNT] =
              results.meta['record-count'] -
              (actualLength - filteredData.length);
          }
          setResultsER(results);
          setCountER(results.meta['record-count']);
          setListLoadingER(false);
        })();
      }
    }
  };

  useEffect(() => {
    if (!Object.keys(healthSystem).length > 0) {
      fetchHealthSystem();
    }
    setDate(
      moment()
        .tz(healthSystem['time-zone'])
        .format(),
    );
  }, []);

  useEffect(() => {
    // setShowFacilityDateCarousel(false);
    if (isTablet) {
      setNumberOfDays(5);
    }
    if (isDesktop) {
      setNumberOfDays(3);
    }
    if (isLgDesktop) {
      setNumberOfDays(5);
    }
    if (isMobile) {
      setNumberOfDays(1);
      setDates(groupedDays(date, 1, healthSystem['time-zone']));
    }
  }, [isTablet, isDesktop, isLgDesktop, isMobile]);

  useEffect(() => {
    setDates(
      groupedDays(
        moment(date).tz(searchFilters.timeZone),
        numberOfDays,
        searchFilters.timeZone,
      ),
    );
  }, [numberOfDays]);

  useEffect(() => {
    loadPS();
  }, [
    reset,
    searched,
    sortBy,
    activeFilters,
    selectedParams,
    pagePS,
    showMapView,
  ]);

  useEffect(() => {
    loadUC();
  }, [pageUC, showMapView, venuesOfCare, sortBy]);

  useEffect(() => {
    loadER();
  }, [pageER, showMapView, venuesOfCare, sortBy]);

  useEffect(() => {
    const viewName = showMapView ? 'map' : 'list';
    const search = QueryString.stringify({ ...params, viewName });
    history.replace({
      pathname,
      search,
    });
  }, [showMapView]);

  useEffect(() => {
    if (providers.length && !providersLoaded) {
      applySearchFilters(activeFilters);
      setProvidersLoaded(true);
    }
  }, [
    providers,
    providersLoaded,
    applySearchFilters,
    setProvidersLoaded,
    activeFilters,
  ]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    const providersForPage = providerData(pagePS, providers, pageSize);
    if (providersForPage && providersForPage.length > 0) {
      const schedules = providersForPage
        .map(p => p.relationships.schedules.data)
        .flat();
      const scheduleIds = schedules.map(s => s.id);
      loadScheduleTimes(scheduleIds, moment());
    }
  }, [providers, pagePS, pageSize, loadScheduleTimes]);

  useEffect(() => {
    if (isEmpty(venuesOfCare)) {
      loadVenuesOfCare();
    }
  }, [venuesOfCare, loadVenuesOfCare]);

  const getVenueForTab = activeTab => {
    if (!venuesOfCare) return null;
    return Object.values(venuesOfCare).find(
      voc => voc.attributes.venueType === activeTab,
    );
  };

  useEffect(() => {
    setActiveTab(getActiveVenueTab(params, selectedParams, venuesOfCare));
    setVenue(getVenueForTab(activeTab));
  }, [setActiveTab, params, selectedParams, venuesOfCare]);

  useEffect(() => {
    setVenue(getVenueForTab(activeTab));
  }, [activeTab]);

  const { branding } = useContext(BrandingContext);
  const providersForVenueType = venueType => {
    const venues = Object.values(venuesOfCare).filter(
      voc => voc.attributes.venueType === venueType,
    );
    const venueTypeScheduleIds = venues
      .map(v => v.relationships.schedules.data.map(s => s.id))
      .flat();

    const matchingProviders = providers.filter(p => {
      const providerScheduleIds = p.relationships.schedules.data.map(s => s.id);
      return intersection(providerScheduleIds, venueTypeScheduleIds).length > 0;
    });

    return matchingProviders;
  };

  const displayedSchedules = activeTab => {
    switch (activeTab) {
      case 'emergency_room':
        return resultsER.data ? resultsER.data : [];
      case 'urgent_care':
        return resultsUC.data ? resultsUC.data : [];
      case 'physician_services':
        return resultsPS.data ? resultsPS.data : [];
      default:
        return [];
    }
  };

  const countForActiveTab = () => {
    switch (activeTab) {
      case 'physician_services':
        return countPS;
      case 'urgent_care':
        return countUC;
      case 'emergency_room':
        return countER;
      default:
        return countPS;
    }
  };

  const pageForActiveTab = () => {
    switch (activeTab) {
      case 'physician_services':
        return pagePS;
      case 'urgent_care':
        return pageUC;
      case 'emergency_room':
        return pageER;
      default:
        return pagePS;
    }
  };

  const setPageForActiveTab = value => {
    switch (activeTab) {
      case 'physician_services':
        return setPagePS(value);
      case 'urgent_care':
        return setPageUC(value);
      case 'emergency_room':
        return setPageER(value);
      default:
        return setPagePS(value);
    }
  };

  const handlePageChange = value => {
    if (showMapView) {
      setPagePS(value);
      setPageUC(value);
      setPageER(value);
    } else {
      setPageForActiveTab(value);
    }
  };

  const handleSortBy = event => {
    setSortBy(event.target.value);
    setQueryParamsFromObject({ sort: event.target.value });
  };

  const handleFilterChange = filters => {
    if (filters.sort) {
      setSortBy(filters.sort);
    }

    setActiveFilters(filters);
    applySearchFilters(filters);
    setPageForActiveTab(1);
  };

  const handleFilterReset = () => {
    setReset(true);
    setPageForActiveTab(1);
    setActiveFilters({});
    resetSearchFilters();
  };

  const totalPagesForActiveTab = () => {
    const pageCount = countForActiveTab();
    let totalPages = 1;
    if (pageCount > pageSize) {
      totalPages = Math.floor(pageCount / pageSize);
      if (pageCount % pageSize !== 0) {
        totalPages += 1;
      }
    }
    return totalPages;
  };

  const handleSetDates = date => {
    if (isMobile) {
      setDates(groupedDays(date, 1, healthSystem['time-zone']));
    }
  };

  const showPaginator =
    !showMapView && !loading && activeTab && totalPagesForActiveTab() > 1;

  const renderSearchedResult = () => {
    const handleDateChange = date => {
      setLoading(true);
      setDate(date);
      setDates(
        groupedDays(
          date,
          numberOfDays,
          searchFilters.timeZone || healthSystem['time-zone'],
        ),
      );
    };

    return (
      <Row>
        <Col
          lg={3}
          className="inventory-sidebar"
          style={{ display: hideFilter ? 'none' : 'block' }}
        >
          <div
            className="inventory-sidebar-wrapper"
            style={{
              backgroundColor: `${branding.secondaryColor}`,
              height: '100%',
            }}
          >
            <InventoryFilter
              results={displayedSchedules(activeTab)}
              totalCount={countForActiveTab()}
              filters={searchFilters}
              loading={loading}
              handleFilterChange={handleFilterChange}
              handleFilterReset={handleFilterReset}
              handleHideFilter={e => setHideFilter(e)}
              sortProp={sortBy}
              setFilterStack={setFilterStack}
              uxMode="educational"
              activeTab={activeTab}
            />
          </div>
        </Col>
        <Col
          lg={hideFilter ? 12 : 9}
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          {!loading && isMobile && (
            <Sticky className="sticky-wrapper">
              <InventoryControlsMobile
                showDateCarousel
                showMapView={showMapView}
                setShowMapView={setShowMapView}
                timezoneName={healthSystem['time-zone']}
                isFacility
                dates={dates}
                onDateChange={handleDateChange}
                handleSetDates={handleSetDates}
              />
            </Sticky>
          )}
          {sortBy && (
            <InventoryControls
              showMapView={showMapView}
              setShowMapView={setShowMapView}
              sortBy={sortBy}
              handleChange={handleSortBy}
            />
          )}

          {!showMapView && !isEmpty(venuesOfCare) && (
            <InventoryTabs
              countPS={countPS}
              countUC={countUC}
              countER={countER}
              activeTab={activeTab}
              getVenueForTab={getVenueForTab}
              setActiveTab={setActiveTab}
              providersForVenueType={providersForVenueType}
              displayedSchedules={displayedSchedules}
            />
          )}
          {venue && Object.entries(venue).length > 0 && (
            <ServiceBanner
              venueType={venue.attributes.venueType}
              services={venue.attributes.educationalMessage.split(',')}
              title={venue.attributes.displayName}
            />
          )}

          {!showMapView && !isEmpty(venuesOfCare) && !isMobile && (
            <div className="InventoryDateCarousel SearchPage educational">
              <Grid container xs={12} sm={12} className="date_carousel">
                <Grid item xs={5} sm={5}>
                  {' '}
                </Grid>
                <Grid item xs={7} sm={7}>
                  {activeTab === 'physician_services' && (
                    <Sticky>
                      <DateCarousel
                        dates={dates}
                        onDateChange={handleDateChange}
                        timezoneName={searchFilters.timeZone}
                        dateHeadingLayout="horizontal"
                        onScroll={onScroll}
                        scrollLeft={scrollLeft}
                        whoIsScrolling={whoIsScrolling}
                        setWhoIsScrolling={setWhoIsScrolling}
                      />
                    </Sticky>
                  )}
                </Grid>
              </Grid>
            </div>
          )}
          <div>
            {showMapView && activeTab === 'physician_services' && (
              <MapView
                schedules={resultsPS.data}
                locations={
                  resultsPS.data && resultsPS.data.length
                    ? resultsPS.included.filter(
                      result => result.type === 'locations',
                    )
                    : []
                }
                providers={
                  resultsPS.data && resultsPS.data.length
                    ? resultsPS.included.filter(
                      result => result.type === 'providers',
                    )
                    : []
                }
              />
            )}
            {showMapView && activeTab === 'urgent_care' && (
              <MapView
                schedules={resultsUC.data}
                locations={
                  resultsUC.data && resultsUC.data.length
                    ? resultsUC.included.filter(
                      result => result.type === 'locations',
                    )
                    : []
                }
                providers={
                  resultsUC.data && resultsUC.data.length
                    ? resultsUC.included.filter(
                      result => result.type === 'providers',
                    )
                    : []
                }
              />
            )}
            {showMapView && activeTab === 'emergency_room' && (
              <MapView
                schedules={resultsER.data}
                locations={
                  resultsER.data && resultsER.data.length
                    ? resultsER.included.filter(
                      result => result.type === 'locations',
                    )
                    : []
                }
                providers={
                  resultsER.data && resultsER.data.length
                    ? resultsER.included.filter(
                      result => result.type === 'providers',
                    )
                    : []
                }
              />
            )}
          </div>
          {/* @Todo ask about the date between the results and the filter */}
          {/* Not all providers are available the same day */}
          {!showMapView && activeTab === 'physician_services' && (
            <div>
              <ScheduleResultList
                patientNowTimes
                venue={getVenueForTab(activeTab)}
                results={resultsPS.data}
                locations={
                  resultsPS.data && resultsPS.data.length
                    ? resultsPS.included.filter(
                      result => result.type === 'locations',
                    )
                    : []
                }
                providers={
                  resultsPS.data && resultsPS.data.length
                    ? resultsPS.included.filter(
                      result => result.type === 'providers',
                    )
                    : []
                }
                selectedParams={selectedParams}
                loading={loading}
                uxMode="educational"
                dates={dates}
                datesLoading={datesLoading}
                dateCurrent={date}
                noOfDays={numberOfDays}
                timezoneName={searchFilters.timeZone}
                handleDateChange={handleDateChange}
                onScroll={onScroll}
                scrollLeft={scrollLeft}
                whoIsScrolling={whoIsScrolling}
                setWhoIsScrolling={setWhoIsScrolling}
              />
            </div>
          )}
          {!showMapView && activeTab === 'urgent_care' && !listLoadingUC && (
            <div>
              <ScheduleResultList
                patientNowTimes
                venue={getVenueForTab(activeTab)}
                results={resultsUC.data}
                locations={
                  resultsUC.data && resultsUC.data.length
                    ? resultsUC.included.filter(
                      result => result.type === 'locations',
                    )
                    : []
                }
                providers={
                  resultsUC.data && resultsUC.data.length
                    ? resultsUC.included.filter(
                      result => result.type === 'providers',
                    )
                    : []
                }
                selectedParams={selectedParams}
                loading={loading}
                uxMode="educational"
                dates={dates}
                datesLoading={datesLoading}
                dateCurrent={date}
                noOfDays={numberOfDays}
              />
            </div>
          )}
          {!showMapView && activeTab === 'emergency_room' && !listLoadingER && (
            <div>
              <ScheduleResultList
                patientNowTimes
                venue={getVenueForTab(activeTab)}
                results={resultsER.data}
                locations={
                  resultsER.data && resultsER.data.length
                    ? resultsER.included.filter(
                      result => result.type === 'locations',
                    )
                    : []
                }
                selectedParams={selectedParams}
                loading={loading}
                uxMode="educational"
              />
            </div>
          )}

          {showPaginator && (
            <>
              <Paginator
                handleChange={handlePageChange}
                activePage={pageForActiveTab()}
                totalPages={totalPagesForActiveTab()}
              />
            </>
          )}
        </Col>
      </Row>
    );
  };

  const displayContent = () => {
    if (searched) {
      return renderSearchedResult();
    }

    return <></>;
  };

  return <>{displayContent()}</>;
}

EducationalInventory.defaultProps = {
  loading: true,
  pageSize: 10,
  searched: false,
  selectedParams: {},
  venuesOfCare: {},
};

EducationalInventory.propTypes = {
  searched: PropTypes.bool,
  providers: PropTypes.instanceOf(Object).isRequired,
  searchFilters: PropTypes.instanceOf(Object).isRequired,
  selectedParams: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  applySearchFilters: PropTypes.func.isRequired,
  resetSearchFilters: PropTypes.func.isRequired,
  loadScheduleTimes: PropTypes.func.isRequired,
  loadVenuesOfCare: PropTypes.func.isRequired,
  venuesOfCare: PropTypes.instanceOf(Object),
  healthSystem: PropTypes.instanceOf(Object).isRequired,
  fetchHealthSystem: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    searched: state.providers.searched,
    searchFilters: state.searchFilters,
    selectedParams: state.searchParams.selectedParams,
    providers: state.searchResult.providers,
    availableTimes: state.searchResult.availableTimes,
    venuesOfCare: state.venuesOfCare.data,
    loading: state.venuesOfCare.meta.loading,
    healthSystem: state.healthSystem,
  };
}

const mapDispatchToProps = {
  loadVenuesOfCare,
  applySearchFilters,
  resetSearchFilters,
  loadScheduleTimes,
  fetchHealthSystem,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EducationalInventory);
