import {
  handleResponse,
  handleError,
  getHeaderInfo,
  getEndpoint,
} from './apiUtils';

export async function getLocation(locationId) {
  return fetch(getEndpoint(`locations/${locationId}`), getHeaderInfo())
    .then(handleResponse)
    .catch(handleError);
}
export async function getLocationByPermalink(locationPermalink) {
  const params = {
    'filter[permalink]': locationPermalink,
    include: 'services.service-line',
  };
  const apiUrl = getEndpoint('locations', params);
  return fetch(apiUrl, getHeaderInfo())
    .then(handleResponse)
    .catch(handleError);
}

export async function getAllLocations() {
  const paginateParameter = 'page[size]=999';
  return fetch(getEndpoint(`locations/`, paginateParameter), getHeaderInfo())
    .then(handleResponse)
    .catch(handleError);
}
