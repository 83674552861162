import {
  handleResponse,
  handleError,
  getHeaderInfo,
  getEndpoint,
  getBaseUrl,
} from './apiUtils';

const providerIndexUrl = (page = 1, pageSize = 10) => {
  return getEndpoint('providers', {
    'page[number]': page,
    'page[size]': pageSize,
    include: 'locations,schedules,schedules.location',
  });
};

export const providerShowUrl = (id, context = 'patient') => {
  return getEndpoint(`providers/${id}`, {
    include: 'locations,schedules,schedules.location,schedules.facility',
    'filter[ignore_scheduling_restrictions]': true,
    'filter[context]': context,
  });
};

export function getProvider(id, context = 'patient') {
  return fetch(providerShowUrl(id, context), getHeaderInfo())
    .then(handleResponse)
    .catch(handleError);
}

export function getProviders(page = 1, pageSize = 10) {
  return fetch(providerIndexUrl(page, pageSize), getHeaderInfo())
    .then(handleResponse)
    .catch(handleError);
}

export function getProviderSpecialityNames(latitude, longitude, venueId) {
  const params = {
    'filter[context]': 'patient',
    'filter[near]': `${latitude},${longitude}`,
    include: 'schedules,schedules.location',
    'fields[providers]':
      'name,profile-image,ui-profile-image,subspecialties,appointment-types',
    'fields[schedules]': 'service',
  };
  if (venueId) params['filter[venue_of_care]'] = venueId;
  const apiUrl = getEndpoint('providers', params);
  return fetch(apiUrl, getHeaderInfo())
    .then(handleResponse)
    .catch(handleError);
}

export function getProviderSpecialityNamesWithServiceIds(
  latitude,
  longitude,
  serviceIds,
  pageSize,
  pageNumber,
) {
  const urlPath = `${getBaseUrl()}/providers`;
  const baseParams = {
    'filter[context]': 'patient',
    // 'filter[near]': `${latitude},${longitude}`,
    // 'filter[radius]': 50,
    'fields[providers]':
      'name,profile-image,ui-profile-image,subspecialties,provider-type',
  };

  const urlParams = new URLSearchParams(baseParams);
  let serviceIdParam = serviceIds;

  if (!Array.isArray(serviceIdParam)) {
    serviceIdParam = serviceIds.split(',');
  }

  urlParams.append('page[size]', pageSize);
  urlParams.append('page[number]', pageNumber);
  const apiUrl = `${urlPath}?${urlParams}`;

  return fetch(apiUrl, getHeaderInfo())
    .then(handleResponse)
    .catch(handleError);
}

const searchProvidersUrl = (searchOptions, page = 1, pageSize = 10) => {
  const params = {
    include: 'locations,schedules,schedules.location,schedules.insurance-plans',
    'page[number]': page,
    'page[size]': pageSize,
  };

  // urgency.
  if (searchOptions.urgency && searchOptions.urgency === 'later') {
    // TODO: pass the actual date and time
    params['filter[urgency]'] = searchOptions.urgency;
  }

  // by geocoded latitude and longitude
  if (searchOptions.latitude && searchOptions.longitude) {
    params[
      'filter[near]'
    ] = `${searchOptions.latitude},${searchOptions.longitude}`;

    // setting radius filter.
    if ('radius' in searchOptions && searchOptions.radius !== 0) {
      params['filter[radius]'] = searchOptions.radius;
    }
  }

  // provider name
  if (searchOptions.search) {
    params['filter[search]'] = searchOptions.search;
  }

  if (searchOptions.insurancePlan) {
    params['filter[insurance]'] = searchOptions.insurancePlan;
  }

  // service name
  if (searchOptions.service) {
    params['filter[service]'] = searchOptions.service;
  }

  // appointment type
  if (searchOptions.appointmentTypeId) {
    params['filter[appointment_type]'] = searchOptions.appointmentTypeId;
  }

  // data or time.
  // enable this once API can accept these filters
  // if (searchOptions.date) {
  //   params['filter[start_time]'] = searchOptions.date.start;
  //   params['filter[end_time]'] = searchOptions.date.end;
  // }

  if (searchOptions.permalink) {
    params['filter[permalink]'] = searchOptions.permalink;
  }

  if (searchOptions.ignore_scheduling_restrictions) {
    params['filter[ignore_scheduling_restrictions]'] =
      searchOptions.ignore_scheduling_restrictions;
  }

  return getEndpoint('providers', params);
};

export function searchProviders(searchOptions, page = 1, pageSize = 10) {
  return fetch(
    searchProvidersUrl(searchOptions, page, pageSize),
    getHeaderInfo(),
  )
    .then(handleResponse)
    .catch(handleError);
}

/**
 * We need to fetch physician services data
 * separately using lat, lng, radius,app type
 * @param {Object} physicianServiceVenue
 * @param {String} appointmentTypeId
 * @param {String} insurancePlanId
 * @param {Number} latitude
 * @param {Number} longitude
 */
export async function getPhysicianServicesData(
  physicianServiceVenue,
  appointmentTypeId,
  insurancePlanId,
  latitude,
  longitude,
) {
  if (!latitude || !latitude) {
    return;
  }

  const searchRadius = physicianServiceVenue.attributes.searchDistanceRadius;

  const searchParams = {};
  if (appointmentTypeId) searchParams.appointmentTypeId = appointmentTypeId;
  if (insurancePlanId) searchParams.insurancePlan = insurancePlanId;

  searchParams.latitude = latitude;
  searchParams.longitude = longitude;

  if (searchRadius > 0) {
    searchParams.radius = searchRadius;
  }

  const response = await searchProviders(searchParams);
  const providers = response.data;

  if (!response.included) {
    // eslint-disable-next-line consistent-return
    return { insurancePlans: [], locations: [], schedules: [], providers };
  }

  const schedules = response.included.filter(data => data.type === 'schedules');
  const locations = response.included.filter(data => data.type === 'locations');

  const insurancePlans = response.included.filter(
    data => data.type === 'insurance-plans',
  );

  // eslint-disable-next-line consistent-return
  return { insurancePlans, locations, schedules, providers };
}
