import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTheme, Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DateHeading from 'components/appointmentPicker/DateHeading';
import { BrandingContext } from 'components/contexts/BrandingContext';
import { makeStyles } from '@material-ui/core/styles';
import 'components/appointmentPicker/DateCarousel.scss';
import 'components/inventory/InventoryDateCarousel.scss';

/**
 * This component only shows up on inventory pages
 * if a time-range search is performed.
 * In this case, the next 7 days are presented as filter options.
 */
const InventoryDateCarousel = ({
  dateHeadingLayout,
  timezoneName,
  setTimeWindow,
}) => {
  const { branding } = useContext(BrandingContext);
  const [activeElement, setActiveElement] = useState('All');
  const [index, setIndex] = useState(0);
  const dates = [
    moment().tz(timezoneName),
    moment()
      .tz(timezoneName)
      .add(1, 'day'),
    moment()
      .tz(timezoneName)
      .add(2, 'days'),
    moment()
      .tz(timezoneName)
      .add(3, 'days'),
    moment()
      .tz(timezoneName)
      .add(4, 'days'),
    moment()
      .tz(timezoneName)
      .add(5, 'days'),
    moment()
      .tz(timezoneName)
      .add(6, 'days'),
  ];

  useEffect(() => {
    if (activeElement !== 'All') {
      setTimeWindow({
        range: {
          'schedule_timeblocks.start_date': {
            gte: dates[activeElement]
              .tz(timezoneName)
              .startOf('day')
              .format(),
            lte: dates[activeElement]
              .tz(timezoneName)
              .endOf('day')
              .format(),
          },
        },
      });
    } else {
      setTimeWindow({});
    }
  }, [activeElement]);

  const theme = useTheme();
  const styles = makeStyles({
    allButton: {
      border: '1px solid transparent',
      background: '#fff',
      color: branding.primaryColor,
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
      padding: '0px 8px',
    },
    button: {
      border: '1px solid transparent',
      background: '#fff',
      color: branding.primaryColor,
      padding: '0px',
      whiteSpace: 'nowrap',
      fontWeight: 'bold',
    },
    filledStyle: {
      background: branding.buttonColor,
      border: `1px solid ${branding.buttonColor}`,
      borderRadius: '2px',
      color: branding.buttonTextColor,
    },
  });
  const classes = styles();
  const offset = isMobile ? 2 : 4;
  const componentClass = `InventoryDateCarousel DateCarousel ${dateHeadingLayout}`;

  const checkLeftDisabled = () => {
    if (index === 0) {
      return true;
    }
    return false;
  };

  const checkRightDisabled = () => {
    if (index >= dates.length - offset) {
      return true;
    }
    return false;
  };

  const checkClass = index => {
    if (activeElement === 'All') {
      return '';
    }
    if (activeElement === index) {
      return `${classes.button} ${classes.filledStyle}`;
    }
    return '';
  };
  const leftDisabled = checkLeftDisabled();
  const rightDisabled = checkRightDisabled();
  const controlLeftClass = `control ${leftDisabled ? 'disabled' : ''}`;
  const controlRightClass = `control ${rightDisabled ? 'disabled' : ''}`;

  return (
    <Grid container className={componentClass}>
      <Grid item xs={2}>
        <button
          type="button"
          onClick={() => {
            setActiveElement('All');
          }}
          className={`${activeElement === 'All' ? classes.filledStyle : ''}
           ${classes.allButton}`}
        >
          ALL
        </button>
      </Grid>
      <Grid item xs={2} className={controlLeftClass}>
        <ChevronLeftIcon
          onClick={
            leftDisabled
              ? () => {}
              : () => {
                  setIndex(index - offset);
                }
          }
          fontSize="large"
        />
      </Grid>

      <Grid item container xs={6} className="dates">
        {dates.slice(index, index + offset).map((day, i) => {
          return (
            <Grid
              item
              key={day.tz(timezoneName).format()}
              onClick={() => {
                setActiveElement(index + i);
              }}
              className={checkClass(index + i)}
            >
              <DateHeading
                date={day.tz(timezoneName).format()}
                layout={dateHeadingLayout}
                timezoneName={timezoneName}
                hideDayName
              />
            </Grid>
          );
        })}
      </Grid>

      <Grid item xs={2} className={controlRightClass}>
        <ChevronRightIcon
          onClick={
            rightDisabled
              ? () => {}
              : () => {
                  setIndex(index + offset);
                }
          }
          fontSize="large"
        />
      </Grid>
    </Grid>
  );
};

InventoryDateCarousel.defaultProps = {
  dateHeadingLayout: 'horizontal',
};

InventoryDateCarousel.propTypes = {
  /** eg. 'America/Toronto'. Required for DateHeading component render. */
  timezoneName: PropTypes.string.isRequired,
  /** Date formatting: 'vertical' or 'horizontal' */
  dateHeadingLayout: PropTypes.string,
  setTimeWindow: PropTypes.func.isRequired,
};

export default InventoryDateCarousel;
