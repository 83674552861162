import {
  handleTokenResponse,
  handleError,
  getHeaderInfo,
  getEndpoint,
  getApiKey,
} from './apiUtils';

const apiUrl = getEndpoint('token', {
  api_key: getApiKey(),
});

export function getAuthToken() {
  return fetch(apiUrl, getHeaderInfo())
    .then(handleTokenResponse)
    .catch(handleError);
}
