import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getLocations } from 'api/scheduleApi';
import { getLocation } from 'api/locationApi';
import Spinner from 'components/common/Spinner';
import AppointmentPicker from 'components/appointmentPicker/AppointmentPicker';
import ProviderLocation from 'components/provider/ProviderLocation';
import { useQueryString } from '../../hooks/useQueryString';

const ProviderLocationPicker = ({
  appointmentTypeId,
  scheduleId,
  servicePermalink,
  nowContext,
}) => {
  const [locationId, setLocationId] = useState('');
  const [timezoneName, setTimezoneName] = useState('');
  const [loading, setLoading] = useState(false);
  const { isDischargePage, urlParams } = useQueryString();

  useEffect(() => {
    const fetchLocation = async scheduleId => {
      try {
        await setLoading(true);
        const { data } = await getLocations(scheduleId);
        await setLocationId(data.id);
      } catch (e) {
        // eslint-disable-next-line no-console
        // console.error(`Can't find location for schedule ${scheduleId}`);
      } finally {
        await setLoading(false);
      }
    };

    fetchLocation(scheduleId);
  }, [scheduleId, setLocationId, setLoading]);

  useEffect(() => {
    const fetchLocationDetail = async locationId => {
      // here
      try {
        await setLoading(true);
        const { data } = await getLocation(locationId);
        const zoneName = data.attributes['time-zone'];
        await setTimezoneName(zoneName);
      } catch (e) {
        // eslint-disable-next-line no-console
        // console.error(`Can't find location ${locationId}`);
      } finally {
        await setLoading(false);
      }
    };

    if (locationId) fetchLocationDetail(locationId);
  }, [locationId]);

  const getRegLink = () => {
    let path = `/schedule/${scheduleId}?appointmentTypes=${appointmentTypeId}&service=${servicePermalink}`;
    if (isDischargePage) {
      path = `/${urlParams.dischargeFacility}/discharge${path}`;
    }
    return path;
  };

  if (loading) return <Spinner />;
  if (!timezoneName) return '';
  return (
    <div className="sidebar-content">
      <ProviderLocation locationId={locationId} />
      <AppointmentPicker
        patientNowTimes={nowContext}
        scheduleId={scheduleId.toString()}
        appointmentTypeId={appointmentTypeId.toString()}
        link={getRegLink()}
        amountDaysTablet={2}
        amountDaysDesktop={3}
        amountDaysLgDesktop={4}
        maxTimesPerDay={6}
        showFullScheduleLink
        timezoneName={timezoneName}
        locationId={locationId}
        servicePermalink={servicePermalink}
      />
    </div>
  );
};

ProviderLocationPicker.defaultProps = {
  nowContext: false,
};

ProviderLocationPicker.propTypes = {
  appointmentTypeId: PropTypes.number.isRequired,
  scheduleId: PropTypes.number.isRequired,
  nowContext: PropTypes.bool,
};

export default ProviderLocationPicker;
