import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { makeStyles, TextField, FormHelperText } from '@material-ui/core';
import { spaceToKaleb } from '../../utils/helpers';

const inputStyles = makeStyles({
  formContainer: {
    position: 'relative',
  },
  iconContainer: {
    left: '10px',
    position: 'absolute',
    top: '13px',
    zIndex: 2,
  },
  iconPad: {
    maxWidth: '80%',
    overflow: 'hidden',
    paddingLeft: '25px !important',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  input: {
    '&&:focus': {
      outline: '1px solid transparent',
    },
  },
  inputPad: {
    paddingLeft: '25px !important',
  },
  inputStyleFilled: {
    background: '#f8f9fa',
  },
});

const CustomTextField = ({
  label,
  required,
  fieldKey,
  fieldValue,
  shrink,
  validateFormHandler,
  tabIndex,
  type,
  onBlur,
  onFocus,
  onKeyDown,
  icon,
  filled,
  hasError,
  errorMessage,
}) => {
  const classes = inputStyles();
  const [shrinkValue, setShrinkValue] = useState(false);
  const labelProps = {
    className: icon && !shrinkValue ? classes.iconPad : '',
  };

  useEffect(() => {
    if (fieldValue && fieldValue.value) {
      setShrinkValue(true);
    }
  }, [fieldValue]);

  const handleChange = e => {
    const { value, error } = e.target;

    if (required) {
      // console.log('run validation');
    }

    validateFormHandler({
      [fieldKey]: {
        error: error || false,
        value,
      },
    });
  };

  if (shrink) {
    labelProps.shrink = true;
  }

  return (
    <div className={classes.formContainer}>
      <div className={classes.iconContainer}>{icon}</div>
      <TextField autoComplete="off"
        className={`${filled ? classes.inputStyleFilled : ''}`}
        onKeyDown={onKeyDown}
        onBlur={e => {
          onBlur(e);
          if (!fieldValue || !fieldValue.value) {
            setShrinkValue(false);
          }
        }}
        onFocus={e => {
          onFocus(e);
          setShrinkValue(true);
        }}
        fullWidth
        id={spaceToKaleb(label)}
        InputLabelProps={labelProps}
        InputProps={{
          className: icon ? classes.inputPad : '',
          'tab-index': tabIndex,
        }}
        inputProps={{
          className: classes.input,
        }}
        label={
          <>
            {required && <span className="required">* </span>}
            {label}
          </>
        }
        variant="outlined"
        onChange={handleChange}
        value={!isEmpty(fieldValue) ? fieldValue.value : ''}
        type={type}
        error={hasError}
      />
      {errorMessage && (
        <FormHelperText error={hasError}>{errorMessage}</FormHelperText>
      )}
    </div>
  );
};

CustomTextField.defaultProps = {
  filled: false,
  icon: null,
  required: false,
  shrink: false,
  tabIndex: '0',
  type: 'text',
  onBlur: () => { },
  onFocus: () => { },
  onKeyDown: () => { },
  hasError: false,
  errorMessage: '',
};

CustomTextField.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  fieldValue: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  shrink: PropTypes.bool,
  validateFormHandler: PropTypes.func.isRequired,
  tabIndex: PropTypes.string,
  type: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  icon: PropTypes.element,
  filled: PropTypes.bool,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default CustomTextField;
