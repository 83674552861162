import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import camel from 'camelcase-keys';
import QueryString from 'query-string';
import { Alert } from 'react-bootstrap';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { getProviderSchedulesById } from 'redux/reducers/providersReducer';
import { makeStyles } from '@material-ui/core/styles';
import { getTimes } from 'api/availableTimesApi';
import { isEmpty, groupBy } from 'lodash';
import { WatchLater } from '@material-ui/icons';
import DateRangeIcon from '@material-ui/icons/DateRange';
import './ProviderLocationMobile.scss';
import { connect, useSelector } from 'react-redux';
import { getLocations } from 'api/scheduleApi';
import moment from 'moment-timezone';
import Appointment from 'components/appointmentPicker/Appointment';
import { useTranslation } from 'react-i18next';
import { groupedDays } from 'utils/dateTimeUtils';
import DateCarousel from 'components/appointmentPicker/DateCarousel';
import DialogMobile from 'components/dialogs/DialogMobile';
import PickerDate from 'components/dialogs/DatePicker';
// eslint-disable-next-line camelcase
import { isServiceGroupB_C } from 'config/serviceLineGroupMappings';
import useSettings from 'hooks/useSettings';
import { shuffleSchedulesByLocationOrderMobile } from 'redux/actions/providerActions';
import DialogRadio from '../dialogs/DialogRadio';
import { BrandingContext } from '../contexts/BrandingContext';
import BrandingButton from '../common/BrandingButton';
import { useQueryString } from '../../hooks/useQueryString';

const ProviderLocationMobile = ({
  insurancePlans,
  locations,
  provider,
  schedules,
  updateQueryString,
  appointmentTypeParam,
  scheduleDetailMobile,
  showAllAvailabilityLink,
  onDateChange,
  handleSetDates,
  servicePermalink,
  suppressWarning,
  nowContext,
}) => {
  const { isDischargePage, urlParams } = useQueryString();
  const [activeScheduleIndex, setActiveScheduleIndex] = useState(0);
  const [date, setDate] = useState();
  const [dateTemp, setDateTemp] = useState({});
  const [appointmentQuery, setAppointmentQuery] = useState('');
  const [providerForm, setProviderForm] = useState({
    insurance: {
      value: '',
    },
    appointmentTypes: {
      value: appointmentTypeParam,
    },
  });
  const useStyles = makeStyles(() => ({
    mapButton: {
      background: 'transparent',
      border: 'none',
      appearance: 'none',
    },
  }));
  const classes = useStyles();
  const [showInsuranceDialog, setShowInsuranceDialog] = useState(false);
  const [showAppointmentTypesDialog, setShowAppointmentTypesDialog] = useState(
    false,
  );
  const [apptTypeSelectFlag, setApptTypeSelectFlag] = useState(false);
  const healthSystem = useSelector(state => state.healthSystem);
  const [todaySchedule, setTodaySchedule] = useState({ day: '', times: [] });
  const { branding } = useContext(BrandingContext);
  const [scheduleLocationIds, setScheduleLocationIds] = useState([]);
  const [validSchedules, setValidSchedules] = useState([]);
  const [hideIndexes, setHideIndexes] = useState([]);
  const hideServicesList = ['Emergency Room'];
  const [nextAvailableTime, setNextAvailableTime] = useState('');
  const [numberOfDays, setNumberOfDays] = useState(1);
  const [loading, setLoading] = useState(false);
  const activeSchedule = () => {
    if (validSchedules.length === 0) return;

    // eslint-disable-next-line consistent-return
    return validSchedules[
      Math.abs(activeScheduleIndex % validSchedules.length)
    ];
  };

  const scheduleLocation = () => {
    if (!activeSchedule()) return;
    const locationId = activeSchedule().relationships.location.data.id;
    const stateLocation = locations.find(l => l.id === locationId);
    const location = {};
    location.attributes = camel(stateLocation.attributes);
    // eslint-disable-next-line consistent-return
    return location;
  };

  const scheduleId = activeSchedule()?.id;
  const availableScheduleDays = useSettings({
    scheduleId,
    caller: 'ProviderLocationMobile',
  });

  const getAppointmentTypeId = () => providerForm.appointmentTypes.value;
  const [t] = useTranslation();
  useEffect(() => {
    if (Number(appointmentTypeParam)) {
      setProviderForm({
        ...providerForm,
        appointmentTypes: {
          value: appointmentTypeParam,
        },
      });
    }
  }, [appointmentTypeParam]);

  // Simplified JS Logic to avoid the locize changes. We may change this in future.
  const [noAvailableAppointments, setNoAvailableAppointments] = useState();
  useEffect(() => {
    if (t('noAvailableAppointments') !== 'noAvailableAppointments') {
      const key = t('noAvailableAppointments');
      setNoAvailableAppointments(key.replace('90', availableScheduleDays));
    }
  }, [availableScheduleDays, t('noAvailableAppointments')]);

  useEffect(() => {
    const service = activeSchedule()?.attributes?.service?.permalink;
    if (isServiceGroupB_C(service)) {
      setNoAvailableAppointments(t('noAvailableAppointmentsGroupBC'));
    }
  }, [validSchedules]);
  // Ignore schedules outside of the regional locations
  useEffect(() => {
    const fetchLocation = async schedule => {
      try {
        const { data } = await getLocations(schedule.id);
        if (data && !scheduleLocationIds.includes(data.id)) {
          return data.id;
        }
        return false;
      } catch (e) {
        return false;
      }
    };

    (async () => {
      const serviceIndexes = [];
      const validSchedules = [];
      const locationIdList = schedules.map(async (schedule, index) => {
        if (hideServicesList.includes(schedule.attributes.service.name)) {
          serviceIndexes.push(index);
        }

        const id = await fetchLocation(schedule);

        if (id) {
          validSchedules.push(schedule);
          return id;
        }

        return false;
      });

      setHideIndexes(serviceIndexes);
      setScheduleLocationIds(
        (await Promise.all(locationIdList)).filter(item => item),
      );
      const vSchedules = await shuffleSchedulesByLocationOrderMobile(
        locations,
        validSchedules,
      );
      setValidSchedules(vSchedules);
    })();
  }, []);

  useEffect(() => {
    setDate(
      moment()
        .tz(
          scheduleLocation()?.attributes?.timeZone || healthSystem['time-zone'],
        )
        .format(),
    );
  }, [validSchedules]);

  useEffect(() => {
    if (providerForm.appointmentTypes.value) {
      setApptTypeSelectFlag(true);
      setAppointmentQuery(
        `?appointmentTypes=${providerForm.appointmentTypes.value}`,
      );
    }
  }, [providerForm]);
  const compare = () => {
    return (
      moment(date)
        .tz(scheduleLocation()?.attributes?.timeZone)
        .startOf('day')
        .format() ===
      moment(todaySchedule?.times?.[0])
        .tz(scheduleLocation()?.attributes?.timeZone)
        .startOf('day')
        .format()
    );
  };
  const fetchTimes = async (scheduleId, date, days, runSetState) => {
    setLoading(true);
    setNextAvailableTime('');
    const dateObj = {
      day: null,
      times: [],
    };
    const today = moment()
      .tz(scheduleLocation()?.attributes?.timeZone || healthSystem['time-zone'])
      .format();
    const toDate = moment(date)
      .tz(scheduleLocation()?.attributes?.timeZone || healthSystem['time-zone'])
      .startOf('day')
      .add(1, 'day')
      .format();
    const toDateToCompare = moment(today)
      .tz(scheduleLocation()?.attributes?.timeZone || healthSystem['time-zone'])
      .add(availableScheduleDays - 1, 'days')
      .format();
    const timesResponse = await getTimes(
      scheduleId,
      getAppointmentTypeId(),
      date,
      days,
      isDischargePage ? 'discharge' : nowContext ? 'patient_now' : 'patient',
      toDate,
    );
    if (timesResponse[0]['next-time']) {
      const nextTime = timesResponse[0]['next-time'];
      if (
        moment(nextTime)
          .tz(
            scheduleLocation()?.attributes?.timeZone ||
              healthSystem['time-zone'],
          )
          .isSameOrBefore(toDateToCompare)
      )
        setNextAvailableTime(timesResponse[0]['next-time']);
    }
    if (runSetState) {
      const { times } = timesResponse[0];
      const groupedTimes = groupBy(
        times,
        moment(date).tz(
          scheduleLocation()?.attributes?.timeZone || healthSystem['time-zone'],
        ),
      );
      const datesList = Object.keys(groupedTimes);
      dateObj.day = datesList[0];
      dateObj.times = groupedTimes[datesList[0]] || [];
    }

    return dateObj;
  };

  useEffect(() => {
    if (!activeSchedule()) return;

    let runSetState = true;

    (async () => {
      if (date) {
        const scheduleId = activeSchedule().id;
        const days = 1;
        if (
          (activeSchedule()?.attributes?.forceDeactivation === false ||
            activeSchedule()?.attributes?.['force-deactivation'] === false) &&
          activeSchedule()?.attributes?.active === true
        )
          setTodaySchedule(
            await fetchTimes(scheduleId, date, days, runSetState),
          );
        else setTodaySchedule([]);
        /* const nextDate = moment(date).add(1, 'days');
    const nextDateTime = await fetchTimes(
      scheduleId,
      nextDate,
      days,
      runSetState,
    );

    setHasNextDate(nextDateTime.day); */
        setLoading(false);
      }
    })();

    // eslint-disable-next-line consistent-return
    return () => {
      runSetState = false;
    };
  }, [
    validSchedules,
    date,
    activeScheduleIndex,
    providerForm.appointmentTypes.value,
  ]);

  const insurancePlansOptions = insurancePlans.map(plan => {
    return { value: plan.id, text: plan.attributes.name };
  });
  const [dates, setDates] = useState();
  useEffect(() => {
    setDates(groupedDays(date, 1, healthSystem['time-zone']));
  }, [date]);

  const handleDateChange = date => {
    setLoading(true);
    setDate(date);
    setDates(groupedDays(date, numberOfDays, healthSystem['time-zone']));
  };
  const appointmentTypesOptions = () => {
    let types = [];
    const schedule = activeSchedule();

    if (provider && provider.attributes && !scheduleDetailMobile) {
      types = provider.attributes.appointmentTypes;
    } else if (schedule && schedule.attributes) {
      types = schedule.attributes['appointment-types'];
    }

    return types.map(at => ({
      value: at.id.toString(),
      text: at.name,
    }));
  };

  const handleSetFormState = e => {
    const [key, obj] = Object.entries(e).shift();

    setProviderForm({
      ...providerForm,
      [key]: obj,
    });
  };
  const [showTimePickerDialog, setShowTimePickerDialog] = useState(false);
  useEffect(() => {
    if (providerForm.appointmentTypes.value) {
      const apptTypeObj = appointmentTypesOptions().find(
        obj => obj.value === providerForm.appointmentTypes.value.toString(),
      );

      if (apptTypeObj) {
        handleSetFormState({ appointmentTypes: apptTypeObj });
      }
    }
  }, [providerForm.appointmentTypes.value, validSchedules]);

  const changeSchedule = incrementAmount => {
    const newScheduleIndex = activeScheduleIndex + incrementAmount;
    setActiveScheduleIndex(newScheduleIndex);
  };

  const hasTimes = () =>
    todaySchedule.times &&
    todaySchedule.times.length > 0 &&
    todaySchedule.day !== '';

  const dischargeLink = () => {
    return isDischargePage ? `/${urlParams.dischargeFacility}/discharge` : '';
  };

  const nextAvailableTimeLink = date => {
    const apptQueryString = QueryString.stringify({
      appointmentTypes: getAppointmentTypeId(),
      date: moment(date)
        .tz(scheduleLocation().attributes.timeZone)
        .format(),
    });
    const apptLink = `${dischargeLink()}/schedule/${
      activeSchedule().id
    }?${apptQueryString}`;
    return apptLink;
  };

  if (scheduleLocationIds.length === 0 || !activeSchedule()) {
    return '';
  }

  const getCurrentServiceName = () => {
    if (!activeSchedule()) return;
    return activeSchedule().attributes.service.name;
  };

  return (
    <div className="ProviderLocationMobile">
      {!isEmpty(appointmentTypesOptions()) && (
        <div className="btn-container">
          <Typography variant="caption">
            {t('RegistrationForm.appointmentType')}
          </Typography>
          <BrandingButton onClick={() => setShowAppointmentTypesDialog(true)}>
            {providerForm.appointmentTypes.text || t('appointmentType')}
          </BrandingButton>
        </div>
      )}

      {!isEmpty(scheduleLocation()) && (
        <Grid
          className="mb-4 mt-4 text-center"
          container
          alignItems="center"
          justify="center"
        >
          <Grid item xs={1}>
            {scheduleLocationIds.length > 1 && (
              <button type="button" onClick={() => changeSchedule(-1)}>
                <ChevronLeftIcon className="icon" />
              </button>
            )}
          </Grid>

          <Grid item xs={10}>
            <Typography className="heading" variant="h5" component="h1">
              {scheduleLocation().attributes.facilityName}
            </Typography>

            <p>{scheduleLocation().attributes.address}</p>
            <p>
              {scheduleLocation().attributes.city},{' '}
              {scheduleLocation().attributes.state}{' '}
              {scheduleLocation().attributes.zip}
            </p>
          </Grid>

          <Grid item xs={1}>
            {scheduleLocationIds.length > 1 && (
              <button type="button" onClick={() => changeSchedule(1)}>
                <ChevronRightIcon className="icon" />
              </button>
            )}
          </Grid>
        </Grid>
      )}
      <Grid
        className="mb-4 text-center times-container"
        container
        alignItems="flex-start"
        justify="center"
      >
        <Grid item xs={8}>
          <DateCarousel
            dates={dates}
            onDateChange={date => {
              setDate(date);
              // Reset the Date Time
              setTodaySchedule({
                day: null,
                times: [],
              });
            }}
            serviceName={getCurrentServiceName()}
            timezoneName={
              scheduleLocation()?.attributes?.timeZone ||
              healthSystem['time-zone']
            }
            dateHeadingLayout="horizontal"
            scheduleId={scheduleId}
          />
        </Grid>
        <Grid className="ProviderDate CalendarIconM" item xs={2}>
          <button
            aria-label="Calender Icon"
            className={`view-button ${classes.mapButton}`}
            type="button"
            onClick={() => setShowTimePickerDialog(true)}
          >
            <DateRangeIcon style={{ marginRight: '5px' }} />
          </button>
        </Grid>
      </Grid>
      <Grid>
        {showTimePickerDialog && (
          <DialogMobile
            content={
              <PickerDate
                handleChange={e => {
                  const startDate = moment(e?.start);
                  if (startDate.isValid()) {
                    setDateTemp(e);
                  }
                }}
                timezone={
                  scheduleLocation()?.attributes?.timeZone ||
                  healthSystem['time-zone']
                }
                timestamp={moment(dates[0])
                  .tz(
                    scheduleLocation()?.attributes?.timeZone ||
                      healthSystem['time-zone'],
                  )
                  .format()}
                scheduleId={scheduleId}
                // fixme later, add timestamp without throwing exception
              />
            }
            handleShowDialog={e => {
              setShowTimePickerDialog(false);
              if (e) {
                onDateChange(
                  moment(dateTemp?.start).tz(
                    scheduleLocation()?.attributes?.timeZone ||
                      healthSystem['time-zone'],
                  ),
                );
                handleSetDates(dateTemp?.start);
                handleDateChange(dateTemp?.start);
              }
            }}
            icon={<WatchLater />}
            showDialog={showTimePickerDialog}
            title="Date Picker"
          />
        )}
      </Grid>

      {(!hasTimes() || !compare()) &&
        getAppointmentTypeId() &&
        nextAvailableTime !== '' && (
          <Grid item container sm={10} className="dates">
            <Link
              to={nextAvailableTimeLink(nextAvailableTime)}
              className={
                activeSchedule()?.attributes?.active
                  ? 'Appointment Link'
                  : 'Appointment AppointmentDisabled Link'
              }
              style={{ textDecoration: 'none' }}
            >
              <button
                type="button"
                className="btn-default-outline appointment-button"
              >
                {t('nextavailabiltyis')}{' '}
                {moment(nextAvailableTime)
                  .tz(scheduleLocation().attributes.timeZone)
                  .format('ddd, MMM DD [at] hh:mm A')}
              </button>
            </Link>
          </Grid>
        )}

      {!hasTimes() && getAppointmentTypeId() && nextAvailableTime === '' && (
        <Grid item container sm={10} className="dates">
          <>
            <div className="noAvailableAppointments">
              {loading
                ? `${t('ScheduleResultList.hangTight')} ${t(
                    'ScheduleResultList.fetchingresults',
                  )}...`
                : noAvailableAppointments}
            </div>
          </>
        </Grid>
      )}

      {!suppressWarning && !apptTypeSelectFlag && (
        <Grid item container sm={10} className="dates">
          <Alert
            variant="info"
            style={{
              width: '100%',
              background: branding.secondaryColor,
              color: branding.primaryColor,
            }}
          >
            {t('PhysicianForm.selectApp')}
          </Alert>
        </Grid>
      )}

      {hasTimes() && compare() && !(!suppressWarning && !apptTypeSelectFlag) && (
        <Grid
          className="mb-4 text-center times-container"
          container
          alignItems="flex-start"
          justify="center"
        >
          <Grid item xs={12}>
            <div className="time-container">
              {todaySchedule.times.map(time => (
                <div key={time} className="time-container-button">
                  <Appointment
                    link={`${dischargeLink()}/schedule/${
                      activeSchedule().id
                    }?appointmentTypes=${getAppointmentTypeId()}&service=${servicePermalink}`}
                    location={scheduleLocation()}
                    time={time}
                    outline
                    timezoneName={scheduleLocation().attributes.timeZone}
                    providerName={
                      provider.attributes ? provider.attributes.name : ''
                    }
                    inactive={!activeSchedule()?.attributes?.active}
                  />
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      )}

      {showAllAvailabilityLink && (
        <Grid container justify="space-between">
          <Grid item>
            <Link
              to={`${dischargeLink()}/schedule/calendar/${
                activeSchedule().id
              }${appointmentQuery}&service=${servicePermalink}`}
            >
              {t('ProviderLocationMobile.allAvailability')}
            </Link>
          </Grid>
        </Grid>
      )}

      {showAppointmentTypesDialog && (
        <DialogRadio
          handleShowDialog={e => {
            if (e) {
              handleSetFormState({ appointmentTypes: e });
              updateQueryString({
                appointmentTypes: e.value,
              });
              if (isEmpty(e.value)) setApptTypeSelectFlag(false);
            }

            setShowAppointmentTypesDialog(false);
          }}
          selections={appointmentTypesOptions()}
          showDialog={showAppointmentTypesDialog}
          title={t('RegistrationForm.appointmentType')}
          selectedValue={providerForm.appointmentTypes.value}
        />
      )}

      {showInsuranceDialog && (
        <DialogRadio
          handleShowDialog={e => {
            if (e) {
              handleSetFormState({ insurance: e });
            }

            setShowInsuranceDialog(false);
          }}
          selections={insurancePlansOptions}
          showDialog={showInsuranceDialog}
          title="Insurances"
          selectedValue={providerForm.insurance.value}
        />
      )}
    </div>
  );
};

ProviderLocationMobile.defaultProps = {
  scheduleDetailMobile: false,
  showAllAvailabilityLink: false,
  suppressWarning: false,
  onDateChange: () => {},
  handleSetDates: () => {},
  nowContext: false,
};

ProviderLocationMobile.propTypes = {
  provider: PropTypes.instanceOf(Object).isRequired,
  insurancePlans: PropTypes.instanceOf(Array).isRequired,
  locations: PropTypes.instanceOf(Array).isRequired,
  schedules: PropTypes.instanceOf(Array).isRequired,
  updateQueryString: PropTypes.func.isRequired,
  appointmentTypeParam: PropTypes.string.isRequired,
  scheduleDetailMobile: PropTypes.bool,
  showAllAvailabilityLink: PropTypes.bool,
  onDateChange: PropTypes.func,
  handleSetDates: PropTypes.func,
  servicePermalink: PropTypes.string,
  /** Optional setting to hide warning alert.
   * Used for schedules with no appt types. */
  suppressWarning: PropTypes.bool,
  nowContext: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
  schedules:
    props.schedules || getProviderSchedulesById(state, props.provider.id),
});

export default connect(mapStateToProps)(ProviderLocationMobile);
