export const serviceLineGroupMapping = {
  "find-a-doctor":                                    "GROUP_A",
  "emergency-room":                                   "GROUP_B",
  "emergency-department":                             "GROUP_B",
  "pediatric-emergency-room":                         "GROUP_B",
  "pediatric-emergency":                              "GROUP_B",
  "immediate-care":                                   "GROUP_C",
  "urgent-care":                                      "GROUP_C",
  "walk-in":                                          "GROUP_C",
  "acute-spine":                                      "GROUP_D",
  "acupuncture":                                      "GROUP_D",
  "addiction-medicine":                               "GROUP_D",
  "aesthetic-medicine":                               "GROUP_D",
  "audiology":                                        "GROUP_D",
  "athletic-trainer":                                 "GROUP_D",
  "cardiac-rehabilitation":                           "GROUP_D",
  "addiction-psychiatry":                             "GROUP_D",
  "adolescent-medicine":                              "GROUP_D",
  "adult-nurse-practitioner":                         "GROUP_D",
  "allergy":                                          "GROUP_D",
  "anatomic-clinical-pathology":                      "GROUP_D",
  "anatomic-pathology":                               "GROUP_D",
  "anesthesiology":                                   "GROUP_D",
  "anesthesiology-critical-care-medicine":            "GROUP_D",
  "back-neck-surgery":                                "GROUP_D",
  "bariatric-surgery":                                "GROUP_D",
  "behavioral-health":                                "GROUP_D",
  "blood-banking-transfusion-medicine":               "GROUP_D",
  "breast-surgery":                                   "GROUP_D",
  "breastfeeding-medicine":                           "GROUP_D",
  "cardiology":                                       "GROUP_D",
  "cardiology-advanced-heart-failure":                "GROUP_D",
  "cardiology-invasive":                              "GROUP_D",
  "cardiology-invasive-interventional":               "GROUP_D",
  "cardiology-invasive-non-interventional":           "GROUP_D",
  "cardiology-noninvasive":                           "GROUP_D",
  "cardiothoracic-surgery":                           "GROUP_D",
  "cardiovascular-disease":                           "GROUP_D",
  "child-abuse-pediatrics":                           "GROUP_D",
  "child-adolescent-psychiatry":                      "GROUP_D",
  "chiropractic":                                     "GROUP_D",
  "clinical-cardiac-electrophysiology":               "GROUP_D",
  "clinical-genetics-genomics":                       "GROUP_D",
  "clinical-neurophysiology":                         "GROUP_D",
  "clinical-psychology":                              "GROUP_D",
  "colon-rectal-surgery":                             "GROUP_D",
  "colonoscopy":                                      "GROUP_D",
  "counseling-psychology":                            "GROUP_D",
  "critical-care-medicine":                           "GROUP_D",
  "cytopathology":                                    "GROUP_D",
  "dentistry":                                        "GROUP_D",
  "dermatology":                                      "GROUP_D",
  "dermatopathology":                                 "GROUP_D",
  "diagnostic-radiology":                             "GROUP_D",
  "ear-nose-throat":                                  "GROUP_D",
  "emergency-medicine":                               "GROUP_D",
  "employee-health":                                  "GROUP_D",
  "endocrine-surgery":                                "GROUP_D",
  "endocrinology":                                    "GROUP_D",
  "endodontics":                                      "GROUP_D",
  "epilepsy":                                         "GROUP_D",
  "family-medicine":                                  "GROUP_D",
  "family-nurse-practitioner":                        "GROUP_D",
  "female-pelvic-medicine-reconstructive-surgery":    "GROUP_D",
  "foot-ankle-surgery":                               "GROUP_D",
  "forensic-psychiatry":                              "GROUP_D",
  "gastroenterology":                                 "GROUP_D",
  "general-surgery":                                  "GROUP_D",
  "geriatric-psychiatry":                             "GROUP_D",
  "geriatrics":                                       "GROUP_D",
  "gerontological-nurse-practitioner":                "GROUP_D",
  "gynecologic-oncology":                             "GROUP_D",
  "gynecologic-surgery-minimally-invasive":           "GROUP_D",
  "gynecology":                                       "GROUP_D",
  "hand-surgery":                                     "GROUP_D",
  "hand-wrist-surgery":                               "GROUP_D",
  "head-neck-surgery":                                "GROUP_D",
  "hematology":                                       "GROUP_D",
  "hematology-oncology":                              "GROUP_D",
  "hepatobiliary-surgery":                            "GROUP_D",
  "hepatobiliary-pancreatic-surgery":                 "GROUP_D",
  "hospice-palliative-medicine":                      "GROUP_D",
  "hospital-medicine":                                "GROUP_D",
  "hyperbaric-medicine":                              "GROUP_D",
  "infectious-disease":                               "GROUP_D",
  "internal-medicine":                                "GROUP_D",
  "interventional-cardiology":                        "GROUP_D",
  "interventional-neurology":                         "GROUP_D",
  "interventional-radiology":                         "GROUP_D",
  "joint-replacement":                                "GROUP_D",
  "maternal-fetal-medicine":                          "GROUP_D",
  "medical-genetics-genomics":                        "GROUP_D",
  "molecular-genetic-pathology":                      "GROUP_D",
  "neonatal-perinatal-medicine":                      "GROUP_D",
  "neonatology":                                      "GROUP_D",
  "nephrology":                                       "GROUP_D",
  "neuro-interventional-radiology":                   "GROUP_D",
  "neurological-surgery":                             "GROUP_D",
  "neurology":                                        "GROUP_D",
  "neuropathology":                                   "GROUP_D",
  "neuropsychology":                                  "GROUP_D",
  "neuroradiology":                                   "GROUP_D",
  "neurotology":                                      "GROUP_D",
  "nuclear-medicine":                                 "GROUP_D",
  "nuclear-radiology":                                "GROUP_D",
  "nurse-midwifery":                                  "GROUP_D",
  "nutrition-services":                               "GROUP_D",
  "obgyn":                                            "GROUP_D",
  "obstetrics":                                       "GROUP_D",
  "occupational-health":                              "GROUP_D",
  "oncology":                                         "GROUP_D",
  "ophthalmology":                                    "GROUP_D",
  "oral-maxillofacial-pathology":                     "GROUP_D",
  "oral-maxillofacial-surgery":                       "GROUP_D",
  "orthodontics":                                     "GROUP_D",
  "orthopaedic-sports-medicine":                      "GROUP_D",
  "orthopaedic-surgery":                              "GROUP_D",
  "orthopedics":                                      "GROUP_D",
  "otolaryngology":                                   "GROUP_D",
  "pain-management":                                  "GROUP_D",
  "pathology":                                        "GROUP_D",
  "pediatric-allergy-immunology":                     "GROUP_D",
  "pediatric-anesthesiology":                         "GROUP_D",
  "pediatric-behavior-development":                   "GROUP_D",
  "pediatric-cardiac-surgery":                        "GROUP_D",
  "pediatric-cardiology":                             "GROUP_D",
  "pediatric-critical-care":                          "GROUP_D",
  "pediatric-dentistry":                              "GROUP_D",
  "pediatric-dermatology":                            "GROUP_D",
  "pediatric-emergency-medicine":                     "GROUP_D",
  "pediatric-endocrinology":                          "GROUP_D",
  "pediatric-gastroenterology":                       "GROUP_D",
  "pediatric-genetics":                               "GROUP_D",
  "pediatric-hematology-oncology":                    "GROUP_D",
  "pediatric-hospital-medicine":                      "GROUP_D",
  "pediatric-infectious-disease":                     "GROUP_D",
  "pediatric-mental-health":                          "GROUP_D",
  "pediatric-nephrology":                             "GROUP_D",
  "pediatric-neurology":                              "GROUP_D",
  "pediatric-neurosurgery":                           "GROUP_D",
  "pediatric-ophthalmology":                          "GROUP_D",
  "pediatric-orthopedic-surgery":                     "GROUP_D",
  "pediatric-otolaryngology":                         "GROUP_D",
  "pediatric-physiatry":                              "GROUP_D",
  "pediatric-plastic-and-craniofacial-surgery":       "GROUP_D",
  "pediatric-pulmonology":                            "GROUP_D",
  "pediatric-radiology":                              "GROUP_D",
  "pediatric-sleep-medicine":                         "GROUP_D",
  "pediatric-surgery":                                "GROUP_D",
  "pediatric-transplant-hepatology":                  "GROUP_D",
  "pediatric-urology":                                "GROUP_D",
  "pelvic-medicine":                                  "GROUP_D",
  "perinatology":                                     "GROUP_D",
  "periodontics":                                     "GROUP_D",
  "physiatry":                                        "GROUP_D",
  "physical-medicine-rehabilitation":                 "GROUP_D",
  "physical-therapy":                                 "GROUP_D",
  "physician-assistant":                              "GROUP_D",
  "plastic-surgery":                                  "GROUP_D",
  "podiatry":                                         "GROUP_D",
  "preventive-medicine":                              "GROUP_D",
  "primary-care":                                     "GROUP_D",
  "proctology":                                       "GROUP_D",
  "prosthodontics":                                   "GROUP_D",
  "psychiatry":                                       "GROUP_D",
  "psychology":                                       "GROUP_D",
  "pulmonology":                                      "GROUP_D",
  "radiation-oncology":                               "GROUP_D",
  "radiology":                                        "GROUP_D",
  "reconstructive-rearfoot-ankle-surgery":            "GROUP_D",
  "rehabilitation-psychology":                        "GROUP_D",
  "reproductive-endocrinology":                       "GROUP_D",
  "retina-specialist":                                "GROUP_D",
  "rheumatology":                                     "GROUP_D",
  "shoulder-surgery":                                 "GROUP_D",
  "sleep-medicine":                                   "GROUP_D",
  "social-work":                                      "GROUP_D",
  "specialty-care":                                   "GROUP_D",
  "spinal-cord-injury-medicine":                      "GROUP_D",
  "spine-surgery":                                    "GROUP_D",
  "sports-medicine":                                  "GROUP_D",
  "surgery":                                          "GROUP_D",
  "surgical-critical-care":                           "GROUP_D",
  "surgical-oncology":                                "GROUP_D",
  "thoracic-cardiovascular-surgery":                  "GROUP_D",
  "thoracic-surgery":                                 "GROUP_D",
  "transplant-hepatology":                            "GROUP_D",
  "transplant-surgery":                               "GROUP_D",
  "ultrasound":                                       "GROUP_D",
  "undersea-hyperbaric-medicine":                     "GROUP_D",
  "urogynecology":                                    "GROUP_D",
  "urologic-surgery":                                 "GROUP_D",
  "urology":                                          "GROUP_D",
  "vascular-cardiology":                              "GROUP_D",
  "vascular-interventional-radiology":                "GROUP_D",
  "vascular-neurology":                               "GROUP_D",
  "vascular-surgery":                                 "GROUP_D",
  "wound-care":                                       "GROUP_D",
  "ophthamology":                                     "GROUP_D",
  "pediatrics":                                       "GROUP_D",
  "preoperative-care":                                "GROUP_D",
  "bone-density":                                     "GROUP_E",
  "ct-scan":                                          "GROUP_E",
  "covid-testing":                                    "GROUP_E",
  "diagnostic-imaging":                               "GROUP_E",
  "exercise-physiology":                              "GROUP_E",
  "fitness":                                          "GROUP_E",
  "flu-shot":                                         "GROUP_E",
  "laboratory-services":                              "GROUP_E",
  "life-long-health":                                 "GROUP_E",
  "mammography":                                      "GROUP_E",
  "mri":                                              "GROUP_E",
  "ortho-on-demand":                                  "GROUP_E",
  "pediatric-specialty-care":                         "GROUP_E",
  "proton-therapy":                                   "GROUP_E",
  "recover-medicine":                                 "GROUP_E",
  "vaccine":                                          "GROUP_E",
  "telehealth":                                       "GROUP_F",
  "health-spa-services":                              "GROUP_G",
  "aesthetics":                                       "GROUP_G",
  "nail-services":                                    "GROUP_G",
  "massage-therapy":                                  "GROUP_G",
  "er-uc":                                            "GROUP_C",
  // adding missing service lines 11/10/22
  "acupuncturist":	                                  "GROUP_D",
  "adult-congenital-heart-disease":                   "GROUP_D",
  "adult-gerontology-nurse-practitioner":           	"GROUP_D",
  "advanced-heart-failure-transplant-cardiology":	    "GROUP_D",
  "advanced-laparoscopy":	                            "GROUP_D",
  "aerospace-medicine":                             	"GROUP_D",
  "aesthetic-plastic-surgery":                      	"GROUP_D",
  "aids-hiv-medicine":                              	"GROUP_D",
  "allergies-immunology":                           	"GROUP_D",
  "alternative-medicine":                            	"GROUP_D",
  "alzheimers-care":                                	"GROUP_D",
  "anger-management":                               	"GROUP_D",
  "bariatric-medicine":                             	"GROUP_D",
  "biofeedback-therapy":                            	"GROUP_D",
  "birth-designer":                                 	"GROUP_D",
  "botox":                                          	"GROUP_D",
  "brain-injury-medicine":                          	"GROUP_D",
  "breast-imaging":                                 	"GROUP_D",
  "breast-medical-oncology":                         	"GROUP_D",
  "cardiac-electrophysiology":                      	"GROUP_D",
  "cardiovascular-surgery":                         	"GROUP_D",
  "chemical-pathology":                             	"GROUP_D",
  "child-adolescent-psychology":                    	"GROUP_D",
  "child-and-adolescent-psychiatry":                	"GROUP_D",
  "clinical-biochemical-genetics":                  	"GROUP_D",
  "clinical-cytogenetics-genomics":                 	"GROUP_D",
  "clinical-health-psychology":                     	"GROUP_D",
  "clinical-informatics":                           	"GROUP_D",
  "clinical-laboratory-immunology":                 	"GROUP_D",
  "clinical-molecular-genetics-genomics":           	"GROUP_D",
  "clinical-neuropsychology":                       	"GROUP_D",
  "clinical-pathology":                              	"GROUP_D",
  "clinical-pharmacology":                          	"GROUP_D",
  "cognitive-behavioral-psychology":                	"GROUP_D",
  "complex-family-planning":                        	"GROUP_D",
  "congenital-cardiac-surgery":                     	"GROUP_D",
  "copyname.txt":                                   	"GROUP_D",
  "cosmetic-dermatology":                           	"GROUP_D",
  "cosmetic-surgery":                               	"GROUP_D",
  "craniofacial-surgery":                           	"GROUP_D",
  "critical-care-surgery":                          	"GROUP_D",
  "dental-public-health":                           	"GROUP_D",
  "dentofacial-orthopedics":                        	"GROUP_D",
  "depression-therapy":                             	"GROUP_D",
  "dermatologic-immunology":                        	"GROUP_D",
  "diabetes-care-education":                        	"GROUP_D",
  "echocardiology":                                 	"GROUP_D",
  "electrodiagnostic-medicine":                     	"GROUP_D",
  "electrolysis":                                   	"GROUP_D",
  "electrophysiology":                               	"GROUP_D",
  "emergency-nurse-practitioner":                   	"GROUP_D",
  "ent-ear-nose-throat":                            	"GROUP_D",
  "environmental-medicine":                         	"GROUP_D",
  "eye-care":                                       	"GROUP_D",
  "facial-plastic-reconstr-surgery":                	"GROUP_D",
  "facial-plastic-surgery":                         	"GROUP_D",
  "family-psychology":                              	"GROUP_D",
  "foot-ankle":                                     	"GROUP_D",
  "foot-surgery":                                   	"GROUP_D",
  "forensic-pathology":                              	"GROUP_D",
  "forensic-psychology":	                            "GROUP_D",
  "gastrointestinal-medical-oncology":              	"GROUP_D",
  "genetic-oncology":                               	"GROUP_D",
  "genetics":                                       	"GROUP_D",
  "genitourinary-medical-oncology":                  	"GROUP_D",
  "geriatric-medicine":                             	"GROUP_D",
  "gerontology":                                    	"GROUP_D",
  "group-psychology":                               	"GROUP_D",
  "hand-wrist":                                     	"GROUP_D",
  "headache-medicine":                               	"GROUP_D",
  "head-and-neck-medical-oncology":                 	"GROUP_D",
  "head-and-neck-oncology":                         	"GROUP_D",
  "head-and-neck-plastic-surgery":                  	"GROUP_D",
  "head-lice-treatment":                            	"GROUP_D",
  "head-neck-surgical-oncology":                    	"GROUP_D",
  "head-neck-surgical-onc-reconstr":                	"GROUP_D",
  "health-coach":                                   	"GROUP_D",
  "hepatology":                                     	"GROUP_D",
  "herbal-medicine":	                                "GROUP_D",
  "holistic-medicine":	                              "GROUP_D",
  "homeopathy":	                                      "GROUP_D",
  "hospice-and-palliative-medicine":	                "GROUP_D",
  "hypnotherapy":	                                    "GROUP_D",
  "immunopathology":	                                "GROUP_D",
  "infectious-disease-medicine":	                    "GROUP_D",
  "infertility-and-reproductive-disorders":	          "GROUP_D",
  "injury-and-wound-care":	                          "GROUP_D",
  "integrative-medicine":	                            "GROUP_D",
  "internal-medicine-pediatrics":	                    "GROUP_D",
  "interventional-neuroradiology":	                  "GROUP_D",
  "intestinal-transplantation":	                      "GROUP_D",
  "kidney-pancreas-transplantation":	                "GROUP_D",
  "laboratory-medicine":	                            "GROUP_D",
  "lactation-consult":	                              "GROUP_D",
  "laser-vision-surgery":	                            "GROUP_D",
  "lipidology":	                                      "GROUP_D",
  "liver-surgery":	                                  "GROUP_D",
  "male-enhancement":	                                "GROUP_D",
  "maternal-and-fetal-medicine":	                    "GROUP_D",
  "medical-microbiology":	                            "GROUP_D",
  "medical-oncology":	                                "GROUP_D",
  "medical-specialties":	                            "GROUP_D",
  "medical-toxicology":	                              "GROUP_D",
  "medical-treatments":	                              "GROUP_D",
  "mens-health-clinic":	                              "GROUP_D",
  "mental-behavioral-health":	                        "GROUP_D",
  "mental-health-service":	                          "GROUP_D",
  "micrographic-dermatologic-surgery":	              "GROUP_D",
  "musculoskeletal-medicine":	                        "GROUP_D",
  "music-therapy":	                                  "GROUP_D",
  "naturopathic-medicine":	                          "GROUP_D",
  "neonatal-nurse-practitioner":	                    "GROUP_D",
  "neonatal-surgery":	                                "GROUP_D",
  "neurocritical-care":	                              "GROUP_D",
  "neurodevelopmental-care":	                        "GROUP_D",
  "neurodevelopmental-disabilities":	                "GROUP_D",
  "neuromuscular-medicine":	                          "GROUP_D",
  "neuro-oncology":	                                  "GROUP_D",
  "neuro-ophthalmology":	                            "GROUP_D",
  "neuropsychiatry":	                                "GROUP_D",
  "neurosurgery":	                                    "GROUP_D",
  "non-surgical-weight-loss":	                        "GROUP_D",
  "nuclear-cardiology":	                              "GROUP_D",
  "nuclear-imaging-therapy":	                        "GROUP_D",
  "nutrition-weight-loss":	                          "GROUP_D",
  "obesity-medicine":	                                "GROUP_D",
  "obstetrics-gynecology":	                          "GROUP_D",
  "occupational-medicine":	                          "GROUP_D",
  "occupational-therapy":	                            "GROUP_D",
  "ocular-oncology":	                                "GROUP_D",
  "ophthalmic-pathology":	                            "GROUP_D",
  "ophthalmic-surgery":	                              "GROUP_D",
  "optometry":	                                      "GROUP_D",
  "oral-and-maxillofacial-surgery":	                  "GROUP_D",
  "oral-maxillofacial-radiology":	                    "GROUP_D",
  "organizational-business-psychology":	              "GROUP_D",
  "orthodontics-and-dentofacial-orthopedics":	        "GROUP_D",
  "orthopaedic-oncology":	                            "GROUP_D",
  "orthopedic-trauma":	                              "GROUP_D",
  "osteopathic-medicine":	                            "GROUP_D",
  "pediatric-adolescent-gynecology":                	"GROUP_D",
  "pediatric-allergist":                            	"GROUP_D",
  "pediatric-allergy-and-immunology":               	"GROUP_D",
  "pediatric-cardiovascular-disease":               	"GROUP_D",
  "pediatric-cardiovascular-surgery":               	"GROUP_D",
  "pediatric-chiropractor":                         	"GROUP_D",
  "pediatric-critical-care-medicine":               	"GROUP_D",
  "pediatric-hematology":                            	"GROUP_D",
  "pediatric-infectious-disease-medicine":          	"GROUP_D",
  "pediatric-internal-medicine":                    	"GROUP_D",
  "pediatric-medical-specialties":                  	"GROUP_D",
  "pediatric-medical-toxicology":                   	"GROUP_D",
  "pediatric-neuro-oncology":                       	"GROUP_D",
  "pediatric-nurse-practitioner":                   	"GROUP_D",
  "pediatric-oncology":                             	"GROUP_D",
  "pediatric-orthopedics":                          	"GROUP_D",
  "pediatric-pathology":                            	"GROUP_D",
  "pediatric-plastic-surgery":                      	"GROUP_D",
  "pediatric-psychiatry":                            	"GROUP_D",
  "pediatric-psychology":                           	"GROUP_D",
  "pediatric-rehabilitation-medicine":              	"GROUP_D",
  "pediatric-rheumatology":                         	"GROUP_D",
  "pediatric-sports-medicine":                      	"GROUP_D",
  "pediatric-surgical-oncology":                    	"GROUP_D",
  "pediatric-trauma-surgery":                       	"GROUP_D",
  "phlebology":                                     	"GROUP_D",
  "plastic-and-reconstructive-surgery":             	"GROUP_D",
  "plastic-reconstructive-surgery":                 	"GROUP_D",
  "prenatal-ultrasound-imaging":                    	"GROUP_D",
  "pre-surgical-testing":                           	"GROUP_D",
  "psychoanalysis":                                 	"GROUP_D",
  "psychoanalysis-psychology":                      	"GROUP_D",
  "psychosomatic-medicine":                         	"GROUP_D",
  "psychotherapy":                                  	"GROUP_D",
  "public-health-community-medicine":               	"GROUP_D",
  "pulmonary-pathology":                            	"GROUP_D",
  "pulmonology-sleep-medicine-complex":             	"GROUP_D",
  "pulmonology-sleep-medicine-simple":              	"GROUP_D",
  "radiological-physics":                           	"GROUP_D",
  "radiology-imaging":                              	"GROUP_D",
  "reconstructive-surgery":                         	"GROUP_D",
  "reconstructive-urology":                          	"GROUP_D",
  "reflexology":                                    	"GROUP_D",
  "regenerative-medicine":                          	"GROUP_D",
  "registered-dietitian":                           	"GROUP_D",
  "rehabilitation-and-physical-medicine":           	"GROUP_D",
  "reproductive-health":                            	"GROUP_D",
  "school-psychology":                              	"GROUP_D",
  "shoulder-elbow":                                 	"GROUP_D",
  "shoulder-elbow-surgery":                         	"GROUP_D",
  "smoking-cessation-program":                      	"GROUP_D",
  "speech-pathology":	                                "GROUP_D",
  "speech-therapy":	                                  "GROUP_D",
  "spine":	                                          "GROUP_D",
  "spine-neck-back":	                                "GROUP_D",
  "surgical-pathology":	                              "GROUP_D",
  "telemedicine":	                                    "GROUP_D",
  "thoracic-medical-oncology":	                      "GROUP_D",
  "total-joint-hip-knee":	                            "GROUP_D",
  "toxicology":                                      	"GROUP_D",
  "transfusion-medicine":                           	"GROUP_D",
  "trauma":                                         	"GROUP_D",
  "trauma-surgery":                                 	"GROUP_D",
  "urologic-oncology":                              	"GROUP_D",
  "vaccination":	                                    "GROUP_D",
  "womens-health":	                                  "GROUP_D",
  "womens-health-nurse-practitioner":	                "GROUP_D",
  "x-ray":	                                          "GROUP_D",
  "x-rays-imaging":	                                  "GROUP_D",
  "nurse-visit":	                                    "GROUP_D",
  "member-fitness":	                                  "GROUP_D",
  "member-health":	                                  "GROUP_D",
  "stroke":                                           "GROUP_D",
  "tele-stroke":                                      "GROUP_F",
}

export const isServiceGroupB_C = servicePermalink => {
  return (
    serviceLineGroupMapping[servicePermalink] === 'GROUP_C' ||
    serviceLineGroupMapping[servicePermalink] === 'GROUP_B'
  );
};

export default serviceLineGroupMapping;
