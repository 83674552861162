import {
  handleResponse,
  handleError,
  getHeaderInfo,
  getEndpoint,
} from './apiUtils';

export function getHealthSystem() {
  return fetch(getEndpoint(`health-systems`), getHeaderInfo())
    .then(handleResponse)
    .catch(handleError);
}
