import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomTextField from '../CustomTextField';
import ZipcodeField from '../ZipcodeField';

const AddressFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const showCity = formContext.isFieldEnabled('city');
  const showState = formContext.isFieldEnabled('state');
  const showZip = formContext.isFieldEnabled('zip');
  const [t] = useTranslation();

  return (
    <>
      {formContext.isFieldEnabled('address') && (
        <div className="form-section_row">
          <Grid container>
            <Grid item sm={8} xs={12}>
              <CustomTextField
                fieldKey="address"
                fieldValue={formContext.form.address}
                validateFormHandler={e => formContext.handleSetFormState(e)}
                label={t('AddressField.addressfield')}
                shrink
                hasError={formContext.hasError('address')}
                errorMessage={formContext.getError('address')}
                required={formContext.isFieldRequired('address')}
              />
            </Grid>
          </Grid>
        </div>
      )}

      {formContext.isFieldEnabled('address2') && (
        <div className="form-section_row">
          <Grid container>
            <Grid item sm={8} xs={12}>
              <CustomTextField
                fieldKey="address2"
                fieldValue={formContext.form.address2}
                validateFormHandler={e => formContext.handleSetFormState(e)}
                label={t('AddressField.addressfield2')}
                shrink
                hasError={formContext.hasError('address2')}
                errorMessage={formContext.getError('address2')}
                required={formContext.isFieldRequired('address2')}
              />
            </Grid>
          </Grid>
        </div>
      )}

      {(showCity || showState || showZip) && (
        <div className="form-section_row">
          <Grid container justify="space-between">
            {showCity && (
              <Grid item sm={4} xs={12} className="form-item">
                <CustomTextField
                  fieldKey="city"
                  fieldValue={formContext.form.city}
                  validateFormHandler={e => formContext.handleSetFormState(e)}
                  shrink
                  label={t('AddressFields.city')}
                  hasError={formContext.hasError('city')}
                  errorMessage={formContext.getError('city')}
                  required={formContext.isFieldRequired('city')}
                />
              </Grid>
            )}

            {showState && (
              <Grid item sm={3} xs={12} className="form-item">
                <CustomTextField
                  fieldKey="state"
                  fieldValue={formContext.form.state}
                  validateFormHandler={e => formContext.handleSetFormState(e)}
                  label={t('AddressFields.state')}
                  hasError={formContext.hasError('state')}
                  errorMessage={formContext.getError('state')}
                  required={formContext.isFieldRequired('state')}
                  shrink
                />
              </Grid>
            )}

            {showZip && (
              <Grid item sm={4} xs={12}>
                <ZipcodeField
                  required={formContext.isFieldRequired('zip')}
                  hasError={formContext.hasError('zip')}
                  errorMessage={formContext.getError('zip')}
                />
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </>
  );
};

export default AddressFields;
