import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  FormControlLabel,
  makeStyles,
  RadioGroup,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import CustomRadio from 'components/form/CustomRadio';
import PickerDate from 'components/dialogs/DatePicker';

const useStyles = makeStyles(() => ({
  card: {
    paddingLeft: '10px',
    paddingRight: '10px',
    width: '100%',
  },
  root: {
    display: 'block',
    width: '100%',
  },
}));
const DialogTimePicker = ({ handleSelectionChange, timezone }) => {
  const classes = useStyles();
  const [value, setValue] = useState('2');
  const defaultDateValue = {
    end: moment()
      .add(1, 'days')
      .format('YYYY-MM-DD HH:mm:ss'),
    start: moment().format('YYYY-MM-DD HH:mm:ss'),
  };
  const [t] = useTranslation();

  const setChange = e => {
    let text = moment(e.start).format('MMM D, YYYY');
    let dateValue = e;

    if (value === '2') {
      text = moment(e.start).format('MMM D, YYYY');
      dateValue = e;
    }

    if (value === '3') {
      text = `${e.text}`;
      dateValue = e;
    }

    handleSelectionChange({
      text,
      value: dateValue,
    });
  };

  useEffect(() => {
    setChange(defaultDateValue);
  }, []);

  return (
    <>
      <RadioGroup
        row
        onChange={e => setValue(e.target.value)}
        defaultValue={value}
      >
        <FormControlLabel
          className={classes.root}
          value="2"
          control={<CustomRadio />}
          label={t('SchedulePicker.date')}
        />
        {value === '2' && (
          <Card className={classes.card}>
            <CardContent>
              <PickerDate
                handleChange={e => setChange(e)}
                timezone={timezone}
                timestamp={moment().tz(timezone).format}
              />
            </CardContent>
          </Card>
        )}
        {/* <FormControlLabel
          className={classes.root}
          value="3"
          control={<CustomRadio />}
          label="Time-Range"
        />
        {value === '3' && (
          <Card className={classes.card}>
            <CardContent>
              <TimePicker handleChange={e => setChange(e)} />
            </CardContent>
          </Card>
        )} */}
      </RadioGroup>
    </>
  );
};

DialogTimePicker.propTypes = {
  handleSelectionChange: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default DialogTimePicker;
