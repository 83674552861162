import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
} from '@material-ui/core';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomRadio from '../CustomRadio';

const StandingAssistanceFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const hasError = formContext.hasError('requires-standing-assistance');
  const [t] = useTranslation();
  return (
    <>
      {formContext.isFieldEnabled('requires-standing-assistance') && (
        <div className="form-section_row">
          <div className="label-question">
            {formContext.isFieldRequired('requires-standing-assistance') && (
              <span className="required">* </span>
            )}
            {t('StandingAssistanceFields.Question')}
          </div>

          <FormControl variant="outlined" fullWidth error={hasError}>
            <RadioGroup
              row
              onChange={e =>
                formContext.handleSetFormState({
                  requiresStandingAssistance: { value: e.target.value },
                })
              }
            >
              <FormControlLabel
                value="yes"
                control={<CustomRadio />}
                label={t('VisitFeedbackPage.yes')}
              />
              <FormControlLabel
                value="no"
                control={<CustomRadio />}
                label={t('VisitFeedbackPage.no')}
              />
            </RadioGroup>

            {hasError && (
              <FormHelperText error={hasError}>
                {formContext.getError('requires-standing-assistance')}
              </FormHelperText>
            )}
          </FormControl>
        </div>
      )}
    </>
  );
};

export default StandingAssistanceFields;
