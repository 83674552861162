import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import { BlackCheckbox } from '../form/FormElements';
import DialogMobile from './DialogMobile';

const CheckBoxContent = ({
  checkboxes,
  onChecked,
  title,
  selectedValues,
  type,
}) => {
  const [checkbox, setCheckbox] = useState([]);
  const [t] = useTranslation();

  useEffect(() => {
    setCheckbox(selectedValues);
  }, []);

  useEffect(() => {
    onChecked(checkbox);
  }, [checkbox]);

  const handleCheckBoxChange = e => {
    const { value, checked } = e.target;

    if (checked) {
      setCheckbox([...checkbox, value]);
    } else {
      setCheckbox(checkbox.filter(item => item !== value));
    }
  };

  return (
    <>
      {checkboxes.map(check => {
        let label = check;
        if (type === 'languages') {
          label = t(
            `languages:${check.charAt(0).toUpperCase() + check.slice(1)}`,
          );
        }
        if (type === 'gender' && check === 'Male') {
          label = t(`shared:Male`);
        }
        if (type === 'gender' && check === 'Female') {
          label = t(`shared:Female`);
        }

        return (
          <FormGroup key={check}>
            <FormControlLabel
              control={<BlackCheckbox inputProps={{ title }} />}
              value={check}
              checked={checkbox.includes(check)}
              onChange={evt => handleCheckBoxChange(evt)}
              label={label}
            />
          </FormGroup>
        );
      })}
    </>
  );
};

CheckBoxContent.defaultProps = {
  type: null,
};

CheckBoxContent.propTypes = {
  checkboxes: PropTypes.instanceOf(Array).isRequired,
  onChecked: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  selectedValues: PropTypes.instanceOf(Array).isRequired,
  type: PropTypes.string,
};

const DialogCheckbox = ({
  checkboxes,
  handleShowDialog,
  icon,
  showDialog,
  title,
  selectedValues,
  type,
}) => {
  const checkboxValue = {
    error: false,
    value: [],
    text: '',
  };

  const closeDialog = value => {
    if (value) {
      return handleShowDialog(checkboxValue);
    }

    return handleShowDialog();
  };

  return (
    <DialogMobile
      content={
        <CheckBoxContent
          checkboxes={checkboxes}
          onChecked={e => {
            checkboxValue.value = e;
          }}
          title={title}
          selectedValues={selectedValues}
          type={type}
        />
      }
      icon={icon}
      handleShowDialog={value => closeDialog(value)}
      showDialog={showDialog}
      title={title}
    />
  );
};

DialogCheckbox.defaultProps = {
  icon: null,
  type: null,
};

DialogCheckbox.propTypes = {
  handleShowDialog: PropTypes.func.isRequired,
  icon: PropTypes.element,
  showDialog: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  checkboxes: PropTypes.instanceOf(Array).isRequired,
  selectedValues: PropTypes.instanceOf(Array).isRequired,
  type: PropTypes.string,
};

export default DialogCheckbox;
