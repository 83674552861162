import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function searchParamsReducer(
  state = initialState.searchParams,
  action,
) {
  switch (action.type) {
    case types.FETCH_DOCTOR_SPECIALITY_SUCCESS:
      return { ...state, ...action.paramsData };
    case types.UPDATE_SEARCH_OPTIONS:
      return { ...state, selectedParams: action.searchOptions };
    case types.BACK_TO_LANDING_PAGE:
      return {
        ...state,
        selectedParams: { appointmentTypeId: '', searchType: '' },
      };
    case types.UPDATE_APPOINTMENT_TYPE:
      return {
        ...state,
        selectedParams: {
          ...state.selectedParams,
          appointmentTypeId: action.id,
        },
      };
    default:
      return state;
  }
}
