import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
} from '@material-ui/core';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomRadio from '../CustomRadio';
import CustomSelect from '../CustomSelect';

const TranslatorFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const hasError = formContext.hasError('requires-translator');
  const languageChoices = [
    { value: 'english', text: 'English' },
    { value: 'spanish', text: 'Spanish' },
    { value: 'french', text: 'French' },
    { value: 'japanese', text: 'Japanese' },
  ];
  const [t] = useTranslation();

  return (
    <>
      {formContext.isFieldEnabled('requires-translator') && (
        <div className="form-section_row">
          <div className="label-question">
            {formContext.isFieldRequired('requires-translator') && (
              <span className="required">* </span>
            )}
            {t('TranslatorFields.patientNeedTranslator')}
          </div>

          <FormControl variant="outlined" fullWidth error={hasError}>
            <RadioGroup
              row
              onChange={e =>
                formContext.handleSetFormState({
                  requiresTranslator: { value: e.target.value },
                })
              }
            >
              <FormControlLabel
                value="yes"
                control={<CustomRadio />}
                label={t('VisitFeedbackPage.yes')}
              />
              <FormControlLabel
                value="no"
                control={<CustomRadio />}
                label={t('VisitFeedbackPage.no')}
              />
            </RadioGroup>

            {hasError && (
              <FormHelperText error={hasError}>
                {formContext.getError('requires-translator')}
              </FormHelperText>
            )}
          </FormControl>

          {formContext.isFieldEnabled('translator-language') &&
            formContext.form.requiresTranslator.value === 'yes' && (
              <div className="form-section_row">
                <CustomSelect
                  fieldKey="translatorLanguage"
                  fieldValue={formContext.form.translatorLanguage}
                  label={t('TranslatorFields.translatorLanguage')}
                  required
                  items={languageChoices}
                  validateFormHandler={formContext.handleSetFormState}
                />
              </div>
            )}
        </div>
      )}
    </>
  );
};

export default TranslatorFields;
