import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { BrandingContext } from '../contexts/BrandingContext';

const ResultsTabItem = ({
  name,
  handleTabClick,
  isActiveTab,
  resultsCount,
  serviceResults,
  serviceId,
}) => {
  const { branding } = useContext(BrandingContext);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(resultsCount);
  }, [resultsCount]);

  const useStyles = makeStyles({
    activeTab: {
      background: `${branding.primaryColor} !important`,
      border: `1px solid ${branding.primaryColor} !important`,
      color: branding.secondaryTextColor,
      cursor: 'text',
      fontWeight: 'bold',
      '&:hover': {
        background: `${branding.primaryColor} !important`,
        color: `#fff !important`,
        cursor: 'text',
      },
    },
    tabItem: {
      background: 'transparent',
      border: 0,
      borderRadius: '8px 8px 0 0',
      cursor: 'pointer',
      marginBottom: '-1px',
      padding: '23px 30px 15px',
      whiteSpace: 'nowrap',
      '&:hover': {
        background: branding.primaryColor,
        color: branding.secondaryTextColor,
      },
    },
  });
  const classes = useStyles();

  return (
    <>
      <button
        className={`${classes.tabItem} ${isActiveTab && classes.activeTab}`}
        disabled={isActiveTab}
        onClick={handleTabClick}
        type="button"
        role="tab"
      >
        <>
          {Object.keys(serviceResults).includes(serviceId.toString())
            ? `${name} (${count || 0})`
            : `${name}`}
        </>
      </button>
    </>
  );
};

ResultsTabItem.defaultProps = {
  isActiveTab: true,
  resultsCount: null,
};

ResultsTabItem.propTypes = {
  serviceId: PropTypes.string.isRequired,
  handleTabClick: PropTypes.func.isRequired,
  isActiveTab: PropTypes.bool,
  name: PropTypes.string.isRequired,
  resultsCount: PropTypes.number,
  serviceResults: PropTypes.instanceOf(Object).isRequired,
};

export default ResultsTabItem;
