import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import camel from 'camelcase-keys';
import QueryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import { usePageTitle } from 'hooks/usePageTitle';
import { getEndpoint, getHeaderInfo } from '../../api/apiUtils';
import ScreeningQuestions from '../provider/ProviderAppointmentScreeningQuestions';
import BreadCrumbs from '../common/BreadCrumbs';
import { BrandingContext } from '../contexts/BrandingContext';
import AppointmentErrors from '../form/AppointmentErrors';
import RegistrationForm from '../form/RegistrationForm';
import Location from '../common/Location';
import Provider from '../provider/Provider';
import { objectCamelCase, checkProhibited } from '../../utils/helpers';
import { useQueryString } from '../../hooks/useQueryString';
import emergencyRoomImage from '../../assets/images/vocImages/default_emergency_room_md.jpeg';
import urgentCareImage from '../../assets/images/vocImages/default_urgent_care_md.jpeg';
import { ER_SERVICE_NAMES } from '../../config/searchConstants';
import 'components/schedule/ScheduleAppointment.scss';
import FormioForm from '../customTemplates/FormioForm';
import CouponCodeDialog from '../dialogs/CouponCodeDialog';
import PageMetaInfo from '../common/PageMetaInfo';
import { setProviderAvatar } from '../../helpers/selectProviderAvatar';

const ScheduleAppointment = ({
  appointmentTypeId,
  geocoding,
  match,
  healthSystem,
}) => {
  const history = useHistory();
  const { branding } = useContext(BrandingContext);
  const [appointmentType, setAppointmentType] = useState(null);
  const [errors, setErrors] = useState([]);
  const [included, setIncluded] = useState();
  const [provider, setProvider] = useState();
  const [loading, setLoading] = useState(true);
  const [showCouponCodeDialog, setShowCouponCodeDialog] = useState(false);
  const [schedule, setSchedule] = useState(null);
  const [scheduleLocation, setScheduleLocation] = useState(null);
  const [screeningQuestions, setScreeningQuestions] = useState({});
  const [appointmentTitle, setAppointmentTitle] = useState();
  const [appointmentTypeTitle, setAppointmentTypeTitle] = useState();
  const [appointmentTimeTitle, setAppointmentTimeTitle] = useState();
  const [paymentMessage, setPaymentMessage] = useState();
  const [showCustomTemplates, setShowCustomTemplates] = useState(false);
  const [serviceName, setServiceName] = useState('');
  const { urlParams } = useQueryString();
  const [screeningQuestionsAnswers, setScreeningQuestionsAnswers] = useState(
    [],
  );

  const { search } = useLocation();
  const params = QueryString.parse(search);
  const defaultAppointmentType = params.appointmentTypes || appointmentTypeId;
  const [
    isScreeningQuestionsShowing,
    setIsScreeningQuestionsShowing,
  ] = useState();
  const [inactive, setInactive] = useState(
    params.inactive === 'true' ? true : false,
  );

  const GA = useGoogleAnalytics();
  useEffect(() => {
    checkProhibited(history);
    return () => GA.set({ dimension1: undefined });
  }, []);

  useEffect(() => {
    if (healthSystem['custom-template']) {
      setShowCustomTemplates(healthSystem['custom-template'] === 'on');
    }
  }, [healthSystem]);

  const [t] = useTranslation();
  useEffect(() => {
    if (!schedule && loading) {
      (async () => {
        const response = await fetch(
          getEndpoint(`schedules/${match.params.scheduleId}`, {
            include:
              'location,provider,service,insurance-plans,venue-of-care,facility',
          }),
          getHeaderInfo(),
        );
        const results = await response.json();

        if (!isEmpty(results)) {
          const servicePermalink = results.included
            ? results.included.find(obj => obj.type === 'services')
            : null;
          setServiceName(servicePermalink.attributes.permalink.toUpperCase());
          const service = servicePermalink.attributes.permalink.toUpperCase();
          if (ER_SERVICE_NAMES.includes(service)) {
            setAppointmentTitle(t('ScheduleAppointment.bookAVisit'));
            setAppointmentTypeTitle(t('ScheduleAppointment.visitType'));
            setAppointmentTimeTitle(t('ScheduleAppointment.visitTime'));
            setPaymentMessage('visit');
          } else {
            setAppointmentTitle(t('ScheduleAppointment.bookAnAppointment'));
            setAppointmentTypeTitle(t('ScheduleAppointment.appointmentType'));
            setAppointmentTimeTitle(t('ScheduleAppointment.appointmentTime'));
            setPaymentMessage('appointment');
          }

          const currentAppointmentTypeId = isEmpty(
            results.data.attributes['appointment-types'],
          )
            ? ''
            : defaultAppointmentType;

          setSchedule(results.data || null);
          setAppointmentType(
            results.data.attributes['appointment-types'].find(
              type => type.id === +currentAppointmentTypeId,
            ) || {},
          );

          const provider = results.included
            ? results.included.find(obj => obj.type === 'providers')
            : null;

          if (provider && provider.attributes) {
            provider.attributes = objectCamelCase(provider.attributes);
          }

          setProvider(provider);

          const facility = results.included.find(i => i.type === 'facilities');
          GA.set({
            dimension1: `${facility.id}-${facility.attributes.name.replace(
              /[\W_]+/g,
              '_',
            )}`,
          });
        }

        if (results.included) {
          setIncluded(results.included);
          const loc = results.included.find(loc => loc.type === 'locations');

          loc.attributes = {
            ...camel(loc.attributes),
          };

          setScheduleLocation(loc);
        }

        // @todo this is breaking tripetto
        // setLoading(false);
      })();
    }

    if (schedule) {
      const breadcrumbLinks = [
        {
          text: ER_SERVICE_NAMES.includes(serviceName)
            ? t('ScheduleAppointment.bookVisit')
            : t('bookAppointment'),
        },
      ];

      if (schedule.attributes && schedule.attributes.name) {
        breadcrumbLinks.unshift({
          url: `/schedule/calendar/${schedule.id}`,
          text: schedule.attributes.name,
        });
      }
    }
  }, [schedule, loading, match, defaultAppointmentType, provider]);

  useEffect(() => {
    if (!isEmpty(screeningQuestions)) {
      setIsScreeningQuestionsShowing(true);
    }
  }, [screeningQuestions]);

  const getFacility = facilityId => {
    const facility = included?.find(
      obj => obj?.id === facilityId && obj?.type === 'facilities',
    );
    return facility?.attributes || null;
  };

  const facility =
    schedule && schedule?.relationships?.facility?.data
      ? getFacility(schedule?.relationships?.facility?.data?.id)
      : null;

  const getVenue = venueId => {
    if (included) {
      const venue = included.find(
        obj => obj.id === venueId && obj.type === 'venue-of-cares',
      );
      venue.attributes = objectCamelCase(venue.attributes);
      return venue || null;
    }

    return null;
  };

  const venue =
    schedule &&
      schedule.relationships &&
      schedule.relationships['venue-of-care'].data
      ? getVenue(schedule.relationships['venue-of-care'].data.id)
      : null;
  const imageForCard = () => {
    if (venue && venue.attributes) {
      switch (venue.attributes.venueType) {
        case 'urgent_care':
          return urgentCareImage;
        case 'emergency_room':
          return emergencyRoomImage;
        default:
          return null;
      }
    }
    return null;
  };

  const { zipcode } = geocoding;

  const isVirtualAppointment = () => appointmentType.virtual_appointment;

  const onScreeningQuestionsClose = showDialog => {
    setIsScreeningQuestionsShowing(showDialog);
  };
  usePageTitle(
    ER_SERVICE_NAMES.includes(serviceName)
      ? t('ScheduleAppointment.bookVisit')
      : t('bookAppointment'),
  );

  function buildPageTitle(serviceName, scheduleLocation, hs) {
    let pageTitle = '';
    if (serviceName !== null && scheduleLocation !== null && hs !== null) {
      const { facilityName } = scheduleLocation.attributes;
      let healthSystemName = '';
      if (hs.region !== null && hs.region !== undefined) {
        healthSystemName = hs.region.name;
      } else {
        healthSystemName = hs.name;
      }
      if (ER_SERVICE_NAMES.includes(serviceName)) {
        pageTitle =
          'Book a visit at ' + facilityName + ' - ' + healthSystemName;
      } else {
        pageTitle =
          'Book an appointment at ' + facilityName + ' - ' + healthSystemName;
      }
    }

    return pageTitle;
  }
  function buildPageDescription(serviceName, scheduleLocation, hs) {
    let pageDescription = '';
    if (serviceName !== null && scheduleLocation !== null && hs !== null) {
      const { facilityName } = scheduleLocation.attributes;
      let descriptions = '';
      if (
        hs.region !== null &&
        hs.region !== undefined &&
        params.type !== undefined &&
        params.type === 'region'
      ) {
        descriptions = hs.region.name + ' - ' + hs.name;
      } else if (params.type !== undefined && params.type === 'hs') {
        descriptions = hs.name;
      } else if (params.type !== undefined && params.type === 'facility') {
        descriptions = facilityName + ' - ' + hs.name;
      } else if (params.type !== undefined && params.type === 'location') {
        descriptions = scheduleLocation.attributes.name + ' - ' + hs.name;
      } else if (hs['ux-selection'] === 'Educational') {
        descriptions = hs.name;
      } else {
        descriptions = hs.name;
      }

      if (ER_SERVICE_NAMES.includes(serviceName)) {
        pageDescription = 'Book a visit online for ' + descriptions;
      } else {
        pageDescription = 'Book an appointment online for ' + descriptions;
      }
    }

    return pageDescription;
  }

  const pageTitle = buildPageTitle(serviceName, scheduleLocation, healthSystem);
  const pageDescription = buildPageDescription(
    serviceName,
    scheduleLocation,
    healthSystem,
  );

  return (
    <main className="ScheduleAppointment">
      {/* <PageMetaInfo title={pageTitle} description={pageDescription} /> */}
      {inactive && (
        <div className="private-mode">
          <p>Inactive schedule</p>
        </div>
      )}
      {appointmentType?.has_coupon_code && (
        <CouponCodeDialog
          healthSystemId={healthSystem.id}
          hasCouponCode={appointmentType.has_coupon_code}
          appointmentTypeId={appointmentType.id}
          setShowCouponCodeDialog={setShowCouponCodeDialog}
          couponCode={localStorage.getItem('code')}
        />
      )}
      {!showCouponCodeDialog && !isEmpty(screeningQuestions) && (
        <ScreeningQuestions
          screeningQuestions={screeningQuestions}
          answers={screeningQuestionsAnswers}
          updateAnswers={setScreeningQuestionsAnswers}
          onScreeningQuestionsClose={onScreeningQuestionsClose}
        />
      )}

      <div className="container">
        <BreadCrumbs />
      </div>

      {!isEmpty(errors) && <AppointmentErrors errors={errors} />}

      <div className="container pt-5 pb-5">
        {provider && scheduleLocation && (
          <div className="pb-4">
            <Provider
              provider={provider}
              locations={[scheduleLocation]}
              location={{
                ...scheduleLocation.attributes,
                ...camel(scheduleLocation.attributes),
                hideAddress:
                  scheduleLocation.attributes.hideAddress ||
                  isVirtualAppointment(),
              }}
              hideSchedule
              servicePermalink={
                !isNaN(params.service) === true
                  ? params.serviceName
                  : params.service
              }
              schedules={[schedule]}
              facility={facility}
            />
          </div>
        )}
        <Grid container>
          <Grid item xs={4} sm={3}>
            {!provider &&
              schedule &&
              schedule.attributes &&
              setProviderAvatar(
                provider,
                scheduleLocation?.attributes,
                schedule?.attributes?.service?.permalink,
                healthSystem,
                schedule?.attributes?.service?.name,
                facility
              )}
            { }
          </Grid>

          <Grid item xs={8} sm={9}>
            {!provider && scheduleLocation && scheduleLocation.attributes && (
              <Location
                location={{
                  ...scheduleLocation.attributes,
                  ...camel(scheduleLocation.attributes),
                  hideAddress:
                    scheduleLocation.attributes.hideAddress ||
                    isVirtualAppointment(),
                }}
              />
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={false} sm={3} />
          <Grid item xs={12} sm={9}>
            <h2 style={{ color: branding.primaryColor }}>
              {ER_SERVICE_NAMES.includes(serviceName)
                ? t('ScheduleAppointment.bookVisit')
                : t('bookAppointment')}
            </h2>

            {!showCouponCodeDialog && scheduleLocation && (
              <>
                {showCustomTemplates && (
                  <FormioForm
                    appointmentType={appointmentType}
                    handleErrors={e => setErrors(e)}
                    handleScreeningQuestions={setScreeningQuestions}
                    scheduleId={match.params.scheduleId}
                    scheduleLocation={scheduleLocation}
                    screeningQuestionsAnswers={screeningQuestionsAnswers}
                    appointmentTimeLabel={
                      ER_SERVICE_NAMES.includes(serviceName)
                        ? t('ScheduleAppointment.visitTime')
                        : t('RegistrationForm.appointmentTime')
                    }
                    appointmentTypeLabel={
                      ER_SERVICE_NAMES.includes(serviceName)
                        ? t('ScheduleAppointment.visitType')
                        : t('RegistrationForm.appointmentType')
                    }
                    appointmentCostLabel={t('RegistrationForm.appointmentCost')}
                    isScreeningQuestionsShowing={isScreeningQuestionsShowing}
                    service={serviceName}
                  />
                )}

                {!showCustomTemplates && (
                  <RegistrationForm
                    appointmentType={appointmentType}
                    appointmentTypeLabel={
                      ER_SERVICE_NAMES.includes(serviceName)
                        ? t('ScheduleAppointment.visitType')
                        : appointmentTypeTitle
                    }
                    appointmentTimeLabel={
                      ER_SERVICE_NAMES.includes(serviceName)
                        ? t('ScheduleAppointment.visitTime')
                        : appointmentTimeTitle
                    }
                    handleErrors={e => setErrors(e)}
                    handleScreeningQuestions={setScreeningQuestions}
                    scheduleId={match.params.scheduleId}
                    scheduleLocation={scheduleLocation}
                    screeningQuestionsAnswers={screeningQuestionsAnswers}
                    submitButtonLabel={
                      ER_SERVICE_NAMES.includes(serviceName)
                        ? t('RegistrationForm.confirm')
                        : t('RegistrationForm.bookIt')
                    }
                    zipcode={zipcode}
                    paymentMessage={paymentMessage}
                    isScreeningQuestionsShowing={isScreeningQuestionsShowing}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </main>
  );
};

ScheduleAppointment.propTypes = {
  appointmentTypeId: PropTypes.string.isRequired,
  geocoding: PropTypes.instanceOf(Object).isRequired,
  healthSystem: PropTypes.instanceOf(Object).isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
  appointmentTypeId: state.searchParams.selectedParams.appointmentTypeId,
  geocoding: state.geocoding,
  healthSystem: state.healthSystem,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScheduleAppointment);
