import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function serviceLinesReducer(
  state = initialState.serviceLines,
  action,
) {
  switch (action.type) {
    case types.BEGIN_FETCH_SERVICE_LINES:
      return state;
    case types.FETCH_SERVICE_LINES_SUCCESS:
      return {
        ...state,
        serviceLines: action.serviceLines || {},
        services: action.services || {},
      };
    case types.FETCH_DISCHARGE_SERVICE_LINES_SUCCESS:
      return {
        ...state,
        services: {
          ...state.services,
          ...action.services,
        },
        serviceLines: {
          ...state.serviceLines,
          ...action.serviceLines,
        },
      };
    case types.FETCH_DISCHARGE_CALLED:
      return {
        ...state,
        dischargedCalled: action.value,
      };
    default:
      return state;
  }
}
