import React from 'react';
import { ReactComponent as AestheticsImage } from 'assets/images/icons/aesthetics.svg';
import { ReactComponent as AllergyImage } from 'assets/images/icons/allergy.svg';
import { ReactComponent as AddictionMedicineImage } from 'assets/images/icons/addiction_medicine.svg';
import { ReactComponent as AdvancedLaparoscopyImage } from 'assets/images/icons/advanced_laparoscopy.svg';
import { ReactComponent as AestheticPlasticSurgeryImage } from 'assets/images/icons/aesthetic_plastic_surgery.svg';
import { ReactComponent as AIDSHIVMedsImage } from 'assets/images/icons/aIDS_hIVMeds.svg';
import { ReactComponent as AllergiesImmunologyImage } from 'assets/images/icons/allergies_immunology.svg';
import { ReactComponent as AlzheimersCareImage } from 'assets/images/icons/alzheimers_care.svg';
import { ReactComponent as AngerMgmtImage } from 'assets/images/icons/anger_mgmt.svg';
import { ReactComponent as AthleticTrainer } from 'assets/images/icons/athletic_trainer.svg';
import { ReactComponent as BackNeckSurgeryImage } from 'assets/images/icons/back_neck_surgery.svg';
import { ReactComponent as BehavorialHealthImage } from 'assets/images/icons/behavioral_health.svg';
import { ReactComponent as BreastSurgeryImage } from 'assets/images/icons/breast_surgery.svg';
import { ReactComponent as BoneDensityImage } from 'assets/images/icons/bone_density.svg';
import { ReactComponent as BariatricMedsImage } from 'assets/images/icons/bariatric_meds.svg';
import { ReactComponent as BioFeedbackTherapyImage } from 'assets/images/icons/bio_feedback_therapy.svg';
import { ReactComponent as BotoxImage } from 'assets/images/icons/botox.svg';
import { ReactComponent as BreastMedOncologyImage } from 'assets/images/icons/breast_med_oncology.svg';
import { ReactComponent as CardiologyImage } from 'assets/images/icons/cardiology.svg';
import { ReactComponent as CardiacElectrophysiologyImage } from 'assets/images/icons/cardiac_electrophysiology.svg';
import { ReactComponent as CardioVascularSurgeryImage } from 'assets/images/icons/cardio_surgery.svg';
import { ReactComponent as ColonoscopyImage } from 'assets/images/icons/colonoscopy.svg';
import { ReactComponent as CovidTestingImage } from 'assets/images/icons/covid_testing.svg';
import { ReactComponent as CTScanImage } from 'assets/images/icons/ct_scan.svg';
import { ReactComponent as ChildAdolescentPsychiatryImage } from 'assets/images/icons/child_adolescent_psychiatry.svg';
import { ReactComponent as ClinicalPharmacologyImage } from 'assets/images/icons/clinical_pharmacology.svg';
import { ReactComponent as CosmeticDermatologyImage } from 'assets/images/icons/cosmetic_dermatology.svg';
import { ReactComponent as CraniofacialSurgeryImage } from 'assets/images/icons/craniofacial_surgery.svg';
import { ReactComponent as CriticalCareSurgeryImage } from 'assets/images/icons/critical_care_surgery.svg';
import { ReactComponent as DepressionTherapyImage } from 'assets/images/icons/depression_therapy.svg';
import { ReactComponent as DiagnosticImage } from 'assets/images/icons/diagnostic_imaging.svg';
import { ReactComponent as EchocardiologyImage } from 'assets/images/icons/echocardiology.svg';
import { ReactComponent as ElectroDiagnosticMedicineImage } from 'assets/images/icons/electro_diagnostic_medicine.svg';
import { ReactComponent as ElectrolysisImage } from 'assets/images/icons/electrolysis.svg';
import { ReactComponent as EyeCareImage } from 'assets/images/icons/eyeCare.svg';
import { ReactComponent as ERImage } from 'assets/images/icons/er.svg';
import { ReactComponent as EarNoseThroatImage } from 'assets/images/icons/ent.svg';
import { ReactComponent as ExercisePhysiologyImage } from 'assets/images/icons/exercise_physiology.svg';
import { ReactComponent as EmployeeHealthImage } from 'assets/images/icons/employeeHealth.svg';
import { ReactComponent as FacialPlasticSurgeryImage } from 'assets/images/icons/facial_plastic_surgery.svg';
import { ReactComponent as FamilyMedicineImage } from 'assets/images/icons/fitness.svg';
import { ReactComponent as FamilyMedicinImage } from 'assets/images/icons/family_med.svg';
import { ReactComponent as MusculoskeletalMedicinImage } from 'assets/images/icons/musculoskeletal_medicine.svg';
import { ReactComponent as FitnessImage } from 'assets/images/icons/fitness.svg';
import { ReactComponent as FindADocImage } from 'assets/images/icons/find_a_doc.svg';
import { ReactComponent as FluShotImage } from 'assets/images/icons/flu_shot.svg';
import { ReactComponent as FootAnkleSurgeryImage } from 'assets/images/icons/foot_ankle_surgery.svg';
import { ReactComponent as FootAnkleImage } from 'assets/images/icons/foot_ankle.svg';
import { ReactComponent as GerontologyImage } from 'assets/images/icons/gerentology.svg';
import { ReactComponent as GeneticsImage } from 'assets/images/icons/genetics.svg';
import { ReactComponent as GeneticOncologyImage } from 'assets/images/icons/genetic_oncology.svg';
import { ReactComponent as GeriatricMedsImage } from 'assets/images/icons/geriatric_meds.svg';
import { ReactComponent as GastroEnterologyImage } from 'assets/images/icons/gastroenterology.svg';
import { ReactComponent as GastroIntestinalMedicalOncologyImage } from 'assets/images/icons/gastro_intestinal_medical-oncology.svg';
import { ReactComponent as GenitoUrinaryMedicalOncologyImage } from 'assets/images/icons/genito_urinary_medical_oncology.svg';
import { ReactComponent as HandWristSurgeryImage } from 'assets/images/icons/hand_wrist_surgery.svg';
import { ReactComponent as HandWristImage } from 'assets/images/icons/hand_wrist.svg';
import { ReactComponent as HeadNeckSurgeryImage } from 'assets/images/icons/head_neck_plastic_surgery.svg';
import { ReactComponent as HeadNeckSurgicalOncologyImage } from 'assets/images/icons/head_neck_oncology.svg';
import { ReactComponent as HeadNeckSurgicalMedOncologyImage } from 'assets/images/icons/head_neck_med_oncology.svg';
import { ReactComponent as HealthSpaServicesImage } from 'assets/images/icons/health_spa_services.svg';
import { ReactComponent as HematologyOncologyImage } from 'assets/images/icons/hematology_oncology.svg';
import { ReactComponent as HepatobiliaryImage } from 'assets/images/icons/Hepatobiliary.svg';
import { ReactComponent as HeadLiceTreatmentImage } from 'assets/images/icons/head_lice_treatment.svg';
import { ReactComponent as HepatologyImage } from 'assets/images/icons/hepatology.svg';
import { ReactComponent as HerbalMedicineImage } from 'assets/images/icons/herbal_medicine.svg';
import { ReactComponent as HolisticMedicineImage } from 'assets/images/icons/holistic_medicine.svg';
import { ReactComponent as HomeopathyImage } from 'assets/images/icons/homeopathy.svg';
import { ReactComponent as HospicePalliativeMedicineImage } from 'assets/images/icons/hospice_palliative_medicine.svg';
import { ReactComponent as HypnotherapyImage } from 'assets/images/icons/hypnotherapy.svg';
import { ReactComponent as ImmediateCareImage } from 'assets/images/icons/immediate_care.svg';
import { ReactComponent as InterventionalNeuroradiologyImage } from 'assets/images/icons/interventional_neuroradiology.svg';
import { ReactComponent as IntestineTransplantImage } from 'assets/images/icons/intestine_transplant.svg';
import { ReactComponent as InjuryWoundCareImage } from 'assets/images/icons/injury_wound_care.svg';
import { ReactComponent as InfertilityReproductiveDisordersImage } from 'assets/images/icons/infertility_reproductive_disorders.svg';
import { ReactComponent as InfectiousDiseaseMedicineImage } from 'assets/images/icons/infectious_disease_medicine.svg';
import { ReactComponent as JointReplacementImage } from 'assets/images/icons/joint_replacement.svg';
import { ReactComponent as KidneyPancreasTransplantationImage } from 'assets/images/icons/kidney_pancreas_transplantation.svg';
import { ReactComponent as LactationConsultImage } from 'assets/images/icons/lactation_consult.svg';
import { ReactComponent as LaserVisionSurgeryImage } from 'assets/images/icons/laser_vision_surgery.svg';
import { ReactComponent as LiverSurgeryImage } from 'assets/images/icons/liver_surgery.svg';
import { ReactComponent as LabServicesImage } from 'assets/images/icons/laboratory_services.svg';
import { ReactComponent as LifeLongHealthImage } from 'assets/images/icons/life_long_health.svg';
import { ReactComponent as MammographyImage } from 'assets/images/icons/mammography.svg';
import { ReactComponent as MassageImage } from 'assets/images/icons/massage.svg';
import { ReactComponent as MedicalSpecialtiesImage } from 'assets/images/icons/medical_specialties.svg';
import { ReactComponent as MedicalTreatmentImage } from 'assets/images/icons/medical_treatments.svg';
import { ReactComponent as MaleEnhancementImage } from 'assets/images/icons/male_enhancement.svg';
import { ReactComponent as MaternalFetalMedicineImage } from 'assets/images/icons/maternal_fetal_medicine.svg';
import { ReactComponent as MedicalOncologyImage } from 'assets/images/icons/medical_oncology.svg';
import { ReactComponent as MensHealthClinicImage } from 'assets/images/icons/mens_Health_clinic.svg';
import { ReactComponent as MedicalBehavioralHealthImage } from 'assets/images/icons/mental_behavioral_health.svg';
import { ReactComponent as MentalHealthServiceImage } from 'assets/images/icons/mental_health_service.svg';
import { ReactComponent as MusicTherapyImage } from 'assets/images/icons/music_therapy.svg';
import { ReactComponent as NonSurgicalWeightLossImage } from 'assets/images/icons/non_surgical_weight_loss.svg';
import { ReactComponent as NuclearMidicineImage } from 'assets/images/icons/nuclear_medicine.svg';
import { ReactComponent as NutritionServicesImage } from 'assets/images/icons/nutrition_services.svg';
import { ReactComponent as NeonatalPerinatalMedicineImage } from 'assets/images/icons/neonatal_perinatal_medicine.svg';
import { ReactComponent as NeonatalSurgeryImage } from 'assets/images/icons/neonatal_surgery.svg';
import { ReactComponent as NeuroPsychiatryImage } from 'assets/images/icons/neuro_psychiatry.svg';
import { ReactComponent as NeuroSurgeryImage } from 'assets/images/icons/neuro_surgery.svg';
import { ReactComponent as NutritionWeightlossImage } from 'assets/images/icons/nutrition_weightloss.svg';
import { ReactComponent as NeurologyImage } from 'assets/images/icons/neurology.svg';
import { ReactComponent as ObGynImage } from 'assets/images/icons/obgyn.svg';
import { ReactComponent as OccupationalHealthImage } from 'assets/images/icons/occupational_health.svg';
import { ReactComponent as OccupationalTherapyImage } from 'assets/images/icons/occupational_therapy.svg';
import { ReactComponent as OccupationalMedicineImage } from 'assets/images/icons/occupational_medicine.svg';
import { ReactComponent as OcularOncologyImage } from 'assets/images/icons/ocular_oncology.svg';
import { ReactComponent as OphthalmicPathologyImage } from 'assets/images/icons/ophthalmic_pathology.svg';
import { ReactComponent as OpthamalicSurgeryImage } from 'assets/images/icons/opthamalic_surgery.svg';
import { ReactComponent as OralMaxiollSurgeryImage } from 'assets/images/icons/oral_maxioll_surgery.svg';
import { ReactComponent as OrthodonticsImage } from 'assets/images/icons/orthodontics.svg';
import { ReactComponent as OrthopedicTraumaImage } from 'assets/images/icons/orthopedic_trauma.svg';
import { ReactComponent as OrthopedicImage } from 'assets/images/icons/orthopedics.svg';
import { ReactComponent as OrthopedicSportsImage } from 'assets/images/icons/orthopedic_sports_medicine.svg';
import { ReactComponent as OsteopathicMedsImage } from 'assets/images/icons/osteopathic_meds.svg';
import { ReactComponent as OrthoOnDemandImage } from 'assets/images/icons/ortho_on_demand.svg';
import { ReactComponent as PainManagementImage } from 'assets/images/icons/pain_management.svg';
import { ReactComponent as PediatricImage } from 'assets/images/icons/pediatrics.svg';
import { ReactComponent as PelvicMedicineImage } from 'assets/images/icons/pelvic_medicine.svg';
import { ReactComponent as PediatricAllergyImmunologyImage } from 'assets/images/icons/ped_allergy_immunology.svg';
import { ReactComponent as PediatricMentalHealthImage } from 'assets/images/icons/pediatric_mental_health.svg';
import { ReactComponent as PediatriCriticalCareMedicineImage } from 'assets/images/icons/ped_critical_care_medicine.svg';
import { ReactComponent as PediatricOrthopedicSurgeryImage } from 'assets/images/icons/pediatric_orthopedic_surgery.svg';
import { ReactComponent as PediatricMedicalSpecialtiesImage } from 'assets/images/icons/pediatric_medical_specialties.svg';
import { ReactComponent as PediatricInternalMedImage } from 'assets/images/icons/ped_internal_med.svg';
import { ReactComponent as PediatricSpecialtyCareImage } from 'assets/images/icons/pediatric_specialty_care.svg';
import { ReactComponent as PediatricAdolescentGynecologyImage } from 'assets/images/icons/ped_obgyn.svg';
import { ReactComponent as PediatricEmergencyRoomImage } from 'assets/images/icons/pediatric_emergency_room.svg';
import { ReactComponent as PediatricCardioVascularDiseaseImage } from 'assets/images/icons/ped_cardio_disease.svg';
import { ReactComponent as PediatricCardioVascularSurgeryImage } from 'assets/images/icons/pediatric_surgery.svg';
import { ReactComponent as PediatricInfectiousDiseaseMedicineImage } from 'assets/images/icons/pediatric_infectious_disease.svg';
import { ReactComponent as PlasticReconstructiveEnergyImage } from 'assets/images/icons/plastic_reconstructive_surgery.svg';
import { ReactComponent as PulmonaryPathologyImage } from 'assets/images/icons/pulmonary_pathology.svg';
import { ReactComponent as PediatricMedicalToxicologyImage } from 'assets/images/icons/pediatric_med_toxicology.svg';
import { ReactComponent as PulmologySleepMedicineSimpleImage } from 'assets/images/icons/pulmonology_sleep_medicine_simple.svg';
import { ReactComponent as PulmologySleepMedicineComplexImage } from 'assets/images/icons/pulmonology_sleep_medicine_complex.svg';
import { ReactComponent as PediatricNeuroOncologyImage } from 'assets/images/icons/pediatric_neuro_oncology.svg';
import { ReactComponent as PediatricOncologyImage } from 'assets/images/icons/pediatric_oncology.svg';
import { ReactComponent as PediatricPlasticSurgeryImage } from 'assets/images/icons/pediatric_plastic_surgery.svg';
import { ReactComponent as PediatricPsychologyImage } from 'assets/images/icons/pediatric_psychology.svg';
import { ReactComponent as PediatricSportsMedImage } from 'assets/images/icons/pediatric_sports_med.svg';
import { ReactComponent as PediatricSurgicalOncologyImage } from 'assets/images/icons/pediatric_surgical_oncology.svg';
import { ReactComponent as PediatricTraumaSurgeryImage } from 'assets/images/icons/pediatric_trauma_surgery.svg';
import { ReactComponent as PediatricAllergistImage } from 'assets/images/icons/pediatric_allergist.svg';
import { ReactComponent as PediatricChiroImage } from 'assets/images/icons/pediatric_chiro.svg';
import { ReactComponent as PediatricHematologyImage } from 'assets/images/icons/pediatric_hematology.svg';
import { ReactComponent as PediatricHematologyOncologyImage } from 'assets/images/icons/pediatric-hematology-oncology.svg';
import { ReactComponent as PhlebologyImage } from 'assets/images/icons/phlebology.svg';
import { ReactComponent as PhysiatryImage } from 'assets/images/icons/physiatry.svg';
import { ReactComponent as ReflexologyImage } from 'assets/images/icons/reflexology.svg';
import { ReactComponent as RehabilitationImage } from 'assets/images/icons/rehabilitation_physical_medicine.svg';
import { ReactComponent as ReconstructiveSurgeryImage } from 'assets/images/icons/plastic_reconstructive_surgery.svg';
import { ReactComponent as PsychoanalysisImage } from 'assets/images/icons/psychoanalysis.svg';
import { ReactComponent as PsychotherapyImage } from 'assets/images/icons/psychotherapy.svg';
import { ReactComponent as NurseVisit } from 'assets/images/icons/nurse_visit.svg';
import { ReactComponent as MemberFitness } from 'assets/images/icons/member_fitness.svg';
import { ReactComponent as MemberHealth } from 'assets/images/icons/member_health.svg';

import { ReactComponent as PhysicalMedicineRehabilitationImage } from 'assets/images/icons/physical_medicine_rehabilitation.svg';
import { ReactComponent as PhysicalTherapyImage } from 'assets/images/icons/physical_therapy.svg';
import { ReactComponent as PodiatryImage } from 'assets/images/icons/podiatry.svg';
import { ReactComponent as PreoperativeCareImage } from 'assets/images/icons/preoperative_care.svg';
import { ReactComponent as PrenatalUltrasoundImagingImage } from 'assets/images/icons/ped_ultrasound.svg';
import { ReactComponent as PulmonologyImage } from 'assets/images/icons/pulmonology.svg';
import { ReactComponent as RadiologyImage } from 'assets/images/icons/radiology.svg';
import { ReactComponent as RadiologyImagingImage } from 'assets/images/icons/radiology_imaging.svg';

import { ReactComponent as RecoverMedicineImage } from 'assets/images/icons/recover_medicine.svg';
import { ReactComponent as ReproductiveHealthImage } from 'assets/images/icons/reproductive_health.svg';
import { ReactComponent as SpeechTherapyImage } from 'assets/images/icons/speech_therapy.svg';
import { ReactComponent as SpeechPathologyImage } from 'assets/images/icons/speech_pathology.svg';
import { ReactComponent as SportsMedicineImage } from 'assets/images/icons/sports_medicine.svg';
import { ReactComponent as SmokingCessationProgramImage } from 'assets/images/icons/smoking_cessation.svg';
import { ReactComponent as ShoulderSurgeryImage } from 'assets/images/icons/shoulder_surgery.svg';
import { ReactComponent as ShoulderElbowSurgeryImage } from 'assets/images/icons/shoulder_elbow_surgery.svg';
import { ReactComponent as ShoulderElbowImage } from 'assets/images/icons/shoulder_elbow.svg';
import { ReactComponent as SpineNeckBackImage } from 'assets/images/icons/spine_neck_back.svg';
import { ReactComponent as SpineImage } from 'assets/images/icons/spine.svg';
import { ReactComponent as SpineSurgeryImage } from 'assets/images/icons/spine_surgery.svg';
import { ReactComponent as StrokeImage } from 'assets/images/icons/stroke.svg';
import { ReactComponent as SurgicalPathologyImage } from 'assets/images/icons/surgical_pathology.svg';
import { ReactComponent as SurgeryImage } from 'assets/images/icons/surgery.svg';
import { ReactComponent as TelestrokeImage } from 'assets/images/icons/telestroke.svg';
import { ReactComponent as ThoracicMedicalOncologyImage } from 'assets/images/icons/thoracic_med_oncology.svg';
import { ReactComponent as ToxicologyImage } from 'assets/images/icons/toxicology.svg';
import { ReactComponent as TransfusionMedicineImage } from 'assets/images/icons/transfusion_meds.svg';
import { ReactComponent as TraumaSurgeryImage } from 'assets/images/icons/trauma_surgery.svg';
import { ReactComponent as TraumaImage } from 'assets/images/icons/trauma.svg';
import { ReactComponent as TelehealthImage } from 'assets/images/icons/telehealth.svg';
import { ReactComponent as TotalJointImage } from 'assets/images/icons/total_joint.svg';
import { ReactComponent as UltrasoundImage } from 'assets/images/icons/ultrasound.svg';
import { ReactComponent as UrgentCareImage } from 'assets/images/icons/urgent_care.svg';
import { ReactComponent as UrologyImage } from 'assets/images/icons/urology.svg';
import { ReactComponent as VascularCardiologyImage } from 'assets/images/icons/vascular_cardiology.svg';
import { ReactComponent as VaccinationImage } from 'assets/images/icons/vaccination.svg';
import { ReactComponent as WalkInImage } from 'assets/images/icons/walk_in.svg';
import { ReactComponent as XRayImage } from 'assets/images/icons/x_ray.svg';
import { ReactComponent as XRaysImage } from 'assets/images/icons/x_rays_imaging.svg';
import { ReactComponent as XRaysPedImage } from 'assets/images/icons/ped_xray.svg';
import { ReactComponent as Endocrinology } from 'assets/images/icons/Endocrinology.svg';
import { ReactComponent as WomensHealth } from 'assets/images/icons/Womens_Health.svg';
import { ReactComponent as NoImage } from 'assets/images/icons/no_image.svg';

export const serviceLineIcons = [
  { icon: <AestheticsImage />, permalink: 'aesthetics' },
  { icon: <AllergyImage />, permalink: 'allergy' },
  { icon: <AddictionMedicineImage />, permalink: 'addiction_medicine' },
  { icon: <AdvancedLaparoscopyImage />, permalink: 'advanced-laparoscopy' },
  {
    icon: <AestheticPlasticSurgeryImage />,
    permalink: 'aesthetic-plastic-surgery',
  },
  { icon: <AIDSHIVMedsImage />, permalink: 'aids-hiv-medicine' },
  { icon: <AllergiesImmunologyImage />, permalink: 'allergies-immunology' },
  { icon: <AlzheimersCareImage />, permalink: 'alzheimers-care' },
  { icon: <AngerMgmtImage />, permalink: 'anger-management' },
  { icon: <AthleticTrainer />, permalink: 'athletic-trainer' },
  { icon: <BackNeckSurgeryImage />, permalink: 'back-neck-surgery' },
  { icon: <BehavorialHealthImage />, permalink: 'behavioral-health' },
  { icon: <BoneDensityImage />, permalink: 'bone-density' },
  { icon: <BreastSurgeryImage />, permalink: 'breast-imaging' },
  { icon: <BreastSurgeryImage />, permalink: 'breast-surgery' },
  { icon: <BariatricMedsImage />, permalink: 'bariatric-medicine' },
  { icon: <BioFeedbackTherapyImage />, permalink: 'biofeedback-therapy' },
  { icon: <BotoxImage />, permalink: 'botox' },
  { icon: <BreastMedOncologyImage />, permalink: 'breast-medical-oncology' },
  { icon: <CardiologyImage />, permalink: 'cardiology' },
  {
    icon: <CardiacElectrophysiologyImage />,
    permalink: 'cardiac-electrophysiology',
  },
  { icon: <CardioVascularSurgeryImage />, permalink: 'cardiovascular-surgery' },
  { icon: <ColonoscopyImage />, permalink: 'colonoscopy' },
  { icon: <CovidTestingImage />, permalink: 'covid-testing' },
  { icon: <CTScanImage />, permalink: 'ct-scan' },
  {
    icon: <ChildAdolescentPsychiatryImage />,
    permalink: 'child-adolescent-psychiatry',
  },
  {
    icon: <ChildAdolescentPsychiatryImage />,
    permalink: 'child-and-adolescent-psychiatry',
  },
  { icon: <ClinicalPharmacologyImage />, permalink: 'clinical-pharmacology' },
  { icon: <CosmeticDermatologyImage />, permalink: 'cosmetic-dermatology' },
  { icon: <CraniofacialSurgeryImage />, permalink: 'craniofacial-surgery' },
  { icon: <CriticalCareSurgeryImage />, permalink: 'critical-care-surgery' },
  { icon: <DepressionTherapyImage />, permalink: 'depression-therapy' },
  { icon: <DiagnosticImage />, permalink: 'diagnostic-imaging' },
  { icon: <ERImage />, permalink: 'emergency-room' },
  { icon: <ERImage />, permalink: 'emergency-department' },
  { icon: <ERImage />, permalink: 'er-uc' },
  { icon: <EchocardiologyImage />, permalink: 'echocardiology' },
  {
    icon: <ElectroDiagnosticMedicineImage />,
    permalink: 'electrodiagnostic-medicine',
  },
  { icon: <ElectrolysisImage />, permalink: 'electrolysis' },
  { icon: <EyeCareImage />, permalink: 'eye-care' },
  { icon: <EarNoseThroatImage />, permalink: 'ear-nose-throat' },
  { icon: <EmployeeHealthImage />, permalink: 'employee-health' },
  { icon: <PelvicMedicineImage />, permalink: 'pelvic-medicine' },
  {
    icon: <PediatricAllergyImmunologyImage />,
    permalink: 'pediatric-allergy-immunology',
  },
  {
    icon: <PediatricAllergyImmunologyImage />,
    permalink: 'pediatric-allergy-and-immunology',
  },
  { icon: <ExercisePhysiologyImage />, permalink: 'exercise-physiology' },
  { icon: <FamilyMedicineImage />, permalink: 'family_medicine' },
  { icon: <FamilyMedicinImage />, permalink: 'family-medicine' },
  {
    icon: <MusculoskeletalMedicinImage />,
    permalink: 'musculoskeletal-medicine',
  },
  { icon: <FitnessImage />, permalink: 'fitness' },
  { icon: <FindADocImage />, permalink: 'find-a-doctor' },
  { icon: <FluShotImage />, permalink: 'flu-shot' },
  { icon: <FluShotImage />, permalink: 'vaccine' },
  { icon: <FluShotImage />, permalink: 'vaccination' },
  { icon: <FacialPlasticSurgeryImage />, permalink: 'facial-plastic-surgery' },
  { icon: <FootAnkleSurgeryImage />, permalink: 'foot-ankle-surgery' },
  { icon: <FootAnkleImage />, permalink: 'foot-ankle' },
  { icon: <GerontologyImage />, permalink: 'gerontology' },
  { icon: <GeneticsImage />, permalink: 'genetics' },
  { icon: <GeneticOncologyImage />, permalink: 'genetic-oncology' },
  { icon: <GeriatricMedsImage />, permalink: 'geriatric-medicine' },
  { icon: <GastroEnterologyImage />, permalink: 'gastroenterology' },
  {
    icon: <GastroIntestinalMedicalOncologyImage />,
    permalink: 'gastrointestinal-medical-oncology',
  },
  {
    icon: <GenitoUrinaryMedicalOncologyImage />,
    permalink: 'genitourinary-medical-oncology',
  },
  { icon: <HandWristSurgeryImage />, permalink: 'hand-wrist-surgery' },
  { icon: <HandWristImage />, permalink: 'hand-wrist' },
  {
    icon: <HeadNeckSurgeryImage />,
    permalink: 'head-and-neck-plastic-surgery',
  },
  {
    icon: <HeadNeckSurgicalOncologyImage />,
    permalink: 'head-and-neck-oncology',
  },
  {
    icon: <HeadNeckSurgicalMedOncologyImage />,
    permalink: 'head-and-neck-medical-oncology',
  },
  { icon: <HealthSpaServicesImage />, permalink: 'health-spa-services' },
  { icon: <HematologyOncologyImage />, permalink: 'hematology-oncology' },
  {
    icon: <HepatobiliaryImage />,
    permalink: 'hepatobiliary-pancreatic-surgery',
  },
  { icon: <HeadLiceTreatmentImage />, permalink: 'head-lice-treatment' },
  { icon: <HepatologyImage />, permalink: 'hepatology' },
  { icon: <HerbalMedicineImage />, permalink: 'herbal-medicine' },
  { icon: <HolisticMedicineImage />, permalink: 'holistic-medicine' },
  { icon: <HomeopathyImage />, permalink: 'homeopathy' },
  {
    icon: <HospicePalliativeMedicineImage />,
    permalink: 'hospice-and-palliative-medicine',
  },
  { icon: <HypnotherapyImage />, permalink: 'hypnotherapy' },
  { icon: <ImmediateCareImage />, permalink: 'immediate-care' },
  {
    icon: <IntestineTransplantImage />,
    permalink: 'intestinal-transplantation',
  },
  { icon: <InjuryWoundCareImage />, permalink: 'injury-and-wound-care' },
  {
    icon: <InfertilityReproductiveDisordersImage />,
    permalink: 'infertility-and-reproductive-disorders',
  },
  {
    icon: <InfectiousDiseaseMedicineImage />,
    permalink: 'infectious-disease-medicine',
  },
  {
    icon: <InterventionalNeuroradiologyImage />,
    permalink: 'interventional-neuroradiology',
  },
  { icon: <JointReplacementImage />, permalink: 'joint-replacement' },
  {
    icon: <KidneyPancreasTransplantationImage />,
    permalink: 'kidney-pancreas-transplantation',
  },
  { icon: <LaserVisionSurgeryImage />, permalink: 'laser-vision-surgery' },
  { icon: <LiverSurgeryImage />, permalink: 'liver-surgery' },
  { icon: <LabServicesImage />, permalink: 'laboratory-services' },
  { icon: <LifeLongHealthImage />, permalink: 'life-long-health' },
  { icon: <LactationConsultImage />, permalink: 'lactation-consult' },
  { icon: <MammographyImage />, permalink: 'mammography' },
  { icon: <MassageImage />, permalink: 'massage-therapy' },
  { icon: <MedicalSpecialtiesImage />, permalink: 'medical-specialties' },
  { icon: <MedicalTreatmentImage />, permalink: 'medical-treatments' },
  { icon: <MaleEnhancementImage />, permalink: 'male-enhancement' },
  {
    icon: <MaternalFetalMedicineImage />,
    permalink: 'maternal-and-fetal-medicine',
  },
  { icon: <MedicalOncologyImage />, permalink: 'medical-oncology' },
  { icon: <MensHealthClinicImage />, permalink: 'mens-health-clinic' },
  {
    icon: <MedicalBehavioralHealthImage />,
    permalink: 'mental-behavioral-health',
  },
  { icon: <MentalHealthServiceImage />, permalink: 'mental-health-service' },
  { icon: <MusicTherapyImage />, permalink: 'music-therapy' },
  { icon: <NeurologyImage />, permalink: 'neurology' },
  { icon: <NutritionWeightlossImage />, permalink: 'nutrition-weight-loss' },
  { icon: <NeuroSurgeryImage />, permalink: 'neurosurgery' },
  { icon: <NeuroPsychiatryImage />, permalink: 'neuropsychiatry' },
  { icon: <NeonatalSurgeryImage />, permalink: 'neonatal-surgery' },
  {
    icon: <NeonatalPerinatalMedicineImage />,
    permalink: 'neonatal-perinatal-medicine',
  },
  { icon: <OccupationalMedicineImage />, permalink: 'occupational-medicine' },
  {
    icon: <OccupationalHealthImage />,
    permalink: 'occupational-health',
  },
  { icon: <OcularOncologyImage />, permalink: 'ocular-oncology' },
  {
    icon: <NonSurgicalWeightLossImage />,
    permalink: 'non-surgical-weight-loss',
  },
  { icon: <NuclearMidicineImage />, permalink: 'nuclear-medicine' },
  { icon: <NutritionServicesImage />, permalink: 'nutrition-services' },
  { icon: <OphthalmicPathologyImage />, permalink: 'ophthalmic-pathology' },
  { icon: <OpthamalicSurgeryImage />, permalink: 'ophthalmic-surgery' },
  {
    icon: <OralMaxiollSurgeryImage />,
    permalink: 'oral-and-maxillofacial-surgery',
  },
  {
    icon: <OrthodonticsImage />,
    permalink: 'orthodontics-and-dentofacial-orthopedics',
  },
  { icon: <OrthopedicTraumaImage />, permalink: 'orthopedic-trauma' },
  { icon: <OrthopedicImage />, permalink: 'orthopedics' },
  { icon: <OrthopedicSportsImage />, permalink: 'orthopaedic-sports-medicine' },
  { icon: <OsteopathicMedsImage />, permalink: 'osteopathic-medicine' },
  { icon: <OrthoOnDemandImage />, permalink: 'ortho-on-demand' },
  {
    icon: <ObGynImage />,
    permalink: 'obgyn',
  },
  {
    icon: <ObGynImage />,
    permalink: 'obstetrics-gynecology',
  },
  {
    icon: <OccupationalTherapyImage />,
    permalink: 'occupational-therapy',
  },
  { icon: <PainManagementImage />, permalink: 'pain-management' },
  { icon: <PediatricImage />, permalink: 'pediatrics' },
  {
    icon: <PediatricMentalHealthImage />,
    permalink: 'pediatric-mental-health',
  },
  {
    icon: <PrenatalUltrasoundImagingImage />,
    permalink: 'prenatal-ultrasound-imaging',
  },
  {
    icon: <PediatricOrthopedicSurgeryImage />,
    permalink: 'pediatric-orthopedics',
  },
  {
    icon: <PediatricSpecialtyCareImage />,
    permalink: 'pediatric-specialty-care',
  },
  {
    icon: <PediatricMedicalSpecialtiesImage />,
    permalink: 'pediatric-medical-specialties',
  },
  {
    icon: <PlasticReconstructiveEnergyImage />,
    permalink: 'plastic-and-reconstructive-surgery',
  },
  {
    icon: <PlasticReconstructiveEnergyImage />,
    permalink: 'plastic-reconstructive-surgery',
  },
  { icon: <PulmonaryPathologyImage />, permalink: 'pulmonary-pathology' },
  {
    icon: <PediatricInternalMedImage />,
    permalink: 'pediatric-internal-medicine',
  },
  {
    icon: <PediatriCriticalCareMedicineImage />,
    permalink: 'pediatric-critical-care-medicine',
  },
  { icon: <ReflexologyImage />, permalink: 'reflexology' },
  {
    icon: <RehabilitationImage />,
    permalink: 'rehabilitation-and-physical-medicine',
  },
  {
    icon: <PediatricAdolescentGynecologyImage />,
    permalink: 'pediatric-adolescent-gynecology',
  },
  {
    icon: <PediatricEmergencyRoomImage />,
    permalink: 'pediatric-emergency-room',
  },
  {
    icon: <PediatricEmergencyRoomImage />,
    permalink: 'pediatric-emergency',
  },
  {
    icon: <PediatricCardioVascularDiseaseImage />,
    permalink: 'pediatric-cardiovascular-disease',
  },
  {
    icon: <PediatricCardioVascularSurgeryImage />,
    permalink: 'pediatric-cardiovascular-surgery',
  },
  {
    icon: <PediatricInfectiousDiseaseMedicineImage />,
    permalink: 'pediatric-infectious-disease-medicine',
  },
  {
    icon: <PediatricMedicalToxicologyImage />,
    permalink: 'pediatric-medical-toxicology',
  },
  { icon: <PulmonologyImage />, permalink: 'pulmonology' },
  {
    icon: <PulmologySleepMedicineSimpleImage />,
    permalink: 'pulmonology-sleep-medicine-simple',
  },
  {
    icon: <PulmologySleepMedicineComplexImage />,
    permalink: 'pulmonology-sleep-medicine-complex',
  },
  {
    icon: <PediatricNeuroOncologyImage />,
    permalink: 'pediatric-neuro-oncology',
  },
  { icon: <PediatricOncologyImage />, permalink: 'pediatric-oncology' },
  {
    icon: <PediatricPlasticSurgeryImage />,
    permalink: 'pediatric-plastic-surgery',
  },
  { icon: <PediatricPlasticSurgeryImage />, permalink: 'plastic-surgery' },
  { icon: <PediatricPsychologyImage />, permalink: 'pediatric-psychology' },
  { icon: <PediatricSportsMedImage />, permalink: 'pediatric-sports-medicine' },
  {
    icon: <PediatricSurgicalOncologyImage />,
    permalink: 'pediatric-surgical-oncology',
  },
  {
    icon: <PediatricTraumaSurgeryImage />,
    permalink: 'pediatric-trauma-surgery',
  },
  { icon: <PediatricAllergistImage />, permalink: 'pediatric-allergist' },
  { icon: <PediatricChiroImage />, permalink: 'pediatric-chiropractor' },
  { icon: <PediatricHematologyImage />, permalink: 'pediatric-hematology' },
  {
    icon: <PediatricHematologyOncologyImage />,
    permalink: 'pediatric-hematology-oncology',
  },
  { icon: <PhlebologyImage />, permalink: 'phlebology' },
  { icon: <PhysiatryImage />, permalink: 'physiatry' },
  { icon: <ReconstructiveSurgeryImage />, permalink: 'reconstructive-surgery' },
  { icon: <PsychoanalysisImage />, permalink: 'psychoanalysis' },
  { icon: <PsychotherapyImage />, permalink: 'psychotherapy' },
  {
    icon: <PhysicalMedicineRehabilitationImage />,
    permalink: 'physical-medicine-rehabilitation',
  },
  { icon: <PhysicalTherapyImage />, permalink: 'physical-therapy' },
  { icon: <PodiatryImage />, permalink: 'podiatry' },
  { icon: <PreoperativeCareImage />, permalink: 'preoperative-care' },
  { icon: <RadiologyImage />, permalink: 'radiology' },
  { icon: <RadiologyImagingImage />, permalink: 'radiology-imaging' },
  { icon: <RecoverMedicineImage />, permalink: 'recover-medicine' },
  { icon: <ReproductiveHealthImage />, permalink: 'reproductive-health' },
  { icon: <SpeechTherapyImage />, permalink: 'speech-therapy' },
  { icon: <SpeechPathologyImage />, permalink: 'speech-pathology' },
  { icon: <SportsMedicineImage />, permalink: 'sports-medicine' },
  { icon: <ShoulderSurgeryImage />, permalink: 'shoulder-surgery' },
  { icon: <SurgicalPathologyImage />, permalink: 'surgical-pathology' },
  {
    icon: <SmokingCessationProgramImage />,
    permalink: 'smoking-cessation-program',
  },
  { icon: <ShoulderElbowSurgeryImage />, permalink: 'shoulder-elbow-surgery' },
  { icon: <ShoulderElbowImage />, permalink: 'shoulder-elbow' },
  { icon: <SpineNeckBackImage />, permalink: 'spine-neck-back' },
  { icon: <SpineImage />, permalink: 'spine' },
  { icon: <SpineSurgeryImage />, permalink: 'spine-surgery' },
  { icon: <SurgeryImage />, permalink: 'surgery' },
  { icon: <StrokeImage />, permalink: 'stroke' },
  { icon: <TelestrokeImage />, permalink: 'tele-stroke' },
  {
    icon: <ThoracicMedicalOncologyImage />,
    permalink: 'thoracic-medical-oncology',
  },
  { icon: <ToxicologyImage />, permalink: 'toxicology' },
  { icon: <TransfusionMedicineImage />, permalink: 'transfusion-medicine' },
  { icon: <TraumaSurgeryImage />, permalink: 'trauma-surgery' },
  { icon: <TraumaImage />, permalink: 'trauma' },
  { icon: <TelehealthImage />, permalink: 'telehealth' },
  { icon: <TotalJointImage />, permalink: 'total-joint-hip-knee' },
  { icon: <UltrasoundImage />, permalink: 'ultrasound' },
  { icon: <UrgentCareImage />, permalink: 'urgent-care' },
  { icon: <UrologyImage />, permalink: 'urology' },
  { icon: <UrologyImage />, permalink: 'reconstructive-urology' },
  { icon: <VaccinationImage />, permalink: 'vaccination' },
  { icon: <VascularCardiologyImage />, permalink: 'vascular-cardiology' },
  { icon: <WalkInImage />, permalink: 'walk-in' },
  { icon: <XRayImage />, permalink: 'x-ray' },
  { icon: <XRaysPedImage />, permalink: 'x-rays-imaging' },
  { icon: <Endocrinology />, permalink: 'endocrinology' },
  { icon: <WomensHealth />, permalink: 'womens-health' },
  { icon: <NoImage />, permalink: 'no-image' },
  { icon: <NurseVisit />, permalink: 'nurse-visit' },
  { icon: <MemberFitness />, permalink: 'member-fitness' },
  { icon: <MemberHealth />, permalink: 'member-health' },
];
