import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function visitSettingsReducer(
  state = initialState.visitSettings,
  action,
) {
  switch (action.type) {
    case types.CLEAR_VISIT_SETTINGS:
      return initialState.visitSettings;
    case types.BEGIN_FETCH_VISIT_SETTINGS:
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: true,
        },
      };
    case types.FETCH_VISIT_SETTINGS_SUCCESS:
      return { ...action.visitSettings, meta: { loading: false } };
    default:
      return state;
  }
}
