import * as types from './actionTypes';

export function clearNotification() {
  return async dispatch => dispatch({ type: types.CLEAR_NOTIFICATION });
}

export function displayNotification(notification) {
  return async dispatch =>
    dispatch({ type: types.DISPLAY_NOTIFICATION, notification });
}
