/**
 * @param {String} phone 10- or 11-digit phone number string
 * @returns {String} Phone number in format: `NNN NNN NNN` or empty.
 */
export const formatPhone = phone => {
  if (phone.length < 10 || phone.length > 11) return phone;
  if (Number.isNaN(Number(phone)) || !phone) return '';
  let phoneNumber = phone;

  if (phone.charAt(0) === '1' && phone.length === 11) {
    phoneNumber = phone.substr(1);
  }
  // return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3');
  phone.replace(/[^\d]/g, "");
  return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};

export const unformatPhone = (phone = '') => {
  return phone.replace(/\D/g, '');
};
