import {
  handleResponse,
  handleError,
  getHeaderInfo,
  getEndpoint,
} from './apiUtils';

export function getSettings() {
  const params = {
    'page[size]': 999
  };
  return fetch(getEndpoint(`iqsettings`, params), getHeaderInfo())
    .then(handleResponse)
    .catch(handleError);
}
