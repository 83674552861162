import React from 'react';
import {
  Button,
  Checkbox,
  Switch,
  Slider,
  createMuiTheme,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

export const BlackCheckbox = withStyles({
  root: {
    color: '#000',
    marginLeft: 4,
    padding: 4,
    '&$checked': {
      color: '#000',
    },
  },
  checked: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
})(props => <Checkbox color="default" {...props} />);

export const BlackSwitch = withStyles(() => ({
  root: {
    display: 'flex',
    height: 16,
    padding: 0,
    width: 28,
  },
  switchBase: {
    padding: 3,
    color: '#000',
    '&$checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#000',
        borderColor: '#000',
      },
    },
  },
  thumb: {
    width: 10,
    height: 10,
    boxShadow: 'none',
  },
  track: {
    border: `2px solid #000`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'transparent',
  },
  checked: {},
}))(Switch);

export const TimeSlider = withStyles({
  root: {
    color: '#000',
  },
  thumb: {
    background: '#faf7fb',
    border: '2px solid #000',
    height: 20,
    marginTop: -8,
    width: 20,
  },
})(Slider);

export const BlackTheme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: '#979797',
      },
    },
    MuiSelect: {
      select: {
        '&&:focus': {
          backgroundColor: '#fff',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#000000',
    },
  },
});

export const DefaultButton = withStyles(() => ({
  root: {
    backgroundColor: blue[700],
    '&:hover': {
      backgroundColor: blue[900],
    },
    color: '#fff',
    fontSize: '1rem',
    padding: '7px 20px',
    textTransform: 'none',
  },
}))(Button);
