import React, { useEffect, useState, useContext } from 'react';
import 'abort-controller/polyfill';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import QueryString from 'query-string';
import moment from 'moment-timezone';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { find, isEmpty, isUndefined } from 'lodash';
import {
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
} from '@material-ui/core';

import { connect, useSelector } from 'react-redux';
import Sticky from 'react-sticky-el';
import {
  fetchHealthSystem,
  updateSearchFilters,
} from 'redux/actions/healthSystemActions';
import { loadVenuesOfCare } from 'redux/actions/venuesOfCareActions';
import {
  applySearchFilters,
  resetSearchFilters,
  loadScheduleTimes,
  endSearch,
} from 'redux/actions/searchActions';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PageMetaInfo from 'components/common/PageMetaInfo';
import Paginator from 'components/common/Paginator';
import InventoryFilter from 'components/inventory/InventoryFilter';
import MapView from 'components/inventory/MapView';
import ScheduleResultList from 'components/result/ScheduleResultList';
import { getEndpointFromURLSearchParams, getHeaderInfo } from 'api/apiUtils';
import Search from 'components/inventory/Search';

import { BrandingContext } from 'components/contexts/BrandingContext';

import './InventoryPage.scss';
import './InventoryTabs.scss';
import BreadCrumbs from 'components/common/BreadCrumbs';
import InventoryControls from 'components/inventory/InventoryControls';
import InventoryControlsMobile from 'components/inventory/InventoryControlsMobile';
import * as searchConstants from 'config/searchConstants';
import usefilterSectionDisplayStatus from 'hooks/useFilterSectionDisplayStatus';
import DateCarousel from 'components/appointmentPicker/DateCarousel';
import {
  shouldShowSearchElement,
  isGroupC,
  hasFilters,
  cleanServiceLineUrls,
} from 'helpers/searchOptions';
import { ER_SERVICE_NAMES, UC_SERVICE_NAMES } from 'config/searchConstants';
import { groupedDays } from 'utils/dateTimeUtils';
import ServiceBanner from 'components/provider/ServiceBanner';
import { useQueryString } from 'hooks/useQueryString';
import { createEvent } from 'api/analyticsApi';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import serviceLineGroupMapping from 'config/serviceLineGroupMappings';
import { getSettingValue } from 'utils/scheduledDaysUtils';
import useSettings from 'hooks/useSettings';

export function InventoryPage({
  loading,
  pageSize,
  providers,
  searched,
  searchFilters,
  selectedParams,
  applySearchFilters,
  resetSearchFilters,
  loadVenuesOfCare,
  venuesOfCare,
  endSearch,
  fetchHealthSystem,
  healthSystem,
  updateSearchFilters,
}) {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const theme = useTheme();
  const { params, urlParams, isDischargePage } = useQueryString();
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.only('md'));
  const isLgDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [startTime, setStartTime] = useState();
  const getUXMode = () => {
    let uxMode;
    if (isUndefined(urlParams.serviceLine)) {
      uxMode = params.ux_mode || 'educational';
    } else uxMode = 'default';
    return uxMode;
  };
  const [t] = useTranslation();
  const uxMode = getUXMode();
  const [endTime, setEndTime] = useState();
  const [startDate, setStartDate] = useState();
  const [datesLoading, setLoading] = useState(true);
  const [numberOfDays, setNumberOfDays] = useState(1);
  const [date, setDate] = useState();
  const [dates, setDates] = useState([]);
  const [whoIsScrolling, setWhoIsScrolling] = useState();
  const [scrollLeft, setScrollLeft] = useState({
    left: '0',
    fromComponent: 'first',
  });
  const [inactive, setInactive] = useState(params.inactive === 'true');
  const { setQueryParamsFromObject } = useQueryString();
  const [apptTypeSelectFlag, setApptTypeSelectFlag] = useState(true);
  const [title] = useState(document.title);
  const [providerCount, setProviderCount] = useState(0);
  const [sortBy, setSortBy] = useState(
    params.sort || healthSystem['sorting-order'] || 'nearest',
  );
  const [page, setPage] = useState(1);
  const [showMapView, setShowMapView] = useState(params.viewName === 'map');
  const [activeFilters, setActiveFilters] = useState({});
  const [providersLoaded, setProvidersLoaded] = useState(false);
  const [venue, setVenue] = useState({});
  const [results, setResults] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  const [reset, setReset] = useState(false);
  const [apptTypeRequired, setApptTypeRequired] = useState(true);
  const [timeWindow, setTimeWindow] = useState({});
  /* Settings */
  const availableScheduleDays = useSettings({
    healthSystemId: healthSystem.id,
    regionId: healthSystem?.region?.id,
  });

  const { branding } = useContext(BrandingContext);

  const useStyles = makeStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    inputStyle: {
      background: '#fff',
      color: '#000',
      paddingLeft: '5px',
      paddingRight: '5px',
    },
    activeTab: {
      color: branding.primaryColor,
      border: `1px solid ${branding.primaryColor}`,
      borderBottomColor: '#fff',
      background: 'transparent',
      cursor: 'text',
      fontWeight: 'bold',
      '&:hover': { cursor: 'text' },
    },
  });
  const classes = useStyles();
  const groupC = isGroupC(uxMode, params.service || urlParams.serviceLine);
  const [filterStack, setFilterStack] = useState([]);
  const displayFilterSection = usefilterSectionDisplayStatus(
    searched,
    results.data ? results.data : [],
    // results.hits ? results.hits.hits : [],
    filterStack,
  );
  const getScheduleLocation = schedule => {
    const locations =
      results.data && results.data.length
        ? results.included.filter(result => result.type === 'locations')
        : [];
    const loc = find(locations, {
      id: schedule.relationships.location.data.id,
    });
    if (loc) return loc.attributes;
    return null;
  };

  const onScroll = dateSliderRef => {
    if (dateSliderRef.current.id === whoIsScrolling) {
      setScrollLeft({
        left: dateSliderRef.current.scrollLeft,
        fromComponent: whoIsScrolling,
      });
    }
  };
  const [serviceLinePermalink, setServiceLinePerlink] = useState();

  const serviceGroup = () => {
    return serviceLineGroupMapping[params.service || serviceLinePermalink];
  };

  useEffect(() => {
    if (!isUndefined(urlParams.serviceLine))
      setServiceLinePerlink(cleanServiceLineUrls(urlParams.serviceLine));
  }, [urlParams?.serviceLine]);
  useEffect(() => {
    if (isTablet) {
      setNumberOfDays(5);
    }
    if (isDesktop) {
      setNumberOfDays(3);
    }
    if (isLgDesktop) {
      setNumberOfDays(5);
    }
    if (isMobile) {
      setNumberOfDays(1);
      setDates(groupedDays(date, 1, healthSystem['time-zone']));
    }
  }, [isTablet, isDesktop, isLgDesktop, isMobile]);

  useEffect(() => {
    if (isEmpty(venuesOfCare) && uxMode === 'educational') {
      loadVenuesOfCare();
    }
  }, [venuesOfCare, loadVenuesOfCare]);

  useEffect(() => {
    setDates(
      groupedDays(
        date
          ? moment(date)
              .tz(healthSystem['time-zone'])
              .format()
          : moment()
              .tz(healthSystem['time-zone'])
              .format(),
        numberOfDays,
        healthSystem['time-zone'],
      ),
    );
  }, [numberOfDays]);

  useEffect(() => {
    if (startDate) {
      setDates(
        groupedDays(
          startDate?.start || startDate?.value?.start,
          numberOfDays,
          healthSystem['time-zone'],
          2,
        ),
      );
      setDate(
        moment(startDate?.start || startDate?.value?.start)
          .tz(searchFilters.timeZone)
          .format(),
      );
    }
  }, [startDate, searchFilters]);

  useEffect(() => {
    // removed state conditions
    if (!Object.keys(healthSystem).length > 0) {
      fetchHealthSystem();
    } else if (Object.keys(healthSystem).length) {
      const cloneHealthSystem = { data: [{ attributes: healthSystem }] };
      updateSearchFilters(cloneHealthSystem);
    }
    if (params.timestamp) {
      setDate(
        moment(params.timestamp)
          .tz(healthSystem['time-zone'])
          .format(),
      );
    } else {
      // fixme
      setDate(
        moment()
          .tz(healthSystem['time-zone'])
          .format(),
      );
    }
  }, []);

  useEffect(() => {
    if (isEmpty(venue) && !isEmpty(venuesOfCare)) {
      const physVenue = Object.values(venuesOfCare).find(
        v => v.attributes.venueType === 'physician_services',
      );
      setVenue(physVenue);

      const displayOrder = physVenue.attributes.scheduleInventoryDisplayOrder;
      if (displayOrder === 'next_available') {
        setSortBy('soonest');
      } else {
        setSortBy('nearest');
      }
    } else if (params.sort) setSortBy(params.sort);
    else setSortBy(healthSystem['sorting-order'] || 'nearest');
  }, [venue, venuesOfCare, uxMode]);

  const postEventData = async () => {
    const payload = {
      zip: params.zip,
      near: `${selectedParams.latitude},${selectedParams.longitude}`,
      appointmentTypes: params.appointmentTypes,
      insurancePlans: params.insurancePlans,
      accepting_new_patients: null,
      gender: null,
      searchType: 'index',
    };
    createEvent(payload, localStorage.getItem('voc'), results.count);
  };

  const getBasicOptions = (selectedParams, venue) => {
    const searchOptions = {
      'filter[context]': 'patient',
      'filter[radius]': isEmpty(venue)
        ? healthSystem['search-radius'] || 50
        : venue.attributes.searchDistanceRadius,
      'filter[hidden]': false,
      include: 'location,provider,facility',
    };

    if (uxMode === 'educational') {
      searchOptions['filter[venue_of_care]'] = venue.id;
    }

    if (
      selectedParams.appointmentTypeId &&
      shouldShowSearchElement(
        uxMode,
        params.service || serviceLinePermalink,
        searchConstants.APP_TYPE_INPUT,
      )
    ) {
      searchOptions['filter[appointment_type]'] =
        selectedParams.appointmentTypeId;
    }

    if (
      selectedParams.appointmentTypeId &&
      shouldShowSearchElement(
        uxMode,
        params.service || serviceLinePermalink,
        searchConstants.APP_TYPE_INPUT,
      ) &&
      groupC
    ) {
      postEventData();
    }

    if (selectedParams.longitude && selectedParams.latitude)
      searchOptions[
        'filter[near]'
      ] = `${selectedParams.latitude},${selectedParams.longitude}`;
    if (selectedParams.insurancePlan)
      searchOptions['filter[insurance]'] = selectedParams.insurancePlan;

    // If we don't manually set these here, they won't get included
    // during the transition from 'now' to 'later', resulting in
    // unexpected result. This at least maintains consistency with
    // the 'manual' user-initiated search.
    searchOptions.from = selectedParams.date
      ? selectedParams.date.start
      : moment()
          .tz(searchFilters.timeZone)
          .format();
    // This flag should only be used for the 'soonest' ordering.
    searchOptions.check_availability = sortBy === 'soonest';
    searchOptions.to =
      moment(date)
        .tz(searchFilters.timeZone)
        .add(availableScheduleDays, 'days')
        .format() ||
      moment()
        .tz(searchFilters.timeZone)
        .add(availableScheduleDays, 'days')
        .format();

    if (selectedParams.searchType === 'Service') {
      searchOptions['filter[service_id][]'] = selectedParams.id;
    }

    if (selectedParams.searchType === 'Speciality') {
      searchOptions['filter[speciality]'] = selectedParams.id;
    }

    return searchOptions;
  };

  const applyServiceFilters = searchParams => {
    if (params.activeServiceFilters && params.activeServiceFilters.length > 0) {
      if (typeof params.activeServiceFilters === 'string') {
        searchParams.append(
          'filter[service_id][]',
          params.activeServiceFilters,
        );
      } else {
        params.activeServiceFilters.forEach(serviceId => {
          searchParams.append('filter[service_id][]', serviceId);
        });
      }
    } else if (params.subservice_ids && params.subservice_ids.length > 0) {
      if (typeof params.subservice_ids === 'string') {
        searchParams.append('filter[service_id][]', params.subservice_ids);
      } else {
        params.subservice_ids.forEach(serviceId => {
          searchParams.append('filter[service_id][]', serviceId);
        });
      }
    }

    return searchParams;
  };

  const appendFilterOptions = (searchOptions, activeFilters) => {
    const newSearchOptions = searchOptions;
    const {
      acceptNewPatient,
      languages,
      genders,
      hospitalAffiliationFilters,
    } = activeFilters;

    const {
      acceptNewPatientParams,
      languagesParams,
      gendersParams,
      hospitalAffiliationFiltersParams,
    } = params;

    if (acceptNewPatient || acceptNewPatientParams)
      newSearchOptions['filter[accepting_new_patients]'] = 'true';

    // When both genders are selected, ignore filter
    if (genders && genders.length === 1) {
      const [g] = genders;
      const gender = g === 'f' ? 'female' : 'male';
      newSearchOptions['filter[gender]'] = gender;
    } else if (gendersParams && gendersParams.length === 1) {
      const [g] = gendersParams;
      const gender = g === 'f' ? 'female' : 'male';
      newSearchOptions['filter[gender]'] = gender;
    }

    // use selected sort
    if (sortBy) {
      newSearchOptions.sort = sortBy;
    }

    // For Array type of params
    const searchParams = new URLSearchParams(newSearchOptions);

    if (hospitalAffiliationFilters && hospitalAffiliationFilters.length > 0) {
      hospitalAffiliationFilters.forEach(ha => {
        searchParams.append('filter[hospital_affiliation][]', ha);
      });
    } else if (
      hospitalAffiliationFiltersParams &&
      hospitalAffiliationFiltersParams.length > 0
    ) {
      hospitalAffiliationFiltersParams.forEach(ha => {
        searchParams.append('filter[hospital_affiliation][]', ha);
      });
    }

    // If all languages are selected, ignore
    if (
      languages &&
      languages.length > 0 &&
      languages.length < searchFilters.languages.length
    ) {
      languages.forEach(language => {
        searchParams.append('filter[language][]', language);
      });
    } else if (
      languagesParams &&
      languagesParams.length > 0 &&
      languagesParams.length < searchFilters.languages.length
    ) {
      languagesParams.forEach(language => {
        searchParams.append('filter[language][]', language);
      });
    }

    return searchParams;
  };
  const [previousParams, setPreviousParams] = useState();
  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      try {
        if (!selectedParams.latitude || !selectedParams.longitude) {
          return;
        }
        console.log(
          'LOG: ',
          reset,
          searched,
          sortBy,
          venue,
          activeFilters,
          // here
          selectedParams,
          page,
          apptTypeRequired,
          timeWindow,
        );
        // Check that appointment type is present if control is visible.
        // If the search doesn't have an appointment type...
        if (!selectedParams.appointmentTypeId && serviceGroup() !== 'GROUP_C') {
          // Don't search if the field should show and hasn't been hidden
          // by PhysicianForm.js.
          if (
            shouldShowSearchElement(
              uxMode,
              params.service || serviceLinePermalink,
              searchConstants.APP_TYPE_INPUT,
            ) &&
            apptTypeRequired
          ) {
            setApptTypeSelectFlag(false);
            return;
          }
        } else setApptTypeSelectFlag(true);

        const searchOptions = getBasicOptions(selectedParams, venue);
        if (page && sortBy === 'soonest') {
          searchOptions.page_number = page;
          searchOptions.page_size = pageSize;
        }
        if (page && sortBy !== 'soonest') {
          searchOptions['page[number]'] = page;
          searchOptions['page[size]'] = pageSize;
        }

        // if (showMapView) {
        //   searchOptions['page[number]'] = 1;
        //   searchOptions['page[size]'] = 1999;
        // }

        let searchParams = null;
        if (reset) {
          searchParams = new URLSearchParams(searchOptions);
          setReset(false);
        } else {
          searchParams = appendFilterOptions(searchOptions, activeFilters);
        }

        searchParams =
          selectedParams.searchType === 'Service'
            ? searchParams
            : applyServiceFilters(searchParams);

        setListLoading(true);
        let pre = {};
        setPreviousParams(p => {
          pre = p;
          return searchParams;
        });
        // removed this code to avoid the call abort
        // if (serviceGroup() !== 'GROUP_C')
        //   if (
        //     searchParams?.toString() === previousParams?.toString() &&
        //     results?.data
        //   ) {
        //     setListLoading(false);
        //     setLoading(false);
        //     return;
        //   }
        // if (
        //   searchParams?.get('to')?.split('T')?.[0] ===
        //   previousParams?.get('to')?.split('T')?.[0]
        // ) {
        //   setListLoading(false);
        //   setLoading(false);
        //   return;
        // }

        if (serviceGroup() === 'GROUP_C') {
          searchParams.delete('filter[appointment_type]');
        }

        const response = await fetch(
          getEndpointFromURLSearchParams(`schedules`, searchParams),
          { ...getHeaderInfo(), signal: abortController.signal },
        );
        const resultsS = await response.json();
        if (!isDischargePage) {
          const actualLength = resultsS.data.length;
          const filteredData = resultsS?.data.filter(
            d => d.attributes?.hidden !== true,
          );
          resultsS.data = filteredData;
          // eslint-disable-next-line operator-assignment
          resultsS.meta['record-count'] =
            resultsS.meta['record-count'] -
            (actualLength - filteredData.length);
        }

        if (!selectedParams.appointmentTypeId && serviceGroup() === 'GROUP_C') {
          resultsS.data = resultsS?.data.filter(
            d => !d.attributes['appointment-types'].length > 0,
          );
          resultsS.meta['record-count'] = resultsS.data.length;
        }

        if (selectedParams.appointmentTypeId && serviceGroup() === 'GROUP_C') {
          resultsS.data = resultsS.data.filter(schedule => {
            const appointmentTypes = schedule.attributes['appointment-types'];
            // Check if appointment-types array is empty or contains an object with appointmenttypeid
            return (
              appointmentTypes.length === 0 ||
              appointmentTypes.some(appointmentType => {
                return (
                  Number(appointmentType.id) ===
                  Number(selectedParams.appointmentTypeId)
                );
              })
            );
          });
          resultsS.meta['record-count'] = resultsS.data.length;
        }

        await setResults(resultsS.errors ? [] : resultsS);
        setListLoading(!!resultsS.errors);
        endSearch();
      } catch (e) {
        console.error('Error fetching Schedule API, probably aborted.');
        console.error(e);
      }
      // eslint-disable-next-line consistent-return
      return () => {
        setResults([]);
        // eslint-disable-next-line no-param-reassign
        searched = false;
      };
    })();

    return () => {
      abortController.abort();
    };
  }, [
    reset,
    searched,
    sortBy,
    venue,
    activeFilters,
    // here
    selectedParams,
    page,
    apptTypeRequired,
    timeWindow,
  ]);
  const [mapViewResults, setMapViewResults] = useState({});
  useEffect(() => {
    const viewName = showMapView ? 'map' : 'list';
    const search = QueryString.stringify({ ...params, viewName });
    history.replace({
      pathname,
      search,
    });
  }, [showMapView]);

  useEffect(() => {
    const abortController = new AbortController();
    (async () => {
      try {
        if (
          !selectedParams.latitude ||
          !selectedParams.longitude ||
          !showMapView
        ) {
          return;
        }
        // Check that appointment type is present if control is visible.
        // If the search doesn't have an appointment type...
        if (!selectedParams.appointmentTypeId) {
          // Don't search if the field should show and hasn't been hidden
          // by PhysicianForm.js.
          if (
            shouldShowSearchElement(
              uxMode,
              params.service || serviceLinePermalink,
              searchConstants.APP_TYPE_INPUT,
            ) &&
            apptTypeRequired
          ) {
            return;
          }
        }

        const searchOptions = getBasicOptions(selectedParams, venue);
        if (page && sortBy === 'soonest') {
          searchOptions.page_number = 1;
          searchOptions.page_size = 1999;
        }
        if (page && sortBy !== 'soonest') {
          searchOptions['page[number]'] = 1;
          searchOptions['page[size]'] = 1999;
        }

        let searchParams = null;
        if (reset) {
          searchParams = new URLSearchParams(searchOptions);
          setReset(false);
        } else {
          searchParams = appendFilterOptions(searchOptions, activeFilters);
        }

        searchParams =
          selectedParams.searchType === 'Service'
            ? searchParams
            : applyServiceFilters(searchParams);

        const response = await fetch(
          getEndpointFromURLSearchParams(`schedules`, searchParams),
          { ...getHeaderInfo(), signal: abortController.signal },
        );
        const results = await response.json();
        setMapViewResults(results);
      } catch (e) {
        console.error('Error fetching Schedule API, probably aborted.');
        console.error(e);
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [
    reset,
    searched,
    sortBy,
    venue,
    activeFilters,
    selectedParams,
    page,
    apptTypeRequired,
    timeWindow,
    showMapView,
  ]);

  useEffect(() => {
    if (providers.length && !providersLoaded) {
      applySearchFilters(activeFilters);
      setProvidersLoaded(true);
    }
  }, [
    providers,
    providersLoaded,
    applySearchFilters,
    setProvidersLoaded,
    activeFilters,
  ]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    setProviderCount(results.meta ? results.meta['record-count'] : 0);
  }, [results]);

  const handlePageChange = value => {
    setPage(value);
  };

  const handleSortBy = event => {
    setSortBy(event.target.value);
    setQueryParamsFromObject({ sort: event.target.value });
  };

  const handleFilterChange = filters => {
    if (filters.sort) {
      setSortBy(filters.sort);
    }

    setActiveFilters(filters);
    applySearchFilters(filters);
    setPage(1);
  };

  const handleFilterReset = () => {
    setReset(true);
    setFilterStack([]);
    setActiveFilters({});
    resetSearchFilters();
    setPage(1);
  };

  let totalPages = 1;
  if (providerCount > pageSize) {
    totalPages = Math.floor(providerCount / pageSize);
    if (providerCount % pageSize !== 0) {
      totalPages += 1;
    }
  }

  const showPaginator = !loading && !!providerCount && !listLoading;

  let type = '';
  let pageTitle = '';
  let pageDescription = '';
  let MediaContentBlockTop = '';
  let MediaContentBlockBottom = '';
  let MediaTitle_Top = '';
  const capitalize = str => {
    return str
      .split(' ')
      .map(s => `${s.charAt(0).toUpperCase()}${s.slice(1)}`)
      .join(' ');
  };
  if (healthSystem.region !== null) {
    if (uxMode === 'educational') {
      if (
        t('Education_RegionPage_MediaContentBlock_Top') !== '' &&
        t('Education_RegionPage_MediaContentBlock_Top') !==
          'Education_RegionPage_MediaContentBlock_Top'
      ) {
        MediaContentBlockTop = t('Education_RegionPage_MediaContentBlock_Top', {
          hs_name: healthSystem?.name,
          region_name: healthSystem?.region?.name,
        });
      }
      if (
        t('Education_RegionPage_MediaContentBlock_Bottom') !== '' &&
        t('Education_RegionPage_MediaContentBlock_Bottom') !==
          'Education_RegionPage_MediaContentBlock_Bottom'
      ) {
        MediaContentBlockBottom = t(
          'Education_RegionPage_MediaContentBlock_Bottom',
          {
            hs_name: healthSystem?.name,
            region_name: healthSystem?.region?.name,
          },
        );
      }
      if (
        t('Education_RegionPage_MediaTitle_Top') !== '' &&
        t('Education_RegionPage_MediaTitle_Top') !==
          'Education_RegionPage_MediaTitle_Top'
      ) {
        MediaTitle_Top = t('Education_RegionPage_MediaTitle_Top', {
          hs_name: healthSystem?.name,
          region_name: healthSystem?.region?.name,
        });
      }
    }
    pageDescription = `Book an appointment online at ${healthSystem.region.name} - ${healthSystem.name}`;
    pageTitle = `Online Healthcare Scheduling at ${healthSystem.region.name} - ${healthSystem.name}`;
    type = 'region';
  } else {
    if (uxMode === 'educational') {
      if (
        t('Education_HealthSystemPage_MediaContentBlock_Top') !== '' &&
        t('Education_HealthSystemPage_MediaContentBlock_Top') !=
          'Education_HealthSystemPage_MediaContentBlock_Top'
      ) {
        MediaContentBlockTop = t(
          'Education_HealthSystemPage_MediaContentBlock_Top',
          {
            hs_name: healthSystem?.name,
            region_name: healthSystem?.region?.name,
          },
        );
      }
      if (
        t('Education_HealthSystemPage_MediaContentBlock_Bottom') !== '' &&
        t('Education_HealthSystemPage_MediaContentBlock_Bottom') !==
          'Education_HealthSystemPage_MediaContentBlock_Bottom'
      ) {
        MediaContentBlockBottom = t(
          'Education_HealthSystemPage_MediaContentBlock_Bottom',
          {
            hs_name: healthSystem?.name,
            region_name: healthSystem?.region?.name,
          },
        );
      }
      if (
        t('Education_HealthSystemPage_MediaTitle_Top') !== '' &&
        t('Education_HealthSystemPage_MediaTitle_Top') !==
          'Education_HealthSystemPage_MediaTitle_Top'
      ) {
        MediaTitle_Top = t('Education_HealthSystemPage_MediaTitle_Top', {
          hs_name: healthSystem?.name,
          region_name: healthSystem?.region?.name,
        });
      }
    }
    pageTitle = `Online Healthcare Scheduling at ${healthSystem.name}`;
    pageDescription = `Book an appointment online at ${healthSystem.name}`;
    type = 'hs';
  }

  if (
    (urlParams?.serviceLine || params?.serviceName) &&
    params?.serviceName !== 'Find A Doctor' &&
    params?.serviceName !== 'Primary Care' &&
    !urlParams?.city
  ) {
    if (
      t('ServiceLinePage_MediaContentBlock_Top') !== '' &&
      t('ServiceLinePage_MediaContentBlock_Top') !=
        'ServiceLinePage_MediaContentBlock_Top'
    ) {
      MediaContentBlockTop = t('ServiceLinePage_MediaContentBlock_Top', {
        hs_name: healthSystem?.name,
        region_name: healthSystem?.region?.name,
        service_name: params?.serviceName
          ? params?.serviceName
          : urlParams?.serviceLine
          ? capitalize(urlParams?.serviceLine)
          : '',
      });
    }
    if (
      t('ServiceLinePage_MediaContentBlock_Bottom') !== '' &&
      t('ServiceLinePage_MediaContentBlock_Bottom') !==
        'ServiceLinePage_MediaContentBlock_Bottom'
    ) {
      MediaContentBlockBottom = t('ServiceLinePage_MediaContentBlock_Bottom', {
        hs_name: healthSystem?.name,
        region_name: healthSystem?.region?.name,
        service_name: params?.serviceName
          ? params?.serviceName
          : urlParams?.serviceLine
          ? capitalize(urlParams?.serviceLine)
          : '',
      });
    }
    if (
      t('ServiceLinePage_MediaTitle_Top') !== '' &&
      t('ServiceLinePage_MediaTitle_Top') !== 'ServiceLinePage_MediaTitle_Top'
    ) {
      MediaTitle_Top = t('ServiceLinePage_MediaTitle_Top', {
        hs_name: healthSystem?.name,
        region_name: healthSystem?.region?.name,
        service_name: params?.serviceName
          ? params?.serviceName
          : urlParams?.serviceLine
          ? capitalize(urlParams?.serviceLine)
          : '',
      });
    }
  }

  if (
    (pathname !== undefined &&
      pathname == '/search' &&
      params?.serviceName === 'Find A Doctor') ||
    params?.serviceName === 'Primary Care'
  ) {
    if (
      t('FindaDocPage_MediaContentBlock_Top') !== '' &&
      t('FindaDocPage_MediaContentBlock_Top') !=
        'FindaDocPage_MediaContentBlock_Top'
    ) {
      MediaContentBlockTop = t('FindaDocPage_MediaContentBlock_Top', {
        hs_name: healthSystem?.name,
        region_name: healthSystem?.region?.name,
      });
    }
    if (
      t('FindaDocPage_MediaContentBlock_Bottom') !== '' &&
      t('FindaDocPage_MediaContentBlock_Bottom') !==
        'FindaDocPage_MediaContentBlock_Bottom'
    ) {
      MediaContentBlockBottom = t('FindaDocPage_MediaContentBlock_Bottom', {
        hs_name: healthSystem?.name,
        region_name: healthSystem?.region?.name,
      });
    }
    if (
      t('FindaDocPage_MediaTitle_Top') !== '' &&
      t('FindaDocPage_MediaTitle_Top') !== 'FindaDocPage_MediaTitle_Top'
    ) {
      MediaTitle_Top = t('FindaDocPage_MediaTitle_Top', {
        hs_name: healthSystem?.name,
        region_name: healthSystem?.region?.name,
      });
    }
  }

  if (
    pathname !== '/search' &&
    urlParams?.serviceLine &&
    urlParams?.state &&
    urlParams?.city
  ) {
    if (
      t('CityPage_MediaContentBlock_Top') !== '' &&
      t('CityPage_MediaContentBlock_Top') != 'CityPage_MediaContentBlock_Top'
    ) {
      MediaContentBlockTop = t('CityPage_MediaContentBlock_Top', {
        hs_name: healthSystem?.name,
        service_name: params?.serviceName,
        location_city: urlParams?.city ? capitalize(urlParams?.city) : '',
        location_state: urlParams?.state?.toUpperCase(),
        region_name: healthSystem?.region?.name,
      });
    }
    if (
      t('CityPage_MediaContentBlock_Bottom') !== '' &&
      t('CityPage_MediaContentBlock_Bottom') !==
        'CityPage_MediaContentBlock_Bottom'
    ) {
      MediaContentBlockBottom = t('CityPage_MediaContentBlock_Bottom', {
        hs_name: healthSystem?.name,
        service_name: params?.serviceName,
        location_city: urlParams?.city ? capitalize(urlParams?.city) : '',
        location_state: urlParams?.state?.toUpperCase(),
        region_name: healthSystem?.region?.name,
      });
    }
    if (
      t('CityPage_MediaTitle_Top') !== '' &&
      t('CityPage_MediaTitle_Top') !== 'CityPage_MediaTitle_Top'
    ) {
      MediaTitle_Top = t('CityPage_MediaTitle_Top', {
        hs_name: healthSystem?.name,
        service_name: params?.serviceName,
        location_city: urlParams?.city ? capitalize(urlParams?.city) : '',
        location_state: urlParams?.state?.toUpperCase(),
        region_name: healthSystem?.region?.name,
      });
    }
  }

  const handleSetDates = date => {
    if (isMobile) {
      setDates(groupedDays(date, 1, healthSystem['time-zone']));
    }
  };
  const renderSearchedResult = () => {
    const handleDateChange = date => {
      setLoading(true);
      setDate(date);
      setDates(groupedDays(date, numberOfDays, healthSystem['time-zone']));
    };
    if (!apptTypeSelectFlag) return null;
    return (
      <Row>
        {displayFilterSection &&
          hasFilters(uxMode, params.service || serviceLinePermalink) && (
            <Col sm={3} className="inventory-sidebar">
              <div
                className="inventory-sidebar-wrapper"
                style={{
                  backgroundColor: `${branding.secondaryColor}`,
                  height: '100%',
                }}
              >
                <InventoryFilter
                  sortProp={params.sort || sortBy}
                  results={results.data}
                  totalCount={providerCount}
                  filters={searchFilters}
                  loading={loading}
                  handleFilterChange={handleFilterChange}
                  handleFilterReset={handleFilterReset}
                  setFilterStack={setFilterStack}
                  uxMode={uxMode}
                />
              </div>
            </Col>
          )}

        <Col
          sm={
            displayFilterSection &&
            hasFilters(uxMode, params.service || serviceLinePermalink)
              ? isMobile
                ? 12
                : 9
              : 12
          }
          style={{ paddingLeft: 0, paddingRight: 0 }}
        >
          {!loading && !!providerCount && displayFilterSection && isMobile && (
            <Sticky className="sticky-wrapper">
              <InventoryControlsMobile
                showDateCarousel
                setTimeWindow={setTimeWindow}
                showMapView={showMapView}
                setShowMapView={setShowMapView}
                timezoneName={healthSystem['time-zone']}
                dates={dates}
                onDateChange={handleDateChange}
                isFacility
                handleSetDates={handleSetDates}
              />
            </Sticky>
          )}

          {displayFilterSection && !isMobile && (
            <InventoryControls
              showDateCarousel={false}
              setTimeWindow={setTimeWindow}
              showMapView={showMapView}
              setShowMapView={setShowMapView}
              sortBy={sortBy}
              handleChange={handleSortBy}
              timezoneName={searchFilters.timeZone}
            />
          )}

          {!showMapView && !isEmpty(venue) && uxMode === 'educational' && (
            <div
              className="InventoryTabs hide-mobile"
              style={{
                borderBottom: `1px solid ${branding.primaryColor}`,
              }}
            >
              <Link
                key={`venue-${venue.attributes.venueType}`}
                to={`/search?tab=${venue.attributes.venueType}`}
                onClick={e => e.preventDefault()}
              >
                <div className={`tab-item ${classes.activeTab}`}>
                  {`${venue.attributes.displayName} (${
                    results.data ? providerCount : '...'
                  })`}
                </div>
              </Link>
            </div>
          )}

          {venue && Object.entries(venue).length > 0 && (
            <ServiceBanner
              venueType={venue.attributes.venueType}
              services={venue.attributes.educationalMessage.split(',')}
              title={venue.attributes.displayName}
            />
          )}
          {showMapView && mapViewResults?.data?.length === 0 && (
            <Alert
              style={{
                background: branding.secondaryColor,
                color: branding.primaryColor,
              }}
            >
              {mapViewResults?.data?.length === 0 && (
                <>
                  <Alert.Heading>{t('ResultList.weTried')}</Alert.Heading>
                  {t('ResultList.noResult')}
                </>
              )}
            </Alert>
          )}
          {showMapView && mapViewResults?.data?.length > 0 && (
            <MapView
              schedules={mapViewResults.data}
              locations={
                mapViewResults.data && mapViewResults.data.length
                  ? mapViewResults.included.filter(
                      result => result.type === 'locations',
                    )
                  : []
              }
              providers={
                mapViewResults.data && mapViewResults.data.length
                  ? mapViewResults.included.filter(
                      result => result.type === 'providers',
                    )
                  : []
              }
            />
          )}
          {!showMapView && (
            <>
              <Grid container item xs={12} sm={12} className="date_carousel">
                <Grid item xs={5} sm={5}>
                  {' '}
                </Grid>
                {!(params.service === 'emergency-room') &&
                  !(params.service === 'urgent-care') &&
                  !(params.service === 'immediate-care') &&
                  !ER_SERVICE_NAMES.includes(params.serviceName) &&
                  !UC_SERVICE_NAMES.includes(params.serviceName) &&
                  !isMobile &&
                  !listLoading && (
                    <Grid item xs={7} sm={7}>
                      <Sticky stickyClassName="sticky test">
                        <DateCarousel
                          dates={dates}
                          onDateChange={handleDateChange}
                          timezoneName={
                            results?.data?.[0]
                              ? getScheduleLocation(results?.data[0])[
                                  'time-zone'
                                ]
                              : searchFilters.timeZone
                          }
                          dateHeadingLayout="horizontal"
                          onScroll={onScroll}
                          scrollLeft={scrollLeft}
                          whoIsScrolling={whoIsScrolling}
                          setWhoIsScrolling={setWhoIsScrolling}
                        />
                      </Sticky>
                    </Grid>
                  )}
              </Grid>
              <ScheduleResultList
                facilityPageTitle={pageTitle}
                venue={venue}
                results={results.data}
                locations={
                  results.data && results.data.length
                    ? results.included.filter(
                        result => result.type === 'locations',
                      )
                    : []
                }
                providers={
                  results.data && results.data.length
                    ? results.included.filter(
                        result => result.type === 'providers',
                      )
                    : []
                }
                facilities={
                  results?.data && results?.data?.length
                    ? results?.included?.filter(
                        result => result?.type === 'facilities',
                      )
                    : []
                }
                selectedParams={selectedParams}
                loading={loading || listLoading}
                uxMode={uxMode}
                dates={dates}
                startTime={startTime}
                endTime={endTime}
                datesLoading={datesLoading}
                dateCurrent={date}
                noOfDays={numberOfDays}
                scrollLeft={scrollLeft}
                onScroll={onScroll}
                whoIsScrolling={whoIsScrolling}
                setWhoIsScrolling={setWhoIsScrolling}
                type={type}
                canonicalLink={
                  !isUndefined(urlParams.serviceLine)
                    ? cleanServiceLineUrls(pathname)
                    : pathname
                }
              />
            </>
          )}

          {showPaginator && !showMapView && totalPages > 1 && (
            <>
              <Paginator
                handleChange={handlePageChange}
                activePage={page}
                totalPages={totalPages}
              />
            </>
          )}
        </Col>
      </Row>
    );
  };

  const displayContent = () => {
    if (searched) {
      return renderSearchedResult();
    }

    return <></>;
  };
  return (
    <main className="InventoryPage SearchPage">
      {/* <PageMetaInfo
        title={pageTitle}
        description={pageDescription}
        canonicalLink={
          !isUndefined(urlParams.serviceLine)
            ? cleanServiceLineUrls(pathname)
            : pathname
        }
      /> */}
      {MediaTitle_Top && (
        <div style={{ color: branding.primaryColor }} className="container">
          <Trans>
            <h1 className="locizeHeaderStyle">{MediaTitle_Top}</h1>
          </Trans>
        </div>
      )}
      {MediaContentBlockTop && (
        <div style={{ color: branding.primaryColor }} className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="desctop" content={MediaContentBlockTop} />
          </Helmet>
          <Trans>
            <p className="locizePTagStyle">{MediaContentBlockTop}</p>
          </Trans>
        </div>
      )}
      <Container className="search-container">
        {inactive && (
          <div className="private-mode">
            <p>Including inactive schedules.</p>
          </div>
        )}
        <Search
          uxMode={uxMode}
          resetPageCount={() => setPage(1)}
          setApptTypeRequired={setApptTypeRequired}
          setStartDate={date => setStartDate(date)}
        />
        <BreadCrumbs />
        {isMobile && (
          <Grid item>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel
                id="sort-by-label-inventory-page"
                htmlFor="sort-by-select-inventory-page"
                className={classes.inputStyle}
              >
                {t('InventoryControl.sort')}
              </InputLabel>
              <Select
                id="sort-by-select-inventory-page"
                labelId="sort-by-label-inventory-page"
                value={sortBy}
                onChange={handleSortBy}
              >
                <MenuItem value="soonest">
                  {t('InventoryControls.soonest')}
                </MenuItem>
                <MenuItem value="nearest">
                  {t('InventoryControls.closest')}
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
      </Container>
      <Container>{displayContent()}</Container>
      {MediaContentBlockBottom && (
        <div style={{ color: branding.primaryColor }} className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="desctop" content={MediaContentBlockBottom} />
          </Helmet>
          <Trans>
            <p className="locizePTagStyle">{MediaContentBlockBottom}</p>
          </Trans>
        </div>
      )}
    </main>
  );
}

InventoryPage.defaultProps = {
  loading: true,
  pageSize: 10,
  searched: false,
  selectedParams: {},
  venuesOfCare: {},
};

InventoryPage.propTypes = {
  searched: PropTypes.bool,
  providers: PropTypes.instanceOf(Object).isRequired,
  searchFilters: PropTypes.instanceOf(Object).isRequired,
  selectedParams: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  pageSize: PropTypes.number,
  applySearchFilters: PropTypes.func.isRequired,
  resetSearchFilters: PropTypes.func.isRequired,
  loadVenuesOfCare: PropTypes.func.isRequired,
  venuesOfCare: PropTypes.instanceOf(Object),
  endSearch: PropTypes.func.isRequired,
  fetchHealthSystem: PropTypes.func.isRequired,
  healthSystem: PropTypes.instanceOf(Object).isRequired,
  updateSearchFilters: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    performSearch: state.searchResult.searching,
    searched: state.providers.searched,
    searchFilters: state.searchFilters,
    selectedParams: state.searchParams.selectedParams,
    providers: state.searchResult.providers,
    schedules: state.searchResult.schedules,
    availableTimes: state.searchResult.availableTimes,
    venuesOfCare: state.venuesOfCare.data,
    loading: state.venuesOfCare.meta.loading,
    healthSystem: state.healthSystem,
  };
}

const mapDispatchToProps = {
  loadVenuesOfCare,
  applySearchFilters,
  resetSearchFilters,
  loadScheduleTimes,
  endSearch,
  fetchHealthSystem,
  updateSearchFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryPage);
