import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { BrandingContext } from '../contexts/BrandingContext';

const ResultsTab = ({ children }) => {
  const { branding } = useContext(BrandingContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      {isDesktop && (
        <div
          style={{
            borderBottom: `1px solid ${branding.primaryColor}`,
            display: 'flex',
            margin: '30px 0 0 0',
            padding: '0px',
          }}
        >
          {children}
        </div>
      )}
    </>
  );
};

ResultsTab.defaultProps = {
  children: null,
};

ResultsTab.propTypes = {
  children: PropTypes.node,
};

export default ResultsTab;
