import React, { useContext, useState, useEffect } from "react";
import { Box, Grid, Typography, Container, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { FolderOpenOutlined } from "@material-ui/icons";
import { isMobile } from 'react-device-detect';
import { PatientIntakeContext } from "../contexts/PatientIntakeContext";
import Popover from '@mui/material/Popover';
import Webcam from 'react-webcam'
import PropTypes from 'prop-types';
import { ReactComponent as AddCircleImage } from 'assets/images/icons/add-circle.svg';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import { ReactComponent as RemoveCircleImage } from 'assets/images/icons/remove-circle.svg';
import { ReactComponent as CircleImage } from 'assets/images/icons/check-circle.svg';


const videoConstraints = {
    width: "100%",
    height: "100%",
    facingMode: 'user',
}

const InsuranceUpload = ({ side, setError, page }) => {
    const value = useContext(PatientIntakeContext);
    const imageData = value?.intakeInfo[page];

    const useStyles = makeStyles((theme) => ({
        imageText: {
            color: "#3175F6",
            fontSize: "16px !important",
            fontWeight: "600"
        },
        arrowBtn: {
            paddingLeft: "5px"
        },
        cardStyle: {
            border: '1px dashed #3175F6',
            borderRadius: 2,
            width: "100%",
            aspectRatio: '4/3'
        },
        innerCardStyle: {
            position: 'relative',
            width: "100%",
            height: "100%",
            justifyContent: 'center',
            alignItems: 'center',
            display: imageData?.frontImage ? 'none' : 'flex',
            flexDirection: 'column',
            cursor: "pointer",
            backgroundColor: "inherit",
            border: "none"
        },
        imageContainerStyle: {
            position: 'relative',
            width: "100%", height: "100%",
            justifyContent: 'center', alignItems: 'center',
            flexDirection: 'column',
            cursor: "pointer"
        },
        removeIconStyle: {
            position: 'absolute', right: 1, top: 1
        },
        imageStyle: {
            width: "100%",
            height: "100%",
            objectFit: "stretch",
            borderRadius: 2
        },
        errorMsgStyle: {
            color: "red",
            position: "absolute",
            padding: 5,
            maxWidth: "8rem",
            fontSize: "10px"
        },
        fileName: {
            width: "134px",
            overflow: "hidden",
            fontSize: 12,
            paddingInline: "2px",
            textOverflow: "ellipsis"
        },
        takePhotoBtn: {
            color: '#f5f5f5 !important',
            borderBottom: "1px solid #f5f5f5 !important",
            textTransform: "none !important"
        },
        chooseFileBtn: {
            color: '#f5f5f5 !important',
            textTransform: "none !important"
        },
        imageControlsStyle: {
            position: 'absolute',
            width: "80%",
            height: "30%",
            left: '10%',
            bottom: 1
        }
    }));
    const classes = useStyles();

    const [t] = useTranslation();

    const webcamRef = React.useRef(null)
    const [isCameraOn, setCameraOn] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);

    const capture = React.useCallback(() => {
        const pictureSrc = webcamRef.current.getScreenshot()
        setCameraOn(false);
        setAnchorEl(false)
        handleClose()
        value.updateInsuranceInfo({ [`${side}Image`]: pictureSrc, [`${side}ImageName`]: `${side}Image.jpg`, [`${side}ImageType`]: "jpg", [`Valid${side}ImageSize`]: true, ImagesChanged: true }, page);
    })

    const Upload = (e) => {
        if (isMobile) document.getElementById(`${side}Image-icon-button-file`).click()
        setAnchorEl(e.currentTarget);
    }

    const choosePhoto = () => {
        handleClose()
        document.getElementById(`${side}Image-icon-button-file`).click()
    }

    useEffect(() => {
        const preview = document.getElementById(`preview-${side}Image`);
        if (!preview) return;
        if (imageData?.[`${side}Image`]) {
            preview.src = imageData?.[`${side}Image`]
        } else {
            preview.src = null;
        }
    }, [imageData?.[`${side}Image`]])


    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };


    const fileSelectHandler = async (e) => {
        e.persist();
        const [file] = e.target.files
        const type = file?.name?.split(".")?.pop();
        if (file.size > 3 * 1024 * 1024) {
            setError(t(`PatientIntake.BigInsuranceDocSize`));
        }
        if (!value?.validImageTypes?.includes(type)) {
            setError(t(`PatientIntake.InvalidFileTypeError`))
        }
        const base64File = await convertBase64(file);
        if (base64File) value.updateInsuranceInfo({ [`${side}Image`]: base64File, [`${side}ImageName`]: file?.name, [`${side}ImageType`]: type, [`Valid${side}ImageSize`]: file.size > 3 * 1024 * 1024 ? false : true, ImagesChanged: true }, page);
        handleClose()
    }

    const onRemove = () => {
        if (imageData?.[`${side}ImageType`] !== "pdf") {
            const preview = document.getElementById(`preview-${side}Image`)
            if (preview) preview.src = "#"
        }
        document.getElementById(`${side}Image-icon-button-file`).value = null;
        value.updateInsuranceInfo({ [`${side}Image`]: null, [`${side}ImageName`]: null, [`${side}ImageType`]: null, ImagesChanged: true }, page);
        setError("")
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onTakePhotoDesktop = () => {
        setCameraOn(true); setAnchorEl(false)
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (<>
        <Grid item xs={6}>
            <Box
                component={"div"}
                className={classes.cardStyle}
                sx={{ borderRadius: 2 }}
            >
                <input accept="capture=camera, image/png, image/jpg, image/jpeg, image/bmp, application/pdf"
                    id={`${side}Image-icon-button-file`}
                    type="file"
                    hidden
                    onChange={fileSelectHandler}
                    data-testid="file-upload"
                />

                {!isMobile && <div> <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Grid container direction={"column"} style={{ backgroundColor: "#555555", padding: 5 }}>
                        <Button onClick={onTakePhotoDesktop} className={classes.takePhotoBtn} >
                            {t(`PatientIntake.TakePhoto`)}  <CameraAltOutlinedIcon fontSize="small" className={classes.arrowBtn} />
                        </Button>
                        <Button onClick={choosePhoto} className={classes.chooseFileBtn}>
                            {t(`PatientIntake.ChooseFile`)} <FolderOpenOutlined fontSize="small" className={classes.arrowBtn} />
                        </Button>
                    </Grid>
                </Popover></div>}
                <Box
                    onClick={Upload}
                    component={isMobile ? "div" : "button"}
                    sx={{ display: isCameraOn ? 'none' : imageData?.[`${side}Image`] ? 'none' : 'flex' }}
                    className={classes.innerCardStyle}
                >
                    <AddCircleImage />
                    <Typography variant="h6" component="h6" className={classes.imageText} >
                        {t(`PatientIntake.${side}Side`)}
                    </Typography>
                </Box>
                {isCameraOn && <Box
                    component={"div"}
                    sx={{ display: isCameraOn ? 'flex' : 'none', position: 'relative' }}
                    className={classes.innerCardStyle}
                >
                    <Webcam
                        audio={false}
                        height={"100%"}
                        ref={webcamRef}
                        width={"100%"}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                    />
                    <Box className={classes.imageControlsStyle}>
                        <Grid container sx={{}} alignItems='center' justifyContent="space-around">
                            <Grid item>
                                <RemoveCircleImage onClick={() => setCameraOn(false)} />
                            </Grid>
                            <Grid item>
                                <CircleImage style={{ color: "green" }} onClick={capture} />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>}

                <Box component={"div"}
                    className={classes.imageContainerStyle}
                    sx={{ display: !isCameraOn && imageData?.[`${side}Image`] ? 'flex' : 'none' }}>
                    {value?.validImageTypes?.includes(imageData?.[`${side}ImageType`]?.toLowerCase()) && imageData?.[`${side}ImageType`]?.toLowerCase() !== "pdf"
                        ? <img src="#" id={`preview-${side}Image`} className={classes.imageStyle} role="img" />
                        :
                        <Typography className={classes.fileName}>
                            {imageData?.[`${side}ImageName`]}
                        </Typography>}
                    <div onClick={onRemove} className={classes.removeIconStyle}>
                        <RemoveCircleImage />
                    </div>
                </Box>
            </Box>
        </Grid>
    </>)
}

InsuranceUpload.propTypes = {
    side: PropTypes.string.isRequired,
    setError: PropTypes.func.isRequired,
};

export default InsuranceUpload;