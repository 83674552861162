import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomTextArea from '../CustomTextArea';

const CommentFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const [t] = useTranslation();
  return (
    <>
      {formContext.isFieldEnabled('comments') && (
        <div className="form-section_row">
          <CustomTextArea
            fieldKey="comments"
            fieldValue={formContext.form.comments}
            validateFormHandler={formContext.handleSetFormState}
            label={t('commentPage.additionalComments')}
            hasError={formContext.hasError('comments')}
            errorMessage={formContext.getError('comments')}
            required={formContext.isFieldRequired('comments')}
          />
        </div>
      )}
    </>
  );
};

export default CommentFields;
