import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { BrandingContext } from 'components/contexts/BrandingContext';
import BaseModal from './BaseModal';
import 'components/TermsPage.scss';

const WhenToCall911Modal = ({ show, onClose }) => {
  const { branding } = useContext(BrandingContext);
  const h3Branding = {
    color: branding.primaryColor,
    borderBottom: `1px solid ${branding.primaryColor}`,
  };
  const [t] = useTranslation();

  return (
    <BaseModal show={show} onClose={onClose}>
      <main className="TermsPage container">
        <h1 style={{ color: branding.primaryColor }}>
          {t('shared:WhenToCall911Modal.headingOne')}
        </h1>
        <p>{t('shared:WhenToCall911Modal.contentSectionOne')}</p>
        <section className="terms">
          <h3 style={h3Branding}>
            {t('shared:WhenToCall911Modal.headingTwo')}
          </h3>
          <p>{t('shared:WhenToCall911Modal.contentSectionTwo')}</p>
          <ul>
            <li>{t('shared:WhenToCall911Modal.listOption1')}</li>
            <li>{t('shared:WhenToCall911Modal.listOption2')}</li>
            <li>{t('shared:WhenToCall911Modal.listOption3')}</li>
            <li>{t('shared:WhenToCall911Modal.listOption4')}</li>
            <li>{t('shared:WhenToCall911Modal.listOption5')}</li>
          </ul>
          <p>{t('shared:WhenToCall911Modal.contentSectionThree')}</p>
        </section>
      </main>
    </BaseModal>
  );
};

WhenToCall911Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default WhenToCall911Modal;
