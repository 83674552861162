import {
  handleResponse,
  handleError,
  getHeaderInfo,
  getEndpoint,
} from './apiUtils';

export async function getBranding() {
  return fetch(
    getEndpoint('branding', { 'enable-experimental-features': 'true' }),
    getHeaderInfo(),
  )
    .then(handleResponse)
    .catch(handleError);
}
