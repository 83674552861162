/* eslint-disable no-restricted-syntax */
import { useQueryString } from 'hooks/useQueryString';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SeoImplementaion = props => {
  const {
    setFacilityPageTitle,
    setFacilityPageDescription,
    setFacilityPageDesc1,
    setFacilityPageDesc2,
    setType,
    setMediaContentBlockBottom,
    setMediaContentBlockTop,
    locationDetails,
    filteredResults,
    facilityResults,
    facilityServices,
    healthSystem,
    setMediaTitleTop
  } = props;
  const [t] = useTranslation();
  const { params, isDischargePage, urlParams } = useQueryString();
  useEffect(() => {
    if ((urlParams.facilityName !== undefined || urlParams.dischargeFacility) && !urlParams.locationPermalink) {
      setType('facility');
      if (
        t('FacilityPage_MediaContentBlock_Top') !== '' &&
        t('FacilityPage_MediaContentBlock_Top') !==
        'FacilityPage_MediaContentBlock_Top'
      ) {
        setMediaContentBlockTop(t('FacilityPage_MediaContentBlock_Top', { hs_name: healthSystem?.name, region_name: healthSystem?.region?.name, facility_name: facilityResults?.data ? facilityResults?.data[0]?.attributes?.name : '' }));
      }
      if (
        t('FacilityPage_MediaContentBlock_Bottom') !== '' &&
        t('FacilityPage_MediaContentBlock_Bottom') !==
        'FacilityPage_MediaContentBlock_Bottom'
      ) {
        setMediaContentBlockBottom(t('FacilityPage_MediaContentBlock_Bottom', { hs_name: healthSystem?.name, region_name: healthSystem?.region?.name, facility_name: facilityResults?.data ? facilityResults?.data[0]?.attributes?.name : '' }));
      }
      if (
        t('FacilityPage_MediaTitle_Top') !== '' &&
        t('FacilityPage_MediaTitle_Top') !==
        'FacilityPage_MediaTitle_Top'
      ) {
        setMediaTitleTop(t('FacilityPage_MediaTitle_Top', { hs_name: healthSystem?.name, region_name: healthSystem?.region?.name, facility_name: facilityResults?.data ? facilityResults?.data[0]?.attributes?.name : '' }));
      }
    }

    if (urlParams.locationPermalink !== undefined) {
      setType('location');
      if (
        t('LocationPage_MediaContentBlock_Top') !== '' &&
        t('LocationPage_MediaContentBlock_Top') !==
        'LocationPage_MediaContentBlock_Top'
      ) {
        setMediaContentBlockTop(t('LocationPage_MediaContentBlock_Top', {
          hs_name: healthSystem?.name,
          region_name: healthSystem?.region?.name,
          facility_name: facilityResults?.data ? facilityResults?.data[0]?.attributes?.name : '',
          location_name: locationDetails?.name,
          location_city: locationDetails?.city,
          location_state: locationDetails?.state,
          location_zip: locationDetails?.zip,
        }));
      }
      if (
        t('LocationPage_MediaContentBlock_Bottom') !== '' &&
        t('LocationPage_MediaContentBlock_Bottom') !==
        'LocationPage_MediaContentBlock_Bottom'
      ) {
        setMediaContentBlockBottom(t('LocationPage_MediaContentBlock_Bottom', {
          hs_name: healthSystem?.name,
          region_name: healthSystem?.region?.name,
          facility_name: facilityResults?.data ? facilityResults?.data[0]?.attributes?.name : '',
          location_name: locationDetails?.name,
          location_city: locationDetails?.city,
          location_state: locationDetails?.state,
          location_zip: locationDetails?.zip,
        }));
      }
      if (
        t('LocationPage_MediaTitle_Top') !== '' &&
        t('LocationPage_MediaTitle_Top') !==
        'LocationPage_MediaTitle_Top'
      ) {
        setMediaTitleTop(t('LocationPage_MediaTitle_Top', {
          hs_name: healthSystem?.name,
          region_name: healthSystem?.region?.name,
          facility_name: facilityResults?.data ? facilityResults?.data[0]?.attributes?.name : '',
          location_name: locationDetails?.name,
          location_city: locationDetails?.city,
          location_state: locationDetails?.state,
          location_zip: locationDetails?.zip,
        }));
      }
    }

    if (
      facilityResults.data !== undefined &&
      urlParams.locationPermalink !== undefined
    ) {
      if (facilityServices !== undefined && params?.service !== undefined) {
        setFacilityPageDesc1(
          facilityResults.data !== undefined
            ? `${facilityResults.data[0]?.attributes?.name} offers `
            : `${locationDetails.name} offers `,
        );
        setFacilityPageDesc2(
          ` in ${locationDetails.city}, ${locationDetails.state}. View available times and locations today.`,
        );
        setFacilityPageDescription(
          facilityResults.data !== undefined
            ? `${facilityResults.data[0]?.attributes?.name} offers ${facilityServices[params?.service]?.attributes?.name
            } in ${locationDetails.city}, ${locationDetails.state
            }. View available times and locations today.`
            : `${locationDetails.name} offers ${facilityServices[params?.service]?.attributes.name
            } in ${locationDetails.city}, ${locationDetails.state
            }. View available times and locations today.`,
        );
      }
      setFacilityPageTitle(
        `${locationDetails.name} - ${locationDetails.city}, ${locationDetails.state} - View Open Times`,
      );
    } else if (
      facilityResults.data !== undefined &&
      params?.service !== undefined &&
      facilityServices[params?.service] !== undefined
    ) {
      // set the service list after checking null values
      const serviceLineList = [];
      if (filteredResults[params?.service] !== undefined) {
        // eslint-disable-next-line no-restricted-syntax,
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(filteredResults)) {
          if (!isEmpty(value)) {
            serviceLineList.push(value[0]?.attributes?.service.name);
          }
        }
      }
      if (facilityServices !== undefined && params.service !== undefined) {
        setFacilityPageDesc1(
          `${facilityResults.data[0]?.attributes?.name} offers `,
        );
        setFacilityPageDesc2(`View available times and locations today.`);
        setFacilityPageDescription(
          `${facilityResults.data[0]?.attributes?.name
          } offers ${serviceLineList.toString()}. View available times and locations today.`,
        );
      }
      setFacilityPageTitle(
        `${facilityResults.data[0]?.attributes?.name} - View Open Times`,
      );
    }

    if (isDischargePage) {
      if (healthSystem.region !== null && healthSystem.region !== undefined) {
        setFacilityPageDescription(
          `Book an appointment online at ${healthSystem.region.name} - ${healthSystem.name}`,
        );
      } else {
        setFacilityPageDescription(
          `Book an appointment online at ${healthSystem.name}`,
        );
      }
    }
  });
};

SeoImplementaion.propTypes = {
  setFacilityPageTitle: PropTypes.func.isRequired,
  setFacilityPageDescription: PropTypes.func.isRequired,
  setFacilityPageDesc1: PropTypes.func.isRequired,
  setFacilityPageDesc2: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  setMediaContentBlockBottom: PropTypes.func.isRequired,
  setMediaContentBlockTop: PropTypes.func.isRequired,
  locationDetails: PropTypes.instanceOf(Object).isRequired,
  filteredResults: PropTypes.instanceOf(Object).isRequired,
  facilityResults: PropTypes.instanceOf(Object).isRequired,
  facilityServices: PropTypes.instanceOf(Object).isRequired,
  healthSystem: PropTypes.instanceOf(Object).isRequired,
  setMediaTitleTop: PropTypes.func.isRequired,
};

export default React.memo(SeoImplementaion);
