import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { geolocated } from 'react-geolocated';
import { useTranslation } from 'react-i18next';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import { useQueryString } from '../../hooks/useQueryString';
import CustomTextField from './CustomTextField';
import ZipcodeAutocompleteOptions from './ZipcodeAutocompleteOptions';
import {
  clearGeocoding,
  loadGeocoding,
  loadReverseGeocoding,
} from '../../redux/actions/geocodingActions';
import { param } from 'jquery';

const ZipcodeField = ({
  value,
  geocodingHasLoaded,
  isGeolocationEnabled,
  coords,
  clearGeocoding,
  loadGeocoding,
  loadReverseGeocoding,
  required,
  icon,
  filled,
  hasError,
  errorMessage,
  tabIndex,
  //handleOnBlur,
}) => {
  const [zipcodeField, setZipcodeField] = useState({ value });
  const [showAutocompleteOptions, setShowAutocompleteOptions] = useState(true);
  const GA = useGoogleAnalytics();
  const [t] = useTranslation();
  const { urlParams, params } = useQueryString();
  const updateGeocoding = async zipcode => {
    clearGeocoding(zipcode);
    loadGeocoding(zipcode);
  };

  useEffect(() => {
    if (value !== '' && value !== undefined) setZipcodeField({ value });
    if (
      value !== '' &&
      value !== undefined &&
      value !== localStorage.getItem('zipCode')
    )
      loadGeocoding(value);
  }, [value, urlParams.city]);

  useEffect(() => {
    if (isGeolocationEnabled && !geocodingHasLoaded) {
      navigator.geolocation.getCurrentPosition(function(position) {
        console.log(params.zip);
        console.log('Latitude is :', position.coords.latitude);
        console.log('Longitude is :', position.coords.longitude);
        const { latitude, longitude } = position.coords;
        if (
          params.zip === null ||
          params.zip === undefined ||
          params.zip === ''
        ) {
          loadReverseGeocoding(latitude, longitude);
          if (localStorage.getItem('zipCode') !== '') {
            setZipcodeField({ value: localStorage.getItem('zipCode') });
          }
        }
      });
    }
  }, [isGeolocationEnabled, geocodingHasLoaded]);

  const onKeyDown = e => {
    // const { keyCode } = e;
    // // <Esc> or <Tab> is pressed
    // if (keyCode === 27 || keyCode === 9) {
    //   setShowAutocompleteOptions(false);
    // } else {
    //   setShowAutocompleteOptions(true);
    // }
  };

  const handleOnBlur = e => {
    setShowAutocompleteOptions(true);
    updateGeocoding(e?.target?.value);
  };
  const handleOnChange = e => {
    setZipcodeField({ value: e?.zip?.value });
    updateGeocoding(e?.target?.value);
  };

  const onAutocompleteSelect = zipcode => {
    console.log(zipcode);
    setZipcodeField({ value: zipcode });
    updateGeocoding(zipcode);
    GA.event({ category: 'location', action: 'autocomplete' });
  };

  const formHandler = (newValue, oldValue) => {
    if (!newValue) {
      clearGeocoding('');
      updateGeocoding('');
      return;
    }
    //if a long value is autoloaded via browser location services.
    if (oldValue.length > 8) {
      clearGeocoding(' ');
      updateGeocoding(' ');
      return;
    }
    if (newValue.length > 8) return;

    const strippedValue = newValue.match('^[A-Za-z0-9- ]+$');
    if (!strippedValue) {
      clearGeocoding('');
      updateGeocoding('');
      return;
    }
    const setValue = strippedValue.join().substr(0, 8);
    updateGeocoding(setValue);
  };

  return (
    <div>
      <CustomTextField
        filled={filled}
        icon={icon}
        required={required}
        onFocus={() => setShowAutocompleteOptions(true)}
        onBlur={handleOnBlur}
        onKeyDown={onKeyDown}
        fieldKey="zip"
        fieldValue={zipcodeField}
        validateFormHandler={e => handleOnChange(e)}
        label={t('ZipcodeField.placeholder.zipCode')}
        hasError={hasError}
        errorMessage={errorMessage}
        tabIndex={tabIndex}
      />
      <ZipcodeAutocompleteOptions
        onSelect={onAutocompleteSelect}
        onBlur={() => setShowAutocompleteOptions(false)}
        onEscape={() => setShowAutocompleteOptions(false)}
        hidden={!showAutocompleteOptions}
        searchTerm={zipcodeField?.value}
        show
      />
    </div>
  );
};
ZipcodeField.defaultProps = {
  value: '',
  geocodingHasLoaded: false,
  coords: {},
  required: false,
  icon: null,
  filled: false,
  tabIndex: '0',
  //handleOnBlur: () => { },
};
ZipcodeField.propTypes = {
  icon: PropTypes.element,
  filled: PropTypes.bool,
  value: PropTypes.string,
  geocodingHasLoaded: PropTypes.bool,
  isGeolocationEnabled: PropTypes.bool.isRequired,
  coords: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  clearGeocoding: PropTypes.func.isRequired,
  loadGeocoding: PropTypes.func.isRequired,
  loadReverseGeocoding: PropTypes.func.isRequired,
  required: PropTypes.bool,
  tabIndex: PropTypes.string,
  handleOnBlur: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    //value: state.geocoding.zipcode,
    geocodingHasLoaded: state.geocoding.loaded,
    loading: state.apiCallsInProgress > 0,
  };
};

const mapDispatchToProps = {
  clearGeocoding,
  loadGeocoding,
  loadReverseGeocoding,
};

const connectedZipcodeField = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ZipcodeField);

const geolocatedZipcodeField = geolocated({
  positionOptions: {
    enableHighAccuracy: true,
  },
  userDecisionTimeout: 5000,
})(connectedZipcodeField);

export default geolocatedZipcodeField;
