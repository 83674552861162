import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from 'react-bootstrap/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import BrandingButton from 'components/common/BrandingButton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  actions: {
    boxShadow:
      '0px -2px 6px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  content: {
    paddingTop: '80px',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogMobile = ({
  content,
  handleShowDialog,
  icon,
  showDialog,
  title,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Dialog
        fullScreen
        open={showDialog}
        onClose={handleShowDialog}
        TransitionComponent={Transition}
      >
        <AppBar color="default">
          <Toolbar>
            {icon}
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <Button variant="light" onClick={() => handleShowDialog(false)}>
              {t('DialogMobile.cancel')}
            </Button>
          </Toolbar>
        </AppBar>

        <DialogContent className={classes.content}>{content}</DialogContent>

        <DialogActions className={classes.actions}>
          <BrandingButton
            style={{ padding: '15px 0' }}
            type="button"
            onClick={() => handleShowDialog(true)}
            block
          >
            {t('DialogMobile.applybutton')}
          </BrandingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

DialogMobile.defaultProps = {
  icon: null,
};

DialogMobile.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  handleShowDialog: PropTypes.func.isRequired,
  icon: PropTypes.element,
  showDialog: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default DialogMobile;
