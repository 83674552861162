import {
  handleResponse,
  handleError,
  getEndpoint,
  getHeaderInfo,
} from './apiUtils';

export const getAppointmentTypes = () => {
  return fetch(
    getEndpoint(`appointment-types`, { 'page[size]': 999 }),
    getHeaderInfo(),
  )
    .then(handleResponse)
    .catch(handleError);
};

export const getAppointmentTypePayments = (appId, scheduleId) => {
  const params = {
    schedule_id: scheduleId,
  };
  if (appId) {
    params.appointment_type_id = appId;
  }
  return fetch(getEndpoint(`payment/fees`, params), getHeaderInfo())
    .then(handleResponse)
    .catch(handleError);
};

export const getSettings = scheduleId => {
  return fetch(getEndpoint(`visit-settings/${scheduleId}`, {}), getHeaderInfo())
    .then(handleResponse)
    .catch(handleError);
};
