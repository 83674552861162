import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';

/**
 * Converts minutes to timestamp in HH:mm format + AM/PM.
 * @param {Number} mins Time in minutes since midnight.
 */
export const convertTime = mins => {
  const time = mins / 60;
  let hour = Math.floor(time);
  const minutes = Math.round((time - hour) * 60);
  let meridiem = 'AM';

  if (mins >= 720) {
    hour -= 12;
    meridiem = 'PM';
  }

  if (hour === 0) hour = 12;

  return `${hour}:${!minutes ? '00' : minutes} ${meridiem}`;
};

/**
 * Takes a string (likely from query string) and parse into start/end values.
 * @param {string} timestamp Query string timestamp value.
 */
export const parseTimestamp = (timestamp, timezone = null) => {
  const date = moment(timestamp)
    .tz(timezone)
    .format();
  if (date) {
    return {
      end: moment(date)
        .tz(timezone)
        .add(1, 'days')
        .format(),
      start: moment(date)
        .tz(timezone)
        .format(),
    };
  }

  const times = timestamp ? timestamp.split('-') : [];
  if (!isEmpty(times)) {
    const today = moment()
      .tz(timezone)
      .format('YYYY-MM-DD');
    return {
      end: moment(`${today} ${times[1]}`).format('HH:mm'),
      start: moment(`${today} ${times[0]}`).format('HH:mm'),
    };
  }

  // By default, we'll start with current date + 1 day.
  return {
    end: moment()
      .tz(timezone)
      .add(1, 'days')
      .format(),
    start: moment()
      .tz(timezone)
      .format(),
  };
};

/**
 * Takes a date + numberofDays, and timezone and returns a set of days.
 * @param {string} startDateCal Datetime string/object
 * @param {Number} numberOfDays Number of days to add to the initial date
 * @param {string} timezone Timezone string
 */
export const groupedDays = (startDateCal, numberOfDays, timezone = null) => {
  const result = [];
  if (startDateCal) {
    let startDate = startDateCal;
    typeof startDateCal === 'object'
      ? (startDate = moment(startDateCal.start))
      : (startDate = moment(startDateCal));

    if (timezone) startDate.tz(timezone);
    for (let i = 0; i < numberOfDays; i += 1) {
      const d = startDate.clone();
      result.push(d.add(i, 'days'));
    }
  }
  return result;
};

export const startOfDayReset = (inputString) => {
  // Replace the numbers between "T" and "-" with "0"
  const outputString = inputString.replace(/T\d+:\d+:\d+/, match => {
    return match.replace(/\d+/g, '00');
  });
  return outputString
}

