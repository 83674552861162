import * as types from './actionTypes';
import * as insurancePlanApi from '../../api/insurancePlanApi';
import { beginApiCall, apiCallError } from './apiStatusActions';

export function loadInsurancePlansSuccess(insurancePlans) {
  return { type: types.FETCH_INSURANCE_PLANS_SUCCESS, insurancePlans };
}

let insurancePlanApiCall = false;
export function loadInsurancePlans() {
  // eslint-disable-next-line func-names
  return async function (dispatch) {
    dispatch(beginApiCall());
    try {
      if (!insurancePlanApiCall) {
        const insurancePlans = await insurancePlanApi.getInsurancePlans();
        dispatch(loadInsurancePlansSuccess(insurancePlans));
      }
      insurancePlanApiCall = false;
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}