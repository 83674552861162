import React from 'react';
import PropTypes from 'prop-types';
import './InventoryFilter.scss';
import { LinearProgress, Typography } from '@material-ui/core';

const InventoryFilterGroup = ({ title, loading, children }) => {
  return (
    <div className="filter">
      <Typography
        variant="h6"
        component="h3"
        style={{
          fontSize: '1rem',
          fontWeight: 'bold',
        }}
      >
        {title}
      </Typography>
      {loading ? <LinearProgress /> : children}
    </div>
  );
};

InventoryFilterGroup.defaultProps = {
  title: null,
  loading: false,
  children: null,
};

InventoryFilterGroup.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
};

export default InventoryFilterGroup;
