/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import i18next from 'i18next';
import './SwitchLanguageFooter.scss';

const SwitchLanguageFooter = props => {
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem('i18nextLng') === 'en'
      ? 'en-US'
      : localStorage.getItem('i18nextLng'),
  );

  const changeLanguage = (e, lang) => {
    e.preventDefault();
    e.stopPropagation();
    setCurrentLang(lang);
    i18next.changeLanguage(lang);
    window.location.reload();
  };
  // eslint-disable-next-line react/prop-types
  const { supportedLocales } = props;

  useEffect(() => {
    if (!!supportedLocales && supportedLocales.length === 1) {
      i18next.changeLanguage(supportedLocales[0].code);
    }
  }, []);
  return (
    <>
      {!!supportedLocales && supportedLocales.length > 1 && (
        <div className="container">
          <Container className="lngContainer align-center">
            <Row>
              <Col xs={12}>
                <div className="footer-links">
                  {supportedLocales.map(
                    (lng, i) =>
                      lng.isDeleted === false && (
                        <div role="region" aria-label="SwitchLanguageFooter"
                          key={i}
                          className={
                            currentLang === lng.code
                              ? 'fl-link active'
                              : 'fl-link'
                          }
                        >
                          {currentLang === lng.code ? (
                            <span className="anchorLng">{lng.name}</span>
                          ) : (
                            <a
                              href="#"
                              className="anchorLng"
                              onClick={e => changeLanguage(e, lng.code)}
                            >
                              {lng.name}
                            </a>
                          )}
                        </div>
                      ),
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default SwitchLanguageFooter;
