import * as types from './actionTypes';
import * as api from '../../api/authApi';
import {
  beginApiAuth,
  beginApiCall,
  apiCallError,
  apiAuthError,
} from './apiStatusActions';

export function loadApiTokenSuccess(token) {
  return { type: types.FETCH_AUTH_TOKEN_SUCCESS, token };
}

export function apiAuthSuccess() {
  return { type: types.API_AUTH_SUCCESS, apiAuthSuccess: true };
}

export function loadApiToken() {
  // eslint-disable-next-line func-names
  return async function(dispatch) {
    dispatch(beginApiAuth());
    dispatch(beginApiCall());
    try {
      const tokenResponse = await api.getAuthToken();
      dispatch(loadApiTokenSuccess(tokenResponse));
      dispatch(apiAuthSuccess());
    } catch (error) {
      dispatch(apiCallError(error));
      dispatch(apiAuthError(error));
      throw error;
    }
  };
}
