import React, { createContext, useState, useEffect, useCallback } from 'react';
import { isEmpty } from 'lodash';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import * as api from '../../api/brandingApi';

export const BrandingContext = createContext();

const BrandingContextProvider = props => {
  const [branding, setBranding] = useState({
    logoImage: '',
    logoUrl: null,
    primaryColor: '',
    secondaryColor: '',
    primaryTextColor: '',
    secondaryTextColor: '',
    buttonColor: '',
    buttonTextColor: '',
    mastheadColor: '',
    noAvailabilityCustomMessage: '',
  });
  const [t] = useTranslation();
  const topMedicalCare = t('BrandingContext.topMedicalCare');
  const topSpecialityServices = t('BrandingContext.topSpecialityServices');
  const [labels, setLabels] = useState({});
  const [brandingResponse, setBrandingReponse] = useState();
  const defaultLabels = {
    en: {
      'homepage-left-label': topMedicalCare,
      'homepage-right-label': topSpecialityServices,
    },
  };

  i18next.on('loaded', loaded => {
    if (Object.keys(loaded).length > 0) {
      setLabels({
        en: {
          'homepage-left-label': t('BrandingContext.topMedicalCare'),
          'homepage-right-label': t('BrandingContext.topSpecialityServices'),
        },
      });
    }
  });

  const fetchBranding = useCallback(async () => {
    const response = await api.getBranding();
    if (response && !isEmpty(response.data)) {
      const br = response.data[0].attributes;
      setTimeout(() => {
        setBranding({
          logoImage: br['logo-image'],
          logoUrl: br['logo-url'],
          primaryColor: br['primary-color'],
          secondaryColor: br['secondary-color'],
          primaryTextColor: br['primary-text-color'],
          secondaryTextColor: br['secondary-text-color'],
          buttonColor: br['button-color'],
          buttonTextColor: br['button-text-color'],
          mastheadColor: br['masthead-color'],
          noAvailabilityCustomMessage: br['no-availability-custom-message'],
          labels: isEmpty(br.experimental)
            ? defaultLabels
            : br.experimental.labels,
        });
        setBrandingReponse(br);
      }, 500);
    }
  }, []);

  useEffect(() => {
    fetchBranding();
  }, []);

  useEffect(() => {
    if (
      brandingResponse &&
      Object.keys(brandingResponse).length > 0 &&
      Object.keys(labels).length > 0
    ) {
      const br = brandingResponse;
      setBranding({
        logoImage: br['logo-image'],
        logoUrl: br['logo-url'],
        primaryColor: br['primary-color'],
        secondaryColor: br['secondary-color'],
        primaryTextColor: br['primary-text-color'],
        secondaryTextColor: br['secondary-text-color'],
        buttonColor: br['button-color'],
        buttonTextColor: br['button-text-color'],
        mastheadColor: br['masthead-color'],
        labels: isEmpty(br.experimental) ? labels : br.experimental.labels,
        noAvailabilityCustomMessage: br['no-availability-custom-message'],
      });
    }
  }, [labels, brandingResponse]);

  return (
    <BrandingContext.Provider value={{ branding }}>
      {props.children}
    </BrandingContext.Provider>
  );
};

export default BrandingContextProvider;
