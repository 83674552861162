import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import useSettings from 'hooks/useSettings';

const PickerDate = ({ handleChange, timezone, timestamp, scheduleId }) => {
  moment.tz.setDefault(timezone);
  const [selectedDate, handleDateChange] = useState(
    moment(timestamp)
      .tz(timezone)
      .format(),
  );

  const availableScheduleDays = useSettings({ scheduleId });

  const today = moment()
    .tz(timezone)
    .format();

  const maxDate = moment(today)
    .tz(timezone)
    .add(Number(availableScheduleDays) - 1, 'days')
    .toString();

  useEffect(() => {
    let selectedDateNew = '';
    if (moment().diff(selectedDate) < 0) {
      selectedDateNew = moment(selectedDate)
        .tz(timezone)
        .startOf('day')
        .format();
    } else {
      selectedDateNew = moment(selectedDate)
        .tz(timezone)
        .format();
    }
    const result = {
      end: moment(selectedDateNew)
        .tz(timezone)
        .format(),
      start: moment(selectedDateNew)
        .tz(timezone)
        .format(),
    };
    handleChange(result);
  }, [selectedDate]);

  return (
    <>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <DatePicker
          disableToolbar
          disablePast
          variant="static"
          label="Only calendar"
          helperText="No year selection"
          value={selectedDate}
          onChange={handleDateChange}
          minDate={today}
          maxDate={maxDate}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

PickerDate.defaultProps = {
  timezone: '',
  timestamp: '',
  scheduleId: ''
};

PickerDate.propTypes = {
  handleChange: PropTypes.func.isRequired,
  timezone: PropTypes.string,
  timestamp: PropTypes.string,
  scheduleId: PropTypes.string,
};

export default PickerDate;
