import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import DateFields from '../DateFields';

const BirthDateFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const [t] = useTranslation();
  return (
    <>
      {formContext.isFieldEnabled('birthdate') && (
        <div className="form-section_row">
          <DateFields
            errorMessage={formContext.getError('birthdate')}
            handleDateChange={e => {
              formContext.handleSetFormState({
                birthdate: {
                  value: `${e.day.value}/${e.month.value}/${e.year.value}`,
                },
              });
            }}
            hasError={formContext.hasError('birthdate')}
            label={t('BirthDateFields.dateOfBirth')}
            required={formContext.isFieldRequired('birthdate')}
          />
        </div>
      )}
    </>
  );
};

export default BirthDateFields;
