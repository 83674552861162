import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import QueryString from 'query-string';
import { getLocationById } from 'redux/reducers/providersReducer';
import './Appointment.scss';

/**
 * Displays a button that links to a reg form.
 */
const Appointment = ({
  link,
  location,
  time,
  outline,
  timezoneName,
  providerName,
  inactive,
}) => {
  if (!link || link === '') return <></>;
  if (!time || time === '') return <></>;
  if (!timezoneName || timezoneName === '') return <></>;
  if (!moment(time).isValid()) return <></>;
  const params = QueryString.parseUrl(link);
  // This allows us to 'carry forward' existing params, if supplied.
  const apptParams = {
    ...params.query,
    ...{
      date: moment(time)
        .tz(timezoneName)
        .format(),
    },
  };
  const apptQueryString = QueryString.stringify({
    ...apptParams,
    date: moment(time)
      .tz(timezoneName)
      .format(),
  });
  const apptLink =
    inactive === false ? `${params.url}?${apptQueryString}` : null;

  const getProviderName = () =>
    providerName !== '' ? ` with ${providerName}` : ``;

  const getLocation = () => {
    if (location && location.attributes) {
      return ` located at ${location.attributes.address}, ${location.attributes.city}, ${location.attributes.state} ${location.attributes.zip}`;
    }
    return '';
  };
  const buttonClass = `btn-default${outline ? '-outline' : ''}`;

  return (
    <Link
      to={apptLink}
      className={inactive ? 'AppointmentDisabled' : 'Appointment'}
      style={{
        textDecoration: 'none',
      }}
      aria-label={`Link to make an appointment${getProviderName()} on ${moment(
        time,
      )
        .tz(timezoneName)
        .format('MMMM DD YYYY')} at ${moment(time)
        .tz(timezoneName)
        .format('h:mm a')}${getLocation()}`}
    >
      <button type="button" className={`${buttonClass} appointment-button`}>
        {moment(time)
          .tz(timezoneName)
          .format('h:mm a')}
      </button>
    </Link>
  );
};

Appointment.defaultProps = {
  outline: false,
  providerName: '',
  location: {},
  inactive: false,
};

Appointment.propTypes = {
  /** Already-generated link to registration page. */
  link: PropTypes.string.isRequired,
  /** format: 2020-02-21T12:00:00-05:00 */
  time: PropTypes.string.isRequired,
  /**  Optional style override button to 'outline' version */
  outline: PropTypes.bool,
  providerName: PropTypes.string,
  timezoneName: PropTypes.string.isRequired,
  location: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state, props) => ({
  location:
    (props.locationId !== '' && getLocationById(state, props.locationId)) || {},
});

export default connect(mapStateToProps)(Appointment);
