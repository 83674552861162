import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { Grid } from '@material-ui/core';
import { BrandingContext } from 'components/contexts/BrandingContext';
import EducationalAlert from 'components/educational/EducationalAlert';
import EducationalKeywords from 'components/educational/EducationalKeywords';
import EducationalScheduleCard from 'components/educational/EducationalScheduleCard';
import { getEndpoint, getHeaderInfo } from 'api/apiUtils';
import EducationalResultSkeleton from 'components/educational/EducationalResultSkeleton';
import { Alert } from 'react-bootstrap';
import './EducationalResult.scss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useQueryString } from 'hooks/useQueryString';

const EducationalNowResult = ({
  venue,
  coord,
  insuranceId,
  appointmentTypeId,
}) => {
  const [t] = useTranslation();
  const { branding } = useContext(BrandingContext);
  const [schedule, setSchedule] = useState({});
  const [location, setLocation] = useState({});
  const [provider, setProvider] = useState({});
  const [loading, setLoading] = useState(true);
  const healthSystem = useSelector(state => state.healthSystem);
  const { isDischargePage } = useQueryString();
  useEffect(() => {
    if (!isEmpty(venue)) {
      (async () => {
        setLoading(true);
        /**
         * Check Availability, From and To are params added to trigger
         * search override on the API side. This is the same call that is
         * used on 'Inventory' searches but the times are hardcoded.
         * This assumption is made given the immediacy of the use case/view.
         */
        const searchOptions = {
          'filter[context]': 'patient_now',
          'filter[radius]': venue.attributes.searchDistanceRadius,
          'filter[venue_of_care]': venue.id,
          include: 'location,provider,service,insurance-plans',
          from: moment()
            .tz(healthSystem['time-zone'])
            .format(),
          to: moment()
            .tz(healthSystem['time-zone'])
            .add(1, 'days')
            .format(),
          check_availability: 'true',
        };

        if (coord) searchOptions['filter[near]'] = coord;
        if (insuranceId) searchOptions['filter[insurance]'] = insuranceId;
        if (venue.attributes.educationalNowDisplayOrder === 'next_available')
          searchOptions.sort = 'soonest';
        if (venue.attributes.venueType === 'physician_services')
          searchOptions['filter[appointment_type]'] = appointmentTypeId;

        const response = await fetch(
          getEndpoint(`schedules`, searchOptions),
          getHeaderInfo(),
        );
        const results = await response.json();
        if (!isDischargePage) {
          const actualLength = results?.data?.length;
          const filteredData = results?.data?.filter(
            d => d.attributes?.hidden !== true,
          );
          results.data = filteredData;
          const RECORD_COUNT = 'record-count';
          if (results?.meta)
            results.meta[RECORD_COUNT] =
              results?.meta?.['record-count'] -
              (actualLength - filteredData?.length);
        }
        if (!isEmpty(results?.data)) {
          const schedule = results.data[0];

          setLocation(
            results.included.find(
              obj =>
                obj.type === 'locations' &&
                obj.id === schedule.relationships.location.data.id,
            ),
          );

          setProvider(
            results.included.find(
              obj =>
                obj.type === 'providers' &&
                obj?.id === schedule?.relationships?.provider?.data?.id,
            ),
          );

          setSchedule(schedule);
        }
        setLoading(false);
      })();
    }
    return function cleanup() {
      setLoading(true);
      setSchedule({});
    };
  }, [venue, coord, appointmentTypeId, insuranceId]);

  if (isEmpty(venue)) return <></>;

  return (
    <div className="EducationalResult">
      <EducationalAlert venue={venue} />
      <Grid
        container
        direction="row"
        spacing={2}
        alignItems="flex-start"
        style={{ marginBottom: '30px' }}
      >
        <Grid item xs={12} sm={4}>
          {!isEmpty(venue) && (
            <EducationalKeywords
              title={venue.attributes.displayName}
              keywords={venue.attributes.educationalMessage}
            />
          )}
        </Grid>

        {loading && <EducationalResultSkeleton />}
        {!loading &&
          ((!appointmentTypeId &&
            venue.attributes.venueType === 'physician_services') ||
            (isEmpty(schedule) && appointmentTypeId)) && (
            <Grid item xs={12} sm={8}>
              <Alert
                style={{
                  background: branding.secondaryColor,
                  color: branding.primaryColor,
                }}
              >
                {isEmpty(schedule) && appointmentTypeId && (
                  <>
                    <Alert.Heading>{t('ResultList.weTried')}</Alert.Heading>
                    {t('ResultList.noResult')}
                  </>
                )}
                {!appointmentTypeId &&
                  venue.attributes.venueType === 'physician_services' && (
                    <>
                      <Alert.Heading>
                        {t('ResultList.selectAppointmentType')}
                      </Alert.Heading>
                    </>
                  )}
              </Alert>
            </Grid>
          )}
        {!loading &&
          (!isEmpty(schedule) ||
            (!appointmentTypeId &&
              venue.attributes.venueType != 'physician_services' &&
              !(
                !isEmpty(schedule) &&
                !appointmentTypeId &&
                venue.attributes.venueType === 'physician_services'
              ))) && (
            <Grid item xs={12} sm={8}>
              <EducationalScheduleCard
                schedule={schedule}
                location={location}
                provider={provider}
                venueType={venue.attributes.venueType}
                appointmentType={appointmentTypeId}
              />
            </Grid>
          )}
      </Grid>
    </div>
  );
};

EducationalNowResult.defaultProps = {
  insuranceId: '',
  coord: '',
};

EducationalNowResult.propTypes = {
  venue: PropTypes.instanceOf(Object).isRequired,
  insuranceId: PropTypes.string,
  appointmentTypeId: PropTypes.string.isRequired,
  coord: PropTypes.string,
};

export default EducationalNowResult;
