import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { chunk, uniq, isEmpty, isUndefined } from 'lodash';
import QueryString from 'query-string';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useLocation, useHistory } from 'react-router-dom';
import Markdown from 'react-markdown/with-html';
import { loadProvider } from 'redux/actions/providerActions';
import { loadAppointmentTypes } from 'redux/actions/appointmentTypesActions';
import {
  getProviderById,
  getProviderLocationsById,
  getProviderSchedulesById,
} from 'redux/reducers/providersReducer';
import { getEndpoint, getHeaderInfo } from 'api/apiUtils';
import { getDefaultApptType } from 'utils/getDefaultApptType';
import { clearSearch } from 'redux/actions/searchActions';
import { usePageTitle } from 'hooks/usePageTitle';
import { useQueryString } from 'hooks/useQueryString';
import Phone from 'components/common/Phone';
import PageNotFound from 'components/PageNotFound';
import ProviderDetailSidebar from 'components/provider/ProviderDetailSidebar';
import ProviderLocationMobile from 'components/provider/ProviderLocationMobile';
import Provider from 'components/provider/Provider';
import ProviderEducation from 'components/provider/ProviderEducation';
import ProviderMedia from 'components/provider/ProviderMedia';
import ProviderNoAvailability from 'components/provider/ProviderNoAvailability';
import { BrandingContext } from 'components/contexts/BrandingContext';
import BreadCrumbs from 'components/common/BreadCrumbs';
import 'components/provider/ProviderDetailPage.scss';
import { useTranslation } from 'react-i18next';
import PageMetaInfo from '../common/PageMetaInfo';

const ProviderBackground = ({ header, description, branding }) => (
  <>
    {header && description && (
      <>
        <h2
          className="section-title"
          style={{
            color: branding.primaryColor,
            borderBottomColor: branding.primaryColor,
          }}
        >
          {header}
        </h2>
        <div>
          <p className="section-description">{description}</p>
        </div>
      </>
    )}
  </>
);

ProviderBackground.defaultProps = {
  description: null,
  header: null,
};

ProviderBackground.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  branding: PropTypes.instanceOf(Object).isRequired,
};

const ProviderLocation = ({ address, city, state, zip, name, number }) => (
  <Grid container item>
    <div>
      <p>{address}</p>
      <p>
        {city}, {state} {zip}
      </p>
      <Phone name={name} phoneNumber={number} />
    </div>
  </Grid>
);

ProviderLocation.propTypes = {
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
};

const ProviderDetail = ({
  provider,
  locations,
  schedules,
  loadProvider,
  match,
  loading,
  providerNotFound,
  defaultAppointmentTypes,
  loadAppointmentTypes,
}) => {
  usePageTitle(provider ? provider.attributes.name : '');
  const theme = useTheme();
  const { isDischargePage } = useQueryString();
  const { branding } = useContext(BrandingContext);
  const [t] = useTranslation();
  const [providerInsurancePlans, setProviderInsurancePlans] = useState([]);
  const [appointmentTypeParam, setAppointmentTypeParam] = useState('');

  const history = useHistory();
  const { search, pathname } = useLocation();
  const params = QueryString.parse(search);
  const hasAvailability = !isEmpty(appointmentTypeParam);
  const appointmentTypePermalink = !isUndefined(params.appointment_type)
    ? params.appointment_type
    : '';
  useEffect(() => {
    if (!isUndefined(params.appointment_type)) {
      loadAppointmentTypes();
    }
  }, [appointmentTypePermalink]);
  useEffect(() => {
    if (!provider && !loading && !providerNotFound) {
      if (isDischargePage) {
        loadProvider(match.params.id, match.params.permalink, 'discharge');
      } else {
        loadProvider(match.params.id, match.params.permalink);
      }
    }

    if (provider && !loading) {
      const defaultAppointmentType = getDefaultApptType(
        provider.attributes.appointmentTypes,
        params.appointmentTypes,
      );
      if (defaultAppointmentType) {
        setAppointmentTypeParam(defaultAppointmentType.toString());
      }

      (async () => {
        const activeSchedules = [];
        const getInsurancePlans = async scheduleId => {
          const response = await fetch(
            getEndpoint(`schedules/${scheduleId}/insurance-plans`, {}),
            getHeaderInfo(),
          );
          const results = await response.json();
          return results.data || [];
        };

        /* eslint-disable-next-line no-restricted-syntax */
        for (const schedule of schedules) {
          if (schedule.attributes.active) {
            activeSchedules.push(getInsurancePlans(schedule.id));
          }
        }

        if (activeSchedules.length) {
          const elements = await Promise.all(activeSchedules);
          setProviderInsurancePlans(
            elements.reduce((acc, cur) => [...acc, ...cur], []),
          );
        }
      })();
    }
  }, [provider, loading, match, loadProvider, providerNotFound]);

  if (providerNotFound) return <PageNotFound />;
  if (!provider || isEmpty(provider)) return <div className="jumbotron" />;
  const getAppointmentTypeId = () => {
    if (!isUndefined(params.appointment_type)) {
      const appType = defaultAppointmentTypes.find(
        obj => obj.attributes.permalink === params.appointment_type,
      );
      return appType?.id || null;
    } else return null;
  };

  const providerData = provider.attributes;
  let pageDescription = '';
  let pageTitle = '';
  if (
    providerData.suffix !== null &&
    providerData.suffix !== undefined &&
    providerData.suffix !== ''
  ) {
    pageTitle = `${providerData.name}, ${providerData.suffix}`;
    pageDescription =
      'Book an Appointment online with ' +
      providerData.name +
      ', ' +
      providerData.suffix +
      ' today. Enjoy the control and convenience of online scheduling today.';
  } else {
    pageTitle = providerData.name;
    pageDescription =
      'Book an Appointment online with ' +
      providerData.name +
      ' today. Enjoy the control and convenience of online scheduling today.';
  }
  const renderBackgrounds = backgrounds => {
    const lang = backgrounds.find(bg => bg.name === 'Language Spoken');
    let translatedLanguages = {};
    if (lang) {
      const languages = lang.description.split(/\r?\n/);
      const locizeLanguages = languages.map(language => {
        return t(
          `languages:${language.charAt(0).toUpperCase() + language.slice(1)}`,
        );
      });
      translatedLanguages = {
        name: 'Language Spoken',
        description: locizeLanguages.join('\r\n'),
      };
    }
    const aff = backgrounds.find(bg => bg.name === 'Affiliation');
    const headerMap = {
      'Language Spoken': t('ProviderDetailPage.languageSpoken'),
      Affiliation: t('ProviderDetailPage.hospitalsAffiliation'),
    };
    return (
      <>
        <ProviderEducation metadata={backgrounds} />
        {translatedLanguages ? (
          <ProviderBackground
            key={`background-${translatedLanguages.name}`}
            header={headerMap[translatedLanguages.name]}
            name={translatedLanguages.name}
            description={translatedLanguages.description}
            branding={branding}
          />
        ) : (
          <h2
            className="section-title"
            style={{
              color: branding.primaryColor,
              borderBottomColor: branding.primaryColor,
            }}
          >
            {t('ProviderDetailPage.languageSpoken')}
          </h2>
        )}
        {aff ? (
          <ProviderBackground
            key={`background-${aff.name}`}
            header={headerMap[aff.name]}
            name={aff.name}
            description={aff.description}
            branding={branding}
          />
        ) : (
          <h2
            className="section-title"
            style={{
              color: branding.primaryColor,
              borderBottomColor: branding.primaryColor,
            }}
          >
            {t('ProviderDetailPage.hospitalsAffiliation')}
          </h2>
        )}
      </>
    );
  };

  const insuranceCompanies = uniq(
    providerInsurancePlans.map(ip => ip.attributes.name),
  );
  const renderInsuranceCompanies = insuranceCompanies =>
    chunk(insuranceCompanies, Math.ceil(insuranceCompanies.length / 2)).map(
      companyNameGroup => {
        return (
          <Grid item sm={6} key={companyNameGroup}>
            <ul className="insurance-list">
              {companyNameGroup.map(companyName => (
                <li key={`company-name-${companyName}`}>{companyName}</li>
              ))}
            </ul>
          </Grid>
        );
      },
    );

  console.log(params);
  const updateQueryString = e => {
    setAppointmentTypeParam(e.appointmentTypes);
    let search = '';
    if (params.appointment_type !== undefined) {
      search = QueryString.stringify({
        ...e,
      });
    } else {
      search = QueryString.stringify({ ...params, ...e });
    }
    history.replace({ pathname, search });
  };

  return (
    <article className="ProviderDetailPage">
      {/* <PageMetaInfo title={pageTitle} description={pageDescription} /> */}
      <div className="container">
        <BreadCrumbs
          links={[
            {
              url: `/providers/${provider.id}`,
              text: providerData.name,
            },
          ]}
        />
      </div>
      <div className="container">
        <Grid container>
          <Grid item xs={12} sm={isMobile ? 12 : 7}>
            <main>
              <Grid container alignItems="flex-start" className="mt-3">
                <Grid item xs={12}>
                  <Provider
                    noLink
                    provider={provider}
                    locations={locations}
                    hideSchedule
                    servicePermalink={
                      !isNaN(params.service) === true
                        ? params?.serviceName
                        : params.service
                    }
                    schedules={schedules}
                  />
                </Grid>
              </Grid>

              {isMobile && (
                <div className="">
                  {hasAvailability ? (
                    <ProviderLocationMobile
                      locations={locations}
                      provider={provider}
                      insurancePlans={providerInsurancePlans}
                      appointmentTypeParam={
                        !isUndefined(params.appointment_type)
                          ? getAppointmentTypeId() == null
                            ? appointmentTypeParam
                            : getAppointmentTypeId()
                          : appointmentTypeParam
                      }
                      updateQueryString={updateQueryString}
                      servicePermalink={
                        !Number.isNaN(params.service) === true
                          ? params?.serviceName
                          : params.service
                      }
                      showAllAvailabilityLink
                      nowContext={
                        !isUndefined(params.nowContext) &&
                        params.nowContext === 'true'
                      }
                    />
                  ) : (
                    <ProviderNoAvailability />
                  )}
                </div>
              )}

              <h2
                className="section-title"
                style={{
                  color: branding.primaryColor,
                  borderBottomColor: branding.primaryColor,
                }}
              >
                {t('ProviderDetailPage.background')}
              </h2>
              <div style={{ paddingRight: '5rem' }}>
                <Markdown escapeHtml={false} source={providerData.bio} />
              </div>

              {renderBackgrounds(providerData.backgrounds)}
              {providerData?.embedCode && providerData.embedCode !== '' && (
                <ProviderMedia embedCode={providerData.embedCode} />
              )}
              {insuranceCompanies && (
                <>
                  <h2
                    className="section-title"
                    style={{
                      color: branding.primaryColor,
                      borderBottomColor: branding.primaryColor,
                    }}
                  >
                    {t('PhysicianForm.insurance')}
                  </h2>

                  <div style={{ marginBottom: '3rem', paddingRight: '5rem' }}>
                    <Grid container spacing={3}>
                      {renderInsuranceCompanies(insuranceCompanies)}
                    </Grid>
                  </div>
                </>
              )}
            </main>
          </Grid>
          {!isMobile && (
            <Grid item sm={5} className="sidebar hide-mobile">
              <aside>
                {hasAvailability ? (
                  <ProviderDetailSidebar
                    nowContext={
                      !isUndefined(params.nowContext) &&
                      params.nowContext === 'true'
                    }
                    provider={provider}
                    insurancePlans={providerInsurancePlans}
                    appointmentTypeParam={
                      !isUndefined(params.appointment_type)
                        ? getAppointmentTypeId() == null
                          ? appointmentTypeParam
                          : getAppointmentTypeId()
                        : appointmentTypeParam
                    }
                    updateQueryString={updateQueryString}
                    servicePermalink={
                      !isNaN(params.service) === true
                        ? params?.serviceName
                        : params.service
                    }
                  />
                ) : (
                  <ProviderNoAvailability />
                )}
              </aside>
            </Grid>
          )}
        </Grid>
      </div>
    </article>
  );
};

const mapStateToProps = (state, props) => ({
  provider: getProviderById(
    state,
    props.match.params.id,
    props.match.params.permalink,
  ),
  schedules: getProviderSchedulesById(
    state,
    props.match.params.id,
    props.match.params.permalink,
  ),
  locations: getProviderLocationsById(
    state,
    props.match.params.id,
    props.match.params.permalink,
  ),
  loading: state.providers.meta.providerIsLoading,
  providerNotFound: state.providers.meta.providerNotFound,
  defaultAppointmentTypes: state.appointmentTypes.data,
});

const mapDispatchToProps = {
  loadProvider,
  clearSearch,
  loadAppointmentTypes,
};

ProviderDetail.propTypes = {
  provider: PropTypes.instanceOf(Object),
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        name: PropTypes.string,
        latitude: PropTypes.number,
        longitude: PropTypes.number,
      }),
    }),
  ).isRequired,
  schedules: PropTypes.instanceOf(Array).isRequired,
  loadProvider: PropTypes.func.isRequired,
  match: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool,
  providerNotFound: PropTypes.bool,
  defaultAppointmentTypes: PropTypes.instanceOf(Array),
  loadAppointmentTypes: PropTypes.func,
};

ProviderDetail.defaultProps = {
  loading: false,
  provider: null,
  providerNotFound: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderDetail);
