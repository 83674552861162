import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BrandingContext } from 'components/contexts/BrandingContext';
import { createEvent } from 'api/analyticsApi';
import 'components/inventory/InventoryTabs.scss';

const InventoryTabs = ({
  activeTab,
  getVenueForTab,
  searchType,
  setActiveTab,
  countPS,
  countUC,
  countER,
}) => {
  const history = useHistory();
  const { search, pathname } = useLocation();
  const params = QueryString.parse(search);
  const { branding } = useContext(BrandingContext);
  const useStyles = makeStyles({
    activeTab: {
      color: branding.primaryColor,
      border: `1px solid ${branding.primaryColor}`,
      borderBottomColor: '#fff',
      background: 'transparent',
      cursor: 'text',
      fontWeight: 'bold',
      '&:hover': { cursor: 'text' },
    },
    tab: {
      color: branding.primaryColor,
      '&:hover': {
        backgroundColor: branding.primaryColor,
        color: branding.secondaryTextColor,
      },
    },
  });
  const classes = useStyles();

  const countForTab = g => {
    switch (g) {
      case 'physician_services':
        return countPS;
      case 'urgent_care':
        return countUC;
      case 'emergency_room':
        return countER;
      default:
        return countPS;
    }
  };

  const renderTab = g => {
    const handleClick = e => {
      e.preventDefault();
      const tab = g;
      const search = QueryString.stringify({ ...params, tab });
      history.replace({
        pathname,
        search,
      });
      setActiveTab(g);
      const voc_value = getVenueForTab(g);
      const result_count = countForTab(g);
      const a = postEventData(params, voc_value, result_count);
    };
    const venue = getVenueForTab(g);
    const length = countForTab(g);
    if (venue)
      return (
        <Link key={`venue-${g}`} to={`/search?tab=${g}`} onClick={handleClick}>
          <div
            className={`tab-item ${
              activeTab === g ? classes.activeTab : classes.tab
            }`}
          >
            {`${venue.attributes.displayName} (${length})`}
          </div>
        </Link>
      );
    return <React.Fragment key={`venue-${g}`} />;
  };

  const postEventData = async (params, voc_value, result_count) => {
    await createEvent(params, voc_value, result_count);
  };

  return (
    <div
      className="InventoryTabs hide-mobile"
      style={{
        borderBottom: `1px solid ${branding.primaryColor}`,
      }}
    >
      {renderTab('physician_services')}
      {searchType === 'now' && renderTab('urgent_care')}
      {searchType === 'now' && renderTab('emergency_room')}
    </div>
  );
};

InventoryTabs.defaultProps = {
  activeTab: '',
  searchType: 'now',
};

InventoryTabs.propTypes = {
  activeTab: PropTypes.string,
  searchType: PropTypes.string,
  getVenueForTab: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  countPS: PropTypes.number.isRequired,
  countUC: PropTypes.number.isRequired,
  countER: PropTypes.number.isRequired,
};

export default InventoryTabs;
