import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Grid } from '@material-ui/core';
import camel from 'camelcase-keys';
import Map from 'components/map';
import Location from 'components/common/Location';
import './LocationDetail.scss';

/**
 * Displays a formatted address + a map image.
 * Typically used on a Detail Page.
 */
const LocationDetail = ({ location }) => {
  if (isEmpty(location)) return <></>;
  const { latitude, longitude } = location;
  const mapLocation = { latitude, longitude };

  return (
    <div className="LocationDetail">
      <Grid container alignItems="center">
        <Grid item sm={12}>
          {/** In case the location prop has snake-case attributes. */}
          <Location location={{ ...location, ...camel(location) }} />
          {!location.hideAddress && <Map location={mapLocation} />}
        </Grid>
      </Grid>
    </div>
  );
};

LocationDetail.defaultProps = {
  location: {},
};

LocationDetail.propTypes = {
  /** Location object. */
  location: PropTypes.instanceOf(Object),
};

export default LocationDetail;
