/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Notification from 'components/common/Notification';
import TermsAndConditionsModal from 'components/modals/TermsAndConditionsModal';
import PrivacyPolicyModal from 'components/modals/PrivacyPolicyModal';
import { BrandingContext } from '../contexts/BrandingContext';
import './Footer.scss';
import image from '../../assets/images/powered-by.png';
import SwitchLanguageFooter from './SwitchLanguageFooter';

const Footer = ({ supportedLocales, defaultLocale }) => {
  const { branding } = useContext(BrandingContext);
  const primaryBranding = {
    color: branding.primaryColor,
  };
  const [t] = useTranslation();
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  return (
    <>
      <SwitchLanguageFooter
        supportedLocales={supportedLocales}
        defaultLocale={defaultLocale}
      />
      <footer className="Footer">
        <div className="container">
          <Container style={{ padding: 0 }}>
            <Row>
              <Col lg={3}>
                <Row>
                  <a
                    target="_blank"
                    role="button"
                    className="btn-plain footer-link"
                    style={primaryBranding}
                    href={t('shared:Footer.carenetLogo')}
                  >
                    <Col lg={3} xl={3}>
                      <div>
                        <img
                          alt={t('shared:Footer.poweredBy')}
                          src={image}
                        ></img>
                      </div>
                    </Col>
                  </a>
                  <Col lg={9} xl={9} className="footer-logo-text" />
                </Row>
              </Col>
              <Col lg={9} />
            </Row>
          </Container>

          <div className="footer-bottom" style={primaryBranding}>
            <p>{t('shared:Footer.poweredBy')}</p>
            <p>{t('shared:Footer.triageNote')}</p>

            <ul>
              <li>
                <button
                  className="btn-plain footer-link"
                  type="button"
                  style={primaryBranding}
                  onClick={() => setShowTermsModal(true)}
                >
                  {t('shared:Footer.terms')}
                </button>{' '}
              </li>
              <li>
                <a
                  target="_blank"
                  role="button"
                  className="btn-plain footer-link"
                  style={primaryBranding}
                  href={t(
                    'shared:privacyPage.url',
                    'https://carenethealthcare.com/privacy-policy/',
                  )}
                >
                  {t('shared:Footer.privacyPolicy')}
                </a>
              </li>
              <li>
                <Link
                  className="btn-plain footer-link"
                  to="/sitemap/"
                  style={primaryBranding}
                >
                  Sitemap
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <TermsAndConditionsModal
          show={showTermsModal}
          onClose={() => setShowTermsModal(false)}
        />
        <PrivacyPolicyModal
          show={showPrivacyModal}
          onClose={() => setShowPrivacyModal(false)}
        />
        <Notification />
      </footer>
    </>
  );
};

export default Footer;
