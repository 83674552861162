import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BrandingContext } from './contexts/BrandingContext';
import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
  const [t] = useTranslation();
  const { branding } = useContext(BrandingContext);
  const h3Branding = {
    color: branding.primaryColor,
    borderBottom: `1px solid ${branding.primaryColor}`,
  };

  return (
    <main className="PrivacyPolicy container">
      <h1 style={{ color: branding.primaryColor }}>
        {t('shared:privacyPage.privacyPolicy')}
      </h1>

      <p className="intro">{t('shared:privacyPage.privacyIntro')}</p>

      <section className="terms">
        <h3 style={h3Branding}>
          {t('shared:privacyPage.trafficDataCollected')}
        </h3>

        <div className="term-details">
          <p>{t('shared:privacyPage.trafficDataCollected1')}</p>
        </div>
      </section>

      <section className="terms">
        <h3 style={h3Branding}>
          {t('shared:privacyPage.personalInfoCollected')}
        </h3>
        <div className="term-details">
          <p>{t('shared:privacyPage.personalInfoCollected1')}</p>
        </div>
      </section>

      <section className="terms">
        <h3 style={h3Branding}>{t('shared:privacyPage.storage')}</h3>
        <div className="term-details">
          <p>{t('shared:privacyPage.storage1')}</p>
        </div>
      </section>

      <section className="terms">
        <h3 style={h3Branding}>
          {t('shared:privacyPage.childrenSectionTitle')}
        </h3>
        <div className="term-details">
          <p>{t('shared:privacyPage.childrenSectionContent')}</p>
        </div>
      </section>

      <section className="terms">
        <h3 style={h3Branding}>{t('shared:privacyPage.useOfYourInfo')}</h3>
        <div className="term-details">
          <p>{t('shared:privacyPage.useOfYourInfo1')}</p>
          <p>{t('shared:privacyPage.useOfYourInfo2')}</p>
          <p>
            {t('shared:privacyPage.useOfYourInfo3')}{' '}
            <a href="mailto:contact@inquicker.com" className="link-default">
              contact@inquicker.com
            </a>
            .
          </p>
        </div>
      </section>

      <section className="terms">
        <h3 style={h3Branding}>{t('shared:privacyPage.sharingOfInfo')}</h3>
        <div className="term-details">
          <p>{t('shared:privacyPage.sharingOfInfo1')}</p>
          <p>{t('shared:privacyPage.sharingOfInfo2')}</p>
          <p>{t('shared:privacyPage.sharingOfInfo3')}</p>
          <p>{t('shared:privacyPage.sharingOfInfo4')}</p>
        </div>
      </section>

      <section className="terms">
        <h3 style={h3Branding}>
          {t('shared:privacyPage.confidentialitySectionTitle')}
        </h3>
        <div className="term-details">
          <p>{t('shared:privacyPage.confidentialitySectionContent')}</p>
        </div>
      </section>

      <section className="terms">
        <h3 style={h3Branding}>{t('shared:privacyPage.security')}</h3>
        <div className="term-details">
          <p>{t('shared:privacyPage.security1')}</p>
          <p>{t('shared:privacyPage.security2')}</p>
        </div>
      </section>

      <section className="terms">
        <h3 style={h3Branding}>{t('shared:privacyPage.notice')}</h3>

        <div className="term-details">
          <p>
            {t('shared:privacyPage.notice1')}{' '}
            <a href="mailto:privacy@inquicker.com" className="link-default">
              privacy@inquicker.com
            </a>{' '}
            {t('shared:privacyPage.notice2')}:
          </p>
          <p>
            {t('shared:attnPrivacyOfficer')}
            <br />
            InQuicker, LLC
            <br />
            P.O. Box 40347
            <br />
            Nashville, TN 37204
          </p>
        </div>
      </section>

      <section className="terms">
        <h3 style={h3Branding}>{t('shared:privacyPage.updatesToPrivacy')}</h3>
        <div className="term-details">
          <p>{t('shared:privacyPage.updatesToPrivacy1')}</p>
        </div>
      </section>
    </main>
  );
};

export default PrivacyPolicy;
