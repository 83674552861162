import * as types from './actionTypes';

export function addHistoryLocation(location) {
  return async dispatch =>
    dispatch({ type: types.ADD_HISTORY_LOCATION, location });
}

export function clearHistory() {
  return async dispatch => dispatch({ type: types.CLEAR_HISTORY });
}

export function updateLocationTitle(key, title) {
  return async dispatch =>
    dispatch({ type: types.UPDATE_LOCATION_TITLE, key, title });
}
