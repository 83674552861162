import { isEmpty, isNull, isUndefined } from "lodash";

export function processSettings(settings) {
    const structuredSettings = {};

    settings.forEach(setting => {
        const { attributes } = setting;
        const {
            healthSystemId,
            regionId,
            facilityId,
            serviceId,
            appointmentTypeTemplateId,
            key,
            value,
        } = attributes;

        const compositeKey = `hs:${healthSystemId || 'any'}|r:${regionId ||
            'any'}|f:${facilityId || 'any'}|s:${serviceId ||
            'any'}|at:${appointmentTypeTemplateId || 'any'}`;

        structuredSettings[compositeKey] = value;
    });

    return structuredSettings;
}

export function getSettingValue(structuredSettings, ids) {
    const levels = [
        'appointmentTypeTemplateId',
        'serviceId',
        'facilityId',
        'regionId',
        'healthSystemId',
    ];
    const defaultKey = `hs`;
    const potentialKeys = levels
        .reduce((acc, level) => {
            const prevKey = acc.length ? acc[acc.length - 1] : '';
            if (ids[level]) {
                const newKey = `${prevKey}${prevKey ? '|' : ''}${level.substring(
                    0,
                    1,
                )}:${ids[level]}`;
                acc.push(newKey);
            }
            return acc;
        }, [])
        .map(
            key =>
                `hs:${ids.healthSystemId || 'any'}|r:${ids.regionId ||
                'any'}|f:${ids.facilityId || 'any'}|s:${ids.serviceId ||
                'any'}|at:${ids.appointmentTypeTemplateId || 'any'}`,
        )
        .reverse();

    for (const key of potentialKeys) {
        if (structuredSettings[key]) {
            return structuredSettings[key];
        }
    }
    // hs:338|r:any|f:any|s:any|at:any
    return 90; // structuredSettings[''];
}

// export function findSettingValue(settings, criteria) {
//     const criteriaKeysOrdered = [
//         'appointmentTypeTemplateId',
//         'serviceId',
//         'facilityId',
//         'regionId',
//         'healthSystemId',
//     ];

//     const generateCriteriaCombinations = criteriaKeys => {
//         const combinations = [];
//         const generateCombinations = (start, combo) => {
//             combinations.push(combo);
//             for (let i = start; i < criteriaKeys.length; i++) {
//                 generateCombinations(i + 1, combo.concat(criteriaKeys[i]));
//             }
//         };
//         generateCombinations(0, []);
//         return combinations
//             .filter(c => c.length)
//             .sort((a, b) => b.length - a.length); // Sorted by combination length
//     };

//     const criteriaCombinations = generateCriteriaCombinations(
//         criteriaKeysOrdered.filter(key => criteria[key] !== undefined),
//     );

//     // eslint-disable-next-line no-restricted-syntax
//     for (const combo of criteriaCombinations) {
//         const matchedSettings = settings.filter(setting => {
//             return combo.every(key => criteria[key] === setting.attributes[key]);
//         });
//         matchedSettings.sort((a, b) => {
//             const countA = combo.reduce(
//                 (acc, key) => acc + (a.attributes[key] !== null ? 1 : 0),
//                 0,
//             );
//             const countB = combo.reduce(
//                 (acc, key) => acc + (b.attributes[key] !== null ? 1 : 0),
//                 0,
//             );
//             return countB - countA;
//         });
//         if (matchedSettings.length > 0) {
//             return matchedSettings[0].attributes.value;
//         }
//     }

//     const healthSystemMatch = settings.find(setting => setting.attributes.healthSystemId === criteria.healthSystemId && Object.keys(criteria).every(key => key === 'healthSystemId' || setting.attributes[key] === undefined));
//     return healthSystemMatch ? healthSystemMatch.attributes.value : null;

// }

export function getNumberOrNull(value) {
    if (value === '' || isUndefined(value) || isNull(value)) return null;
    const number = Number(value);
    return Number.isNaN(number) ? null : number;
}

export function findSettingValue(settings, criteria, findMax = false) {
    if (findMax) {
        const maxValue = settings.reduce((max, item) => {
            const value = parseInt(item.attributes.value);
            return value > max ? value : max;
        }, -Infinity);
        return maxValue
    }
    // Order the criteria by specificity
    const criteriaKeysOrdered = [
        'appointmentTypeTemplateId',
        'serviceId',
        'facilityId',
        'regionId',
        'healthSystemId',
    ];
    // Generate criteria combinations based on the specified order
    const generateCriteriaCombinations = criteriaKeys => {
        const combinations = [];
        const helper = (start, chosen) => {
            if (start === criteriaKeys.length) {
                if (chosen.length > 0) combinations.push(chosen);
                return;
            }
            // Include this key
            helper(start + 1, [...chosen, criteriaKeys[start]]);
            // Exclude this key
            helper(start + 1, chosen);
        };
        helper(0, []);
        return combinations.sort((a, b) => b.length - a.length);
    };

    const validCriteriaKeys = criteriaKeysOrdered.filter(
        key => criteria[key] !== null,
    );

    const criteriaCombinations = generateCriteriaCombinations(validCriteriaKeys);
    // eslint-disable-next-line no-restricted-syntax
    for (const combo of criteriaCombinations) {
        // Filter settings for the current combination of criteria
        const matchedSettings = settings.filter(setting =>
            combo.every(key => setting.attributes[key] === criteria[key]),
        );
        // Return the value if we have matched settings
        if (matchedSettings.length > 0 && matchedSettings.length === 1) {
            return matchedSettings[0].attributes.value;
        }
    }

    // Fallback to regionId only if no specific match is found
    if (criteria.regionId !== null) {
        const regionHealthSystemMatch = settings.find(setting => {
            return (
                setting.attributes.regionId === criteria.regionId &&
                setting.attributes.healthSystemId === criteria.healthSystemId &&
                Object.keys(criteria).every(key => {
                    return key === 'regionId' || key === 'healthSystemId' || setting.attributes[key] === null;
                })
            );
        });

        if (regionHealthSystemMatch) {
            return regionHealthSystemMatch.attributes.value; // Return this specific match if found
        }
    }

    // Fallback to healthSystemId only if no specific match is found
    const healthSystemMatch = settings.find(setting => {
        return (
            setting.attributes.healthSystemId === criteria.healthSystemId &&
            Object.keys(criteria).every(key => {
                return key === 'healthSystemId' || setting.attributes[key] === null;
            })
        );
    });
    return healthSystemMatch ? healthSystemMatch.attributes.value : null;
}
