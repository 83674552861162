import React, { useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrandingContext } from '../contexts/BrandingContext';
import { useTranslation } from 'react-i18next';

/**
 * This component renders all Provider video items
 * under an Media heading.
 *
 * This is used in the Provider Detail Page.
 */
const ProviderMedia = ({ embedCode }) => {
  const { branding } = useContext(BrandingContext);
  const [t] = useTranslation();
  const divEle = useRef();
  useEffect(() => {
    if (divEle && divEle.current && embedCode) {
      divEle.current.innerHTML = embedCode;
    }
  }, [divEle, embedCode]);
  return (
    <>
      <h2
        className="section-title"
        style={{
          color: branding.primaryColor,
          borderBottomColor: branding.primaryColor,
        }}
      >
        {t('ProviderEducation.media')}
      </h2>
      <div
        style={{
          display: "flex",
        }}
        ref={divEle}
      />
    </>
  );
};

ProviderMedia.defaultProps = {
  embedCode: '',
};

ProviderMedia.propTypes = {
  embedCode: PropTypes.string,
};

export default ProviderMedia;
