/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PageMetaInfo from 'components/common/PageMetaInfo';
import serviceLineGroupMapping from 'config/serviceLineGroupMappings';
import { cleanServiceLineUrls } from 'helpers/searchOptions';
import { useQueryString } from 'hooks/useQueryString';
import meta from './meta';

const PageMeta = () => {
  const location = useLocation();
  const healthSystem = useSelector(state => state.healthSystem);
  const facResults = useSelector(
    state => state.facilitiesReducer.availableFacilities,
  );
  const schedule = useSelector(state => state?.scheduleDetailsReducer);
  const providers = useSelector(state => state?.providers);
  const visit = useSelector(state => state.visitReducer);
  const serviceLines = useSelector(state => state.serviceLines.serviceLines);
  const services = useSelector(state => state.serviceLines.services);
  const { params } = useQueryString();
  const isProviderDetailPage = useRouteMatch({
    path: [
      '/providers/:id',
      '/provider/:permalink',
      '/:dischargeFacility/discharge/providers/:id',
      '/:dischargeFacility/discharge/provider/:permalink',
    ],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const isSitemap = useRouteMatch({
    path: ['/sitemap/'],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const isInventoryPage = useRouteMatch({
    path: ['/search'],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const isServiceLinePage = useRouteMatch({
    path: ['/:serviceLine/search', '/:serviceLine', '/:serviceLine/'],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const isCityStatePage = useRouteMatch({
    path: [
      '/:serviceLine/:state/:city/search',
      '/:serviceLine/:state/:city',
      '/:serviceLine/:state/:city/',
    ],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const isLandingPage = useRouteMatch({
    path: ['/'],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const isFacilityPage = useRouteMatch({
    path: [
      '/facility/:facilityName/service/:serviceLine',
      '/facility/:facilityName',
      '/:dischargeFacility/discharge',
      '/:dischargeFacility/discharge/service/:serviceLine',
    ],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const isLocationPage = useRouteMatch({
    path: [
      '/facility/:facilityName/:locationPermalink',
      '/facility/:facilityName/:locationPermalink/service/:serviceLine',
      '/:dischargeFacility/discharge/:locationPermalink',
      '/:dischargeFacility/discharge/:locationPermalink/service/:serviceLine',
    ],
    strict: true,
    sensitive: true,
    exact: true,
  });

  // eslint-disable-next-line no-unused-vars
  const isRegistrationPage = useRouteMatch({
    path: [
      '/schedule/:scheduleId',
      '/:dischargeFacility/discharge/schedule/:scheduleId',
    ],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const isConfirmationPage = useRouteMatch({
    path: [
      '/schedule/:scheduleId/confirmation/:confirmationId',
      '/:dischargeFacility/discharge/schedule/:scheduleId/confirmation/:confirmationId',
    ],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const isScheduleDetailPage = useRouteMatch({
    path: [
      '/schedule/calendar/:id',
      '/:dischargeFacility/discharge/schedule/calendar/:id',
    ],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const isSitemapCities = useRouteMatch({
    path: ['/sitemap/cities'],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const isSitemapServices = useRouteMatch({
    path: ['/sitemap/services'],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const isSitemapFacilites = useRouteMatch({
    path: ['/sitemap/facilities'],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const isSitemapProviders = useRouteMatch({
    path: ['/sitemap/providers'],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const isPatientIntakePage = useRouteMatch({
    path: ['/patient-intake/:secureId'],
    strict: true,
    sensitive: true,
    exact: true,
  });

  const [metaInfo, setMetaInfo] = useState();
  const HEALTH_SYSTEM = 'HealthSystem';
  const REGION = 'Region';
  const getCanonical = (hsPermalink, region) => {
    const url = window.location.href.split('?')[0];
    if (region === null) return url.replace(/\/$/, '');
    return url.replace(region.permalink, hsPermalink).replace(/\/$/, '');
  };
  const prepareMetaInfo = useCallback(
    async (pageType, options = [], canonical = null) => {
      const type = healthSystem?.region !== null ? REGION : HEALTH_SYSTEM;
      let config = meta.find(m => m.type === type && m.pageType === pageType);
      options.push({ '[[region-name]]': healthSystem?.region?.name });
      options.push({ '[[hs-name]]': healthSystem?.name });
      options.push({ '[[hs-permalink]]': healthSystem?.permalink });
      await options.forEach(m => {
        config = JSON.parse(
          JSON.stringify(config).replaceAll(
            Object.keys(m).toString(),
            Object.values(m).toString(),
          ),
        );
      });
      config.canonical =
        canonical === null
          ? getCanonical(healthSystem?.permalink, healthSystem?.region)
          : canonical;
      setMetaInfo(config);
    },
    [healthSystem],
  );
  const serviceGroup = permalink => {
    const groupName = serviceLineGroupMapping[permalink];
    return groupName;
  };
  const capitalize = str => {
    return str
      .split(' ')
      .map(s => `${s.charAt(0).toUpperCase()}${s.slice(1)}`)
      .join(' ');
  };
  useEffect(() => {
    (async () => {
      if (isSitemapCities) {
        prepareMetaInfo('SitemmapCities');
      } else if (isSitemapServices) {
        prepareMetaInfo('SitemapServices');
      } else if (isSitemapFacilites) {
        prepareMetaInfo('SitemapFacilities');
      } else if (isSitemapProviders) {
        prepareMetaInfo('SitemapProviders');
      } else if (isSitemap) {
        prepareMetaInfo('Sitemap');
      } else if (isScheduleDetailPage) {
        const attributes = schedule?.schedule?.attributes;
        const placeholders = [
          { '[[provider-name]]': schedule?.provider?.name },
          {
            ', [[provider-suffix]]':
              schedule?.provider?.suffix === '' ||
              schedule?.provider?.suffix === null
                ? ''
                : `, ${schedule?.provider?.suffix}`,
          },
          { '[[service-name]]': attributes?.service?.name },
          { '[[schedule-id]]': schedule?.schedule?.id },
        ];
        prepareMetaInfo('FullSchedulePage', placeholders);
      } else if (isRegistrationPage) {
        prepareMetaInfo('LandingPage');
      } else if (isLandingPage) {
        prepareMetaInfo('LandingPage');
      } else if (
        isCityStatePage &&
        !isCityStatePage.url.includes('facility') &&
        isCityStatePage.url !== '/search'
      ) {
        const url = window.location.href;
        const canonical =
          healthSystem.region === null
            ? `${url.replace(/\/$/, '')}`
            : `${url
                .replace(healthSystem.region.permalink, healthSystem.permalink)
                .replace(/\/$/, '')}`;
        const service = services[params?.service_id];
        const placeholders = [
          { '[[service-name]]': service?.attributes?.name },
          { '[[service-line-permalink]]': service?.attributes?.permalink },
          { '[[location-city]]': capitalize(isCityStatePage.params.city) },
          {
            '[[location-state]]': isCityStatePage.params.state.toUpperCase(),
          },
        ];
        prepareMetaInfo('CityStatePages', placeholders, canonical);
      } else if (
        isServiceLinePage &&
        !isServiceLinePage.url.includes('facility') &&
        isServiceLinePage.url !== '/search'
      ) {
        const cleanService = cleanServiceLineUrls(
          isServiceLinePage.params.serviceLine,
        );
        const url = window.location.origin;
        const canonical =
          healthSystem.region === null
            ? `${url.replace(/\/$/, '')}/${cleanService}`
            : `${url
                .replace(healthSystem.region.permalink, healthSystem.permalink)
                .replace(/\/$/, '')}/${cleanService}`;

        const service = services[params?.service_id];
        const group = serviceGroup(cleanService);
        const placeholders = [
          { '[[service-name]]': service?.attributes?.name },
          { '[[service-line-permalink]]': service?.attributes?.permalink },
        ];
        if (group === 'GROUP_B' || group === 'GROUP_C' || group === 'GROUP_F') {
          prepareMetaInfo('ServiceLinePageEC', placeholders, canonical);
        } else {
          prepareMetaInfo('ServiceLinePageNonEC', placeholders, canonical);
        }
      } else if (isInventoryPage) {
        prepareMetaInfo('FindDoc');
      } else if (isProviderDetailPage) {
        const provider = providers.data.find(
          p =>
            p.id === isProviderDetailPage?.params?.id ||
            p.attributes.permalink === isProviderDetailPage?.params?.permalink,
        );
        const schedule = providers.schedules.find(
          s => s.attributes.active === true,
        );
        const location = providers.locations.find(
          l =>
            l.id.toString() ===
            schedule.relationships.location.data.id.toString(),
        );
        const placeholders = [
          { '[[provider-name]]': provider?.attributes?.name },
          {
            ', [[provider-suffix]]':
              provider?.attributes?.suffix === ''
                ? ''
                : `, ${provider?.attributes?.suffix}`,
          },
          { '[[provider-permalink]]': provider?.attributes?.permalink },
          { '[[service-name]]': schedule?.attributes?.service?.name },
          { '[[schedule-id]]': schedule?.id },
          { '[[location-zip]]': location?.attributes?.zip },
          { '[[location-city]]': location?.attributes?.city },
          { '[[location-state]]': location?.attributes?.state },
        ];
        const url = window.location.origin;
        const canonical =
          healthSystem.region === null
            ? `${url.replace(/\/$/, '')}/provider/${
                provider?.attributes?.permalink
              }`
            : `${url
                .replace(healthSystem.region.permalink, healthSystem.permalink)
                .replace(/\/$/, '')}/provider/${
                provider?.attributes?.permalink
              }`;
        if (provider?.attributes?.name)
          prepareMetaInfo('ProviderPage', placeholders, canonical);
      } else if (isConfirmationPage) {
        prepareMetaInfo('LandingPage');
      } else if (isFacilityPage) {
        const facility = facResults?.data?.[0]?.attributes;
        const placeholders = [{ '[[facility-name]]': facility?.name }];
        prepareMetaInfo('FacilityPage', placeholders);
      } else if (isLocationPage) {
        const facility = facResults?.data?.[0]?.attributes;
        const location = facResults?.included?.find(
          l =>
            l?.type === 'locations' &&
            l.attributes.permalink ===
              isLocationPage?.params?.locationPermalink,
        );
        const service = serviceLines[params?.service];
        const placeholders = [
          { '[[facility-name]]': facility?.name },
          { '[[location-zip]]': location?.attributes?.zip },
          { '[[location-city]]': location?.attributes?.city },
          { '[[location-state]]': location?.attributes?.state },
          { '[[location-name]]': location?.attributes?.name },
          { '[[service-name]]': service?.attributes?.name },
        ];
        prepareMetaInfo('LocationPage', placeholders);
      } else if (isPatientIntakePage) {
        prepareMetaInfo('PatientIntakePages');
      } else {
        prepareMetaInfo('LandingPage');
      }
    })();
  }, [
    location.pathname,
    facResults,
    visit,
    serviceLines,
    schedule,
    providers,
    params.service,
  ]);

  return metaInfo && metaInfo !== undefined ? (
    <PageMetaInfo
      title={metaInfo.title}
      description={metaInfo.description}
      canonicalLink={metaInfo.canonical}
    />
  ) : (
    <></>
  );
};

export default PageMeta;
