import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomTextField from '../CustomTextField';

const PatientDescriptionFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const [t] = useTranslation();

  return (
    <>
      {formContext.isFieldEnabled('height-and-weight') && (
        <div className="form-section_row">
          <Grid container spacing={4}>
            <Grid item sm={3} xs={12} className="form-item">
              <CustomTextField
                fieldKey="height"
                fieldValue={formContext.form.height}
                validateFormHandler={formContext.handleSetFormState}
                shrink
                required={formContext.isFieldRequired('height-and-weight')}
                label={t('PatientDescriptionFields.height')}
                hasError={formContext.hasError('height-and-weight')}
                errorMessage={formContext.getError('height-and-weight')}
              />
            </Grid>

            <Grid item sm={3} xs={12} className="form-item">
              <CustomTextField
                fieldKey="weight"
                fieldValue={formContext.form.weight}
                validateFormHandler={formContext.handleSetFormState}
                shrink
                required={formContext.isFieldRequired('height-and-weight')}
                label={t('PatientDescriptionFields.weight')}
                hasError={formContext.hasError('height-and-weight')}
                errorMessage={formContext.getError('height-and-weight')}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default PatientDescriptionFields;
