import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import { isEmpty } from 'lodash';

const useGoogleAnalytics = (options = {}) => {
  const [initialized, setInitialized] = useState(false);
  const [keys, setKeys] = useState([]);

  const { pathname } = useLocation();

  // initialization
  useEffect(() => {
    if (!initialized && !isEmpty(options.keys) && isEmpty(keys)) {
      ReactGA.initialize(options.keys);
      setInitialized(true);
      setKeys(options.keys);
    }
  }, [options.keys, pathname]);

  useEffect(() => {
    if (initialized) {
      setTimeout(() => ReactGA.pageview(pathname), 1000);
    }
  }, [initialized, pathname]);

  return ReactGA;
};

export default useGoogleAnalytics;
