import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomTextField from '../CustomTextField';

const ReferringFacilityFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const [t] = useTranslation();

  return (
    <>
      {(formContext.isFieldEnabled('referring-facility') ||
        formContext.isFieldRequired('referring-facility')) && (
        <div className="form-section_row">
          <Grid container>
            <Grid item sm={4} xs={12}>
              <CustomTextField
                fieldKey="referring-facility"
                fieldValue={formContext.form['referring-facility']}
                validateFormHandler={formContext.handleSetFormState}
                label={t('ReferringFacilityFields.referringFacility')}
                required={formContext.isFieldRequired('referring-facility')}
                shrink
                hasError={formContext.hasError('referring-facility')}
                errorMessage={formContext.getError('referring-facility')}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default ReferringFacilityFields;
