/* eslint-disable react/jsx-props-no-spreading */
import { flatMap, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { loadActiveServices } from 'redux/actions/serviceLineActions';
import InventoryPage from 'components/inventory/InventoryPage';
import { LinearProgress } from '@material-ui/core';
import EducationalNowPage from 'components/educational/EducationalNowPage';
import { beginSearch, searchProviders } from 'redux/actions/searchActions';

export const Next24HoursHOC = () => {
  const healthSystem = useSelector(state => state.healthSystem);
  const serviceLines = useSelector(state => state.serviceLines.serviceLines);
  const services = useSelector(state => state.serviceLines.services);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!Object.keys(serviceLines)?.length) {
      dispatch(loadActiveServices());
    }
  }, []);
  const servicesForBubble = bubble => {
    const serviceIds = bubble.relationships.services.data.map(s => s.id);
    const bubbleServices = Object.values(services).filter(s =>
      serviceIds.includes(s.id),
    );

    return bubbleServices;
  };

  const findADocServices = () => {
    const flatServiceLines = Object.values(serviceLines);
    const findADocPermalinks = ['specialty-care', 'primary-care'];
    const findADocServiceLines = flatServiceLines.filter(sl =>
      findADocPermalinks.includes(sl.attributes.permalink),
    );
    return flatMap(findADocServiceLines, sl => servicesForBubble(sl));
  };

  const linkAddress = async () => {
    const services = await findADocServices();
    let address = `?ux_mode=${healthSystem[
      'ux-selection'
    ]?.toLowerCase()}&service=find-a-doctor&serviceName=Find A Doctor&sort=${
      healthSystem['sorting-order']
    }`;
    if (
      !isEmpty(services) &&
      services.length &&
      healthSystem['ux-selection'] === 'Default'
    ) {
      address += services.map(s => `&subservice_ids=${s.id}`).join('');
      if (services.length > 0)
        address += `&service_id=${services.map(s => s.id).join(',')}`;
    }
    if (healthSystem['ux-selection'] === 'Educational') {
      address += '&searchType=now';
    }
    if (healthSystem['ux-selection'] === 'Default') {
      address += '&searchType=inventory';
    }
    return address;
  };

  const [landingPage, setLandingPage] = useState();

  const history = useHistory();
  const [address, setAddress] = useState();
  useEffect(() => {
    (async () => {
      if (Object.values(serviceLines).length > 0 && Object.keys(healthSystem)) {
        const serviceLinesArray = Object.values(serviceLines);
        const address = await linkAddress();
        setAddress(address);
        const checkFindADoc = () => {
          return serviceLinesArray.find(
            sl =>
              sl.attributes.permalink === 'specialty-care' ||
              sl.attributes.permalink === 'primary-care',
          );
        };
        const result = await checkFindADoc();
        if (result) {
          if (pathname.startsWith('/next-24')) {
            history.replace({
              pathname,
              search: address,
            });
            return setLandingPage(false);
          }
        }
        return history.push({
          pathname: '/',
        });
        // return setLandingPage(true);
      }
    })();
  }, [Object.values(serviceLines).length, healthSystem]);

  // eslint-disable-next-line no-nested-ternary
  return landingPage === false &&
    landingPage !== undefined &&
    healthSystem['ux-selection'] === 'Default' ? (
    <Route
      exact
      strict
      key="inventory-page"
      path={['/next-24', '/next-24/', '/next-24/:state/:city']}
      component={InventoryPage}
    />
  ) : landingPage === false &&
    landingPage !== undefined &&
    healthSystem['ux-selection'] === 'Educational' ? (
    <Route
      exact
      key="inventory-page"
      path={['/next-24', '/next-24/', '/next-24/:state/:city']}
      component={EducationalNowPage}
    />
  ) : (
    <LinearProgress />
  );
};
