import React from 'react';
import PropTypes from 'prop-types';
import TermsPage from 'components/TermsPage';
import BaseModal from './BaseModal';

const TermsAndConditionsModal = ({ show, onClose }) => {
  return (
    <BaseModal show={show} onClose={onClose}>
      <TermsPage />
    </BaseModal>
  );
};

TermsAndConditionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TermsAndConditionsModal;
