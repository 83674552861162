import {
  getEndpoint,
  getHeaderInfo,
  handleResponse,
  handleError,
} from './apiUtils';

export const validateCouponCode = params => {
  return fetch(
    getEndpoint(`health_systems/validate_coupon_code`, params),
    getHeaderInfo(),
  )
    .then(handleResponse)
    .catch(handleError);
};
