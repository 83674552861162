import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getAllLocations } from 'api/locationApi';
import { getActiveServices } from 'api/serviceLinesApi';
import BreadCrumbs from 'components/common/BreadCrumbs';
import './Sitemap.scss';

const SitemapCities = () => {
  const [locations, setLocations] = useState(null);
  const [services, setServices] = useState(null);

  function buildCitiesUrlLink(zip, services) {
    const serviceName = 'Find A Doctor';
    const permalink = 'find-a-doctor';
    const id = services?.data?.find(
      element => element?.attributes?.name === 'Primary Care',
    )?.id;
    const primaryCareObj = services?.included?.find(
      element => element?.attributes?.name === 'Primary Care',
    );
    let subServiceIdParameter = `&subservice_ids=${primaryCareObj?.relationships?.services?.data?.[0]?.id}`;

    for (
      let i = 1;
      i < primaryCareObj?.relationships?.services?.data?.length;
      i++
    ) {
      const subServiceID = primaryCareObj.relationships.services.data[i].id;
      subServiceIdParameter += `&subservice_ids=${subServiceID}`;
    }

    return `/search?service=${permalink}&service_id=${id}&serviceName=${serviceName}${subServiceIdParameter}&zip=${zip}&ux_mode=default`;
  }

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function containsName(name, list) {
    let i;
    for (i = 0; i < list.length; i++) {
      if (list[i].name === name) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    getAllLocations().then(setLocations);
    getActiveServices().then(setServices);
  }, []);

  function buildOutputMap(data) {
    // const output = data.reduce((acc, cur) => ({ ...acc, [cur.state]: [cur.name, cur.link] }), {})
    // return output;
    const output = {};
    data.forEach(element => {
      // output[element.state].push({name: element.name, link: element.link});
      if (output.hasOwnProperty(element.state)) {
        output[element.state].push({ name: element.name, link: element.link });
      } else {
        output[element.state] = [{ name: element.name, link: element.link }];
      }
    });

    for (let key in output) {
      output[key].sort(function(a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    }
    return output;
  }

  const locationMap = [];
  if (locations !== null) {
    locations.data.forEach(element => {
      // console.log(element);
      if (!containsName(toTitleCase(element.attributes.city), locationMap)) {
        locationMap.push({
          name: toTitleCase(element.attributes.city),
          link: buildCitiesUrlLink(element.attributes.city, services),
          state: element.attributes.state,
        });
      }
    });
  }

  const outputMap = buildOutputMap(locationMap);

  return (
    <div className="Sitemap">
      <div className="container">
        <BreadCrumbs sitemap />
        {Object.keys(outputMap).map(key => (
          <>
            <h4>{key}</h4>
            <ul>
              {outputMap[key].map(item => (
                <li key={item.name}>
                  <Link to={item.link}>{item.name}</Link>
                </li>
              ))}
            </ul>
          </>
        ))}
      </div>
    </div>
  );
};

export default SitemapCities;
