/**
 * Super efficient sort of Venues.
 * Assumes 3 VOCs, one of each.
 * Sorts into: Physician Services, Urgent Care then ER.
 * @param {Array} venues Array of Venue objects.
 * @return {Array}
 */
export const sortVenues = venues => {
  const sorted = [];
  sorted.push(
    venues.find(v => v.attributes.venueType === 'physician_services') || {
      attributes: {},
    },
  );
  sorted.push(
    venues.find(v => v.attributes.venueType === 'urgent_care') || {
      attributes: {},
    },
  );
  sorted.push(
    venues.find(v => v.attributes.venueType === 'emergency_room') || {
      attributes: {},
    },
  );
  return sorted;
};
