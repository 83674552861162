import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { groupBy, isEmpty } from 'lodash';
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  FormHelperText,
} from '@material-ui/core';
import { spaceToKaleb } from '../../utils/helpers';
import { useTranslation } from 'react-i18next';

const selectStyles = makeStyles({
  iconPad: {
    maxWidth: '78%',
    overflow: 'hidden',
    paddingLeft: '25px !important',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  formContainer: {
    position: 'relative',
  },
  iconContainer: {
    left: '10px',
    position: 'absolute',
    top: '13px',
    zIndex: 2,
  },
  inputLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '90%',
  },
  inputPad: {
    paddingLeft: '37px !important',
  },
  selectStyle: {
    background: '#fff',
    borderRadius: '4px',
    "& div":
      { overflow: 'auto', }
  },
  selectStyleFilled: {
    background: '#f8f9fa',
    borderRadius: '4px',
  },
  inputStyleFilled: {
    background: '#f8f9fa',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  inputStyle: {
    background: '#fff',
    paddingLeft: '5px',
    paddingRight: '5px',
    "& div":
      { overflow: 'auto',}
  },
});

const CustomSelect = ({
  filled,
  fieldKey,
  fieldValue,
  validateFormHandler,
  icon,
  items,
  label,
  required,
  grouped,
  groupKey,
  hasError,
  errorMessage,
  tabIndex,
}) => {
  const classes = selectStyles();
  const inputLabel = useRef(null);
  const selectRef = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  const [shrink, setShrink] = useState(false);
  const [t] = useTranslation();
  useEffect(() => {
    if (
      shrink ||
      (!isEmpty(fieldValue) && fieldValue.value.toString().length)
    ) {
      setLabelWidth(inputLabel.current.offsetWidth);
    } else {
      setLabelWidth(0);
    }
  }, [shrink, fieldValue]);

  const handleChange = e => {
    const { value } = e.target;
    validateFormHandler({
      [fieldKey]: {
        error: false,
        value,
      },
    });
  };

  const renderOptions = (isGrouped, items, groupKey) => {
    if (isGrouped) {
      const groupedItems = groupBy(items, groupKey);
      const optGroups = Object.keys(groupedItems).map(label => {
        return (
          <optgroup key={label} label={label}>
            {groupedItems[label].map(item => (
              <option key={item.value} value={item.value}>
                {item.text}
              </option>
            ))}
          </optgroup>
        );
      });
      return [<option value="" key="emptyOption" />, ...optGroups];
    }

    const options = items.map(item => (
      <MenuItem
        key={item.id ? item.id : item.value}
        value={item.id ? item.id : item.value}
      >
        {item.name ? item.name : item.text}
      </MenuItem>
    ));
    return [
      <MenuItem key="emptyOption" value="">
        {t('choose')}
      </MenuItem>,
      ...options,
    ];
  };

  return (
    <div className={classes.formContainer}>
      <div className={classes.iconContainer}>{icon}</div>
      <FormControl variant="outlined" fullWidth error={hasError}>
        <InputLabel
          id={spaceToKaleb(`${label}-label`)}
          htmlFor={spaceToKaleb(`${label}-select`)}
          className={`${icon && !shrink && !labelWidth ? classes.iconPad : ''}`}
          ref={inputLabel}
        >
          {required && <span className="required">*</span>}
          {label}
        </InputLabel>
        <Select
          id={spaceToKaleb(`${label}-select`)}
          labelId={spaceToKaleb(`${label}-label`)}
          onFocus={() => setShrink(true)}
          onBlur={() => setShrink(false)}
          ref={selectRef}
          native={grouped}
          value={fieldValue.value}
          className={filled ? classes.selectStyleFilled : classes.selectStyle}
          onChange={handleChange}
          input={<OutlinedInput notched labelWidth={labelWidth} />}
          inputProps={{
            className: icon ? classes.inputPad : '',
            tabIndex,
          }}
        >
          {renderOptions(grouped, items, groupKey)}
        </Select>
        {errorMessage && (
          <FormHelperText error={hasError}>{errorMessage}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

CustomSelect.defaultProps = {
  filled: false,
  required: false,
  grouped: false,
  groupKey: '',
  icon: null,
  hasError: false,
  errorMessage: '',
  tabIndex: '0',
};

CustomSelect.propTypes = {
  filled: PropTypes.bool,
  fieldKey: PropTypes.string.isRequired,
  fieldValue: PropTypes.instanceOf(Object).isRequired,
  icon: PropTypes.element,
  items: PropTypes.instanceOf(Array).isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  grouped: PropTypes.bool,
  groupKey: PropTypes.string,
  validateFormHandler: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  tabIndex: PropTypes.string,
};

export default CustomSelect;
