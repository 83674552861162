import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { formatPhone } from 'utils/formatPhone';
import './Phone.scss';

/**
 * Formats and displays a phone number.
 * Mobile viewports will render a clickable number to call from device.
 * Larger viewports will only show static text.
 * @param {String} name Optional label for number
 * @param {String} phoneNumber Required number in string format
 */
const Phone = ({ name, phoneNumber }) => {
  if (!phoneNumber) return <></>;
  return (
    <Grid container className="Phone">
      <Grid item xs={12}>
        {name && (
          <>
            <span className="contact-type">{name}</span>
            <br />
          </>
        )}
        <span className="contact-number hide-mobile">
          {formatPhone(phoneNumber)}
        </span>
        <a
          href={`tel:${phoneNumber}`}
          className="contact-number hide-desktop link-default"
        >
          {formatPhone(phoneNumber)}
        </a>
      </Grid>
    </Grid>
  );
};

Phone.defaultProps = {
  name: null,
};

Phone.propTypes = {
  name: PropTypes.string,
  phoneNumber: PropTypes.string.isRequired,
};

export default Phone;
