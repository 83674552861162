/* eslint-disable no-param-reassign */
import moment from 'moment-timezone';
import { uniq } from 'lodash';
import Cookies from 'universal-cookie';
import { currencies } from './currencies';

export function isValid(value) {
  if (typeof value !== 'string' || value.length === 0) {
    return false;
  }

  // matches five and nine digit zipcodes as well as Canadian postal code
  // https://www.regextester.com/103297
  // const regex = /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/;
  // return !!value.match(regex);

  return true;
}

export function camelCase(str) {
  return str.replace(/([-_\s][a-z])/g, group =>
    group
      .toUpperCase()
      .replace(' ', '')
      .replace('-', '')
      .replace('_', ''),
  );
}

export function objectCamelCase(obj) {
  const result = {};
  const keys = Object.keys(obj);

  keys.forEach(key => {
    const camel = camelCase(key);
    result[camel] = obj[key];
  });

  return result;
}

export function spaceToKaleb(value) {
  return value.replace(/\s+/g, '-').toLowerCase();
}

export function getInputName(value) {
  return value
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .map(v => v.charAt(0).toUpperCase() + v.slice(1))
    .join(' ');
}

// accept two arrays of flat values that can be parsed by moment,
// turned into unix timestamps for sorting, then returning the uniq values.
export const mergeTimes = (nowTimes, laterTimes) => {
  const allTimes = [...nowTimes, ...laterTimes];
  const sorted = allTimes.sort((a, b) => moment(a).unix() - moment(b).unix());
  return uniq(sorted);
};

export const filterTimes = (times, startTime, endTime, timeZone) => {
  if (startTime || endTime) {
    const filteredResults = times.filter(time => {
      const startDate = moment(time).tz(timeZone);
      const endDate = moment(time).tz(timeZone);
      const splitStartTime = startTime.split(':');
      const splitEndTime = endTime.split(':');
      startDate
        .set({
          hour: parseInt(splitStartTime[0], 10),
          minute: parseInt(splitStartTime[1], 10),
        })
        .tz(timeZone)
        .toString();
      endDate
        .set({
          hour: parseInt(splitEndTime[0], 10),
          minute: parseInt(splitEndTime[1], 10),
        })
        .tz(timeZone)
        .toString();
      return (
        moment(time)
          .tz(timeZone)
          .isSameOrAfter(startDate) &&
        moment(time)
          .tz(timeZone)
          .isSameOrBefore(endDate)
      );
    });
    return filteredResults;
  }
};
export const checkProhibited = history => {
  const cookies = new Cookies();
  const prohibited = cookies.get('prohibited');
  if (prohibited && prohibited.value) {
    const expiryTime = new Date(prohibited.expires);
    const now = new Date();

    if (expiryTime - now > 0) {
      const blockVisitUrl = `/schedule/prohibited`;
      history.push(blockVisitUrl);
    } else {
      cookies.set('prohibited', { value: false, expires: null }, { path: '/' });
    }
  }
};

export const getCurrency = cur => {
  const currency = Object.values(currencies).find(item => item.code === cur);
  return currency ? currency.symbol : cur;
};

export const sortArrayOfObjects = (props, object) => {
  props = props.split('.');
  const { length } = props;

  object.sort((a, b) => {
    let i = 0;
    while (i < length) {
      a = a[props[i]];
      b = b[props[i]];
      i += 1;
    }
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });
  return object;
};

/**
 * Remove Duplicates from top level Array of Objects
 *
 * @param {Array<Record<any, any>>} objArray
 * @param {string} uniqueBy; has to exist in every element in objArray i.e. id
 * @return {Object[]}
 */
export const removeDuplicatesByUniqueKey = (objArray, uniqueBy) => {
  return [...new Map(objArray.map(item => [item[uniqueBy], item])).values()];
};

export const uniqueid = () => {
  var idstr = String.fromCharCode(Math.floor((Math.random() * 25) + 65));
  do {
    var ascicode = Math.floor((Math.random() * 42) + 48); if (ascicode < 58 || ascicode > 64) {
      idstr += String.fromCharCode(ascicode);
    }
  }
  while (idstr.length < 32);
  return (idstr);
}

export const removeDuplicates = (array, property) => {
  return array.reduce((accumulator, current) => {
    if (!accumulator.some(obj => obj[property] === current[property])) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
};
