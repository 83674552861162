import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

const NextAvailableAt = ({ time, timezoneName }) => {
  const [t] = useTranslation();

  if (!time) return <></>;
  if (
    !moment(time)
      .tz(timezoneName)
      .isValid()
  )
    return <></>;
  const getAvailableDay = () => {
    return moment(time).format('dddd');
  };

  const getAvailableDate = () => {
    const day = moment(time)
      .tz(timezoneName)
      .format('D');
    const month = moment(time)
      .tz(timezoneName)
      .format('M');
    if (localStorage.getItem('countryCode') === 'GB' || localStorage.getItem('countryCode') === 'UK') {
      return `${day}/${month}`;
    }
    else {
      return `${month}/${day}`;
    }

  };

  const getAvailableTime = () => {
    const hour = moment(time)
      .tz(timezoneName)
      .format('h');
    const min = moment(time)
      .tz(timezoneName)
      .format('mm');
    return `${hour}:${min}${moment(time)
      .tz(timezoneName)
      .format('A')}`;
  };
  return (
    <p>
      <strong>
        {t('NextAvailableAt.nextAvailableText')} {getAvailableDay()},{' '}
        {getAvailableDate()} @ {getAvailableTime()}
      </strong>
    </p>
  );
};

NextAvailableAt.propTypes = {
  time: PropTypes.string.isRequired,
  timezoneName: PropTypes.string.isRequired,
};

export default NextAvailableAt;
