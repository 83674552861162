import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Grid,
  FormControlLabel,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import { getVisit, createFeedback } from 'api/visitApi';
import BreadCrumbs from 'components/common/BreadCrumbs';
import PageNotFound from 'components/PageNotFound';
import CustomRadio from 'components/form/CustomRadio';
import CustomSelect from 'components/form/CustomSelect';
import CustomTextField from 'components/form/CustomTextField';
import { BlackCheckbox } from 'components/form/FormElements';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';
import BrandingButton from '../common/BrandingButton';
import './VisitFeedbackPage.scss';
import { useTranslation, Trans } from 'react-i18next';

const VisitFeedbackPage = ({ match }) => {
  const GA = useGoogleAnalytics();
  const history = useHistory();
  const { search } = useLocation();
  const params = QueryString.parse(search);
  const [visit, setVisit] = useState({
    data: null,
  });

  const [location, setLocation] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [t] = useTranslation();
  const recommendParam = params.recommend
    ? `${params.recommend[0].toUpperCase()}${params.recommend.substr(1)}`
    : '';

  const [feedbackForm, setFeedbackForm] = useState({
    wouldRecommend: {
      value: recommendParam,
    },
    timeWaited: {
      value: '',
    },
    inquickerRating: {
      value: '',
    },
    facilityRating: {
      value: '',
    },
    referer: {
      value: '',
    },
    comments: {
      value: '',
    },
    publish: {
      value: 'true',
    },
  });

  useEffect(() => {
    GA.event({ category: 'feedback', action: 'started' });
  }, []);

  const handleSetFormState = e => {
    const [key, obj] = Object.entries(e).shift();

    setFeedbackForm({
      ...feedbackForm,
      [key]: obj,
    });
  };

  const handleRadioChoice = (e, key) => {
    const { value } = e.target;
    handleSetFormState({
      [key]: {
        value,
      },
    });
  };

  useEffect(() => {
    (async () => {
      if (visit.data === null) {
        const v = await getVisit(match.params.visitId, {
          include: 'location',
        });
        if (v.data !== null) {
          setVisit(v);
          const loc = v.included.find(l => l.type === 'locations');
          setLocation(loc.attributes);
        } else {
          setVisit({ data: 'loaded' });
        }
      }
    })();
  });

  const refererOptions = [
    {
      value: 'Search Engine',
      text: t('VisitFeedbackPage.reffererOptionSearchEngine'),
    },
    {
      value: 'Family or Friend',
      text: t('VisitFeedbackPage.reffererOptionFamilyOrFriend'),
    },
    {
      value: 'Advertisement',
      text: t('VisitFeedbackPage.reffererOptionsAdvertisements'),
    },
    {
      value: 'Facebook/Twitter',
      text: t('VisitFeedbackPage.reffererOptionsFbTw'),
    },
    {
      value: 'News Article',
      text: t('VisitFeedbackPage.reffererOptionsNewsArticle'),
    },
    {
      value: 'TV or Radio News',
      text: t('VisitFeedbackPage.reffererOptionsTvOrRadio'),
    },
    {
      value: 'Email or Newsletter',
      text: t('VisitFeedbackPage.reffererOptionsEmailOrNwsLetter'),
    },
    {
      value: 'Other',
      text: t('VisitCancelConfirmationPage.cancelresponseOther'),
    },
  ];

  const submit = async () => {
    const id = match.params.visitId;
    const params = {
      type: 'feedbacks',
      attributes: {
        id,
        'would-recommend': feedbackForm.wouldRecommend.value,
        'time-waited': feedbackForm.timeWaited.value,
        'inquicker-rating': feedbackForm.inquickerRating.value,
        'facility-rating': feedbackForm.facilityRating.value,
        referer: feedbackForm.referer.value,
        comments: feedbackForm.comments.value,
        publish: feedbackForm.publish.value,
      },
    };
    const { errors, data } = await createFeedback(id, params);

    if (!isEmpty(data)) {
      GA.event({ category: 'feedback', action: 'finished' });
      const uri = `/feedback/thank-you`;
      return history.push(uri);
    }
    if (!isEmpty(errors)) {
      setFormErrors(errors);
      window.scrollTo(0, 0);
    }

    return false;
  };

  if (visit.data === null) return <></>;
  if (visit.data === 'loaded') return <PageNotFound />;
  const locName = location.name;
  return (
    <main className="VisitFeedbackPage container">
      <BreadCrumbs onlyHome />
      {!isEmpty(formErrors) && (
        <div className="errors">
          <div className="errors-wrapper">
            <Grid container>
              <Grid item xs={12}>
                <ul>
                  {/* form errors are coming back in a weird format, so hiding this for now}
                  {/* {formErrors.map((e, i) => (
                    <li key={i}>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="button-icon"
                      />
                      <div className="error-message">{`${e.detail}`}</div>
                    </li>
                  ))} */}
                  <li>
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="button-icon"
                    />
                    <div className="error-message">
                      {t('VisitFeedbackPage.err.allFields')}
                    </div>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1">
            {t('VisitFeedbackPage.experience')}
          </Typography>
          <span>
            <Trans i18nKey="VisitFeedbackPage.thankFeedback" name={locName}>
              Thank you for providing feedback to help improve {{ locName }}
              &apos;s online schedule service
            </Trans>
          </span>
        </Grid>

        <Grid item xs={12}>
          <>
            <div className="label-question">
              {t('VisitFeedbackPage.recommendScheduling')}
            </div>

            <RadioGroup
              aria-label="Would Recommend"
              onChange={e => handleRadioChoice(e, 'wouldRecommend')}
              value={feedbackForm.wouldRecommend.value}
              row
            >
              <FormControlLabel
                value="Yes"
                label={t('VisitFeedbackPage.yes')}
                control={<CustomRadio />}
              />
              <FormControlLabel
                value="No"
                label={t('VisitFeedbackPage.no')}
                control={<CustomRadio />}
              />
              <FormControlLabel
                value="Unsure"
                label={t('VisitFeedbackPage.unsure')}
                control={<CustomRadio />}
              />
            </RadioGroup>
          </>
        </Grid>

        <Grid item xs={12}>
          <>
            <div className="label-question">
              {t('VisitFeedbackPage.projectedTime')}
            </div>

            <RadioGroup
              aria-label="Time waited"
              onChange={e => handleRadioChoice(e, 'timeWaited')}
              value={feedbackForm.timeWaited.value}
              row
            >
              <FormControlLabel
                value="<5 minutes"
                label={t('VisitFeedbackPage.less5Min')}
                control={<CustomRadio />}
              />
              <FormControlLabel
                value="5-30 minutes"
                label={t('VisitFeedbackPage.minutes30')}
                control={<CustomRadio />}
              />
              <FormControlLabel
                value="Over 30 minutes"
                label={t('VisitFeedbackPage.over30')}
                control={<CustomRadio />}
              />
            </RadioGroup>
          </>
        </Grid>

        <Grid item xs={12}>
          <>
            <div className="label-question">
              {t('VisitFeedbackPage.rateExp')}
            </div>
          </>
        </Grid>

        <Grid item xs={12}>
          <>
            <div>{t('VisitFeedbackPage.onlineSearch')}</div>

            <RadioGroup
              aria-label="Facility Rating"
              onChange={e => handleRadioChoice(e, 'inquickerRating')}
              value={feedbackForm.inquickerRating.value}
              row
            >
              <FormControlLabel
                value="1"
                label={t('VisitFeedbackPage.poor')}
                control={<CustomRadio />}
              />
              <FormControlLabel
                value="2"
                label={t('VisitFeedbackPage.fair')}
                control={<CustomRadio />}
              />
              <FormControlLabel
                value="3"
                label={t('VisitFeedbackPage.good')}
                control={<CustomRadio />}
              />
              <FormControlLabel
                value="4"
                label={t('VisitFeedbackPage.excellent')}
                control={<CustomRadio />}
              />
            </RadioGroup>
          </>
        </Grid>

        <Grid item xs={12}>
          <>
            <div>{`${t('VisitFeedbackPage.careTreatment')} ${location.name
              }`}</div>

            <RadioGroup
              aria-label="Facility Rating"
              onChange={e => handleRadioChoice(e, 'facilityRating')}
              value={feedbackForm.facilityRating.value}
              row
            >
              <FormControlLabel
                value="1"
                label={t('VisitFeedbackPage.poor')}
                control={<CustomRadio />}
              />
              <FormControlLabel
                value="2"
                label={t('VisitFeedbackPage.fair')}
                control={<CustomRadio />}
              />
              <FormControlLabel
                value="3"
                label={t('VisitFeedbackPage.good')}
                control={<CustomRadio />}
              />
              <FormControlLabel
                value="4"
                label={t('VisitFeedbackPage.excellent')}
                control={<CustomRadio />}
              />
              <FormControlLabel
                value="0"
                label="N/A"
                control={<CustomRadio />}
              />
            </RadioGroup>
          </>
        </Grid>
        <Grid item xs={12} sm={8}>
          <CustomSelect
            fieldKey="referer"
            fieldValue={feedbackForm.referer}
            label={`${t('VisitFeedbackPage.howDidYouHear')} ${location.name}`}
            items={refererOptions}
            validateFormHandler={e => handleSetFormState(e, 'referer')}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <CustomTextField
            fieldKey="comments"
            fieldValue={{ value: feedbackForm.comments.value }}
            label={t('VisitFeedbackPage.tellExp')}
            validateFormHandler={e => handleSetFormState(e, 'comments')}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <FormControlLabel
            control={
              <BlackCheckbox
                fieldKey="comments"
                fieldValue={feedbackForm.publish.value}
                validateFormHandler={e => handleSetFormState(e, 'publish')}
              />
            }
            label={<>{t('VisitFeedbackPage.shareFeedback')} </>}
          />
        </Grid>
        <Grid item xs={12}>
          <BrandingButton type="button" onClick={() => submit()}>
            {t('VisitFeedbackPage.submitFeedback')}
          </BrandingButton>
        </Grid>
      </Grid>
    </main>
  );
};

VisitFeedbackPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      visitId: PropTypes.string,
    }),
  }).isRequired,
};

export default VisitFeedbackPage;
