import normalize from 'json-api-normalizer';
import isEmpty from 'lodash/isEmpty';
import { getMultipleTimes } from 'api/availableTimesApi';
import * as providerApi from 'api/providerApi';
import * as types from './actionTypes';
import { beginApiCall, apiCallError } from './apiStatusActions';

function prepareSearchParamsData(apiData) {
  let found = false;
  let providerNames = [];
  let appointmentTypes = [];
  let specialityNames = [];
  let serviceNames = [];

  if (!isEmpty(apiData.providers)) {
    Object.values(apiData.providers.providers).forEach(provider => {
      if (provider.attributes.providerType !== "HealthResource") {
        providerNames.push({
          id: provider.id,
          name: provider.attributes.name,
          type: 'Provider',
          image: provider.attributes.profileImage,
        });

        provider.attributes.subspecialties.forEach(speciality => {
          found = specialityNames.find(item => item.name === speciality.name);
          if (!found) {
            specialityNames.push({
              id: speciality.id,
              name: speciality.name,
              type: 'Speciality',
            });
          }
        });

        //   provider.attributes.appointmentTypes.forEach(appointmentType => {
        //     found = appointmentTypes.find(
        //       item => item.value === appointmentType.id,
        //     );
        //     if (!found) {
        //       appointmentTypes.push({
        //         value: appointmentType.id,
        //         text: appointmentType.name,
        //       });
        //     }
        //   });
      }
    });

    let serviceIds = [];
    if (apiData.serviceIds) {
      if (Array.isArray(apiData.serviceIds)) {
        serviceIds = apiData.serviceIds.map(Number);
      } else {
        serviceIds = apiData.serviceIds.split(',').map(Number);
      }
    }

    // provides.schedules are being used at only place on while preparing the search data, I am not yet sure for case and imapct of removal because everything loads as it is if we remove the following code which are adding the missing service ids in search box.
    Object.values(apiData.services).forEach(service => {
      if (apiData.uxMode === 'default') {
        if (!serviceIds.includes(Number(service.id))) {
          return;
        }
      }
      found = serviceNames.find(item => Number(item.id) === Number(service.id));
      if (!found) {
        serviceNames.push({
          id: service.id,
          name: service.attributes.name,
          type: 'Service',
        });
      }
    });

    // appointmentTypes = [...appointmentTypes].sort((a, b) => {
    //   return a.text.localeCompare(b.text);
    // });

    specialityNames = [...specialityNames].sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    serviceNames = [...serviceNames].sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    providerNames = [...providerNames].sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  return {
    providerNames,
    specialityNames,
    serviceNames,
  };
}

export function updateSearchResults(providers) {
  return { type: types.UPDATE_SEARCH_RESULT, providers };
}

export function loadProviderSpecialityNamesSuccess(data) {
  const paramsData = prepareSearchParamsData(data);
  return { type: types.FETCH_DOCTOR_SPECIALITY_SUCCESS, paramsData };
}

export function beginSearch() {
  return { type: types.BEGIN_SEARCH };
}

export function endSearch() {
  return { type: types.END_SEARCH };
}

export function updateSearchOptions(searchOptions) {
  return { type: types.UPDATE_SEARCH_OPTIONS, searchOptions };
}

export function resetSearchResult() {
  return { type: types.RESET_SEARCH_RESULT };
}

export function clearSearch() {
  return { type: types.BACK_TO_LANDING_PAGE };
}

export function loadSearchProvidersResult(data) {
  let providers = [];
  let schedules = [];
  if (data.providers) providers = Object.values(data.providers);
  if (data.schedules) schedules = Object.values(data.schedules);
  return { type: types.UPDATE_SEARCH_RESULT, providers, schedules };
}

export function loadSearchSchedulesResult(data) {
  let schedules = [];
  if (data.schedules) schedules = Object.values(data.schedules);
  return { type: types.UPDATE_SEARCH_RESULT, schedules };
}

export function applySearchFilters({
  acceptNewPatient,
  genders,
  languages,
  hospitalAffiliationFilters,
}) {
  // eslint-disable-next-line func-names
  return async (dispatch, getState) => {
    const { providers } = getState();

    const found = providers.data.filter(provider => {
      let matched = true;
      const { acceptingNewPatients, gender } = provider.attributes;

      if (genders && genders.length > 0) {
        if (!genders.includes(gender)) matched = false;
      }

      if (acceptNewPatient) {
        if (acceptingNewPatients === 'no') matched = false;
      }

      if (languages && languages.length > 0) {
        const providerLanguages = provider.attributes.backgrounds.find(
          p => p.name === 'Language Spoken',
        );

        if (providerLanguages) {
          const canSpeak = providerLanguages.description.split('\n');
          if (!languages.some(() => languages.includes(canSpeak))) {
            matched = false;
          }
        }
      }

      if (hospitalAffiliationFilters && hospitalAffiliationFilters.length > 0) {
        let providerAffiliation = provider.attributes.backgrounds.find(
          p => p.name === 'Affiliation',
        );

        if (providerAffiliation) {
          providerAffiliation = providerAffiliation.description;
          if (!hospitalAffiliationFilters.includes(providerAffiliation)) {
            matched = false;
          }
        } else {
          matched = false;
        }
      }

      return matched;
    });

    dispatch(updateSearchResults(found));
  };
}

export function resetSearchFilters() {
  // eslint-disable-next-line func-names
  return async (dispatch, getState) => {
    const { providers } = getState();
    dispatch(updateSearchResults(providers.data));
  };
}

export function loadNames(latitude, longitude, venueId) {
  // eslint-disable-next-line func-names
  return async dispatch => {
    dispatch(beginApiCall());
    try {
      const data = await providerApi.getProviderSpecialityNames(
        latitude,
        longitude,
        venueId,
      );
      const action = loadProviderSpecialityNamesSuccess({
        providers: normalize(data),
        meta: { responseMeta: data.meta },
        uxMode: 'educational',
      });
      dispatch(action);
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function loadNameWithServiceIds(
  latitude,
  longitude,
  serviceIds,
  services,
) {
  // eslint-disable-next-line func-names
  return async dispatch => {
    dispatch(beginApiCall());
    try {
      const data = await providerApi.getProviderSpecialityNamesWithServiceIds(
        latitude,
        longitude,
        serviceIds,
        1,
        1,
      );

      if (data && data.meta) {
        const pageSize = 100; // Assuming each call fetches 100 records
        const metaCount = data.meta['record-count'];
        let allData = data; // Initialize with first set of data

        if (metaCount > 1) {
          const totalPages = Math.ceil(metaCount / pageSize);
          const promises = [];

          // eslint-disable-next-line no-plusplus
          for (let page = 1; page <= totalPages; page++) {
            const promise = providerApi.getProviderSpecialityNamesWithServiceIds(
              latitude,
              longitude,
              serviceIds,
              pageSize,
              page,
            );
            promises.push(promise);
          }
          // Merge the results of all API calls
          const additionalData = await Promise.all(promises);
          additionalData.forEach(pageData => {
            allData = {
              ...allData,
              data: allData.data.concat(pageData.data),
            };
          });
        }
        // Call success action with the merged data
        const action = loadProviderSpecialityNamesSuccess({
          providers: normalize(allData),
          meta: { responseMeta: allData.meta },
          uxMode: 'default',
          serviceIds,
          services,
        });
        dispatch(action);
      }
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function loadSearchProvidersSuccess({ providers, meta }) {
  return { type: types.FETCH_SEARCH_PROVIDERS_SUCCESS, providers, meta };
}

export function loadSearchSchedulesSuccess({ schedules, meta }) {
  return { type: types.FETCH_SEARCH_SCHEDULES_SUCCESS, schedules, meta };
}

export function searchProviders(searchOptions) {
  return async dispatch => {
    const options = searchOptions;

    if (
      !Array.isArray(searchOptions.serviceIds) &&
      !isEmpty(options.serviceIds)
    ) {
      options.serviceIds = options.serviceIds.split(',');
    }

    dispatch(resetSearchResult());
    dispatch(updateSearchOptions(options));
    dispatch(beginSearch());
  };
}

export function updateAppointmentTypeParam(id) {
  return async dispatch => {
    dispatch({ type: types.UPDATE_APPOINTMENT_TYPE, id });
  };
}

export function loadAvailableTimes(availableTimes) {
  return { type: types.FETCH_AVAILABLE_TIMES_SUCCESS, availableTimes };
}

export function loadScheduleTimes(scheduleIds, date, appointmentTypeId) {
  // eslint-disable-next-line func-names
  return async dispatch => {
    dispatch(beginApiCall());
    try {
      const data = await getMultipleTimes(scheduleIds, date, appointmentTypeId);
      const action = loadAvailableTimes(data);
      dispatch(action);
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}
