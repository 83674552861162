import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function healthSystemReducer(
  state = initialState.healthSystem,
  action,
) {
  switch (action.type) {
    case types.FETCH_HEALTH_SYSTEM_SUCCESS:
      localStorage.setItem('healthSystemId', action.health_system.id);
      return action.health_system;
    default:
      return state;
  }
}
