import { getEndpointFromURLSearchParams, getHeaderInfo } from 'api/apiUtils';

export function getInventoryResult(searchParams, abortController) {
  // eslint-disable-next-line func-names
  return async function(dispatch) {
    try {
      const isDischargePage =
        searchParams.get('filter[context]') === 'discharge';
      const response = await fetch(
        getEndpointFromURLSearchParams(`schedules`, searchParams.toString()),
        { ...getHeaderInfo(), signal: abortController.signal },
      );
      const results = await response.json();
      if (!isDischargePage) {
        const actualLength = results.data.length;
        const filteredData = results?.data.filter(
          d => d.attributes?.hidden !== true,
        );
        results.data = filteredData;
        const RECORD_COUNT = 'record-count';
        results.meta[RECORD_COUNT] =
          results.meta['record-count'] - (actualLength - filteredData.length);
      }
      dispatch({ type: 'SET_RESULTS', payload: { results } });
    } catch (err) {
      // console.error('In get inventory error => ', err);
    }
  };
}

export function singleScheduleFetch(results) {
  return async dispatch => dispatch({ type: 'SET_SCHEDULE', payload: results });
}
