import {
  handleResponse,
  handleError,
  getHeaderInfo,
  getEndpoint,
} from './apiUtils';

export async function getLocations(scheduleId) {
  return fetch(
    getEndpoint(`schedules/${scheduleId}/relationships/location`, {
      include: 'insurance-plans',
    }),
    getHeaderInfo(),
  )
    .then(handleResponse)
    .catch(handleError);
}

export async function getInsurancePlansForSchedule(scheduleId) {
  return fetch(
    getEndpoint(`schedules/${scheduleId}/insurance-plans`),
    getHeaderInfo(),
  )
    .then(handleResponse)
    .catch(handleError);
}

export async function getFacilityForSchedule(scheduleId) {
  return fetch(getEndpoint(`schedules/${scheduleId}/facility`), getHeaderInfo())
    .then(handleResponse)
    .catch(handleError);
}

/**
 * Constructs the API endpoint for searching against schedules.
 * @param {Object} searchOptions Object with user-input params.
 * @param {Number} page Active page number, default: 1
 * @param {Number} pageSize Results per page, default: 10
 * @return {String}
 */
const getSearchUrl = (searchOptions = {}, page = 1, pageSize = 10) => {
  const params = {
    include: 'location,provider,service,venue-of-care,insurance-plans',
    'page[number]': page,
    'page[size]': pageSize,
  };

  // urgency.
  if (searchOptions.urgency && searchOptions.urgency === 'later') {
    // TODO: pass the actual date and time
    params['filter[urgency]'] = searchOptions.urgency;
  }

  // by geocoded latitude and longitude
  if (searchOptions.latitude && searchOptions.longitude) {
    params[
      'filter[near]'
    ] = `${searchOptions.latitude},${searchOptions.longitude}`;

    // setting radius filter.
    if ('radius' in searchOptions && searchOptions.radius >= 0) {
      params.radius = searchOptions.radius;
    }
  }

  // provider name
  if (searchOptions.search) {
    params['filter[search]'] = searchOptions.search;
  }

  // insurance permalink
  if (searchOptions.insurancePlanPermalink) {
    params['filter[insurance]'] = searchOptions.insurancePlanPermalink;
  }

  // service name
  if (searchOptions.service) {
    params['filter[service]'] = searchOptions.service;
  }

  // appointment type
  if (searchOptions.appointmentTypeId) {
    params['filter[appointment_type]'] = searchOptions.appointmentTypeId;
  }
  return getEndpoint('schedules', params);
};

/**
 * Performs the API request.
 * @param {Object} searchOptions Object with user-input params.
 * @param {Number} page Active page number, default: 1
 * @param {Number} pageSize Results per page, default: 10
 */
export function search(searchOptions, page = 1, pageSize = 10) {
  return fetch(getSearchUrl(searchOptions, page, pageSize), getHeaderInfo())
    .then(handleResponse)
    .catch(handleError);
}
