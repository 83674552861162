import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function educationalPageReducer(
  state = initialState.educationalPage,
  action,
) {
  switch (action.type) {
    case types.SET_PROVIDER_SHOW_FORM:
      return {
        ...state,
        showForm: {
          ...state.showForm,
          provider: action.payload,
        },
      };
    case types.SET_PHYSICIAN_SHOW_FORM:
      return {
        ...state,
        showForm: {
          ...state.showForm,
          physician: action.payload,
        },
      };
    case types.BACK_TO_LANDING_PAGE:
      return initialState.educationalPage;
    default:
      return state;
  }
}
