import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import BreadCrumbs from 'components/common/BreadCrumbs';
import BrandingButton from 'components/common/BrandingButton';
import './VisitProhibitedPatientPage.scss';
import PageMetaInfo from '../common/PageMetaInfo';

export default function VisitProhibitedPatientPage() {
  const [t] = useTranslation();
  const history = useHistory();
  return (
    <div>
      {/* <PageMetaInfo /> */}
      <div className="container">
        <BreadCrumbs onlyHome />
      </div>
      <div className="container">
        <main className="VisitProhibitedPatient container">
          <h1>{t('error.prohibited_patient.title')}</h1>
          <p>{t('error.prohibited_patient.ending')}</p>
          <br />
          <br />
          <BrandingButton type="button" onClick={() => history.push('/')}>
            Return to Home Page
          </BrandingButton>
        </main>
      </div>
    </div>
  );
}
