import normalize from 'json-api-normalizer';
import {
  getServiceLines,
  getActiveServices,
  getActiveServicesByPermalink,
} from 'api/serviceLinesApi';
import * as types from './actionTypes';
import { beginApiCall, apiCallError } from './apiStatusActions';

const beginLoadServiceLines = () => {
  return { type: types.BEGIN_FETCH_SERVICE_LINES };
};

export function loadServiceLinesSuccess(serviceLines, services) {
  return { type: types.FETCH_SERVICE_LINES_SUCCESS, serviceLines, services };
}

export function loadDischargeServiceLinesSuccess(serviceLines, services) {
  return {
    type: types.FETCH_DISCHARGE_SERVICE_LINES_SUCCESS,
    serviceLines,
    services,
  };
}

export function setDischargeCalled(value) {
  return {
    type: types.FETCH_DISCHARGE_CALLED,
    value,
  };
}

export function loadDischargeActiveServices() {
  // eslint-disable-next-line func-names
  return async function (dispatch) {
    try {
      dispatch(setDischargeCalled(true));
      const data = await getActiveServices('discharge');
      const { services, serviceLines } = normalize(data);
      dispatch(loadDischargeServiceLinesSuccess(serviceLines, services));
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function loadFilteredActiveServices(service) {
  // eslint-disable-next-line func-names
  return async function (dispatch) {
    try {
      dispatch(beginApiCall());
      dispatch(beginLoadServiceLines());
      const data = await getActiveServicesByPermalink(service, 'patient');
      const { services, serviceLines } = normalize(data);
      dispatch(loadServiceLinesSuccess(serviceLines, services));
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function loadActiveServices(isDischargePage) {

  // eslint-disable-next-line func-names
  return async function (dispatch) {
    dispatch(beginApiCall());
    dispatch(beginLoadServiceLines());
    try {
      const data = await getActiveServices(
        isDischargePage ? 'discharge' : 'patient',
      );
      const { services, serviceLines } = normalize(data);
      dispatch(loadServiceLinesSuccess(serviceLines, services));
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function loadServiceLines() {
  // eslint-disable-next-line func-names
  return async function (dispatch) {
    dispatch(beginApiCall());
    dispatch(beginLoadServiceLines());
    try {
      const data = await getServiceLines();
      const { services, serviceLines } = normalize(data);
      dispatch(loadServiceLinesSuccess(serviceLines, services));
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}
