import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

export const useFacilityServices = () => {
  const serviceLines = useSelector(state => state.serviceLines.serviceLines);
  const [serviceIdsState, setServiceIdsState] = useState({});
  const [facilityServices, setFacilityServices] = useState({});
  const [specialServices, setSpecialServices] = useState([]);
  const [t] = useTranslation();
  const mergedId = '0';
  const mergeToProviderServicesIds = isMobile
    ? ['specialty-care']
    : ['primary-care', 'specialty-care'];
  const mergedService = {
    [mergedId]: {
      id: mergedId,
      attributes: {
        name: t('useFacilityServices.providerServices'),
        permalink: 'provider-services',
      },
    },
  };

  const parentServiceIdIsProviderService = parentServiceId => {
    return (
      serviceLines[parentServiceId] &&
      serviceLines[parentServiceId].attributes &&
      serviceLines[parentServiceId].attributes.permalink &&
      mergeToProviderServicesIds.indexOf(
        serviceLines[parentServiceId].attributes.permalink,
      ) > -1
    );
  };

  const getAndSetServiceIdsState = () => {
    const ids = {};
    let mergedList = [];
    const spList = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [id, service] of Object.entries(serviceLines)) {
      if (parentServiceIdIsProviderService(id)) {
        mergedList = [
          ...mergedList,
          ...service.relationships.services.data.map(obj => obj.id),
        ];
        spList.push({
          id,
          services: {
            ...service.relationships.services.data.map(obj => obj.id),
          },
          permalink: serviceLines[id].attributes.permalink,
        });
        ids[id.toString()] = service.relationships.services.data.map(
          obj => obj.id,
        );
      } else {
        ids[id.toString()] = service.relationships.services.data.map(
          obj => obj.id,
        );
      }
    }
    if (spList.length === 2) {
      setServiceIdsState({
        [mergedId]: mergedList,
        ...ids,
      });
    } else if (spList.length === 1) {
      const serviceId = spList[0]?.id;
      setServiceIdsState({
        [serviceId]: mergedList,
        ...ids,
      });
    } else {
      setServiceIdsState({
        [mergedId]: mergedList,
        ...ids,
      });
    }
    setSpecialServices(spList);
  };

  useEffect(() => {
    if (
      Object.keys(serviceLines).length &&
      Object.keys(facilityServices).length === 0
    ) {
      setFacilityServices({
        ...mergedService,
        ...serviceLines,
      });
      getAndSetServiceIdsState();
    }
  }, [serviceLines]);

  return { facilityServices, serviceIdsState, specialServices };
};
