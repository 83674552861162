import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import moment from 'moment-timezone';
import { FormHelperText, Grid } from '@material-ui/core';
import CustomSelect from './CustomSelect';
import { useTranslation } from 'react-i18next';

const DateFields = ({
  errorMessage,
  handleDateChange,
  hasError,
  label,
  required,
}) => {
  const [fullDate, setFullDate] = useState({
    month: {
      value: '',
    },
    day: {
      value: '',
    },
    year: {
      value: '',
    },
  });
  const [t] = useTranslation();
  useEffect(() => {
    if (
      !!fullDate.month.value &&
      !!fullDate.day.value &&
      !!fullDate.year.value
    ) {
      handleDateChange(fullDate);
    }
  }, [fullDate]);

  const handleChange = (e, key) => {
    setFullDate({
      ...fullDate,
      [key]: e[key],
    });
  };

  const monthOptions = [
    { value: '01', text: 'January' },
    { value: '02', text: 'February' },
    { value: '03', text: 'March' },
    { value: '04', text: 'April' },
    { value: '05', text: 'May' },
    { value: '06', text: 'June' },
    { value: '07', text: 'July' },
    { value: '08', text: 'August' },
    { value: '09', text: 'September' },
    { value: '10', text: 'October' },
    { value: '11', text: 'November' },
    { value: '12', text: 'December' },
  ];

  const dayOptions = range(31).map(d => {
    return { value: d + 1, text: d + 1 };
  });

  const yearOptions = range(parseInt(moment().format('YYYY'), 10), 1901).map(
    m => {
      return { value: m, text: m };
    },
  );

  return (
    <div className="DateFields">
      <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
        {required && <span className="required">* </span>}
        {label}
      </div>

      <Grid container justify="space-between">
        <Grid item sm={4} xs={12} className="form-item">
          <CustomSelect
            hasError={hasError}
            fieldKey="month"
            fieldValue={fullDate.month}
            label={t('DateFields.month')}
            items={monthOptions}
            validateFormHandler={e => handleChange(e, 'month')}
          />
        </Grid>

        <Grid item sm={3} xs={12} className="form-item">
          <CustomSelect
            hasError={hasError}
            fieldKey="day"
            fieldValue={fullDate.day}
            label={t('DateFields.day')}
            items={dayOptions}
            validateFormHandler={e => handleChange(e, 'day')}
          />
        </Grid>

        <Grid item sm={4} xs={12} className="form-item">
          <CustomSelect
            hasError={hasError}
            fieldKey="year"
            fieldValue={fullDate.year}
            label={t('DateFields.year')}
            items={yearOptions}
            validateFormHandler={e => handleChange(e, 'year')}
          />
        </Grid>

        {hasError && (
          <FormHelperText error={hasError}>{errorMessage}</FormHelperText>
        )}
      </Grid>
    </div>
  );
};

DateFields.defaultProps = {
  required: false,
};

DateFields.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

export default DateFields;
