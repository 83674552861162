import normalize from 'json-api-normalizer';
import { isEmpty } from 'lodash';
import * as types from './actionTypes';
import * as providerApi from '../../api/providerApi';
import { beginApiCall, apiCallError } from './apiStatusActions';

const selectRegionFromHealthSystem = state => state.healthSystem?.region;

export function loadProvidersSuccess({
  providers,
  locations,
  schedules,
  meta,
}) {
  return {
    type: types.FETCH_PROVIDERS_SUCCESS,
    providers,
    locations,
    schedules,
    meta,
  };
}

export function beginLoadProvider() {
  return { type: types.BEGIN_FETCH_PROVIDER };
}
function filterFacilitiesByRegionName(facilities, regionName) {
  // Filter facilities based on region name
  const filteredFacilities = Object.fromEntries(
    Object.entries(facilities).filter(([key, facility]) => {
      const region = facility.attributes.facilityRegion;
      // Exclude if region is null or name doesn't match
      return region && region.name === regionName;
    }),
  );

  return filteredFacilities;
}

function filterLocationsByFacilities(locations, facilities) {
  // Create a Set of facility names from the filtered facilities
  const validFacilityNames = new Set(
    Object.values(facilities).map(facility => facility.attributes.name),
  );
  // Filter locations based on the facility name
  const filteredLocations = Object.fromEntries(
    Object.entries(locations).filter(([key, location]) => {
      return validFacilityNames.has(location.attributes.facilityName);
    }),
  );

  return filteredLocations;
}
// This is to shuffle the schedules based on locations sequence
function shuffleSchedulesByLocationOrder(locations, schedules) {
  const orderedSchedulesArray = [];
  // Iterate over the locations in the provided sequence
  Object.keys(locations).forEach(locationId => {
    // Find matching schedules for the current locationId
    Object.values(schedules).forEach(schedule => {
      if (schedule.relationships.location.data.id === locationId) {
        // Push the matching schedule to the orderedSchedulesArray
        // ` ${schedule.id}` is really important to maintain the keys as string.
        orderedSchedulesArray.push({ [` ${schedule.id}`]: schedule });
      }
    });
  });
  // Convert the ordered array back into a single object with the same format
  const orderedSchedulesObject = Object.assign({}, ...orderedSchedulesArray);
  return orderedSchedulesObject;
}

export function shuffleSchedulesByLocationOrderMobile(locations, schedules) {
  const orderedSchedulesArray = [];
  // Iterate over the locations in the provided sequence
  locations.forEach(location => {
    // Find matching schedules for the current locationId
    Object.values(schedules).forEach(schedule => {
      if (schedule.relationships.location.data.id === location.id) {
        // Push the matching schedule to the orderedSchedulesArray
        orderedSchedulesArray.push(schedule);
      }
    });
  });
  return orderedSchedulesArray;
}

export function loadProviderSuccess({ provider, region }) {
  let modifiedProvider = {};
  if (region !== null) {
    const facilities = filterFacilitiesByRegionName(
      provider.facilities,
      region.name,
    );
    const locations = filterLocationsByFacilities(
      provider.locations,
      facilities,
    );
    const schedules = shuffleSchedulesByLocationOrder(
      locations,
      provider.schedules,
    );
    modifiedProvider = { ...provider, schedules, facilities, locations };
  } else {
    const schedules = shuffleSchedulesByLocationOrder(
      provider.locations,
      provider.schedules,
    );
    modifiedProvider = { ...provider, schedules };
  }
  return { type: types.FETCH_PROVIDER_SUCCESS, provider: modifiedProvider };
}

export function loadProviderNotFound() {
  return { type: types.PROVIDER_NOT_FOUND };
}

export function loadProvider(id, permalink, context = 'patient') {
  // eslint-disable-next-line func-names
  return async (dispatch, getState) => {
    dispatch(beginApiCall());
    dispatch(beginLoadProvider());
    try {
      let provider = null;
      let data = null;
      const region = selectRegionFromHealthSystem(getState());
      if (!Number.isNaN(Number(id))) {
        // If id is number, try to load provider with id
        // To handle new refresh link, in the format of https://host/providers/[id]
        data = await providerApi.getProvider(id, context);
        if (data.data) {
          provider = normalize(data);
        }
      } else if (!isEmpty(permalink)) {
        // If id is not number, try to load provider with permalink
        // To handle legacy link, in the format of https://host/provider/[permalink]
        const searchParams = {
          permalink,
          ignore_scheduling_restrictions: true,
        };
        data = await providerApi.searchProviders(searchParams);
        if (data && data.data[0]) {
          // Add permalink to payload since api doesn't return permalink
          data.data[0].attributes.permalink = permalink;
          provider = normalize(data);
        }
      }

      if (provider) {
        const action = loadProviderSuccess({
          provider,
          region,
        });
        dispatch(action);
      } else {
        dispatch(loadProviderNotFound());
      }
    } catch (error) {
      dispatch(loadProviderNotFound());
      dispatch(apiCallError(error));
      throw error;
    }
  };
}

export function loadProviders(page = 1, pageSize = 4) {
  // eslint-disable-next-line func-names
  return async dispatch => {
    dispatch(beginApiCall());
    try {
      const data = await providerApi.getProviders(page, pageSize);
      const normalizedData = normalize(data);
      const { providers, locations, schedules } = normalizedData;
      const actionParams = {
        providers,
        locations,
        schedules,
        meta: {
          page,
          responseMeta: data.meta,
        },
      };
      const action = loadProvidersSuccess(actionParams);
      dispatch(action);
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}
