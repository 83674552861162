import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { findSettingValue, getNumberOrNull, getSettingValue } from 'utils/scheduledDaysUtils';
import { useQueryString } from './useQueryString';
import { getProviderSchedulesById } from 'redux/reducers/providersReducer';
import { isMobile } from 'react-device-detect';

function useSettings(ids) {
    const settings = useSelector(state => state.settingsReducer);
    let dynamicIds = {};
    const location = useLocation();
    const healthSystem = useSelector(state => state.healthSystem);
    const facResults = useSelector(
        state => state.facilitiesReducer.availableFacilities,
    );
    const schedule = useSelector(state => state?.scheduleDetailsReducer);
    const providers = useSelector(state => state?.providers);
    const visit = useSelector(state => state.visitReducer);
    const serviceLines = useSelector(state => state.serviceLines.serviceLines);
    const services = useSelector(state => state.serviceLines.services);
    const { params } = useQueryString();
    const isProviderDetailPage = useRouteMatch({
        path: [
            '/providers/:id',
            '/provider/:permalink',
            '/:dischargeFacility/discharge/providers/:id',
            '/:dischargeFacility/discharge/provider/:permalink',
        ],
        strict: true,
        sensitive: true,
        exact: true,
    });

    const isInventoryPage = useRouteMatch({
        path: [
            '/search',
        ],
        strict: true,
        sensitive: true,
        exact: true,
    });

    let isServiceLinePage = useRouteMatch({
        path: ['/:serviceLine/search', '/:serviceLine', '/:serviceLine/'],
        strict: true,
        sensitive: true,
        exact: true,
    });

    const winLoc = window.location.pathname;
    const conflictingPaths = ['/schedule/calendar', '/search', '/providers', '/provider', '/facility', '/visits'];
    const hasConflict = conflictingPaths.some(path => winLoc.includes(path));
    const isDischargePath = winLoc.split('/').includes('discharge')

    let isCityStatePage = useRouteMatch({
        path: [
            '/:serviceLine/:state/:city/search',
            '/:serviceLine/:state/:city',
            '/:serviceLine/:state/:city/',
        ],
        strict: true,
        sensitive: true,
        exact: true,
    });

    if (hasConflict || isDischargePath) {
        isServiceLinePage = null;
        isCityStatePage = null;
        console.log('Path conflict found:', winLoc);
    }

    const isDischargePage = useRouteMatch({
        path: [
            '/:dischargeFacility/discharge',
            '/:dischargeFacility/discharge/:locationPermalink',
            '/:dischargeFacility/discharge/service/:serviceLine',
            '/:dischargeFacility/discharge/:locationPermalink/service/:serviceLine',
        ],
        strict: true,
        sensitive: true,
        exact: true,
    });

    const isFacilityPage = useRouteMatch({
        path: [
            '/facility/:facilityName/service/:serviceLine',
            '/facility/:facilityName',
            '/:dischargeFacility/discharge',
            '/:dischargeFacility/discharge/service/:serviceLine',
        ],
        strict: true,
        sensitive: true,
        exact: true,
    });

    const isLocationPage = useRouteMatch({
        path: [
            '/facility/:facilityName/:locationPermalink',
            '/facility/:facilityName/:locationPermalink/service/:serviceLine',
            '/:dischargeFacility/discharge/:locationPermalink',
            '/:dischargeFacility/discharge/:locationPermalink/service/:serviceLine',
        ],
        strict: true,
        sensitive: true,
        exact: true,
    });

    const isScheduleDetailPage = useRouteMatch({
        path: [
            '/schedule/calendar/:id',
            '/:dischargeFacility/discharge/schedule/calendar/:id',
        ],
        strict: true,
        sensitive: true,
        exact: true,
    });

    const isVisitCancelPage = useRouteMatch({
        path: ['/visits/:visitId/cancel', '/visits/:visitId/reschedule'],
        strict: true,
        sensitive: true,
        exact: true,
    });
    
    if ((isInventoryPage || (isServiceLinePage || isCityStatePage && !hasConflict)) && Object.keys(services).length) {
        const facilityId = getNumberOrNull(ids?.caller === 'FacilityDateCarousel' || ids?.caller === 'AvailableTimesToday' ? ids.facilityId : null)
        const regionId = getNumberOrNull(ids?.caller === 'FacilityDateCarousel' || ids?.caller === 'AvailableTimesToday' ? ids.regionId : null)
        if (params.service === 'find-a-doctor')
            dynamicIds = {
                healthSystemId: getNumberOrNull(healthSystem?.id),
                regionId: getNumberOrNull(healthSystem?.region?.id) || regionId,
                serviceId: null,
                appointmentTypeTemplateId: getNumberOrNull(params.appointmentTypes),
                facilityId
            }
        else {
            const value = Object.values(services).find(x => x?.attributes?.permalink === params.service);
            dynamicIds = {
                healthSystemId: getNumberOrNull(healthSystem?.id),
                regionId: getNumberOrNull(healthSystem?.region?.id) || regionId,
                serviceId: getNumberOrNull(value?.id),
                appointmentTypeTemplateId: getNumberOrNull(params.appointmentTypes),
                facilityId
            }
        }
        const availableScheduleDays = findSettingValue(settings, dynamicIds);
        console.log(availableScheduleDays, dynamicIds, isInventoryPage, isServiceLinePage, isCityStatePage, hasConflict, 'availableScheduleDays isInventoryPage');
        return availableScheduleDays;
    }

    if (isScheduleDetailPage && Object.keys(services).length && Object.keys(schedule?.schedule).length) {
        const { service, appointmentTypes } = params
        const facilityId = schedule?.schedule?.relationships?.facility?.data?.id
        const serviceId = schedule?.schedule?.attributes?.service?.id
        const regionId = getNumberOrNull(schedule?.facility?.['facility-region']?.id || null)
        // const value = Object.values(services).find(x => x?.attributes?.permalink === service);
        const dynamicIds = {
            healthSystemId: getNumberOrNull(healthSystem?.id),
            regionId: getNumberOrNull(healthSystem?.region?.id) || regionId,
            serviceId: getNumberOrNull(serviceId),
            appointmentTypeTemplateId: getNumberOrNull(appointmentTypes),
            facilityId: getNumberOrNull(facilityId)
        }
        const availableScheduleDays = findSettingValue(settings, dynamicIds);
        console.log(availableScheduleDays, isScheduleDetailPage, dynamicIds, isInventoryPage, 'availableScheduleDays isScheduleDetailPage');
        return availableScheduleDays;
    }


    if (isProviderDetailPage && providers?.data?.length) {
        const { appointmentTypes } = params;
        const schedule = providers.schedules.find(
            s => s.id === ids.scheduleId,
        );
        const facility = Object.values(providers.facilities)?.find(
            f => f?.id === schedule?.relationships?.facility?.data?.id,
        );
        const dynamicIds = {
            healthSystemId: getNumberOrNull(healthSystem?.id),
            regionId: getNumberOrNull(healthSystem?.region?.id) || getNumberOrNull(facility?.attributes?.facilityRegion?.id || null),
            facilityId: getNumberOrNull(schedule?.relationships?.facility?.data?.id),
            serviceId: getNumberOrNull(schedule?.attributes?.service?.id),
            appointmentTypeTemplateId: getNumberOrNull(appointmentTypes),
        }
        const availableScheduleDays = findSettingValue(settings, dynamicIds);
        console.log(availableScheduleDays, providers, ids, dynamicIds, schedule, facility, 'availableScheduleDays isProviderDetailPage');
        return availableScheduleDays;
    }
    if (isFacilityPage || isLocationPage || isDischargePage && Object.keys(services).length && facResults?.data?.length && params?.service) {
        const serviceLine = Object.values(serviceLines).find(x => x?.id === params.service);
        const value = Object.values(services).find(x => x?.attributes?.permalink === serviceLine?.attributes?.permalink);
        const { appointmentTypeId } = params;

        dynamicIds = {
            healthSystemId: getNumberOrNull(healthSystem?.id),
            regionId: getNumberOrNull(healthSystem?.region?.id),
            facilityId: getNumberOrNull(facResults?.data?.[0]?.id),
            serviceId: getNumberOrNull(value?.id),
            appointmentTypeTemplateId: getNumberOrNull(appointmentTypeId),
        }

        const availableScheduleDays = findSettingValue(settings, dynamicIds, isMobile);
        console.log(availableScheduleDays, dynamicIds, 'availableScheduleDays isFacilityPage');
        return availableScheduleDays;
    }

    if (isVisitCancelPage) {
        const schedule = visit?.included?.find(s => s?.type === 'schedules');
        const facility = visit?.included?.find(f => f?.type === 'facilities');
        const appointmentTypeId = visit?.data?.attributes?.['appointment-type'];
        const regionId =
        getNumberOrNull(healthSystem?.region?.id) ||
        getNumberOrNull(facility?.attributes?.['facility-region']?.id || null);
        const dynamicIds = {
            healthSystemId: getNumberOrNull(healthSystem?.id),
            regionId,
            facilityId: getNumberOrNull(facility?.id),
            serviceId: getNumberOrNull(schedule?.attributes?.service?.id),
            appointmentTypeTemplateId: getNumberOrNull(appointmentTypeId),
        };
        const availableScheduleDays = findSettingValue(settings, dynamicIds);
        console.log(
            availableScheduleDays,
            ids,
            dynamicIds,
            'availableScheduleDays isVisitCancelPage',
        );
        return availableScheduleDays;
    }
}

export default useSettings;
