import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RoomIcon from '@material-ui/icons/Room';
import ZipcodeField from 'components/form/ZipcodeField';
import DialogMobile from './DialogMobile';
import {
  clearGeocoding,
  loadGeocoding,
} from '../../redux/actions/geocodingActions';

const DialogZipCode = ({
  handleShowDialog,
  loadGeocoding,
  showDialog,
  geocoding,
}) => {
  const { zipcode } = geocoding;

  const [oldZipcode, setOldZipcode] = useState('');

  /**
   * This effect runs when the dialog opens or closes.
   * It pins a zipcode to local state, to support
   * cancel functionality.
   */
  useEffect(() => {
    setOldZipcode(zipcode);
  }, [showDialog]);

  /**
   * Handles any geocoding logic needed prior to closing the dialog.
   * @param {Boolean} value Reason dialog is closing.
   *   True for 'Apply', False for 'Cancel'.
   */
  const closeDialog = value => {
    // If true, ie. user clicks 'Apply':
    if (value) {
      return handleShowDialog(zipcode);
    }
    // If false, ie. user clicks 'Cancel':
    // Load the previous zipcode (from when they opened the dialog).
    loadGeocoding(oldZipcode);
    return handleShowDialog(oldZipcode);
  };

  return (
    <DialogMobile
      content={<div />}
      handleShowDialog={e => {
        closeDialog(e);
      }}
      icon={<RoomIcon />}
      showDialog={showDialog}
      title={
        <div style={{ padding: '10px 0' }}>
          <ZipcodeField filled />
        </div>
      }
    />
  );
};

DialogZipCode.propTypes = {
  loadGeocoding: PropTypes.func.isRequired,
  handleShowDialog: PropTypes.func.isRequired,
  showDialog: PropTypes.bool.isRequired,
  geocoding: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => {
  return {
    geocoding: state.geocoding,
  };
};

const mapDispatchToProps = {
  clearGeocoding,
  loadGeocoding,
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogZipCode);
