import React from 'react';
import BreadCrumbs from 'components/common/BreadCrumbs';
import PageError from './PageError';

const PageServerError = () => {
  return (
    <div>
      <div className="container">
        <BreadCrumbs onlyHome />
      </div>
      <PageError
        title="Oh no!"
        subtext="Something unexpected has occurred."
        content="Try to refresh or feel free to contact us if the problem persist."
        code="500"
      />
    </div>
  );
};

export default PageServerError;
