import apiKeys from '../config/apiKeys.json';
import mcaUrls from '../config/mcaUrls.json';

const parser = require('parse-domain');

export async function handleResponse(response) {
  if (response.status === 400 || response.status === 404) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message,
    // so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  if (response.ok) {
    const jsonData = response.json();
    return jsonData;
  }
  throw new Error('Network response was not ok.');
}

/**
 * Parse response as text instead of JSON.
 * @param {Object} response
 * @return {String}
 */
export async function handleTokenResponse(response) {
  if (response.ok) return response.text();
  throw new Error('Token response was not ok.');
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error(`API call failed. ${error}`);
  throw error;
}

export function getHeaderInfo(method = 'GET') {
  const token = localStorage.getItem('jwt');
  const session = sessionStorage.getItem('sessionId');
  const healthSystemId = localStorage.getItem('healthSystemId');
  const speciality =
    localStorage.getItem('speciality') === 'undefined'
      ? ''
      : localStorage.getItem('speciality');
  const voc =
    localStorage.getItem('voc') === 'undefined'
      ? ''
      : localStorage.getItem('voc');
  const zip =
    localStorage.getItem('zipCode') === 'undefined'
      ? ''
      : localStorage.getItem('zipCode');
  const headerInfo = {
    'Content-Type': 'application/json',
    Accept: 'application/vnd.api+json',
    Authorization: `Bearer ${token}`,
    Locale: localStorage.getItem('i18nextLng') || 'en-US',
    Session: session,
    HealthSystemId: healthSystemId,
    VoC: voc,
    Zip: zip,
    Speciality: speciality,
  };

  return { method, headers: headerInfo };
}

/**
 * Build and return the API url for the environment.
 * Defined in apiKeys json file.
 * @return {String}
 */
export function getBaseUrl() {
  const { hostname } = window.location;

  if (hostname === 'localhost') {
    return apiKeys[hostname].apiUrl;
  }

  const { subdomain } = parser(window.location.href);
  return apiKeys[subdomain].apiUrl;
}

export function getMcaUrl() {
  const { hostname } = window.location;
  if (hostname === 'localhost') {
    return mcaUrls[hostname]?.url;
  }

  const env = window.location.origin.split('.')[1];
  return mcaUrls[env]?.url;
}

/**
 * Build and return the Endpoint url.
 * @param {String} resource
 * @param {Object} params (optional)
 * @return {String}
 */

export function getEndpoint(resource, params) {
  let url = `${getBaseUrl()}/${resource}`;
  const prefix = url.includes('?') ? '&' : '?';
  if (resource === 'available-appointment-times') {
    url += `${prefix}${new URLSearchParams(params)}`;
  } else {
    const queryParams = `${new URLSearchParams(params) +
      (params ? '&' : '')}locale=${localStorage.getItem('i18nextLng') ||
      'en-US'}`;
    url += `${prefix}${queryParams}`;
  }
  return url;
}


/**
 * Build and return the Endpoint url.
 * @param {String} resource
 * @param {URLSearchParams} searchParams
 * @return {String}
 */
export function getEndpointFromURLSearchParams(resource, searchParams) {
  return `${getBaseUrl()}/${resource}?${searchParams.toString()}`;
}

/**
 * Returns the API key as defined in apiKeys json file.
 * @return {String}
 */
export function getApiKey() {
  const { hostname } = window.location;

  if (hostname === 'localhost') {
    return apiKeys[hostname].apiKey;
  }

  const { subdomain } = parser(window.location.href);

  return apiKeys[subdomain].apiKey;
}

export function getSubDomain() {
  const { hostname } = window.location;

  if (hostname === 'localhost') {
    return 'ascensionhealth';
  }

  const { subdomain } = parser(window.location.href);

  return subdomain;
}

export function getHealthSystem() {
  const { hostname } = window.location;

  if (hostname === 'localhost') {
    return apiKeys[hostname].healthSystem;
  }

  const { subdomain } = parser(window.location.href);

  return apiKeys[subdomain].healthSystem;
}

