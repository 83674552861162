import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
} from '@material-ui/core';
import { range } from 'lodash';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomSelect from '../CustomSelect';
import CustomRadio from '../CustomRadio';

const weekOptions = range(50).map(d => {
  return { value: d + 1, text: d + 1 };
});

const PregnantFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const hasPregnantError = formContext.hasError('pregnant');
  const [t] = useTranslation();
  return (
    <>
      {formContext.isFieldEnabled('pregnant') &&
        formContext.form.gender.value === 'female' && (
          <div className="form-section_row">
            <div className="pregnant-fields">
              <div className="label-question">
                {formContext.isFieldRequired('pregnant') && (
                  <span className="required">* </span>
                )}
                {t('PregnantFields.Question')}
              </div>

              <FormControl
                variant="outlined"
                fullWidth
                error={hasPregnantError}
              >
                <RadioGroup
                  row
                  onChange={e =>
                    formContext.handleSetFormState({
                      pregnant: { value: e.target.value },
                    })
                  }
                >
                  <FormControlLabel
                    value="yes"
                    control={<CustomRadio />}
                    label={t('VisitFeedbackPage.yes')}
                  />
                  <FormControlLabel
                    value="no"
                    control={<CustomRadio />}
                    label={t('VisitFeedbackPage.no')}
                  />
                </RadioGroup>

                {hasPregnantError && (
                  <FormHelperText error={hasPregnantError}>
                    {formContext.getError('pregnant')}
                  </FormHelperText>
                )}
              </FormControl>

              {formContext.form.pregnant.value === 'yes' && (
                <div className="form-section_row">
                  <CustomSelect
                    required
                    hasError={hasPregnantError}
                    fieldKey="weeksPregnant"
                    fieldValue={formContext.form.weeksPregnant}
                    label={t('PregnantFields.weeksPregnant')}
                    items={weekOptions}
                    validateFormHandler={formContext.handleSetFormState}
                  />
                </div>
              )}
            </div>
          </div>
        )}
    </>
  );
};

export default PregnantFields;
