import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  RadioGroup,
} from '@material-ui/core';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomRadio from '../CustomRadio';
import CustomTextField from '../CustomTextField';

const EmployerPatientFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const hasError = formContext.hasError('employer-or-patient');
  const [t] = useTranslation();

  return (
    <>
      {(formContext.isFieldEnabled('employer-or-patient') ||
        formContext.isFieldRequired('employer-or-patient')) && (
        <div className="form-section_row">
          <div className="label-question">
            {formContext.isFieldRequired('employer-or-patient') && (
              <span className="required">* </span>
            )}
            {t('EmployerPatientFields.employer-or-patient')}
          </div>

          <FormControl variant="outlined" fullWidth error={hasError}>
            <RadioGroup
              row
              onChange={e =>
                formContext.handleSetFormState({
                  employerOrPatient: { value: e.target.value },
                })
              }
            >
              <FormControlLabel
                value="patient"
                control={<CustomRadio />}
                label={t('EmployerPatientFields.patient')}
              />
              <FormControlLabel
                value="employer"
                control={<CustomRadio />}
                label={t('EmployerPatientFields.employer')}
              />
            </RadioGroup>

            {hasError && (
              <FormHelperText error={hasError}>
                {formContext.getError('employer-or-patient')}
              </FormHelperText>
            )}
          </FormControl>

          {formContext.form.employerOrPatient.value === 'employer' && (
            <div className="form-section_row">
              <Grid container spacing={4}>
                <Grid item sm={4} xs={12}>
                  <CustomTextField
                    fieldKey="employerAuthorizer"
                    fieldValue={formContext.form.employerAuthorizer}
                    validateFormHandler={formContext.handleSetFormState}
                    label="Authorizer"
                    required={formContext.isFieldRequired(
                      'employer-authorizer',
                    )}
                    shrink
                    hasError={hasError}
                    errorMessage={formContext.getError('employer-authorizer')}
                  />
                </Grid>

                <Grid item sm={4} xs={12}>
                  <CustomTextField
                    fieldKey="employerCompanyName"
                    fieldValue={formContext.form.employerCompanyName}
                    validateFormHandler={formContext.handleSetFormState}
                    label="Company Name"
                    required={formContext.isFieldRequired(
                      'employer-company-name',
                    )}
                    shrink
                    hasError={hasError}
                    errorMessage={formContext.getError('employer-company-name')}
                  />
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EmployerPatientFields;
