import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import camel from 'camelcase-keys';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { getEndpoint, getHeaderInfo } from 'api/apiUtils';
import NextAvailableAt from 'components/common/NextAvailableAt';
import ScheduleItButton from 'components/common/ScheduleItButton';
import MoreAvailableDoctors from 'components/common/MoreAvailableDoctors';
import ResultListLocation from 'components/inventory/ResultListLocation';
import EducationalImage from 'components/educational/EducationalImage';
import emergencyRoomImage from 'assets/images/vocImages/default_emergency_room_md.jpeg';
import urgentCareImage from 'assets/images/vocImages/default_urgent_care_md.jpeg';
import './EducationalScheduleCard.scss';

const EducationalScheduleCard = ({
  schedule,
  location,
  provider,
  venueType,
  appointmentType,
}) => {
  const theme = useTheme();

  // TODO: Make Location Optional
  // if location.attributes doesn't exist, location.attributes = {}
  // also, consider moving this line lower after useEffect stuff, then you can
  // do a check on location first.
  const timezoneName = location.attributes
    ? location.attributes['time-zone']
    : '';

  //  If a location / provider only has one associated appointment type,
  //  always find times for that.  Otherwise, find times for the selected
  //  appointment type in the search.
  const getAppointmentTypeId = () => {
    if (!schedule.attributes) {
      schedule.attributes = {};
    }
    const scheduleAppointmentTypes = schedule.attributes['appointment-types'];
    if (scheduleAppointmentTypes && scheduleAppointmentTypes.length === 1) {
      return scheduleAppointmentTypes[0].id;
    }
    return appointmentType;
  };

  const params = {
    max_times: 1,
    'schedule_ids[]': schedule.id,
    appointment_type_id: getAppointmentTypeId(),
  };
  const endpoint = getEndpoint('available-appointment-times', params);
  const [availableTime, setAvailableTime] = useState({});
  const [hasTimes, setHasTimes] = useState(false);

  useEffect(() => {
    (async () => {
      fetch(endpoint, getHeaderInfo())
        .then(resp => resp.json())
        .then(result => {
          const availableObj = result.shift();
          setAvailableTime(availableObj);
          setHasTimes(
            !availableObj ||
              (!!availableObj.times && !!availableObj.times.length),
          );
        });
    })();
    return function cleanup() {
      setAvailableTime({});
      setHasTimes(false);
    };
  }, [setAvailableTime, setHasTimes, endpoint]);

  const renderScheduleName = () => {
    const queryParam = `?appointmentTypes=${appointmentType}&nowContext=true`;
    if (!schedule) return <></>;
    if (schedule.attributes.name !== '') {
      return (
        <Link
          to={`/schedule/calendar/${schedule.id}${queryParam}`}
          className="provider-name"
        >
          {schedule.attributes.name}
        </Link>
      );
    }
    if (provider.attributes) {
      return (
        <Link
          to={`/providers/${provider.id}${queryParam}`}
          className="provider-name"
        >
          {provider.attributes.name.trim()}
          {!!provider.attributes.suffix && <>, {provider.attributes.suffix}</>}
        </Link>
      );
    }
    return (
      <Link
        to={`/schedule/calendar/${schedule.id}${queryParam}`}
        className="provider-name"
      >
        {schedule.attributes.service.name}
      </Link>
    );
  };

  const renderScheduleService = () => {
    if (schedule?.attributes?.name !== '' && schedule?.attributes?.service) {
      return <p>{schedule?.attributes?.service?.name}</p>;
    }
    if (provider.attributes) {
      return <p>{schedule?.attributes?.service?.name}</p>;
    }
    return <></>;
  };

  const imageForCard = () => {
    if (provider && provider.attributes) {
      return provider.attributes['ui-profile-image'];
    }
    switch (venueType) {
      case 'urgent_care':
        return urgentCareImage;
      case 'emergency_room':
        return emergencyRoomImage;
      default:
        return null;
    }
  };

  if (isEmpty(schedule)) return <></>;
  return (
    <div className="EducationalScheduleCard">
      <div className={`provider-details ${isMobile ? '' : 'pr-3'}`}>
        <Grid container className="grid-container" justify="space-between">
          <Grid item className="img-row" xs={4} sm={3}>
            <EducationalImage image={imageForCard()} venueType={venueType} />
          </Grid>

          <Grid item xs={8} sm={5}>
            {renderScheduleName()}

            {renderScheduleService()}

            {typeof location.attributes !== 'undefined' && (
              <>
                <ResultListLocation
                  location={{
                    ...location.attributes,
                    ...camel(location.attributes),
                  }}
                  hideSchedule
                />
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={3}>
            <Grid container className="schedule-container">
              <Grid item xs={6} sm={12}>
                {hasTimes && availableTime && (
                  <NextAvailableAt
                    time={availableTime.times[0]}
                    timezoneName={timezoneName}
                  />
                )}
              </Grid>

              <Grid
                item
                xs={6}
                sm={12}
                container
                justify={isMobile ? 'flex-end' : 'flex-start'}
              >
                {hasTimes && schedule && availableTime && (
                  <ScheduleItButton
                    provider={provider}
                    schedule={schedule}
                    appointmentType={availableTime['appointment-type-id']}
                    time={availableTime.times[0]}
                    timezoneName={timezoneName}
                    venueType={venueType}
                  />
                )}
              </Grid>
            </Grid>
            {hasTimes && schedule && availableTime && (
              <MoreAvailableDoctors venueType={venueType} />
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

EducationalScheduleCard.defaultProps = {
  provider: {},
  venueType: null,
};

EducationalScheduleCard.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  schedule: PropTypes.instanceOf(Object).isRequired,
  provider: PropTypes.instanceOf(Object),
  venueType: PropTypes.string,
  appointmentType: PropTypes.string.isRequired,
};

export default EducationalScheduleCard;
