import React, { useContext } from 'react';
import { BrandingContext } from 'components/contexts/BrandingContext';
import { darken, makeStyles } from '@material-ui/core/styles';
import Button from 'react-bootstrap/Button';
import { uniqueid } from 'utils/helpers';

const BrandingButton = props => {
  const { branding } = useContext(BrandingContext);

  const useStyles = makeStyles({
    button: {
      backgroundColor: branding.buttonColor,
      borderColor: branding.buttonColor,
      color: branding.buttonTextColor,
      '&:hover, &:focus, &:not(.disabled):not(.disabled):active': {
        backgroundColor:
          branding.buttonColor !== '' && darken(branding.buttonColor, 0.15),
        color: branding.buttonTextColor,
        borderColor: branding.buttonColor,
      },
    },
  });

  const classes = useStyles();

  return (
    <Button
      {...props}
      className={
        props.className
          ? `${props.className} ${classes.button}`
          : classes.button
      }
      aria-label={`Schedule it branding ${uniqueid}`}
    >
      {props.children}
    </Button>
  );
};

export default BrandingButton;
