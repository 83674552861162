import Jsona from 'jsona';
import * as types from '../actions/actionTypes';
import initialState from './initialState';

const dataFormatter = new Jsona();

export default function servicesReducer(state = initialState.services, action) {
  switch (action.type) {
    case types.FETCH_SERVICES_SUCCESS:
      return dataFormatter.deserialize(action.services);
    default:
      return state;
  }
}
