import * as types from './actionTypes';

export function beginApiCall() {
  return { type: types.BEGIN_API_CALL };
}

export function apiCallError() {
  return { type: types.API_CALL_ERROR };
}

export function beginApiAuth() {
  return { type: types.BEGIN_API_AUTH };
}

export function apiAuthError() {
  return { type: types.API_AUTH_ERROR };
}

export function apiAuthSuccess() {
  return { type: types.API_AUTH_SUCCESS };
}
