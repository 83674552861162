import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import PropTypes from 'prop-types';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const OmniboxCheckboxList = forwardRef(({
  handleOnBlur,
  handleChange,
  options,
  defaultvalues,
}, ref) => {
  return (
    <Autocomplete
      multiple
      id="OmniboxCheckboxList"
      clearOnBlur={true}
      options={options}
      defaultValue={defaultvalues}
      getOptionLabel={(option) => {
        return option.toString();
      }}
      onChange={(_, value) => {
        handleChange(value);
      }}
      getOptionSelected={(option, value) => option === value}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </React.Fragment>
      )}
      style={{ width: '95%', innerheight: '40% !important', outerheight: '40%' }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label=""
          placeholder="..."
        />
      )
      }
      ref={ref}
    />
  );
});
OmniboxCheckboxList.defaultProps = {
  options: [],
  handleChange: () => { },
  handleOnBlur: () => { },
  defaultvalues: [],
};
OmniboxCheckboxList.propTypes = {
  handleOnBlur: PropTypes.func,
  handleChange: PropTypes.func,
  options: PropTypes.instanceOf(Array),
  defaultvalues: PropTypes.instanceOf(Array),

};
export default OmniboxCheckboxList;
