import React, { useContext, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import WhenToCall911Modal from '../../modals/WhenToCall911Modal';
import TermsAndConditionsModal from '../../modals/TermsAndConditionsModal';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import { BlackCheckbox } from '../FormElements';

const styles = makeStyles({
  alignTop: {
    alignItems: 'flex-start',
  },
  noPadTop: {
    paddingTop: 0,
  },
  termsContainer: {
    marginTop: 50,
  },
});

const TermsAndConditions = () => {
  const classes = styles();
  const formContext = useContext(RegistrationFormContext);
  const hasError = formContext.hasError('terms-tos');
  const [show911Modal, setShow911Modal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [t] = useTranslation();
  return (
    <>
      {formContext.isFieldEnabled('terms-tos') && (
        <div className="form-section_row">
          <div className={classes.termsContainer}>
            <p style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
              {formContext.isFieldRequired('terms-tos') && (
                <span className="required">* </span>
              )}
              {t('text.termsConditions')}
            </p>

            <FormControl
              variant="outlined"
              fullWidth
              error={hasError}
              component="fieldset"
            >
              <FormGroup>
                <FormControlLabel
                  className={classes.alignTop}
                  control={
                    <BlackCheckbox
                      className={classes.noPadTop}
                      inputProps={{
                        'aria-label': t('TermsAndConditions.checkBoxTitle'),
                        title: t('TermsAndConditions.checkBoxTitle'),
                      }}
                      value={!formContext.form.tocTerms.value}
                      onChange={e =>
                        formContext.handleSetFormState({
                          tocTerms: {
                            value: e.target.value === 'true',
                          },
                        })
                      }
                    />
                  }
                  label={
                    <>
                      {t('text.readAgree')}{' '}
                      <button
                        className="btn-plain"
                        type="button"
                        onClick={() => setShowTermsModal(true)}
                      >
                        {t('text.termsOfUse')}.
                      </button>
                    </>
                  }
                />

                {(formContext.isFieldEnabled('terms-911') ||
                  formContext.isFieldRequired('terms-911')) && (
                  <FormControlLabel
                    className={classes.alignTop}
                    control={
                      <BlackCheckbox
                        className={classes.noPadTop}
                        inputProps={{
                          'aria-label': t('TermsAndConditions.terms911'),
                          title: t('TermsAndConditions.terms911'),
                        }}
                        value={!formContext.form.tocNon911.value}
                        onChange={e =>
                          formContext.handleSetFormState({
                            tocNon911: {
                              value: e.target.value === 'true',
                            },
                          })
                        }
                      />
                    }
                    label={
                      <>
                        {t('TermsAndConditions.terms911LabelTextOne')}{' '}
                        <button
                          className="btn-plain"
                          type="button"
                          onClick={() => setShow911Modal(true)}
                        >
                          {t('TermsAndConditions.terms911ButtonTextOne')}
                        </button>{' '}
                        {t('TermsAndConditions.terms911LabelTextTwo')}
                      </>
                    }
                  />
                )}

                <FormControlLabel
                  className={classes.alignTop}
                  control={
                    <BlackCheckbox
                      className={classes.noPadTop}
                      inputProps={{
                        'aria-label': t(
                          'TermsAndConditions.toAgreeToReceiveInformation',
                        ),
                        title: t(
                          'TermsAndConditions.toAgreeToReceiveInformation',
                        ),
                      }}
                      value={!formContext.form.tocAgreeToReceive.value}
                      onChange={e =>
                        formContext.handleSetFormState({
                          tocAgreeToReceive: {
                            value: e.target.value === 'true',
                          },
                        })
                      }
                    />
                  }
                  label={t('agreeToReceive')}
                />

                {(formContext.isFieldEnabled('custom-terms') ||
                  formContext.isFieldRequired('custom-terms')) &&
                  formContext.form.customTerms.text.trim().length > 0 && (
                    <FormControlLabel
                      className={classes.alignTop}
                      control={
                        <BlackCheckbox
                          className={classes.noPadTop}
                          inputProps={{
                            'aria-label': 'Agree to custom terms',
                          }}
                          value={!formContext.form.customTerms.value}
                          onChange={e =>
                            formContext.handleSetFormState({
                              customTerms: {
                                text: formContext.form.customTerms.text,
                                value: e.target.value === 'true',
                              },
                            })
                          }
                        />
                      }
                      label={formContext.form.customTerms.text}
                    />
                  )}
              </FormGroup>

              {hasError && (
                <FormHelperText error={hasError}>
                  {formContext.getError('terms-tos')}
                </FormHelperText>
              )}
            </FormControl>

            <TermsAndConditionsModal
              show={showTermsModal}
              onClose={() => setShowTermsModal(false)}
            />

            <WhenToCall911Modal
              show={show911Modal}
              onClose={() => setShow911Modal(false)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default TermsAndConditions;
