import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { BrandingContext } from '../contexts/BrandingContext';
import './EducationalKeywords.scss';

const EducationalKeywords = ({ title, keywords }) => {
  const [t] = useTranslation();
  const { branding } = useContext(BrandingContext);
  const [showFullList, setShowFullList] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const minimumCount = 4;
  const keywordList = keywords.split(',');
  const keywordsCount = Math.ceil(
    (showFullList || isDesktop ? keywordList.length : minimumCount) / 2,
  );

  const styles = {
    keywordsBg: {
      backgroundColor: branding.primaryColor !== '' && branding.secondaryColor,
    },
    keywordsTitle: {
      borderBottom: !isDesktop ? `1px solid ${branding.primaryColor}` : 0,
      color: branding.primaryColor,
    },
    keywordsText: {
      color: branding.primaryTextColor,
    },
    showMoreButton: {
      border: `1px solid ${branding.primaryColor}`,
      color: branding.primaryColor,
    },
  };

  if (!keywords) return <></>;

  return (
    <div className="EducationalKeywords" style={styles.keywordsBg}>
      <Typography
        variant="h6"
        component="h2"
        className="keywords-heading"
        style={styles.keywordsTitle}
      >
        {title}
      </Typography>

      <div>
        {keywordsCount && (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {keywordList.slice(0, keywordsCount).map(word => (
                <p style={styles.keywordsText} key={word}>
                  {word}
                </p>
              ))}
            </Grid>

            <Grid item xs={6}>
              {keywordList
                .slice(
                  keywordsCount,
                  showFullList || isDesktop ? keywordList.length : minimumCount,
                )
                .map(word => (
                  <p style={styles.keywordsText} key={word}>
                    {word}
                  </p>
                ))}
            </Grid>
          </Grid>
        )}

        {keywordList.length > minimumCount && !isDesktop && (
          <div className="read-more">
            <button
              style={styles.showMoreButton}
              type="button"
              onClick={() => setShowFullList(!showFullList)}
            >
              {!showFullList && <span>{t('showMore')}</span>}
              {showFullList && <span>{t('showLess')}</span>}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

EducationalKeywords.defaultProps = {
  title: null,
  keywords: null,
};

EducationalKeywords.propTypes = {
  title: PropTypes.string,
  keywords: PropTypes.string,
};

export default EducationalKeywords;
