import React, { useState, useEffect, useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { flatMap, isEmpty } from 'lodash';
import { Alert } from 'react-bootstrap';
import { Grid, Typography, useTheme } from '@material-ui/core';
import Container from 'react-bootstrap/Container';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { loadActiveServices } from 'redux/actions/serviceLineActions';
import PageMetaInfo from '../common/PageMetaInfo';
import { BrandingContext } from '../contexts/BrandingContext';
import SpecialtyBubble from './SpecialtyBubble';
import './LandingPage.scss';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';

const LandingPage = ({ serviceLines, services, loadActiveServices }) => {
  const findADocPermalinks = ['specialty-care', 'primary-care'];
  const [t] = useTranslation();
  const [showFindADoc, setShowFindADoc] = useState(false);
  const [leftBubbles, setLeftBubbles] = useState([]);
  const [rightBubbles, setRightBubbles] = useState([]);

  const healthSystem = useSelector(state => state.healthSystem);
  var pageTitle = '';
  var pageDescription = '';
  let MediaContentBlockTop = '';
  let MediaContentBlockBottom = '';
  let MediaTitle_Top = '';
  if (healthSystem.region !== null) {
    if (
      t('RegionPage_MediaContentBlock_Top') !== '' &&
      t('RegionPage_MediaContentBlock_Top') !==
      'RegionPage_MediaContentBlock_Top'
    ) {
      MediaContentBlockTop = t('RegionPage_MediaContentBlock_Top', { hs_name: healthSystem?.name, region_name: healthSystem?.region?.name });
    }
    if (
      t('RegionPage_MediaContentBlock_Bottom') !== '' &&
      t('RegionPage_MediaContentBlock_Bottom') !==
      'RegionPage_MediaContentBlock_Bottom'
    ) {
      MediaContentBlockBottom = t('RegionPage_MediaContentBlock_Bottom', { hs_name: healthSystem?.name, region_name: healthSystem?.region?.name });
    }
    if (
      t('RegionPage_MediaTitle_Top') !== '' &&
      t('RegionPage_MediaTitle_Top') !==
      'RegionPage_MediaTitle_Top'
    ) {
      MediaTitle_Top = t('RegionPage_MediaTitle_Top', { hs_name: healthSystem?.name, region_name: healthSystem?.region?.name });
    }
    pageTitle =
      'Online Healthcare Scheduling at ' +
      healthSystem.region.name +
      ' - ' +
      healthSystem.name;
    pageDescription =
      'Book an appointment online at ' +
      healthSystem.region.name +
      ' - ' +
      healthSystem.name;
  } else {
    if (
      t('HealthSystemPage_MediaContentBlock_Top') !== '' &&
      t('HealthSystemPage_MediaContentBlock_Top') !=
      'HealthSystemPage_MediaContentBlock_Top'
    ) {
      MediaContentBlockTop = t('HealthSystemPage_MediaContentBlock_Top', { hs_name: healthSystem?.name, region_name: healthSystem?.region?.name });
    }
    if (
      t('HealthSystemPage_MediaContentBlock_Bottom') !== '' &&
      t('HealthSystemPage_MediaContentBlock_Bottom') !==
      'HealthSystemPage_MediaContentBlock_Bottom'
    ) {
      MediaContentBlockBottom = t('HealthSystemPage_MediaContentBlock_Bottom', { hs_name: healthSystem?.name, region_name: healthSystem?.region?.name });
    }
    if (
      t('HealthSystemPage_MediaTitle_Top') !== '' &&
      t('HealthSystemPage_MediaTitle_Top') !==
      'HealthSystemPage_MediaTitle_Top'
    ) {
      MediaTitle_Top = t('HealthSystemPage_MediaTitle_Top', { hs_name: healthSystem?.name, region_name: healthSystem?.region?.name });
    }
    pageTitle = 'Online Healthcare Scheduling at ' + healthSystem.name;
    pageDescription = 'Book an appointment online at ' + healthSystem.name;
  }

  const [isShowLoader, setIsShowLoader] = useState(true);
  const [labels, setLabels] = useState();
  const { pathname } = useLocation();
  const { branding } = useContext(BrandingContext);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  localStorage.setItem('zipCode', '');

  useEffect(() => {
    loadActiveServices().then(() =>
      setTimeout(() => {
        // setIsShowLoader(false);
      }, 0),
    );
  }, []);

  useEffect(() => {
    setLabels({
      en: {
        'homepage-left-label': branding?.labels?.en['homepage-left-label'],
        'homepage-right-label': branding?.labels?.en['homepage-right-label'],
      },
    });
  }, [branding]);

  useEffect(() => {
    if (
      labels?.en['homepage-left-label'] !== 'BrandingContext.topMedicalCare'
    ) {
      setIsShowLoader(false);
    }
  }, [labels]);

  useEffect(() => {
    // Segregate service lines to display on the left and right based on the list below.
    const leftPermalinks = [
      'urgent-care',
      'emergency-room',
      'pediatric-emergency',
      'emergency-department',
      'walk-in',
      'immediate-care',
      'telehealth',
    ];

    const serviceLinesArray = Object.values(serviceLines);

    setShowFindADoc(
      serviceLinesArray
        .map(sl => sl.attributes.permalink)
        .includes('specialty-care') ||
      serviceLinesArray
        .map(sl => sl.attributes.permalink)
        .includes('primary-care'),
    );

    setLeftBubbles(
      serviceLinesArray
        .filter(sl => leftPermalinks.includes(sl.attributes.permalink))
        .sort((a, b) => a.attributes.sortOrder - b.attributes.sortOrder),
    );

    setRightBubbles(
      serviceLinesArray
        .filter(
          sl =>
            !leftPermalinks.includes(sl.attributes.permalink) &&
            !findADocPermalinks.includes(sl.attributes.permalink),
        )
        .sort((a, b) => a.attributes.sortOrder - b.attributes.sortOrder),
    );
  }, [serviceLines, services]);

  const servicesForBubble = bubble => {
    const serviceIds = bubble.relationships.services.data.map(s => s.id);
    const bubbleServices = Object.values(services).filter(s =>
      serviceIds.includes(s.id),
    );

    return bubbleServices;
  };

  const findADocServices = () => {
    const flatServiceLines = Object.values(serviceLines);
    const findADocServiceLines = flatServiceLines.filter(sl =>
      findADocPermalinks.includes(sl.attributes.permalink),
    );
    return flatMap(findADocServiceLines, sl => servicesForBubble(sl));
  };

  const noServicesText = (
    <Grid item>
      <span>{t('LandingPage.registrationTimeCurrentlyTaken')}</span>
    </Grid>
  );

  return (
    <main className="LandingPage">
      {isShowLoader === true ? (
        <Alert
          style={{
            background: branding.secondaryColor,
            color: branding.primaryColor,
            padding: '.75rem 40.25rem',
          }}
        >
          <Alert.Heading>{t('ScheduleResultList.hangTight')}!</Alert.Heading>
          {t('ScheduleResultList.fetchingresults')}...
        </Alert>
      ) : (
        <>
          {/* <PageMetaInfo
            title={pageTitle}
            description={pageDescription}
            canonicalLink={pathname}
          /> */}
          {(MediaTitle_Top || MediaContentBlockTop) && (
            <div style={{ color: branding.primaryColor }} className="locizeContainer">
              <div className="container">
                <Trans>
                  <h1 className='locizeHeaderStyle'>{MediaTitle_Top}</h1>
                </Trans>
                <Trans>
                  <p className="locizePTagStyle">{MediaContentBlockTop}</p>
                </Trans>
              </div>
            </div>
          )}
          <Container className="search-container">
            <Grid container>
              {(!isEmpty(leftBubbles) || showFindADoc) && (
                <Grid item xs={12} sm={5}>
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{ color: branding.primaryColor }}
                    className="section-heading"
                  >
                    {labels && labels.en['homepage-left-label']}
                  </Typography>

                  <Grid container spacing={3}>
                    {isEmpty(leftBubbles) && !showFindADoc && noServicesText}
                    {showFindADoc && (
                      <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        lg={3}
                        className="bubble-container"
                      >
                        <SpecialtyBubble
                          name="Find A Doctor"
                          permalink="find-a-doctor"
                          services={findADocServices()}
                        />
                      </Grid>
                    )}
                    {leftBubbles.map(sl => {
                      const services = servicesForBubble(sl);
                      return flatMap(services, s => (
                        <Grid
                          item
                          xs={6}
                          sm={4}
                          md={3}
                          lg={3}
                          key={s.id}
                          className="bubble-container"
                        >
                          <SpecialtyBubble
                            name={s.attributes.name}
                            id={s.id}
                            permalink={s.attributes.permalink}
                            serviceLinePermalink={sl.attributes.permalink}
                            services={[s]}
                          />
                        </Grid>
                      ));
                    })}
                  </Grid>
                </Grid>
              )}

              {!isEmpty(leftBubbles) && !isEmpty(rightBubbles) && (
                <Grid item xs={12} sm={1}>
                  <hr
                    style={{
                      backgroundColor: isDesktop
                        ? 'rgba(112,114,113,0.32)'
                        : branding.primaryColor,
                    }}
                    className={
                      isDesktop ? 'vertical-stroke' : 'horizontal-stroke'
                    }
                  />
                </Grid>
              )}
              {!isEmpty(rightBubbles) && (
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="h6"
                    component="h2"
                    style={{ color: branding.primaryColor }}
                    className="section-heading"
                  >
                    {labels && labels.en['homepage-right-label']}
                  </Typography>

                  <Grid container spacing={3}>
                    {isEmpty(rightBubbles) && noServicesText}
                    {rightBubbles.map(item => {
                      const services = servicesForBubble(item);
                      let serviceToShow = services.find(
                        sl =>
                          sl.attributes.permalink === item.attributes.permalink,
                      );
                      if (!serviceToShow) {
                        serviceToShow = item;
                      }
                      return (
                        <Grid
                          item={serviceToShow}
                          xs={6}
                          sm={3}
                          md={3}
                          lg={2}
                          key={serviceToShow.id}
                          className="bubble-container"
                        >
                          <SpecialtyBubble
                            name={serviceToShow.attributes.name}
                            id={serviceToShow.id}
                            permalink={serviceToShow.attributes.permalink}
                            services={servicesForBubble(item)}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Container>
        </>
      )}
      {MediaContentBlockBottom && (
        <div style={{ color: branding.primaryColor }} className="container">
          <Trans>
            <p className="locizePTagStyle"> {MediaContentBlockBottom}</p>
          </Trans>
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="descbottom" content={MediaContentBlockBottom} />
          </Helmet>
        </div>
      )}
    </main>
  );
};

LandingPage.propTypes = {
  loadActiveServices: PropTypes.func.isRequired,
  services: PropTypes.instanceOf(Object).isRequired,
  serviceLines: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => ({
  loading: !state.apiAuthSuccess,
  services: state.serviceLines.services,
  serviceLines: state.serviceLines.serviceLines,
});

const mapDispatchToProps = {
  loadActiveServices,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
