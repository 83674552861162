import { find } from 'lodash';

/**
 *
 */
export const getActiveVenueTab = (params, selectedParams, venues) => {
  if (params.tab) return params.tab;

  if (selectedParams.tab) return selectedParams.tab;

  let defaultTab = '';

  // Of available tabs, we want to display
  //   the 'least expensive' options by default.
  // Here is my sad `if` structure to set the 'correct' tab as active.
  if (find(venues, { attributes: { venueType: 'emergency_room' } }))
    defaultTab = 'emergency_room';
  if (find(venues, { attributes: { venueType: 'urgent_care' } }))
    defaultTab = 'urgent_care';
  if (find(venues, { attributes: { venueType: 'physician_services' } }))
    defaultTab = 'physician_services';

  return defaultTab;
};
