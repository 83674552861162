import { isEmpty, isNull, isUndefined } from 'lodash';
import {
  handleResponse,
  handleError,
  getEndpoint,
  getHeaderInfo,
} from './apiUtils';

export function getVisit(id, params) {
  return fetch(getEndpoint(`visits/${id}`, params), getHeaderInfo())
    .then(handleResponse)
    .catch(handleError);
}

export function createFeedback(id, formData) {
  const { method, headers } = getHeaderInfo('POST');
  const fixedHeaders = {
    ...headers,
    'Content-Type': 'application/vnd.api+json',
  };
  const params = {
    method,
    headers: fixedHeaders,
    body: JSON.stringify({ data: formData }),
  };
  return fetch(getEndpoint(`feedbacks`), params)
    .then(handleResponse)
    .catch(handleError);
}

export function cancel(id, formData) {
  const { method, headers } = getHeaderInfo('PATCH');
  const fixedHeaders = {
    ...headers,
    'Content-Type': 'application/vnd.api+json',
  };
  const params = {
    method,
    headers: fixedHeaders,
    body: JSON.stringify({ data: formData }),
  };
  return fetch(getEndpoint(`visits/${id}/cancel`), params)
    .then(handleResponse)
    .catch(handleError);
}

export const getVisitSettings = (scheduleId, appointmentTypeId) => {
  return fetch(
    getEndpoint(`visit-settings/${scheduleId}`, {
      appointment_type_template_id: appointmentTypeId,
    }),
    getHeaderInfo(),
  )
    .then(handleResponse)
    .catch(handleError);
};

const createVisitUrl = queryParams => {
  return getEndpoint('visits', queryParams);
};

const handleCreateResponse = async response => {
  const { status } = response;
  const jsonData = response.json();
  if (response.ok) return jsonData;
  if (status === 400 || status === 422) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message,
    // so parse as text instead of json.

    return jsonData;
  }
  throw new Error('Network response was not ok.');
};

export const createVisit = async formData => {
  const { method, headers } = getHeaderInfo('POST');
  const fixedHeaders = {
    ...headers,
    'Content-Type': 'application/vnd.api+json',
  };
  const utmSource = localStorage.getItem('utm_source') || null;
  const utmMedium = localStorage.getItem('utm_medium') || null;
  const utmCampaign = localStorage.getItem('utm_campaign') || null;
  const params = {
    method,
    headers: fixedHeaders,
    body: JSON.stringify({ data: formData }),
  };
  const nullCheck = value =>
    isNull(value) ||
    isUndefined(value) ||
    isEmpty(value) ||
    value === 'null' ||
    value === 'undefined';

  const queryParams = {
    ...((!nullCheck(utmSource) && { utm_source: utmSource }) || {}),
    ...((!nullCheck(utmMedium) && { utm_medium: utmMedium }) || {}),
    ...((!nullCheck(utmCampaign) && { utm_campaign: utmCampaign }) || {}),
  };
  return fetch(createVisitUrl(queryParams), params)
    .then(handleCreateResponse)
    .catch(e => {
      console.error(`API call failed. ${e}`);
    });
};
