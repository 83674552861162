import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Grid } from '@material-ui/core';
import { getInsurancePlansForSchedule } from '../../../api/scheduleApi';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomSelect from '../CustomSelect';
import CustomTextField from '../CustomTextField';

const InsurancePlanFields = ({ scheduleId }) => {
  const formContext = useContext(RegistrationFormContext);
  const [insurancePlans, setInsurancePlans] = useState([]);
  const [t] = useTranslation();
  useEffect(() => {
    const fetchInsurancePlans = async scheduleId => {
      const { data } = await getInsurancePlansForSchedule(scheduleId);
      await setInsurancePlans(
        data.map(ip => {
          return {
            value: ip.attributes.permalink,
            text: ip.attributes.name,
            company: ip.attributes.company,
          };
        }),
      );
    };

    if (isEmpty(insurancePlans)) {
      fetchInsurancePlans(scheduleId);
    }
  }, [scheduleId]);

  return (
    <>
      {formContext.isFieldEnabled('insurance-plan-name') && (
        <div className="form-section_row">
          <CustomSelect
            fieldKey="insurancePlans"
            fieldValue={formContext.form.insurancePlans}
            label={t('PhysicianForm.insurance')}
            required={formContext.isFieldRequired('insurance-plan-name')}
            items={insurancePlans}
            grouped
            groupKey="company"
            validateFormHandler={formContext.handleSetFormState}
          />
        </div>
      )}

      {formContext.isFieldEnabled('insurance-plan-full-address') && (
        <div className="form-section_row">
          <Grid container>
            <Grid item sm={8} xs={12}>
              <CustomTextField
                fieldKey="insurancePlanFullAddress"
                fieldValue={formContext.form.insurancePlanFullAddress}
                validateFormHandler={formContext.handleSetFormState}
                label={t('InsurancePlanField.insuranceFullAddress')}
                required={formContext.isFieldRequired(
                  'insurance-plan-full-address',
                )}
                shrink
                hasError={formContext.hasError('insurance-plan-full-address')}
                errorMessage={formContext.getError(
                  'insurance-plan-full-address',
                )}
              />
            </Grid>
          </Grid>
        </div>
      )}

      {formContext.isFieldEnabled('insurance-plan-phone-number') && (
        <div className="form-section_row">
          <Grid container>
            <Grid item sm={4} xs={12}>
              <CustomTextField
                fieldKey="insurancePlanPhoneNumber"
                fieldValue={formContext.form.insurancePlanPhoneNumber}
                validateFormHandler={formContext.handleSetFormState}
                label={t('InsurancePlanFields.insurancePhoneNumber')}
                required={formContext.isFieldRequired(
                  'insurance-plan-phone-number',
                )}
                shrink
                hasError={formContext.hasError('insurance-plan-phone-number')}
                errorMessage={formContext.getError(
                  'insurance-plan-phone-number',
                )}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

InsurancePlanFields.propTypes = {
  scheduleId: PropTypes.string.isRequired,
};

export default InsurancePlanFields;
