import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { BrandingContext } from '../contexts/BrandingContext';

const Logo = ({ clearSearch }) => {
  const history = useHistory();
  const healthSystem = useSelector(state => state.healthSystem);
  const { branding } = useContext(BrandingContext);
  const isDischargePage = useRouteMatch({
    path: [
      '/:dischargeFacility/discharge',
      '/:dischargeFacility/discharge/:locationPermalink',
      '/:dischargeFacility/discharge/service/:serviceLine',
      '/:dischargeFacility/discharge/:locationPermalink/service/:serviceLine',
    ],
    strict: true,
    sensitive: true,
    exact: true,
  });

  // @Note
  // Nuclear option.
  // Only used if the page is already the homepage.
  // This reloads the page, clearing state and user starts from scratch.
  const handleNavigation = () => {
    const { pathname } = history.location;
    // Check for the presence of branding.logoUrl
    if (isDischargePage) {
      history.push('/');
      return; // Prevent further execution
    }

    const logoUrl = branding?.logoUrl;
    if (logoUrl) {
      // Redirect to logo URL if defined
      window.open(branding.logoUrl, '_blank');
      return; // Stop further execution
    }

    if (pathname === '/') {
      window.location.replace(pathname);
      return; // Prevent further execution
    }

    // If no specific condition matches, clear search and navigate to home
    history.push('/');
    clearSearch();
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleNavigation(); // Handle navigation on Enter or Space key
    }
  };

  const logoAltText = healthSystem?.region
    ? `${healthSystem?.region?.name} Logo`
    : `${healthSystem?.name} Logo`;

  return (
    <div
      role="button"
      tabIndex={0}
      style={{ display: 'block', cursor: 'pointer' }}
      onClick={handleNavigation}
      onKeyDown={handleKeyPress}
      aria-label={logoAltText}
    >
      <img alt={logoAltText} className="logo" src={branding.logoImage} />
    </div>
  );
};
Logo.propTypes = {
  /* Dispatch function found in searchActions. */
  clearSearch: PropTypes.func.isRequired,
};

export default Logo;
