import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import CustomTextField from '../CustomTextField';
import { RegistrationFormContext } from '../RegistrationFormProvider';

const ReferringPhysicianFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const [t] = useTranslation();

  return (
    <>
      {formContext.isFieldEnabled('referring-physician-name') && (
        <div className="form-section_row">
          <Grid container>
            <Grid item sm={4} xs={12}>
              <CustomTextField
                fieldKey="referringPhysicianName"
                fieldValue={formContext.form.referringPhysicianName}
                validateFormHandler={formContext.handleSetFormState}
                label={t('ReferringPhysicianFields.referringPhysicianName')}
                shrink
                required={formContext.isFieldRequired(
                  'referring-physician-name',
                )}
                hasError={formContext.hasError('referring-physician-name')}
                errorMessage={formContext.getError('referring-physician-name')}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default ReferringPhysicianFields;
