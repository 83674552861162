import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ScreeningQuestionsModal from 'components/modals/ScreeningQuestionModal';
import useGoogleAnalytics from 'hooks/useGoogleAnalytics';

const ProviderAppointmentScreeningQuestions = ({
  screeningQuestions,
  answers,
  updateAnswers,
  onScreeningQuestionsClose,
}) => {
  const { heading, questions, messages } = screeningQuestions || {};

  const GA = useGoogleAnalytics();

  const PRESENTATION_TYPE = {
    QUESTION: 'question',
    MESSAGE: 'message',
  };

  useEffect(() => {
    GA.event({ category: 'screening_questions', action: 'show' });
  }, []);

  const [showModal, setShowModal] = useState(true);
  const [activePresentationType, setActivePresentationType] = useState(
    PRESENTATION_TYPE.QUESTION,
  );
  const [activeObjectId, setActiveObjectId] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(activeObjectId);

  const handleAction = action => {
    const { handler, messageId, questionId } = action;
    switch (handler) {
      case 'abortWithMessage':
        setActivePresentationType(PRESENTATION_TYPE.MESSAGE);
        setActiveObjectId(messageId);
        GA.event({ category: 'screening_questions', action: 'abort' });
        break;
      case 'findQuestion': {
        setActivePresentationType(PRESENTATION_TYPE.QUESTION);
        const nextQuestion = questions.find(q => q.id === questionId);
        const nextQuestionIndex = questions.indexOf(nextQuestion);
        setActiveObjectId(nextQuestionIndex);
        setQuestionIndex(nextQuestionIndex);
        break;
      }
      case 'gaAction': // no clue what this means
      default:
        break;
    }
  };

  const closeModal = () => {
    GA.event({ category: 'screening_questions', action: 'close' });
    setShowModal(false);
    onScreeningQuestionsClose(false);
  };

  const addAnswer = (outcome, value) => {
    const answer = {
      question: questions[questionIndex].text,
      answer: value,
    };
    updateAnswers([...answers, answer]);
  };

  const handleOutcome = (outcome, value) => {
    addAnswer(outcome, value);
    // if outcome has no action it means it "leads to the next question in the set"
    // refs: https://app.getguru.com/card/cABKpR9i/Screening-Questions?q=screening%20questions
    if (outcome === undefined) {
      if (questionIndex === questions.length - 1) {
        closeModal();
      } else {
        const nextQuestionIndex = questionIndex + 1;
        setQuestionIndex(nextQuestionIndex);
        setActiveObjectId(nextQuestionIndex);
        return;
      }
    }
    const { actions } = outcome;
    actions.map(action => handleAction(action));
  };

  const activeQuestion = () => questions[questionIndex];

  const activeMessage = () =>
    //Conversion to text is necessary for comparison, otherwise it returns false even if both values are same
    messages.find(m => String(m.messageId) === String(activeObjectId));

  const activeObject =
    activePresentationType === PRESENTATION_TYPE.QUESTION
      ? activeQuestion()
      : activeMessage();

  return (
    <ScreeningQuestionsModal
      show={showModal}
      onClose={closeModal}
      heading={heading}
      presentationType={activePresentationType}
      activeObject={activeObject}
      onHandleOutcome={handleOutcome}
    />
  );
};

ProviderAppointmentScreeningQuestions.propTypes = {
  screeningQuestions: PropTypes.instanceOf(Object).isRequired,
  answers: PropTypes.instanceOf(Object).isRequired,
  updateAnswers: PropTypes.func.isRequired,
  onScreeningQuestionsClose: PropTypes.func.isRequired,
};

export default ProviderAppointmentScreeningQuestions;
