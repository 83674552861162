import capitalize from 'capitalize';
import parser from 'parse-domain';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import serviceLineGroups from '../config/serviceLineGroups';
import serviceLineGroupMapping from '../config/serviceLineGroupMappings';

export function shouldShowSearchElement(uxMode, serviceName, componentName) {
  if (uxMode === 'educational') {
    return true;
  }

  let visible = false;
  try {
    // If service not found in mapping, default to Group D.
    const groupName = serviceLineGroupMapping[serviceName] || 'GROUP_D';
    const { search, filter } = serviceLineGroups[groupName];
    visible = search.includes(componentName) || filter.includes(componentName);
  } catch (error) {
    console.log('!!! search configuration lookup error:', error);
  }

  return visible;
}

export function shouldVerfiyRoute(serviceName) {
  let visible = false;
  try {
    const groupName = serviceLineGroupMapping[serviceName];
    if (groupName !== undefined) visible = true;
  } catch (error) {
    console.log('!!! search configuration lookup error:', error);
  }

  return visible;
}

export function hasFilters(uxMode, serviceName) {
  if (uxMode === 'educational') {
    return true;
  }
  const groupName = serviceLineGroupMapping[serviceName] || 'GROUP_D';
  const { filter } = serviceLineGroups[groupName];
  return filter.length > 0;
}

export function isGroupC(uxMode, serviceName) {
  if (uxMode === 'educational') {
    return false;
  }

  return serviceLineGroupMapping[serviceName] === 'GROUP_C';
}

/**
 * Constructs an Elastic Query in their DSL.
 * @param {Object} searchParams
 * @param {String} timezone eg. America/Chicago. Comes from HS response.
 * @param {Object} dateWindow A DSL-formatted range query for a single date.
 *   - This is for use by the InventoryDateCarousel.
 * @returns {String} body A JSON payload for Elastic search.
 */
export function formatElasticQuery(searchParams, timezone, dateWindow) {
  const params = new URLSearchParams(searchParams);
  const geopoint = params.get('filter[near]').split(',');
  const context = capitalize(params.get('filter[context]'));
  const acceptingNewPatients = params.get('filter[accepting_new_patients]');
  const radius = params.get('filter[radius]');
  const apptType = params.get('filter[appointment_type]');
  const gender = params.get('filter[gender]');
  const insurance = params.get('filter[insurance]');
  const languages = params.getAll('filter[language][]');
  const services = params.getAll('filter[service_id][]');
  const affiliations = params.getAll('filter[hospital_affiliation][]');
  const sort = params.get('sort');
  const page = params.get('page_number');
  const startDate = params.get('from');
  const endDate = params.get('to');
  const { hostname } = window.location;
  let permalink;

  if (hostname === 'localhost') {
    permalink = '1kaepic';
  } else {
    permalink = parser(window.location.href).subdomain;
  }

  const esSort = [];
  const esFilter = [];

  esFilter.push({
    match: {
      schedule_active: 1,
    },
  });

  if (startDate && endDate) {
    const dateFilters = [];
    /**
     *
     * Standard time format is length 5: `HH:mm`
     */
    if (startDate.length === 5 && endDate.length === 5) {
      dateFilters.push({
        range: {
          'schedule_timeblocks.start_time': {
            gte: startDate,
            lte: endDate,
          },
        },
      });
      dateFilters.push({
        range: {
          'schedule_timeblocks.start_date': {
            gte: moment().format(),
          },
        },
      });
    } else {
      // TODO: Get timezone from App.
      dateFilters.push({
        range: {
          'schedule_timeblocks.start_date': {
            gte: moment
              .tz(startDate, timezone || 'America/Toronto')
              .utc()
              .format(),
            lte: moment
              .tz(endDate, timezone || 'America/Toronto')
              .utc()
              .format(),
          },
        },
      });
    }
    if (!isEmpty(dateWindow)) {
      dateFilters.push(dateWindow);
    }
    if (apptType) {
      dateFilters.push({
        match: {
          'schedule_timeblocks.appointment_type_id': apptType,
        },
      });
    }
    esFilter.push({
      nested: {
        path: 'schedule_timeblocks',
        query: {
          bool: {
            filter: dateFilters,
          },
        },
        inner_hits: {
          size: 12,
        },
      },
    });
  }
  if (sort === 'soonest') {
    esSort.push({
      'schedule_timeblocks.start_date': {
        order: 'asc',
        nested: {
          path: 'schedule_timeblocks',
        },
      },
    });
  }

  if (sort === 'nearest') {
    esSort.push({
      _geo_distance: {
        location_geo_point: {
          lat: geopoint[0],
          lon: geopoint[1],
        },
        order: 'asc',
        unit: 'km',
        mode: 'min',
        distance_type: 'arc',
        ignore_unmapped: true,
      },
    });
  }

  if (permalink) {
    esFilter.push({
      match: {
        health_system_permalink: permalink,
      },
    });
  }
  if (context) {
    esFilter.push({
      match: {
        'schedule_contexts.context': context,
      },
    });
  }
  if (gender) {
    esFilter.push({
      match: {
        provider_gender: gender[0],
      },
    });
  }

  if (insurance) {
    esFilter.push({
      nested: {
        path: 'insurances',
        query: {
          bool: {
            must: [
              {
                match: {
                  'insurances.insurance_plan_id': insurance,
                },
              },
            ],
          },
        },
      },
    });
  }

  if (languages) {
    const langTerms = [];
    languages.forEach(lang =>
      langTerms.push({ term: { provider_languages: lang } }),
    );
    if (!isEmpty(langTerms)) {
      esFilter.push({
        bool: {
          should: langTerms,
        },
      });
    }
  }

  if (affiliations) {
    const affiliationTerms = [];
    affiliations.forEach(a9n =>
      affiliationTerms.push({ match: { provider_affiliations: a9n } }),
    );
    if (!isEmpty(affiliationTerms)) {
      esFilter.push({
        bool: {
          should: affiliationTerms,
        },
      });
    }
  }

  if (services) {
    const serviceTerms = [];
    services.forEach(s => serviceTerms.push({ term: { service_id: s } }));
    if (!isEmpty(serviceTerms)) {
      esFilter.push({
        bool: {
          should: serviceTerms,
        },
      });
    }
  }

  if (acceptingNewPatients) {
    esFilter.push({
      match: {
        accepting_new_patients: true,
      },
    });
  }

  if (geopoint) {
    esFilter.push({
      geo_distance: {
        distance: `${radius}km`,
        location_geo_point: {
          lat: geopoint[0],
          lon: geopoint[1],
        },
      },
    });
  }

  const body = {
    _source: [
      'facility_id',
      'facility_name',
      'facility_time_zone',
      'location_*',
      'provider_*',
      'schedule_id',
      'schedule_appointment_types',
      'service_name',
    ],
    query: {
      bool: {
        must: [],
        filter: esFilter,
      },
    },
    sort: esSort,
    from: (page - 1) * 10,
  };

  return JSON.stringify(body);
}

export function timeBlocksElasticSearchQuery(
  scheduleId,
  appointmentTypeId,
  startDate,
  endDate,
) {
  const esFilter = [];
  esFilter.push({
    match_phrase: {
      time_block_schedule_id: scheduleId,
    },
  });
  esFilter.push({
    match_phrase: {
      time_block_appointment_type_id: appointmentTypeId,
    },
  });
  esFilter.push({
    range: {
      time_block_start_date: {
        gte: moment(startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
        lte: moment(endDate).format('YYYY-MM-DDTHH:mm:ssZ'),
      },
    },
  });
  esFilter.push({
    range: {
      time_block_start_date: {
        gte: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
      },
    },
  });

  const body = {
    query: {
      bool: {
        filter: esFilter,
      },
    },
    aggs: {
      time_blocks: {
        date_histogram: {
          field: 'time_block_start_date',
          calendar_interval: 'day',
        },
        aggs: {
          top_time_block_hits: {
            top_hits: {
              sort: [
                {
                  time_block_start_date: {
                    order: 'asc',
                  },
                },
              ],
              _source: {
                includes: [
                  'time_block_start_date',
                  'time_block_start_time',
                  'time_block_schedule_id',
                  'time_block_appointment_type_id',
                ],
              },
              size: 5,
            },
          },
        },
      },
    },
  };
  return JSON.stringify(body);
}

/**
 * Given an array of time slot objects, return only those that
 * belong to the first day available.
 * This is because only one day is viewable in the
 * ElasticScheduleResultTimes UI at a time.
 * @param {Array} times
 * @return {Array}
 */
export function getFirstDay(times) {
  if (isEmpty(times)) {
    return [];
  }

  return times.filter(t =>
    // eslint-disable-next-line no-underscore-dangle
    moment(t._source.start_date).isSame(times[0]._source.start_date, 'day'),
  );
}
// Services which are ending with -services
const omittedServices = [
  'nutrition-services',
  'laboratory-services',
  'health-spa-services',
  'nail-services',
];

export function cleanServiceLineUrls(serviceLine) {
  let serviceLineParam = serviceLine;
  if (
    !omittedServices.includes(serviceLine) &&
    serviceLine?.endsWith('-services')
  ) {
    serviceLineParam = serviceLine.replace(/-services/g, '');
  }
  return serviceLineParam;
}
