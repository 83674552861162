import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import AddToCalendar from 'react-add-to-calendar';
import Button from 'react-bootstrap/Button';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';

import { getEndpoint, getHeaderInfo } from 'api/apiUtils';
import { Form } from 'react-formio';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { BrandingContext } from '../contexts/BrandingContext';

const newInstance = i18next.createInstance();
newInstance.init(
  {
    fallbackLng: 'en',
    lng: localStorage.getItem('i18nextLng'),
    debug: false,
  },
  err => {
    if (err) return console.log('something went wrong loading', err);
  },
);

const useStyles = makeStyles({
  button: branding => ({
    paddingLeft: '0px',
    width: '150px',
    paddingRight: '0px',
    margin: '0px',
    color: branding.buttonTextColor,
    borderColor: branding.buttonColor,
    backgroundColor: branding.buttonColor,
    '& a': {
      color: `${branding.buttonTextColor} !important`,
    },
    '&:hover': {
      color: `${branding.buttonColor} !important`,
      borderColor: branding.buttonColor,
      backgroundColor: branding.buttonTextColor,
      '& a': {
        color: `${branding.buttonColor} !important`,
      },
    },
    '&:active': {
      color: `${branding.buttonColor} !important`,
      borderColor: `${branding.buttonColor} !important`,
      backgroundColor: `${branding.buttonTextColor} !important`,
      '& a': {
        color: `${branding.buttonColor} !important`,
      },
    },
    '&:focus': {
      color: `${branding.buttonColor} !important`,
      borderColor: `${branding.buttonColor} !important`,
      backgroundColor: `${branding.buttonTextColor} !important`,
      '& a': {
        color: `${branding.buttonColor} !important`,
      },
    },
  }),
  dropdownClass: branding => ({
    border: `1px solid ${branding.buttonColor}`,
    backgroundColor: branding.buttonTextColor,
  }),
  pageTitle: branding => ({
    color: branding.primaryColor,
  }),
  paddingZero: {
    padding: '0px !important',
  },
});

const ConfirmationFormio = ({
  visitId,
  visit,
  provider,
  location,
  timezoneName,
}) => {
  const healthSystem = useSelector(state => state.healthSystem);
  const [formDefinition, setFormDefinition] = useState(null);
  const { branding } = useContext(BrandingContext);
  const [t] = useTranslation();
  const classes = useStyles(branding);
  const isVirtualAppointment = visit => {
    const virtualAppointment = visit.data.attributes['virtual-appointment'];
    return !!virtualAppointment;
  };

  const getVisitForCalendar = (visit, location, provider) => {
    const startTime = moment(visit.data.attributes['appointment-at']).tz(
      timezoneName,
    );
    const endTime = moment(visit.data.attributes['appointment-at'])
      .tz(timezoneName)
      .add(15, 'm');
    const title = `Appointment - ${location.name}`;
    let description = !isEmpty(provider)
      ? `Appointment with ${provider.attributes.name}.`
      : `Appointment at ${location.name}.`;
    let locationText = `${location.address} ${location.city} ${location.state} ${location.zip}`;

    if (isVirtualAppointment(visit)) {
      const joinUrl = visit.data.attributes['virtual-appointment-join-url'];
      const testUrl = visit.data.attributes['virtual-appointment-test-url'];
      description += `  Join Meeting: ${joinUrl}.`;
      if (testUrl) {
        description += `  Please ensure to test your setup and use the following link to test connections prior to the appointment time `;
        description += ` ${testUrl}.`;
      }
      locationText = `Virtual meeting - ${joinUrl}`;
    }

    return {
      title,
      description,
      location: locationText,
      startTime,
      endTime,
    };
  };

  useEffect(() => {
    (async () => {
      let defaultLanguage = 'English';
      const locale = localStorage.getItem('i18nextLng');

      if (locale) {
        const languages = healthSystem['supported-locales'].filter(
          lang => lang.code === locale,
        );

        if (languages.length) {
          defaultLanguage = languages[0].name;
        }
      }

      const response = await fetch(
        getEndpoint(`custom-templates`, {
          type: 'confirmation',
          visit_id: visitId,
          language: defaultLanguage,
        }),
        getHeaderInfo(),
      );
      const results = await response.json();

      if (results.data && results.data.length) {
        const { attributes } = results.data[0];

        if (attributes && attributes['form-definition']) {
          const data = JSON.parse(attributes['form-definition']);
          setFormDefinition(data);
        }
      }
    })();
  }, [healthSystem]);

  const setCalendarButton = () => {
    const icon = { textOnly: 'none' };
    const getXpathElements = document.evaluate(
      '//a[text()="Add to calendar"]',
      document,
      null,
      XPathResult.ANY_TYPE,
      null,
    );
    const addToCalendar = getXpathElements.iterateNext();
    if (addToCalendar) {
      addToCalendar.className = 'selected';
      addToCalendar.addEventListener('click', e => {
        e.preventDefault();
      });
      ReactDOM.render(
        <Button
          onClick={() => addToCalendar.click()}
          className={`${classes.button} ${classes.paddingZero}`}
          type="button"
        >
          <AddToCalendar
            event={getVisitForCalendar(visit, location, provider)}
            buttonLabel={t('ConfirmationPage.AddToCalendar')}
            dropdownClass={`react-add-to-calendar__dropdown ${classes.dropdownClass}`}
            buttonTemplate={icon}
          />
        </Button>,
        addToCalendar,
      );
    }
  };

  const setPrintButton = () => {
    const printBtn = document.querySelector('#print-btn');

    if (printBtn) {
      printBtn.innerText = t('ConfirmationPage.print');
      printBtn.addEventListener('click', () => window.print());
    }
  };

  const translateDirections = () => {
    const getXpathElements = document.evaluate(
      '//a[text()="Directions"]',
      document,
      null,
      XPathResult.ANY_TYPE,
      null,
    );
    const directions = getXpathElements.iterateNext();
    if (directions) {
      directions.innerText = t('MapLink.directions');
    }
  };

  const translateJoin = () => {
    const getXpathElements = document.evaluate(
      '//a[text()="Join"]',
      document,
      null,
      XPathResult.ANY_TYPE,
      null,
    );
    const join = getXpathElements.iterateNext();
    if (join) {
      join.innerText = t('ConfirmationPage.join');
    }
  };

  const translateTest = () => {
    const getXpathElements = document.evaluate(
      '//a[text()="Test"]',
      document,
      null,
      XPathResult.ANY_TYPE,
      null,
    );
    const test = getXpathElements.iterateNext();
    if (test) {
      test.innerText = t('ConfirmationPage.test');
    }
  };

  const setErrorOnFailedDocs = () => {
    if (sessionStorage.getItem("DocUpload_OnError") != null) {
      const docErrorMessage = document.querySelector('#DocUpload');
      docErrorMessage.style.display = "block";
      docErrorMessage.innerText = t(sessionStorage.getItem("DocUpload_OnError"));
    }
  }
  const handleOnRender = () => {
    setCalendarButton();
    setPrintButton();
    translateDirections();
    translateJoin();
    translateTest();
    setErrorOnFailedDocs();
  };

  return (
    <>
      <style>{`
        .formio-button {
          background-color: ${branding.buttonColor};
          border: 1px solid ${branding.buttonColor};
          border-radius: .25rem;
          color: ${branding.buttonTextColor};
          display: inline-block;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          padding: .375rem .75rem;
          text-align: center;
          transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          user-select: none;
          vertical-align: middle;
          -webkit-user-select: none;
          -ms-user-select: none;
        } 
        a.formio-button {
          font-size: 0.85rem;
          font-weight: bold;
          line-height: 1.4;
        }
        .formio-button:hover {
          background-color: ${branding.buttonTextColor};
          border-color: ${branding.buttonColor};
          color: ${branding.buttonColor};
        }
        .formio-button:active {
          background-color: ${branding.buttonTextColor} !important;
          border-color: ${branding.buttonColor} !important;
          color: ${branding.buttonColor} !important;
        }
        .formio-button:focus {
          background-color: ${branding.buttonTextColor} !important;
          border-color: ${branding.buttonColor} !important;
          color: ${branding.buttonColor} !important;
        }
        .react-add-to-calendar__button {
          display: block !important;
          padding: 0.375rem 0.75rem !important;
        }
      `}</style>

      {formDefinition && formDefinition.data && (
        <Form
          form={formDefinition.data}
          options={{
            i18next: newInstance,
          }}
          onRender={handleOnRender}
        />
      )}
    </>
  );
};

ConfirmationFormio.propTypes = {
  visitId: PropTypes.string.isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  provider: PropTypes.instanceOf(Object).isRequired,
  timezoneName: PropTypes.string.isRequired,
  visit: PropTypes.instanceOf(Object).isRequired,
};

export default ConfirmationFormio;
