import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Toast from 'react-bootstrap/Toast';
import { clearNotification } from 'redux/actions/uiActions';
import { BrandingContext } from 'components/contexts/BrandingContext';
import './Notification.scss';

const Notification = ({
  show,
  title,
  message,
  // severity is ignored because leadership doesn't want too many colors.
  severity,
  delay,
  hideDesktop,
  clearNotification,
}) => {
  const { branding } = useContext(BrandingContext);

  const style = {
    backgroundColor: branding.secondaryColor,
    color: branding.primaryColor,
  };

  const className = `Notification ${hideDesktop ? 'hide-desktop' : ''}`;
  return (
    <div className={className}>
      <Toast
        autohide
        show={show}
        delay={delay}
        onClose={() => {
          setTimeout(clearNotification, delay);
        }}
      >
        <Toast.Header style={style} >
          <strong className="mr-auto">{title}</strong>
        </Toast.Header>
        <Toast.Body style={style} >
          {message}
        </Toast.Body>
      </Toast>
    </div>
  );
};

Notification.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
  delay: PropTypes.number.isRequired,
  hideDesktop: PropTypes.bool.isRequired,
  clearNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  const {
    show,
    title,
    message,
    severity,
    delay,
    hideDesktop,
  } = state.ui.notification;
  return {
    show,
    title,
    message,
    severity,
    delay,
    hideDesktop,
  };
};

const mapDispatchToProps = { clearNotification };

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
