import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function venuesOfCareReducer(
  state = initialState.venuesOfCare,
  action,
) {
  switch (action.type) {
    case types.BEGIN_FETCH_VENUES_OF_CARE:
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: true,
        },
      };
    case types.FETCH_VENUES_OF_CARE_SUCCESS:
      return {
        data: action.venuesOfCare,
        included: action.included,
        meta: { loading: false },
      };
    default:
      return state;
  }
}

export function getPhysicianServicesVOC(vocData) {
  return Object.values(vocData).find(
    v => v.attributes.venueType === 'physician_services',
  );
}
