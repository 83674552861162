import {
  handleResponse,
  handleError,
  getEndpoint,
  getHeaderInfo,
} from './apiUtils';

export const getActiveServices = (context = 'patient') => {
  return fetch(
    getEndpoint(`services`, {
      'page[size]': 999,
      include: 'service-line',
      'filter[active]': true,
      'filter[context]': context,
    }),
    getHeaderInfo(),
  )
    .then(handleResponse)
    .catch(handleError);
};

export const getActiveServicesByPermalink = (
  permalink = 'emergency-room',
  context = 'patient',
) => {
  return fetch(
    getEndpoint(`services`, {
      'page[size]': 999,
      include: 'service-line',
      'filter[active]': true,
      'filter[context]': context,
      'filter[permalink]': permalink,
    }),
    getHeaderInfo(),
  )
    .then(handleResponse)
    .catch(handleError);
};

export const getServiceLines = () => {
  return fetch(
    getEndpoint(`service-lines`, {
      'page[size]': 999,
      include: 'services',
    }),
    getHeaderInfo(),
  )
    .then(handleResponse)
    .catch(handleError);
};
