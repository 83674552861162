import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import 'components/educational/EducationalScheduleCard.scss';
import 'components/educational/EducationalImage.scss';

const EducationalResultSkeleton = () => {
  return (
    <Grid
      item
      container
      xs={12}
      sm={8}
      alignContent="center"
      justify="space-between"
    >
      <div className="EducationalScheduleCard">
        <Grid container alignItems="center" justify="space-between">
          <Grid item sm={3}>
            <div className="EducationalImage">
              <div className="educational-image-element">
                <Skeleton
                  height={220}
                  width={190}
                  style={{ margin: 0, transform: 'scale(1,1)' }}
                />
              </div>
            </div>
          </Grid>

          <Grid item sm={4}>
            <Skeleton width={200} height={27} />
            <Skeleton width={100} height={20} />
            <Skeleton width={75} height={20} />
            <Skeleton width={150} height={20} />
            <Skeleton width={165} height={20} />
            <Skeleton width={100} height={20} />
          </Grid>

          <Grid item sm={4}>
            <Skeleton width={200} height={20} />
            <Skeleton width={124} height={40} />
            <Skeleton width={160} height={20} />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default EducationalResultSkeleton;
