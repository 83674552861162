import Jsona from 'jsona';
import * as types from '../actions/actionTypes';
import initialState from './initialState';

const dataFormatter = new Jsona();

export default function insurancePlansReducer(
  state = initialState.insurancePlans,
  action,
) {
  switch (action.type) {
    case types.FETCH_INSURANCE_PLANS_SUCCESS:
      return dataFormatter.deserialize(action.insurancePlans);
    default:
      return state;
  }
}
