import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Avatar } from '@material-ui/core';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { serviceLineIcons } from '../../config/serviceLineIcons';
import { uniqueid } from 'utils/helpers';
import { BrandingContext } from '../contexts/BrandingContext';
import { setPhysicianShowForm } from '../../redux/actions/educationalPageActions';


const SpecialtyBubble = ({
  name,
  permalink,
  serviceLinePermalink,
  services,
  setPhysicianShowForm,
  id,
}) => {
  const { branding } = useContext(BrandingContext);
  const [t] = useTranslation();
  const iconForPermalink = permalink => {
    const permalinkIcon = serviceLineIcons.find(i => i.permalink === permalink);

    if (!isEmpty(permalinkIcon)) {
      return permalinkIcon.icon;
    }

    return '';
  };

  const displayName = () => {
    if (
      !isEmpty(services) &&
      services.length === 1 &&
      services[0].attributes.name !== name
    ) {
      return services[0].attributes.name;
    }
    if (name === 'Find A Doctor') {
      return t('LandingPage.findDoctor');
    }
    return name;
  };

  const linkAddress = () => {
    const serviceParam = serviceLinePermalink || permalink;
    let address = `/search?ux_mode=default&service=${serviceParam}&serviceName=${name}`;
    if (!isEmpty(services) && services.length) {
      address += services.map(s => `&subservice_ids=${s.id}`).join('');
      if (services.length > 0) address += `&service_id=${services.map(s => s.id).join(',')}`;
    }
    // if (id) {
    //   address += `&service_id=${id}`;
    // }
    return address;
  };

  useEffect(() => {
    const svgG = Array.from(document.querySelectorAll("svg[id],svg [id]"));
    svgG.forEach(g => {
      if (g.id) {
        // eslint-disable-next-line no-param-reassign
        g.id = uniqueid();
      }
    })
  }, []);
  return (
    <Link
      className="SpecialtyBubble"
      to={linkAddress()}
      onClick={() => {
        localStorage.setItem('voc', permalink);
        localStorage.setItem('speciality', permalink);
        setPhysicianShowForm(true);
      }}
    >
      <Avatar
        style={{
          backgroundColor: branding.primaryColor,
        }}
        className="icon-avatar"
      >
        {iconForPermalink(permalink)}
      </Avatar>
      <p className="icon-text">{displayName()}</p>
    </Link>
  );
};

SpecialtyBubble.defaultProps = {
  services: null,
  serviceLinePermalink: '',
};

SpecialtyBubble.propTypes = {
  name: PropTypes.string.isRequired,
  permalink: PropTypes.string.isRequired,
  serviceLinePermalink: PropTypes.string,
  services: PropTypes.instanceOf(Object),
  setPhysicianShowForm: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  setPhysicianShowForm,
};

export default connect(null, mapDispatchToProps)(SpecialtyBubble);
