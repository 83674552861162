import * as types from '../actions/actionTypes';
import initialState from './initialState';

const crypto = require('crypto');

export default function authTokenReducer(
  state = initialState.authToken,
  action,
) {
  switch (action.type) {
    case types.FETCH_AUTH_TOKEN_SUCCESS:
      if (sessionStorage.getItem('sessionId') === null) {
        let sessionId = crypto.createHash('sha1').update(action.token).digest('hex');
        sessionStorage.setItem('sessionId', sessionId);
      } else {
        let sessionId = sessionStorage.getItem('sessionId');
        sessionStorage.setItem('sessionId', sessionId);
      }
      localStorage.setItem('jwt', action.token);
      return action.token;
    default:
      return state;
  }
}
