import React from 'react';
import PropTypes from 'prop-types';
import PrivacyPolicy from 'components/PrivacyPolicy';
import BaseModal from './BaseModal';

const PrivacyPolicyModal = ({ show, onClose }) => {
  return (
    <BaseModal show={show} onClose={onClose}>
      <PrivacyPolicy />
    </BaseModal>
  );
};

PrivacyPolicyModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PrivacyPolicyModal;
