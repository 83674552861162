import React from 'react';
import PropTypes from 'prop-types';
import formatMapUrl from 'utils/formatMapUrl';
import { useTranslation } from 'react-i18next';
import './Map.scss';

const MapLink = ({ location }) => {
  const [t] = useTranslation();
  if (!location) return <></>;
  return (
    <a
      aria-label={`Directions to ${location.address}, ${location.city}, ${location.state} ${location.zip}`}
      title={t('MapLink.directions')}
      className="map-link link-tdn"
      target="_blank"
      rel="noopener noreferrer"
      href={formatMapUrl(location)}
    >
      {t('MapLink.directions')}
    </a>
  );
};

MapLink.propTypes = {
  location: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
};

export default MapLink;
