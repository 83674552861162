import { processSettings } from 'utils/scheduledDaysUtils';
import { apiCallError, beginApiCall } from './apiStatusActions';
import * as settingsApi from '../../api/settingsApi';
import * as types from './actionTypes';

export function settingsSuccess(settings) {
  const structuredSettings = settings.data;
  return {
    type: types.SET_SETTINGS,
    settings: [...structuredSettings],
  };
}

export function getSettings() {
  // eslint-disable-next-line func-names
  return async function (dispatch) {
    dispatch(beginApiCall());
    try {
      const settings = await settingsApi.getSettings();
      dispatch(settingsSuccess(settings));
    } catch (error) {
      dispatch(apiCallError(error));
      throw error;
    }
  };
}
