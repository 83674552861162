import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomTextArea from '../CustomTextArea';

const PatientComplaintFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const [t] = useTranslation();
  return (
    <>
      {formContext.isFieldEnabled('patient-complaint') && (
        <div className="form-section_row">
          <CustomTextArea
            fieldKey="reason"
            fieldValue={formContext.form.reason}
            validateFormHandler={formContext.handleSetFormState}
            label={t('PatientComplaintFields.reasonForVisit')}
            hasError={formContext.hasError('patient-complaint')}
            errorMessage={formContext.getError('patient-complaint')}
            required={formContext.isFieldRequired('patient-complaint')}
          />
        </div>
      )}
    </>
  );
};

export default PatientComplaintFields;
