import React, { useEffect, useState, useContext, useRef } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { isMobile } from 'react-device-detect';
import { useLocation, useHistory } from 'react-router-dom';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import OmniBox from 'components/form/OmniboxCheckboxList';
import { useTranslation } from 'react-i18next';
import {
  FormGroup,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import ScrollContainer from 'react-indiana-drag-scroll';
import SortIcon from '@material-ui/icons/Sort';
import Button from 'react-bootstrap/Button';
import { loadActiveServices } from 'redux/actions/serviceLineActions';
import { BrandingContext } from 'components/contexts/BrandingContext';
import { makeStyles } from '@material-ui/core/styles';
import { BlackCheckbox, BlackSwitch } from '../form/FormElements';
import './InventoryFilter.scss';
import InventoryFilterGroup from './InventoryFilterGroup';
import DialogCheckbox from '../dialogs/DialogCheckbox';
import DialogRadio from '../dialogs/DialogRadio';
import DialogMobile from '../dialogs/DialogMobile';
import { shouldShowSearchElement } from '../../helpers/searchOptions';
import * as searchConstants from '../../config/searchConstants';
import { ChildCare } from '@material-ui/icons';
export function shouldShowInventoryFilters(
  uxMode,
  service,
  searchConstant,
  selectedTab,
) {
  if (selectedTab === 'emergency_room' || selectedTab === 'urgent_care')
    return false;
  else return shouldShowSearchElement(uxMode, service, searchConstant);
}
const InventoryFilter = ({
  results,
  filters,
  handleFilterChange,
  handleFilterReset,
  setFilterStack,
  loadActiveServices,
  loading,
  totalCount,
  sortProp,
  services,
  serviceLines,
  uxMode,
  activeTab,
}) => {
  const [sortBy, setSortBy] = useState(sortProp);
  const history = useHistory();
  const languagesRef = useRef();
  const hospitalsRef = useRef();
  const { search, pathname } = useLocation();
  const params = QueryString.parse(search);
  const { branding } = useContext(BrandingContext);
  const useStyles = makeStyles({
    resetButton: {
      backgroundColor: branding.primaryColor,
      border: 0,
      color: branding.buttonTextColor,
      '&:hover, &:focus, &:not(.disabled):not(.disabled):active': {
        backgroundColor: branding.buttonTextColor,
        color: branding.buttonColor,
      },
    },
    filterHeading: {
      backgroundColor: branding.primaryColor,
      color: branding.secondaryTextColor,
    },
  });
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    if (isEmpty(services)) {
      loadActiveServices();
    }
  }, []);

  const initializeFilter = (param, initialValue) => {
    if (param) {
      if (Array.isArray(param)) {
        return [...param];
      }
      return [param];
    }
    return initialValue;
  };

  const [filterChanged, setFilterChanged] = useState(false);
  const [acceptNewPatientFilter, setAcceptNewPatientFilter] = useState(
    params.acceptNewPatient ? JSON.parse(params.acceptNewPatient) : false,
  );
  const [genderFilters, setGenderFilters] = useState(
    initializeFilter(params.genders, []),
  );
  const [languageFilters, setLanguageFilters] = useState(
    initializeFilter(params.languages, []),
  );
  const [hospitalAffiliationFilters, setHospitalAffiliationsFilters] = useState(
    initializeFilter(params.hospitalAffiliationFilters, []),
  );
  const [activeServiceFilters, setActiveServiceFilters] = useState(
    initializeFilter(params.activeServiceFilters, []),
  );
  const [showSubservicesDialog, setShowSubservicesDialog] = useState(false);
  const [showNewPatientDialog, setShowNewPatientDialog] = useState(false);
  const [showAffiliatesDialog, setShowAffiliatesDialog] = useState(false);
  const [showGenderDialog, setShowGenderDialog] = useState(false);
  const [showLanguagesDialog, setShowLanguagesDialog] = useState(false);
  const [showSortByDialog, setShowSortByDialog] = useState(false);
  const [tempAcceptNewPatient, setTempAcceptNewPatient] = useState(
    acceptNewPatientFilter,
  );

  const formatFilters = () => {
    return {
      acceptNewPatient: acceptNewPatientFilter,
      genders: genderFilters,
      languages: languageFilters,
      hospitalAffiliationFilters,
      sort: params.sort || sortBy,
      activeServiceFilters,
    };
  };

  /**
   * Here we group all filters except for 'sortBy'.
   * We also keep values in array format instead of hash.
   * This is used to keep track of any active filters, without
   * concern for what is specifically active.
   *
   * Used by 'displayFilterSection' in InventoryPage.
   * @returns {Array}
   */
  const concatFilters = () => {
    const results = [].concat(
      genderFilters,
      languageFilters,
      hospitalAffiliationFilters,
      activeServiceFilters,
    );
    if (acceptNewPatientFilter)
      return results.concat(`${acceptNewPatientFilter}`);

    return results;
  };

  useEffect(() => {
    handleFilterChange(formatFilters());
    setFilterStack(concatFilters());
  }, []);

  const handleCheckBoxChange = (filter, event) => {
    const { value, checked } = event.target;
    setFilterChanged(true);
    if (filter === 'gender') {
      if (checked) {
        setGenderFilters([value].concat(genderFilters));
      } else {
        setGenderFilters(genderFilters.filter(item => item !== value));
      }
    }

    if (filter === 'language') {
      if (checked) {
        setLanguageFilters([value].concat(languageFilters));
      } else {
        setLanguageFilters(languageFilters.filter(item => item !== value));
      }
    }

    if (filter === 'hospital') {
      if (checked) {
        setHospitalAffiliationsFilters(
          [value].concat(hospitalAffiliationFilters),
        );
      } else {
        setHospitalAffiliationsFilters(
          hospitalAffiliationFilters.filter(item => item !== value),
        );
      }
    }
    if (filter === 'service') {
      if (checked) {
        setActiveServiceFilters([value].concat(activeServiceFilters));
      } else {
        setActiveServiceFilters(
          activeServiceFilters.filter(item => item !== value),
        );
      }
    }
  };

  const handleSwitchChange = (filter, event) => {
    const { checked } = event.target;

    setFilterChanged(true);
    if (filter === 'acceptNewPatient') {
      setAcceptNewPatientFilter(checked);
    }
  };

  const updateQueryString = filters => {
    const search = QueryString.stringify({ ...params, ...filters });
    history.replace({
      pathname,
      search,
    });
  };
  const handleResetClick = () => {
    const languagesCloseButton = languagesRef.current.getElementsByClassName(
      'MuiAutocomplete-clearIndicator',
    )[0];
    if (languagesCloseButton) languagesCloseButton.click();
    const hospitalsCloseButton = hospitalsRef.current.getElementsByClassName(
      'MuiAutocomplete-clearIndicator',
    )[0];
    if (hospitalsCloseButton) hospitalsCloseButton.click();
  };

  const resetButtonPressed = () => {
    setGenderFilters([]);
    setAcceptNewPatientFilter(false);
    setLanguageFilters([]);
    setHospitalAffiliationsFilters([]);
    setActiveServiceFilters([]);
    updateQueryString({
      acceptNewPatient: false,
      genders: [],
      languages: [],
      hospitalAffiliationFilters: [],
      activeServiceFilters: [],
    });
    handleFilterReset();
    handleResetClick();
  };

  const [t] = useTranslation();
  useEffect(() => {
    if (filterChanged) {
      setFilterChanged(false);
      const filters = formatFilters();
      updateQueryString(filters);
      handleFilterChange(filters);
      setFilterStack(concatFilters());
    }
  }, [
    results,
    sortBy,
    filterChanged,
    genderFilters,
    languageFilters,
    acceptNewPatientFilter,
    hospitalAffiliationFilters,
    handleFilterChange,
  ]);

  const filterHasValue = filter => {
    if (typeof filter === 'boolean') {
      return filter;
    }
    return !isEmpty(filter);
  };

  const subservices = () => {
    if (isEmpty(params.subservice_ids)) {
      return [];
    }
    return Object.values(services).filter(s =>
      params.subservice_ids.includes(s.id),
    );
  };

  const serviceLine = () => {
    // Hack:  Since "Find A Doctor" isn't a real ServiceLine, if this special permalink appears, just return a dummy object with this name.
    if (params.service && params.service === 'find-a-doctor') {
      return { attributes: { name: 'Find A Doctor' } };
    }
    return Object.values(serviceLines).find(
      sl => sl.attributes.permalink === params.service,
    );
  };

  const showServiceFilter =
    subservices() &&
    subservices().length > 1 &&
    params.service !== 'find-a-doctor';

  const filledStyle = {
    background: branding.buttonColor,
    border: `1px solid ${branding.buttonColor}`,
    color: branding.buttonTextColor,
  };

  const inverseStyle = {
    border: `1px solid ${branding.primaryColor}`,
    color: branding.primaryColor,
  };

  const handleSearchLanguageChanged = v => {
    setFilterChanged(true);
    setLanguageFilters(v);
  };

  const handleSearchHospitalChanged = v => {
    setFilterChanged(true);
    setHospitalAffiliationsFilters(v);
  };

  const displayLanguageFilters = () => {
    return (
      filters.languages &&
      filters.languages.length > 0 && (
        <InventoryFilterGroup
          title={t('InventoryFilter.languages')}
          loading={loading}
        >
          <OmniBox
            ref={languagesRef}
            defaultvalues={languageFilters}
            options={filters.languages}
            handleChange={handleSearchLanguageChanged}
          />
        </InventoryFilterGroup>
      )
    );
  };

  const displayHospitalAffiliations = () => {
    return (
      filters.hospitalAffiliations &&
      filters.hospitalAffiliations.length > 0 && (
        <InventoryFilterGroup
          title={t('InventoryFilter.hospitalAffiliations')}
          loading={loading}
        >
          <OmniBox
            ref={hospitalsRef}
            defaultvalues={hospitalAffiliationFilters}
            options={filters.hospitalAffiliations}
            handleChange={handleSearchHospitalChanged}
          />
        </InventoryFilterGroup>
      )
    );
  };

  const showNewPatientsFilter = shouldShowInventoryFilters(
    uxMode,
    params.service,
    searchConstants.TAKING_NEW_PATIENTS,
    activeTab,
  );

  const showGenderFilter = shouldShowInventoryFilters(
    uxMode,
    params.service,
    searchConstants.GENDERS,
    activeTab,
  );

  const showLanguageFilter = shouldShowInventoryFilters(
    uxMode,
    params.service,
    searchConstants.LANGUAGES,
    activeTab,
  );

  const showAffiliationsFilter = shouldShowInventoryFilters(
    uxMode,
    params.service,
    searchConstants.HOSPITAL_AFFILIATIONS,
    activeTab,
  );
  const showResetButton =
    showNewPatientsFilter ||
    showGenderFilter ||
    showLanguageFilter ||
    showAffiliationsFilter ||
    showServiceFilter;

  return (
    <div
      className="inventory-filter"
      style={{
        backgroundColor: `${branding.secondaryColor}`,
      }}
    >
      {!isMobile && (
        <div className="hide-mobile">
          <header
            style={{
              backgroundColor: branding.primaryColor,
              color: `${branding.buttonTextColor} !important`,
            }}
          >
            {loading ? (
              <Typography
                variant="h5"
                component="h2"
                className={`filter-heading ${classes.filterHeading}`}
              >
                Loading...
              </Typography>
            ) : (
              <Typography
                variant="h5"
                component="h2"
                className={`filter-heading ${classes.filterHeading}`}
              >
                {totalCount}{' '}
                {totalCount === 1
                  ? t('InventoryFilter.result')
                  : t('InventoryFilter.results')}
              </Typography>
            )}
            {!loading && showResetButton && (
              <Button
                type="button"
                onClick={resetButtonPressed}
                className={classes.resetButton}
              >
                {t('InventoryFilter.reset')}
              </Button>
            )}
          </header>

          <article>
            {showServiceFilter && (
              <InventoryFilterGroup
                title={serviceLine().attributes.name}
                loading={loading}
              >
                <FormGroup>
                  {subservices().map(service => (
                    <FormControlLabel
                      control={
                        <BlackCheckbox
                          inputProps={{ title: serviceLine().attributes.name }}
                        />
                      }
                      value={service.id}
                      checked={activeServiceFilters.includes(service.id)}
                      onChange={evt => handleCheckBoxChange('service', evt)}
                      label={service.attributes.name}
                      key={service.id}
                    />
                  ))}
                </FormGroup>
              </InventoryFilterGroup>
            )}

            {showNewPatientsFilter && (
              <InventoryFilterGroup
                title={t('InventoryFilter.takingNewPatients')}
                loading={loading}
              >
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <BlackSwitch
                      inputProps={{
                        title: t('InventoryFilter.takingNewPatients'),
                      }}
                      checked={acceptNewPatientFilter === true}
                      onChange={evt =>
                        handleSwitchChange('acceptNewPatient', evt)
                      }
                    />
                  </Grid>
                  <Grid item>{t('VisitFeedbackPage.yes')}</Grid>
                </Grid>
              </InventoryFilterGroup>
            )}

            {showGenderFilter && (
              <InventoryFilterGroup
                title={t('InventoryFilter.GenderFields.gender')}
                loading={loading}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <BlackCheckbox
                        inputProps={{ title: 'Male' }}
                        value="m"
                        checked={genderFilters.includes('m')}
                        onChange={evt => handleCheckBoxChange('gender', evt)}
                      />
                    }
                    label={t('shared:Male')}
                    key="m"
                  />
                  <FormControlLabel
                    control={
                      <BlackCheckbox
                        inputProps={{ title: 'Female' }}
                        value="f"
                        checked={genderFilters.includes('f')}
                        onChange={evt => handleCheckBoxChange('gender', evt)}
                      />
                    }
                    label={t('shared:Female')}
                    key="f"
                  />
                </FormGroup>
              </InventoryFilterGroup>
            )}

            {showLanguageFilter && displayLanguageFilters(filters)}
            {showAffiliationsFilter && displayHospitalAffiliations(filters)}
          </article>
        </div>
      )}

      {isMobile && (
        <div className="filter-mobile">
          <ScrollContainer className="scroll-container">
            <div className="filter-mobile-wrapper">
              {false && (
                <button
                  onClick={() => setShowSortByDialog(true)}
                  type="button"
                  style={filledStyle}
                  className="filled"
                >
                  <SortIcon />
                </button>
              )}

              {showServiceFilter && (
                <button
                  type="button"
                  style={
                    filterHasValue(activeServiceFilters)
                      ? filledStyle
                      : inverseStyle
                  }
                  className={
                    filterHasValue(activeServiceFilters) ? 'filled' : 'inverse'
                  }
                  onClick={() => setShowSubservicesDialog(true)}
                >
                  {`${t('InventoryFilter.Services')}${filterHasValue(activeServiceFilters)
                      ? ` (${activeServiceFilters.length})`
                      : ''
                    }`}
                </button>
              )}

              {showGenderFilter && (
                <button
                  type="button"
                  style={
                    filterHasValue(genderFilters) ? filledStyle : inverseStyle
                  }
                  className={
                    filterHasValue(genderFilters) ? 'filled' : 'inverse'
                  }
                  onClick={() => setShowGenderDialog(true)}
                >
                  {`${t('InventoryFilter.GenderFields.gender')}${filterHasValue(genderFilters)
                      ? ` (${genderFilters.length})`
                      : ''
                    }`}
                </button>
              )}

              {filters.languages &&
                filters.languages.length > 0 &&
                showLanguageFilter && (
                  <button
                    type="button"
                    style={
                      filterHasValue(languageFilters)
                        ? filledStyle
                        : inverseStyle
                    }
                    className={
                      filterHasValue(languageFilters) ? 'filled' : 'inverse'
                    }
                    onClick={() => setShowLanguagesDialog(true)}
                  >
                    {`${t('InventoryFilter.languages')}${filterHasValue(languageFilters)
                        ? ` (${languageFilters.length})`
                        : ''
                      }`}
                  </button>
                )}

              {showNewPatientsFilter && (
                <button
                  type="button"
                  style={
                    filterHasValue(acceptNewPatientFilter)
                      ? filledStyle
                      : inverseStyle
                  }
                  className={
                    filterHasValue(acceptNewPatientFilter)
                      ? 'filled'
                      : 'inverse'
                  }
                  onClick={() => setShowNewPatientDialog(true)}
                >
                  {t('InventoryFilter.takingNewPatients')}
                </button>
              )}

              {filters.hospitalAffiliations &&
                filters.hospitalAffiliations.length > 0 &&
                showAffiliationsFilter && (
                  <button
                    type="button"
                    style={
                      filterHasValue(hospitalAffiliationFilters)
                        ? filledStyle
                        : inverseStyle
                    }
                    className={
                      filterHasValue(hospitalAffiliationFilters)
                        ? 'filled'
                        : 'inverse'
                    }
                    onClick={() => setShowAffiliatesDialog(true)}
                  >
                    {`${t('InventoryFIlter.affiliations')}${filterHasValue(hospitalAffiliationFilters)
                        ? ` (${hospitalAffiliationFilters.length})`
                        : ''
                      }`}
                  </button>
                )}
            </div>
          </ScrollContainer>
        </div>
      )}
      {showNewPatientDialog && (
        <DialogMobile
          content={
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <BlackSwitch
                  inputProps={{ title: t('InventoryFilter.takingNewPatients') }}
                  checked={tempAcceptNewPatient === true}
                  onChange={evt => {
                    setTempAcceptNewPatient(evt.target.checked);
                  }}
                />
              </Grid>
              <Grid item>{t('VisitFeedbackPage.yes')}</Grid>
            </Grid>
          }
          handleShowDialog={e => {
            if (e) {
              setFilterChanged(true);
              setAcceptNewPatientFilter(tempAcceptNewPatient);
            } else {
              setTempAcceptNewPatient(acceptNewPatientFilter);
            }
            setShowNewPatientDialog(false);
          }}
          showDialog={showNewPatientDialog}
          title={t('InventoryFilter.takingNewPatients')}
        />
      )}

      {showSubservicesDialog && (
        <DialogCheckbox
          checkboxes={subservices().map(s => s.attributes.name)}
          handleShowDialog={e => {
            if (e) {
              const checkedSubserviceIds = subservices()
                .filter(s => e.value.includes(s.attributes.name))
                .map(s => s.id);
              setFilterChanged(true);
              setActiveServiceFilters(checkedSubserviceIds);
            }

            setShowSubservicesDialog(false);
          }}
          showDialog={showSubservicesDialog}
          title={serviceLine().attributes.name}
          selectedValues={subservices()
            .filter(s => activeServiceFilters.includes(s.id))
            .map(s => s.attributes.name)}
        />
      )}
      {showGenderDialog && (
        <DialogCheckbox
          checkboxes={['Male', 'Female']}
          handleShowDialog={e => {
            if (e) {
              setFilterChanged(true);
              setGenderFilters(
                e.value.map(gender => (gender === 'Male' ? 'm' : 'f')),
              );
            }

            setShowGenderDialog(false);
          }}
          showDialog={showGenderDialog}
          title={t('InventoryFilter.GenderFields.gender')}
          selectedValues={genderFilters.map(gender =>
            gender === 'm' ? 'Male' : 'Female',
          )}
          type="gender"
        />
      )}

      {showLanguagesDialog && (
        <DialogCheckbox
          showDialog={showLanguagesDialog}
          checkboxes={filters.languages}
          handleShowDialog={e => {
            if (e) {
              setFilterChanged(true);
              setLanguageFilters(e.value);
            }

            setShowLanguagesDialog(false);
          }}
          title={t('InventoryFilter.languages')}
          selectedValues={languageFilters}
          type="languages"
        />
      )}

      {showAffiliatesDialog && (
        <DialogCheckbox
          showDialog={showAffiliatesDialog}
          checkboxes={filters.hospitalAffiliations}
          handleShowDialog={e => {
            if (e) {
              setFilterChanged(true);
              setHospitalAffiliationsFilters(e.value);
            }
            setShowAffiliatesDialog(false);
          }}
          title={t('InventoryFilter.hospitalAffiliations')}
          selectedValues={hospitalAffiliationFilters}
        />
      )}

      {showSortByDialog && (
        <DialogRadio
          forceSelection
          showDialog={showSortByDialog}
          handleShowDialog={e => {
            if (e) {
              setFilterChanged(true);
              setSortBy(e.value);
            }

            setShowSortByDialog(false);
          }}
          selections={[
            { value: 'soonest', text: t('InventoryControls.soonest') },
            { value: 'nearest', text: t('InventoryControls.closest') },
          ]}
          title={t('InventoryControl.sort')}
          selectedValue={sortProp}
        />
      )}
    </div>
  );
};

InventoryFilter.defaultProps = {
  results: [],
  filters: {},
  loading: false,
};

InventoryFilter.propTypes = {
  results: PropTypes.instanceOf(Array),
  filters: PropTypes.instanceOf(Object),
  handleFilterChange: PropTypes.func.isRequired,
  handleFilterReset: PropTypes.func.isRequired,
  setFilterStack: PropTypes.func.isRequired,
  loadActiveServices: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  totalCount: PropTypes.number.isRequired,
  sortProp: PropTypes.string.isRequired,
  services: PropTypes.instanceOf(Object).isRequired,
  serviceLines: PropTypes.instanceOf(Object).isRequired,
  uxMode: PropTypes.string.isRequired,
  activeTab: PropTypes.string,
};

const mapStateToProps = state => ({
  services: state.serviceLines.services,
  serviceLines: state.serviceLines.serviceLines,
});

const mapDispatchToProps = {
  loadActiveServices,
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryFilter);
