import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import BreadCrumbs from 'components/common/BreadCrumbs';
import BrandingButton from 'components/common/BrandingButton';
import './VisitProhibitedPage.scss';
import PageMetaInfo from '../common/PageMetaInfo';

export default function VisitProhibitedPage() {
  const [t] = useTranslation();
  const history = useHistory();
  return (
    <div>
      <PageMetaInfo />
      <div className="container">
        <BreadCrumbs onlyHome />
      </div>
      <div className="container">
        <main className="VisitProhibited container">
          <h1>{t('error.prohibited.title')}</h1>

          <p className="intro">{t('error.prohibited.intro')}</p>

          <p>
            <span className="important">{t('error.prohibited.important')}</span>
          </p>

          <p>{t('error.prohibited.ending')}</p>
          <br />
          <br />
          <BrandingButton type="button" onClick={() => history.push('/')}>
            Return to Home Page
          </BrandingButton>
        </main>
      </div>
    </div>
  );
}
