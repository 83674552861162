import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, useTheme } from '@material-ui/core';
import { getTimes } from 'api/availableTimesApi';
import { Alert } from 'react-bootstrap';
import Sticky from 'react-sticky-el';
import DateRangeIcon from '@material-ui/icons/DateRange';
import 'components/inventory/InventoryControls.scss';
import DateCarousel from 'components/appointmentPicker/DateCarousel';
import DateCarouselContainer from 'components/appointmentPicker/DateCarouselContainer';
import FullSchedule from 'components/common/FullSchedule';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { groupedDays } from 'utils/dateTimeUtils';
import SchedulePicker from 'components/dialogs/SchedulePicker';
import { useSelector } from 'react-redux';
import useSettings from 'hooks/useSettings';
import { useQueryString } from '../../hooks/useQueryString';

const AppointmentPicker = ({
  amountDaysTablet,
  amountDaysDesktop,
  amountDaysLgDesktop,
  patientNowTimes,
  scheduleId,
  appointmentTypeId,
  maxTimesPerDay,
  link,
  suppressWarning,
  showFullScheduleLink,
  timezoneName,
  locationId,
  serviceName,
  component,
  inactive,
  onDateChange,
  handleSetDates,
  servicePermalink,
  scheduleStatus,
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.only('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.only('md'));
  const isLgDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [t] = useTranslation();
  const healthSystem = useSelector(state => state.healthSystem);
  const [numberOfDays, setNumberOfDays] = useState(1);
  const [date, setDate] = useState();
  const [dates, setDates] = useState([]);
  const [times, setTimes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAppointmentPicker, setShowAppointmentPicker] = useState(true);
  const { isDischargePage } = useQueryString();
  const [nextAvailableTime, setNextAvailableTime] = useState('');
  const [showSchedulePicker, setShowSchedulePicker] = useState(false);
  const useStyles = makeStyles(() => ({
    mapButton: {
      background: 'transparent',
      border: 'none',
      appearance: 'none',
    },
  }));
  const classes = useStyles();
  const availableScheduleDays = useSettings({ scheduleId });

  useEffect(() => {
    let runSetState = true;
    setLoading(true);
    if (date) {
      setDates(groupedDays(date, numberOfDays, timezoneName));
      const fetchTimes = async () => {
        const today = moment()
          .tz(timezoneName)
          .format();
        const toDate = moment(today)
          .tz(timezoneName)
          .add(availableScheduleDays - 1, 'days')
          .format();
        const responseTimes = await getTimes(
          scheduleId,
          appointmentTypeId,
          date,
          numberOfDays,
          isDischargePage
            ? 'discharge'
            : patientNowTimes
              ? 'patient_now'
              : 'patient',
          toDate,
        );
        if (runSetState) {
          const nextTime = responseTimes[0]['next-time'];
          if (
            moment(nextTime)
              .tz(timezoneName)
              .isSameOrBefore(toDate)
          )
            setNextAvailableTime(responseTimes[0]['next-time']);
          else setNextAvailableTime('');
          setTimes(responseTimes[0].times);
          await setLoading(false);
        }
      };
      if (numberOfDays > 1 && availableScheduleDays !== undefined) {
        fetchTimes();
        setShowAppointmentPicker(true);
      }
    }
    return () => {
      runSetState = false;
    };
  }, [
    scheduleId,
    appointmentTypeId,
    date,
    numberOfDays,
    availableScheduleDays,
  ]);

  useEffect(() => {
    setDate(
      moment()
        .tz(timezoneName)
        .format(),
    );
  }, [timezoneName]);
  useEffect(() => {
    setShowAppointmentPicker(false);

    if (isTablet) {
      setNumberOfDays(amountDaysTablet);
    }

    if (isDesktop) {
      setNumberOfDays(amountDaysDesktop);
    }

    if (isLgDesktop) {
      setNumberOfDays(amountDaysLgDesktop);
    }
  }, [isTablet, isDesktop, isLgDesktop]);

  const handleDateChange = date => {
    setLoading(true);
    setDate(date);
    setDates(groupedDays(date, numberOfDays, timezoneName));
  };

  return (
    <>
      {showAppointmentPicker && (
        <div className="AppointmentPicker mb-3" style={{ background: 'white' }}>
          <Grid item xs={14} container alignItems="center">
            <Grid item xs={10} container>
              {component === 'ScheduleDetailPage' ? (
                <Sticky
                  className="sticky-wrapper"
                  stickyClassName="sticy testClass"
                >
                  <DateCarousel
                    dates={dates}
                    onDateChange={handleDateChange}
                    timezoneName={timezoneName}
                    serviceName={serviceName}
                  />
                </Sticky>
              ) : (
                <DateCarousel
                  dates={dates}
                  onDateChange={handleDateChange}
                  timezoneName={timezoneName}
                  serviceName={serviceName}
                  scheduleId={scheduleId}
                />
              )}
            </Grid>
            <Grid item xs={2} container alignItems="left">
              <button
                aria-label="Calender Icon"
                className={`view-button ${classes.mapButton}`}
                type="button"
                onClick={() => setShowSchedulePicker(true)}
              >
                <DateRangeIcon
                  style={{ marginRight: '5px', marginTop: '-15px' }}
                />
              </button>
              <SchedulePicker
                openDialog={showSchedulePicker}
                closeDialog={e => {
                  if (e) {
                    e.displayText = moment(e.value?.start)
                      .tz(timezoneName)
                      .format('MMM D, YYYY');
                    console.log(e);
                    onDateChange(moment(e.value?.start).tz(timezoneName));
                    handleSetDates(e.value?.start);
                    handleDateChange(e.value?.start);
                    //handleSetFormState({ timestamp: e });
                  }
                  setShowSchedulePicker(false);
                }}
                timezone={timezoneName}
                timestamp={date || moment()
                  .tz(timezoneName)
                  .format()}
                scheduleId={scheduleId}
              />
            </Grid>
            {!appointmentTypeId && !suppressWarning && (
              <Grid container>
                <Grid item sm={1} />
                <Grid item container sm={10} className="dates">
                  <Alert variant="info" style={{ width: '100%' }}>
                    {t('PhysicianForm.selectApp')}
                  </Alert>
                </Grid>
                <Grid item sm={1} />
              </Grid>
            )}
            <DateCarouselContainer
              dates={dates}
              times={times}
              link={link}
              loading={loading}
              locationId={locationId}
              maxTimesPerDay={maxTimesPerDay}
              timezoneName={timezoneName}
              showNextAvailable
              inactive={inactive}
              nextAvailableTime={nextAvailableTime}
              // this will send the aapointment type present flag only for which services it is required
              appointmentTypeFlag={!(!appointmentTypeId && !suppressWarning)}
              servicePermalink={servicePermalink}
              scheduleId={scheduleId}
              scheduleStatus={scheduleStatus}
            />
          </Grid>

          {showFullScheduleLink && (
            <Grid container justify="space-between" style={{ padding: '15px' }}>
              <Grid item>
                <FullSchedule
                  schedule={scheduleId}
                  appointmentType={appointmentTypeId}
                  inactive={inactive}
                  servicePermalink={servicePermalink}
                  nowContext={patientNowTimes}
                />
              </Grid>

              <Grid item />
            </Grid>
          )}
        </div>
      )}
    </>
  );
};

AppointmentPicker.defaultProps = {
  amountDaysTablet: 4,
  amountDaysDesktop: 4,
  amountDaysLgDesktop: 4,
  locationId: '',
  patientNowTimes: false,
  maxTimesPerDay: null,
  suppressWarning: false,
  showFullScheduleLink: false,
  serviceName: '',
  component: '',
  dates: [],
  onDateChange: () => { },
  handleSetDates: () => { },
};

AppointmentPicker.propTypes = {
  amountDaysTablet: PropTypes.number,
  amountDaysDesktop: PropTypes.number,
  amountDaysLgDesktop: PropTypes.number,
  /** Appt Type ID. Required to fetch times. */
  appointmentTypeId: PropTypes.string.isRequired,
  /** Registration Page link */
  link: PropTypes.string.isRequired,
  /** Schedule ID. Required to fetch times. */
  scheduleId: PropTypes.string.isRequired,
  /** Display time based on the facilty time zone */
  timezoneName: PropTypes.string.isRequired,
  /** Number of days to display. */
  locationId: PropTypes.string,
  /** Component will display all times unless limited by this prop. */
  maxTimesPerDay: PropTypes.number,
  /** Whether to display a link to full schedule page.
   * Redundant on the Schedule Page, so default is false. */
  showFullScheduleLink: PropTypes.bool,
  /** Optional setting to hide warning alert.
   * Used for schedules with no appt types. */
  suppressWarning: PropTypes.bool,
  serviceName: PropTypes.string,
  component: PropTypes.string,
  patientNowTimes: PropTypes.bool,
  inactive: PropTypes.bool,
  dates: PropTypes.arrayOf(Object),
  onDateChange: PropTypes.func,
  handleSetDates: PropTypes.func,
  servicePermalink: PropTypes.string.isRequired,
  scheduleStatus: PropTypes.instanceOf(Object).isRequired,
};

export default AppointmentPicker;
