import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, LinearProgress } from '@material-ui/core';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { PatientIntakeContext } from '../contexts/PatientIntakeContext';
import ValidationPage from './ValidationPage';
import StepperComponent from './Stepper';
import InsurancePage from './InsurancePage';
import { getExpiryStatus } from 'api/patientIntakeApi';
import { useTranslation } from 'react-i18next';
import PaymentPage from './PaymentPage';
import DocsPage from './DocsPage';
import SubmitPage from './SubmitPage';
import ThankYouPage from './ThankYouPage';
import CustomTemplatePage from './CustomTemplatePage';

const PatientIntakePage = ({ match }) => {
    const [loading, setLoading] = useState(false);
    const [isVisitValid, setIsVisitValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const intake = useContext(PatientIntakeContext);
    const secureId = match?.params?.secureId;
    const [t] = useTranslation();

    const useStyles = makeStyles(() => ({
        container: {
            paddingInline: "10px",
            justifyContent:"center",
            alignItems:"center"
        }
    }));
    const classes = useStyles();

    const fetchExpirtStatus = async () => {
        try {
            setLoading(true);
            const data = await getExpiryStatus(secureId);
            setIsVisitValid(data?.success);
            if (!data?.success) {
                setErrorMessage(data?.message)
            }else if(data?.success){
                const validationObj = {
                    "documentTypeId": 0,
                    "documentTypeCode": "validation",
                    "documentTypeName": "Validation"
                }
                data.requiredDocumets.unshift(validationObj)
                intake.setScheduleId(data?.scheduleId);
                intake.setRequiredSteps(data?.requiredDocumets);
            }
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        intake.setSecureId(secureId);
        fetchExpirtStatus();
    }, [secureId]);

    if (loading) return <LinearProgress />;

    return (

        <Grid container className={classes.container} >
            {errorMessage && (
                <Alert severity="error">
                    <b>{t(errorMessage)}</b>
                </Alert>
            )}
            {!errorMessage && isVisitValid && <Grid item xs={12} sm={6}  >
            {intake?.page < intake?.requiredSteps?.length + 1 && (
                    <StepperComponent />
                )}
                {intake?.requiredSteps?.map((label, index) => {
                    if(label?.documentTypeCode === "validation" && index === intake?.page) return <ValidationPage key={index} />
                    if(label?.documentTypeCode === "insurance" && index === intake?.page) return <InsurancePage key={index} />
                    if(label?.documentTypeCode === "payment" && index === intake?.page) return <PaymentPage key={index} />
                    if(label?.documentTypeCode === "drivers-license" && index === intake?.page) return <DocsPage name={label?.documentTypeCode} key={index} />
                    if(label?.documentTypeCode === "other" && index === intake?.page) return <DocsPage name={label?.documentTypeCode} key={index} />
                    if(label?.documentTypeCode === "lab" && index === intake?.page) return <DocsPage name={label?.documentTypeCode} key={index} />
                    if (label?.documentTypeCode === "consent" && index === intake?.page) return <CustomTemplatePage name={label?.documentTypeCode} key={index} />
                    if (label?.documentTypeCode === "vaccinations" && index === intake?.page) return <CustomTemplatePage name={label?.documentTypeCode} key={index} />
                    if (label?.documentTypeCode === "social-history" && index === intake?.page) return <CustomTemplatePage name={label?.documentTypeCode} key={index} />
                    if (label?.documentTypeCode === "personal-and-medical-history" && index === intake?.page) return <CustomTemplatePage name={label?.documentTypeCode} key={index} />
                    if (label?.documentTypeCode === "medication" && index === intake?.page) return <CustomTemplatePage name={label?.documentTypeCode} key={index} />
                    if (label?.documentTypeCode === "allergies" && index === intake?.page) return <CustomTemplatePage name={label?.documentTypeCode} key={index} />
                    if (label?.documentTypeCode === "family-health-history" && index === intake?.page) return <CustomTemplatePage name={label?.documentTypeCode} key={index} />
                })}
                {intake?.page === intake?.requiredSteps?.length  && <SubmitPage />} 
                {intake?.page === intake?.requiredSteps?.length + 1  && <ThankYouPage />}
            </Grid>}
        </Grid>
    );
};

PatientIntakePage.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            secureId: PropTypes.string,
        }),
    }).isRequired,
};

export default PatientIntakePage;
