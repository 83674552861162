import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { isMobile } from 'react-device-detect';
import { Grid } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useDispatch, useSelector } from 'react-redux';
import { fetchHealthSystem } from 'redux/actions/healthSystemActions';
import DateHeading from 'components/appointmentPicker/DateHeading';
import './DateCarousel.scss';
import useSettings from 'hooks/useSettings';
import { useQueryString } from '../../hooks/useQueryString';
import {
  ER_SERVICE_NAMES,
  UC_SERVICE_NAMES,
} from '../../config/searchConstants';

const DateCarousel = ({
  dates,
  dateHeadingLayout,
  onDateChange,
  timezoneName,
  serviceName,
  onScroll,
  scrollLeft,
  scheduleId,
}) => {
  const healthSystem = useSelector(state => state.healthSystem);
  const [date, setDate] = useState(
    moment().tz(timezoneName || healthSystem['time-zone']),
  );
  const [disabled, setDisabled] = useState(true);
  const [disableRight, setDisableRight] = useState(false);
  const { pathname } = useQueryString();
  const isSearchPage = () => pathname === '/search';
  const dispatch = useDispatch();
  const today = moment()
    .tz(timezoneName || healthSystem['time-zone'])
    .format();

  const dateSliderRef = useRef(null);

  const availableScheduleDays = useSettings({
    scheduleId,
    caller: 'DateCarousel',
  });

  useEffect(() => {
    if (scrollLeft.fromComponent !== 'DateCarousel')
      dateSliderRef.current.scrollLeft = scrollLeft.left;
  }, [scrollLeft]);

  const updateDate = date => {
    if (
      moment(today)
        .tz(timezoneName || healthSystem['time-zone'])
        .isSameOrAfter(date, 'day')
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setDate(date);
    onDateChange(date);
  };

  const componentClass = `DateCarousel ${dateHeadingLayout}`;
  const controlClass = `control ${disabled ? 'disabled' : ''}`;
  const [limit, setLimit] = useState(0);

  useEffect(() => {
    if (!Object.keys(healthSystem)?.length) {
      dispatch(fetchHealthSystem());
    }
  }, []);

  useEffect(() => {
    if (healthSystem && Object.keys(healthSystem).length) {
      if (availableScheduleDays) {
        setLimit(availableScheduleDays);
      }
    }
  }, [healthSystem, availableScheduleDays]);

  useEffect(() => {
    setDate(dates[0]);
    if (dates) {
      if (
        moment(today)
          .tz(timezoneName || healthSystem['time-zone'])
          .isSameOrAfter(dates[0], 'day')
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }

      const ER = ER_SERVICE_NAMES.includes(serviceName);
      const UC = UC_SERVICE_NAMES.includes(serviceName);
      if (UC || ER) {
        const dateToCompare = moment(
          moment(today)
            .tz(timezoneName || healthSystem['time-zone'])
            .add(1, 'days')
            .toString(),
        ).tz(timezoneName || healthSystem['time-zone']);
        const show = moment(dates[0])
          .tz(timezoneName || healthSystem['time-zone'])
          .isSameOrAfter(dateToCompare, 'day');
        setDisableRight(show);
      } else if (limit) {
        const dateToCompare = moment(
          moment(today)
            .tz(timezoneName || healthSystem['time-zone'])
            .add(Number(limit) - 1, 'days')
            .toString(),
        ).tz(timezoneName || healthSystem['time-zone']);
        const show = moment(dates[dates.length - 1])
          .tz(timezoneName || healthSystem['time-zone'])
          .isSameOrAfter(dateToCompare, 'day');
        setDisableRight(show);
      }
    }
  });

  const getContainerWidth = () => {
    if (isMobile) return 4;
    if (isSearchPage()) return 12;
    return 10;
  };

  return (
    <Grid container className={componentClass}>
      <Grid item xs={isMobile ? 3 : 1} className={controlClass}>
        {!disabled ? (
          <ChevronLeftIcon
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              disabled
                ? () => { }
                : updateDate(
                  moment(date.clone())
                    .tz(timezoneName)
                    .add(`-${dates.length}`, 'days')
                    .format(),
                );
            }}
            fontSize="large"
          />
        ) : null}
      </Grid>
      <Grid
        item
        container
        xs={getContainerWidth()}
        className={isMobile ? 'dates datesMobile' : 'dates'}
        ref={dateSliderRef}
        onScroll={() => {
          onScroll(dateSliderRef, 'DateCarousel');
        }}
      >
        {dates.map((day, index) => {
          const creationDate = moment(day)
            ?.creationData()
            ?.input?.slice(0, 10);

          return (
            <Grid
              item
              key={day.tz(timezoneName || healthSystem['time-zone']).format()}
            >
              <DateHeading
                date={moment
                  .utc(creationDate)
                  .add(index, 'days')
                  .format('YYYY-MM-DD')}
                layout={dateHeadingLayout}
                timezoneName={timezoneName || healthSystem['time-zone']}
              />
            </Grid>
          );
        })}
      </Grid>{' '}
      {disableRight ? (
        <Grid item xs={3} className="control">
          <></>
        </Grid>
      ) : (
        <Grid item xs={isMobile ? 3 : 1} className="control">
          <ChevronRightIcon
            onClick={() =>
              updateDate(
                moment(date.clone())
                  .tz(timezoneName)
                  .add(`${dates.length}`, 'days')
                  .format(),
              )
            }
            fontSize="large"
          />
        </Grid>
      )}
    </Grid>
  );
};

DateCarousel.defaultProps = {
  dates: [],
  dateHeadingLayout: 'vertical',
  onDateChange: () => { },
  serviceName: '',
  scrollLeft: {},
  onScroll: () => { },
  scheduleId: '',
};

DateCarousel.propTypes = {
  /** eg. 'America/Toronto'. Required for DateHeading component render. */
  timezoneName: PropTypes.string.isRequired,
  /** How many dates render in the carousel at once. */
  dates: PropTypes.instanceOf(Array),
  /** Date formatting: 'vertical' or 'horizontal' */
  dateHeadingLayout: PropTypes.string,
  /** Optional data hoisting so other components can interact with this one. */
  onDateChange: PropTypes.func,
  serviceName: PropTypes.string,
  scrollLeft: PropTypes.instanceOf(Object),
  onScroll: PropTypes.func,
  scheduleId: PropTypes.func,
};

export default DateCarousel;
