import React, { useContext } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { FormHelperText, Grid } from '@material-ui/core';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomTextField from '../CustomTextField';

const ContactNameFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const hasError = formContext.hasError('caregiver-name');
  const [t] = useTranslation();
  const patientIsChild = formContext.form.birthdate.value
    ? moment().diff(
        moment(formContext.form.birthdate.value, 'DD-MM-YYYY'),
        'years',
      ) < 18
    : false;

  return (
    <>
      {formContext.isFieldEnabled('caregiver-name') && (
        <div className="form-section_row">
          <Grid container>
            <Grid item sm={4} xs={12}>
              <CustomTextField
                fieldKey="contactName"
                fieldValue={formContext.form.contactName}
                validateFormHandler={formContext.handleSetFormState}
                label={t('ContactNameFields.contactName')}
                required={
                  formContext.isFieldRequired('caregiver-name') ||
                  patientIsChild
                }
                shrink
                hasError={hasError}
                errorMessage={formContext.getError('caregiver-name')}
              />

              {!hasError && (
                <FormHelperText style={{ color: 'rgba(0,0,0,0.75)' }}>
                  {t('ContactNameFields.ifDifferentFromPatient')}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default ContactNameFields;
