import React from 'react';
import PropTypes from 'prop-types';
import { getSignedUrl } from 'utils/urlSigner';

const Map = ({ location }) => {
  if (!location || !location.latitude || !location.longitude) return <></>;
  const markers = `${location.latitude},${location.longitude}`;
  const src = getSignedUrl(markers);
  return (
    <div className="map" aria-hidden="true">
      <img alt="map" src={src} />
    </div>
  );
};

Map.propTypes = {
  location: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }).isRequired,
};

export default Map;
