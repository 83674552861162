import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { Dialog, Grid, withStyles, makeStyles } from '@material-ui/core';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { BrandingContext } from 'components/contexts/BrandingContext';
import BrandingButton from 'components/common/BrandingButton';
import PickerDate from './DatePicker';
import TimePicker from './TimePicker';
import './SchedulePicker.scss';

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
    '&:first-child': {
      padding: 0,
    },
  },
}))(MuiDialogContent);

const SchedulePicker = ({
  openDialog,
  closeDialog,
  showTimeSliderOnly,
  timezone,
  timestamp,
  scheduleId,
}) => {
  const { branding } = useContext(BrandingContext);
  const [tab, setTab] = useState(1);
  const isActive = num => (tab === num ? 'active' : '');
  const [dateData, setDateData] = useState({});
  const [t] = useTranslation();
  const setCloseDialog = submitDate => {
    if (submitDate) {
      return closeDialog(dateData);
    }

    setDateData({});
    return closeDialog();
  };

  const useStyles = makeStyles({
    tabButton: {
      borderRight: `1px solid ${branding.primaryColor}`,
      borderBottom: `1px solid ${branding.primaryColor}`,
      color: branding.primaryColor,
      '&:hover, &:focus, &:not(.disabled):not(.disabled):active, &.active': {
        backgroundColor: branding.primaryColor,
        color: branding.primaryColor ? '#fff' : '',
      },
    },
  });

  const classes = useStyles();

  useEffect(() => {
    if (showTimeSliderOnly) {
      setTab(3);
    }
  });

  return (
    <Dialog open={openDialog} onClose={() => setCloseDialog(false)}>
      <DialogContent>
        <div className="SchedulePicker">
          <Grid container>
            <Grid item sm={4} style={{ backgroundColor: '#f1f1f1' }}>
              {!showTimeSliderOnly && (
                <>
                  <button
                    className={`tab-button ${isActive(1)} ${classes.tabButton}`}
                    type="button"
                    onClick={() => setTab(1)}
                  >
                    {t('SchedulePicker.date')}
                  </button>
                </>
              )}
              {/* <button
                className={`tab-button ${isActive(3)} ${classes.tabButton}`}
                type="button"
                onClick={() => setTab(3)}
              >
                Time Range
              </button> */}
            </Grid>

            <Grid item sm={8} className="schedule-content">
              {/* {isActive(1) && <ShowTodayPicker />} */}
              {isActive(1) && (
                <PickerDate
                  handleChange={e => {
                    const startDate = moment(e?.start);
                    setDateData({
                      text: moment(e.start)
                        .tz(timezone)
                        .format(),
                      value: startDate.isValid()
                        ? e
                        : moment()
                            .tz(timezone)
                            .format(),
                    });
                  }}
                  timezone={timezone}
                  timestamp={timestamp}
                  scheduleId={scheduleId}
                />
              )}
              {isActive(3) && (
                <TimePicker
                  handleChange={e => {
                    setDateData({
                      text: `${e.text}`,
                      value: e,
                    });
                  }}
                />
              )}

              <div className="actions">
                <BrandingButton
                  type="button"
                  onClick={() => setCloseDialog(true)}
                >
                  {t('SchedulePicker.ok.button')}
                </BrandingButton>
                <BrandingButton
                  type="button"
                  onClick={() => setCloseDialog(false)}
                >
                  {t('SchedulePicker.cancelbutton')}
                </BrandingButton>
              </div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};
SchedulePicker.defaultProps = {
  showTimeSliderOnly: false,
  timezone: '',
  timestamp: '',
  scheduleId: '',
};

SchedulePicker.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  showTimeSliderOnly: PropTypes.bool,
  timezone: PropTypes.string,
  timestamp: PropTypes.string,
  scheduleId: PropTypes.string,
};

export default SchedulePicker;
