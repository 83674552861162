import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { TimeSlider } from 'components/form/FormElements';
import { convertTime } from 'utils/dateTimeUtils';

const TimePicker = ({ handleChange }) => {
  const [sliderValue, setSliderValue] = useState([0, 1439]);

  useEffect(() => {
    const today = moment().format('YYYY-MM-DD');
    handleChange({
      text: `${convertTime(sliderValue[0])}-${convertTime(sliderValue[1])}`,
      end: moment(`${today} ${convertTime(sliderValue[1])}`).format('HH:mm'),
      start: moment(`${today} ${convertTime(sliderValue[0])}`).format('HH:mm'),
    });
  }, [sliderValue]);

  return (
    <div className="time-picker">
      <p>Time</p>
      <TimeSlider
        defaultValue={sliderValue}
        getAriaLabel={() => {
          'Select time range';
        }}
        min={0}
        max={1439}
        step={15}
        value={sliderValue}
        onChange={(e, v) => setSliderValue(v)}
      />
      <p>
        {convertTime(sliderValue[0])} to {convertTime(sliderValue[1])}
      </p>
    </div>
  );
};

TimePicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
};

export default TimePicker;
