import { getHealthSystem } from 'api/apiUtils';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';

let defaultNs = 'default';
const { hostname } = window.location;
const hs = getHealthSystem();
let loadNs = ['default', 'shared', 'languages', 'CustomDefaultMessage'];
if (hs !== undefined) {
  loadNs.push(hs);
}
if (hostname.split('.').length > 1) {
  // eslint-disable-next-line prefer-destructuring
  defaultNs = hostname.split('.')[0];
  loadNs = loadNs.concat(hostname.split('.')[0]);
}

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
  referenceLng: 'en-US',
};

const options = {
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  debug: false,
  backend: locizeOptions,
  locizeLastUsed: locizeOptions,
  fallbackLng: {
    fr: ['fr-CA', 'en-US'],
    es: ['es-US', 'en-US'],
    en: ['en-US'],
    vi: ['vi-VN', 'en-US'],
    default: ['en-US'],
  },
  fallbackNS: [hs, 'default'],
  ns: loadNs,
  defaultNS: defaultNs,
  preload: ['en-US'],
  nonExplicitSupportedLngs: true,
  load: 'currentOnly',
  partialBundledLanguages: true,
  react: {
    wait: true,
    reactSuspense: true,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
    useSuspense: false,
    transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'p'], // don't convert to <1></1> if simple react elements
  },
};

i18n
  .use(LanguageDetector)
  .use(Backend)
  .init(options);

export default i18n;
