import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import './VirtualAppointment.scss';

/**
 * This component renders virtual appointment information.
 */
const VirtualAppointment = ({ joinUrl, testUrl }) => {
  return (
    <div className="VirtualAppointment">
      <strong>Join Meeting</strong>
      <p>
        <a
          href={joinUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="link-default"
        >
          {joinUrl}
        </a>
      </p>

      {!isEmpty(testUrl) && (
        <p>
          Please ensure to test your setup and use the following link to test
          connections prior to the appointment time.{' '}
          <a
            href={testUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="link-default"
          >
            {testUrl}
          </a>
        </p>
      )}
    </div>
  );
};

VirtualAppointment.propTypes = {
  joinUrl: PropTypes.string.isRequired,
  testUrl: PropTypes.string.isRequired,
};

export default VirtualAppointment;
