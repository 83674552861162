import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomTextField from '../CustomTextField';

const InsuranceGroupMemberFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const [t] = useTranslation();

  return (
    <>
      {formContext.isFieldEnabled('insurance-group-number') && (
        <div className="form-section_row">
          <Grid container>
            <Grid item sm={4} xs={12}>
              <CustomTextField
                fieldKey="insuranceGroupNumber"
                fieldValue={formContext.form.insuranceGroupNumber}
                validateFormHandler={formContext.handleSetFormState}
                label={t('InsuranceGroupMemberFields.insuranceGroupNumber')}
                required={formContext.isFieldRequired('insurance-group-number')}
                shrink
                hasError={formContext.hasError('insurance-group-number')}
                errorMessage={formContext.getError('insurance-group-number')}
              />
            </Grid>
          </Grid>
        </div>
      )}

      {formContext.isFieldEnabled('insurance-member-number') && (
        <div className="form-section_row">
          <Grid container>
            <Grid item sm={4} xs={12}>
              <CustomTextField
                fieldKey="insuranceMemberNumber"
                fieldValue={formContext.form.insuranceMemberNumber}
                validateFormHandler={formContext.handleSetFormState}
                label={t('InsuranceGroupMemberFields.insuranceMemberNumber')}
                required={formContext.isFieldRequired(
                  'insurance-member-number',
                )}
                shrink
                hasError={formContext.hasError('insurance-member-number')}
                errorMessage={formContext.getError('insurance-member-number')}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default InsuranceGroupMemberFields;
