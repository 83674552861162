/**
 * @param {Object} location Object with keys:
 * - name
 * - address
 * - city
 * - state
 * - zip
 * @return String
 */
const formatMapUrl = location => {
  return `https://www.google.com/maps/search/?api=1&query=${location.name} ${location.address} ${location.city} ${location.state} ${location.zip}`;
};

export default formatMapUrl;
