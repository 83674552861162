import { isEmpty } from 'lodash';

/**
 *
 * @param {Array} appointmentTypes The array of appt type options.
 * @param {Number|String} queryApptTypeId Optional, appt type id from URL.
 * @return {Number|null} The id to use as default.
 */
export const getDefaultApptType = (appointmentTypes, queryApptTypeId) => {
  // if 0 appt types, leave blank
  if (isEmpty(appointmentTypes)) return null;

  // if they exist, and query string defines one
  // and it exists in set, use that one
  if (
    queryApptTypeId &&
    appointmentTypes.find(apptType => {
      return apptType.id === Number(queryApptTypeId);
    })
  ) {
    return Number(queryApptTypeId);
  }
  // Otherwise, default to highest ranked appt type.
  // These get returned by API in ranked order, so pick first one.
  return appointmentTypes[0].id;
};
