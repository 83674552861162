import React, { useContext } from "react";
import { Stepper, Step, StepButton, Box, Grid } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';
import { PatientIntakeContext } from "../contexts/PatientIntakeContext";
import { ReactComponent as ValidationImage } from 'assets/images/icons/unlock.svg';
import { ReactComponent as ValidationActiveImage } from 'assets/images/icons/unlock_active.svg';
import { ReactComponent as PaymentImage } from 'assets/images/icons/card.svg';
import { ReactComponent as PaymentActiveImage } from 'assets/images/icons/card_active.svg';
import { ReactComponent as InsuranceImage } from 'assets/images/icons/insurance.svg';
import { ReactComponent as InsuranceActiveImage } from 'assets/images/icons/shield_active.svg';
import { ReactComponent as CircleImage } from 'assets/images/icons/check-circle.svg';
import { ReactComponent as DocsImage } from 'assets/images/icons/user.svg';
import { ReactComponent as DocsActiveImage } from 'assets/images/icons/user-rectangle.svg';
import { ReactComponent as AllergiesImage } from "assets/images/icons/allergies.svg";
import { ReactComponent as AllergiesAcitveImage } from "assets/images/icons/allergies_active.svg";
import { ReactComponent as ConsentImage } from "assets/images/icons/e-Sign.svg";
import { ReactComponent as ConsentAciveImage } from "assets/images/icons/e-Sign_active.svg";
import { ReactComponent as VaccinationImage } from "assets/images/icons/vaccination_inactive_icon.svg";
import { ReactComponent as VaccinationActiveImage } from "assets/images/icons/vaccination_active_icon.svg";
import { ReactComponent as SocialImage } from "assets/images/icons/social_history.svg";
import { ReactComponent as SocialAcitveImage } from "assets/images/icons/social_history_acive.svg";
import { ReactComponent as FamilyImage } from "assets/images/icons/family_health_history.svg";
import { ReactComponent as FamilyActiveImage } from "assets/images/icons/family_health_history_active.svg";
import { ReactComponent as MedicalImage } from "assets/images/icons/medication.svg";
import { ReactComponent as MedicalAciveImage } from "assets/images/icons/medication_active.svg";
import { ReactComponent as PersonalHistoryImage } from "assets/images/icons/personal_medical_history.svg";
import { ReactComponent as PersonalHistoryAciveImage } from "assets/images/icons/personal_medical_history_active.svg";
import "./PatientIntakePage.scss";

const stepIcons = [
    { title: 'validation', icon: <ValidationImage />, activeIcon: <ValidationActiveImage /> },
    { title: 'insurance', icon: <InsuranceImage />, activeIcon: <InsuranceActiveImage /> },
    { title: 'payment', icon: <PaymentImage />, activeIcon: <PaymentActiveImage /> },
    { title: 'drivers-license', icon: <DocsImage />, activeIcon: <DocsActiveImage /> },
    { title: 'other', icon: <DocsImage />, activeIcon: <DocsActiveImage /> },
    { title: 'lab', icon: <DocsImage />, activeIcon: <DocsActiveImage /> },
    { title: 'consent', icon: <ConsentImage />, activeIcon: <ConsentAciveImage /> },
    { title: 'vaccinations', icon: <VaccinationImage />, activeIcon: <VaccinationActiveImage /> },
    { title: 'social-history', icon: <SocialImage />, activeIcon: <SocialAcitveImage /> },
    { title: 'personal-and-medical-history', icon: <PersonalHistoryImage />, activeIcon: <PersonalHistoryAciveImage /> },
    { title: 'medication', icon: <MedicalImage />, activeIcon: <MedicalAciveImage /> },
    { title: 'allergies', icon: <AllergiesImage />, activeIcon: <AllergiesAcitveImage /> },
    { title: 'family-health-history', icon: <FamilyImage />, activeIcon: <FamilyActiveImage /> },
];

const stepStyle = {
    "& .MuiStepConnector-root": {
        top: "20px",
    },
    "& .MuiStepConnector-line": {
        borderTopStyle: "dotted"
    }
}

const StepperComponent = () => {

    const intake = useContext(PatientIntakeContext);

    const useStyles = makeStyles(() => ({
        iconBorder: {
            position: 'relative',
            border: "1px solid #c3c3c3",
            padding: "8px",
            borderRadius: "8px"
        },
        completeIconStyle: {
            position: 'absolute',
            top: 0,
            left: 35
        },
        activeStyle:{
            pointerEvents: ""
        },
        inActiveStyle:{
            pointerEvents: "none"
        }
    }));
    const classes = useStyles();

    const StepIcon = ({ state, index, name }) => (
        <div className={classes.iconBorder}>
            {state === "none" && stepIcons.find(i => i.title === name)?.icon}
            {(state === "active" || state === "completed") && stepIcons.find(i => i.title === name)?.activeIcon}
            {state === "completed" ? <div className={classes.completeIconStyle}>{<CircleImage />}</div> :   null}
        </div>
    );

    const handleClick = (isCompleted, index) => {
        if(isCompleted) intake.setPageNum(index)
    }

    return (
        <Box
            sx={{
                width: '100%',       
                overflowX: 'auto',  
                overflowY: 'hidden',  
                whiteSpace: 'nowrap', 
                paddingBottom: 1, 
                margin: 0,
                display: 'flex',
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Stepper activeStep={intake?.page} alternativeLabel sx={stepStyle} nonLinear>
                        {intake?.requiredSteps?.map((label, index) => (
                            <Step key={label?.documentTypeName}  >
                                <StepButton onClick={() => handleClick(label?.isCompleted, index)} className={ label?.isCompleted ? classes.activeStyle : classes.inActiveStyle}
                                    icon={<StepIcon index={index} name={label?.documentTypeCode} state={label?.isCompleted ? "completed" : index === intake?.page ? "active" :  "none"} />}
                                />
                            </Step>
                        ))}
                    </Stepper>
                </Grid>
            </Grid>
        </Box>
    )
}

export default StepperComponent;