import React, { useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import camel from 'camelcase-keys';
import QueryString from 'query-string';
import { useTheme } from '@material-ui/core/styles';
import { Badge, Grid, useMediaQuery } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';
import { Link, useLocation } from 'react-router-dom';
import Location from 'components/common/Location';
import Phone from 'components/common/Phone';
import AvailableTimesToday from 'components/appointmentPicker/AvailableTimesToday';
import FullSchedule from 'components/common/FullSchedule';
import { BrandingContext } from 'components/contexts/BrandingContext';
import emergencyRoomImage from 'assets/images/vocImages/default_emergency_room_md.jpeg';
import urgentCareImage from 'assets/images/vocImages/default_urgent_care_md.jpeg';
import './Result.scss';
import FacilityDateCarousel from 'components/appointmentPicker/FacilityDateCarousel';
import { ER_SERVICE_NAMES, UC_SERVICE_NAMES } from 'config/searchConstants';
import { useQueryString } from 'hooks/useQueryString';
import { serviceLineIcons } from '../../config/serviceLineIcons';
import { setProviderAvatar } from '../../helpers/selectProviderAvatar';
import { useSelector } from 'react-redux';
const ScheduleResult = ({
  location,
  schedule,
  appointmentTypeId,
  provider,
  patientNowTimes,
  venueType,
  uxMode,
  datesLoading,
  datesShow,
  dateCurrent,
  noOfDays,
  startTime,
  endTime,
  scrollLeft,
  onScroll,
  setWhoIsScrolling,
  whoIsScrolling,
  venueId,
  inactive,
  type,
  facility,
}) => {
  const { branding } = useContext(BrandingContext);
  const { search } = useLocation();
  let iServiceLineIcon;
  const { pathname, isDischargePage, urlParams } = useQueryString();
  const params = QueryString.parse(search);
  const timezoneName = location ? location['time-zone'] : '';
  // eslint-disable-next-line no-nested-ternary
  const context = isDischargePage
    ? 'discharge'
    : patientNowTimes
    ? 'patient_now'
    : 'patient';
  const isSearchPage = () => pathname === '/search' || pathname === '/';
  const theme = useTheme();
  const [hideFullSchedule, setHideFullSchedule] = useState(false);
  const healthSystem = useSelector(state => state.healthSystem);
  //  If we're on the "More AvailableTimes" view and location / provider only
  //  has one associated appointment type,
  //  always find times for that.  Otherwise, find times for the selected
  //  appointment type in the search.
  const getAppointmentTypeId = useCallback(() => {
    let appTypeId;
    const scheduleAppointmentTypes = schedule.appointmentTypes;
    if (patientNowTimes && scheduleAppointmentTypes.length === 1) {
      appTypeId = scheduleAppointmentTypes[0].id;
      return appTypeId.toString();
    }

    if (uxMode === 'default' && scheduleAppointmentTypes.length > 0) {
      let appTypeId = scheduleAppointmentTypes[0].id;

      if (params.appointmentTypes && params.appointmentTypes.trim().length) {
        appTypeId =
          scheduleAppointmentTypes.find(
            type => type.id === +params.appointmentTypes,
          )?.id ?? scheduleAppointmentTypes[0].id;
      }

      return appTypeId.toString();
    }

    appTypeId = appointmentTypeId;

    if (appTypeId === null || appTypeId.trim() === '') {
      appTypeId = params.appointmentTypeId ? params.appointmentTypeId : '';
    }

    return appTypeId.toString();
  }, [params, appointmentTypeId]);

  let serviceLink = '';

  if (params.service) {
    serviceLink = `&service=${params.service}`;
  }
  let serviceNameLink = '';
  serviceNameLink =
    !isNaN(params.service) === true
      ? schedule?.service?.permalink
      : params.service;
  let providerLink = '';
  if (!isEmpty(provider) && !isEmpty(provider.attributes)) {
    providerLink = `/providers/${
      provider.id
    }?appointmentTypes=${getAppointmentTypeId()}${serviceLink}&type=${type}&serviceName=${serviceNameLink}&nowContext=${patientNowTimes}`;
  }
  let regLink = getAppointmentTypeId()
    ? `/schedule/${
        schedule.id
      }?appointmentTypes=${getAppointmentTypeId()}&inactive=${!schedule.active}&type=${type}&service=${
        params.service
      }`
    : `/schedule/${
        schedule.id
      }?inactive=${!schedule.active}&type=${type}&service=${params.service}`;

  if (
    uxMode === 'educational' &&
    !(
      Object.keys(urlParams).length > 0 &&
      (Object.keys(urlParams).includes('facilityName') ||
        Object.keys(urlParams).includes('dischargeFacility'))
    )
  ) {
    localStorage.setItem('voc', venueType.split('_').join('-'));
  }
  if (isDischargePage) {
    providerLink = `/${urlParams.dischargeFacility}/discharge${providerLink}`;
    regLink = `/${urlParams.dischargeFacility}/discharge${regLink}`;
  }

  const imageForCard = () => {
    if (provider && provider.attributes) {
      if (provider.attributes['ui-profile-image'] !== null) {
        return provider.attributes['ui-profile-image'];
      } else if (location['logo'] !== null) {
        return location['logo'];
      } else {
        return serviceLineIcons.find(i => i.permalink === params.service).icon;
      }
    }
    switch (venueType) {
      case 'urgent_care':
        return urgentCareImage;
      case 'emergency_room':
        return emergencyRoomImage;
      default:
        return null;
    }
  };
  if (isEmpty(location)) return <></>;
  return (
    <Grid
      container
      className="result"
      style={{ borderTop: `1px solid ${branding.primaryColor}` }}
      key={`schedule-${schedule.id}`}
    >
      <Grid item xs={3} sm={2}>
        {setProviderAvatar(
          provider,
          location,
          !isNaN(params.service) === true
            ? schedule?.service?.permalink
            : params.service,
          healthSystem,
          schedule?.service && schedule?.service?.name
            ? schedule?.service?.name
            : '',
          facility,
        )}
      </Grid>
      <Grid
        item
        xs={9}
        sm={isSearchPage() ? (isMobile ? 4 : 3) : 4}
        className="providerhead"
      >
        {!isEmpty(provider) &&
          !isEmpty(provider.attributes) &&
          provider.attributes['provider-type'] !== 'HealthResource' && (
            <>
              <Link className="provider-name" to={providerLink}>
                {provider.attributes.name}
                {provider.attributes.suffix &&
                  `, ${provider.attributes.suffix}`}
              </Link>
              {schedule.service && schedule.service.name && (
                <p className="service-name">{schedule.service.name}</p>
              )}
            </>
          )}
        {!isEmpty(provider) &&
          !isEmpty(provider.attributes) &&
          provider.attributes['provider-type'] === 'HealthResource' && (
            <>
              <Badge className="provider-name">
                {provider.attributes.name}
                {provider.attributes.suffix &&
                  `, ${provider.attributes.suffix}`}
              </Badge>
              {schedule.service && schedule.service.name && (
                <p className="service-name">{schedule.service.name}</p>
              )}
            </>
          )}
        <Location location={{ ...location, ...camel(location) }} />
        <Phone phoneNumber={location.phone} />
      </Grid>
      <Grid item xs={12} sm={isSearchPage() === true ? (isMobile ? 6 : 7) : 6}>
        {params.service === 'urgent-care' ||
        params.service === 'emergency-room' ||
        params.service === 'emergency-department' ||
        params.service === 'immediate-care' ||
        venueType === 'urgent_care' ||
        venueType === 'emergency_room' ||
        ER_SERVICE_NAMES.includes(schedule.service.name) ||
        UC_SERVICE_NAMES.includes(schedule.service.name) ||
        isMobile ? (
          <>
            <AvailableTimesToday
              appointmentTypeId={getAppointmentTypeId()}
              context={context}
              link={regLink}
              scheduleId={schedule.id}
              facility={schedule?.facility?.id}
              timezoneName={timezoneName}
              providerName={
                !isEmpty(provider) ? provider.attributes.name : location.name
              }
              startTimestamp={dateCurrent}
              serviceName={schedule.service.name}
              isMobile={isMobile}
              setHideFullSchedule={setHideFullSchedule}
              venueType={venueType}
              servicePermalink={
                !isNaN(params.service) === true
                  ? schedule?.service?.permalink
                  : params.service
              }
              regionId={facility?.['facility-region']?.id}
            />
            {!hideFullSchedule && (
              <FullSchedule
                schedule={schedule.id}
                appointmentType={getAppointmentTypeId()}
                inactive={!schedule.active}
                type={type}
                servicePermalink={
                  !isNaN(params.service) === true
                    ? schedule?.service?.permalink
                    : params.service
                }
                nowContext={patientNowTimes}
              />
            )}
          </>
        ) : (
          <>
            <FacilityDateCarousel
              scheduleId={schedule.id}
              facility={schedule?.facility?.id}
              appointmentTypeId={getAppointmentTypeId()}
              link={regLink}
              startTime={startTime}
              endTime={endTime}
              maxTimesPerDay={4}
              timezoneName={timezoneName}
              datesShow={datesShow}
              datesLoading={datesLoading}
              dateCurrent={dateCurrent}
              noOfDays={noOfDays}
              uxMode={uxMode}
              scrollLeft={scrollLeft}
              onScroll={onScroll}
              setWhoIsScrolling={setWhoIsScrolling}
              whoIsScrolling={whoIsScrolling}
              servicePermalink={
                !Number.isNaN(params.service) === true
                  ? schedule?.service?.permalink
                  : params.service
              }
              nowContext={patientNowTimes}
              regionId={facility?.['facility-region']?.id}
            />
            <Grid container>
              <Grid item xs={1} sm={1} />
              <Grid item xs={10} sm={11} className="full_schedule">
                <FullSchedule
                  schedule={schedule.id}
                  appointmentType={getAppointmentTypeId()}
                  inactive={!schedule.active}
                  type={type}
                  servicePermalink={
                    !isNaN(params.service) === true
                      ? schedule?.service?.permalink
                      : params.service
                  }
                  nowContext={patientNowTimes}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

ScheduleResult.defaultProps = {
  provider: {},
  patientNowTimes: false,
  appointmentTypeId: null,
  datesLoading: true,
  datesShow: [],
  dateCurrent: '',
  startTime: '',
  endTime: '',
  noOfDays: 1,
  scrollLeft: {},
  onScroll: () => {},
  setWhoIsScrolling: () => {},
  whoIsScrolling: '',
};

ScheduleResult.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  schedule: PropTypes.instanceOf(Object).isRequired,
  appointmentTypeId: PropTypes.string,
  provider: PropTypes.instanceOf(Object),
  patientNowTimes: PropTypes.bool,
  selectedParams: PropTypes.instanceOf(Object).isRequired,
  venueType: PropTypes.string.isRequired,
  uxMode: PropTypes.string.isRequired,
  datesShow: PropTypes.instanceOf(Array),
  datesLoading: PropTypes.bool,
  dateCurrent: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  noOfDays: PropTypes.number,
  scrollLeft: PropTypes.instanceOf(Object),
  onScroll: PropTypes.func,
  setWhoIsScrolling: PropTypes.func,
  whoIsScrolling: PropTypes.string,
  inactive: PropTypes.bool,
  type: PropTypes.string,
  facility: PropTypes.instanceOf(Object),
};

export default ScheduleResult;
