import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getAllFacilities } from 'redux/actions/facilitiesActions';
import { BrandingContext } from '../contexts/BrandingContext';
import BreadCrumbs from 'components/common/BreadCrumbs';
import './Sitemap.scss';

const SitemapFacilities = () => {
  const { branding } = useContext(BrandingContext);
  const [facilities, setFacilties] = useState(null);

  useEffect(() => {
    getAllFacilities().then(setFacilties);
  }, []);
  const facilityMap = [];
  if (facilities !== null) {
    facilities.forEach(element => {
      facilityMap.push({
        name: element.attributes.name,
        link: `/facility/${element.attributes.permalink}`,
      });
    });
  }

  facilityMap.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  return (
    <div className="Sitemap">
      <div className="container">
        <BreadCrumbs />
        {facilityMap.map(item => (
          <ul>
            <li>
              <Link to={item.link}>{item.name}</Link>
            </li>
          </ul>
        ))}
      </div>
    </div>
  );
};

export default SitemapFacilities;
