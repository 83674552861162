import { find, uniq, uniqBy } from 'lodash';
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function providersReducer(
  state = initialState.providers,
  action,
) {
  switch (action.type) {
    case types.BEGIN_SEARCH:
      return { ...state, searched: true, searchType: action.searchType };
    case types.FETCH_PROVIDERS_SUCCESS:
      return {
        ...state,
        data: uniqBy(
          [...state.data, ...Object.values(action.providers || [])],
          p => p.id,
        ),
        locations: uniqBy(
          [...state.locations, ...Object.values(action.locations || [])],
          p => p.id,
        ),
        schedules: uniqBy(
          [...state.schedules, ...Object.values(action.schedules || [])],
          p => p.id,
        ),
        included: action.included,
        meta: {
          response: action.meta.responseMeta,
          pagesLoaded: uniq([...state.meta.pagesLoaded, action.meta.page]),
        },
        junk: action.providers,
      };
    case types.BEGIN_FETCH_PROVIDER:
      return {
        ...state,
        meta: { ...state.meta, providerIsLoading: true },
      };
    case types.PROVIDER_NOT_FOUND:
      return {
        ...state,
        data: [{}],
        locations: [{}],
        schedules: [{}],
        meta: {
          ...state.meta,
          providerIsLoading: false,
          providerNotFound: true,
        },
      };
    case types.FETCH_PROVIDER_SUCCESS:
      return {
        ...state,
        data: uniqBy(
          [...state.data, ...Object.values(action.provider.providers)],
          p => p.id,
        ),
        locations: uniqBy(
          [
            ...state.locations,
            ...Object.values(action.provider.locations || []),
          ],
          p => p.id,
        ),
        schedules: uniqBy(
          [
            ...state.schedules,
            ...Object.values(action.provider.schedules || []),
          ],
          p => p.id,
        ),
        facilities: {
          ...state.facilities,
          ...Object.values(action.provider.facilities || []),
        },
        meta: {
          ...state.meta,
          providerIsLoading: false,
        },
      };
    case types.FETCH_SEARCH_PROVIDERS_SUCCESS:
      return {
        ...state,
        data: uniqBy(
          [...state.data, ...Object.values(action.providers.providers || [])],
          p => p.id,
        ),
        locations: uniqBy(
          [
            ...state.locations,
            ...Object.values(action.providers.locations || []),
          ],
          p => p.id,
        ),
        schedules: uniqBy(
          [
            ...state.schedules,
            ...Object.values(action.providers.schedules || []),
          ],
          p => p.id,
        ),
        meta: {
          response: action.meta.responseMeta,
          pagesLoaded: uniq([...state.meta.pagesLoaded, action.meta.page]),
        },
        junk: action.providers,
      };
    case types.BACK_TO_LANDING_PAGE:
      return { ...state, searched: false };
    default:
      return state;
  }
}

// Selectors
export const getProviderById = (state, id, permalink) => {
  return find(
    state.providers.data,
    p =>
      (id && p.id === id) ||
      (permalink && p.attributes && p.attributes.permalink === permalink),
  );
};

export const getLocationById = (state, id) => {
  try {
    const location = find(state.providers.locations, l => l.id === id);
    return location;
  } catch (e) {
    return {};
  }
};

export const getProviderLocationsById = (state, id, permalink) => {
  try {
    const provider = getProviderById(state, id, permalink);
    const scheduleIds = provider.relationships.schedules.data.map(s => s.id);
    // Combine all 3 filter conditionals into one for locationIds
    const locationIds = state.providers.schedules
      .filter(
        s =>
          !s.attributes.forceDeactivation &&
          (s.attributes.remoteSchedule || s.attributes.active) &&
          scheduleIds.includes(s.id),
      )
      .map(l => l.relationships.location.data.id);
    const locations = state.providers.locations.filter(l =>
      locationIds.includes(l.id),
    );
    return locations;
  } catch (e) {
    return [];
  }
};

export const getProviderSchedulesById = (state, id, permalink) => {
  try {
    const provider = getProviderById(state, id, permalink);
    const scheduleIds = provider.relationships.schedules.data.map(s => s.id);
    const schedules = state.providers.schedules.filter(
      s => scheduleIds.includes(s.id) && s.attributes.active === true,
    );
    return schedules;
  } catch (e) {
    return [];
  }
};

export const getScheduleById = (state, id) => {
  try {
    return find(state.providers.schedules, p => p.id === id);
  } catch (e) {
    return {};
  }
};

export const getLocationByScheduleId = (state, scheduleId) => {
  try {
    const schedule = getScheduleById(state, scheduleId);
    const location = find(
      state.providers.locations,
      l => l.id === schedule.relationships.location.data.id,
    );
    return location;
  } catch (e) {
    return {};
  }
};
