/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import QueryString from 'query-string';
import { Link } from 'react-router-dom';
import Appointment from 'components/appointmentPicker/Appointment';
import { getTimes } from 'api/availableTimesApi';
import { useQueryString } from 'hooks/useQueryString';
import useSettings from 'hooks/useSettings';
import { isServiceGroupB_C } from 'config/serviceLineGroupMappings';
import {
  ER_SERVICE_NAMES,
  UC_SERVICE_NAMES,
} from '../../config/searchConstants';
import './AvailableTimesToday.scss';
import './DateCarouselContainer.scss';
import { startOfDayReset } from 'utils/dateTimeUtils';
import { isObject } from 'lodash';

/**
 * Displays a list of appointments available 'today'
 */
const AvailableTimesToday = ({
  appointmentTypeId,
  context,
  link,
  providerName,
  scheduleId,
  startTimestamp,
  timezoneName,
  serviceName,
  isMobile,
  setHideFullSchedule,
  inactive,
  venueType,
  servicePermalink,
  facility,
  regionId,
}) => {
  const [times, setTimes] = useState([]);
  const [nextAvailableTime, setNextAvailableTime] = useState('');
  const [t] = useTranslation();
  const { params } = useQueryString();
  const [loading, setLoading] = useState(false);
  const availableScheduleDays = useSettings({
    facilityId: facility,
    regionId,
    caller: 'AvailableTimesToday',
  });

  useEffect(() => {
    (async () => {
      setLoading(true);
      setNextAvailableTime('');
      setTimes([]);
      const today = moment()
        .tz(timezoneName)
        .format();
      const toDate = moment(today)
        .tz(timezoneName)
        .add(availableScheduleDays - 1, 'days')
        .format();

      const response = await getTimes(
        scheduleId,
        appointmentTypeId,
        startTimestamp || today,
        1,
        context,
        toDate,
      );
      setHideFullSchedule(!response[0]?.times?.length);
      setTimes(response[0].times);
      const nextTime = response[0]['next-time'];
      if (
        moment(nextTime)
          .tz(timezoneName)
          .isSameOrBefore(toDate)
      )
        setNextAvailableTime(response[0]['next-time']);
      setLoading(!response[0]);
    })();
    return () => {
      setHideFullSchedule(false);
      setTimes([]);
      setNextAvailableTime({});
      setLoading(false);
    };
  }, [scheduleId, appointmentTypeId, startTimestamp, context]);
  const [noAvailableAppointments, setNoAvailableAppointments] = useState();
  useEffect(() => {
    if (t('noAvailableAppointments') !== 'noAvailableAppointments') {
      const key = t('noAvailableAppointments');
      setNoAvailableAppointments(key.replace('90', availableScheduleDays));
    }
  }, [availableScheduleDays, t('noAvailableAppointments')]);

  useEffect(() => {
    if (times?.length && times.length === 0) setHideFullSchedule(true);
  }, [times]);

  useEffect(() => {
    if (isServiceGroupB_C(servicePermalink))
      setNoAvailableAppointments(t('noAvailableAppointmentsGroupBC'));
  }, [servicePermalink]);
  if (!link || link === '') return <></>;

  // Removes duplicates.
  let distinctTimes = [];
  if (
    (venueType === 'urgent_care' ||
      venueType === 'emergency_room' ||
      params.service === 'urgent-care' ||
      params.service === 'emergency-room' ||
      params.service === 'emergency-department' ||
      params.service === 'immediate-care' ||
      ER_SERVICE_NAMES.includes(serviceName) ||
      UC_SERVICE_NAMES.includes(serviceName)) &&
    !isMobile
  )
    distinctTimes = [...new Set(times)].slice(0, 6);
  else distinctTimes = [...new Set(times)].slice(0, 3);

  const labels = [];
  distinctTimes.forEach(time => {
    let format = '';
    if (
      localStorage.getItem('countryCode') === 'GB' ||
      localStorage.getItem('countryCode') === 'UK'
    ) {
      format = 'DD/MM';
    } else {
      format = 'MMM D';
    }
    const timeString = moment(time)
      .tz(timezoneName)
      .format(format);
    const found = labels.find(l => l === timeString);
    if (found) labels.push('');
    if (!found) labels.push(timeString);
  });

  const firstDateFromList = moment(startTimestamp)
    .tz(timezoneName)
    .format();
  const today = moment()
    .tz(timezoneName)
    .format();
  const currentDayComparison = moment(firstDateFromList)
    .tz(timezoneName)
    .isSame(today, 'day');

  const checkNextAvailable = () => {
    if (times.length > 0 || nextAvailableTime) {
      const time = times[0] ? times[0] : nextAvailableTime;
      const firstDateFromTime = moment(time)
        .tz(timezoneName)
        .format();
      const timestamp = isObject(startTimestamp)
        ? startTimestamp.format()
        : startTimestamp;
      const timeWithDayStart = startOfDayReset(time);
      const startDateTimeStampToMaintainTimezone = timestamp
        ? `${timestamp.split('T')?.[0]}T${timeWithDayStart.split('T')?.[1]}`
        : `${today.split('T')?.[0]}T${timeWithDayStart.split('T')?.[1]}`;
      const dateToCompare = moment(startDateTimeStampToMaintainTimezone)
        .tz(timezoneName)
        .startOf('day');
      const dateComparison = moment(firstDateFromTime)
        .tz(timezoneName)
        .isAfter(startDateTimeStampToMaintainTimezone, 'day');
      if (dateComparison) {
        const params = QueryString.parseUrl(link);
        // This allows us to 'carry forward' existing params, if supplied.
        const apptParams = {
          ...params.query,
          ...{
            date: moment(time)
              .tz(timezoneName)
              .format(),
          },
        };
        const apptQueryString = QueryString.stringify({
          ...apptParams,
          date: moment(time)
            .tz(timezoneName)
            .format(),
        });
        const apptLink = `${params.url}?${apptQueryString}`;
        return apptLink;
      }
      return false;
    }
    return false;
  };
  if (loading) {
    return <></>;
  }
  if (
    isMobile &&
    times?.length &&
    distinctTimes?.length &&
    checkNextAvailable()
  ) {
    const apptLink = checkNextAvailable();
    if (apptLink) {
      return (
        <>
          <Link
            to={apptLink}
            className="Appointment Link"
            style={{ textDecoration: 'none' }}
          >
            <button
              type="button"
              className="btn-default-outline appointment-button"
            >
              {t('nextavailabiltyis')}{' '}
              {moment(times[0] ? times[0] : nextAvailableTime)
                .tz(timezoneName)
                .format('ddd, MMM DD [at] hh:mm A')}
            </button>
          </Link>
        </>
      );
    }
  }
  if (times?.length && distinctTimes?.length) {
    return (
      <div className="AvailableTimesToday">
        {!isMobile && (
          <span className="title">
            {t('AvailableTimesToday.availableTimes')}{' '}
            {distinctTimes?.length > 1 &&
              `(${t('AvailableTimesToday.first')} ${distinctTimes.length} ${t(
                'AvailableTimesToday.shown',
              )})`}
          </span>
        )}
        <div className="button-container flex-wrap">
          {distinctTimes?.map((time, index) => {
            let breakLine = false;
            if ((index + 1) % 3 === 0) {
              breakLine = true;
            }
            return (
              <React.Fragment key={time}>
                {labels[index] && <div className="date">{labels[index]}</div>}
                <div className="cell" key={time}>
                  <Appointment
                    key={time}
                    link={link}
                    time={time}
                    timezoneName={timezoneName}
                    providerName={providerName}
                    outline
                    inactive={inactive}
                  />
                </div>
                {breakLine && <div className="cell-separator" />}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }
  if (times.length || nextAvailableTime) {
    const apptLink = checkNextAvailable();
    if (apptLink) {
      return (
        <>
          <Link
            to={apptLink}
            className="Appointment Link"
            style={{ textDecoration: 'none' }}
          >
            <button
              type="button"
              className="btn-default-outline appointment-button"
            >
              {t('nextavailabiltyis')}{' '}
              {moment(times[0] ? times[0] : nextAvailableTime)
                .tz(timezoneName)
                .format('ddd, MMM DD [at] hh:mm A')}
            </button>
          </Link>
        </>
      );
    }
  }
  if (
    !loading &&
    !times.length &&
    (!nextAvailableTime || !currentDayComparison)
  ) {
    return (
      <>
        <div className="noAvailableAppointments">{noAvailableAppointments}</div>
      </>
    );
  }
  return <></>;
};

AvailableTimesToday.defaultProps = {
  appointmentTypeId: null,
  providerName: '',
  startTimestamp: moment(),
  serviceName: '',
  isMobile: false,
  inactive: false,
  facility: {},
  regionId: null,
};

AvailableTimesToday.propTypes = {
  appointmentTypeId: PropTypes.string,
  context: PropTypes.string.isRequired,
  scheduleId: PropTypes.string.isRequired,
  startTimestamp: PropTypes.string,
  /** Link to registration page */
  link: PropTypes.string.isRequired,
  timezoneName: PropTypes.string.isRequired,
  providerName: PropTypes.string,
  serviceName: PropTypes.string,
  isMobile: PropTypes.bool,
  inactive: PropTypes.bool,
  venueType: PropTypes.string.isRequired,
  setHideFullSchedule: PropTypes.func.isRequired,
  servicePermalink: PropTypes.string.isRequired,
  facility: PropTypes.instanceOf(Object),
  regionId: PropTypes.string,
};

export default AvailableTimesToday;
