import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import AddToCalendar from 'react-add-to-calendar';
import camel from 'camelcase-keys';
import Button from 'react-bootstrap/Button';
import Location from '../common/Location';
import Phone from '../common/Phone';
import Map from '../map';
import VirtualAppointment from './VirtualAppointment';
import Provider from './Provider';
import { BrandingContext } from '../contexts/BrandingContext';

const ConfirmationSection = ({
  location,
  number,
  provider,
  timezoneName,
  visit,
}) => {
  const { branding } = useContext(BrandingContext);
  const [t] = useTranslation();

  const useStyles = makeStyles({
    button: {
      color: branding.buttonTextColor,
      borderColor: branding.buttonColor,
      backgroundColor: branding.buttonColor,
      borderRadius: '4px',
      '& a': {
        color: `${branding.buttonTextColor} !important`,
      },
      '&:hover': {
        color: branding.buttonColor,
        borderColor: branding.buttonColor,
        backgroundColor: branding.buttonTextColor,
      },
      '&:active': {
        color: `${branding.buttonColor} !important`,
        borderColor: `${branding.buttonColor} !important`,
        backgroundColor: `${branding.buttonTextColor} !important`,
      },
      '&:focus': {
        color: `${branding.buttonColor} !important`,
        borderColor: `${branding.buttonColor} !important`,
        backgroundColor: `${branding.buttonTextColor} !important`,
      },
    },
    dropdownClass: {
      border: `1px solid ${branding.buttonColor}`,
      backgroundColor: branding.buttonTextColor,
    },
    pageTitle: {
      color: branding.primaryColor,
    },
  });
  const classes = useStyles();

  const isVirtualAppointment = visit => {
    const virtualAppointment = visit.data.attributes['virtual-appointment'];
    return !!virtualAppointment;
  };

  const getVisitForCalendar = (visit, location, provider) => {
    const startTime = moment(visit.data.attributes['appointment-at']).tz(
      timezoneName,
    );
    const endTime = moment(visit.data.attributes['appointment-at'])
      .tz(timezoneName)
      .add(15, 'm');
    const title = `Appointment - ${location.name}`;
    let description = !isEmpty(provider)
      ? `Appointment with ${provider.attributes.name}.`
      : `Appointment at ${location.name}.`;
    let locationText = `${location.address} ${location.city} ${location.state} ${location.zip}`;

    if (isVirtualAppointment(visit)) {
      const joinUrl = visit.data.attributes['virtual-appointment-join-url'];
      const testUrl = visit.data.attributes['virtual-appointment-test-url'];
      description += `  Join Meeting: ${joinUrl}.`;
      if (testUrl) {
        description += `  Please ensure to test your setup and use the following link to test connections prior to the appointment time `;
        description += ` ${testUrl}.`;
      }
      locationText = `Virtual meeting - ${joinUrl}`;
    }

    return {
      title,
      description,
      location: locationText,
      startTime,
      endTime,
    };
  };

  return (
    <>
      <Grid container>
        <Grid item sm={8}>
          <h1 className={`confirmation ${classes.pageTitle}`}>
            {t('ConfirmationPage.confirmation', {
              number: visit.data.attributes['confirmation-number'],
            })}
          </h1>

          <p>
            {location &&
              location.name &&
              t('ConfirmationPage.description', { location: location.name })}
          </p>
        </Grid>
      </Grid>

      <h2 className="confirmation">
        {moment(visit.data.attributes['appointment-at'])
          .tz(timezoneName)
          .format('dddd, MMMM Do YYYY @ h:mm a')}
      </h2>

      <hr />

      <Grid container>
        <Grid item xs={12} sm={8}>
          {!isEmpty(provider) && (
            <Provider
              provider={{ ...provider, ...camel(provider, { deep: true }) }}
              location={{
                ...location,
                ...camel(location),
                hideAddress:
                  camel(location).hideAddress || isVirtualAppointment(visit),
              }}
              hideSchedule
              appointmentType={visit.data.attributes['appointment-type-name']}
            />
          )}
          {!isEmpty(location) && isEmpty(provider) && (
            <>
              <Location
                location={{
                  ...location,
                  ...camel(location),
                  hideAddress:
                    camel(location).hideAddress || isVirtualAppointment(visit),
                }}
                appointmentType={visit.data.attributes['appointment-type-name']}
              />
              <Phone phoneNumber={location.phone} />
            </>
          )}
        </Grid>
        <Grid item sm={4}>
          {!isVirtualAppointment(visit) ? (
            <Map location={location} />
          ) : (
            <VirtualAppointment
              joinUrl={visit.data.attributes['virtual-appointment-join-url']}
              testUrl={visit.data.attributes['virtual-appointment-test-url']}
            />
          )}
        </Grid>
      </Grid>

      <hr />

      <Grid container>
        <Grid item sm={8}>
          <Typography
            variant="h5"
            component="h2"
            className="confirmation-heading"
          >
            {!isVirtualAppointment(visit)
              ? t('confirmation.instructions.title')
              : t('confirmation.instructions.virtualAppointment.title')}
          </Typography>

          <p>
            {number &&
              (!isVirtualAppointment(visit) ? (
                <Trans i18nKey="ConfirmationPage.insDesc" number={number}>
                  Please bring a valid photo ID and any insurance cards (if
                  applicable), as well as your confirmation number (
                  <strong>#{{ number }}</strong>). In addition, please bring a
                  list of your current medications and dosages including
                  prescriptions, over the counter, and herbal and home remedies.
                </Trans>
              ) : (
                <Trans
                  i18nKey="confirmation.instructions.virtualAppointment.description"
                  number={number}
                >
                  Please have a valid photo ID and any insurance cards (if
                  applicable), as well as your confirmation number (
                  <strong>#{{ number }}</strong>). In addition, please have a
                  list of your current medications and dosages available -
                  including prescriptions, over the counter, and herbal and home
                  remedies.
                </Trans>
              ))}
          </p>

          {!isEmpty(visit) && !isEmpty(location) && (
            <Button className={classes.button} type="button">
              <AddToCalendar
                event={getVisitForCalendar(visit, location, provider)}
                buttonLabel={t('ConfirmationPage.AddToCalendar')}
                dropdownClass={`react-add-to-calendar__dropdown ${classes.dropdownClass}`}
              />
            </Button>
          )}

          <Button
            className={classes.button}
            type="button"
            onClick={() => {
              window.print();
            }}
          >
            {t('ConfirmationPage.print')}
          </Button>
        </Grid>
      </Grid>

      <hr />

      <Grid container>
        <Grid item sm={8}>
          <Typography
            variant="h5"
            component="h3"
            className="confirmation-heading"
          >
            {t('ConfirmationPage.importantTitle')}
          </Typography>

          <p>{t('ConfirmationPage.importantDescription')}</p>
        </Grid>
      </Grid>

      <hr />

      <Grid container>
        <Grid item sm={8}>
          <Typography
            variant="h5"
            component="h3"
            className="confirmation-heading important"
          >
            {t('ConfirmationPage.cancelTitle')}
          </Typography>

          <p>{t('ConfirmationPage.cancelDescription')}</p>
        </Grid>
      </Grid>
    </>
  );
};

ConfirmationSection.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  number: PropTypes.string.isRequired,
  provider: PropTypes.instanceOf(Object).isRequired,
  timezoneName: PropTypes.string.isRequired,
  visit: PropTypes.instanceOf(Object).isRequired,
};

export default ConfirmationSection;
