const urlSigner = require('gmaps-url-signer');

const key = process.env.REACT_APP_GOOGLE_LOCATION_SERVICE_API_KEY;
const secret = process.env.REACT_APP_GOOGLE_LOCATION_SERVICE_SIGNATURE;
const domain = 'http://maps.googleapis.com';

export const getSignedUrl = markers => {
  const path = `/maps/api/staticmap?size=640x230&zoom=16&markers=|${markers}&sensor=false&scale=2&key=${key}`;
  return domain + urlSigner.sign(path, secret);
};
