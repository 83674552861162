import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { BrandingContext } from './contexts/BrandingContext';
import WhenToCall911Modal from './modals/WhenToCall911Modal';
import './TermsPage.scss';

const TermsPage = () => {
  const { branding } = useContext(BrandingContext);
  const [t] = useTranslation();
  const h3Branding = {
    color: branding.primaryColor,
    borderBottom: `1px solid ${branding.primaryColor}`,
  };
  const [show911Modal, setShow911Modal] = useState(false);

  return (
    <>
      <main className="TermsPage container">
        {/* Key Not Found */}
        <h1 style={{ color: branding.primaryColor }}>
          {t('shared:termsPage.termsOfUse')}
        </h1>

        <p className="intro">
          {t('shared:termsPage.termsAndConditions')}
        </p>

        <p>
          {t('shared:termsPage.useOfWebDesc')} “
          <a onClick={() => setShow911Modal(true)} className="link-default link-call911">
            {t('shared:termsPage.call911')}
          </a>
          ” {t('shared:termsPage.useOfWebDesc2')}
        </p>

        <section className="terms">
          <h3 style={h3Branding}>1. {t('shared:termsPage.responsibilityForMinors')}</h3>
          <div className="term-details">
            <p>{t('shared:termsPage.responsibilityForMinorsDesc')}</p>
          </div>
        </section>

        <section className="terms">
          <h3 style={h3Branding}>2. {t('shared:termsPage.ownership')}</h3>
          <div className="term-details">
            <p>{t('shared:termsPage.ownershipDesc')}</p>
          </div>
        </section>

        <section className="terms">
          <h3 style={h3Branding}>3. {t('shared:termsPage.prohibitedAct')}</h3>
          <div className="term-details">
            <p>{t('shared:termsPage.prohibitedDesc')}</p>
            <p>{t('shared:termsPage.additionallyNotAgree')}:</p>
            <ol>
              <li>{t('shared:termsPage.useThisSiteForCommercial')};</li>
              <li>{t('shared:termsPage.prohibitedDesc2')}</li>
              <li>{t('shared:termsPage.prohibitedDesc3')}</li>
              <li>{t('shared:termsPage.prohibitedDesc4')}</li>
              <li>{t('shared:termsPage.prohibitedDesc5')}</li>
            </ol>
            <p>{t('shared:termsPage.prohibitedDesc6')}</p>
          </div>
        </section>

        <section className="terms">
          <h3 style={h3Branding}>4. {t('shared:termsPage.termination')}</h3>
          <div className="term-details">
            <p>{t('shared:termsPage.termination1')}</p>
          </div>
        </section>

        <section className="terms">
          <h3 style={h3Branding}>5. {t('shared:termsPage.services')}</h3>
          <div className="term-details">
            <p>{t('shared:termsPage.servicesDesc1')}</p>
            <p>{t('shared:termsPage.servicesDesc2')}</p>
          </div>
        </section>

        <section className="terms">
          <h3 style={h3Branding}>6. {t('shared:termsPage.disclaimer')}</h3>
          <div className="term-details">
            <p>{t('shared:termsPage.disclaimerDesc')}</p>
          </div>
        </section>

        <section className="terms">
          <h3 style={h3Branding}>7. {t('shared:termsPage.exclusionOfLiability')}</h3>
          <div className="term-details">
            <p>{t('shared:termsPage.exclusionOfLiabilityDesc')}</p>
          </div>
        </section>

        <section className="terms">
          <h3 style={h3Branding}>8. {t('shared:termsPage.exclusionRemedy')}</h3>
          <div className="term-details">
            <p>{t('shared:termsPage.exclusionRemedyDesc')}</p>
          </div>
        </section>

        <section className="terms">
          <h3 style={h3Branding}>9. {t('shared:termsPage.release')}</h3>
          <div className="term-details">
            <p>{t('shared:termsPage.releaseDesc')}</p>
          </div>
        </section>

        <section className="terms">
          <h3 style={h3Branding}>10. {t('shared:termsPage.indemnification')}</h3>
          <div className="term-details">
            <p>{t('shared:termsPage.indemnification1')}</p>
          </div>
        </section>

        <section className="terms">
          <h3 style={h3Branding}>11. {t('shared:termsPage.entireAgreement')}</h3>
          <div className="term-details">
            <p>{t('shared:termsPage.entireAgreement1')}</p>
          </div>
        </section>

        <section className="terms">
          <h3 style={h3Branding}>12. {t('shared:termsPage.severability')}</h3>
          <div className="term-details">
            <p>{t('shared:termsPage.severabilityDesc')}</p>
          </div>
        </section>

        <section className="terms">
          <h3 style={h3Branding}>13. {t('shared:termsPage.amendmentsAndWaivers')}</h3>
          <div className="term-details">
            <p>{t('shared:termsPage.amendmentsAndWaiversDesc')}</p>
          </div>
        </section>

        <section className="terms">
          <h3 style={h3Branding}>14. {t('shared:termsPage.applicableLaw')}</h3>
          <div className="term-details">
            <p>{t('shared:termsPage.applicableLawDesc')}</p>
          </div>
        </section>

        <section className="terms">
          <h3 style={h3Branding}>15. {t('shared:termsPage.cookies')}</h3>
          <div className="term-details">
            <p>{t('shared:termsPage.cookiesDesc')}</p>
          </div>
        </section>

        <section className="terms">
          <h3 style={h3Branding}>16. {t('shared:termsPage.digitalCopyright')}</h3>
          <div className="term-details">
            <p>
              {t('shared:termsPage.digitalCopyright1')}{' '}
              <a href="mailto:DMCA@inquicker.com" className="link-default">
                DMCA@inquicker.com
              </a>
              .
            </p>
          </div>
        </section>

        <section className="terms">
          <h3 style={h3Branding}>
            17. {t('shared:termsPage.emergencyMedicalTreatment')}
          </h3>
          <div className="term-details">
            <p>{t('shared:termsPage.emergencyMedicalTreatment1')}</p>
            <p>{t('shared:termsPage.emergencyMedicalTreatment2')}</p>
            <p>{t('shared:termsPage.emergencyMedicalTreatment3')}</p>
            <p>{t('shared:termsPage.emergencyMedicalTreatment4')}</p>
            <p>{t('shared:termsPage.emergencyMedicalTreatment5')}</p>
            <p>{t('shared:termsPage.emergencyMedicalTreatment6')}</p>
            <p>{t('shared:termsPage.emergencyMedicalTreatment7')}</p>
          </div>
        </section>
      </main>

      <WhenToCall911Modal
        show={show911Modal}
        onClose={() => setShow911Modal(false)}
      />
    </>
  );
};

export default TermsPage;
