/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useState } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {
  Form,
  Modal,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import './StripeForm.scss';
import { FormHelperText } from '@material-ui/core';
import { BrandingContext } from 'components/contexts/BrandingContext';
import BrandingButton from '../../common/BrandingButton';
import Loader from '../../../assets/images/ajaxloader.gif';
import { useTranslation } from 'react-i18next';

const StripeForm = props => {
  const {
    _onChange,
    _formSubmit,
    toggleModal,
    show = true,
    formContext,
    handleClose,
    appointmentCurrency,
    appointmentCost,
    emailAddress,
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [stripeErr, setStripeErr] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const { branding } = useContext(BrandingContext);
  const [t] = useTranslation();

  const makePayment = async () => {
    if (!stripe || !elements) {
      return false;
    }
    const card = elements.getElement(CardNumberElement);
    const cardHolder = document.getElementById('cardHolderName');
    let cardHolderName = '';
    if (cardHolder) {
      cardHolderName = cardHolder.value;
    }
    if (cardHolderName) {
      setShowLoader(true);
      stripe
        .confirmCardPayment(formContext.form.providerSessionSecret, {
          payment_method: {
            card,
            billing_details: {
              name: cardHolderName,
              email: formContext.form.email.value || emailAddress,
            },
          },
        })
        .then(async res => {
          if (res.error) {
            setShowLoader(false);
            setStripeErr([res.error]);
          }
          if (
            res.paymentIntent &&
            res.paymentIntent.status === 'requires_capture'
          ) {
            await props.callVisit();
            setShowLoader(false);
          }
        })
        .catch(err => {
          setShowLoader(false);
          console.error('Payment Failed', err);
          setShowLoader(false);
        });
    } else if (!stripeErr.find(err => err.code === 'cardName')) {
      setShowLoader(false);
      setStripeErr(
        stripeErr.concat({
          code: 'cardName',
          message: t('paymentModal.cardHolderNameRequired'),
        }),
      );
    }
  };

  return (
    <Modal
      show={show}
      onHide={toggleModal}
      onClose={handleClose}
      backdrop="static"
      keyboard={false}
      className="custom-modal"
    >
      {showLoader && (
        <div className="loader-container-box">
          <div className="loader-inner-box">
            <img src={Loader} alt="" className="loader-image" />
            <div className="loader-text">Processing Payment...</div>
          </div>
        </div>
      )}
      <ModalHeader closeButton>
        <ModalTitle>{t('paymentModal.Title')}</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={_formSubmit}>
          <div className="logo-section">
            <img
              alt="Health System Logo"
              className="logo"
              src={branding.logoImage}
            />
          </div>
          <div className="form-control-inline">
            <label htmlFor="cardHolderName" className="label nopad">
              {t('paymentModal.labelAmount')}
            </label>
            <div className="input-element">
              {appointmentCurrency}
              {appointmentCost}
            </div>
          </div>
          <div className="form-control-inline">
            <label htmlFor="cardHolderName" className="label">
              {t('paymentModal.labelNameOnCard')}
            </label>
            <div className="input-element">
              <input
                placeholder={t('paymentModal.labelNameOnCard')}
                onChange={_onChange}
                id="cardHolderName"
                className="stripeElement"
                // options={{
                style={{
                  fontSize: '16px',
                  width: '100%',
                  margin: '0',
                }}
                // }}
              />
              <FormHelperText className="errors">
                {stripeErr.find(err => err.code === 'cardName') ? (
                  <>
                    {' '}
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="button-icon"
                    />{' '}
                    {stripeErr.find(err => err.code === 'cardName').message}
                  </>
                ) : (
                  ''
                )}
              </FormHelperText>
            </div>
          </div>
          <div className="form-control-inline">
            <label htmlFor="email" className="label">
              {t('paymentModal.labelEmailAddress')}
            </label>
            <div className="input-element">
              <input
                placeholder="Email"
                value={formContext.form.email.value || emailAddress}
                id="email"
                disabled
                className="stripeElement"
                // options={{
                style={{
                  fontSize: '16px',
                  width: '100%',
                  margin: '0',
                }}
                // }}
              />
            </div>
          </div>
          {/* <CardElement /> */}
          <div className="form-control-vertical">
            <label htmlFor="email" className="label">
              {t('paymentModal.labelCardNumber')}
            </label>
            <div className="input-element">
              <div className="stripeElement">
                <CardNumberElement
                  placeholder={t('paymentModal.labelCardNumber')}
                  onChange={_onChange}
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                      },
                    },
                    showIcon: true,
                  }}
                  id="cardNumber"
                />
                <FormHelperText className="err errors">
                  {stripeErr.find(err => err.code.includes('number')) ? (
                    <>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="button-icon"
                      />
                      {t('paymentModal.cardNumberIsInvalid')}
                    </>
                  ) : (
                    ''
                  )}
                </FormHelperText>
              </div>
            </div>
          </div>

          <div className="contain-flex">
            <div className="inline">
              <div className="form-control-vertical">
                <label htmlFor="email" className="label">
                  {t('paymentModal.labelExpiration')}
                </label>
                <div className="input-element">
                  <div className="stripeElement">
                    <CardExpiryElement
                      onChange={_onChange}
                      placeholder="MM/YY"
                    />
                    <FormHelperText className="err errors">
                      {stripeErr.find(err => err.code.includes('expiry')) ? (
                        <>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="button-icon"
                          />
                          {t('CreditCard.ExpiryDate')}
                        </>
                      ) : (
                        ''
                      )}
                    </FormHelperText>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline">
              <div className="form-control-vertical">
                <label htmlFor="email" className="label">
                  {t('paymentModal.labelSecurityCode')}
                </label>
                <div className="input-element">
                  <div className="stripeElement cvv-image">
                    <CardCvcElement onChange={_onChange} />
                    <FormHelperText className="err errors">
                      {stripeErr.find(err => err.code.includes('cvc')) ? (
                        <>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="button-icon"
                          />{' '}
                          {t('CreditCard.CVC')}
                        </>
                      ) : (
                        ''
                      )}
                    </FormHelperText>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <BrandingButton
          className="submit-btn"
          type="button"
          onClick={e => {
            e.preventDefault();
            makePayment();
          }}
        >
          {t('paymentModal.btnPay')}
        </BrandingButton>
      </ModalFooter>
    </Modal>
  );
};
StripeForm.defaultProps = {
  emailAddress: '',
};

StripeForm.propTypes = {
  show: PropTypes.bool.isRequired,
  _onChange: PropTypes.func.isRequired,
  _formSubmit: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  appointmentCurrency: PropTypes.string.isRequired,
  appointmentCost: PropTypes.string.isRequired,
  callVisit: PropTypes.func.isRequired,
  emailAddress: PropTypes.string,
};
export default StripeForm;
