import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import QueryString from 'query-string';
import { isEmpty, isUndefined, uniqBy } from 'lodash';
import { FormHelperText, Grid, useTheme } from '@material-ui/core';
import ScrollContainer from 'react-indiana-drag-scroll';
import { connect, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import RoomIcon from '@material-ui/icons/Room';
import SearchIcon from '@material-ui/icons/Search';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import WatchLater from '@material-ui/icons/WatchLater';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SecurityIcon from '@material-ui/icons/Security';
import PropTypes from 'prop-types';
import {
  displayNotification,
  clearNotification,
} from 'redux/actions/uiActions';
import {
  loadAppointmentTypes,
  loadAppointmentTypesAction,
} from 'redux/actions/appointmentTypesActions';
import { loadInsurancePlans } from 'redux/actions/insurancePlanActions';
import { useQueryString } from 'hooks/useQueryString';
import { loadFilteredActiveServices } from 'redux/actions/serviceLineActions';
import { loadGeocoding } from 'redux/actions/geocodingActions';
import {
  loadNames,
  loadNameWithServiceIds,
  searchProviders,
} from 'redux/actions/searchActions';
import { fetchHealthSystem } from 'redux/actions/healthSystemActions';
import { setPhysicianShowForm } from 'redux/actions/educationalPageActions';
import { BrandingContext } from 'components/contexts/BrandingContext';
import ZipcodeField from 'components/form/ZipcodeField';
import { parseTimestamp } from 'utils/dateTimeUtils';
import * as searchConstants from 'config/searchConstants';
import { getHeaderInfo, getBaseUrl } from 'api/apiUtils';
import PageNotFound from 'components/PageNotFound';
import { isMobile } from 'react-device-detect';
import DialogTimePicker from '../dialogs/DialogTimePicker';
import DialogZipCode from '../dialogs/DialogZipCode';
import DialogRadio from '../dialogs/DialogRadio';
import DialogMobile from '../dialogs/DialogMobile';
import SchedulePicker from '../dialogs/SchedulePicker';
import Omnibox from './Omnibox';
import CustomSelect from './CustomSelect';
import BrandingButton from '../common/BrandingButton';
import { isValid, getInputName } from '../../utils/helpers';
import {
  shouldShowSearchElement,
  shouldVerfiyRoute,
  isGroupC,
  cleanServiceLineUrls,
} from '../../helpers/searchOptions';

const PhysicianForm = ({
  geocoding,
  searchParams,
  insurancePlans,
  loadNames,
  loadNameWithServiceIds,
  searchProviders,
  venuesOfCare,
  loadInsurancePlans,
  defaultAppointmentTypes,
  loadAppointmentTypes,
  displayNotification,
  clearNotification,
  fetchHealthSystem,
  loadGeocoding,
  setPhysicianShowForm,
  setApptTypeRequired,
  showForm,
  resetPageCount,
  uxMode,
  loadAppointmentTypesAction,
  setStartDate,
  healthSystem,
  services,
  loadFilteredActiveServices,
}) => {
  const { branding } = useContext(BrandingContext);
  const history = useHistory();
  const { search, pathname, state } = useLocation();
  const params = QueryString.parse(search);
  const { urlParams } = useQueryString();
  const {
    selectedParams: { appointmentTypeId, date },
  } = searchParams;
  const { lat, lng, zipcode, zipcodeValid } = geocoding;
  const insuranceOptions = insurancePlans.map(plan => ({
    value: plan.id,
    text: plan.name,
  }));

  const providerNamesRedux = useSelector(
    state => state?.searchParams?.providerNames,
  );

  const [dropdownAppointmentTypes, setDropdownAppointmentTypes] = useState([]);
  const [showApptTypeSelect, setShowApptTypeSelect] = useState(true);
  const groupC = isGroupC(uxMode, params.service);
  const [t] = useTranslation();
  if (params.zip) {
    localStorage.setItem('zipCode', params.zip);
  }
  const timestamp = () => {
    let result;
    if (params.timestamp) {
      result = params.timestamp;
    }

    if (!isEmpty(date) && isEmpty(params.timestamp)) {
      // Usually exists after navigating around the site.
      // This is how search params can populate via BreadCrumbs.
      // The HH:mm format is 5 chars long - it is otherwise a real date string.
      if (date.start.length !== 5) {
        result = date.start;
      }
    }
    return result;
  };
  const timestampDisplayText = () => {
    let result;
    if (params.timestamp) {
      result = moment(params.timestamp)
        .tz(healthSystem['time-zone'])
        .format('MMM D, YYYY');
    } else {
      result = moment()
        .tz(healthSystem['time-zone'])
        .format('MMM D, YYYY');
    }

    if (!isEmpty(date) && isEmpty(params.timestamp)) {
      // Usually exists after navigating around the site.
      // This is how search params can populate via BreadCrumbs.
      // The HH:mm format is 5 chars long - it is otherwise a real date string.
      if (date?.start?.length !== 5) {
        result = moment(date.start)
          .tz(healthSystem['time-zone'])
          .format('MMM D, YYYY');
      }
    }
    return result;
  };

  const [physicianForm, setPhysicianForm] = useState({
    appointmentTypes: {
      value: params.appointmentTypes || appointmentTypeId || '',
    },
    insurancePlans: {
      value: params.insurancePlans || '',
    },
    specialties: {
      name: params.specialtyName || '',
      type: params.specialtyType || '',
      id: params.specialtyId || '',
    },
    timestamp: {
      displayText: timestampDisplayText(),
      text: timestamp(),
      value: parseTimestamp(timestamp(), healthSystem['time-zone']),
    },
    zip: {
      value: params.zip || '',
    },
    isVirtual: {
      value: params.isVirtual || false,
    },
  });
  const [showTimePickerDialog, setShowTimePickerDialog] = useState(false);
  const [showZipDialog, setShowZipDialog] = useState(false);
  const [showSpecialityDialog, setShowSpecialityDialog] = useState(false);
  const [showInsuranceDialog, setShowInsuranceDialog] = useState(false);
  const [showTypesDialog, setShowTypesDialog] = useState(false);
  const [showSchedulePicker, setShowSchedulePicker] = useState(false);
  const [searchRadius, setSearchRadius] = useState(0);
  const [showAppointmentTypeError, setShowAppointmentTypeError] = useState(
    false,
  );
  const [zipcodeLengthError, setZipcodeLengthError] = useState(false);
  const [zipcodeInvalidError, setZipcodeInvalidError] = useState(false);
  const [geoInfo, setGeoInfo] = useState({ lat: 0, lng: 0 });
  const [mobileSpecialities, setMobileSpecialities] = useState({});
  const [timestampPlaceholder, setTimestampPlaceholder] = useState({});
  const [changedSearchOption, setChangedSearchOption] = useState();
  const [initialSearchDone, setInitialSearchDone] = useState(true);
  const APP_TYPE_RESET = JSON.stringify({
    appointmentTypes: { error: false, value: '' },
  });

  const theme = useTheme();
  const handleSetFormState = e => {
    const k = Object.keys(e)[0];
    if (k === 'appointmentTypes' && dropdownAppointmentTypes.length === 0) {
      // skip search option change
      setChangedSearchOption(k);
    } else {
      setChangedSearchOption(k);
    }

    if (groupC && JSON.stringify(e) === APP_TYPE_RESET) {
      setPhysicianForm({
        ...physicianForm,
        ...e,
      });
      return;
    }

    if (!isEmpty(e)) {
      setPhysicianForm({
        ...physicianForm,
        ...e,
      });
    }
  };

  useEffect(() => {
    if (!isUndefined(urlParams.serviceLine)) {
      const formParams = Object.keys(physicianForm).reduce((acc, curr) => {
        if (curr === 'timestamp') {
          acc[curr] = physicianForm[curr]?.value?.start;
        } else if (curr === 'specialties') {
          acc.specialtyName = physicianForm[curr].name;
          acc.specialtyType = physicianForm[curr].type;
          acc.specialtyId = physicianForm[curr].id;
        } else {
          acc[curr] = physicianForm[curr].value;
        }

        return acc;
      }, {});
      const service = Object.values(services).find(s => s.type === 'services');
      if (service) {
        const search = QueryString.stringify({
          ...params,
          ...formParams,
          service: service.attributes.permalink,
          serviceName: service.attributes.name,
          subservice_ids: service.id,
          service_id: service.id,
          subservice_ids: service.id,
          zip: zipcode?.replace(/  /g, ''),
        });

        history.replace({
          pathname,
          search,
          state: { initialSearch: true },
        });
      }
    }
  }, [services, zipcode, appointmentTypeId]);

  useEffect(() => {
    if (zipcodeValid) {
      setZipcodeLengthError(false);
      setZipcodeInvalidError(false);
    }
  }, [zipcodeValid]);

  // initial date/time range setup
  useEffect(() => {
    if (
      shouldShowSearchElement(
        uxMode,
        params.service,
        searchConstants.DATE_TIME_INPUT,
      )
    ) {
      return;
    }

    const { timestamp: schedule } = physicianForm;
    const startDate = moment(schedule.text, 'MMM D, YYYY');
    const timeRange = {
      start: startDate.format(),
      end: startDate.add(1, 'days').format(),
    };
    setPhysicianForm({
      ...physicianForm,
      timestamp: timeRange,
    });
    if (!Object.keys(healthSystem).length > 0) {
      fetchHealthSystem();
    }
  }, [uxMode]);

  // Find radius of the physician services
  useEffect(() => {
    if (uxMode === 'default') return;
    const { data } = venuesOfCare;
    if (!isEmpty(data)) {
      const vocPhysicianServices = Object.values(data).find(
        v => v.attributes.venueType === 'physician_services',
      );
      const radius = vocPhysicianServices.attributes.searchDistanceRadius;
      setSearchRadius(radius || 100);
    }
  }, [venuesOfCare, uxMode]);

  useEffect(() => {
    if (
      !isUndefined(urlParams.serviceLine) &&
      !Object.keys(services).length > 0
    ) {
      const serviceLineParam = cleanServiceLineUrls(urlParams.serviceLine);
      loadFilteredActiveServices(serviceLineParam);
    }
    const formParams = Object.keys(physicianForm).reduce((acc, curr) => {
      if (curr === 'timestamp') {
        acc[curr] = physicianForm?.[curr]?.value?.start;
      } else if (curr === 'specialties') {
        acc.specialtyName = physicianForm[curr].name;
        acc.specialtyType = physicianForm[curr].type;
        acc.specialtyId = physicianForm[curr].id;
      } else {
        acc[curr] = physicianForm[curr].value;
      }
      return acc;
    }, {});

    if (!zipcode && isValid(params.zip) && !geocoding.ziploaded) {
      loadGeocoding(params.zip);
    }
    if (isUndefined(urlParams.serviceLine)) {
      const search = QueryString.stringify({
        ...params,
        ...formParams,
        zip: zipcode,
      });
      history.replace({
        pathname,
        search,
        state: { initialSearch: true },
      });
    }
  }, [physicianForm, uxMode, zipcode]);

  // To Maintain the latitude state to use the sideeffect of changes in latitude
  const [latitude, setLat] = useState(null);
  useEffect(() => {
    setLat(state => {
      if (geocoding.coords.lat !== state && geocoding.coords.lat !== 0)
        return geocoding.coords.lat;
      return state;
    });
  }, [geocoding.coords.lat]);
  const [providerCall, setProviderCall] = useState(true);

  useEffect(() => {
    // const {
    //   coords: { lat, lng },
    // } = geocoding;
    // if (!lat || !lng) return;
    if (uxMode === 'default' && providerCall) {
      let serviceIdParam = !isUndefined(params.subservice_ids)
        ? params.subservice_ids
        : '';

      if (Array.isArray(serviceIdParam) && serviceIdParam !== '') {
        serviceIdParam = serviceIdParam.join(',');
      } else if (serviceIdParam !== '') {
        serviceIdParam =
          serviceIdParam.indexOf(',') > 0
            ? serviceIdParam.split(',')
            : serviceIdParam;
      }

      if (
        serviceIdParam.length > 0 &&
        serviceIdParam !== '' &&
        Object.keys(services).length > 0 &&
        providerNamesRedux.length === 0
      ) {
        setProviderCall(false);
        loadNameWithServiceIds(
          geocoding.coords.lat,
          geocoding.coords.lng,
          serviceIdParam,
          services,
        );
      }
    } else if (uxMode === 'educational') {
      const { data } = venuesOfCare;
      if (data && Object.values(data).length > 0) {
        const venue = Object.values(data).find(
          v => v.attributes.venueType === 'physician_services',
        );
        if (venue) {
          loadNames(geocoding.coords.lat, geocoding.coords.lng, venue.id);
        }
      }
    }
  }, [services, providerCall]);

  // useEffect(() => {
  //   setChangedSearchOption('zipcode');
  //   setPhysicianForm({
  //     ...physicianForm,
  //     zip: {
  //       value: zipcode !== undefined ? zipcode.replace(/  /g, '') : zipcode,
  //     },
  //   });
  // }, [zipcode]);

  const handleSearchTextChanged = v => {
    if (v && v.type === 'Provider' && v.id) {
      history.push(`/providers/${v.id}?${QueryString.stringify(params)}`);
    }
    if (v !== null && v.type === 'Provider') {
    } else {
      setChangedSearchOption('speciality');
      setPhysicianForm({
        ...physicianForm,
        specialties: v || {
          name: '',
          type: '',
          id: '',
        },
      });
      let search;
      if (v === null) {
        search = QueryString.stringify({
          ...params,
        });
      } else {
        search = QueryString.stringify({
          ...params,
          specialtyId: v === null ? '' : v.id,
          specialtyName: v === null ? '' : v.id === undefined ? '' : v.name,
          specialtyType: v === null ? '' : v.type,
        });
      }
      history.replace({
        pathname,
        search,
        state: { initialSearch: false },
      });
    }
  };

  const appTypeVisible = shouldShowSearchElement(
    uxMode,
    params.service,
    searchConstants.APP_TYPE_INPUT,
  );

  const appointmentTypesErrorMessage = t('PhysicianForm.selectApp');
  const zipcodeErrorMessage = t('PhysicianForm.err.zip');
  const zipcodeLengthIssue = false;
  const appointmentTypeNotSet = !physicianForm.appointmentTypes.value;

  useEffect(() => {
    appTypeVisible &&
      appointmentTypeNotSet &&
      dropdownAppointmentTypes &&
      dropdownAppointmentTypes.length > 0 &&
      setShowAppointmentTypeError(true);
  }, [defaultAppointmentTypes, dropdownAppointmentTypes]);

  const zipErrorMessage = () => {
    // TODO: refactor all this zipcode error handling into something somewhat sensical.
    if (zipcodeInvalidError || zipcodeLengthError) {
      return zipcodeErrorMessage;
    }
    return '';
  };

  useEffect(() => {
    if (zipcodeValid && !zipcodeLengthIssue && !zipcodeInvalidError) {
      setZipcodeLengthError(false);
      setZipcodeInvalidError(false);
    }
  }, [zipcodeValid, zipcodeLengthIssue, zipcodeInvalidError]);

  const handleSearch = initialSearch => {
    if (initialSearch) {
      setInitialSearchDone(true);
    }

    if (!initialSearch && !initialSearchDone) {
      return;
    }

    let apptTypes = [];
    if (physicianForm?.appointmentTypes?.value) {
      apptTypes = dropdownAppointmentTypes.filter(
        d => d.value === physicianForm.appointmentTypes.value,
      );
    }
    const isVirtual = apptTypes && apptTypes[0]?.isVirtualAppointment === true;
    if (isVirtual && !physicianForm.isVirtual.value) {
      setPhysicianForm({
        ...physicianForm,
        ...{ isVirtual: { value: true } },
      });
    }
    if (!isVirtual && physicianForm.isVirtual.value) {
      setPhysicianForm({
        ...physicianForm,
        ...{ isVirtual: { value: false } },
      });
    }

    if (
      !zipcodeValid ||
      zipcodeLengthIssue ||
      (appTypeVisible &&
        appointmentTypeNotSet &&
        dropdownAppointmentTypes.length > 0)
    ) {
      if (
        appTypeVisible &&
        appointmentTypeNotSet &&
        dropdownAppointmentTypes.length > 0
      ) {
        setShowAppointmentTypeError(true);
      }

      if (!lat || !lng) {
        setZipcodeInvalidError(true);
      }

      if (zipcodeLengthIssue) {
        setZipcodeLengthError(true);
      }
      if (!zipcodeValid) {
        setZipcodeInvalidError(true);
      }
    }

    if (appTypeVisible && appointmentTypeNotSet) {
      setShowAppointmentTypeError(true);
    } else {
      setShowAppointmentTypeError(false);
    }
    setZipcodeLengthError(false);
    setZipcodeInvalidError(false);

    const searchParams = {
      isVirtual,
    };

    if (physicianForm.appointmentTypes.value)
      searchParams.appointmentTypeId = physicianForm.appointmentTypes.value;

    if (physicianForm.insurancePlans.value)
      searchParams.insurancePlan = physicianForm.insurancePlans.value;
    if (physicianForm.specialties.name) {
      searchParams.search = physicianForm.specialties.name;
      searchParams.searchType = physicianForm.specialties.type;
      searchParams.id = physicianForm.specialties.id;
    }

    if (physicianForm.timestamp.value) {
      searchParams.date = physicianForm.timestamp.value;
      setStartDate(physicianForm.timestamp.value);
    }

    if (
      geocoding &&
      geocoding.coords &&
      geocoding.coords.lat &&
      geocoding.coords.lng &&
      zipcode &&
      zipcodeValid
    ) {
      searchParams.latitude = geocoding.coords.lat;
      searchParams.longitude = geocoding.coords.lng;
    }

    if (!searchParams.latitude || !searchParams.longitude) {
      setZipcodeInvalidError(true);
      return;
    }

    if (searchRadius > 0) {
      searchParams.radius = searchRadius;
    }

    if (params.subservice_ids) {
      searchParams.serviceIds = params.subservice_ids;
    }

    if (isMobile) {
      // setShowAppointmentTypeError(false);
    }

    resetPageCount();
    searchProviders(searchParams);

    return true;
  };

  // zipcode and speciality are handled by handleSearch
  useEffect(() => {
    // zipcode is handled by useEffect hook
    if (!initialSearchDone || changedSearchOption === 'zipcode') {
      return;
    }

    if (isMobile) {
      handleSearch(false);
      return;
    }
    if (changedSearchOption && params.specialtyName === undefined) {
      handleSearch(false);
    }
    if (changedSearchOption && params.specialtyId === '') {
      handleSearch(false);
    }
    if (
      changedSearchOption &&
      params.specialtyId !== undefined &&
      params.specialtyName !== ''
    ) {
      handleSearch(false);
    }
  }, [physicianForm]);

  useEffect(() => {
    const {
      coords: { lat, lng },
      zipcode,
    } = geocoding;

    if (!lat || !lng || zipcode === '') {
      setZipcodeInvalidError(true);
    }
    if (!lat || !lng) return;

    if (
      geoInfo.zipcode !== zipcode ||
      geoInfo.lat !== lat ||
      geoInfo.lng !== lng
    ) {
      setGeoInfo({ lat, lng });
      if (initialSearchDone) {
        clearNotification();
        handleSearch(false);
        if (isMobile) setPhysicianShowForm(false);
      }
    }
  }, [geocoding]);

  const {
    providerNames,
    specialityNames,
    appointmentTypes,
    serviceNames,
  } = searchParams;

  const searchable = [
    ...providerNames,
    ...specialityNames,
    ...uniqBy(serviceNames, 'name'),
  ];

  const [insurancePlanCallPlaced, setInsurancePlanCallPlaced] = useState(true);
  useEffect(() => {
    const formData = {};
    // Infinite loop if no insurance plans set up.
    if (geocoding.coords && insurancePlanCallPlaced) {
      setInsurancePlanCallPlaced(false);
      loadInsurancePlans().catch(error => {
        // console.log(`Loading insurance plans failed ${error}`);
      });
    }

    if (uxMode === 'educational') {
      const formattedDefaultAppointmentTypes = !isEmpty(defaultAppointmentTypes)
        ? defaultAppointmentTypes.map(at => {
          return {
            value: at.id,
            text: at.attributes.name,
            isVirtualAppointment:
              at.attributes['virtual-appointment'] || false,
          };
        })
        : [];

      setDropdownAppointmentTypes(formattedDefaultAppointmentTypes);
    }

    if (
      uxMode === 'educational' &&
      isEmpty(appointmentTypes) &&
      isEmpty(defaultAppointmentTypes)
    ) {
      loadAppointmentTypes();
    }

    if (uxMode === 'educational' && !isEmpty(defaultAppointmentTypes)) {
      const typeId = appointmentTypeId || params.appointmentTypes;
      const typeObj = defaultAppointmentTypes.find(type => type.id === typeId);

      if (typeObj) {
        formData.appointmentTypes = {
          ...physicianForm.appointmentTypes,
          text: typeObj.attributes.name,
        };
      }
    }

    if (!isEmpty(insuranceOptions)) {
      const typeObj = insuranceOptions.find(
        type => type.value === params.insurancePlans,
      );

      if (typeObj) {
        formData.insurancePlans = {
          ...physicianForm.insurancePlans,
          text: typeObj.text,
        };
      }
    }

    if (!isEmpty(formData)) {
      handleSetFormState({ ...formData });
    }
  }, [
    uxMode,
    appointmentTypes,
    defaultAppointmentTypes,
    loadAppointmentTypes,
    insurancePlans,
    loadInsurancePlans,
    geocoding?.zipcode,
  ]);

  useEffect(() => {
    const abortController = new AbortController();
    if (!Object.keys(healthSystem).length > 0) {
      fetchHealthSystem();
    }
    if (dropdownAppointmentTypes.length > 0) {
      return;
    }

    if (Object.keys(services).length === 0) {
      return;
    }

    if (uxMode !== 'educational') {
      (async () => {
        const urlPath = `${getBaseUrl()}/appointment-types`;
        const baseParams = { 'page[size]': 999 };
        const urlParams = new URLSearchParams(baseParams);

        let serviceIdParam = !isUndefined(params.subservice_ids)
          ? params.subservice_ids
          : '';
        if (!Array.isArray(serviceIdParam) && serviceIdParam !== '') {
          serviceIdParam =
            serviceIdParam.indexOf(',') > 0
              ? serviceIdParam.split(',')
              : serviceIdParam;
        }

        if (serviceIdParam !== '') {
          if (serviceIdParam.indexOf(',') > 0) {
            serviceIdParam.forEach(sid => {
              urlParams.append('filter[service][]', sid);
            });
          } else urlParams.append('filter[service]', serviceIdParam);
        }

        const apiUrl = `${urlPath}?${urlParams}`;

        const response = await fetch(apiUrl, {
          ...getHeaderInfo(),
          signal: abortController.signal,
        });
        const results = await response.json();

        const data = results.data.map(d => {
          return {
            value: d.id,
            text: d.attributes.name,
            isVirtualAppointment: d.attributes['virtual-appointment'] || false,
          };
        });

        loadAppointmentTypesAction(results);
        setDropdownAppointmentTypes(data);
        // We show the field by default.
        // If, after searching, there are no results, hide it.
        if (data.length === 0) {
          setShowApptTypeSelect(false);
          // This is used by InventoryPage.js to let the search occur if there
          // are no appt types but the group says the field should appear.
          setApptTypeRequired(false);
        }

        // Find appointment type and set it into state if it's found
        if (physicianForm.appointmentTypes.value) {
          const apptTypeObj = data.find(
            at => at.value === physicianForm.appointmentTypes.value,
          );

          const updatedForm = {
            ...physicianForm,
            appointmentTypes: apptTypeObj || { value: '' },
            // have to set the zipcode here or else it doesn't exist in form state.
            zip: {
              value:
                zipcode !== undefined ? zipcode.replace(/  /g, '') : zipcode,
            },
          };
          setPhysicianForm(updatedForm);
        }
      })();
    }
    if (state?.initialSearch) {
      setInitialSearchDone(true);
    }
    return () => {
      abortController.abort();
    };
  }, [services]);

  const [paramZip, setParamZip] = useState();
  useEffect(() => {
    if (params.zip !== undefined && !isEmpty(params.zip)) {
      if (paramZip === '' || paramZip === undefined) setParamZip(params.zip);
    }
  }, [params.zip]);

  const btnStyle = {
    display: 'flex',
    justifyContent: 'center',
  };

  const iconSpan = {
    display: 'inline-block',
    overflow: 'hidden',
    paddingLeft: '10px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '80%',
  };

  let isValidRoute = false;
  if (urlParams.serviceLine !== undefined) {
    const serviceLineParam = cleanServiceLineUrls(urlParams.serviceLine);
    isValidRoute = shouldVerfiyRoute(serviceLineParam);

    if (!isValidRoute) {
      return <PageNotFound />;
    }
  }

  const ZipFields = () => {
    return (
      <Grid item xs={6} sm={2}>
        {!isMobile && (
          <ZipcodeField
            filled
            value={paramZip ? paramZip : undefined}
            icon={<RoomIcon />}
            hasError={zipcodeLengthError || zipcodeInvalidError}
            errorMessage={zipErrorMessage()}
          />
        )}
        {isMobile && (
          <>
            <Button
              onClick={() => setShowZipDialog(true)}
              style={btnStyle}
              variant="light"
              type="button"
              block
            >
              <RoomIcon />
              <span style={iconSpan}>
                {physicianForm.zip.value ||
                  zipcode ||
                  t('ZipcodeField.placeholder.zipCode')}
              </span>
            </Button>
            {zipErrorMessage() && (
              <FormHelperText error={zipcodeLengthError || zipcodeInvalidError}>
                {zipErrorMessage()}
              </FormHelperText>
            )}
          </>
        )}
      </Grid>
    );
  };

  const SpecialitiesFields = () => {
    return (
      <Grid item xs={6} sm={3}>
        {!isMobile && (
          <Omnibox
            filled
            options={searchable}
            handleChange={handleSearchTextChanged}
            handleOnBlur={() => handleSearch(false)}
            defaultValue={physicianForm.specialties}
          />
        )}
        {isMobile && (
          <Button
            onClick={() => setShowSpecialityDialog(true)}
            style={btnStyle}
            variant="light"
            type="button"
            block
          >
            {physicianForm.specialties.name ||
              t('PhysicianForm.mobile.nameSpeciality')}
          </Button>
        )}
      </Grid>
    );
  };

  const AppointmentTypesFields = () => {
    if (!showApptTypeSelect) {
      return '';
    }

    return (
      <Grid item xs={6} sm={2}>
        {!isMobile && (
          <CustomSelect
            filled
            fieldKey="appointmentTypes"
            fieldValue={physicianForm.appointmentTypes}
            icon={<LocalHospitalIcon />}
            label={t('PhysicianForm.placeholder.apptTypes')}
            items={dropdownAppointmentTypes}
            validateFormHandler={e => {
              const { value } = e.appointmentTypes;
              if (groupC) {
                setShowAppointmentTypeError(false);
              } else if (value) {
                setShowAppointmentTypeError(false);
              } else {
                setShowAppointmentTypeError(true);
              }
              handleSetFormState(e);
            }}
            hasError={showAppointmentTypeError}
            errorMessage={
              showAppointmentTypeError ? appointmentTypesErrorMessage : ''
            }
          />
        )}
        {isMobile && (
          <>
            <Button
              onClick={() => setShowTypesDialog(true)}
              variant="light"
              style={btnStyle}
              type="button"
              block
            >
              <LocalHospitalIcon />
              <span style={iconSpan}>
                {physicianForm.appointmentTypes.text ||
                  t('PhysicianForm.placeholder.apptTypes')}
              </span>
            </Button>
            {showAppointmentTypeError && (
              <FormHelperText error>
                {appointmentTypesErrorMessage}
              </FormHelperText>
            )}
          </>
        )}
      </Grid>
    );
  };

  const InsurancePlansFields = () => {
    return (
      <Grid item xs={6} sm={2}>
        {!isMobile && (
          <CustomSelect
            filled
            fieldKey="insurancePlans"
            fieldValue={physicianForm.insurancePlans}
            icon={<SecurityIcon />}
            label={t('PhysicianForm.insurancePlans')}
            items={insuranceOptions}
            validateFormHandler={handleSetFormState}
          />
        )}
        {isMobile && (
          <Button
            onClick={() => setShowInsuranceDialog(true)}
            variant="light"
            style={btnStyle}
            type="button"
            block
          >
            <SecurityIcon />
            <span style={iconSpan}>
              {physicianForm.insurancePlans.text ||
                t('PhysicianForm.insurance')}
            </span>
          </Button>
        )}
      </Grid>
    );
  };

  const DateTimeFields = () => {
    return (
      <Grid item xs={12} sm={2}>
        {!isMobile && (
          <>
            <Button
              onClick={() => setShowSchedulePicker(true)}
              variant="light"
              style={{
                border: '1px solid #979797',
                display: 'flex',
                padding: '14.5px 7px',
                textAlign: 'left',
              }}
              type="button"
              block
            >
              <WatchLater />
              <span
                style={{
                  display: 'inline-block',
                  fontSize: '14px',
                  paddingLeft: '5px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '70%',
                }}
              >
                {physicianForm.timestamp.displayText}
              </span>
            </Button>
            <SchedulePicker
              openDialog={showSchedulePicker}
              closeDialog={e => {
                if (e) {
                  e.displayText = moment(e.value?.start)
                    .tz(healthSystem['time-zone'])
                    .format('MMM D, YYYY');
                  setStartDate(e);
                  handleSetFormState({ timestamp: e });
                }
                setShowSchedulePicker(false);
              }}
              timezone={healthSystem['time-zone']}
              timestamp={timestamp()}
            />
          </>
        )}
        {isMobile && (
          <Button
            onClick={() => setShowTimePickerDialog(true)}
            variant="light"
            style={btnStyle}
            type="button"
            block
          >
            <WatchLater />
            <span style={iconSpan}>{physicianForm.timestamp.displayText}</span>
          </Button>
        )}
      </Grid>
    );
  };

  const elementHasValue = el => {
    let value = 'value';

    if (el === 'specialties') {
      value = 'name';
    }

    if (el === 'zip' && params.zip) {
      return false;
    }

    return (
      !physicianForm[el] ||
      !(typeof physicianForm[el][value] === 'string'
        ? physicianForm[el][value].trim()
        : '')
    );
  };

  const getButtonText = value => {
    if (physicianForm[value].displayText && value === 'timestamp') {
      return physicianForm[value].displayText;
    }
    if (physicianForm[value].text && value === 'timestamp') {
      return physicianForm[value].displayText;
    }

    if (physicianForm[value].text && value !== 'timestamp') {
      return physicianForm[value].text;
    }

    if (physicianForm[value].name) {
      return physicianForm[value].name;
    }

    if (elementHasValue(value)) {
      return getInputName(value);
    }

    if (value === 'zip') {
      return zipcode;
    }

    if (value === 'appointmentTypes') {
      // We get a list of appointmentType text/value objects as props,
      // so we search that for a match on the form state value.
      // 'Type' is a fallback in case we somehow don't find a match.
      const selectedAppointmentType = dropdownAppointmentTypes.find(
        a => a.value === physicianForm[value].value,
      );

      return selectedAppointmentType ? selectedAppointmentType.text : 'Type';
    }

    return physicianForm[value].value;
  };

  const formTypes = {
    insurancePlans: setShowInsuranceDialog,
    specialties: setShowSpecialityDialog,
    dateTime: setShowTimePickerDialog,
    appointmentTypes: setShowTypesDialog,
    timestamp: setShowTimePickerDialog,
    zip: setShowZipDialog,
  };

  const clickedStyle = {
    color: branding.primaryColor,
  };

  const filledStyle = {
    background: branding.buttonColor,
    border: `1px solid ${branding.buttonColor}`,
    color: branding.buttonTextColor,
  };

  const inverseStyle = {
    border: `1px solid ${branding.primaryColor}`,
    color: branding.primaryColor,
  };

  const showZipFields = shouldShowSearchElement(
    uxMode,
    params.service,
    searchConstants.ZIP_CODE_INPUT,
  );

  const showSpecialityFields = shouldShowSearchElement(
    uxMode,
    params.service === 'primary-care' ? 'find-a-doctor' : params.service,
    searchConstants.DOCTOR_NAME_SPECIALITY_INPUT,
  );
  const showAppointmentTypesFields = shouldShowSearchElement(
    uxMode,
    params.service,
    searchConstants.APP_TYPE_INPUT,
  );
  const showInsuranceFields = shouldShowSearchElement(
    uxMode,
    params.service,
    searchConstants.INSURANCE_INPUT,
  );
  const showDateTimeFields = shouldShowSearchElement(
    uxMode,
    params.service,
    searchConstants.DATE_TIME_INPUT,
  );

  return (
    <>
      {!showForm && isMobile && (
        <div
          className="filter-mobile"
          style={{
            borderBottom: `1px solid ${branding.primaryColor}`,
          }}
        >
          <ScrollContainer className="scroll-container">
            <div className="filter-mobile-wrapper">
              <button
                className="search"
                style={clickedStyle}
                type="button"
                onClick={() => {
                  handleSearch(true);
                }}
              >
                <SearchIcon />
              </button>

              {showZipFields && (
                <button
                  key="zip"
                  onClick={() => formTypes.zip(true) || null}
                  type="button"
                  style={elementHasValue('zip') ? inverseStyle : filledStyle}
                  className={elementHasValue('zip') ? 'inverse' : 'filled'}
                >
                  {elementHasValue('zip')
                    ? t('ZipcodeField.placeholder.zipCode')
                    : getButtonText('zip')}
                </button>
              )}

              {showSpecialityFields && (
                <button
                  key="specialties"
                  onClick={() => formTypes.specialties(true) || null}
                  type="button"
                  style={
                    elementHasValue('specialties') ? inverseStyle : filledStyle
                  }
                  className={
                    elementHasValue('specialties') ? 'inverse' : 'filled'
                  }
                >
                  {elementHasValue('specialties')
                    ? t('PhysicianForm.mobile.nameSpeciality')
                    : getButtonText('specialties')}
                </button>
              )}

              {showAppointmentTypesFields && showApptTypeSelect && (
                <button
                  key="appointmentTypes"
                  onClick={() => formTypes.appointmentTypes(true) || null}
                  type="button"
                  style={
                    elementHasValue('appointmentTypes')
                      ? inverseStyle
                      : filledStyle
                  }
                  className={
                    elementHasValue('appointmentTypes') ? 'inverse' : 'filled'
                  }
                >
                  {elementHasValue('appointmentTypes')
                    ? t('RegistrationForm.appointmentType')
                    : getButtonText('appointmentTypes')}
                </button>
              )}

              {showInsuranceFields && (
                <button
                  key="insurancePlans"
                  onClick={() => formTypes.insurancePlans(true) || null}
                  type="button"
                  style={
                    elementHasValue('insurancePlans')
                      ? inverseStyle
                      : filledStyle
                  }
                  className={
                    elementHasValue('insurancePlans') ? 'inverse' : 'filled'
                  }
                >
                  {elementHasValue('insurancePlans')
                    ? t('PhysicianForm.insurancePlans')
                    : getButtonText('insurancePlans')}
                </button>
              )}

              {/* Since this field is always applied to searches it should always be in the "filled in" style */}
              {showDateTimeFields && (
                <button
                  key="timestamp"
                  onClick={() => formTypes.timestamp(true) || null}
                  type="button"
                  style={filledStyle}
                  className="filled"
                >
                  {getButtonText('timestamp')}
                </button>
              )}
            </div>
          </ScrollContainer>
          {zipErrorMessage() && (
            <FormHelperText error>{zipErrorMessage()}</FormHelperText>
          )}
          {showApptTypeSelect && showAppointmentTypeError && (
            <FormHelperText error>
              {appointmentTypesErrorMessage}
            </FormHelperText>
          )}
        </div>
      )}

      {showForm && (
        <div className="form-content">
          <Grid container justify="flex-start" className="mb-3" spacing={2}>
            {showZipFields && ZipFields()}
            {showSpecialityFields && SpecialitiesFields()}
            {showAppointmentTypesFields && AppointmentTypesFields()}
            {showInsuranceFields && InsurancePlansFields()}
            {showDateTimeFields && DateTimeFields()}
            <Grid item xs={12} sm={1}>
              {isMobile && (
                <div className="hide-desktop button-container">
                  <BrandingButton
                    className="button-search"
                    type="submit"
                    block
                    onClick={e => {
                      const submitted = handleSearch(true);
                      if (submitted) {
                        setPhysicianShowForm(false);
                      }
                    }}
                  >
                    <SearchIcon />
                    <span className="button-text">
                      {t('PhysicianForm.find')}
                    </span>
                  </BrandingButton>
                </div>
              )}
              {!isMobile && (
                <div className="hide-mobile button-container">
                  <BrandingButton
                    className="button-search"
                    style={{ marginTop: 0 }}
                    type="submit"
                    block
                    onClick={() => handleSearch(true)}
                  >
                    <SearchIcon />
                    <span className="button-text">
                      {t('PhysicianForm.find')}
                    </span>
                  </BrandingButton>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      )}

      {showZipDialog && (
        <DialogZipCode
          handleShowDialog={e => {
            if (e) {
              handleSetFormState({ zip: { value: e } });
            }
            setShowZipDialog(false);
          }}
          showDialog={showZipDialog}
        />
      )}

      {showSpecialityDialog && (
        <DialogMobile
          content={<div />}
          handleShowDialog={e => {
            if (e) {
              handleSearchTextChanged(mobileSpecialities);
            }
            setShowSpecialityDialog(false);
          }}
          icon={<SupervisorAccountIcon />}
          showDialog={showSpecialityDialog}
          title={
            <div style={{ padding: '10px 0' }}>
              <Omnibox
                defaultValue={physicianForm.specialties}
                handleChange={e => {
                  setMobileSpecialities(e);
                }}
                options={searchable}
              />
            </div>
          }
        />
      )}

      {showTypesDialog && (
        <DialogRadio
          handleShowDialog={e => {
            if (e) {
              handleSetFormState({ appointmentTypes: e });
            }

            setShowTypesDialog(false);
          }}
          icon={<LocalHospitalIcon />}
          selections={dropdownAppointmentTypes}
          showDialog={showTypesDialog}
          title={t('RegistrationForm.appointmentType')}
          selectedValue={physicianForm.appointmentTypes.value}
        />
      )}

      {showInsuranceDialog && (
        <DialogRadio
          handleShowDialog={e => {
            if (e) {
              handleSetFormState({ insurancePlans: e });
            }

            setShowInsuranceDialog(false);
          }}
          icon={<SecurityIcon />}
          selections={insuranceOptions}
          showDialog={showInsuranceDialog}
          title={t('PhysicianForm.insurancePlans')}
          selectedValue={physicianForm.insurancePlans.value}
        />
      )}

      {showTimePickerDialog && (
        <DialogMobile
          content={
            <DialogTimePicker
              handleSelectionChange={e => {
                e.displayText = e.text;
                setTimestampPlaceholder({ timestamp: e });
              }}
              timezone={healthSystem['time-zone']}
            />
          }
          handleShowDialog={e => {
            setShowTimePickerDialog(false);
            if (e) {
              handleSetFormState(timestampPlaceholder);
            }
          }}
          icon={<WatchLater />}
          showDialog={showTimePickerDialog}
          title="Date Picker"
        />
      )}
    </>
  );
};

PhysicianForm.defaultProps = {
  geocoding: {},
  venuesOfCare: null,
};

PhysicianForm.propTypes = {
  geocoding: PropTypes.instanceOf(Object),
  loadNames: PropTypes.func.isRequired,
  loadNameWithServiceIds: PropTypes.func.isRequired,
  loadInsurancePlans: PropTypes.func.isRequired,
  searchProviders: PropTypes.func.isRequired,
  searchParams: PropTypes.instanceOf(Object).isRequired,
  insurancePlans: PropTypes.instanceOf(Array).isRequired,
  defaultAppointmentTypes: PropTypes.instanceOf(Array).isRequired,
  venuesOfCare: PropTypes.instanceOf(Object),
  loadAppointmentTypes: PropTypes.func.isRequired,
  loadAppointmentTypesAction: PropTypes.func.isRequired,
  fetchHealthSystem: PropTypes.func.isRequired,
  loadGeocoding: PropTypes.func.isRequired,
  setPhysicianShowForm: PropTypes.func.isRequired,
  setApptTypeRequired: PropTypes.func.isRequired,
  showForm: PropTypes.bool.isRequired,
  resetPageCount: PropTypes.func.isRequired,
  displayNotification: PropTypes.func.isRequired,
  clearNotification: PropTypes.func.isRequired,
  uxMode: PropTypes.string.isRequired,
  setStartDate: PropTypes.func.isRequired,
  healthSystem: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => {
  return {
    geocoding: state.geocoding,
    geocodingHasLoaded: state.geocoding.loaded,
    searchParams: state.searchParams,
    insurancePlans: state.insurancePlans,
    venuesOfCare: state.venuesOfCare,
    defaultAppointmentTypes: state.appointmentTypes.data,
    loading: state.apiCallsInProgress > 0,
    showForm: state.educationalPage.showForm.physician,
    healthSystem: state.healthSystem,
    services: state.serviceLines.services,
  };
};

const mapDispatchToProps = {
  loadGeocoding,
  loadNames,
  loadNameWithServiceIds,
  loadInsurancePlans,
  loadAppointmentTypes,
  searchProviders,
  displayNotification,
  clearNotification,
  fetchHealthSystem,
  setPhysicianShowForm,
  loadAppointmentTypesAction,
  loadFilteredActiveServices,
};

const connectedPhysicianForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PhysicianForm);

export default connectedPhysicianForm;
