import {
  handleResponse,
  handleError,
  getHeaderInfo,
  getEndpoint,
} from './apiUtils';

export async function getVenuesOfCare() {
  return fetch(
    getEndpoint(`venue-of-cares`, {
      include:
        'schedules,schedules.location,schedules.provider,schedules.insurance-plans',
    }),
    getHeaderInfo(),
  )
    .then(handleResponse)
    .catch(handleError);
}
