import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Avatar, makeStyles, useTheme } from '@material-ui/core';
import { BrandingContext } from '../contexts/BrandingContext';
import './ProviderAvatarServiceLine.scss'
import { ImportantDevices } from '@material-ui/icons';
import { isUndefined } from 'lodash';
const styles = makeStyles({
  avatar: {
    width: '80%'
  },
  serviceLineavatar: {
    width: '80%',
    borderRadius: '50%',
    svg: {
      width: '108px'
    }
  },
  avt: {
    svg: {
      width: '108px'
    }
  }
});
const ProviderAvatarServiceLine = ({ alt, src, service }) => {
  const { branding } = useContext(BrandingContext);
  const classes = styles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const avatarRef = useRef();
  const [avatarStyle, setAvatarStyle] = useState({ background: branding.primaryColor });
  useLayoutEffect(() => {
    handleResize();
    function handleResize() {
      if (avatarRef.current) {
        setAvatarStyle({
          height: avatarRef.current.offsetWidth + "px"
        })
      }
    }

    window.addEventListener('resize', handleResize)

  }, [avatarRef?.current?.offsetWidth]);
  return (
    <>
      {isDesktop && (
        <Avatar ref={avatarRef}
          alt={alt}
          src={src}
          className={classes.avatar}
          style={{ backgroundColor: branding.primaryColor, height: (avatarRef.current) ? avatarRef.current.offsetWidth > 100 ? avatarRef.current.offsetWidth + 'px' : isUndefined(service) === false && (service === 'mammography' || service === 'diagnostic-imaging') ? '109px' : '140px' : '147px' }}
        >{src}</Avatar>)}
      {!isDesktop && (
        <Avatar
          ref={avatarRef}
          alt={alt}
          className="ProviderAvatarServiceLine"

        style={{ backgroundColor: branding.primaryColor, height:  'auto', width: '80%'}}
        > {src}
        </Avatar>)}
    </>
  );
};

ProviderAvatarServiceLine.defaultProps = {
  src: null,
  isServieLine: false,
};

ProviderAvatarServiceLine.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
  isServieLine: PropTypes.string,
};

export default ProviderAvatarServiceLine;
