import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { getVisit } from 'api/visitApi';
import camel from 'camelcase-keys';
import PageNotFound from 'components/PageNotFound';
import BreadCrumbs from 'components/common/BreadCrumbs';
import Location from 'components/common/Location';
import './VisitCancelConfirmationPage.scss';

const VisitCancelConfirmationPage = ({ match }) => {
  const [visit, setVisit] = useState({
    data: null,
  });

  const [location, setLocation] = useState({});
  const [scheduleId, setScheduleId] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      if (visit.data === null) {
        const v = await getVisit(match.params.visitId, {
          include: 'schedule,location,provider',
        });
        if (v.data !== null) {
          setVisit(v);
          const loc = v.included.find(l => l.type === 'locations');
          setLocation(loc.attributes);
          const sked = v.included.find(l => l.type === 'schedules');
          setScheduleId(sked.id);
        } else {
          setVisit({ data: 'loaded' });
        }
      }
    })();
  });

  if (visit.data === null) return <></>;
  if (visit.data === 'loaded') return <PageNotFound />;

  return (
    <main className="VisitCancelConfirmationPage container">
      <BreadCrumbs onlyHome />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1">
          {visit?.data?.attributes?.['is-copay-refunded'] ? t('PatientIntake.CoPayRefundMsg') : 
              !visit?.data?.attributes?.['is-copay-refunded'] && visit?.data?.attributes?.['payment-info'] ? t('PatientIntake.CoPayRefundErrorMsg') : t('VisitCancelConfirmationPage.visitCancelled')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={8}>
          <span>
            {t('VisitCancelConfirmationPage.requireFurtherAssistance')}{' '}
            <Link to={`/schedule/calendar/${scheduleId}`}>
              {t('VisitCancelConfirmationPage.clickHere')}
            </Link>{' '}
            {t('VisitCancelConfirmationPage.toRescheduleFacility')}
          </span>
        </Grid>
        <Grid item xs={12}>
          <Location location={{ ...location, ...camel(location) }} />
        </Grid>
      </Grid>
    </main>
  );
};

VisitCancelConfirmationPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      visitId: PropTypes.string,
    }),
  }).isRequired,
};

export default VisitCancelConfirmationPage;
