import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import QueryString from 'query-string';
import { isEmpty } from 'lodash';
import { FormHelperText, Grid, useTheme } from '@material-ui/core';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import ScrollContainer from 'react-indiana-drag-scroll';
import RoomIcon from '@material-ui/icons/Room';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SearchIcon from '@material-ui/icons/Search';
import SecurityIcon from '@material-ui/icons/Security';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { loadAppointmentTypes } from 'redux/actions/appointmentTypesActions';
import { loadInsurancePlans } from 'redux/actions/insurancePlanActions';
import {
  clearNotification,
  displayNotification,
} from 'redux/actions/uiActions';
import { BrandingContext } from 'components/contexts/BrandingContext';
import ZipcodeField from 'components/form/ZipcodeField';
import { useTranslation } from 'react-i18next';
import { setProviderShowForm } from '../../redux/actions/educationalPageActions';
import { loadGeocoding } from '../../redux/actions/geocodingActions';
import CustomSelect from './CustomSelect';
import { loadNames, searchProviders } from '../../redux/actions/searchActions';
import DialogRadio from '../dialogs/DialogRadio';
import DialogZipCode from '../dialogs/DialogZipCode';
import BrandingButton from '../common/BrandingButton';
import { isValid, getInputName } from '../../utils/helpers';

const ProviderForm = ({
  geocoding,
  searchParams,
  loadNames,
  searchProviders,
  insurancePlans,
  loadInsurancePlans,
  defaultAppointmentTypes,
  loadAppointmentTypes,
  displayNotification,
  clearNotification,
  loadGeocoding,
  setProviderShowForm,
  showForm,
}) => {
  const { coords, zipcode, zipcodeValid } = geocoding;
  const { branding } = useContext(BrandingContext);
  const history = useHistory();
  const { search, pathname } = useLocation();
  const params = QueryString.parse(search);
  const {
    selectedParams: { appointmentTypeId },
  } = searchParams;
  const [t] = useTranslation();
  const [providerForm, setProviderForm] = useState({
    appointmentTypes: {
      value: params.appointmentTypes || appointmentTypeId || '',
    },
    zip: {
      value: params.zip || '',
    },
    insurancePlans: {
      value: params.insurancePlans || '',
    },
  });
  const [showZipDialog, setShowZipDialog] = useState(false);
  const [showInsuranceDialog, setShowInsuranceDialog] = useState(false);
  const [showTypesDialog, setShowTypesDialog] = useState(false);
  const [showAppointmentTypeError, setShowAppointmentTypeError] = useState(
    false,
  );
  const [zipcodeLengthError, setZipcodeLengthError] = useState(false);
  const [zipcodeInvalidError, setZipcodeInvalidError] = useState(false);
  const [geoInfo, setGeoInfo] = useState({ lat: 0, lng: 0 });
  const [changedSearchOption, setChangedSearchOption] = useState();
  const [initialSearchDone, setInitialSearchDone] = useState(true);
  const [insurancePlanCallPlaced, setInsurancePlanCallPlaced] = useState(true);

  useEffect(() => {
    if (zipcodeValid) {
      setZipcodeLengthError(false);
      setZipcodeInvalidError(false);
    }
  }, [zipcodeValid]);

  const insuranceOptions = insurancePlans.map(plan => ({
    value: plan.id,
    text: plan.name,
  }));

  const handleSetFormState = e => {
    setChangedSearchOption(Object.keys(e)[0]);

    if (!isEmpty(e)) {
      setProviderForm({
        ...providerForm,
        ...e,
      });
    }
  };

  useEffect(() => {
    setChangedSearchOption('zipcode');
    setProviderForm({ ...providerForm, zip: { value: zipcode } });
  }, [zipcode]);

  useEffect(() => {
    const formParams = Object.keys(providerForm).reduce((acc, curr) => {
      acc[curr] = providerForm[curr].value;
      return acc;
    }, {});

    if (!zipcode && isValid(params.zip) && !geocoding.ziploaded) {
      loadGeocoding(params.zip);
    }

    formParams.tab = params.tab || null;
    formParams.timestamp = null;

    const search = QueryString.stringify({
      ...params,
      ...formParams,
      zip: zipcode,
    });
    history.replace({
      pathname,
      search,
    });
  }, [providerForm, searchParams]);

  useEffect(() => {
    if (coords && coords.lat && coords.lng) {
      loadNames(coords.lat, coords.lng);
    }
  }, [loadNames, coords]);

  const appointmentTypesErrorMessage = t('err.selectApp');
  const zipcodeErrorMessage = t('PhysicianForm.err.zip');
  const zipcodeLengthIssue = false;
  const appointmentTypeNotSet = !providerForm.appointmentTypes.value;

  const zipErrorMessage = () => {
    // TODO: refactor all this zipcode error handling into something somewhat sensical.
    if (zipcodeInvalidError || zipcodeLengthError) {
      return zipcodeErrorMessage;
    }
    return '';
  };

  const handleSearch = initialSearch => {
    if (initialSearch) {
      setInitialSearchDone(true);
    }

    if (!initialSearch && !initialSearchDone) {
      return;
    }

    if (!zipcodeValid || zipcodeLengthIssue) {
      if (zipcodeLengthIssue) {
        setZipcodeLengthError(true);
      }

      if (!zipcodeValid) {
        setZipcodeInvalidError(true);
      }

      return false;
    }

    setShowAppointmentTypeError(false);
    setZipcodeLengthError(false);
    setZipcodeInvalidError(false);

    const searchParams = {};

    if (providerForm.appointmentTypes.value)
      searchParams.appointmentTypeId = providerForm.appointmentTypes.value;
    if (zipcodeValid && coords.lat && coords.lng) {
      searchParams.latitude = coords.lat;
      searchParams.longitude = coords.lng;
    }
    if (providerForm.insurancePlans.value) {
      searchParams.insurancePlan = providerForm.insurancePlans.value;
    }

    const search = QueryString.stringify({
      ...params,
      searchType: params.searchType || 'now',
      zip: zipcode,
    });

    if (params.searchType === 'inventory') {
      history.push({
        pathname,
        search,
      });
    } else {
      history.replace({
        pathname,
        search,
      });
    }

    searchProviders(searchParams);
    return true;
  };

  const { appointmentTypes } = searchParams;

  useEffect(() => {
    const formData = {};

    if (isEmpty(appointmentTypes) && isEmpty(defaultAppointmentTypes)) {
      loadAppointmentTypes();
    }

    if (coords && insurancePlanCallPlaced) {
      setInsurancePlanCallPlaced(false);
      loadInsurancePlans().catch(error => {
        // console.log(`Loading insurance plans failed ${error}`);
      });
    }

    if (!isEmpty(insuranceOptions)) {
      const typeObj = insuranceOptions.find(
        type => type.value === params.insurancePlans,
      );

      if (typeObj) {
        formData.insurancePlans = {
          ...providerForm.insurancePlans,
          text: typeObj.text,
        };
      }
    }

    if (!isEmpty(defaultAppointmentTypes)) {
      const typeId = appointmentTypeId || params.appointmentTypes;
      const typeObj = defaultAppointmentTypes.find(type => type.id === typeId);

      if (typeObj) {
        formData.appointmentTypes = {
          ...providerForm.appointmentTypes,
          text: typeObj.attributes.name,
        };
      }
    }

    if (!isEmpty(formData)) {
      handleSetFormState({ ...formData });
    }
  }, [
    appointmentTypes,
    defaultAppointmentTypes,
    loadAppointmentTypes,
    insurancePlans,
    loadInsurancePlans,
    coords,
  ]);

  const btnStyle = {
    display: 'flex',
    justifyContent: 'center',
  };

  const iconSpan = {
    display: 'inline-block',
    overflow: 'hidden',
    paddingLeft: '10px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '80%',
  };

  const elementHasValue = el => {
    return (
      !providerForm[el] ||
      !(typeof providerForm[el].value === 'string'
        ? providerForm[el].value.trim()
        : '')
    );
  };

  const getButtonText = value => {
    if (providerForm[value].text) {
      return providerForm[value].text;
    }

    if (elementHasValue(value)) {
      return getInputName(value);
    }

    return providerForm[value].value;
  };

  const formTypes = {
    insurancePlans: setShowInsuranceDialog,
    appointmentTypes: setShowTypesDialog,
    zip: setShowZipDialog,
  };

  const clickedStyle = {
    color: branding.primaryColor,
  };

  const filledStyle = {
    background: branding.buttonColor,
    border: `1px solid ${branding.buttonColor}`,
    color: branding.buttonTextColor,
  };

  const inverseStyle = {
    border: `1px solid ${branding.primaryColor}`,
    color: branding.primaryColor,
  };

  // zipcode and speciality are handled by handleOnBlur
  useEffect(() => {
    if (!initialSearchDone || changedSearchOption === 'zipcode') {
      return;
    }

    if (isMobile) {
      handleSearch(false);
      return;
    }

    if (changedSearchOption && changedSearchOption !== 'speciality') {
      handleSearch(false);
    }
  }, [providerForm]);

  useEffect(() => {
    const {
      coords: { lat, lng },
      zipcode,
    } = geocoding;

    if (!lat || !lng || zipcode === '') {
      setZipcodeInvalidError(true);
    }
    if (!lat || !lng) return;

    if (
      geoInfo.zipcode !== zipcode ||
      geoInfo.lat !== lat ||
      geoInfo.lng !== lng
    ) {
      setGeoInfo({ lat, lng });
      if (initialSearchDone) {
        clearNotification();
        handleSearch(false);
        if (isMobile) setProviderShowForm(false);
      }
    }
  }, [geocoding]);

  return (
    <>
      {!showForm && (
        <div
          className="filter-mobile hide-desktop"
          style={{
            borderBottom: `1px solid ${branding.primaryColor}`,
          }}
        >
          <ScrollContainer className="scroll-container">
            <div className="filter-mobile-wrapper">
              <button
                className="search"
                type="button"
                onClick={() => handleSearch(false)}
                style={clickedStyle}
              >
                <SearchIcon />
              </button>
              <button
                key="zip"
                onClick={() => formTypes.zip(true) || null}
                type="button"
                style={elementHasValue('zip') ? inverseStyle : filledStyle}
                className={elementHasValue('zip') ? 'inverse' : 'filled'}
              >
                {elementHasValue('zip')
                  ? t('ZipcodeField.placeholder.zipCode')
                  : getButtonText('zip')}
              </button>
              <button
                key="appointmentTypes"
                onClick={() => formTypes.appointmentTypes(true) || null}
                type="button"
                style={
                  elementHasValue('appointmentTypes')
                    ? inverseStyle
                    : filledStyle
                }
                className={
                  elementHasValue('appointmentTypes') ? 'inverse' : 'filled'
                }
              >
                {elementHasValue('appointmentTypes')
                  ? t('RegistrationForm.appointmentType')
                  : getButtonText('appointmentTypes')}
              </button>
              <button
                key="insurancePlans"
                onClick={() => formTypes.insurancePlans(true) || null}
                type="button"
                style={
                  elementHasValue('insurancePlans') ? inverseStyle : filledStyle
                }
                className={
                  elementHasValue('insurancePlans') ? 'inverse' : 'filled'
                }
              >
                {elementHasValue('insurancePlans')
                  ? t('PhysicianForm.insurancePlans')
                  : getButtonText('insurancePlans')}
              </button>
            </div>
          </ScrollContainer>
          {zipErrorMessage() && (
            <FormHelperText error={zipcodeLengthError || zipcodeInvalidError}>
              {zipErrorMessage()}
            </FormHelperText>
          )}
        </div>
      )}

      {showForm && (
        <div className="form-content">
          <Grid container justify="flex-start" className="mb-3" spacing={2}>
            <Grid item xs={12} sm={3}>
              <div className="hide-mobile">
                <ZipcodeField
                  filled
                  icon={<RoomIcon />}
                  hasError={zipcodeLengthError || zipcodeInvalidError}
                  errorMessage={zipErrorMessage()}
                />
              </div>
              <div className="hide-desktop">
                <Button
                  onClick={() => setShowZipDialog(true)}
                  style={btnStyle}
                  variant="light"
                  type="button"
                  block
                >
                  <RoomIcon />
                  <span style={iconSpan}>
                    {providerForm.zip.value ||
                      t('ZipcodeField.placeholder.zipCode')}
                  </span>
                </Button>
                {zipErrorMessage() && (
                  <FormHelperText
                    error={zipcodeLengthError || zipcodeInvalidError}
                  >
                    {zipErrorMessage()}
                  </FormHelperText>
                )}
              </div>
            </Grid>

            <Grid item xs={6} sm={3}>
              <div className="hide-mobile">
                <CustomSelect
                  filled
                  fieldKey="appointmentTypes"
                  fieldValue={providerForm.appointmentTypes}
                  label={t('RegistrationForm.appointmentType')}
                  icon={<LocalHospitalIcon />}
                  items={defaultAppointmentTypes}
                  showNone="test"
                  displayEmpty
                  validateFormHandler={e => {
                    const { value } = e.appointmentTypes;
                    if (value) {
                      setShowAppointmentTypeError(false);
                    } else {
                      setShowAppointmentTypeError(true);
                    }
                    handleSetFormState(e);
                  }}
                  hasError={showAppointmentTypeError}
                  errorMessage={
                    showAppointmentTypeError ? appointmentTypesErrorMessage : ''
                  }
                />
              </div>
              <div className="hide-desktop">
                <Button
                  onClick={() => setShowTypesDialog(true)}
                  style={btnStyle}
                  variant="light"
                  type="button"
                  block
                >
                  <LocalHospitalIcon />
                  <span style={iconSpan}>
                    {providerForm.appointmentTypes.text || t('appointmentType')}
                  </span>
                </Button>
              </div>
            </Grid>

            <Grid item xs={6} sm={4}>
              <div className="hide-mobile">
                <CustomSelect
                  filled
                  fieldKey="insurancePlans"
                  fieldValue={providerForm.insurancePlans}
                  label={t('PhysicianForm.insurancePlans')}
                  icon={<SecurityIcon />}
                  items={insuranceOptions}
                  validateFormHandler={handleSetFormState}
                />
              </div>
              <div className="hide-desktop">
                <Button
                  onClick={() => setShowInsuranceDialog(true)}
                  style={btnStyle}
                  variant="light"
                  type="button"
                  block
                >
                  <SecurityIcon />
                  <span style={iconSpan}>
                    {providerForm.insurancePlans.text ||
                      t('PhysicianForm.insurance')}
                  </span>
                </Button>
              </div>
            </Grid>

            <Grid item xs={12} sm={2} md={1}>
              <div className="hide-desktop button-container">
                <BrandingButton
                  className="button-search"
                  type="submit"
                  block
                  onClick={e => {
                    const submitted = handleSearch(true);
                    if (submitted) {
                      setProviderShowForm(false);
                    }
                  }}
                >
                  <SearchIcon fontSize="large" />
                  <span className="button-text">{t('PhysicianForm.find')}</span>
                </BrandingButton>
              </div>
              <div className="hide-mobile button-container">
                <BrandingButton
                  className="button-search"
                  type="submit"
                  block
                  onClick={() => handleSearch(true)}
                >
                  <SearchIcon fontSize="large" />
                  <span className="button-text">{t('PhysicianForm.find')}</span>
                </BrandingButton>
              </div>
            </Grid>
          </Grid>
        </div>
      )}

      {showZipDialog && (
        <DialogZipCode
          handleShowDialog={e => {
            if (e) {
              handleSetFormState({ zip: { value: e } });
            }
            setShowZipDialog(false);
          }}
          showDialog={showZipDialog}
        />
      )}

      {showTypesDialog && (
        <DialogRadio
          handleShowDialog={e => {
            if (e) {
              handleSetFormState({ appointmentTypes: e });
            }

            setShowTypesDialog(false);
          }}
          icon={<LocalHospitalIcon />}
          selections={defaultAppointmentTypes}
          showDialog={showTypesDialog}
          title={t('RegistrationForm.appointmentType')}
          selectedValue={providerForm.appointmentTypes.value}
        />
      )}

      {showInsuranceDialog && (
        <DialogRadio
          handleShowDialog={e => {
            if (e) {
              handleSetFormState({ insurancePlans: e });
            }

            setShowInsuranceDialog(false);
          }}
          icon={<SecurityIcon />}
          selections={insuranceOptions}
          showDialog={showInsuranceDialog}
          title={t('PhysicianForm.insurancePlans')}
          selectedValue={providerForm.insurancePlans.value}
        />
      )}
    </>
  );
};

ProviderForm.propTypes = {
  geocoding: PropTypes.instanceOf(Object).isRequired,
  loadNames: PropTypes.func.isRequired,
  searchProviders: PropTypes.func.isRequired,
  searchParams: PropTypes.instanceOf(Object).isRequired,
  loadInsurancePlans: PropTypes.func.isRequired,
  loadAppointmentTypes: PropTypes.func.isRequired,
  displayNotification: PropTypes.func.isRequired,
  clearNotification: PropTypes.func.isRequired,
  insurancePlans: PropTypes.instanceOf(Array).isRequired,
  defaultAppointmentTypes: PropTypes.instanceOf(Array),
  loadGeocoding: PropTypes.func.isRequired,
  setProviderShowForm: PropTypes.func.isRequired,
  showForm: PropTypes.bool.isRequired,
};

ProviderForm.defaultProps = {
  defaultAppointmentTypes: [],
};

const mapStateToProps = state => {
  return {
    geocoding: state.geocoding,
    searchParams: state.searchParams,
    insurancePlans: state.insurancePlans,
    defaultAppointmentTypes: state.appointmentTypes.data,
    loading: state.apiCallsInProgress > 0,
    showForm: state.educationalPage.showForm.provider,
  };
};

const mapDispatchToProps = {
  loadNames,
  loadGeocoding,
  loadInsurancePlans,
  loadAppointmentTypes,
  searchProviders,
  displayNotification,
  clearNotification,
  setProviderShowForm,
};

const connectedProviderForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderForm);

export default connectedProviderForm;
