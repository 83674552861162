import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import QueryString from 'query-string';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateSearchOptions } from 'redux/actions/searchActions';
import './MoreAvailableDoctors.scss';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const MoreAvailableDoctors = ({
  venueType,
  selectedParams,
  updateSearchOptions,
}) => {
  const history = useHistory();
  const [t] = useTranslation();
  const { search, pathname } = useLocation();
  const queryParams = QueryString.parse(search);

  const handleClick = () => {
    const params = { tab: venueType };
    updateSearchOptions({ ...selectedParams, ...params });
    const searchParams = { ...queryParams, ...params };
    const search = QueryString.stringify({
      ...searchParams,
      searchType: 'inventory',
    });
    history.replace({
      pathname,
      search,
    });
  };
  return (
    <Button
      onClick={handleClick}
      className="mt-2 btn-plain provider-more-doctors-link"
    >
      {t('FullSchedule.moreAvailableTimes')}
    </Button>
  );
};

MoreAvailableDoctors.defaultProps = {
  venueType: null,
  selectedParams: {},
  updateSearchOptions: () => {},
};

MoreAvailableDoctors.propTypes = {
  venueType: PropTypes.string,
  selectedParams: PropTypes.instanceOf(Object),
  updateSearchOptions: PropTypes.func,
};

const mapDispatchToProps = {
  updateSearchOptions,
};

function mapStateToProps(state) {
  return {
    selectedParams: state.searchParams.selectedParams,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MoreAvailableDoctors);
