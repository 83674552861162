import React, { useContext } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomRadio from '../CustomRadio';

const GenderFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const hasError = formContext.hasError('gender');
  const [t] = useTranslation();
  return (
    <>
      {formContext.isFieldEnabled('gender') && (
        <div className="form-section_row">
          <div className="label-question">
            {formContext.isFieldRequired('gender') && (
              <span className="required">* </span>
            )}
            {t('Registration.GenderFields.gender')}
          </div>

          <FormControl variant="outlined" fullWidth error={hasError}>
            <RadioGroup
              aria-label="gender"
              onChange={e =>
                formContext.handleSetFormState({
                  gender: { value: e.target.value },
                })
              }
              value={formContext.form.gender.value}
              row
            >
              <FormControlLabel
                value="male"
                control={<CustomRadio />}
                label={t('GenderFields.genderMale')}
              />
              <FormControlLabel
                value="female"
                control={<CustomRadio />}
                label={t('GenderFields.genderFemale')}
              />
            </RadioGroup>

            {hasError && (
              <FormHelperText error={hasError}>
                {formContext.getError('gender')}
              </FormHelperText>
            )}
          </FormControl>
        </div>
      )}
    </>
  );
};

export default GenderFields;
