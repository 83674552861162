import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Dialog, DialogTitle, DialogContent } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import { CancelOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import { PatientIntakeContext } from 'components/contexts/PatientIntakeContext';
import moment from 'moment';

const AppointmentInfoModal = ({ show, onClose }) => {
    const [t] = useTranslation();
    const intake = useContext(PatientIntakeContext);
    const appointmentInfo = intake?.apptInfo;

    const useStyles = makeStyles((theme) => ({
        valueTextStyle: {
            display: "flex",
            justifyContent: "flex-end",
            fontSize: "13px !important",
            fontWeight: "600 !important"
        },
        keyTextStyle: {
            fontSize: "13px !important",
            fontWeight: "500 !important"
        },
        titleStyle: {
            margin: 0,
            padding: 2,
            fontWeight: "600 !important",
            lineHeight: "30px !important"
        },
        clearIconStyle: {
            position: 'absolute !important',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
        },
        addressTextStyle: {
            display: "grid",
            justifyContent: "flex-end",
            fontSize: "13px !important",
            fontWeight: "600 !important",
            textAlign: "right"
        }
    }))

    const classes = useStyles();

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={show}
            maxWidth={"xs"}
        >
            <DialogTitle className={classes.titleStyle} id="customized-dialog-title">
                {t('PatientIntake.ApptInfo')}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                className={classes.clearIconStyle}
            >
                <CancelOutlined />
            </IconButton>
            <DialogContent dividers >
                <Grid container justifyContent={"space-between"}>
                    <Grid item xs={5} marginY={0.5}>
                        <Typography className={classes.keyTextStyle}>
                            {t('PatientIntake.Patient')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7} marginY={0.5}>
                        <Typography className={classes.valueTextStyle}>
                            {appointmentInfo?.patientName}
                        </Typography>
                    </Grid>
                    <Grid item xs={5} marginY={0.5}>
                        <Typography className={classes.keyTextStyle}>
                            {t('PatientIntake.ApptType')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7} marginY={0.5}>
                        <Typography className={classes.valueTextStyle}>
                            {appointmentInfo?.appointmentType}
                        </Typography>
                    </Grid>
                    <Grid item xs={5} marginY={0.5}>
                        <Typography className={classes.keyTextStyle}>
                            {t('PatientIntake.Appt')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7} marginY={0.5}>
                        <Typography className={classes.valueTextStyle}>
                            {moment(appointmentInfo?.appointmentTime?.split(".")[0]).format('MMM DD, YYYY h:mm A')}
                        </Typography>
                    </Grid>
                    <Grid item xs={5} marginY={0.5}>
                        <Typography className={classes.keyTextStyle}>
                            {t('PatientIntake.Provider')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7} marginY={0.5}>
                        <Typography className={classes.valueTextStyle}>
                            {appointmentInfo?.providerName}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} marginY={0.5}>
                        <Typography className={classes.keyTextStyle}>
                            {t('PatientIntake.Address')}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} marginY={0.5}>
                        <Typography component={'div'} className={classes.addressTextStyle}>
                            <p>{appointmentInfo?.providerAddress}</p>
                            <p>{appointmentInfo?.providerLocation}</p>
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

AppointmentInfoModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default AppointmentInfoModal;
