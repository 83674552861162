import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FormControlLabel, RadioGroup } from '@material-ui/core';
import CustomRadio from '../CustomRadio';
import { RegistrationFormContext } from '../RegistrationFormProvider';

const NewPatientFields = ({ locationName }) => {
  const formContext = useContext(RegistrationFormContext);
  const [t] = useTranslation();
  return (
    <>
      {formContext.isFieldEnabled('new-patient') && (
        <div className="form-section_row">
          <div className="label-question">
            {formContext.isFieldRequired('new-patient') && (
              <span className="required">* </span>
            )}
            {`${t('NewPatientFields.hasThePatientVisited')} ${locationName} ${t(
              'NewPatientFields.before',
            )}?`}
          </div>

          <RadioGroup
            aria-label="New Patient"
            row
            onChange={e =>
              formContext.handleSetFormState({
                newPatient: { value: e.target.value },
              })
            }
          >
            <FormControlLabel
              value="yes"
              control={<CustomRadio />}
              label={t('VisitFeedbackPage.yes')}
            />
            <FormControlLabel
              value="no"
              control={<CustomRadio />}
              label={t('VisitFeedbackPage.no')}
            />
          </RadioGroup>
        </div>
      )}
    </>
  );
};

NewPatientFields.propTypes = {
  locationName: PropTypes.string.isRequired,
};

export default NewPatientFields;
