import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import CustomTextField from '../CustomTextField';

const EmailFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const [t] = useTranslation();
  return (
    <>
      {formContext.isFieldEnabled('email') && (
        <div className="form-section_row">
          <Grid container>
            <Grid item sm={4} xs={12}>
              <CustomTextField
                fieldKey="email"
                fieldValue={formContext.form.email}
                validateFormHandler={formContext.handleSetFormState}
                label={t('EmailFields.email')}
                required={formContext.isFieldRequired('email')}
                hasError={formContext.hasError('email')}
                errorMessage={formContext.getError('email')}
                shrink
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default EmailFields;
