import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Avatar, makeStyles, useTheme } from '@material-ui/core';
import { BrandingContext } from '../contexts/BrandingContext';

const styles = makeStyles({
  avatar: {
    width: '80%'
  },
  serviceLineavatar: {
    width: '100%'
  },

});

const ProviderAvatar = ({ alt, src, isServieLine }) => {
  const { branding } = useContext(BrandingContext);
  const classes = styles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const avatarRef = useRef();
  const [avatarStyle, setAvatarStyle] = useState({ background: branding.primaryColor, });

  useLayoutEffect(() => {

    handleResize();

    function handleResize() {
      if (avatarRef.current) {
        setAvatarStyle({
          height: avatarRef.current.offsetWidth + "px",
          background: branding.primaryColor
        })
      }
    }

    window.addEventListener('resize', handleResize)

  }, []);
  return (
    <>
      {!isServieLine && (
        <Avatar ref={avatarRef}
          alt={alt}
          src={src}
          className={classes.avatar}
          style={avatarStyle}
        />)}
    </>
  );
};

ProviderAvatar.defaultProps = {
  src: null,
  isServieLine: false,
};

ProviderAvatar.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
  isServieLine: PropTypes.string,
};

export default ProviderAvatar;
