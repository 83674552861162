import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Grid } from '@material-ui/core';
import { formatPhone, unformatPhone } from 'utils/formatPhone';
import { RegistrationFormContext } from '../RegistrationFormProvider';
import { BlackCheckbox } from '../FormElements';
import CustomTextField from '../CustomTextField';

const PhoneNumberFields = () => {
  const formContext = useContext(RegistrationFormContext);
  const [t] = useTranslation();
  return (
    <>
      {formContext.isFieldEnabled('phone-number') && (
        <div className="form-section_row">
          <Grid container>
            <Grid item sm={4} xs={12}>
              <CustomTextField
                fieldKey="phoneNumber"
                fieldValue={{
                  value: formatPhone(formContext.form.phoneNumber.value),
                }}
                validateFormHandler={e => {
                  const {
                    phoneNumber: { error, value },
                  } = e;
                  formContext.handleSetFormState({
                    phoneNumber: {
                      error,
                      value: unformatPhone(value),
                    },
                  });
                }}
                label={t('PhoneNumberFields.phoneNumber')}
                hasError={formContext.hasError('phone-number')}
                errorMessage={formContext.getError('phone-number')}
                required={formContext.isFieldRequired('phone-number')}
                shrink
              />

              <FormControlLabel
                control={
                  <BlackCheckbox
                    inputProps={{
                      'aria-label': t('PhoneNumberFields.smsNotifications'),
                      title: t('PhoneNumberFields.smsNotifications'),
                    }}
                    value={!formContext.form['sms-notifications'].value}
                    onChange={e =>
                      formContext.handleSetFormState({
                        'sms-notifications': {
                          value: e.target.value === 'true',
                        },
                      })
                    }
                  />
                }
                label={t('PhoneNumberFields.smsNotificationsLabel')}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default PhoneNumberFields;
